import React from "react";
import {
    Modal
} from "@amzn/awsui-components-react/polaris";
import ContactCreatePage from "contact/ContactCreatePage";
import AddProviderServiceToContact from "contact/AddProviderServiceToContact";

const ContactModal = props => (
    <Modal
        visible={props.isAddContactClicked || props.isAddServiceToContactClicked}
        header={props.isAddContactClicked ? "Add Contact" : "Add Service To Contact"}
        onDismiss={props.handleContactModalClick}
        size="large"
    >
        {props.isAddContactClicked &&
            <ContactCreatePage
                match={props.match}
                auth={props.auth}
                isModal
                handleContactModalClick={props.handleContactModalClick}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                providerOptions={props.providerOptions}
                providersLoading={props.providersLoading}
            />
        }
        {props.isAddServiceToContactClicked &&
            <AddProviderServiceToContact
                match={props.match}
                auth={props.auth}
                contactItems={props.contactItems}
                contactOptions={props.contactOptions}
                contacts={props.contacts}
                providerServiceItems={props.providerServiceItems}
                providerServiceOptions={props.providerServiceOptions}
                handleContactModalClick={props.handleContactModalClick}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
            />
        }
    </Modal>
);

export default ContactModal;