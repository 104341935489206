import Constants from "utils/Constants";

export default class ProviderValidation {
    static DEFAULT_PROVIDER_ERROR_TEXTS = {
        providerName: Constants.ERROR_STRINGS.blankInput,
        status: "",
        parentProviderName: "",
        description: "",
        industry: "",
        sites: "",
        businessOperationsId: Constants.ERROR_STRINGS.blankInput
    };

    static validateInput = (input) => {
        const inputtedData = input;
        // Here we fetch the input
        const attributeId = inputtedData.evt.target.id;
        let inputValue;
        if (attributeId === Constants.ATTRIBUTES.status || attributeId === Constants.ATTRIBUTES.industry
            || attributeId === Constants.ATTRIBUTES.parentProviderName
            || attributeId === Constants.ATTRIBUTES.businessOperationsId) {
            inputValue = inputtedData.evt.detail.selectedOption.label === Constants.BLANK_OPTION
                ? "" : inputtedData.evt.detail.selectedOption.value;
        } else {
            inputValue = inputtedData.evt.detail.value;
        }
        // If the input value is empty, pass null as the value into the provider object so a backend error doesn't
        // trigger (dynamo doesn't like blank strings)
        inputtedData.provider[attributeId] = inputValue || null;

        // NOTE: In the future, if we make the providerName field editable once the provider has been created, we might
        // want to have some additional validation to make sure the value is unique compared to existing providers

        // required fields cannot be blank
        if (attributeId === Constants.ATTRIBUTES.providerName || attributeId === Constants.ATTRIBUTES.status
            || attributeId === Constants.ATTRIBUTES.businessOperationsId) {
            if (!inputValue) {
                inputtedData.providerErrorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
            } else {
                inputtedData.providerErrorTexts[attributeId] = "";
            }
        } else if (!inputValue) {
            // optional fields can be blank, but otherwise must be validate
            inputtedData.providerErrorTexts[attributeId] = "";
        }
        return inputtedData;
    }
}