import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import { StageDisplayMode, StageEditMode } from "order/OrderCommonComponents";
import {
    WorkOrderExecutionDisplayMode,
    WorkOrderExecutionEditMode,
    getDisplayWorkOrderField
} from "order/stages/install/WorkOrderExecutionInformation";
import ConfirmDeliveryDateModal from "./ConfirmDeliveryDateModal";

export default class WorkOrderExecutionHandler extends Component {
    state = {
        updatedWorkOrder: {},
        updatedSpan: {},
        searchedWorkOrder: {},
        loadingWorkOrder: false,
        disableWorkOrderSearch: this.props.order.orderType === Constants.ORDER_TYPES.RERATE,
        disableWorkOrderFields: this.props.order.orderType === Constants.ORDER_TYPES.INSTALL,
        loadingSpan: false,
        disableSpanSearch: this.props.order.orderType === Constants.ORDER_TYPES.RERATE,
        disableSpanFields: this.props.order.orderType === Constants.ORDER_TYPES.INSTALL,
        errorTexts: {},
        isEditClicked: false,
        isUpdateStageInProgress: false,
        hasBeenSubmittedOnce: false
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This function takes a work orders contract central url, with the pattern of
     * https://contractcentral.amazon.com/<UNIQUE_PATH_AND_ID>. Since the first part of the URL
     * (https://contractcentral.amazon.com/) is hardcoded, we remove that from the work orders contractCentralUrl
     * while the user is editing the stage. Before submission, we add it back so that the value will not change
     * @param contractCentralUrl
     */
    trimWorkOrderContractCentralUrl = (contractCentralUrl) => {
        const splitContractCentralUrlArray = contractCentralUrl.split(Constants.CONTRACT_CENTRAL_URL);
        // Here we filter out any falsey elements from the splitContractCentralUrlArray. Since we split on the
        // entire https://contractcentral.amazon.com/, the first element of the array will be an empty string and
        // will be filtered out. The second element will be the unique path/identifier of the contract which
        // we will use to display during edit mode
        return splitContractCentralUrlArray.filter(splitString => !!splitString).find(Boolean);
    }

    handleCloseConfirmDeliveryDateModal = () => {
        this.setState({
            isDeliveryDatePastDate: false
        });
    }

    handleStageEditClick = () => {
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        // const updatedOrder = HelperFunctions.deepClone(this.props.order);
        const updatedWorkOrder = HelperFunctions.deepClone(this.props.workOrder);
        updatedWorkOrder.contractCentralUrl = updatedWorkOrder.contractCentralUrl
            ? this.trimWorkOrderContractCentralUrl(updatedWorkOrder.contractCentralUrl)
            : updatedWorkOrder.contractCentralUrl;
        const updatedSpan = HelperFunctions.deepClone(this.props.span);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            isUpdateStageInProgress: false,
            hasBeenSubmittedOnce: false,
            updatedWorkOrder,
            updatedSpan
        });
    };

    /**
     * This method handles input changes to the work order section
     */
    handleWorkOrderInputChange = (evt) => {
        const updatedWorkOrder = HelperFunctions.deepClone(this.state.updatedWorkOrder);
        const errorTexts = HelperFunctions.deepClone(this.state.errorTexts);
        const attributeId = evt.target.id;

        let inputValue;
        if (attributeId === Constants.ATTRIBUTES.currency) {
            inputValue = evt.detail.selectedOption.value;
        } else {
            inputValue = evt.detail.value;
        }

        updatedWorkOrder[attributeId] = inputValue || null;

        // Both tax and approval urls are optional fields on workOrder object.
        if (!inputValue
            && !(attributeId === Constants.ATTRIBUTES.taxUrl || attributeId === Constants.ATTRIBUTES.approvalUrl)) {
            errorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
        } else {
            errorTexts[attributeId] = "";
        }

        if (attributeId === Constants.ATTRIBUTES.contractCentralUrl) {
            errorTexts[attributeId] = "Must search for work order before submitting.";
            Object.assign(errorTexts, {
                spanNumber: Constants.ERROR_STRINGS.blankInput,
                spanServiceType: Constants.ERROR_STRINGS.blankInput,
                nrc: Constants.ERROR_STRINGS.blankInput,
                mrc: Constants.ERROR_STRINGS.blankInput
            });
            this.setState({
                disableWorkOrderSearch: false,
                disableWorkOrderFields: true,
                disableSpanSearch: false,
                disableSpanFields: true,
                updatedSpan: {}
            });
        }

        this.setState({
            updatedWorkOrder,
            errorTexts
        });
    };

    searchForWorkOrder = async () => {
        const errorTexts = HelperFunctions.deepClone(this.state.errorTexts);
        errorTexts[Constants.ATTRIBUTES.contractCentralUrl] = "";
        await this.setState({
            loadingWorkOrder: true,
            disableWorkOrderSearch: false,
            errorTexts
        });
        try {
            const searchForWorkOrderByContractCentralUrlResponse =
                await this.FremontBackendClient.getWorkOrdersBasedOffSearchParams(
                    `${Constants.CONTRACT_CENTRAL_URL}${this.state.updatedWorkOrder.contractCentralUrl}`,
                    this.props.auth
                );

            // If we don't find a workOrder add all error messages back
            if (searchForWorkOrderByContractCentralUrlResponse.workOrders.length === 0) {
                errorTexts[Constants.ATTRIBUTES.term] = Constants.ERROR_STRINGS.blankInput;
                errorTexts[Constants.ATTRIBUTES.currency] = Constants.ERROR_STRINGS.blankInput;
                // If we don't find a workOrder than we clear out all the other workOrder fields
                // except for the contract central URL
                const updatedWorkOrder = {
                    [Constants.ATTRIBUTES.contractCentralUrl]: this.state.updatedWorkOrder.contractCentralUrl,
                    [Constants.ATTRIBUTES.term]: "",
                    [Constants.ATTRIBUTES.currency]: ""
                };
                this.setState({
                    updatedWorkOrder,
                    loadingWorkOrder: false,
                    disableWorkOrderSearch: false,
                    disableWorkOrderFields: false,
                    errorTexts
                });
            } else if (searchForWorkOrderByContractCentralUrlResponse.workOrders.length > 1) {
                // If more then a single work order has the same contract central URL, we display an error message
                // to the user and do not modify the work order or its error texts
                const error = new Error();
                error.message = `More than one work order exists with the same contract central URL ${this.state.updatedWorkOrder.contractCentralUrl}.`;
                this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
                this.setState({
                    loadingWorkOrder: false,
                    disableWorkOrderSearch: false,
                    disableWorkOrderFields: false
                });
            } else {
                // In this case, only a single work order has been returned so we update the value of our work order
                // with the one returned and clear out the error messages
                errorTexts[Constants.ATTRIBUTES.term] = "";
                errorTexts[Constants.ATTRIBUTES.currency] = "";
                // Here we obtain the work order from the response, update the contractCentralUrlToDisplay, and then
                // trim the contractCentralUrl attribute
                const workOrder = searchForWorkOrderByContractCentralUrlResponse.workOrders.find(Boolean);
                workOrder.contractCentralUrlToDisplay = workOrder.contractCentralUrl;
                workOrder.contractCentralUrl = this.trimWorkOrderContractCentralUrl(workOrder.contractCentralUrl);
                this.setState({
                    updatedWorkOrder: workOrder,
                    searchedWorkOrder: workOrder,
                    disableWorkOrderSearch: true,
                    loadingWorkOrder: false,
                    disableWorkOrderFields: false,
                    errorTexts
                });
            }
        } catch (error) {
            // If an error was thrown during the search for a work order, we do not alter the work order object
            // or any error texts, display the error message, and set the appropriate state attributes to false
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                loadingWorkOrder: false,
                disableWorkOrderSearch: false,
                disableWorkOrderFields: false
            });
        }
    };

    /**
     * This method handles input changes to the span section
     */
    handleSpanInputChange = (evt) => {
        const updatedSpan = HelperFunctions.deepClone(this.state.updatedSpan);
        const errorTexts = HelperFunctions.deepClone(this.state.errorTexts);
        const attributeId = evt.target.id;

        let inputValue;
        if (attributeId === Constants.ATTRIBUTES.spanServiceType) {
            inputValue = evt.detail.selectedOption.value;
        } else {
            inputValue = evt.detail.value;
        }

        if (attributeId === Constants.ATTRIBUTES.spanServiceType ||
            attributeId === Constants.ATTRIBUTES.expectedDeliveryDate) {
            updatedSpan[attributeId] = inputValue;
        } else {
            updatedSpan[attributeId] = inputValue || null;
        }

        if (attributeId === Constants.ATTRIBUTES.expectedDeliveryDate) {
            const dateValue = evt.detail.value.trim();
            const parsedDate = Date.parse(dateValue);
            if (!inputValue) {
                errorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
            } else if (!parsedDate) {
                errorTexts[attributeId] = "Invalid date format";
            } else if (Date.now() > parsedDate && dateValue.length === 10) {
                this.state.isDeliveryDatePastDate = true;
            } else {
                errorTexts[attributeId] = "";
            }
        } else if (!inputValue) {
            errorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
        } else {
            errorTexts[attributeId] = "";
        }

        // if user changes the span number they need to search again
        if (attributeId === Constants.ATTRIBUTES.spanNumber) {
            errorTexts[attributeId] = "Must search for span before submitting.";
            this.setState({
                disableSpanSearch: false,
                disableSpanFields: true
            });
        }

        this.setState({
            updatedSpan,
            errorTexts
        });
    }

    searchForSpan = async () => {
        const errorTexts = HelperFunctions.deepClone(this.state.errorTexts);
        errorTexts[Constants.ATTRIBUTES.spanNumber] = "";
        this.setState({
            loadingSpan: true,
            disableSpanSearch: false,
            errorTexts
        });
        if (this.state.updatedWorkOrder.workOrderId) {
            try {
                const searchForSpansByWorkOrderIdSpanNumberRequest = {
                    workOrderId: this.state.updatedWorkOrder.workOrderId,
                    spanNumber: this.state.updatedSpan.spanNumber
                };
                const searchForSpansByWorkOrderIdSpanNumberResponse =
                    await this.FremontBackendClient.getSpansBasedOffSearchParams(
                        searchForSpansByWorkOrderIdSpanNumberRequest, this.props.auth
                    );

                // If we don't find a span add all error messages back
                if (searchForSpansByWorkOrderIdSpanNumberResponse.spans.length === 0) {
                    errorTexts[Constants.ATTRIBUTES.spanServiceType] = Constants.ERROR_STRINGS.blankInput;
                    errorTexts[Constants.ATTRIBUTES.mrc] = Constants.ERROR_STRINGS.blankInput;
                    errorTexts[Constants.ATTRIBUTES.nrc] = Constants.ERROR_STRINGS.blankInput;
                    errorTexts[Constants.ATTRIBUTES.expectedDeliveryDate] = Constants.ERROR_STRINGS.blankInput;
                    // If we don't find a span than we clear out all the span fields except for the span number
                    const updatedSpan = {
                        [Constants.ATTRIBUTES.spanNumber]: this.state.updatedSpan.spanNumber,
                        [Constants.ATTRIBUTES.spanServiceType]: "",
                        [Constants.ATTRIBUTES.mrc]: "",
                        [Constants.ATTRIBUTES.nrc]: "",
                        [Constants.ATTRIBUTES.expectedDeliveryDate]: ""
                    };
                    this.setState({
                        updatedSpan,
                        loadingSpan: false,
                        disableSpanSearch: false,
                        disableSpanFields: false,
                        errorTexts
                    });
                } else if (searchForSpansByWorkOrderIdSpanNumberResponse.spans.length > 1) {
                    // If more then a single span has the same workOrderIdSpanNumber, we display an error message
                    // to the user and do not modify the span or its error texts
                    const error = new Error();
                    error.message = `More than one span exists with the same span number ${this.state.updatedSpan.spanNumber} for the contract central URL ${this.state.updatedWorkOrder.contractCentralUrl}.`;
                    this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
                    this.setState({
                        loadingSpan: false,
                        disableSpanSearch: false,
                        disableSpanFields: false
                    });
                } else {
                    // In this case, only a single span has been returned so we update the value of our span
                    // with the one returned
                    const updatedSpan = searchForSpansByWorkOrderIdSpanNumberResponse.spans.find(Boolean);
                    // Here obtain all of the billingSegmentIds that exist on the circuits in the order we viewing
                    const circuitsBillingSegmentIds = this.props.circuitDesignObjects.map(circuitDesign =>
                        circuitDesign[Constants.ATTRIBUTES.billingSegmentId]);
                    // If every circuits billing segment ID exists on the span, we do not need an error message since
                    // the span is connected to these circuits. Otherwise, we show a frontend error message since users
                    // cannot select a span that is already assigned to different circuits
                    errorTexts[Constants.ATTRIBUTES.spanNumber] =
                        circuitsBillingSegmentIds.every(circuitBillingSegmentId =>
                            updatedSpan.billingSegmentIdList.includes(circuitBillingSegmentId)) ?
                            "" : "A span with this number already exists for this work order";
                    errorTexts[Constants.ATTRIBUTES.expectedDeliveryDate] = "";
                    errorTexts[Constants.ATTRIBUTES.spanServiceType] = "";
                    errorTexts[Constants.ATTRIBUTES.mrc] = "";
                    errorTexts[Constants.ATTRIBUTES.nrc] = "";
                    this.setState({
                        updatedSpan,
                        loadingSpan: false,
                        disableSpanSearch: true,
                        disableSpanFields: false,
                        errorTexts
                    });
                }
            } catch (error) {
                // If an error was thrown during the search for a span, we do not alter the span object
                // or any error texts, display the error message, and set the appropriate attributes to false
                // Display error message
                this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
                this.setState({
                    loadingSpan: false,
                    disableSpanSearch: false,
                    disableSpanFields: false
                });
            }
        } else {
            // In this case the work order is not found, we add back all the error messages
            errorTexts[Constants.ATTRIBUTES.spanServiceType] = Constants.ERROR_STRINGS.blankInput;
            errorTexts[Constants.ATTRIBUTES.mrc] = Constants.ERROR_STRINGS.blankInput;
            errorTexts[Constants.ATTRIBUTES.nrc] = Constants.ERROR_STRINGS.blankInput;
            errorTexts[Constants.ATTRIBUTES.expectedDeliveryDate] = Constants.ERROR_STRINGS.blankInput;
            // Since there is no span for a new work order than we clear out all the span fields except
            // for the span number
            const updatedSpan = {
                [Constants.ATTRIBUTES.spanNumber]: this.state.updatedSpan.spanNumber,
                [Constants.ATTRIBUTES.spanServiceType]: "",
                [Constants.ATTRIBUTES.mrc]: "",
                [Constants.ATTRIBUTES.nrc]: "",
                [Constants.ATTRIBUTES.expectedDeliveryDate]: ""
            };
            // If the work order does not have a work order ID, that means the work order will be created
            // upon submission. In that case, we don't actually need to search for a span. However, we still
            // set the appropriate state to make it appear like we performed a search
            this.setState({
                updatedSpan,
                errorTexts,
                loadingSpan: false,
                disableSpanSearch: true,
                disableSpanFields: false
            });
        }
    }

    handleStageSubmit = async () => {
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true
        });
        if (Object.values(this.state.errorTexts).some(errorText => !!errorText)) {
            this.props.handleFlashBarMessagesFromChildTabs(
                false,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                false
            );
            this.setState({ isUpdateStageInProgress: false, hasBeenSubmittedOnce: true });
            return;
        }
        try {
            const workOrderToSubmit = HelperFunctions.deepClone(this.state.updatedWorkOrder);
            // If the contractCentralUrl of the workOrder is a valid UUID, than we reattach the static piece of the
            // URL here. If it is not, that means the workOrder has some non-valid contractCentralUrl (such as one
            // imported from NCIS) so we do not want to modify it here
            if (Constants.REGEX_PATTERNS.uuidV4.test(
                this.state.updatedWorkOrder[Constants.ATTRIBUTES.contractCentralUrl]
            )) {
                workOrderToSubmit[Constants.ATTRIBUTES.contractCentralUrl] =
                    `${Constants.CONTRACT_CENTRAL_URL}${this.state.updatedWorkOrder[Constants.ATTRIBUTES.contractCentralUrl]}`;
            }
            workOrderToSubmit.orderIdFromRequest = this.props.order[Constants.ATTRIBUTES.orderId];
            workOrderToSubmit[Constants.ATTRIBUTES.providerName] = this.props.order[Constants.ATTRIBUTES.providerName];

            const existingWorkOrder = Object.keys(this.state.searchedWorkOrder).length > 0 ?
                this.state.searchedWorkOrder : this.props.workOrder;
            const workOrderResponse = await this.FremontBackendClient.modifyWorkOrder(
                [workOrderToSubmit], [existingWorkOrder], this.props.auth
            );

            // Here we obtain the work order from the response, update the contractCentralUrlToDisplay, and then
            // trim the contractCentralUrl attribute
            const [workOrder] = workOrderResponse.workOrders;
            workOrder.contractCentralUrlToDisplay = workOrder.contractCentralUrl;
            workOrder.contractCentralUrl = this.trimWorkOrderContractCentralUrl(workOrder.contractCentralUrl);

            const spanToSubmit = HelperFunctions.deepClone(this.state.updatedSpan);
            spanToSubmit[Constants.ATTRIBUTES.workOrderId] = workOrder[Constants.ATTRIBUTES.workOrderId];
            spanToSubmit[Constants.ATTRIBUTES.orderId] = this.props.order[Constants.ATTRIBUTES.orderId];
            spanToSubmit[Constants.ATTRIBUTES.orderIdFromRequest] = this.props.order[Constants.ATTRIBUTES.orderId];

            await this.FremontBackendClient.modifySpan([spanToSubmit], [this.props.span], this.props.auth);

            // Here we call a helper function which updates all data related to the order and also update the props
            // workorder and span here
            await this.props.loadData(true, true);

            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            // Resets all input fields to original state if request is successful
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false,
                hasBeenSubmittedOnce: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isEditClicked: false,
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.workOrderExecution}
                    showAttachmentModal={false}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.props.order.circuitDesignIdList.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    circuitItems={[]}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    content={
                        <WorkOrderExecutionDisplayMode
                            order={this.props.order}
                            workOrder={this.props.workOrder}
                            span={this.props.span}
                            currencySymbol={HelperFunctions.findCurrencySymbol(
                                getDisplayWorkOrderField(this.props.workOrder, Constants.ATTRIBUTES.currency)
                            )}
                        />
                    }
                />
                :
                <div>
                    <StageEditMode
                        order={this.props.order}
                        stageName={Constants.STAGE_NAMES.workOrderExecution}
                        handleStageEditClick={this.handleStageEditClick}
                        handleStageSubmit={this.handleStageSubmit}
                        isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                        isUpdateStageDisabled={this.state.loadingWorkOrder || this.state.loadingSpan}
                        content={
                            <WorkOrderExecutionEditMode
                                order={this.props.order}
                                updatedWorkOrder={this.state.updatedWorkOrder}
                                updatedSpan={this.state.updatedSpan}
                                loadingWorkOrder={this.state.loadingWorkOrder}
                                disableWorkOrderSearch={this.state.disableWorkOrderSearch}
                                disableWorkOrderFields={this.state.disableWorkOrderFields}
                                loadingSpan={this.state.loadingSpan}
                                disableSpanSearch={this.state.disableSpanSearch}
                                disableSpanFields={this.state.disableSpanFields}
                                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                                    this.props.order.stageStatusMap)}
                                errorTexts={this.state.hasBeenSubmittedOnce
                                    ? this.state.errorTexts : {}}
                                isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                                handleWorkOrderInputChange={this.handleWorkOrderInputChange}
                                searchForWorkOrder={this.searchForWorkOrder}
                                handleSpanInputChange={this.handleSpanInputChange}
                                searchForSpan={this.searchForSpan}
                            />
                        }
                    />
                    <ConfirmDeliveryDateModal
                        isDeliveryDatePastDate={this.state.isDeliveryDatePastDate}
                        handleCloseConfirmDeliveryDateModal={this.handleCloseConfirmDeliveryDateModal}
                    />
                </div>
        );
    }
}
