import React from "react";
import { Link } from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class ContactTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "firstName",
            sortingField: "firstName",
            header: "First Name",
            description: "First name of Contact",
            cell: item => <Link href={`${Constants.ROUTES.contact}/${item.contactId}`}>{item.firstName}</Link>
        },
        {
            id: "lastName",
            sortingField: "lastName",
            header: "Last Name",
            description: "Last name of Contact",
            cell: item => <Link href={`${Constants.ROUTES.contact}/${item.contactId}`}>{item.lastName}</Link>
        },
        {
            id: "providerNameList",
            header: "Provider Name(s)",
            description: "List of providers supported by this contact",
            cell: item =>
                (
                    <div>
                        {HelperFunctions.renderProviderNameLinks(item.providerNameList)}
                    </div>)
        },
        {
            id: "status",
            header: "Status",
            description: "Status",
            cell: item => item.status
        },
        {
            id: "title",
            header: "Title",
            description: "Contact title",
            cell: item => item.title
        },
        {
            id: "email",
            header: "Email",
            description: "Contact email address",
            cell: item => item.email
        }
    ];

    static fetchCreateContactLink = providerName =>
        (providerName ? `${Constants.ROUTES.createContact}/${providerName}` : Constants.ROUTES.createContact);
}