import React, { Component } from "react";
import "airwolf-header.css";
// We use the react-router-dom link here because it does not force a
// full page reload when navigating back to the homepage
import { Link as InternalLink } from "react-router-dom";
import {
    Grid,
    Flashbar
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import FremontGlobalSearch from "common/FremontGlobalSearch";
import HelperFunctions from "common/HelperFunctions";

/*
 * The FremontHeader displays the main header of Fremont Service and conditionally renders a flashbar depending
 * if the flashbar type input prop is set.
 */
export default class FremontHeader extends Component {
    state = {
        flashbar: {
            type: this.props.flashbarType,
            text: this.props.flashbarText
        }
    };

    /**
     * We use the componentDidUpdate function to modify state when the flashbar props change
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        if (prevProps.flashbarType !== this.props.flashbarType
            || prevProps.flashbarText !== this.props.flashbarText) {
            this.setState({
                flashbar: {
                    type: this.props.flashbarType,
                    text: this.props.flashbarText
                }
            });
        }
        // If the sideNavError has changed and the new sideNavError has a message, we display it
        if (prevProps.sideNavError !== this.props.sideNavError && this.props.sideNavError
            && this.props.sideNavError.message) {
            this.setState({
                flashbar: {
                    type: Constants.FLASHBAR_TYPES.error,
                    text: this.props.sideNavError.message
                }
            });
        }
    };

    /**
     * This function handles dismissing the flashbar. This will run the onDismiss() function that was passed in, if one
     * exists, along with actually clearing out the flashbar
     */
    dismissFlashbar = () => {
        // Most consumers of the <FremontHeader> will pass a custom onDismiss function to handle dismissing the flashbar
        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
        // However, if this function is not passed, we handle removing the flashbar here
        HelperFunctions.dismissFlashbar(this);
    }

    // Here we define a function which allows a child of FremontHeader (in this case, FremontGlobalSearch)
    // to modify the flashbar directly
    updateFlashbar = (flashbarParameters) => {
        this.setState({
            flashbar: {
                type: flashbarParameters.type,
                text: flashbarParameters.text
            }
        });
    };

    render() {
        return (
            <span>
                <div className="flashbar">
                    <Grid
                        gridDefinition={[{ colspan: { default: 12 } }]}
                        disableGutters
                    >
                        <div>
                            <Flashbar
                                items={
                                    // Here we conditionally render an information flashbar if the current
                                    // user is mimicking another user
                                    (HelperFunctions.isMimickingUser(this.props.auth)) ?
                                        [{
                                            dismissible: false,
                                            content: `You (${this.props.auth.userId}) are viewing Fremont from the perspective of ${this.props.auth.mimickedUserId}. Changes made will still be under your alias.`,
                                            type: Constants.FLASHBAR_TYPES.info
                                        }] : []
                                }
                            />
                        </div>
                    </Grid>
                </div>
                <div className="airwolf-header">
                    <span className="airwolf-header-stripe-shine one"/>
                    <span className="airwolf-header-stripe-shine two"/>
                    <span className="airwolf-header-stripe-fade one"/>
                    <span className="airwolf-header-stripe-fade two"/>
                    <span className="airwolf-header-stripe-fade three"/>
                    <span className="airwolf-header-stripe-fade four"/>
                    <span className="airwolf-header-stripe-fade five"/>
                    <InternalLink to={Constants.ROUTES.fremontDashboard}>
                        <span className="airwolf-header-title">
                            <legend className="header-link">
                                <span className="airworlf-header-title">FREMONT</span>
                            </legend>
                        </span>
                    </InternalLink>
                    <span className="airwolf-header-subtitle">
                        THE NETWORK CAPACITY WORKFLOW ORCHESTRATION SERVICE
                    </span>
                </div>
                <div className="airwolf-header-search">
                    <span className="airwolf-header-global-search">
                        <FremontGlobalSearch
                            updateFlashbar={this.updateFlashbar}
                            history={this.props.history}
                            auth={this.props.auth}
                            updateSearchResults={this.props.updateSearchResults}
                        />
                    </span>
                </div>
                {this.state.flashbar.type &&
                    <div className="flashbar">
                        <Flashbar
                            items={
                                [{
                                    content: this.state.flashbar.text,
                                    dismissible: true,
                                    type: this.state.flashbar.type,
                                    loading: this.props.flashbarLoading || false,
                                    onDismiss: this.dismissFlashbar
                                }]
                            }
                        />
                    </div>
                }
            </span>
        );
    }
}