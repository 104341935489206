import React from "react";
import {
    Box,
    FormField,
    Header,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontExpandableSection,
    FremontInput,
    FremontSelect,
    FremontAutosuggest
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import {
    TableDisplayModeTable,
    TableEditModeTable
} from "order/OrderCommonComponents";

export const autosuggestEnteredText = value => `Current value: "${value}`;

export const generateCablingInstallStageColumnDefinitions = (serviceType, site,
    numberOfItems, customerFabric) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item[Constants.ATTRIBUTES.circuitDesignId]}`}>
                    {item[Constants.ATTRIBUTES.circuitDesignNumber]}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.cabling], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.subStatusMap,
            header: "Sub-Status",
            description: "Stage sub-status for this circuit",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.cabling] :
                    <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.subStatusMap}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={HelperFunctions.createSelectedOptions(
                                Constants.SUB_STATUS_OPTIONS.cabling
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.cabling]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled ||
                            !HelperFunctions.isStageInProgress(item.stageStatusMap.cabling)}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: site === Constants.COMPONENT_NAMES.siteA ? "loaA" : "loaZ",
            header: site === Constants.COMPONENT_NAMES.siteA ? "LOA A" : "LOA Z",
            cell: (item) => {
                if (site === Constants.COMPONENT_NAMES.siteA ? item[Constants.ATTRIBUTES.loaAttachmentIdA] :
                    item[Constants.ATTRIBUTES.loaAttachmentIdZ]) {
                    return (
                        <FremontButton
                            variant="icon"
                            iconName="file"
                            disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                            onClick={() => item.downloadAttachment(site === Constants.COMPONENT_NAMES.siteA ?
                                item[Constants.ATTRIBUTES.loaAttachmentIdA] :
                                item[Constants.ATTRIBUTES.loaAttachmentIdZ])}
                        />
                    );
                }
                return "";
            }
        }
    ];

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)) {
        columnDefinitions.push(
            {
                id: site === Constants.COMPONENT_NAMES.siteA ?
                    Constants.COMPONENT_NAMES.crossConnectA : Constants.COMPONENT_NAMES.crossConnectZ,
                header: site === Constants.COMPONENT_NAMES.siteA ? "Cross Connect A" : "Cross Connect Z",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: (item) => {
                    if (!item.editable) {
                        return site === Constants.COMPONENT_NAMES.siteA ?
                            item[Constants.COMPONENT_NAMES.crossConnectA] :
                            item[Constants.COMPONENT_NAMES.crossConnectZ];
                    }
                    return (
                        <FormField
                            errorText={item.hasStageSubmittedOnce &&
                            item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                                Constants.COMPONENT_NAMES.crossConnectA :
                                Constants.COMPONENT_NAMES.crossConnectZ]}
                        >
                            <FremontInput
                                id={`${site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.COMPONENT_NAMES.crossConnectA :
                                    Constants.COMPONENT_NAMES.crossConnectZ}${Constants.SEPARATOR}${item.circuitDesignId}`}
                                value={item[site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.COMPONENT_NAMES.crossConnectA :
                                    Constants.COMPONENT_NAMES.crossConnectZ]}
                                onInput={item.handleStageInputChange}
                                disabled={item.allFieldsDisabled}
                            />
                        </FormField>
                    );
                }
            }
        );
    }

    columnDefinitions.push(
        {
            id: Constants.COMPONENT_NAMES.ttId,
            header: site === Constants.COMPONENT_NAMES.siteA ? "TT Install A" : "TT Install Z",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (!item.editable ?
                HelperFunctions.renderExternalLink(item[
                    Constants.ATTRIBUTES.ttMap
                ][
                    site === Constants.COMPONENT_NAMES.siteA ?
                        Constants.TT_TYPES.dcoTTA : Constants.TT_TYPES.dcoTTZ
                ][
                    Constants.COMPONENT_NAMES.ttId
                ])
                :
                <FormField
                    errorText={item.hasStageSubmittedOnce &&
                    item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                        Constants.TT_TYPES.dcoTTA :
                        Constants.TT_TYPES.dcoTTZ]}
                >
                    <FremontInput
                        /* Using multiple Constants.SEPARATOR to render values from the ttMap, ttId, and ttStatus */
                        id={`${site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.TT_TYPES.dcoTTA :
                            Constants.TT_TYPES.dcoTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttId}`}
                        value={item[Constants.ATTRIBUTES.ttMap][site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.TT_TYPES.dcoTTA :
                            Constants.TT_TYPES.dcoTTZ][Constants.COMPONENT_NAMES.ttId]}
                        onInput={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                    />
                </FormField>)
        },
        {
            id: Constants.COMPONENT_NAMES.ttStatus,
            header: site === Constants.COMPONENT_NAMES.siteA ? "TT Status A" : "TT Status Z",
            description: "Status of the TT",
            cell: item =>
                (!item.editable ?
                    item[Constants.ATTRIBUTES.ttMap][
                        site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.TT_TYPES.dcoTTA : Constants.TT_TYPES.dcoTTZ
                    ][Constants.COMPONENT_NAMES.ttStatus] :
                    <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                        <FremontSelect
                            /* Using multiple Constants.SEPARATOR to render values from the ttMap, ttId, and ttStatus */
                            id={`${site === Constants.COMPONENT_NAMES.siteA ? Constants.TT_TYPES.dcoTTA :
                                Constants.TT_TYPES.dcoTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttStatus}`}
                            options={HelperFunctions.createTTStatusSelectedOptions(
                                item[Constants.ATTRIBUTES.ttMap][site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.TT_TYPES.dcoTTA :
                                    Constants.TT_TYPES.dcoTTZ][Constants.COMPONENT_NAMES.ttId]
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.ttMap][site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.TT_TYPES.dcoTTA : Constants.TT_TYPES.dcoTTZ][
                                    Constants.COMPONENT_NAMES.ttStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            empty="Enter a valid TT url first."
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>)
        }
    );

    if (Constants.OPTICAL_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: "opticalTTId",
                header: site === Constants.COMPONENT_NAMES.siteA ? "Optical TT A" : "Optical TT Z",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (!item.editable ?
                    HelperFunctions.renderExternalLink(item[
                        Constants.ATTRIBUTES.ttMap
                    ][
                        site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.TT_TYPES.opticalTTA : Constants.TT_TYPES.opticalTTZ
                    ][
                        Constants.COMPONENT_NAMES.ttId
                    ]) :
                    <FormField
                        errorText={item.hasStageSubmittedOnce &&
                        item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.TT_TYPES.opticalTTA :
                            Constants.TT_TYPES.opticalTTZ]}
                    >
                        <FremontInput
                            /* Using multiple Constants.SEPARATOR to render values from the
                                    ttMap, ttId, and ttStatus */
                            id={`${site === Constants.COMPONENT_NAMES.siteA ?
                                Constants.TT_TYPES.opticalTTA :
                                Constants.TT_TYPES.opticalTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttId}`}
                            value={item[Constants.ATTRIBUTES.ttMap][site === Constants.COMPONENT_NAMES.siteA ?
                                Constants.TT_TYPES.opticalTTA :
                                Constants.TT_TYPES.opticalTTZ][Constants.COMPONENT_NAMES.ttId]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>)
            },
            {
                id: "opticalTTStatus",
                header: site === Constants.COMPONENT_NAMES.siteA ? "Optical TT Status A" : "Optical TT Status Z",
                description: "Status of the Optical TT",
                cell: item =>
                    (!item.editable ?
                        item[Constants.ATTRIBUTES.ttMap][
                            site === Constants.COMPONENT_NAMES.siteA ?
                                Constants.TT_TYPES.opticalTTA : Constants.TT_TYPES.opticalTTZ
                        ][Constants.COMPONENT_NAMES.ttStatus] :
                        <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                            <FremontSelect
                                /* Using multiple Constants.SEPARATOR to render values from the ttMap, ttId, ttStatus */
                                id={`${site === Constants.COMPONENT_NAMES.siteA ? Constants.TT_TYPES.opticalTTA :
                                    Constants.TT_TYPES.opticalTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttStatus}`}
                                options={HelperFunctions.createTTStatusSelectedOptions(
                                    item[Constants.ATTRIBUTES.ttMap][
                                        Constants.TT_TYPES.opticalTTA
                                    ][Constants.COMPONENT_NAMES.ttId]
                                )}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    item[Constants.ATTRIBUTES.ttMap][site === Constants.COMPONENT_NAMES.siteA ?
                                        Constants.TT_TYPES.opticalTTA : Constants.TT_TYPES.opticalTTZ][
                                        Constants.COMPONENT_NAMES.ttStatus]
                                )}
                                onChange={item.handleStageInputChange}
                                empty="Enter a valid TT url first."
                                disabled={item.allFieldsDisabled}
                                expandToViewport
                            />
                        </div>)
            }
        );
    }

    columnDefinitions.push(
        {
            id: site === Constants.COMPONENT_NAMES.siteA ?
                Constants.COMPONENT_NAMES.demarcAndCfaA : Constants.COMPONENT_NAMES.demarcAndCfaZ,
            header: `CFA ${site === Constants.COMPONENT_NAMES.siteA ? "A" : "Z"}`,
            description: `CFA ${site === Constants.COMPONENT_NAMES.siteA ? "A" : "Z"} of the circuit`,
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                !item.editable ? item[site === Constants.COMPONENT_NAMES.siteA ?
                    Constants.COMPONENT_NAMES.demarcAndCfaA : Constants.COMPONENT_NAMES.demarcAndCfaZ] :
                    <FremontAutosuggest
                        id={`${site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.COMPONENT_NAMES.demarcAndCfaA :
                            Constants.COMPONENT_NAMES.demarcAndCfaZ}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        value={item[site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.COMPONENT_NAMES.demarcAndCfaA : Constants.COMPONENT_NAMES.demarcAndCfaZ] || ""
                        }
                        onChange={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                        options={item.comprehendCFAOptions}
                        enteredTextLabel={autosuggestEnteredText} // required field for Autosuggest
                        expandToViewport
                        disableBrowserAutocorrect
                        empty="No suggested values for this CFA field"
                    />
            )
        }
    );

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)
        || Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.router),
                header: HelperFunctions.returnHeaderInformation(site, Constants.COMPONENT_LABELS.router),
                description: Constants.COMPONENT_DESCRIPTION.router,
                cell: item => item[site === Constants.COMPONENT_NAMES.siteA ?
                    Constants.COMPONENT_NAMES.nodeA : Constants.COMPONENT_NAMES.nodeZ]
            },
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.interface),
                header: HelperFunctions.returnHeaderInformation(site, Constants.COMPONENT_LABELS.interface),
                description: Constants.COMPONENT_DESCRIPTION.interface,
                cell: item => item[site === Constants.COMPONENT_NAMES.siteA ?
                    Constants.COMPONENT_NAMES.portA : Constants.COMPONENT_NAMES.portZ]
            }
        );
    }

    if (serviceType === Constants.SERVICE_TYPES.BACKBONE
            && Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.lever),
                header: HelperFunctions.returnHeaderInformation(site, Constants.COMPONENT_LABELS.lever),
                description: Constants.COMPONENT_DESCRIPTION.lever,
                cell: item => (item[site === Constants.COMPONENT_NAMES.siteA ?
                    Constants.COMPONENT_NAMES.leverA : Constants.COMPONENT_NAMES.leverZ]
                )
            },
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.leverExternalInterface),
                header: HelperFunctions.returnHeaderInformation(site,
                    Constants.COMPONENT_LABELS.leverExternalInterface),
                description: Constants.COMPONENT_DESCRIPTION.leverExternalInterface,
                cell: item => (item[site === Constants.COMPONENT_NAMES.siteA ?
                    Constants.COMPONENT_NAMES.leverAExternalInterface :
                    Constants.COMPONENT_NAMES.leverZExternalInterface]
                )
            },
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.leverInternalInterface),
                header: HelperFunctions.returnHeaderInformation(site,
                    Constants.COMPONENT_LABELS.leverInternalInterface),
                description: Constants.COMPONENT_DESCRIPTION.leverInternalInterface,
                cell: item => (item[site === Constants.COMPONENT_NAMES.siteA ?
                    Constants.COMPONENT_NAMES.leverAInternalInterface :
                    Constants.COMPONENT_NAMES.leverZInternalInterface]
                )
            }
        );
    }

    return columnDefinitions;
};

export const generateProviderCircuitColumnDefinitions = () => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item[Constants.ATTRIBUTES.circuitDesignId]}`}>
                    {item[Constants.ATTRIBUTES.circuitDesignNumber]}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.cabling], item.blockers
            )
        },
        {
            id: Constants.COMPONENT_NAMES.providerCircuitA,
            header: "Provider Circuit ID",
            description: "Provider circuit of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.COMPONENT_NAMES.providerCircuitA] :
                    <FremontAutosuggest
                        id={`${Constants.COMPONENT_NAMES.providerCircuitA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        value={item[Constants.COMPONENT_NAMES.providerCircuitA] || ""}
                        onChange={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                        options={item.comprehendProviderCircuitOptions}
                        enteredTextLabel={autosuggestEnteredText} // required field for Autosuggest
                        expandToViewport
                        disableBrowserAutocorrect
                        empty="No suggested values for this provider circuit field"
                    />
            )
        }
    ];
    return columnDefinitions;
};

export const CablingInstallStageDisplayMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                Circuits
            </Header>
        }
        defaultExpanded={!HelperFunctions.isStageCompleted(props.order.stageStatusMap[props.stageName])}
    >
        {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
        <TableDisplayModeTable
            columnDefinitions={generateCablingInstallStageColumnDefinitions(
                props.order[Constants.ATTRIBUTES.serviceType], Constants.COMPONENT_NAMES.siteA,
                props.circuitItems.length, props.order[Constants.ATTRIBUTES.customerFabric]
            )}
            header={
                <Header variant="h2">
                    {Constants.INTERCONNECT_SERVICE_TYPES.includes(
                        props.order[Constants.ATTRIBUTES.serviceType]
                    ) ? "Provider Site: " : "Site A: "}
                    {props.siteNames.siteAName ?
                        <Link href={`${Constants.ROUTES.site}/${props.order.siteAId}`}>
                            {props.siteNames.siteAName}
                        </Link> : "-"
                    }
                </Header>
            }
            circuitItems={props.circuitItems}
            unitItems={props.unitItems}
            isDataLoaded={props.isDataLoaded}
        />
        {!HelperFunctions.isProviderAmazonInternal(props.order.providerName) &&
        <Box padding={{ top: "m" }}>
            {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
            <TableDisplayModeTable
                columnDefinitions={generateProviderCircuitColumnDefinitions()}
                header={
                    <Header variant="h2">
                        Provider Circuits
                    </Header>}
                circuitItems={props.circuitItems}
                unitItems={props.unitItems}
                isDataLoaded={props.isDataLoaded}
            />
        </Box>
        }
        <Box padding={{ top: "m" }}>
            {props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                <TableDisplayModeTable
                    columnDefinitions={generateCablingInstallStageColumnDefinitions(
                        props.order[Constants.ATTRIBUTES.serviceType], Constants.COMPONENT_NAMES.siteZ,
                        props.circuitItems.length, props.order[Constants.ATTRIBUTES.customerFabric]
                    )}
                    header={
                        <Header variant="h2">
                            {"Site Z: "}
                            {props.siteNames.siteZName ?
                                <Link href={`${Constants.ROUTES.site}/${props.order.siteZId}`}>
                                    {props.siteNames.siteZName}
                                </Link> : "-"
                            }
                        </Header>
                    }
                    circuitItems={props.circuitItems}
                    unitItems={props.unitItems}
                    isDataLoaded={props.isDataLoaded}
                />
            }
        </Box>
    </FremontExpandableSection>
);

export const CablingInstallStageEditMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                Circuits
            </Header>
        }
        defaultExpanded
    >
        {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
        <Box padding={{ top: "m" }}>
            {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
            <TableEditModeTable
                columnDefinitions={generateCablingInstallStageColumnDefinitions(
                    props.order[Constants.ATTRIBUTES.serviceType], Constants.COMPONENT_NAMES.siteA,
                    props.circuitItems.length, props.order[Constants.ATTRIBUTES.customerFabric]
                )}
                header={
                    <div>
                        <Header variant="h2">
                            {Constants.INTERCONNECT_SERVICE_TYPES.includes(
                                props.order[Constants.ATTRIBUTES.serviceType]
                            ) ? "Provider Site: " : "Site A: "}
                            {props.siteNames.siteAName ?
                                <Link to={`${Constants.ROUTES.site}/${props.order.siteAId}`}>
                                    {props.siteNames.siteAName}
                                </Link> : "-"
                            }
                        </Header>
                    </div>
                }
                circuitItems={props.circuitItems}
                loading={props.areOptionsLoading}
                massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedCircuitDesignIds}
                handleSelectedFromTable={props.handleSelectedFromTable}
                multiTable
            />
        </Box>
        {!HelperFunctions.isProviderAmazonInternal(props.order.providerName) &&
        <Box padding={{ top: "m" }}>
            {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
            <TableEditModeTable
                columnDefinitions={generateProviderCircuitColumnDefinitions()}
                header={
                    <Header variant="h2">
                        Provider Circuits
                    </Header>
                }
                circuitItems={props.circuitItems}
                loading={props.areOptionsLoading}
                massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedCircuitDesignIds}
                handleSelectedFromTable={props.handleSelectedFromTable}
                multiTable
            />
        </Box>
        }
        <Box padding={{ top: "m" }}>
            {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
            {props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                <TableEditModeTable
                    columnDefinitions={generateCablingInstallStageColumnDefinitions(
                        props.order[Constants.ATTRIBUTES.serviceType], Constants.COMPONENT_NAMES.siteZ,
                        props.circuitItems.length, props.order[Constants.ATTRIBUTES.customerFabric]
                    )}
                    header={
                        <Header variant="h2">
                            {"Site Z: "}
                            {props.siteNames.siteZName ?
                                <Link href={`${Constants.ROUTES.site}/${props.order.siteZId}`}>
                                    {props.siteNames.siteZName}
                                </Link> : "-"
                            }
                        </Header>
                    }
                    circuitItems={props.circuitItems}
                    loading={props.areOptionsLoading}
                    massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedCircuitDesignIds}
                    handleSelectedFromTable={props.handleSelectedFromTable}
                    multiTable
                />
            }
        </Box>
    </FremontExpandableSection>
);

