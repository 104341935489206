import React from "react";
import {
    Box,
    ColumnLayout,
    FormField
} from "@amzn/awsui-components-react/polaris";
import {
    FremontDatePicker,
    FremontSelect,
    FremontToggle,
    optionalLabel,
    requiredLabel
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const CarrierBuild = props => (
    <Box padding={{ top: "m" }}>
        <div>
            <ColumnLayout columns={2} borders="vertical">
                <FormField
                    stretch
                >
                    <FremontToggle
                        id={Constants.ATTRIBUTES.orderSentToSupplier}
                        disabled={props.disabled}
                        checked={props.order[Constants.ATTRIBUTES.orderSentToSupplier]}
                        onChange={props.handleStageInputChange}
                    >
                        {props.editMode
                            ? requiredLabel("All Span Orders sent to Providers:")
                            : "All Span Orders sent to Providers:"}
                    </FremontToggle>
                </FormField>
                {props.editMode ?
                    <div>
                        <FormField
                            label={requiredLabel("Estimated Completion Date for All Span Order Builds:")}
                            stretch
                        >
                            <FremontDatePicker
                                id={Constants.ATTRIBUTES.estimatedCarrierBuildCompletionDate}
                                value={props.order.estimatedCarrierBuildCompletionDate || ""}
                                placeholder={Constants.DATE_PICKER_FORMAT}
                                disabled={props.disabled}
                                onInput={props.handleStageInputChange}
                                onChange={props.handleStageInputChange}
                            />
                        </FormField>
                    </div>
                    :
                    <div>
                        <Box color="text-label">Estimated Completion Date for All Span Order Builds:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.estimatedCarrierBuildCompletionDate]}</div>
                    </div>
                }
            </ColumnLayout>
            <ColumnLayout columns={2} borders="vertical">
                <FormField
                    stretch
                >
                    <FremontToggle
                        id={Constants.ATTRIBUTES.carrierBuildCompleted}
                        checked={props.order[Constants.ATTRIBUTES.carrierBuildCompleted]}
                        disabled={props.disabled}
                        onChange={props.handleStageInputChange}
                    >
                        {props.editMode
                            ? requiredLabel("All Span Order Builds In Topology Completed:")
                            : "All Span Order Builds In Topology Completed:"}
                    </FremontToggle>
                </FormField>
                {props.editMode ?
                    <FormField
                        stretch
                        label={optionalLabel("All Span Orders LOA Disposition:")}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.loaDisposition}
                            options={Constants.LOA_DISPOSITION_OPTIONS}
                            disabled={props.disabled}
                            selectedOption={HelperFunctions.createSelectedOption(props.order.loaDisposition)}
                            onChange={props.handleStageInputChange}
                            expandToViewport
                        />
                    </FormField>
                    :
                    <div>
                        <Box color="text-label">All Span Orders LOA Disposition:</Box>
                        <div>{HelperFunctions.displayLoaDisposition(props.order.loaDisposition)}</div>
                    </div>
                }
            </ColumnLayout>
        </div>
    </Box>
);

export const CarrierBuildEditMode = props => (
    <div>
        <CarrierBuild
            order={props.order}
            handleStageInputChange={props.handleStageInputChange}
            disabled={props.isUpdateStageInProgress || props.allFieldsDisabled}
            editMode
        />
    </div>
);

export const CarrierBuildDisplayMode = props => (
    <div>
        <CarrierBuild
            order={props.order}
            disabled
            editMode={false}
        />
    </div>
);