import React from "react";
import {
    Alert,
    Autosuggest,
    Box,
    Button,
    DatePicker,
    Checkbox,
    ContentLayout,
    ExpandableSection,
    FormField,
    Icon,
    Input,
    Modal,
    Multiselect,
    Select,
    SpaceBetween,
    Textarea,
    Toggle,
    Popover,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";

// Paddings for FremontComponents
export const ComponentConstants = {
    BOX_PAGE_PADDING: "xxl",

    SPACE_BETWEEN_CONTAINER_PADDING: "l",
    SPACE_BETWEEN_STAGES: "s",
    SPACE_BETWEEN_BUTTON_PADDING: "s",
    SPACE_BETWEEN_BUTTON_PADDING_XS: "xs",
    SPACE_BETWEEN_LINK_PADDING: "xxxs"
};

export const useCollectionDefault = inputItems => useCollection(
    inputItems,
    {
        filtering: {},
        pagination: { pageSize: 1000 }, // Making the default ridiculously high since stage tables don't paginate
        sorting: {},
        selection: {}
    }
);

export const optionalLabel = fieldName => (
    <span>
        {fieldName} &nbsp;
        <i style={
            {
                fontSize: "12px",
                color: "#687078"
            }
        }
        >
            (optional)
        </i>
    </span>
);

export const requiredLabel = fieldName => (
    <span>
        {fieldName}
        <span style={
            {
                fontSize: "17.5px",
                color: "red"
            }
        }
        >
            *
        </span>
    </span>
);

export const ProviderSelectComponent = (props) => {
    // Ensuring excludeProvider Provider is not in the options list where applicable (i.e parent provider selection)
    let providerOptions = props.options;
    if (props.options) {
        providerOptions = props.excludeProviders
            ? props.options.filter(prov => !props.excludeProviders.includes(prov.label))
            : props.options;
    }

    return (
        <FormField
            label={props.label}
            errorText={props.errorText}
        >
            <FremontSelect
                id={props.id}
                selectedOption={{ value: props.selectedOption, label: props.selectedOption }}
                statusType={props.providersLoading ? Constants.LOADING : Constants.FINISHED}
                loadingText={Constants.STATUS_TEXTS.loadingProviders}
                // Makes sure that if we have no options we don't set the "options" props at all (not the same as
                // setting it to undefined or empty, which would throw many errors)
                {...(providerOptions && { options: providerOptions })}
                empty={Constants.EMPTY_MESSAGES.provider}
                disabled={props.disabled}
                onChange={props.onChange}
                placeholder={Constants.PROVIDER_AUTOCOMPLETE_PLACEHOLDER_TEXT}
                filteringType="auto"
                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
            />
        </FormField>
    );
};

export const centerContent = content => (
    <span style={
        {
            position: "absolute",
            width: "40%",
            top: "50%",
            left: "50%",
            msTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)"
        }
    }
    >
        {content}
    </span>
);

export const goToComponentButton = (action, ref) => (
    <FremontButton
        iconName="angle-left"
        variant="primary"
        onClick={() => action(ref)}
    >
        Go to Dependency
    </FremontButton>
);

export const FileUploadComponent = props => (
    props.fileName ?
        <div>
            <FremontButton
                id={props.buttonId}
                iconName="upload"
                iconAlign="right"
                onClick={props.onClick}
                disabled={props.isAttachmentSubmissionInProgress}
            >
                Choose file
            </FremontButton>
            <IconWithColoredText
                iconName="status-positive"
                text={` "${props.fileName}" attached!`}
                type={Constants.ICON_TEXT_TYPES.success}
            />
        </div>
        :
        <div key={props.key}>
            <FremontButton
                id={props.buttonId}
                iconName="upload"
                iconAlign="right"
                onClick={props.onClick}
                disabled={props.isAttachmentSubmissionInProgress}
            >
                Choose file
            </FremontButton>
        </div>
);

// item, newComponent, componentName, onInput, onClick, options, disabled)
export const ComponentField = props => (
    props.newComponent ?
        <FormField
            errorText={props.item.errorTexts[props.componentName]}
        >
            <FremontInput
                id={`${props.componentName}${Constants.SEPARATOR}${props.item.circuitDesignId}`}
                value={props.item[props.componentName]}
                disabled={props.disabled}
                onInput={props.onInput}
            />
            <Box padding={{ top: "xs" }}>
                <FremontButton
                    variant="primary"
                    id={`${props.componentName}${Constants.SEPARATOR}${props.item.circuitDesignId}`}
                    onClick={props.onClick}
                >
                    Cancel
                </FremontButton>
            </Box>
        </FormField>
        :
        <FormField>
            <FremontSelect
                id={`${props.componentName}${Constants.SEPARATOR}${props.item.circuitDesignId}`}
                selectionOption={props.item[props.componentName]}
                options={props.options}
                disabled={props.disabled}
                onInput={props.onInput}
            />
            <Box padding={{ top: "xs" }}>
                <FremontButton
                    variant="primary"
                    id={`${props.componentName}${Constants.SEPARATOR}${props.item.circuitDesignId}`}
                    onClick={props.onClick}
                >
                    Add New
                </FremontButton>
            </Box>
        </FormField>
);

export const ConfirmationModal = props => (
    <Modal
        visible={props.isVisible}
        header={props.header}
        onDismiss={props.hideModal}
        footer={
            <Box variant="span" float="right">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        id="modalCancel"
                        variant="normal"
                        onClick={props.hideModal}
                        disabled={!!props.disabled || props.loading}
                    >
                        {props.cancelButtonText}
                    </FremontButton>
                    <FremontButton
                        id="modalSubmit"
                        variant="primary"
                        onClick={props.onClickFunction}
                        disabled={!!props.disabled}
                        loading={props.loading}
                    >
                        {props.primaryButtonText}
                    </FremontButton>
                </SpaceBetween>
            </Box>
        }
    >
        {props.description}
    </Modal>
);

// iconName, type (success, error, secondaryText), text
export const IconWithColoredText = (props) => {
    let variant;
    let textColor;
    if (props.type === Constants.ICON_TEXT_TYPES.success) {
        variant = "success";
        textColor = "text-status-success";
    }
    if (props.type === Constants.ICON_TEXT_TYPES.error) {
        variant = "error";
        textColor = "text-status-error";
    }
    if (props.type === Constants.ICON_TEXT_TYPES.secondaryText) {
        variant = "subtle";
        textColor = "text-body-secondary";
    }

    return (
        <SpaceBetween direction="horizontal" size="xxxs">
            <Icon name={props.iconName} variant={variant}/>
            <Box color={textColor}>{props.text}</Box>
        </SpaceBetween>

    );
};


export const FremontAlert = (props) => {
    const [visible, setVisible] = React.useState(true);
    return (
        <Alert
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={props.header}
            dismissible={!!props.dismissible}
            type={props.type}
            action={props.action}
        >
            {props.children}
        </Alert>
    );
};


/**
 * This method exists as a wrapper method on top of the polaris <Input> method to pass the id parameter
 * and use the onChange method.
 */
export const FremontInput = props => (
    <Input
        {...props}
        onChange={({ detail }) => props.onInput({ detail, target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Textarea> method to pass the id parameter
 * and use the onChange method.
 */
export const FremontTextarea = props => (
    <Textarea
        {...props}
        onChange={({ detail }) => props.onInput({ detail, target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Input> method to pass the id parameter
 * and use the onChange method.
 */
export const FremontToggle = props => (
    <Toggle
        {...props}
        onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
    >
        {props.children}
    </Toggle>
);

/**
 * This method exists as a wrapper method on top of the polaris <DatePicker> method to pass the id parameter.
 */
export const FremontDatePicker = props => (
    <DatePicker
        {...props}
        onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Autosuggest> method to pass the id parameter.
 */
export const FremontAutosuggest = props => (
    <Autosuggest
        {...props}
        onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Select> method to pass the id parameter.
 */
export const FremontSelect = props => (
    <Select
        {...props}
        onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Select> method to pass the id parameter.
 */
export const FremontSelectWithPopover = props => (
    <Popover
        fixedWidth={false}
        position="top"
        size="large"
        triggerType="custom"
        content={
            <StatusIndicator type="info">
                {props.popoverInfo}
            </StatusIndicator>
        }
    >
        <Select
            {...props}
            onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
        />
    </Popover>
);


/**
 * This method exists as a wrapper method on top of the polaris <Multiselect> method to pass the id parameter.
 */
export const FremontMultiselect = props => (
    <Multiselect
        {...props}
        onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <ExpandableSection> method to pass the id parameter.
 */
export const FremontExpandableSection = props => (
    <ExpandableSection
        {...props}
        // ExpandableSection onChange event may not always be defined, since we usually don't use that event so we're
        // accounting for the case where that is not passed in
        onChange={({ detail }) =>
            (props.onChange ? props.onChange({ detail, target: { id: props.id } }) : { target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Button> method to pass the id parameter.
 */
export const FremontButton = props => (
    <Button
        {...props}
        onClick={({ detail }) => props.onClick({ detail, target: { id: props.id } })}
    >
        {props.children}
    </Button>
);

/**
 * This method exists as a wrapper method on top of the polaris <Checkbox> method to pass the id parameter.
 */
export const FremontCheckbox = props => (
    <Checkbox
        {...props}
        onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Box> method.
 */
export const FremontBox = props => (
    <Box margin={{ bottom: props.bottom ? props.bottom : "xxxs" }} color={props.color ? props.color : "text-label"}>
        {props.label}
    </Box>
);

export const FremontTopNavigation = props => (
    <TopNavigation
        {...props}
    />
);

export const FremontContentLayout = props => (
    <ContentLayout
        {...props}
    />
);