import React from "react";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class AuditTableData {
    static auditColumnDefinitions = user => [
        {
            id: "createdTime",
            sortingField: "createdTime",
            header: "Time",
            description: "Time when the audit was created",
            cell: item => HelperFunctions.formatDateAndTime(item.createdTime, Constants.YYYYDDMM_HHMMSS_FORMAT),
            width: 120
        },
        {
            id: "entityType",
            header: "Entity",
            cell: item => item.changedTable,
            width: 150
        },
        {
            id: "createdBy",
            header: "User ID",
            description: "User Id that created the audit",
            cell: item => item.createdBy,
            width: 100
        },
        {
            id: "type",
            header: "Type",
            description: "Type Of Action",
            cell: item => item.actionType,
            width: 90
        },
        {
            id: "changedValues",
            header: "Changes",
            description: "Changed Values",
            cell: item => (
                item.changedValues.map((value, index) => (
                    <li key={`${value.toString()}.${index.toString()}`}>
                        {HelperFunctions.replaceOrderStringsWithOrderLinks(
                            HelperFunctions.hideIdsInAuditMessages(value, user)
                        )}
                    </li>
                ))
            ),
            width: 200
        }
    ];
}