import React, { Component } from "react";

import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    StageEditMode
} from "order/OrderCommonComponents";
import { IpTestingDisplayMode, IpTestingEditMode } from "order/stages/install/IpTestingInformation";

class IpTestingHandler extends Component {
    state = {
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        allFieldsDisabled: false,
        massUpdateSelectedPortIds: [],
        massUpdateSelectedLagIds: [],
        updatedPortTestingObjects: [],
        updatedLagTestingObjects: []
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * Handle edit click to ip testing stage
     * @param evt
     */
    handleStageEditClick = () => {
        const updatedLagTestingObjects = this.generateLagOrPortItems(true).static;
        const updatedPortTestingObjects = this.generateLagOrPortItems(false).static;

        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            updatedLagTestingObjects,
            updatedPortTestingObjects,
            massUpdateSelectedPortIds: [],
            massUpdateSelectedLagIds: [],
            hasBeenSubmittedOnce: false,
            allFieldsDisabled: false,
            isUpdateStageInProgress: false
        });
    }

    /**
     * Handle any edits to the ip testing stage
     * @param evt
     */
    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;

        // We need to determine whether this input change was on lag or port testing table
        // The only way to do so is to use the groupingId and see if it exists in either list
        const uniqueId = input.evt.target.id.split(Constants.SEPARATOR).find(el =>
            el.includes(Constants.UUID_SEPARATOR));
        const portUpdate = this.state.updatedPortTestingObjects.filter(port => port.groupingId === uniqueId).length > 0;

        const transformedObjects = portUpdate ?
            HelperFunctions.deepClone(this.state.updatedPortTestingObjects) :
            HelperFunctions.deepClone(this.state.updatedLagTestingObjects);
        input.circuitDesignObjects = HelperFunctions.deepClone(transformedObjects);
        const { attributeId, index } = CircuitDesignValidation.getIdsAndIndex(input);
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // If we've modified the status or the testIPv4 we need to check
        if ([Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4, Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus]
            .includes(attributeId)) {
            const circuitDesign = circuitDesignOutput.circuitDesignObjects[index];
            // If the amazonTestIPv4 is empty and status is inProgress or Completed show an error
            if (!circuitDesign[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]
                && [Constants.IP_TESTING_ATTRIBUTES.completed, Constants.IP_TESTING_ATTRIBUTES.inProgress]
                    .includes(circuitDesign[Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus])) {
                circuitDesign.errorTexts[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4] =
                    "Amazon Test IPv4 cannot be blank if Status is \"In Progress\" or \"Completed\"";
            } else {
                circuitDesign.errorTexts[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4] =
                    !circuitDesign[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4] ? "" :
                        HelperFunctions.validateIPv4WithoutSubnet(
                            circuitDesign[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]
                        );
            }
        }

        // Do mass update choosing between either lag or port tables
        const output = CircuitDesignValidation.massUpdate(portUpdate ?
            this.state.massUpdateSelectedPortIds : this.state.massUpdateSelectedLagIds, input,
        circuitDesignOutput.circuitDesignObjects);

        if (portUpdate) {
            this.setState({
                updatedPortTestingObjects: output
            });
        } else {
            this.setState({
                updatedLagTestingObjects: output
            });
        }
    };

    /**
     * Handles the selection of rows for mass update for lag table
     * @param evt
     */
    handleSelectedFromTableLag = (evt) => {
        const selectedGroupingIds = evt.detail.selectedItems.map(group => group.groupingId);
        this.setState({
            massUpdateSelectedLagIds: selectedGroupingIds
        });
    };

    /**
     * Handles the selection of rows for mass update for port testing table.
     * @param evt
     */
    handleSelectedFromTablePort = (evt) => {
        const selectedGroupingIds = evt.detail.selectedItems.map(group => group.groupingId);
        this.setState({
            massUpdateSelectedPortIds: selectedGroupingIds
        });
    };

    handleSubmitHelper = (updatedCustomAttributeObject, modifyCustomAttributeList, isLag) => {
        const customAttributeToSubmit = HelperFunctions.deepClone(updatedCustomAttributeObject);
        if (!customAttributeToSubmit.customAttributeId) {
            delete customAttributeToSubmit.customAttributeId;
        }
        customAttributeToSubmit[Constants.IP_TESTING_ATTRIBUTES.entityType] =
            isLag ? Constants.COMPONENT_TYPES.lag : Constants.COMPONENT_TYPES.port;
        customAttributeToSubmit[Constants.IP_TESTING_ATTRIBUTES.entityId] =
            customAttributeToSubmit[Constants.TABLE_IDS.groupingId];

        // The customAttributeValue field is what we need for submission
        const customAttributeValue = {
            [Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus]:
                !updatedCustomAttributeObject[Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus] ?
                    Constants.IP_TESTING_ATTRIBUTES.notStarted :
                    updatedCustomAttributeObject[Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus],
            [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]:
                updatedCustomAttributeObject[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4],
            [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4]:
                updatedCustomAttributeObject[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4],
            [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6]:
                updatedCustomAttributeObject[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6],
            [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6]:
                updatedCustomAttributeObject[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6],
            [Constants.IP_TESTING_ATTRIBUTES.vlanNumber]:
                updatedCustomAttributeObject[Constants.IP_TESTING_ATTRIBUTES.vlanNumber]
        };
        // if the value of a key is undefined/null, then remove it from the customAttributeValue
        Object.keys(customAttributeValue)
            .forEach(key => !customAttributeValue[key] && delete customAttributeValue[key]);
        customAttributeToSubmit[Constants.ATTRIBUTES.customAttributeValue] =
            JSON.stringify(customAttributeValue);
        modifyCustomAttributeList.push(customAttributeToSubmit);
    }

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });

        if (this.state.updatedPortTestingObjects.some(updatedObject =>
            Object.values(updatedObject.errorTexts).some(error => error))) {
            this.setState({
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            return;
        }


        if (this.state.updatedLagTestingObjects.some(updatedUnitObject =>
            Object.values(updatedUnitObject.errorTexts).some(error => error))) {
            this.setState({
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            return;
        }
        try {
            const modifyCustomAttributeList = [];
            this.state.updatedPortTestingObjects.forEach((updatedPortCustomAttributeObject) => {
                this.handleSubmitHelper(updatedPortCustomAttributeObject, modifyCustomAttributeList, false);
            });

            this.state.updatedLagTestingObjects.forEach((updatedLagCustomAttributeObject) => {
                this.handleSubmitHelper(updatedLagCustomAttributeObject, modifyCustomAttributeList, true);
            });

            await this.FremontBackendClient.modifyCustomAttribute(modifyCustomAttributeList, [], this.props.auth);

            // Here we call a helper function which updates all data related to the order
            // and loads new component info as well
            await this.props.loadData(true, true);
            // Resets all input fields to updated state in dynamo
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText);
            this.setState({
                hasBeenSubmittedOnce: false,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    }

    /**
     * This method will convert all the lags and ports used in this order into a common formatted list
     * which can be rendered for the tables on the UI.
     * @param lagOrPort A boolean representing whether caller wants a lag or port version of list.
     */
    generateLagOrPortItems = (isLag) => {
        const circuitItems = {
            static: [],
            dynamic: []
        };
        // The grouping object IDs are a list of either lag or port IDs
        const groupingObjectIds = new Set();
        this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
            const interfaceAComponent = HelperFunctions.findComponent(
                circuitDesignObject.positionMap,
                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA
            );
            if (interfaceAComponent.uuid) {
                groupingObjectIds.add(interfaceAComponent.uuid);
            }
        });

        groupingObjectIds.forEach((groupingObjectId) => {
            this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                // Here we determine whether the groupingId already exists
                // in one of the items in the circuitItemsArray
                const componentItemInArray = circuitItems.static.find(item =>
                    item[Constants.TABLE_IDS.groupingId] === groupingObjectId);
                const isObjectInPositionMap = !!HelperFunctions.findComponentByUUID(
                    circuitDesignObject.positionMap, groupingObjectId
                );
                const status = HelperFunctions.getDisplayValueFromCustomAttribute(
                    this.props.componentIdToObjectMap,
                    circuitDesignObject.positionMap,
                    isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA,
                    Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus
                );
                if (!componentItemInArray && isObjectInPositionMap) {
                    circuitItems.static.push({
                        [Constants.TABLE_IDS.groupingId]: groupingObjectId,
                        [Constants.ATTRIBUTES.circuitDesignId]: groupingObjectId,
                        [Constants.ATTRIBUTES.customAttributeId]:
                            HelperFunctions.getCustomAttributeId(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA
                            ),
                        [Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus]:
                            !status ? Constants.IP_TESTING_ATTRIBUTES.notStarted : status,
                        editable: false,
                        allFieldsDisabled: false,
                        [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            circuitDesignObject.positionMap,
                            Constants.COMPONENT_NAMES.nodeA
                        ),
                        [isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA]:
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA
                            ),
                        [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]:
                            HelperFunctions.getDisplayValueFromCustomAttribute(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA,
                                Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4
                            ),
                        [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4]:
                            HelperFunctions.getDisplayValueFromCustomAttribute(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA,
                                Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4
                            ),
                        [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6]:
                            HelperFunctions.getDisplayValueFromCustomAttribute(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA,
                                Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6
                            ),
                        [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6]:
                            HelperFunctions.getDisplayValueFromCustomAttribute(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA,
                                Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6
                            ),
                        [Constants.IP_TESTING_ATTRIBUTES.vlanNumber]:
                            HelperFunctions.getDisplayValueFromCustomAttribute(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA,
                                Constants.IP_TESTING_ATTRIBUTES.vlanNumber
                            ),
                        errorTexts: HelperFunctions.deepClone(
                            OrderValidation.IP_TESTING_STAGE_ERROR_TEXTS
                        )
                    });
                }
            });
        });

        const array = isLag ? this.state.updatedLagTestingObjects : this.state.updatedPortTestingObjects;

        array.forEach((object) => {
            circuitItems.dynamic.push({
                [Constants.TABLE_IDS.groupingId]: object[Constants.TABLE_IDS.groupingId],
                [Constants.ATTRIBUTES.circuitDesignId]: object[Constants.TABLE_IDS.groupingId],
                [Constants.ATTRIBUTES.customAttributeId]: object[Constants.ATTRIBUTES.customAttributeId],
                errorTexts: this.state.hasBeenSubmittedOnce ? object.errorTexts :
                    OrderValidation.IP_ALLOCATION_STAGE_ERROR_TEXTS,
                // If the IP testing status is not yet set, default it to "Not Started" when in edit mode
                [Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus]:
                    object[Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus],
                [Constants.COMPONENT_NAMES.nodeA]: object[Constants.COMPONENT_NAMES.nodeA],
                [isLag ? Constants.COMPONENT_NAMES.lagA : Constants.COMPONENT_NAMES.portA]:
                    isLag ? object[Constants.COMPONENT_NAMES.lagA] : object[Constants.COMPONENT_NAMES.portA],
                [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]:
                    object[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4],
                [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4]:
                    object[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4],
                [Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6]:
                    object[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6],
                [Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6]:
                    object[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6],
                [Constants.IP_TESTING_ATTRIBUTES.vlanNumber]:
                    object[Constants.IP_TESTING_ATTRIBUTES.vlanNumber],
                editable: this.state.isEditClicked,
                hasStageSubmittedOnce: this.state.hasBeenSubmittedOnce,
                isUpdateStageInProgress: this.state.isUpdateStageInProgress,
                handleStageInputChange: this.handleStageInputChange,
                allFieldsDisabled: this.state.allFieldsDisabled
            });
        });

        return circuitItems;
    }

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.ipTesting}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.props.circuitDesignObjects.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.props.circuitDesignObjects}
                    content={
                        <IpTestingDisplayMode
                            order={this.props.order}
                            componentIdToObjectMap={this.props.componentIdToObjectMap}
                            lagItems={this.generateLagOrPortItems(true).static}
                            portItems={this.generateLagOrPortItems(false).static}
                            isDataLoaded={this.props.isDataLoaded}
                            stageName={Constants.STAGE_NAMES.ipTesting}
                        />
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.ipTesting}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <IpTestingEditMode
                            order={this.props.order}
                            componentIdToObjectMap={this.props.componentIdToObjectMap}
                            lagItems={this.generateLagOrPortItems(true).dynamic}
                            portItems={this.generateLagOrPortItems(false).dynamic}
                            handleSelectedFromTablePort={this.handleSelectedFromTablePort}
                            handleSelectedFromTableLag={this.handleSelectedFromTableLag}
                            massUpdateSelectedPortIds={this.state.massUpdateSelectedPortIds}
                            massUpdateSelectedLagIds={this.state.massUpdateSelectedLagIds}
                        />
                    }
                />
        );
    }
}

export default IpTestingHandler;