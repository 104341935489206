import React from "react";
import {
    Box,
    Container,
    Grid,
    Header,
    Icon,
    Link,
    Popover,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontExpandableSection,
    centerContent,
    useCollectionDefault
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const generateOrderAcceptanceStageColumnDefinitions = (serviceType, lacpProvider) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.ATTRIBUTES.revisionNumber,
            header: "Version",
            description: "description",
            width: Constants.COLUMN_WIDTHS.version,
            cell: item => `V${item[Constants.ATTRIBUTES.revisionNumber]}`
        },
        {
            id: Constants.COMPONENT_NAMES.nodeA,
            header: "Router A",
            description: "description",
            cell: item => item[Constants.COMPONENT_NAMES.nodeA]
        },
        {
            id: Constants.COMPONENT_NAMES.portA,
            header: "Interface A",
            description: "description",
            cell: item => item[Constants.COMPONENT_NAMES.portA]
        }
    ];
    // Using the spread operator to conditionally add the LOA column if we have an interconnect order and
    // the orders LACP provider is true
    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)
        && !!HelperFunctions.parseBoolean(lacpProvider)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "LAG",
                description: "description",
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            }
        );
    }

    return columnDefinitions;
};

/*
 * This function determines whether or not an order can be accepted.
 */
export const isOrderAcceptanceButtonDisabled = (props) => {
    // If the edit buttons are disabled, the data is still loading, or the orderAcceptance stage is not in progress
    // the accept button is disabled
    if (props.editButtonsDisabled || !props.isDataLoaded
        || !HelperFunctions.isStageInProgress(props.order.stageStatusMap[props.stageName])
        || props.order.requiredFieldsCompletedMap[props.stageName] !== Constants.HAS_NOT_BEEN_APPROVED
    ) {
        return true;
    }
    // We want to allow local devs to be able to accept and reject orders
    if (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()) {
        return false;
    }
    if (props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION
        && props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
        // In a backbone decom order, only a Business Development Manager can accept or reject the order
        if (props.user && props.user.permissions
            && ((props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT]
            && props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_BD])
            || props.user.permissions[Constants.POSIX_GROUPS.NEST])) {
            return false;
        }
        // If the user does not have the appropriate permissions, they cannot accept the order
        return true;
    }
    // This is the case where we are not in a backbone decom order, the stage is in progress, and the data is loaded,
    // so the accept button will be enabled
    return false;
};

/*
 * This function determines whether or not an order can be rejected.
 */
export const isOrderRejectionButtonDisabled = (props) => {
    // If the edit buttons are disabled, the data is still loading, or we are in a Change or Decom order
    // and the orderAcceptance stage is not in progress, the reject button is disabled
    if (props.editButtonsDisabled || !props.isDataLoaded
        || (props.order.orderType !== Constants.ORDER_TYPES.INSTALL
            && (!HelperFunctions.isStageInProgress(props.order.stageStatusMap[props.stageName]))
        )) {
        return true;
    }
    // If the order is cancelled, completed, or not been submitted for approval, the reject button is disabled
    if (HelperFunctions.isOrderCancelled(props.order.stageStatusMap)
        || HelperFunctions.isOrderCompleted(props.order.stageStatusMap)
        || !props.order.hasBeenSubmittedForApproval) {
        return true;
    }
    // We want to allow local devs to be able to accept and reject orders
    if (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()) {
        return false;
    }
    if (props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION
        && props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
        // In a backbone decom order, only a Business Development Manager can accept or reject the order
        if (props.user && props.user.permissions
            && ((props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT]
                && props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_BD])
                || props.user.permissions[Constants.POSIX_GROUPS.NEST])) {
            return false;
        }
        // If the user does not have the appropriate permissions, they cannot reject the order
        return true;
    }
    // This is the case where we are not in a backbone decom order, the order is not cancelled, completed, or has yet
    // to submit for approval, and the data is loaded so the reject button will be enabled
    return false;
};

export const OrderAcceptanceStageDisplayMode = (props) => {
    const { items, collectionProps } = useCollectionDefault(props.circuitItems);

    return (
        <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
            <Container
                header={
                    <StageHeader
                        order={props.order}
                        circuitItems={props.circuitItems}
                        stageName={props.stageName}
                        isOrderAcceptance
                        goToComponentAction={props.goToComponentAction}
                    />
                }
            >
                <div
                    className="fremont-stage-spacing"
                >
                    <Grid gridDefinition={[{ colspan: { default: 12 } }]}>
                        <Box margin={{ bottom: "xxxl" }}>
                            {centerContent(
                                <SpaceBetween direction="horizontal" size="l">
                                    <div>
                                        <Box padding={{ left: "s" }}>
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="m"
                                            >
                                                <FremontButton
                                                    id={props.stageName}
                                                    disabled={isOrderAcceptanceButtonDisabled(props)}
                                                    loading={props.isUpdateStageInProgress}
                                                    variant="primary"
                                                    onClick={props.handleOrderAccepted}
                                                >
                                                    Accept
                                                </FremontButton>
                                                {props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION
                                                && props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE
                                                &&
                                                <Popover
                                                    content={
                                                        <Box>Only a Backbone BD Manager can accept this order</Box>
                                                    }
                                                >
                                                    <Icon
                                                        name="status-warning"
                                                        variant="subtle"
                                                    />
                                                </Popover>
                                                }
                                            </SpaceBetween>
                                        </Box>
                                    </div>
                                    <div>
                                        <Box padding={{ left: "s" }}>
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="m"
                                            >
                                                <FremontButton
                                                    id={props.stageName}
                                                    disabled={isOrderRejectionButtonDisabled(props)}
                                                    loading={props.isUpdateStageInProgress}
                                                    variant="primary"
                                                    onClick={props.handleOrderRejected}
                                                >
                                                    Reject
                                                </FremontButton>
                                                {props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION
                                                && props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE
                                                &&
                                                <Popover
                                                    content={
                                                        <Box>Only a Backbone BD Manager can reject this order</Box>
                                                    }
                                                >
                                                    <Icon
                                                        name="status-warning"
                                                        variant="subtle"
                                                    />
                                                </Popover>
                                                }
                                            </SpaceBetween>
                                        </Box>
                                    </div>
                                </SpaceBetween>
                            )}
                        </Box>
                    </Grid>
                </div>
                {props.order.orderType !== Constants.ORDER_TYPES.INSTALL
                && Constants.INTERCONNECT_SERVICE_TYPES.includes(props.order.serviceType) ?
                    <Box margin={{ top: "xs" }}>
                        <FremontExpandableSection
                            variant="container"
                            header={
                                <Header variant="h2">
                                    Circuits
                                </Header>
                            }
                            defaultExpanded={!HelperFunctions.isStageCompleted(props.order
                                .stageStatusMap[props.stageName])}
                        >
                            <Table
                                {...collectionProps}
                                columnDefinitions={generateOrderAcceptanceStageColumnDefinitions(
                                    props.order[Constants.ATTRIBUTES.serviceType],
                                    props.order[Constants.ATTRIBUTES.lacpProvider]
                                )}
                                items={items}
                                empty={
                                    <Box textAlign="center">
                                        <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                            <b>
                                                {Constants.EMPTY_TABLE_MESSAGES.noCircuits}
                                            </b>
                                        </Box>
                                    </Box>
                                }
                                loading={!props.isDataLoaded}
                                loadingText={Constants.STATUS_TEXTS.loadingComponents}
                                resizableColumns
                            />
                        </FremontExpandableSection>
                    </Box> : ""
                }
            </Container>
        </div>
    );
};
