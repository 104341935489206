import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Grid,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import AsnValidation from "asn/AsnValidation";
import HelperFunctions from "common/HelperFunctions";
import Constants from "utils/Constants";
import {
    FremontButton,
    FremontInput,
    FremontSelect,
    FremontMultiselect,
    optionalLabel,
    requiredLabel,
    ProviderSelectComponent,
    ComponentConstants
} from "utils/CommonComponents";

const fetchCallBackLink = providerName =>
    (providerName ? `${Constants.ROUTES.provider}/${providerName}` : Constants.ROUTES.fremontDashboard);

const determineDescriptionText = (allFieldsDisabled, serviceOptions, servicesLoading, providerName) => {
    if (allFieldsDisabled) {
        return "";
    } else if (serviceOptions.length > 0 || servicesLoading || !providerName) {
        return "Select one or more services that this ASN is linked to.";
    }
    return Constants.DESCRIPTION_TEXTS.noServicesForProvider;
};

/**
 * AsnForm is the React component that will handle creating a asn
 */
const AsnForm = props => (
    <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
        <Form
            header={
                <Header
                    variant="h1"
                    description="Fill out the required fields in this form to create an ASN."
                >
                    ASN Creation Form
                </Header>}
            actions={
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    {props.isModal ?
                        <FremontButton
                            variant="normal"
                            disabled={props.isSubmissionInProgress}
                            onClick={props.handleAsnModalClick}
                        >
                            Cancel
                        </FremontButton>
                        :
                        <div>
                            <Link href={fetchCallBackLink(props.providerName)}>
                                <FremontButton
                                    variant="normal"
                                    disabled={props.isSubmissionInProgress}
                                >
                                    Cancel
                                </FremontButton>
                            </Link>
                        </div>
                    }
                    <FremontButton
                        variant="primary"
                        id="asnFormSubmitButton"
                        disabled={props.allFieldsDisabled}
                        loading={props.isSubmissionInProgress}
                        onClick={props.handleAsnSubmit}
                    >
                        {props.isSubmissionInProgress ?
                            Constants.SUBMISSION_STRINGS.submitInProgress :
                            Constants.SUBMISSION_STRINGS.readyToSubmit}
                    </FremontButton>
                </SpaceBetween>
            }
        >
            <Container header={<Header variant="h2">General Information</Header>}>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        label={requiredLabel("Autonomous System Number:")}
                        errorText={props.asnErrorTexts.asnNumber}
                    >
                        <FremontInput
                            id="asnNumber"
                            value={props.asn.asnNumber}
                            disabled={props.allFieldsDisabled || props.providerOptions == null}
                            onInput={props.handleAsnInputChange}
                        />
                    </FormField>
                    <ProviderSelectComponent
                        id="providerName"
                        label={requiredLabel("Provider:")}
                        errorText={props.providerErrorText}
                        selectedOption={props.asn.providerName}
                        providersLoading={props.providersLoading}
                        options={props.providerOptions}
                        disabled={props.allFieldsDisabled || props.providerOptions == null}
                        onChange={props.handleAsnInputChange}
                    />
                    <Box margin={{ top: "xxs" }}>
                        <FormField
                            label={requiredLabel("Service(s):")}
                            description={determineDescriptionText(props.allFieldsDisabled,
                                props.serviceOptions, props.servicesLoading, props.asn.providerName)}
                            errorText={props.asnErrorTexts.providerServiceIdList}
                        >
                            <FremontMultiselect
                                id="providerServiceIdList"
                                statusType={props.servicesLoading ? Constants.LOADING : Constants.FINISHED}
                                loadingText={Constants.STATUS_TEXTS.loadingServices}
                                disabled={(props.serviceOptions.length === 0 && !props.servicesLoading)
                                        || !props.asn.providerName || !!props.providerErrorText
                                        || props.allFieldsDisabled}
                                options={props.serviceOptions}
                                selectedOptions={props.chosenProviderServices}
                                onChange={props.handleAsnInputChange}
                                keepOpen
                            />
                            {!props.isModal &&
                            <Box padding={{ top: "xs" }}>
                                <FremontButton
                                    variant="primary"
                                    id="createProviderServiceButton"
                                    disabled={props.allFieldsDisabled || props.servicesLoading
                                            || props.disableCreateProviderServiceButton}
                                    loading={props.isSubmissionInProgress}
                                    onClick={props.showProviderServiceModal}
                                >
                                            Create Provider Service
                                </FremontButton>
                            </Box>
                            }
                        </FormField>
                    </Box>
                    <FormField
                        label={optionalLabel("Geographic Region(s):")}
                        errorText={props.asnErrorTexts.geographicRegion}
                    >
                        <AsnFormGeographicRegionField
                            addRegionButtonDisabled={props.addRegionButtonDisabled}
                            handleAdditionalRegionInput={props.handleAdditionalRegionInput}
                            handleSubtractRegionInput={props.handleSubtractRegionInput}
                            hasSubmittedOnce={props.hasSubmittedOnce}
                            allFieldsDisabled={props.allFieldsDisabled}
                            asn={props.asn}
                            handleAsnInputChange={props.handleAsnInputChange}
                            asnErrorTexts={props.asnErrorTexts}
                            regionObjects={props.regionObjects}
                        />
                    </FormField>
                    <FormField
                        label={optionalLabel("Peercast Provider Name:")}
                        errorText={props.asnErrorTexts.peercastProviderName}
                    >
                        <FremontInput
                            id="peercastProviderName"
                            value={props.asn.peercastProviderName}
                            disabled={props.allFieldsDisabled}
                            onInput={props.handleAsnInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </Container>
        </Form>
    </div>
);

/**
 * This handles what gets rendered in the region/tier 1 selection section of the asn form
 */
export const AsnFormGeographicRegionField = props => ((
    <div>
        { props.regionObjects.map(regionObject => (
            <div key={regionObject.id}>
                <Grid
                    gridDefinition={[{ colspan: 5 }, { colspan: 5 }, { colspan: 2 }]}
                >
                    <FormField
                        errorText={props.hasSubmittedOnce ? regionObject.errorText : ""}
                        description={Constants.DESCRIPTION_TEXTS.asnRegion}
                    >
                        <FremontSelect
                            id={regionObject.id}
                            disabled={props.allFieldsDisabled}
                            // Limiting the available options to only the regions that have not been chosen yet
                            options={HelperFunctions.createSelectedOptionsWithNone(
                                Object.values(Constants.GEOGRAPHIC_REGIONS)
                            ).filter(availableRegion =>
                                availableRegion.value === regionObject.value ||
                                !props.regionObjects.map(selectedRegion =>
                                    selectedRegion.value).includes(availableRegion.label))}
                            selectedOption={HelperFunctions.createSelectedOption(regionObject.value)}
                            onChange={props.handleAsnInputChange}
                        />
                    </FormField>
                    <FormField
                        errorText={props.hasSubmittedOnce ? regionObject.asnSessionType.errorText : ""}
                        description={Constants.DESCRIPTION_TEXTS.asnSessionType}
                    >
                        <FremontSelect
                            id={regionObject.asnSessionType.id}
                            disabled={props.allFieldsDisabled}
                            options={Constants.ASN_SESSION_TYPES}
                            selectedOption={regionObject.asnSessionType.value ?
                                HelperFunctions.createSelectedOption(regionObject.asnSessionType.value)
                                : {}
                            }
                            onChange={props.handleAsnInputChange}
                        />
                    </FormField>
                    {/* Need to nest the button in FormField so that it aligns (padding is off otherwise) */}
                    <FormField
                        description={Constants.NO_BREAK_SPACE_UNICODE}
                    >
                        <FremontButton
                            iconName="close"
                            variant="normal"
                            onClick={props.handleSubtractRegionInput}
                            disabled={props.allFieldsDisabled || props.regionObjects.length <= 1}
                            id={regionObject.id}
                        />
                    </FormField>
                </Grid>
            </div>
        ))}

        <Box padding={{ top: "xs" }}>
            <FremontButton
                id={AsnValidation.BUTTON_NAMES.addRegionButton}
                iconName="add-plus"
                variant="normal"
                onClick={props.handleAdditionalRegionInput}
                disabled={props.allFieldsDisabled || props.addRegionButtonDisabled}
            />
        </Box>
    </div>
));


export default AsnForm;
