import React from "react";
import {
    Box,
    ColumnLayout,
    Flashbar,
    FormField,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontSelect,
    FremontInput,
    requiredLabel
} from "utils/CommonComponents";
import HelperFunctions from "common/HelperFunctions";
import Constants from "utils/Constants";

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const caps = alpha.map(x => String.fromCharCode(x));
const regions = Object.keys(Constants.GEOGRAPHIC_REGIONS);

const serviceTypeOptions = resourceType =>
    (resourceType === Constants.RESOURCE_TYPES.backBoneEngineer
        ? HelperFunctions.createSelectedOptions([Constants.SERVICE_TYPES.BACKBONE])
        : HelperFunctions.sortObjectsByField(
            HelperFunctions.createSelectedOptions(Object.values(Constants.SERVICE_TYPES)), "label"
        ));

const getResourceNameLabel = (resourceType) => {
    if (resourceType === Constants.RESOURCE_TYPES.provisioner) {
        return "Provisioner:";
    }
    if (resourceType === Constants.RESOURCE_TYPES.businessDeveloper) {
        return "Business Developer:";
    }
    return "Engineer:";
};

const NewResourceModalForm = props => (
    <div>
        <Modal
            visible={props.newResourceModalVisible}
            header="Add a Resource"
            onDismiss={props.handleDismissNewResourceModal}
            size="large"
            footer={
                <Box variant="span" float="right">
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            variant="normal"
                            onClick={props.handleDismissNewResourceModal}
                            disabled={props.isSubmissionInProgress}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id={props.newResource.resourceType}
                            variant="primary"
                            onClick={props.handleSubmitNewResource}
                            loading={props.isSubmissionInProgress}
                        >
                            {Constants.SUBMISSION_STRINGS.readyToSubmit}
                        </FremontButton>
                    </SpaceBetween>
                </Box>
            }
        >
            <Flashbar
                items={props.flashbar.text ?
                    HelperFunctions.generateErrorMessageForFlashbar(props.flashbar.text) : []}
            />
            <ColumnLayout columns={2} borders="vertical">
                <FormField
                    label="Resource Type:"
                >
                    <FremontSelect
                        id="resourceType"
                        selectedOption={HelperFunctions.createSelectedOption(props.newResource.resourceType)}
                        disabled
                    />
                </FormField>
                <FormField
                    label={
                        requiredLabel(getResourceNameLabel(props.newResource.resourceType))
                    }
                    errorText={props.newResourceErrorTexts.resourceName}
                >
                    {props.newResource.resourceType === Constants.RESOURCE_TYPES.provisioner &&
                        <FremontSelect
                            id={Constants.ATTRIBUTES.resourceName}
                            onChange={props.handleNewResourceModalInputChange}
                            options={
                                HelperFunctions.createSelectedOptions(props.posixResources.provisioners)
                            }
                            selectedOption={HelperFunctions.createSelectedOption(props.newResource.resourceName)}
                            disabled={props.isSubmissionInProgress}
                        />
                    }
                    {props.newResource.resourceType === Constants.RESOURCE_TYPES.ipEngineer &&
                        <FremontSelect
                            id={Constants.ATTRIBUTES.resourceName}
                            options={
                                HelperFunctions.createSelectedOptions(props.posixResources.ipEngineers)
                            }
                            selectedOption={HelperFunctions.createSelectedOption(props.newResource.resourceName)}
                            onChange={props.handleNewResourceModalInputChange}
                            disabled={props.isSubmissionInProgress}
                        />
                    }
                    {props.newResource.resourceType === Constants.RESOURCE_TYPES.backBoneEngineer &&
                        <FremontSelect
                            id={Constants.ATTRIBUTES.resourceName}
                            options={
                                HelperFunctions.createSelectedOptions(props.posixResources.backboneEngineers)
                            }
                            selectedOption={HelperFunctions.createSelectedOption(props.newResource.resourceName)}
                            onChange={props.handleNewResourceModalInputChange}
                            disabled={props.isSubmissionInProgress}
                        />
                    }
                    {props.newResource.resourceType === Constants.RESOURCE_TYPES.businessDeveloper &&
                    <FremontSelect
                        id={Constants.ATTRIBUTES.resourceName}
                        options={
                            HelperFunctions.createSelectedOptions(props.posixResources.businessDevelopers)
                        }
                        selectedOption={HelperFunctions.createSelectedOption(props.newResource.resourceName)}
                        onChange={props.handleNewResourceModalInputChange}
                        disabled={props.isSubmissionInProgress}
                    />
                    }
                </FormField>
                <FormField
                    label={requiredLabel("Order Type:")}
                    errorText={props.newResourceErrorTexts.orderType}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.orderType}
                        options={Constants.RESOURCE_ORDER_TYPES_ARRAY}
                        selectedOption={
                            HelperFunctions.createSelectedOption(
                                Constants.BACKEND_TO_FRONTEND_MAP[props.newResource.orderType]
                            )
                        }
                        onChange={props.handleNewResourceModalInputChange}
                        disabled={props.isSubmissionInProgress}
                    />
                </FormField>
                <FormField
                    label={requiredLabel("Service Type:")}
                    errorText={props.newResourceErrorTexts.serviceType}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.serviceType}
                        options={serviceTypeOptions(props.newResource.resourceType)}
                        selectedOption={HelperFunctions.createSelectedOption(props.newResource.serviceType)}
                        disabled={props.isSubmissionInProgress
                            || props.newResource.resourceType === Constants.RESOURCE_TYPES.backBoneEngineer}
                        onChange={props.handleNewResourceModalInputChange}
                    />
                </FormField>
                {props.newResource.resourceType === Constants.RESOURCE_TYPES.provisioner &&
                    <FormField
                        label={requiredLabel("Business Developer:")}
                        errorText={props.newResourceErrorTexts.businessDeveloper}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.businessDeveloper}
                            options={
                                HelperFunctions.createSelectedOptions(props.posixResources.businessDevelopers)
                            }
                            selectedOption={HelperFunctions.createSelectedOption(props.newResource.businessDeveloper)}
                            onChange={props.handleNewResourceModalInputChange}
                            disabled={props.isSubmissionInProgress}
                        />
                    </FormField>
                }
                {props.newResource.resourceType === Constants.RESOURCE_TYPES.ipEngineer &&
                    <FormField
                        label={requiredLabel("Region:")}
                        errorText={props.newResourceErrorTexts.geographicRegion}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.geographicRegion}
                            options={HelperFunctions.createSelectedOptions(regions)}
                            selectedOption={HelperFunctions.createSelectedOption(props.newResource.geographicRegion)}
                            onChange={props.handleNewResourceModalInputChange}
                            disabled={props.isSubmissionInProgress}
                        />
                    </FormField>
                }
                {(props.newResource.resourceType === Constants.RESOURCE_TYPES.ipEngineer
                || props.newResource.resourceType === Constants.RESOURCE_TYPES.provisioner) &&
                    <FormField
                        label={requiredLabel("Filter Start:")}
                        errorText={props.newResourceErrorTexts.filterStart}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.filterStart}
                            options={HelperFunctions.createSelectedOptions(caps)}
                            selectedOption={HelperFunctions.createSelectedOption(props.newResource.filterStart)}
                            onChange={props.handleNewResourceModalInputChange}
                            disabled={props.isSubmissionInProgress}
                        />
                    </FormField>
                }
                {(props.newResource.resourceType === Constants.RESOURCE_TYPES.ipEngineer
                || props.newResource.resourceType === Constants.RESOURCE_TYPES.provisioner) &&
                    <FormField
                        label={requiredLabel("Filter End:")}
                        errorText={props.newResourceErrorTexts.filterEnd}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.filterEnd}
                            options={HelperFunctions.createSelectedOptions(caps)}
                            selectedOption={HelperFunctions.createSelectedOption(props.newResource.filterEnd)}
                            onChange={props.handleNewResourceModalInputChange}
                            disabled={props.isSubmissionInProgress}
                        />
                    </FormField>
                }
                {props.newResource.resourceType === Constants.RESOURCE_TYPES.businessDeveloper &&
                <FormField
                    label="Cluster:"
                    errorText={props.newResourceErrorTexts.cluster}
                >
                    <FremontInput
                        id={Constants.ATTRIBUTES.cluster}
                        value={props.newResource.cluster}
                        onInput={props.handleNewResourceModalInputChange}
                        disabled={props.isSubmissionInProgress}
                    />
                </FormField>
                }
                {props.newResource.resourceType === Constants.RESOURCE_TYPES.businessDeveloper &&
                <FormField
                    label="Regional Default:"
                    errorText={props.newResourceErrorTexts.regionalDefault}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.regionalDefault}
                        options={HelperFunctions.createSelectedOptionsWithNone(regions)}
                        selectedOption={HelperFunctions.createSelectedOption(props.newResource.regionalDefault)}
                        onChange={props.handleNewResourceModalInputChange}
                        disabled={props.isSubmissionInProgress}
                    />
                </FormField>
                }
            </ColumnLayout>
        </Modal>
    </div>
);

export default NewResourceModalForm;