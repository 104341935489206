import React from "react";
import moment from "moment";
import { Box, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import LinkServiceTable from "../components/LinkServiceTable";
import { getAttributesColumn } from "./LinkColumnHelpers";

export default function ({
    linkInstanceId,
    history,
    fetchLinkHistory,
    fetchingHistory,
    linkType,
    firstInstanceVersion
}) {
    const isHistoryTab = true;
    const columnDefinitions = [
        {
            id: "Version",
            header: "Version",
            description: "Version",
            width: 90,
            cell: item => item.instanceVersion
        },
        {
            id: "Actor",
            header: "Actor",
            description: "Actor",
            width: 330,
            cell: item => JSON.stringify(item.actor)
        },
        {
            id: "UpdateTime",
            header: "Update Time",
            description: "Update Time",
            width: 275,
            cell: item => moment.unix(item.updatedAt).utc().format(Constants.DATE_TIME_FORMAT)
        },
        {
            id: "Lifecycle",
            header: "Lifecycle",
            description: "Lifecycle",
            width: 150,
            cell: item => item.lifecycleState
        },
        getAttributesColumn(linkType, isHistoryTab)
    ];

    return (
        <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
            <Box color="text-label" fontSize="heading-m">
                {`Link ID : ${Constants.LINK_INSTANCE_ID_PATTERN}${linkInstanceId}`}
            </Box>
            <LinkServiceTable
                entity="Link History"
                tableItems={history}
                columnDefinitions={columnDefinitions}
                loading={fetchingHistory}
                nextToken={firstInstanceVersion}
                loadMoreEnabled
                onNextPageClickHelper={{
                    nextToken: firstInstanceVersion, fetch: fetchLinkHistory
                }}
            />
        </SpaceBetween>
    );
}
