import { uniq } from "lodash";
import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import { BillingSegmentRecordForm } from "billing/BillingSegmentRecordInformation";
import HelperFunctions from "common/HelperFunctions";
import {
    SearchHandler,
    SearchInformation
} from "common/SearchHandler";

class BillingSegmentRecord extends Component {
    state = {
        queriedBillingObjects: [],
        isQueryForBillingInProgress: false,
        searchParameters: SearchHandler.generateSearchParameters(SearchHandler.TYPES.BillingSegmentRecord),
        selectedQueriedBillingSegments: [],
        billingSegmentsToEditFinalized: [],
        selectedBillingSegmentsToMassUpdate: [],
        isUpdateInProgress: false,
        editMode: true
    };

    /**
     * This function fetches every billing segment based on the query parameters
     */
    getQueriedBillingSegmentRecord = async () => {
        this.setState({
            queriedBillingObjects: [],
            isQueryForBillingInProgress: true,
            billingSegmentsToEditFinalized: [],
            selectedBillingSegmentsToMassUpdate: []
        });

        this.props.handleFlashbarClose();

        try {
            const finalInput = SearchHandler.parseSearchParameters(this.state.searchParameters);
            const searchBillingSegmentsResponse = await this.FremontBackendClient.getBillingSegmentBasedOffSearchParams(
                finalInput,
                this.props.auth
            );

            // Batch get circuits
            const batchCircuits = [];
            searchBillingSegmentsResponse.billingSegments.forEach(billingSegment =>
                batchCircuits.push(billingSegment.circuitDesignId));

            const circuitDesignResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CIRCUIT_DESIGN, batchCircuits, this.props.auth
            );

            const circuitDesignsMap = {};
            circuitDesignResponse.circuitDesigns.forEach((circuitDesign) => {
                circuitDesignsMap[circuitDesign[Constants.ATTRIBUTES.circuitDesignId]] = circuitDesign;
            });

            // We need to augment with the circuit design number, revision number, and lifecycle stage so we can filter
            searchBillingSegmentsResponse.billingSegments.forEach((billingSegment) => {
                const circuitDesign = circuitDesignsMap[billingSegment.circuitDesignId];
                Object.assign(billingSegment, {
                    [Constants.ATTRIBUTES.circuitDesignNumber]: circuitDesign[Constants.ATTRIBUTES.circuitDesignNumber],
                    [Constants.ATTRIBUTES.revisionNumber]: circuitDesign[Constants.ATTRIBUTES.revisionNumber],
                    [Constants.ATTRIBUTES.lifeCycleStage]: circuitDesign[Constants.ATTRIBUTES.lifeCycleStage],
                    [Constants.ATTRIBUTES.serviceType]: circuitDesign[Constants.ATTRIBUTES.serviceType],
                    [Constants.ATTRIBUTES.customerFabric]: circuitDesign[Constants.ATTRIBUTES.customerFabric],
                    [Constants.ATTRIBUTES.orderId]: circuitDesign[Constants.ATTRIBUTES.orderId],
                    [Constants.ATTRIBUTES.providerName]: circuitDesign[Constants.ATTRIBUTES.providerName]
                });
            });

            await this.augmentSearchBillingSegmentsResponseWithProviderCircuitName(
                searchBillingSegmentsResponse, circuitDesignResponse
            );

            this.setState({
                queriedBillingObjects: searchBillingSegmentsResponse.billingSegments,
                isQueryForBillingInProgress: false
            });
        } catch (error) {
            this.setState({
                isQueryForBillingInProgress: false
            });
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarInvalidInput,
                error, false);
        }
    };

    /**
     * Adds providerCircuitName to searchBillingSegmentsResponse. Handles adding the same providerCircuit
     * object to multiple circuitDesign objects who point to the same provider circuit.
     * @param searchBillingSegmentsResponse
     * @param circuitDesignResponse
     * @returns {Promise<void>}
     */
    augmentSearchBillingSegmentsResponseWithProviderCircuitName = async (
        searchBillingSegmentsResponse,
        circuitDesignResponse
    ) => {
        const circuitDesignIdToProviderCircuitIdMap = {};
        circuitDesignResponse.circuitDesigns.forEach((circuitDesign) => {
            const providerCircuitAComponent = HelperFunctions.findComponent(
                circuitDesign[Constants.ATTRIBUTES.positionMap],
                Constants.COMPONENT_NAMES.providerCircuitA
            );
            if (providerCircuitAComponent) {
                circuitDesignIdToProviderCircuitIdMap[circuitDesign.circuitDesignId] =
                    providerCircuitAComponent[Constants.COMPONENT_KEYS.uuid];
            }
        });

        const batchProviderCircuitIds = uniq(Object.values(circuitDesignIdToProviderCircuitIdMap));

        const batchProviderCircuitsResponse = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.PROVIDER_CIRCUIT, batchProviderCircuitIds, this.props.auth
        );

        const providerCircuitsMap = {};
        batchProviderCircuitsResponse.providerCircuits.forEach((providerCircuit) => {
            providerCircuitsMap[providerCircuit[Constants.ATTRIBUTES.providerCircuitId]] = providerCircuit;
        });

        // Augment searchBillingSegmentsResponse with providerCircuitName.
        searchBillingSegmentsResponse.billingSegments.forEach((billingSegment) => {
            // Here we obtain the correct providerCircuit by obtaining the corresponding circuitDesignId that the
            // billing segments circuit points to
            const providerCircuit =
                providerCircuitsMap[circuitDesignIdToProviderCircuitIdMap[billingSegment.circuitDesignId]];
            let providerCircuitName = !providerCircuit
                ? ""
                : providerCircuit[Constants.ATTRIBUTES.providerCircuitName];
            providerCircuitName = !providerCircuitName ? "" : providerCircuitName;
            Object.assign(billingSegment, {
                [Constants.ATTRIBUTES.providerCircuitName]: providerCircuitName
            });
        });
    };

    FremontBackendClient = new FremontBackendClient();

    handleSelectedFromTable = (evt) => {
        // This function is called after user check marks billing segments
        // and adds them to the edit list
        this.setState({
            selectedQueriedBillingSegments: evt.detail.selectedItems
        });
    };

    handleSelectedFromTableForMassUpdate = (evt) => {
        this.setState({
            selectedBillingSegmentsToMassUpdate: evt.detail.selectedItems
        });
    };

    handleAddSelectedBillingToEdit = () => {
        // This function is called after user finalizes their chosen
        // and hits the Add Selected To Edit button
        // so takes the output from handleBillingSelectedFromTable
        const billingSegments = HelperFunctions.deepClone(this.state.selectedQueriedBillingSegments);

        billingSegments.forEach((billingSegment) => {
            Object.assign(billingSegment, { handleBillingInputChange: this.handleBillingInputChange });
        });

        this.setState({
            billingSegmentsToEditFinalized: billingSegments,
            editMode: true
        });
    };

    handleBillingInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.billingSegments = HelperFunctions.deepClone(this.state.billingSegmentsToEditFinalized);
        const billingSegmentOutput = this.validateInput(input);

        // Here we add the input change function back to the billing segment object
        billingSegmentOutput.billingSegments.forEach(billingSegment =>
            Object.assign(billingSegment, { handleBillingInputChange: this.handleBillingInputChange }));

        // Here we create a map of the billing segments that we have modified
        const billingSegmentOutputMap = {};
        billingSegmentOutput.billingSegments.forEach(billingSegment =>
            Object.assign(billingSegmentOutputMap,
                { [billingSegment.billingSegmentId]: billingSegment }));

        // Here we replace the old billingSegment (if it was selected for mass update) with the new
        // one so that the selection process works as expected. In order for a row to render as selected,
        // the tableItem must equal the selectedItem exactly so we just replace the old version of the
        // selectedItem with the new version
        const selectedBillingSegmentsToMassUpdate = this.state.selectedBillingSegmentsToMassUpdate
            .map(billingSegment =>
                billingSegmentOutputMap[billingSegment.billingSegmentId] || billingSegment);

        this.setState({
            billingSegmentsToEditFinalized: billingSegmentOutput.billingSegments,
            selectedBillingSegmentsToMassUpdate
        });
    };

    validateInput = (input) => {
        const output = Object.assign({}, input);
        // To determine the unique id that was passed in from the event, we split the full id on the SEPARATOR
        // constant, the underscore. We now have a list of two elements, one of which is the unique id. Each unique
        // id for a circuitDesign is a uuid which contains a hyphen. We locate the element in the list that has a
        // hyphen, and that is what returns the unique id of the circuitDesign object.
        const uniqueId = output.evt.target.id.split(Constants.SEPARATOR)[1];
        // To find the attributeId, we perform the same operation except that we find the element that does not contain
        // a hyphen, which will always be the attributeId
        const attributeId = output.evt.target.id.split(Constants.SEPARATOR).find(el =>
            !el.includes(Constants.UUID_SEPARATOR));

        // Get the index of the billingSegment we are changing so we can adjust the attribute values and errors
        const index = output.billingSegments.findIndex(billingSegment =>
            billingSegment.billingSegmentId === uniqueId);

        const inputValue = output.evt.detail.value;

        // If the input value is empty, pass null as the value into the blocker object so a backend error doesn't
        // trigger (dynamo doesn't like blank strings)
        // special case for completedDate and startDate as they are used by DatePicker in Blocker Modal
        // DatePicker cannot accept null values, only empty strings.
        if (attributeId === Constants.ATTRIBUTES.billingStartDate ||
            attributeId === Constants.ATTRIBUTES.billingEndDate) {
            output.billingSegments[index][attributeId] = inputValue || "";
        } else {
            output.billingSegments[index][attributeId] = inputValue || null;
        }

        const selectedBillingSegmentIdsToMassUpdate = this.state.selectedBillingSegmentsToMassUpdate
            .map(billingSegment => billingSegment.billingSegmentId);

        // We can do the massupdate here
        output.billingSegments
            .filter(billingSegment =>
                selectedBillingSegmentIdsToMassUpdate.includes(billingSegment.billingSegmentId))
            .map(billingSegment =>
                Object.assign(billingSegment, {
                    [attributeId]: output.billingSegments[index][attributeId]
                }));

        return output;
    }

    handleSubmit = async () => {
        this.setState({
            isUpdateInProgress: true,
            queriedBillingObjects: []
        });

        // take the billingSegmentsToEdit and submit to backend
        try {
            await this.FremontBackendClient.modifyBillingSegment(this.state.billingSegmentsToEditFinalized,
                this.state.selectedQueriedBillingSegments, this.props.auth);
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false, false);
            this.setState({
                editMode: false,
                selectedBillingSegmentsToMassUpdate: []
            });
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarInvalidInput,
                error, false);
        }

        this.setState({
            isUpdateInProgress: false
        });
    }

    render() {
        return (
            <BillingSegmentRecordForm
                isQueryForBillingInProgress={this.state.isQueryForBillingInProgress}
                isUpdateInProgress={this.state.isUpdateInProgress}
                queriedBillingObjects={this.state.queriedBillingObjects}
                handleSelectedFromTable={this.handleSelectedFromTable}
                selectedQueriedBillingSegments={this.state.selectedQueriedBillingSegments}
                handleSelectedFromTableForMassUpdate={this.handleSelectedFromTableForMassUpdate}
                selectedBillingSegmentsToMassUpdate={this.state.selectedBillingSegmentsToMassUpdate}
                billingSegmentsToEditFinalized={this.state.billingSegmentsToEditFinalized}
                handleAddSelectedBillingToEdit={this.handleAddSelectedBillingToEdit}
                handleSubmit={this.handleSubmit}
                isSubmitUpdateInProgress={this.state.isSubmitUpdateInProgress}
                editMode={this.state.editMode}
                searchInformation={
                    <SearchInformation
                        order={null}
                        siteNames={null}
                        searchParameters={this.state.searchParameters}
                        orderCompleted={null}
                        isUpdateInProgress={this.state.isUpdateInProgress}
                        isQueryInProgress={this.state.isQueryForBillingInProgress}
                        stageCompleted={false}
                        setSearchParameters={(searchParameters) => {
                            this.setState({ searchParameters });
                        }}
                        getQueriedObjects={this.getQueriedBillingSegmentRecord}
                        handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                        disableSearchButton={this.state.isQueryForBillingInProgress}
                        disabledFilterFields={["Provider Name", "Service Type"]}
                        disabledFilterInputs={["Service Type"]}
                        providerOptions={this.props.providerOptions}
                        searchParameterOptions={Constants.SEARCH_PARAMETER_OPTIONS_BILLING}
                    />
                }
            />
        );
    }
}

export default BillingSegmentRecord;
