export default class PolarisUtils {
    static OPTION_VALUE_KEY = "value";
    static OPTION_LABEL_KEY = "label";

    /**
     * Helper methods to generate options for Select and Multiselect components.
     * If the labelFieldName is not defined, it will use the same field as the valueFieldName
     * @param items
     * @param valueFieldName
     * @param labelFieldName
     */
    static polarisOptionByField = (items, valueFieldName, labelFieldName) => {
        const labelFieldNameToUse = labelFieldName || valueFieldName;
        return items.map(item => ({
            [PolarisUtils.OPTION_VALUE_KEY]: item[valueFieldName],
            [PolarisUtils.OPTION_LABEL_KEY]: item[labelFieldNameToUse]
        }));
    }
}
