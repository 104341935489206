/* eslint react/no-array-index-key: 0 */

import React from "react";
import {
    Box,
    Container,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontAlert,
    FremontButton,
    ComponentConstants
} from "utils/CommonComponents";
import Constants from "utils/Constants";

export const SiteAGroup = props => (
    <Container header={<Header variant="h2">Site: {props.site}</Header>}>
        <div>
            {props.componentGroupArray.map(componentGroup => (
                <div key={componentGroup.positionMapIndex}>
                    {componentGroup.site === props.site &&
                        <div>
                            {/* TODO make sure the background of this container is light grey */}
                            <Container
                                header={
                                    <Header
                                        variant="h4"
                                        actions={
                                            componentGroup.type === Constants.CIRCUIT_DESIGN_VALUES.customComponent &&
                                            props.editMode &&
                                            <SpaceBetween
                                                direction="horizontal"
                                                size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                            >
                                                <FremontButton
                                                    id={`${componentGroup.positionMapIndex}:${componentGroup.siteGroup}`}
                                                    iconName="close"
                                                    variant="primary"
                                                    onClick={props.deleteCustomComponent}
                                                />
                                                {componentGroup.group !== Constants.COMPONENT_NAMES.fremontCircuit &&
                                                componentGroup.group !== Constants.COMPONENT_NAMES.linkService &&
                                                <FremontButton
                                                    id={`${componentGroup.positionMapIndex}:${componentGroup.siteGroup}`}
                                                    iconName="edit"
                                                    variant="primary"
                                                    onClick={props.editCustomComponent}
                                                />
                                                }
                                            </SpaceBetween>
                                        }
                                    >
                                        {componentGroup.group}
                                    </Header>
                                }
                            >
                                {componentGroup.reactElements.map((reactElement, elementIndex) => (
                                    <div key={`componentGroup${elementIndex}`}>
                                        {reactElement}
                                        <SpaceBetween size="xxs"/>
                                    </div>
                                ))}
                            </Container>

                            <Box padding={{ vertical: "s" }}>
                                {props.editMode &&
                                    <FremontButton
                                        id={`${componentGroup.positionMapIndex + 1}:${componentGroup.siteGroup}`}
                                        onClick={props.addCustomComponent}
                                        iconName="add-plus"
                                        disabled={props.customComponentModalVisible}
                                    />
                                }
                            </Box>
                        </div>
                    }
                </div>
            ))}
        </div>
    </Container>
);

export const SitelessGroup = props => (
    <Box margin={{ left: "xl", right: "xl" }}>
        <div>
            {props.componentGroupArray.map((componentGroup, index) => (
                <div key={componentGroup.positionMapIndex}>
                    {(componentGroup.site === props.siteA && props.componentGroupArray[index + 1]
                        && props.componentGroupArray[index + 1].site !== props.siteA) &&
                        <Box padding={{ vertical: "s" }}>
                            {props.editMode
                            && !Constants.PATH_CUSTOMER_FABRICS.includes(props.circuitDesign.customerFabric) &&
                            <FremontButton
                                id={`${componentGroup.positionMapIndex + 1}:`}
                                onClick={props.addCustomComponent}
                                iconName="add-plus"
                                disabled={props.customComponentModalVisible}
                            />
                            }
                        </Box>
                    }
                    <div>
                        {!componentGroup.site &&
                            <div>
                                <Container
                                    actions={
                                        <FremontButton
                                            iconName="edit"
                                            variant="primary"
                                        />
                                    }
                                    header={
                                        <Header
                                            variant="h2"
                                            actions={
                                                componentGroup.type ===
                                                Constants.CIRCUIT_DESIGN_VALUES.customComponent &&
                                                props.editMode &&
                                                <SpaceBetween
                                                    direction="horizontal"
                                                    size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                                >
                                                    <FremontButton
                                                        id={`${componentGroup.positionMapIndex}:`}
                                                        iconName="close"
                                                        variant="primary"
                                                        onClick={props.deleteCustomComponent}
                                                    />
                                                    {componentGroup.group !==
                                                    Constants.COMPONENT_NAMES.fremontCircuit &&
                                                    componentGroup.group !==
                                                    Constants.COMPONENT_NAMES.linkService &&
                                                    <FremontButton
                                                        id={`${componentGroup.positionMapIndex}:`}
                                                        iconName="edit"
                                                        variant="primary"
                                                        onClick={props.editCustomComponent}
                                                    />
                                                    }
                                                </SpaceBetween>
                                            }
                                        >
                                            {componentGroup.group}
                                        </Header>
                                    }
                                >
                                    {componentGroup.reactElements.map((reactElement, elementIndex) => (
                                        <div key={`componentGroup${elementIndex}`}>
                                            {reactElement}
                                            <SpaceBetween size="xxs"/>
                                        </div>
                                    ))}
                                </Container>
                                <Box padding={{ vertical: "s" }}>
                                    <div>
                                        {props.editMode &&
                                            <FremontButton
                                                id={`${componentGroup.positionMapIndex + 1}:`}
                                                onClick={props.addCustomComponent}
                                                iconName="add-plus"
                                                disabled={props.customComponentModalVisible}
                                            />
                                        }
                                    </div>
                                </Box>
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    </Box>
);

export const SiteZGroup = props => (
    <Container header={<Header variant="h2">Site: {props.site}</Header>}>
        <div>
            {props.componentGroupArray.map((componentGroup, index) => (
                <div key={componentGroup.positionMapIndex}>
                    {componentGroup.site === props.site &&
                        <div>
                            <Box padding={{ vertical: "s" }}>
                                {/* This button goes before the component, id contains the positionMap index and
                                we must lookup the previous component's positionmapIndex (index - 1) and add 1 to it
                                not depend on current component's positionmapindex as it is it could be a combination
                                of multiple positionmap items */}
                                {props.editMode &&
                                <FremontButton
                                    id={`${props.componentGroupArray[index - 1].positionMapIndex + 1}:${componentGroup.siteGroup}`}
                                    onClick={props.addCustomComponent}
                                    iconName="add-plus"
                                    disabled={props.customComponentModalVisible}
                                />
                                }
                            </Box>
                            <Container
                                header={
                                    <Header
                                        variant="h4"
                                        actions={
                                            componentGroup.type === Constants.CIRCUIT_DESIGN_VALUES.customComponent &&
                                            props.editMode &&
                                            <SpaceBetween
                                                direction="horizontal"
                                                size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                            >
                                                <FremontButton
                                                    id={`${componentGroup.positionMapIndex}:${componentGroup.siteGroup}`}
                                                    iconName="close"
                                                    variant="primary"
                                                    onClick={props.deleteCustomComponent}
                                                />
                                                {componentGroup.group !==
                                                Constants.COMPONENT_NAMES.fremontCircuit &&
                                                componentGroup.group !== Constants.COMPONENT_NAMES.linkService &&
                                                <FremontButton
                                                    id={`${componentGroup.positionMapIndex}:${componentGroup.siteGroup}`}
                                                    iconName="edit"
                                                    variant="primary"
                                                    onClick={props.editCustomComponent}
                                                />
                                                }
                                            </SpaceBetween>
                                        }
                                    >
                                        {componentGroup.group}
                                    </Header>
                                }
                            >
                                {componentGroup.reactElements.map((reactElement, elementIndex) => (
                                    <div key={`componentGroup${elementIndex}`}>
                                        {reactElement}
                                        <SpaceBetween size="xxs"/>
                                    </div>
                                ))}
                            </Container>
                        </div>
                    }
                </div>
            ))}
        </div>
    </Container>
);

export const CircuitWithCustomComponents = props => (
    <SpaceBetween direction="vertical" size="s">
        {props.componentGroupArray.length === 0 &&
            <FremontAlert header="All Components Are Empty" type="info">
                {
                    [
                        "This circuit has no values assigned to any component,",
                        " go to the Order Details page to edit this circuit."
                    ].join("")
                }
            </FremontAlert>
        }
        {props.siteA &&
        <div>
            <SiteAGroup
                componentGroupArray={props.componentGroupArray}
                site={props.siteA}
                addCustomComponent={props.addCustomComponent}
                editCustomComponent={props.editCustomComponent}
                deleteCustomComponent={props.deleteCustomComponent}
                editMode={props.editMode}
                customComponentModalVisible={props.customComponentModalVisible}
            />
        </div>
        }
        <div>
            <SitelessGroup
                componentGroupArray={props.componentGroupArray}
                siteA={props.siteA}
                addCustomComponent={props.addCustomComponent}
                editCustomComponent={props.editCustomComponent}
                deleteCustomComponent={props.deleteCustomComponent}
                editMode={props.editMode}
                customComponentModalVisible={props.customComponentModalVisible}
                circuitDesign={props.circuitDesign}
            />
        </div>
        {props.siteZ &&
        <div>
            <SiteZGroup
                componentGroupArray={props.componentGroupArray}
                site={props.siteZ}
                addCustomComponent={props.addCustomComponent}
                editCustomComponent={props.editCustomComponent}
                deleteCustomComponent={props.deleteCustomComponent}
                editMode={props.editMode}
                customComponentModalVisible={props.customComponentModalVisible}
            />
        </div>
        }
    </SpaceBetween>
);
