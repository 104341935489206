import React from "react";
import {
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const generateCostOutColumnDefinitions = (serviceType) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.costOut], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.revisionNumber,
            header: "Version",
            description: "Revision number for this circuit",
            width: Constants.COLUMN_WIDTHS.version,
            cell: item => `V${item[Constants.ATTRIBUTES.revisionNumber]}`
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "Circuit bandwidth for this circuit",
            width: Constants.COLUMN_WIDTHS.bandwidth,
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth]
        },
        {
            id: Constants.ATTRIBUTES.costOutStatus,
            header: "Cost Out Status",
            description: "Cost Out status of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.costOutStatus] :
                    <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.costOutStatus}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={Constants.COMPLETION_OPTIONS}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.costOutStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.isUpdateStageInProgress || item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: Constants.COMPONENT_NAMES.nodeA,
            header: "Router A",
            description: "The nodeA component for this circuit",
            cell: item => item[Constants.COMPONENT_NAMES.nodeA]
        },
        {
            id: Constants.COMPONENT_NAMES.portA,
            header: "Port A",
            description: "The portA component for this circuit",
            cell: item => item[Constants.COMPONENT_NAMES.portA]
        }
    ];

    if (Constants.SERVICE_TYPES.SFP === serviceType) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "LAG A",
                description: "description",
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            }
        );
    }

    if (Constants.SERVICE_TYPES.BACKBONE === serviceType) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.leverAInternalInterface,
                header: "Lever A Internal Interface",
                description: "The leverAInternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverAInternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.leverA,
                header: "Lever A",
                description: "The leverA component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverA]
            },
            {
                id: Constants.COMPONENT_NAMES.leverAExternalInterface,
                header: "Lever A External Interface",
                description: "The leverAExternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverAExternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.leverZExternalInterface,
                header: "Lever A External Interface",
                description: "The leverZExternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverZExternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.leverZ,
                header: "Lever Z",
                description: "The leverZ component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverZ]
            },
            {
                id: Constants.COMPONENT_NAMES.leverZInternalInterface,
                header: "Lever A Internal Interface",
                description: "The leverZInternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverZInternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.portZ,
                header: "Port Z",
                description: "The portZ component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.portZ]
            },
            {
                id: Constants.COMPONENT_NAMES.nodeZ,
                header: "Router Z",
                description: "The nodeZ component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.nodeZ]
            }
        );
    }

    return columnDefinitions;
};

export default generateCostOutColumnDefinitions;