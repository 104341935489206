import React from "react";
import {
    Box,
    ColumnLayout,
    FormField,
    Modal,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import {
    ComponentConstants,
    FremontButton,
    FremontMultiselect,
    ProviderSelectComponent
} from "utils/CommonComponents";

/*
 * This handles what gets rendered in the provider selection section of the contact form
 */
export const ContactFormAdditionalProviderInputs = props => (
    <div>
        {props.providerNameList.map((provider, providerNumber) => (
            <div key={provider}>
                <ColumnLayout columns={2}>
                    <div>
                        <ProviderSelectComponent
                            id={provider}
                            errorText={props.hasSubmittedOnce
                                && providerNumber === props.providerNameList.length - 1 ? props.errorText : ""}
                            providersLoading={props.providersLoading}
                            options={props.providerOptions}
                            excludeProviders={props.providerNameList}
                            disabled={props.providersDisabled
                                || providerNumber !== props.providerNameList.length - 1}
                            selectedOption={provider}
                            onChange={props.handleContactProviderChange}
                        />
                    </div>
                    {props.internal ?
                        <div className="subtractIndividualFieldButton">
                            <FremontButton
                                iconName="close"
                                variant="normal"
                                onClick={props.handleSubtractSpecificProviderName}
                                disabled={props.providersDisabled || props.isSubmissionInProgress
                                || props.providerNameList.length <= 1 || props.providerInfoLoading}
                                className={provider}
                            />
                        </div> : props.providerInfoLoading && <Spinner/>
                    }
                </ColumnLayout>
            </div>
        ))}
        {props.internal ?
            <Box padding={{ top: "xs" }}>
                <FremontButton
                    iconName="add-plus"
                    variant="normal"
                    onClick={props.handleAddAdditionalProviderInput}
                    disabled={props.providersDisabled || props.isSubmissionInProgress
                    || props.disableAddProviderButton}
                    loading={props.providerInfoLoading}
                />
            </Box>
            : null }
    </div>
);

/*
 * This handles what gets rendered in the provider service selection section of the contact form
 */
export const ContactFormProviderServices = props => (
    <ColumnLayout>
        {props.providerNameList[Constants.MAP_INDEXES.keyIndex] ?
            props.providerNameList.map(provider => (
                <div key={provider}>
                    {props.serviceOptions[provider] ?
                        <FormField
                            label={`Supported Provider Services for ${provider}:`}
                            description={props.serviceOptions[provider].options.length ?
                                "" : Constants.DESCRIPTION_TEXTS.noServicesForProvider}
                        >
                            <FremontMultiselect
                                id={provider}
                                disabled={props.allFieldsDisabled ||
                                !props.serviceOptions[provider].options.length}
                                options={props.serviceOptions[provider].options}
                                selectedOptions={props.serviceOptions[provider].selectedOptions}
                                onChange={props.handleServiceOptionsChange}
                                keepOpen
                            />
                        </FormField> : null
                    }
                </div>
            ))
            : "Please enter a provider."
        }
    </ColumnLayout>
);

/*
 * This handles what gets rendered in the provider service selection section of the contact form
 */
export const ContactFormSites = props => (
    <ColumnLayout>
        {Object.keys(props.siteMap).length > 0 ?
            Object.keys(props.siteMap)
                .map(providerName => (
                    <div key={providerName}>
                        <FormField
                            label={`Supported Sites for ${providerName}:`}
                            description="Select countries and regions that this contact supports for each provider."
                            stretch
                        >
                            <ColumnLayout columns={2}>
                                <FormField
                                    label={
                                        <SpaceBetween
                                            direction="horizontal"
                                            size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                        >
                                            Regions Supported:
                                            {
                                                HelperFunctions.renderCustomTooltip(
                                                    Constants.TOOLTIP_LINKS.regionsSupported,
                                                    Constants.TOOLTIP_STRINGS.regionsSupported
                                                )
                                            }
                                        </SpaceBetween>
                                    }
                                >
                                    <FremontMultiselect
                                        id={`${Constants.ATTRIBUTES.regionsSupported}:${providerName}`}
                                        options={HelperFunctions.createSelectedOptions(
                                            Object.values(Constants.GEOGRAPHIC_REGIONS)
                                        )}
                                        disabled={props.allFieldsDisabled}
                                        selectedOptions={HelperFunctions.createSelectedOptions(props.siteMap[
                                            providerName][Constants.ATTRIBUTES.regionsSupported])}
                                        onChange={props.handleContactInputChange}
                                        keepOpen
                                    />
                                </FormField>
                                <FormField
                                    label={
                                        <SpaceBetween
                                            direction="horizontal"
                                            size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                        >
                                            Countries Supported:
                                            {
                                                HelperFunctions.renderCustomTooltip(
                                                    Constants.TOOLTIP_LINKS.countriesSupported,
                                                    Constants.TOOLTIP_STRINGS.countriesSupported
                                                )
                                            }
                                        </SpaceBetween>
                                    }
                                >
                                    <FremontMultiselect
                                        id={`${Constants.ATTRIBUTES.countriesSupported}:${providerName}`}
                                        disabled={props.allFieldsDisabled}
                                        options={HelperFunctions.getCountryOptions()}
                                        selectedOptions={HelperFunctions.createSelectedOptions(props.siteMap[
                                            providerName][Constants.ATTRIBUTES.countriesSupported])}
                                        onChange={props.handleContactInputChange}
                                        keepOpen
                                        filteringType="auto"
                                        noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                    />
                                </FormField>
                            </ColumnLayout>
                        </FormField>
                    </div>
                ))
            : "Please enter a provider."}
    </ColumnLayout>
);

export const ContactAlertModal = props => (
    <Modal
        visible={props.showContactAlertModal}
        size="medium"
        onDismiss={props.handleContactAlertCancel}
        footer={
            <Box variant="span" float="right">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        id="cancel"
                        variant="normal"
                        onClick={props.handleContactAlertCancel}
                    >
                        Cancel
                    </FremontButton>
                    <FremontButton
                        id="okay"
                        variant="primary"
                        onClick={props.handleContactAlertOkay}
                    >
                        Okay
                    </FremontButton>
                </SpaceBetween>
            </Box>
        }
    >
        <Box>
            Changing a contact to external removes all providers except the first one and all
                of their related information (supported sites and services). Do you still wish to proceed?
        </Box>
    </Modal>
);