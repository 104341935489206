import React from "react";
import {
    Modal
} from "@amzn/awsui-components-react/polaris";
import SiteCreatePage from "site/SiteCreatePage";

const SiteModal = props => (
    <Modal
        visible={props.isAddSiteClicked}
        header="Add site"
        onDismiss={props.handleSiteModalClick}
        size="large"
    >
        {props.isAddSiteClicked ? (
            <SiteCreatePage
                match={props.match}
                auth={props.auth}
                isModal
                serviceType={props.serviceType}
                handleSiteModalClick={props.handleSiteModalClick}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                providerOptions={props.providerOptions}
                providersLoading={props.providersLoading}
            />)
            :
            <span/>
        }
    </Modal>
);

export default SiteModal;