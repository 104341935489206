import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";
import { Badge, Popover, StatusIndicator } from "@amzn/awsui-components-react";

export const getLegacyOrActiveOrderBadge = (order) => {
    // For now, to be consistent with the message shown in the OrderDetailsPage, we're going to show the badge only if
    // we have the containsLatestActiveRevisions field in the order's meta AND if the order is completed
    if (HelperFunctions.isOrderCompleted(order.stageStatusMap)) {
        if (order.containsLatestActiveRevisions === true) {
            return (
                <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={
                        <StatusIndicator type="success" colorOverride="grey">
                            {Constants.CIRCUIT_HAS_NO_FUTURE_ACTIVE_REVISIONS}
                        </StatusIndicator>
                    }
                >
                    <Badge color="green" className="badge-hover">Latest</Badge>
                </Popover>
            );
        }
        if (order.containsLatestActiveRevisions === false) {
            return (
                <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={
                        <StatusIndicator type="info" colorOverride="grey">
                            {Constants.CIRCUIT_HAS_FUTURE_ACTIVE_REVISIONS}
                        </StatusIndicator>
                    }
                >
                    <Badge color="grey" className="badge-hover">Legacy</Badge>
                </Popover>
            );
        }
    }
    return null; // If we don't have this information, just return nothing
};

export default class OrderTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "orderId",
            sortingField: "orderId",
            sortingComparator: (order1, order2) => HelperFunctions.sortIdsNumerically(order1, order2, "orderId"),
            header: "Order ID",
            description: "Order Identifier",
            cell: item => (
                <div>
                    <Link href={`${Constants.ROUTES.order}/${item.orderId}`}>
                        {item.orderId}
                    </Link>
                    {getLegacyOrActiveOrderBadge(item)}
                </div>
            ),
            width: 135
        },
        {
            id: "priorityType",
            sortingField: "priorityType",
            header: "Priority",
            description: "Order Priority",
            cell: item => item.priorityType
        },
        {
            id: "externalOrderId",
            sortingField: "externalOrderId",
            sortingComparator: (order1, order2) => HelperFunctions.sortIdsNumerically(order1, order2, "ncisLabel"),
            header: "NCIS ID",
            description: "NCIS Order Identifier",
            cell: item => (HelperFunctions.hasNCISMeta(item.meta)
                ? <Link external href={item.ncisUrl}>{item.ncisLabel}</Link>
                : "")
        },
        {
            id: "orderStatus",
            sortingField: "objectStatusColumnSearchable",
            header: "Status",
            width: 150,
            description: "Indicates whether the order is complete",
            cell: item => item.objectStatusColumnIcon
        },
        {
            id: "orderStagesCompleted",
            sortingField: "orderStagesCompleted",
            sortingComparator: (order1, order2) => {
                const isOrder1Completed = HelperFunctions.isOrderCompleted(order1.stageStatusMap);
                const isOrder2Completed = HelperFunctions.isOrderCompleted(order2.stageStatusMap);
                if (isOrder1Completed && !isOrder2Completed) {
                    return 1;
                }
                if (!isOrder1Completed && isOrder2Completed) {
                    return -1;
                }
                const order1NumStagesCompleted = Object.keys(order1.stageStatusMap)
                    .filter(stageName => HelperFunctions.isStageCompleted(order1.stageStatusMap[stageName])).length;
                const order2NumStagesCompleted = Object.keys(order2.stageStatusMap)
                    .filter(stageName => HelperFunctions.isStageCompleted(order2.stageStatusMap[stageName])).length;
                return order1NumStagesCompleted - order2NumStagesCompleted;
            },
            header: "Stages Completed",
            description: "Indicates how many stages are complete",
            width: 130,
            cell: (item) => {
                if (HelperFunctions.isEmpty(item)) {
                    return "-";
                }
                const numberOfStages = Object.keys(item.stageStatusMap).length;
                const numberOfCompletedStages = Object.keys(item.stageStatusMap)
                    .filter(stageName => HelperFunctions.isStageCompleted(item.stageStatusMap[stageName])).length;
                if (numberOfStages === numberOfCompletedStages) {
                    return "-";
                }
                return `${numberOfCompletedStages} out of ${numberOfStages}`;
            }
        },
        {
            id: "orderType",
            sortingField: "orderType",
            header: "Order Type",
            description: "Type of order",
            cell: item => Constants.BACKEND_TO_FRONTEND_MAP[item.orderType]
        },
        {
            id: "serviceType",
            sortingField: "serviceType",
            sortingComparator: (order1, order2) => {
                const order1ServiceTypeToDisplay = HelperFunctions.getOrderServiceTypeCustomerFabricString(order1);
                const order2ServiceTypeToDisplay = HelperFunctions.getOrderServiceTypeCustomerFabricString(order2);
                return order1ServiceTypeToDisplay.localeCompare(order2ServiceTypeToDisplay);
            },
            header: "Service Type",
            description: "Type of service",
            cell: item => HelperFunctions.getOrderServiceTypeCustomerFabricString(item)
        },
        {
            id: "providerName",
            sortingField: "providerName",
            header: "Provider Name",
            description: "Name of provider associated with this order",
            cell: item => <Link href={`${Constants.ROUTES.provider}/${item.providerName}`}>{item.providerName}</Link>
        },
        {
            id: "asnNumber",
            sortingField: "asnNumber",
            sortingComparator: (order1, order2) => HelperFunctions.sortNumberStrings(order1, order2, "asnNumber"),
            header: "ASN",
            description: "Asn number associated with this order",
            cell: item => <Link href={`${Constants.ROUTES.asn}/${item.asnId}`}>{item.asnNumber}</Link>
        },
        {
            id: "siteA",
            sortingField: "siteAName",
            header: "Site A",
            description: "Site A associated with this order",
            cell: item => <Link href={`${Constants.ROUTES.site}/${item.siteAId}`}>{item.siteAName}</Link>
        },
        {
            id: "siteZ",
            sortingField: "siteZName",
            header: "Site Z",
            description: "Site Z associated with this order",
            cell: item => <Link href={`${Constants.ROUTES.site}/${item.siteZId}`}>{item.siteZName}</Link>
        },
        {
            id: "circuitQuantity",
            sortingField: "circuitQuantity",
            header: "Circuit Quantity",
            description: "Number of Circuits on this Order",
            cell: item => item.circuitQuantity
        },
        {
            id: "businessNeed",
            sortingField: "businessNeed",
            header: "Business Need",
            description: "Business Need",
            cell: item => item.businessNeed
        },
        {
            id: "provisionerId",
            sortingField: "provisionerId",
            header: "Provisioner",
            description: "Provisioner user",
            cell: item => item.provisionerId
        },
        {
            id: "engineerId",
            sortingField: "engineerId",
            header: "Engineer",
            description: "Engineer user",
            cell: item => item.engineerId
        },
        {
            id: "businessOperationsId",
            sortingField: "businessOperationsId",
            header: "Business Operations",
            description: "Business operations user",
            cell: item => item.businessOperationsId
        }
    ];

    /**
     * Fetch related items information to display
     */
    static fetchOrderRelatedObjects = async (orderItems, auth, handleFlashBarMessagesFromChildTabs) => {
        const fremontBackendClient = new FremontBackendClient();
        const orders = HelperFunctions.deepClone(orderItems);
        try {
            const orderIds = orders.map(order => order.orderId);
            const orderResponse = await fremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.ORDER, orderIds, auth, true, true
            );

            // Create a map of the orders, the sites, and the asns returned
            const orderMap = {};
            const siteMap = {};
            const asnMap = {};

            if (orderResponse.orders) {
                orderResponse.orders.forEach(order => Object.assign(orderMap, { [order.orderId]: order }));
                orders.forEach((order) => {
                    const { orderId } = order;
                    if (HelperFunctions.hasOrderTransientFields(orderMap[orderId])) {
                        Object.assign(order, {
                            containsLatestActiveRevisions:
                                JSON.parse(orderMap[orderId].meta.transientFields).containsLatestActiveRevisions
                        });
                    }
                });
            }
            if (orderResponse.sites) {
                orderResponse.sites.forEach(site => Object.assign(siteMap, { [site.siteId]: site }));
                orders.forEach((order) => {
                    if (order.siteAId && siteMap[order.siteAId]) {
                        Object.assign(order, { siteAName: siteMap[order.siteAId].siteName });
                    }
                    if (order.siteZId && siteMap[order.siteZId]) {
                        Object.assign(order, { siteZName: siteMap[order.siteZId].siteName });
                    }
                });
            }
            if (orderResponse.asns) {
                orderResponse.asns.forEach(asn => Object.assign(asnMap, { [asn.asnId]: asn }));
                orders.forEach((order) => {
                    if (order.asnId && asnMap[order.asnId]) {
                        Object.assign(order, { asnNumber: asnMap[order.asnId].asnNumber });
                    }
                });
            }
        } catch (error) {
            // This is used for showing the flashbar error message on the parent page
            handleFlashBarMessagesFromChildTabs(false, error, false);
        }
        return orders;
    }

    static fetchCreateOrderLink = providerName =>
        (providerName ? `${Constants.ROUTES.createOrder}/${providerName}` : Constants.ROUTES.createOrder);
}