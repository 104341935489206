import React from "react";
import { PropertyFilter } from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import { getFilterCounterText } from "../table/FremontTable";

export default function OrderTablePropertyFilter({
    setQuery, query, tableItems
}) {
    const onFilterChange = ({ detail }) => {
        setQuery(detail);
    };
    return (
        <PropertyFilter
            onChange={onFilterChange}
            query={query}
            hideOperations
            i18nStrings={Constants.PROPERTY_FILTER_STRINGS}
            expandToViewport
            countText={getFilterCounterText(tableItems.length)}
            filteringOptions={[
                {
                    propertyKey: Constants.POLARIS_FILTER_PROPERTY_KEYS.ORDER_STATUS,
                    value: Constants.POLARIS_FILTER_VALUES.cancelled
                },
                {
                    propertyKey: Constants.POLARIS_FILTER_PROPERTY_KEYS.ORDER_STATUS,
                    value: Constants.POLARIS_FILTER_VALUES.completed
                }
            ]}
            filteringProperties={[
                {
                    key: Constants.POLARIS_FILTER_PROPERTY_KEYS.ORDER_STATUS,
                    ...Constants.POLARIS_FILTER_PROPERTIES[Constants.POLARIS_FILTER_PROPERTY_KEYS.ORDER_STATUS]
                }
            ]}
        />
    );
}