import React, { Component } from "react";
import {
    Box,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontExpandableSection
} from "utils/CommonComponents";
import FremontBackendClient from "common/FremontBackendClient";
import AsnTable from "asn/AsnTable";
import AsnTableData from "asn/AsnTableData";
import CircuitDesignTable from "circuitDesign/CircuitDesignTable";
import Constants from "utils/Constants";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import OrderTable from "order/OrderTable";
import OrderTableData from "order/OrderTableData";
import ProviderTable from "provider/ProviderTable";
import SiteTable from "site/SiteTable";

/**
 * A component to display the search results from FremontGlobalSearch
 */
export default class SearchPage extends Component {
    state = {
        entityType: this.props.history.location.state && this.props.history.location.state.entityType
            ? this.props.history.location.state.entityType : "",
        subString: this.props.history.location.state && this.props.history.location.state.subString
            ? this.props.history.location.state.subString : "",
        searchResults: this.props.history.location.state && this.props.searchResults
            ? this.props.searchResults : {},
        augmentedSearchResults: this.props.history.location.state && this.props.searchResults
            ? this.props.searchResults : {},
        isExpanded: {
            [Constants.SEARCH_OBJECTS.orders]: false,
            [Constants.SEARCH_OBJECTS.circuitDesigns]: false,
            [Constants.SEARCH_OBJECTS.providers]: false,
            [Constants.SEARCH_OBJECTS.sites]: false,
            [Constants.SEARCH_OBJECTS.asns]: false
        },
        flashbar: {}
    }

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else if (this.props.searchResults) {
            this.augmentSearchResults();
        }
    };

    componentDidUpdate = async () => {
        if (!this.props.history.location.state) {
            return;
        }

        if (this.props.history.location.state.entityType !== this.state.entityType
            || this.props.history.location.state.subString !== this.state.subString
            || JSON.stringify(this.props.searchResults) !== JSON.stringify(this.state.searchResults)) {
            await this.setState({
                entityType: this.props.history.location.state.entityType,
                subString: this.props.history.location.state.subString,
                searchResults: this.props.searchResults
            });
            this.augmentSearchResults();
        }
    };

    augmentSearchResults = async () => {
        const searchResults = HelperFunctions.deepClone(this.props.searchResults);
        // If there is only one search object with results, expand it
        let searchObject;
        const objectsWithResults = Object.keys(searchResults).filter(key =>
            searchResults[key].length > 0);
        if (objectsWithResults.length === 1) {
            [searchObject] = objectsWithResults;
        }
        if (searchObject) {
            const isExpanded = HelperFunctions.deepClone(this.state.isExpanded);
            isExpanded[searchObject] = true;
            this.setState({ isExpanded });
        }

        // Need to fetch circuitDesigns (and potentially other objects) for these components
        if (searchResults[Constants.SEARCH_OBJECTS.asns]
            && searchResults[Constants.SEARCH_OBJECTS.asns].length > 0) {
            searchResults[Constants.SEARCH_OBJECTS.asns] = await AsnTableData.fetchAsnRelatedObjects(
                searchResults[Constants.SEARCH_OBJECTS.asns],
                this.props.auth,
                this.handleFlashBarMessagesFromChildTabs
            );
        }
        if (searchResults[Constants.SEARCH_OBJECTS.orders]
            && searchResults[Constants.SEARCH_OBJECTS.orders].length > 0) {
            searchResults[Constants.SEARCH_OBJECTS.orders] = await OrderTableData.fetchOrderRelatedObjects(
                searchResults[Constants.SEARCH_OBJECTS.orders],
                this.props.auth,
                this.handleFlashBarMessagesFromChildTabs
            );
        }
        this.setState({
            augmentedSearchResults: searchResults
        });
    }

    FremontBackendClient = new FremontBackendClient();

    handleExpand = async (evt) => {
        const isExpanded = HelperFunctions.deepClone(this.state.isExpanded);
        isExpanded[evt.target.id] = evt.detail.expanded;
        this.setState({
            isExpanded
        });
    };

    /**
     * Gets the correct table to display the search results
     * @param searchResults entries for the table
     */
    formatSearchResults = (searchResults) => {
        if (!Object.keys(searchResults).some(key => searchResults[key].length !== 0)) {
            return (
                <span>No search results, please search again</span>
            );
        }
        const searchResultOrder = [
            Constants.SEARCH_OBJECTS.orders,
            Constants.SEARCH_OBJECTS.circuitDesigns,
            Constants.SEARCH_OBJECTS.providers,
            Constants.SEARCH_OBJECTS.sites,
            Constants.SEARCH_OBJECTS.asns
        ];
        return (
            <SpaceBetween size="m">
                {
                    searchResultOrder
                        .filter(searchObject => searchResults[searchObject] && searchResults[searchObject].length > 0)
                        .map((searchObject) => {
                            switch (searchObject) {
                            case Constants.SEARCH_OBJECTS.asns:
                                return (
                                    <FremontExpandableSection
                                        header={
                                            <Header variant="h2">
                                                {`ASNs (Results ${searchResults[searchObject].length})`}
                                            </Header>
                                        }
                                        variant="container"
                                        expanded={this.state.isExpanded[searchObject]}
                                        key={searchObject}
                                        id={searchObject}
                                        onChange={this.handleExpand}
                                    >
                                        <AsnTable
                                            loading={false}
                                            items={searchResults[searchObject]}
                                        />
                                    </FremontExpandableSection>
                                );
                            case Constants.SEARCH_OBJECTS.circuitDesigns:
                                return (
                                    <FremontExpandableSection
                                        header={
                                            <Header variant="h2">
                                                {`Circuit Designs (Results ${searchResults[searchObject].length})`}
                                            </Header>
                                        }
                                        variant="container"
                                        expanded={this.state.isExpanded[searchObject]}
                                        key={searchObject}
                                        id={searchObject}
                                        onChange={this.handleExpand}
                                    >
                                        <CircuitDesignTable
                                            loading={false}
                                            items={searchResults[searchObject]}
                                        />
                                    </FremontExpandableSection>
                                );
                            case Constants.SEARCH_OBJECTS.orders:
                                return (
                                    <FremontExpandableSection
                                        header={
                                            <Header variant="h2">
                                                {`Orders (Results ${searchResults[searchObject].length})`}
                                            </Header>
                                        }
                                        variant="container"
                                        expanded={this.state.isExpanded[searchObject]}
                                        key={searchObject}
                                        id={searchObject}
                                        onChange={this.handleExpand}
                                    >
                                        <OrderTable
                                            showOnlyHeaderDescription
                                            searchTitle="Search Results"
                                            emptyTableMessage={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                            emptySearchMessage=""
                                            loading={false}
                                            items={searchResults[searchObject]}
                                            tablePageSize={50}
                                        />
                                    </FremontExpandableSection>
                                );
                            case Constants.SEARCH_OBJECTS.providers:
                                return (
                                    <FremontExpandableSection
                                        header={
                                            <Header variant="h2">
                                                {`Providers (Results ${searchResults[searchObject].length})`}
                                            </Header>
                                        }
                                        variant="container"
                                        expanded={this.state.isExpanded[searchObject]}
                                        key={searchObject}
                                        id={searchObject}
                                        onChange={this.handleExpand}
                                    >
                                        <ProviderTable
                                            loading={false}
                                            items={searchResults[searchObject]}
                                        />
                                    </FremontExpandableSection>
                                );
                            case Constants.SEARCH_OBJECTS.sites:
                                return (
                                    <FremontExpandableSection
                                        header={
                                            <Header variant="h2">
                                                {`Sites (Results ${searchResults[searchObject].length})`}
                                            </Header>
                                        }
                                        variant="container"
                                        expanded={this.state.isExpanded[searchObject]}
                                        key={searchObject}
                                        id={searchObject}
                                        onChange={this.handleExpand}
                                    >
                                        <SiteTable
                                            loading={false}
                                            items={searchResults[searchObject]}
                                        />
                                    </FremontExpandableSection>
                                );
                            default:
                                // This is actually unreachable so not sure how to test it
                                return (
                                    <span>Something went wrong, please search again</span>
                                );
                            }
                        })
                }
            </SpaceBetween>
        );
    }

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    render() {
        return (
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                <Box margin={{ horizontal: "s", bottom: "m" }} padding={{ top: "xs" }}>
                    {this.formatSearchResults(this.state.augmentedSearchResults)}
                </Box>
            </div>
        );
    }
}