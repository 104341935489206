import React from "react";
import FremontTable from "table/FremontTable";
import ProviderServiceTableData from "providerService/ProviderServiceTableData";

export default function ProviderServiceTable(props) {
    return (
        <FremontTable
            entity="Provider Service"
            createEntityLink={ProviderServiceTableData.fetchCreateProviderServiceLink(props.providerName)}
            columnDefinitions={ProviderServiceTableData.COLUMN_DEFINITIONS}
            tableItems={props.items}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            onNextPageClickHelper={{
                nextToken: props.nextToken, fetch: async () => props.fetchAllProviderServiceItems()
            }}
            nextToken={props.nextToken}
        />
    );
}