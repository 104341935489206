import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class AsnValidation {
    static CHECKBOX = "checkbox";

    static BUTTON_NAMES = {
        addRegionButton: "addRegionButton",
        subtractRegionButton: "subtractRegionButton"
    };

    static UPDATE_ASN_SERVICE_ERROR_TEXT = "An ASN must have at least one provider service.";

    static DEFAULT_ASN_ERROR_TEXTS = {
        asnNumber: Constants.ERROR_STRINGS.blankInput,
        providerServiceIdList: Constants.ERROR_STRINGS.blankInput,
        providerName: Constants.ERROR_STRINGS.blankInput,
        peercastProviderName: "",
        peeringDB: "",
        peercast: "",
        netVane: "",
        flowUI: "",
        lookout: ""
    };

    static EMPTY_ASN_ERROR_TEXTS = {
        asnNumber: "",
        providerServiceIdList: "",
        providerName: "",
        peercastProviderName: "",
        peeringDB: "",
        peercast: "",
        netVane: "",
        flowUI: "",
        lookout: ""
    };

    static handleAdditionalInput = (input) => {
        const output = input;

        if (output.objectChanged === AsnValidation.BUTTON_NAMES.addRegionButton) {
            const newRegionId = `region${output.regionObjects.length + 1}`;
            const newAsnSessionTypeId = `asnSessionType${output.regionObjects.length + 1}`;
            output.regionObjects =
                output.regionObjects.concat({
                    id: newRegionId,
                    value: "",
                    errorText: Constants.ERROR_STRINGS.blankRegionErrorText,
                    asnSessionType: {
                        id: newAsnSessionTypeId,
                        value: "",
                        errorText: Constants.ERROR_STRINGS.blankSessionTypeErrorText
                    }
                });
        }

        return output;
    };

    static handleSubtractRegionInput = (evt, input) => {
        const output = input;
        output.regionObjects = HelperFunctions.subtractSpecificObjectHelper(
            output.regionObjects,
            evt.target.id,
            Constants.DYNAMIC_INPUT_TYPES.region
        );

        return output;
    };

    static disableAddRegionButton = regionsObjects =>
        // If any one of the region fields are not filled out (since its not a required field, it can be blank)
        (regionsObjects.some(region => !region.value))
        // If any of the region objects or session type have an error
        || (regionsObjects.some(region => region.errorText || region.asnSessionType.errorText))
        // We check for one less than the length because one option is None which has no value
        || !(regionsObjects.length < Object.values(Constants.GEOGRAPHIC_REGIONS).length);


    static validateInput = (input) => {
        // Here we fetch the changed entity Id
        const attributeId = input.evt.target.id;
        const output = input;

        if (attributeId.includes("region") || attributeId.includes("asnSessionType")) {
            output.regionObjects = input.regionObjects.map((region) => {
                if (region.id === attributeId || region.asnSessionType.id === attributeId) {
                    const inputValue = input.evt.detail.selectedOption.label === "None" // Check to see if its None
                        ? "" : input.evt.detail.selectedOption.label;
                    const regionValue = region.id === attributeId ? inputValue : region.value;
                    const asnSessionTypeValue = region.asnSessionType.id === attributeId
                        ? inputValue : region.asnSessionType.value;
                    let regionErrorText = "";
                    let asnSessionTypeErrorText = "";

                    // Populate the error texts as for region
                    if (!regionValue && asnSessionTypeValue) {
                        regionErrorText = Constants.ERROR_STRINGS.missingRegionForSessionTypeErrorText;
                    } else if (!regionValue && output.regionObjects.length > 1) {
                        regionErrorText = Constants.ERROR_STRINGS.blankRegionErrorText;
                    }
                    // Populate the error text for asnSessionType
                    if (regionValue && !asnSessionTypeValue) {
                        asnSessionTypeErrorText = Constants.ERROR_STRINGS.missingSessionTypeForRegionErrorText;
                    } else if (!asnSessionTypeValue && output.regionObjects.length > 1) {
                        asnSessionTypeErrorText = Constants.ERROR_STRINGS.blankSessionTypeErrorText;
                    }

                    return Object.assign({}, region, {
                        value: regionValue,
                        errorText: regionErrorText,
                        asnSessionType: {
                            id: region.asnSessionType.id,
                            value: asnSessionTypeValue,
                            errorText: asnSessionTypeErrorText
                        }
                    });
                }
                return region;
            });
        } else if (attributeId === "providerServiceIdList") {
            output.chosenProviderServices = input.evt.detail.selectedOptions;
            output.asn[attributeId] = output.chosenProviderServices.map(service => service.value);
            if (output.chosenProviderServices.length !== 0) {
                output.asnErrorTexts[attributeId] = "";
            } else {
                output.asnErrorTexts[attributeId] = AsnValidation.UPDATE_ASN_SERVICE_ERROR_TEXT;
            }
        } else if (attributeId === Constants.ATTRIBUTES.providerName) {
            const inputValue = output.evt.detail.selectedOption?.value;
            output.asn[attributeId] = inputValue;
            output.asnErrorTexts[attributeId] =
                HelperFunctions.validateInfo(inputValue, Constants.VALIDATE_INFO_OPTIONS.required);

            output.hasModifiedProviderName = true; // Only this block of code should be able to decide to do this

            // Whenever the provider name is changed, we need to clear out any chosen services because they
            // are dependent upon a specific provider
            output.asn.providerServiceIdList = [];
            output.asnErrorTexts.providerServiceIdList = Constants.ERROR_STRINGS.blankInput;
        } else {
            const inputValue = input.evt.detail.value;
            output.asn[attributeId] = inputValue || null;
            if (attributeId === "asnNumber") {
                output.asnErrorTexts[attributeId] = HelperFunctions.validateInfo(inputValue,
                    Constants.VALIDATE_INFO_OPTIONS.required);
            } else if (attributeId !== "peercastProviderName") {
                // The rest of the fields are websites, except for peercastProviderName which has no validation
                if (inputValue === "") {
                    output.asnErrorTexts[attributeId] = "";
                } else {
                    output.asnErrorTexts[attributeId] = HelperFunctions.validateWebsite(inputValue);
                }
            }
        }
        return output;
    };

    static createDynamicLinks = (input) => {
        const output = input;
        // This link goes to a netvane page that displays all circuits and interfaces that are connected to an ASN
        output.netVane = `https://nm-pdx.corp.amazon.com/netvane?device_query=name%3A%2F%28.%2A-br-tra-.%2A%7C.%2Abr-spc-.%2A-tra-t1.%2A%29.%2A%2F&element_query=AS${output.asnNumber}&nsmq=false&sort_Interface=mainMetrics%3Aelement%3Aasc%3Anatural&start=-3+Hours&type_and_probe=Interface%7CifTrafficIn%3AInterface%7CifTrafficOut`;
        return output;
    };
}