import React, { Component } from "react";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderTabRenderingHandler from "order/OrderTabRenderingHandler";

/**
 * OrderBusinessOperationsTab is used to display all of the stages that a business operator works through in an order
 */
class OrderBusinessOperationsTab extends Component {
    state = {
        isUpdateOrderBusinessOperationsTabInProgress: false
    };

    componentDidMount = () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        }
    };

    handleStageInEditOrSubmitMode = (isBeingEditedOrSubmitted) => {
        this.props.setIsAnyEditModeEnabled(isBeingEditedOrSubmitted);
    };

    render() {
        return (
            <OrderTabRenderingHandler
                order={this.props.order}
                circuitDesignObjects={this.props.circuitDesignObjects}
                circuitDesignsLoading={this.props.circuitDesignsLoading}
                loadData={this.props.loadData}
                handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                componentIdToObjectMap={this.props.componentIdToObjectMap}
                stages={this.props.stages}
                auth={this.props.auth}
                orderCompleted={this.props.orderCompleted}
                goToComponentAction={this.props.goToComponentAction}
                isUpdateOrderBusinessOperationsTabInProgress={this.state.isUpdateOrderBusinessOperationsTabInProgress}
                blockers={this.props.blockers}
                editButtonsDisabled={this.props.isAnyEditModeEnabled || !this.props.isTabEditable}
                handleStageInEditOrSubmitMode={this.handleStageInEditOrSubmitMode}
                isDataLoaded={this.props.isDataLoaded}
            />
        );
    }
}

export default OrderBusinessOperationsTab;