import React, { Component } from "react";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import Constants from "utils/Constants";
import OrderProviderChangeModal from "order/OrderProviderChangeModal";
import PolarisUtils from "utils/PolarisUtils";

export default class OrderProviderChangeHandler extends Component {
    state = {
        updatedOrder: HelperFunctions.deepClone(this.props.order),
        providersLoading: this.props.allProvidersLoading,
        submissionInProgress: false,
        errorsToDisplayInModal: [],
        providerOptions: this.props.allProviders,

        // Provider Service based options:
        entitiesLoading: false,
        asnOptions: [],
        contactOptions: [],

        // We need some state variables to capture the selected options as well
        selectedProvider: {},
        selectedContactOption: {},
        selectedAsnOption: {}
    };

    componentDidMount() {
        this.getProviderRelatedEntities(this.state.updatedOrder.providerName);
    }

    getProviderRelatedEntities = async (providerName) => {
        this.resetErrorMessages();
        this.#resetRelatedEntities();
        this.setState({ entitiesLoading: true });

        try {
            // Get the provider object
            const getProvider = await this.FremontBackendClient.getProviderInfo(providerName, this.props.auth);
            const selectedProvider = getProvider[Constants.ATTRIBUTES.provider];
            this.setState({ selectedProvider });

            // Get the provider service, if the provider has any. The contact/asn loaded is based on the service type
            if (!HelperFunctions.isEmpty(selectedProvider[Constants.ATTRIBUTES.providerServiceIdList])) {
                const correspondingProviderService =
                    await this.getMatchingProviderService(selectedProvider[Constants.ATTRIBUTES.providerServiceIdList]);
                this.setState({ correspondingProviderService });

                // If we have the provider service, we may need to additionally fetch the contact and asn information
                if (correspondingProviderService) {
                    if (!HelperFunctions.isOrderChangeOrder(this.props.order)
                        && !HelperFunctions.isEmpty(correspondingProviderService[Constants.ATTRIBUTES.contactIdList])) {
                        await this.getContacts(
                            correspondingProviderService[Constants.ATTRIBUTES.contactIdList], providerName
                        );
                    }

                    // Only fetch the asn if the existing order also has an asn
                    if (this.props.order[Constants.ATTRIBUTES.asnId]
                        && !HelperFunctions.isEmpty(correspondingProviderService[Constants.ATTRIBUTES.asnIdList])) {
                        await this.getAsns(correspondingProviderService[Constants.ATTRIBUTES.asnIdList], providerName);
                    }
                }
            }
        } catch (error) {
            this.#generateFlashbarErrors("Failed to fetch provider information", error);
        }

        // Finally set our state to non-loading
        this.setState({ entitiesLoading: false });
    }

    getAsns = async (asnIds, providerName) => {
        // Here we call the back end to get all the asn objects
        const getBatchAsnObjectsResponse = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.ASN, asnIds, this.props.auth
        );
        const asnOptions = HelperFunctions
            .sortObjectsByField(getBatchAsnObjectsResponse.asns
                .map(asn => ({
                    [PolarisUtils.OPTION_VALUE_KEY]: asn[Constants.ATTRIBUTES.asnId],
                    [PolarisUtils.OPTION_LABEL_KEY]: asn[Constants.ATTRIBUTES.asnNumber]
                })), "label");

        let selectedAsnOption = {};
        if (this.#isOriginalProvider(providerName)) {
            selectedAsnOption = asnOptions
                .filter(option => option[PolarisUtils.OPTION_VALUE_KEY]
                    === this.props.order[Constants.ATTRIBUTES.asnId])
                .find(Boolean);
        }

        this.setState({ asnOptions, selectedAsnOption });
    }

    getContacts = async (contactIdList, providerName) => {
        const batchProviderContactResponse = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.CONTACT, contactIdList, this.props.auth
        );
        const contactOptions = HelperFunctions
            .sortObjectsByField(batchProviderContactResponse.contacts.map(contact => ({
                [PolarisUtils.OPTION_VALUE_KEY]: contact[Constants.ATTRIBUTES.contactId],
                [PolarisUtils.OPTION_LABEL_KEY]: `${contact[Constants.ATTRIBUTES.firstName]} ${contact[Constants.ATTRIBUTES.lastName]}`
            })), "label");

        let selectedContactOption = {};
        if (this.#isOriginalProvider(providerName)) {
            selectedContactOption = contactOptions
                .filter(option => option[PolarisUtils.OPTION_VALUE_KEY]
                    === this.props.order[Constants.ATTRIBUTES.contactId])
                .find(Boolean);
        }

        this.setState({ contactOptions, selectedContactOption });
    }

    getMatchingProviderService = async (providerServiceIdList) => {
        const batchProviderServicesResponse = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.PROVIDER_SERVICE, providerServiceIdList, this.props.auth
        );

        return batchProviderServicesResponse.providerServices
            .filter(providerService => providerService[Constants.ATTRIBUTES.serviceType] ===
                this.props.order[Constants.ATTRIBUTES.serviceType]).find(Boolean);
    }

    submitOrderUpdate = async () => {
        try {
            // Set loading state and clear out errors
            this.resetErrorMessages();
            this.setState({ submissionInProgress: true });

            // Update the order and fetch all its related entities again
            await this.FremontBackendClient.updateOrderInfo(
                this.state.updatedOrder, this.props.order, this.props.auth
            );

            // reload the order data on our details page
            await this.props.loadData(true, true);
            await this.props.loadDataOnOriginalPageLoad();

            // Finally hide the modal if the request is successful. If its unsuccessful, we need to show the error
            // so the modal stays
            this.props.hideOrderProviderChangeModal();
        } catch (error) {
            this.#generateFlashbarErrors("Failed to update order", error);
        }

        this.setState({
            submissionInProgress: false
        });
    }

    FremontBackendClient = new FremontBackendClient();

    handleOrderInputChange = async (evt) => {
        let outputOrder = HelperFunctions.deepClone(this.state.updatedOrder);

        // Updating the order based on the event
        const attributeId = evt.target.id;
        const inputValue = evt.detail.selectedOption.value;
        outputOrder[attributeId] = inputValue;

        // Since we are updating state of the order to render our select correctly, we need to capture whether or not
        // the provider has changed
        const hasProviderChanged = outputOrder[Constants.ATTRIBUTES.providerName]
            !== this.state.updatedOrder[Constants.ATTRIBUTES.providerName];

        // Setting the state here so that the provider shows up in the select form immediately (as opposed to after we
        // load its service's contact/asn information)
        this.setState({
            updatedOrder: outputOrder
        });

        // If the provider has changed, we need to load the contacts, asns, and provider services
        if (hasProviderChanged) {
            // If we change back to the provider that is initially on the order, we want to fully pre-populate the form
            if (this.#isOriginalProvider(outputOrder[Constants.ATTRIBUTES.providerName])) {
                outputOrder = this.props.order; // Reset the order object
            } else {
                // Clear out the contact and asn if the provider has changed. Service type is not allowed to change, so
                // we're not going to clear that out
                delete outputOrder[Constants.ATTRIBUTES.contactId];
                delete outputOrder[Constants.ATTRIBUTES.asnId];
            }

            await this.getProviderRelatedEntities(outputOrder.providerName);
        }

        // If we have a change to the contactId or the asnId, we need to populate the respective selected entity
        if (attributeId === Constants.ATTRIBUTES.contactId) {
            const selectedContactOption = this.state.contactOptions
                .filter(option => option[PolarisUtils.OPTION_VALUE_KEY] === outputOrder[Constants.ATTRIBUTES.contactId])
                .find(Boolean);
            this.setState({ selectedContactOption });
        }
        if (attributeId === Constants.ATTRIBUTES.asnId) {
            const selectedAsnOption = this.state.asnOptions
                .filter(option => option[PolarisUtils.OPTION_VALUE_KEY] === outputOrder[Constants.ATTRIBUTES.asnId])
                .find(Boolean);
            this.setState({ selectedAsnOption });
        }

        // Finally update our state once more
        this.setState({
            updatedOrder: outputOrder
        });
    }

    // Currently, this dismisses all the error messages because we don't expect there to be multiple. We can update
    // this logic as needed in the future.
    resetErrorMessages = () => {
        this.setState({ errorsToDisplayInModal: [] });
    }

    #resetRelatedEntities = () => {
        this.setState({
            asnOptions: [],
            contactOptions: [],

            // We need some state variables to capture the selected options as well
            selectedProvider: {},
            correspondingProviderService: undefined,
            selectedContactOption: {},
            selectedAsnOption: {}
        });
    }

    #isOriginalProvider = providerName => this.props.order[Constants.ATTRIBUTES.providerName] === providerName;

    #generateFlashbarErrors = (header, error) => {
        this.setState({
            errorsToDisplayInModal: HelperFunctions.generateErrorMessageForFlashbarV3(
                header, error.message, true, this.resetErrorMessages
            )
        });
    };

    render() {
        return (
            <OrderProviderChangeModal
                // Props passed in from parent (OrderDetailsPage)
                hideOrderProviderChangeModal={this.props.hideOrderProviderChangeModal}
                isOrderProviderChangeModalClicked={this.props.isOrderProviderChangeModalClicked}
                order={this.props.order} // Required for rendering some fields

                // Props passed in from this component
                updatedOrder={this.state.updatedOrder}
                providersLoading={this.state.providersLoading}
                entitiesLoading={this.state.entitiesLoading}
                handleOrderInputChange={this.handleOrderInputChange}
                submitOrderUpdate={this.submitOrderUpdate}
                submissionInProgress={this.state.submissionInProgress}
                errorsToDisplayInModal={this.state.errorsToDisplayInModal}

                // All the select option related fields
                providerOptions={this.state.providerOptions}
                selectedProvider={this.state.selectedProvider}
                correspondingProviderService={this.state.correspondingProviderService}
                asnOptions={this.state.asnOptions}
                selectedAsnOptions={this.state.selectedAsnOption}
                contactOptions={this.state.contactOptions}
                selectedContactOption={this.state.selectedContactOption}
            />
        );
    }
}