import React from "react";
import {
    Box,
    FormField,
    Header,
    Icon,
    Link,
    Table,
    SpaceBetween,
    Popover
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontExpandableSection,
    FremontSelect,
    FremontToggle,
    FremontAlert,
    requiredLabel,
    ComponentConstants
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

/**
 * This method handles appending the provider circuit IDs to the transient fields of the meta so that
 * they can be displayed in the select circuits stage display mode.
 * @param circuitDesignObjects list of circuit objects
 * @param componentIdToObjectMap map of component IDs to their corresponding objects
 * @returns list of modified circuit objects.
 *          Meta schema = meta: { transientFields: "providerCircuitId1, providerCircuitId2" }
 */
export const appendConsumedSpanProviderCircuitIdsToMeta = (circuitDesignObjects, componentIdToObjectMap) => {
    // First we get the consumed circuits from the positionMap of the circuit we passed in
    const parentCircuitToChildCircuitMap = {};
    circuitDesignObjects.forEach(circuitDesign =>
        Object.assign(parentCircuitToChildCircuitMap, { [circuitDesign.circuitDesignId]: [] }));
    circuitDesignObjects.forEach((circuitDesignObjectToReturn) => {
        Object.values(circuitDesignObjectToReturn.positionMap).forEach((positionMapComponent) => {
            if (positionMapComponent.componentGroup === Constants.COMPONENT_NAMES.fremontCircuit) {
                parentCircuitToChildCircuitMap[circuitDesignObjectToReturn.circuitDesignId]
                    .push(positionMapComponent.uuid);
            }
        });
    });

    // Reduce the list of consumed circuit Id lists into a single consumed circuit id list
    const consumedCircuitIds = Array.prototype.concat.apply([], Object.values(parentCircuitToChildCircuitMap));

    // If there are no consumed circuits, we just return the unmodified circuits
    if (consumedCircuitIds.length === 0) {
        return circuitDesignObjects;
    }

    // Obtain the child circuits from the componentIdToObjectMap. We filter out null child circuits. This could
    // occur if a child circuit in not present in the componentIdToObjectMap
    const childCircuitDesigns = consumedCircuitIds
        .map(consumedCircuitId => componentIdToObjectMap[consumedCircuitId])
        .filter(consumedCircuit => !!consumedCircuit);

    // Next we get the provider circuit IDs from the consumed circuits
    const spanCircuitToProviderCircuitMap = {};
    childCircuitDesigns.forEach((childCircuitDesign) => {
        Object.values(childCircuitDesign.positionMap).forEach((positionMapComponent) => {
            if (positionMapComponent.name === Constants.COMPONENT_NAMES.providerCircuitA) {
                Object.assign(
                    spanCircuitToProviderCircuitMap,
                    { [childCircuitDesign.circuitDesignId]: positionMapComponent.uuid }
                );
            }
        });
    });

    // Then we get the provider circuits themselves from the componentIdToObjectMap
    const providerCircuitToProviderCircuitNameMap = {};
    Object.values(spanCircuitToProviderCircuitMap).filter(value => value !== undefined).forEach(providerCircuitId =>
        Object.assign(
            providerCircuitToProviderCircuitNameMap,
            {
                [providerCircuitId]: componentIdToObjectMap[providerCircuitId].providerCircuitName
            }
        ));

    // Finally we construct the transient field on the meta to have a list of the consumed provider circuit IDs.
    // We filter out any circuits that already have a value inside of the transientFields of the meta
    circuitDesignObjects.filter(circuitDesign => !circuitDesign.meta.transientFields).forEach(circuitDesign =>
        Object.assign(
            circuitDesign.meta,
            {
                transientFields: Object.values(parentCircuitToChildCircuitMap[circuitDesign.circuitDesignId])
                    .map(childCircuitId =>
                        providerCircuitToProviderCircuitNameMap[spanCircuitToProviderCircuitMap[childCircuitId]])
                    // We join the list with a comma to match the backend format
                    .join(", ")
            }
        ));

    return circuitDesignObjects;
};

const getChangeOrderSpanDecomExplanation = orderId => "If this flag is selected, a span decom reason must be entered."
    + ` When ${orderId} completes, Fremont will locate all span circuits that were deconsumed as a part of ${orderId}.`
    + " Fremont will then create decom orders for the necessary span circuits to prevent orphaned spans.";

const getDecomOrderSpanDecomExplanation = orderId => "If this flag is selected, a span decom reason must be entered."
    + ` When ${orderId} completes, Fremont will locate all consumed span circuits that are consumed by the path`
    + " circuits on the order. Fremont will then create decom orders for the necessary span circuits to prevent"
    + " orphaned spans.";

export const generateSelectCircuitsColumnDefinitions = (serviceType, customerFabric) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.ATTRIBUTES.revisionNumber,
            header: "Version",
            description: "The version of the circuitDesign",
            width: Constants.COLUMN_WIDTHS.version,
            cell: item => `V${item.revisionNumber}`
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "The bandwidth of the circuitDesign",
            width: Constants.COLUMN_WIDTHS.bandwidth,
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth] || item[Constants.ATTRIBUTES.portBandwidth]
        }
    ];

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)) {
        columnDefinitions.push(
            {
                id: Constants.ATTRIBUTES.lacpProvider,
                header: "LACP Provider",
                description: "Whether a circuitDesign is LACP Provider",
                width: 125,
                cell: item => HelperFunctions.booleanToYesNo(
                    HelperFunctions.parseBoolean(item[Constants.ATTRIBUTES.lacpProvider])
                )
            }
        );
    }

    columnDefinitions.push(
        {
            id: Constants.ATTRIBUTES.orderId,
            header: "Order",
            description: "The order for this circuitDesign",
            width: 125,
            cell: item => (
                <Link href={`${Constants.ROUTES.order}/${item.orderId}`}>
                    {item.orderId}
                </Link>
            )
        }
    );

    if (Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: Constants.ATTRIBUTES.transientFields,
                header: "Provider Circuit IDs",
                description: "A calculated value put in the Path Circuit's meta's transientField",
                width: 125,
                cell: item => (
                    item[Constants.ATTRIBUTES.meta][Constants.ATTRIBUTES.transientFields])
            }
        );
    }

    return columnDefinitions;
};

export const generateSearchForCircuitsColumnsDefinitions = (props) => {
    const columnDefinitions = [
        {
            header: ![Constants.ORDER_TYPES.CTC, Constants.ORDER_TYPES.RERATE].includes(props.order.orderType)
            || props.queriedCircuitDesignObjects.length === 0
            || props.queriedCircuitDesignObjects.length === props.selectedCircuitDesignObjects.length
                ? "" :
                <FremontButton
                    id={`${Constants.ADD_CIRCUIT_TO_ORDER}${Constants.SEPARATOR}${Constants.ALL_CIRCUITS}`}
                    iconName="add-plus"
                    variant="normal"
                    onClick={props.handleStageInputChange}
                    disabled={props.isUpdateStageInProgress || props.allFieldsDisabled}
                />,
            id: Constants.ADD_CIRCUIT_TO_ORDER,
            width: 80,
            cell: [Constants.ORDER_TYPES.CTC, Constants.ORDER_TYPES.RERATE].includes(props.order.orderType)
                ? () => {} : item => (
                    <FremontButton
                        id={`${Constants.ADD_CIRCUIT_TO_ORDER}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        iconName="add-plus"
                        variant="normal"
                        onClick={item.handleStageInputChange}
                        disabled={item.isUpdateStageInProgress || item.allFieldsDisabled}
                    />
                )
        }
    ];
    return columnDefinitions.concat(generateSelectCircuitsColumnDefinitions(
        props.order.serviceType, props.order.customerFabric
    ));
};

export const generateChosenCircuitsColumnsDefinitions = (props) => {
    const columnDefinitions = [
        {
            header: ![Constants.ORDER_TYPES.CTC, Constants.ORDER_TYPES.RERATE].includes(props.order.orderType)
            || props.selectedCircuitDesignObjects.length === 0
                ? "" :
                <FremontButton
                    id={`${Constants.REMOVE_CIRCUIT_FROM_ORDER}${Constants.SEPARATOR}${Constants.ALL_CIRCUITS}`}
                    iconName="close"
                    variant="normal"
                    onClick={props.handleStageInputChange}
                    disabled={props.isUpdateStageInProgress || props.allFieldsDisabled}
                />,
            id: Constants.REMOVE_CIRCUIT_FROM_ORDER,
            width: 80,
            cell: [Constants.ORDER_TYPES.CTC, Constants.ORDER_TYPES.RERATE].includes(props.order.orderType)
                ? () => {} : item => (
                    <FremontButton
                        id={`${Constants.REMOVE_CIRCUIT_FROM_ORDER}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        iconName="close"
                        variant="normal"
                        onClick={item.handleStageInputChange}
                        disabled={item.isUpdateStageInProgress || item.allFieldsDisabled}
                    />
                )
        }
    ];
    return columnDefinitions.concat(generateSelectCircuitsColumnDefinitions(
        props.order.serviceType, props.order.customerFabric
    ));
};

export const SpanDecomToggle = props => (
    <Box margin={{ bottom: "m" }} padding={{ top: "xs" }}>
        <FremontExpandableSection
            variant="container"
            header={
                <SpaceBetween direction="horizontal" size="m">
                    <Header variant="h2">
                        Span Decom Orders
                    </Header>
                    <Box margin={{ top: "xxs" }}>
                        <Popover
                            content={
                                <Box>
                                    {props.orderType === Constants.ORDER_TYPES.CHANGE
                                        ? getChangeOrderSpanDecomExplanation(props.orderId)
                                        : getDecomOrderSpanDecomExplanation(props.orderId)
                                    }
                                </Box>
                            }
                        >
                            <Icon
                                name="status-info"
                                variant="subtle"
                            />
                        </Popover>
                    </Box>
                </SpaceBetween>
            }
            defaultExpanded={props.expanded}
        >
            <Box margin={{ bottom: "m" }} padding={{ top: "s" }}>
                <FremontToggle
                    id={Constants.ATTRIBUTES.createSpanDecomOrdersOnTrigger}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    checked={props.checked}
                >
                    Automatically Create Span Decom Order(s)
                </FremontToggle>
            </Box>
            {props.checked && props.noteBody &&
                <FremontAlert
                    type="info"
                    header="Note:"
                >
                    <span className="pre-wrap">{props.noteBody}</span>
                </FremontAlert>
            }
        </FremontExpandableSection>
    </Box>
);

export const SelectCircuitsStage = props => (
    <div>
        {props.order.orderType === Constants.ORDER_TYPES.CHANGE
        && Constants.INTERCONNECT_SERVICE_TYPES.includes(props.order.serviceType) &&
        <Box margin={{ bottom: "m" }} padding={{ top: "xs" }}>
            <FormField
                label={
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        {requiredLabel("LACP Provider:")}
                        {
                            HelperFunctions.renderCustomTooltip(null,
                                Constants.TOOLTIP_STRINGS.lacpProviderChangeOrderExplanation)
                        }
                    </SpaceBetween>
                }
                errorText={props.lacpProviderError}
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.lacpProvider}
                    disabled={props.orderCompleted || props.allFieldsDisabled}
                    options={HelperFunctions.deepClone(Constants.GENERAL_YES_NO_OPTIONS).concat([{
                        value: null, label: "No Selection"
                    }])}
                    selectedOption={HelperFunctions.booleanToYesNoNullSelectedOption(props.lacpProvider)}
                    onChange={props.handleChangeOrderLacpProvider}
                    expandToViewport
                />
            </FormField>
        </Box>
        }
        {Constants.PATH_CUSTOMER_FABRICS.includes(props.order.customerFabric) &&
            <SpanDecomToggle
                orderType={props.order.orderType}
                orderId={props.order.orderId}
                handleModalVisibilityHandler={props.handleModalVisibilityHandler}
                onChange={props.handleModalVisibilityHandler}
                disabled={props.orderCompleted || props.allFieldsDisabled}
                checked={props.createSpanDecomOrdersOnTrigger}
                noteBody={props.noteBody}
                expanded
            />
        }
        <FremontExpandableSection
            variant="container"
            header={
                <Header variant="h2">Add and Remove Circuits</Header>}
            defaultExpanded
        >
            <div>
                {props.searchInformation}
                <Table
                    header={
                        <Header variant="h2">Found Circuits</Header>}
                    columnDefinitions={generateSearchForCircuitsColumnsDefinitions(props)}
                    loading={props.isQueryForCircuitsInProgress}
                    loadingText={Constants.STATUS_TEXTS.loadingComponents}
                    items={props.circuitItems.dynamic}
                    wrapLines
                    empty={
                        <Box textAlign="center">
                            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                <b>
                                    {Constants.EMPTY_TABLE_MESSAGES.noCircuitsFromQuery}
                                </b>
                            </Box>
                        </Box>
                    }
                />
                <Table
                    header={
                        <Header variant="h2">Selected Circuits</Header>}
                    columnDefinitions={generateChosenCircuitsColumnsDefinitions(props)}
                    loading={props.isQueryForCircuitsInProgress}
                    loadingText={Constants.STATUS_TEXTS.loadingComponents}
                    items={appendConsumedSpanProviderCircuitIdsToMeta(
                        props.circuitItems.static, props.componentIdToObjectMap
                    )}
                    wrapLines
                    empty={
                        <Box textAlign="center">
                            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                <b>
                                    {Constants.EMPTY_TABLE_MESSAGES.noCircuitsAddedToOrder}
                                </b>
                            </Box>
                        </Box>
                    }
                />
            </div>
        </FremontExpandableSection>
    </div>
);