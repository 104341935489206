import React from "react";
import {
    Box,
    Flashbar,
    Modal,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton
} from "utils/CommonComponents";
import HelperFunctions from "common/HelperFunctions";
import RemoveCircuitsModalHandler from "circuitDesign/RemoveCircuitsModalHandler";

const RemoveCircuitsModal = props => (
    <Modal
        visible={props.isRemoveCircuitsModalVisible}
        size="medium"
        onDismiss={props.handleCancelRemoveCircuitsModal}
        header="Select Circuits to Remove"
        footer={
            <Box variant="span" float="right">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        id="cancel"
                        variant="normal"
                        onClick={props.handleCancelRemoveCircuitsModal}
                    >
                        Cancel
                    </FremontButton>
                    <FremontButton
                        id="submit"
                        variant="primary"
                        onClick={props.handleSubmit}
                    >
                        Remove Selected Circuit(s) from Order
                    </FremontButton>
                </SpaceBetween>
            </Box>
        }
    >
        <Flashbar
            items={
                props.errorToDisplay ? HelperFunctions.generateErrorMessageForFlashbar(props.errorToDisplay) : []
            }
        />
        <div>
            {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
            <Table
                id="removeCircuitsModalTable"
                columnDefinitions={RemoveCircuitsModalHandler.REMOVE_CIRCUIT_DEFINITIONS}
                items={props.circuitItems}
            />
        </div>
    </Modal>
);

export default RemoveCircuitsModal;