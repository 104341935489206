import React from "react";
import { Box, Icon } from "@amzn/awsui-components-react/polaris";
import "footer.css";

const FOOTER_STRINGS = {
    bugLink: "https://t.corp.amazon.com/create/templates/792521b2-a12e-4e0e-a5e6-fb38e3340d5b",
    bugText: "Bug Report",
    featureLink: "https://issues.amazon.com/issues/create?template=ac6d4860-13c4-473a-9b30-de2871be9890",
    featureText: "Feature Request",
    helpLink: "https://broadcast.amazon.com/channels/56570/playlists/36953",
    helpText: "LinkService UI How-To's"
};

/**
 * The LinkServiceFooter displays the User guides, bug report, and feature request links.
 * We wrap the Box and Icon components inside of spans that have custom CSS attributes since
 * most polaris V3 attributes do not allow custom CSS overrides.
 */
const LinkServiceFooter = () => (
    <div className="footer">
        <span className="footer-link">
            <a href={FOOTER_STRINGS.helpLink} target="_blank" rel="noopener noreferrer">
                <span className="link-color">
                    <Icon name="external" variant="normal"/>
                </span>
                <span className="footer-text-color">
                    <Box
                        variant="strong"
                        color="inherit"
                        fontSize="heading-s"
                        margin={{ left: "xxs" }}
                    >
                        {FOOTER_STRINGS.helpText}
                    </Box>
                </span>
            </a>
        </span>
        <span className="footer-link">
            <a href={FOOTER_STRINGS.bugLink} target="_blank" rel="noopener noreferrer">
                <span className="link-color">
                    <Icon name="bug" variant="normal"/>
                </span>
                <span className="footer-text-color">
                    <Box
                        variant="strong"
                        color="inherit"
                        fontSize="heading-s"
                        margin={{ left: "xxs" }}
                    >
                        {FOOTER_STRINGS.bugText}
                    </Box>
                </span>
            </a>
        </span>
        <span className="footer-link">
            <a href={FOOTER_STRINGS.featureLink} target="_blank" rel="noopener noreferrer">
                <span className="link-color">
                    <Icon name="folder" variant="normal"/>
                </span>
                <span className="footer-text-color">
                    <Box
                        variant="strong"
                        color="inherit"
                        fontSize="heading-s"
                        margin={{ left: "xxs" }}
                    >
                        {FOOTER_STRINGS.featureText}
                    </Box>
                </span>
            </a>
        </span>
    </div>
);

export default LinkServiceFooter;