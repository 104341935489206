import React from "react";
import {
    Box,
    Container,
    ColumnLayout,
    FormField,
    Grid,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import AsnValidation from "asn/AsnValidation";
import Constants from "utils/Constants";
import FremontComponentInfo from "common/FremontComponentInfo";
import HelperFunctions from "common/HelperFunctions";
import {
    optionalLabel,
    requiredLabel,
    FremontButton,
    FremontInput,
    FremontMultiselect,
    FremontSelect,
    ComponentConstants
} from "utils/CommonComponents";

/**
 * This component serves as the main container holding all of the asn information
 */
const AsnInformation = props => (
    <div>
        <AsnInfoContainer
            asn={props.asn}
            updatedAsn={props.updatedAsn}
            isUpdateAsnInfoEditClicked={props.isUpdateAsnInfoEditClicked}
            isUpdateAsnInfoInProgress={props.isUpdateAsnInfoInProgress}
            handleUpdateAsnEdit={props.handleUpdateAsnEdit}
            handleUpdateAsnInputChange={props.handleUpdateAsnInputChange}
            handleUpdateAsnSubmit={props.handleUpdateAsnSubmit}
            addRegionButtonDisabled={props.addRegionButtonDisabled}
            handleAdditionalRegionInput={props.handleAdditionalRegionInput}
            handleSubtractSpecificRegionObject={props.handleSubtractSpecificRegionObject}
            regionObjectsShallowCopy={props.regionObjectsShallowCopy}
            asnErrorTexts={props.asnErrorTexts}
            hasUpdateAsnSubmittedOnce={props.hasUpdateAsnSubmittedOnce}
            chosenProviderServices={props.chosenProviderServices}
            serviceOptions={props.serviceOptions}
            providerName={props.asn.providerName}
        />
    </div>
);

/**
 * This component holds the asn display and edit modes
 */
export const AsnInfoContainer = (props) => {
    if (props.isUpdateAsnInfoEditClicked) {
        return (
            <AsnInfoEditMode
                isUpdateAsnInfoInProgress={props.isUpdateAsnInfoInProgress}
                asn={props.asn}
                updatedAsn={props.updatedAsn}
                asnErrorTexts={props.asnErrorTexts}
                handleUpdateAsnEdit={props.handleUpdateAsnEdit}
                handleUpdateAsnInputChange={props.handleUpdateAsnInputChange}
                handleUpdateAsnSubmit={props.handleUpdateAsnSubmit}
                addRegionButtonDisabled={props.addRegionButtonDisabled}
                handleAdditionalRegionInput={props.handleAdditionalRegionInput}
                handleSubtractSpecificRegionObject={props.handleSubtractSpecificRegionObject}
                regionObjectsShallowCopy={props.regionObjectsShallowCopy}
                hasUpdateAsnSubmittedOnce={props.hasUpdateAsnSubmittedOnce}
                chosenProviderServices={props.chosenProviderServices}
                serviceOptions={props.serviceOptions}
                providerName={props.asn.providerName}
            />
        );
    }
    return (
        <AsnInfoDisplayMode
            asn={props.asn}
            providerName={props.asn.providerName}
            chosenProviderServices={props.chosenProviderServices}
            handleUpdateAsnEdit={props.handleUpdateAsnEdit}
        />
    );
};

/**
 * This component displays the asn info
 */
export const AsnInfoDisplayMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <FremontButton
                        id="editAsnInfoButton"
                        iconName="edit"
                        variant="primary"
                        onClick={props.handleUpdateAsnEdit}
                    />
                }
            >
                ASN Information
            </Header>
        }
    >
        <SpaceBetween size="l">
            <Container
                header={<Header variant="h2"> General Information </Header>}
            >
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Autonomous System Number:</Box>
                                <div>{props.asn.asnNumber}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Geographic Regions:</Box>
                                <div>
                                    {Object.keys(props.asn.asnRegions)
                                        .map(key => `${key} (${props.asn.asnRegions[key]})`).join(", ") || "-"}
                                </div>
                            </div>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Provider:</Box>
                                <div>
                                    <Link href={`${Constants.ROUTES.provider}/${props.providerName}`}>
                                        {props.providerName}
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Peercast Provider Name:</Box>
                                <div>{props.asn.peercastProviderName || "-"}</div>
                            </div>
                        </SpaceBetween>
                    </div>
                </ColumnLayout>
            </Container>
            <Container
                header={<Header variant="h2"> External Links </Header>}
            >
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Flow UI URL:</Box>
                                <div>{props.asn.flowUI || "-"}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">NetVane:</Box>
                                <div>{HelperFunctions.generateNetVaneLink(props.asn)}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">PeeringDB URL:</Box>
                                <div>{props.asn.peeringDB || "-"}</div>
                            </div>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Lookout URL:</Box>
                                <div>{props.asn.lookout || "-"}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Peercast URL:</Box>
                                <div>{props.asn.peercast || "-"}</div>
                            </div>
                        </SpaceBetween>
                    </div>
                </ColumnLayout>
            </Container>
            <FremontComponentInfo
                modifiedBy={props.asn.modifiedBy}
                modifiedTime={props.asn.modifiedTime}
                createdBy={props.asn.createdBy}
                createdTime={props.asn.createdTime}
            />
        </SpaceBetween>
    </Container>
);

/**
 * This component allows the user to edit asn information
 */
export const AsnInfoEditMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <FremontButton
                            disabled={props.isUpdateAsnInfoInProgress}
                            variant="normal"
                            onClick={props.handleUpdateAsnEdit}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id="submitAsnInfoButton"
                            loading={props.isUpdateAsnInfoInProgress}
                            variant="primary"
                            onClick={props.handleUpdateAsnSubmit}
                        >
                            {props.isUpdateAsnInfoInProgress ? "Submitting..." : "Submit"}
                        </FremontButton>
                    </SpaceBetween>
                }
            >
                ASN Information
            </Header>
        }
    >
        <Container header={<Header variant="h2">General Information</Header>}>
            <ColumnLayout columns={2} borders="vertical">
                <FormField
                    label={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            {requiredLabel("Autonomous System Number:")}
                            {
                                HelperFunctions.renderCustomTooltip(null,
                                    Constants.TOOLTIP_STRINGS.asnNumberEditExplanation)
                            }
                        </SpaceBetween>
                    }
                >
                    <FremontInput
                        id="asnName"
                        value={props.updatedAsn.asnNumber}
                        disabled
                    />
                </FormField>
                <FormField
                    label={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            {requiredLabel("Provider:")}
                            {
                                HelperFunctions.renderCustomTooltip(null,
                                    Constants.TOOLTIP_STRINGS.providerNameEditExplanation)
                            }
                        </SpaceBetween>}
                >
                    <FremontInput
                        value={props.providerName}
                        disabled
                    />
                </FormField>
                <Box margin={{ top: "xxs" }}>
                    <FormField
                        label={requiredLabel("Service(s):")}
                        errorText={props.asnErrorTexts.providerServiceIdList}
                        description="Select one or more services that this ASN is linked to."
                    >
                        <FremontMultiselect
                            id="providerServiceIdList"
                            disabled={props.isUpdateAsnInfoInProgress}
                            options={props.serviceOptions}
                            selectedOptions={props.chosenProviderServices}
                            onChange={props.handleUpdateAsnInputChange}
                            keepOpen
                        />
                    </FormField>
                </Box>
                <FormField
                    label={optionalLabel("Geographic Region(s):")}
                    errorText={props.asnErrorTexts.geographicRegion}
                >
                    <AsnPageGeographicRegionField
                        addRegionButtonDisabled={props.addRegionButtonDisabled}
                        handleAdditionalRegionInput={props.handleAdditionalRegionInput}
                        handleSubtractSpecificRegionObject={props.handleSubtractSpecificRegionObject}
                        hasUpdateAsnSubmittedOnce={props.hasUpdateAsnSubmittedOnce}
                        isUpdateAsnInfoInProgress={props.isUpdateAsnInfoInProgress}
                        handleUpdateAsnInputChange={props.handleUpdateAsnInputChange}
                        regionObjectsShallowCopy={props.regionObjectsShallowCopy}
                        asn={props.asn}
                    />
                </FormField>
                <FormField
                    label={optionalLabel("Peercast Provider Name:")}
                    errorText={props.asnErrorTexts.peercastProviderName}
                >
                    <FremontInput
                        id="peercastProviderName"
                        value={props.updatedAsn.peercastProviderName}
                        disabled={props.isUpdateAsnInfoInProgress}
                        onInput={props.handleUpdateAsnInputChange}
                    />
                </FormField>
            </ColumnLayout>
        </Container>
    </Container>
);

/**
 * This handles what gets rendered in the region/tier 1 selection section of the asn details page
 */
export const AsnPageGeographicRegionField = props => (
    (
        <div>
            { props.regionObjectsShallowCopy.map(regionObject => (
                <div key={regionObject.id}>
                    <Grid
                        gridDefinition={[{ colspan: 5 }, { colspan: 5 }, { colspan: 2 }]}
                    >
                        <FormField
                            errorText={props.hasUpdateAsnSubmittedOnce ? regionObject.errorText : ""}
                            description={Constants.DESCRIPTION_TEXTS.asnRegion}
                        >
                            <FremontSelect
                                id={regionObject.id}
                                disabled={props.isUpdateAsnInfoInProgress}
                                // Limiting the available options to only the regions that have not been chosen yet
                                options={HelperFunctions.createSelectedOptionsWithNone(
                                    Object.values(Constants.GEOGRAPHIC_REGIONS)
                                ).filter(availableRegion =>
                                    !props.regionObjectsShallowCopy.map(selectedRegion =>
                                        selectedRegion.value).includes(availableRegion.label))}
                                selectedOption={{ label: regionObject.value, id: regionObject.value }}
                                onChange={props.handleUpdateAsnInputChange}
                            />
                        </FormField>
                        <FormField
                            errorText={props.hasUpdateAsnSubmittedOnce ? regionObject.asnSessionType.errorText : ""}
                            description={Constants.DESCRIPTION_TEXTS.asnSessionType}
                        >
                            <FremontSelect
                                id={regionObject.asnSessionType.id}
                                disabled={props.isUpdateAsnInfoInProgress}
                                options={Constants.ASN_SESSION_TYPES}
                                selectedOption={regionObject.asnSessionType.value ?
                                    HelperFunctions.createSelectedOption(regionObject.asnSessionType.value)
                                    : {}
                                }
                                onChange={props.handleUpdateAsnInputChange}
                            />
                        </FormField>
                        {/* Need to nest the button in FormField so that it aligns (padding is off otherwise) */}
                        <FormField
                            description={Constants.NO_BREAK_SPACE_UNICODE}
                        >
                            <FremontButton
                                iconName="close"
                                variant="normal"
                                onClick={props.handleSubtractSpecificRegionObject}
                                disabled={props.isUpdateAsnInfoInProgress
                                || props.regionObjectsShallowCopy.length <= 1}
                                id={regionObject.id}
                            />
                        </FormField>
                    </Grid>
                </div>
            ))}

            <Box padding={{ top: "xs" }}>
                <FremontButton
                    iconName="add-plus"
                    variant="normal"
                    onClick={props.handleAdditionalRegionInput}
                    disabled={props.regionObjectsShallowCopy.length === 0 ?
                        false : props.isUpdateAsnInfoInProgress || props.addRegionButtonDisabled}
                    id={AsnValidation.BUTTON_NAMES.addRegionButton}
                />
            </Box>
        </div>
    )
);

export default AsnInformation;