import React, { Component } from "react";
import TemplateGeneratorModal from "templateGenerator/TemplateGeneratorModal";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const interconnectSiteATableHeader = `| Circuit ID | Site A | Router A | Interface A | XC | Demarcations | Provider Circuit ID |
|------------|--------|----------|-------------|----|--------------|---------------------|`;

const interconnectSiteATableRow = `\n| {CIRCUIT} |  {SITE}  | {ROUTER} |   {INTERFACE}  | {XC} |   {DEMARCATIONS}   |  {PROVIDERCIRCUITID}   |`;

const backboneATableHeader = `| Circuit ID | Site A | Router A | Interface A | Patch | Lever A Internal Interface | Lever A | Lever A External Interface | XC | Demarcations | Provider Circuit ID |
|------------|--------|----------|-------------|-------|----------------------------|---------|----------------------------|----|--------------|---------------------|`;

const backboneZTableHeader = `| Circuit ID | Site Z | Router Z | Interface A | Patch | Lever Z Internal Interface | Lever Z | Lever Z External Interface | XC | Demarcations | Provider Circuit ID |
|------------|--------|----------|-------------|-------|----------------------------|---------|----------------------------|----|--------------|---------------------|`;

const backboneTableRow = `\n| {CIRCUIT} | {SITE} | {ROUTER} | {INTERFACE} | <> | {LEVERINTERNAL}           | {LEVER} | {LEVEREXTERNAL}           | {XC} | {DEMARCATIONS} | {PROVIDERCIRCUITID} |`;

const myTemplate = `REQUESTER:

Please attach all required LOAs to the ticket, and provide all the fields below for each cable being requested.

Local Details
{TABLE}

Provider Contact
{CONTACT}
    
Please note any other info below (e.g. non-LR optics, special instructions, etc.):
[OTHER_INFO]
=============================================================
RESOLVER:
Please reference Purchase Order [] when ordering the cross connect and Purchase Order [] when submitting Smart Hands requests.
Before resolution, please attach completion notices (if applicable) and provide all fields below for each cable completed:
Local Patching
    Cage: [AMZN_CAGE]
    Rack: [AMZN_RACK]
    Patch Panel: [AMZN_PP]
    Port: [AMZN_PORT]
    Connector: [AMZN_CONNECTOR]
    Colo Cable ID: [CABLE_ID]
MMR
    Location: [MMR]
    Rack location: [MMR_RACK]
    Patch Panel: [MMR_PP]
    Port: [MMR_PORT]
    Connector type: [MMR_CONNECTOR]`;

const mcmCutsheetTemplateHeader =
    `a_hostname, a_int, a_lever, a_lever_client, a_lever_trunk, a_optic_type, a_connector, a_port_speed, a_fiber_type, z_fiber_type, z_port_speed, z_optic_type, z_connector, z_lever_trunk, z_lever_client, z_lever, z_interface, z_hostname, order_id, circuit_number`;

const mcmCutsheetCSVRow = `\n{ROUTER_A},{INTERFACE_A},{LEVER_A},{LEVER_A_EXTERNAL},{LEVER_A_INTERNAL},"{OPTIC}",{CONNECTOR},{SPEED},{FIBER},{FIBER},{SPEED},{CONNECTOR},"{OPTIC}",{LEVER_Z_INTERNAL},{LEVER_Z_EXTERNAL},{LEVER_Z},{INTERFACE_Z},{ROUTER_Z}, {ORDER}, {CIRCUIT}`;

const mcmTemplate = `{TABLE}`;

export default class TemplateGeneratorHandler extends Component {
    state = {
        template: "",
        templateTypeOptions: [],
        generateTemplateButtonDisabled: false
    }

    componentDidMount() {
        this.templateOptions();
    }

    templateOptions = () => {
        if (this.props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            // options
            this.setState({
                templateTypeOptions: [Constants.TEMPLATE_TYPES.backboneA, Constants.TEMPLATE_TYPES.backboneZ,
                    Constants.TEMPLATE_TYPES.backboneMCMCutsheet],
                selectedTemplateType: ""
            });
        } else {
            this.setState({
                templateTypeOptions: [Constants.TEMPLATE_TYPES.interconnectA],
                selectedTemplateType: Constants.TEMPLATE_TYPES.interconnectA
            });
        }
    }

    generateFabricCutSheet = () => {
        let tableHeader;
        let index;
        let row;

        tableHeader = mcmCutsheetTemplateHeader;

        // Now loop through all the circuits creating the rows for the table
        for (index = 0; index < this.props.circuitDesignObjects.length; index += 1) {
            if (!HelperFunctions.isStageNotStarted(
                this.props.circuitDesignObjects[index].stageStatusMap[Constants.STAGE_NAMES.cabling]
            )) {
                const routerA = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.nodeA
                );
                const routerZ = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.nodeZ
                );
                const interfaceA = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.portA
                );
                const interfaceZ = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.portZ
                );
                const leverA = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.leverA
                );
                const leverZ = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.leverZ
                );
                const leverAExternal = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.leverAExternalInterface
                );
                const leverZExternal = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.leverZExternalInterface
                );
                const leverAInternal = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.leverAInternalInterface
                );
                const leverZInternal = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    Constants.COMPONENT_NAMES.leverZInternalInterface
                );
                row = mcmCutsheetCSVRow;

                row = row.replace("{ROUTER_A}", routerA);
                row = row.replace("{ROUTER_Z}", routerZ);
                row = row.replace("{INTERFACE_A}", interfaceA);
                row = row.replace("{INTERFACE_Z}", interfaceZ);
                row = row.replace("{LEVER_A}", leverA || "n/a");
                row = row.replace("{LEVER_Z}", leverZ || "n/a");
                row = row.replace("{LEVER_A_EXTERNAL}", leverAExternal || "n/a");
                row = row.replace("{LEVER_Z_EXTERNAL}", leverZExternal || "n/a");
                row = row.replace("{LEVER_A_INTERNAL}", leverAInternal || "n/a");
                row = row.replace("{LEVER_Z_INTERNAL}", leverZInternal || "n/a");
                row = row.replaceAll("{CONNECTOR}", this.props.order.connectorType);
                row = row.replaceAll("{SPEED}", this.props.circuitDesignObjects[index].circuitBandwidth);
                row = row.replaceAll("{FIBER}", this.props.order.fiberType);
                row = row.replaceAll("{OPTIC}", this.props.order.linkHandoff);
                row = row.replace("{ORDER}", this.props.order.orderId);
                row = row.replace("{CIRCUIT}", this.props.circuitDesignObjects[index].circuitDesignNumber);

                tableHeader = tableHeader.concat(row);
            }
        }

        const template = mcmTemplate.replace("{TABLE}", tableHeader);

        this.setState({
            template
        });
    }

    generateTemplate = () => {
        // copy the myTemplate object
        let row;
        let index;
        let tableHeader;

        // Initialize the header with the proper header.
        if (Constants.TEMPLATE_TYPES.interconnectA === this.state.selectedTemplateType) {
            tableHeader = interconnectSiteATableHeader;
        } else if (Constants.TEMPLATE_TYPES.backboneA === this.state.selectedTemplateType) {
            tableHeader = backboneATableHeader;
        } else {
            tableHeader = backboneZTableHeader;
        }

        // Now loop through all the circuits creating the rows for the table
        for (index = 0; index < this.props.circuitDesignObjects.length; index += 1) {
            const { circuitDesignNumber } = this.props.circuitDesignObjects[index];
            const router = HelperFunctions.getDisplayValueFromComponentName(
                this.props.componentIdToObjectMap,
                this.props.circuitDesignObjects[index].positionMap,
                this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneZ ?
                    Constants.COMPONENT_NAMES.nodeZ : Constants.COMPONENT_NAMES.nodeA
            );
            const site = HelperFunctions.getDisplayValueFromComponentName(
                this.props.componentIdToObjectMap,
                this.props.circuitDesignObjects[index].positionMap,
                this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneZ ?
                    Constants.COMPONENT_NAMES.siteZ : Constants.COMPONENT_NAMES.siteA
            );
            const node = HelperFunctions.getDisplayValueFromComponentName(
                this.props.componentIdToObjectMap,
                this.props.circuitDesignObjects[index].positionMap,
                this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneZ ?
                    Constants.COMPONENT_NAMES.portZ : Constants.COMPONENT_NAMES.portA
            );
            const demarc = HelperFunctions.getDemarcAndCfaDisplayValue(
                this.props.circuitDesignObjects[index].positionMap,
                this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneZ ?
                    Constants.COMPONENT_NAMES.demarcAndCfaZ : Constants.COMPONENT_NAMES.demarcAndCfaA,
                this.props.order,
                this.props.componentIdToObjectMap
            );
            const providerCircuitId = HelperFunctions.getProviderCircuitDisplayValue(
                this.props.circuitDesignObjects[index].positionMap,
                Constants.COMPONENT_NAMES.providerCircuitA,
                this.props.order,
                this.props.componentIdToObjectMap
            );
            const crossConnect = HelperFunctions.getCrossConnectDisplayValue(
                this.props.circuitDesignObjects[index].positionMap,
                this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneZ ?
                    Constants.COMPONENT_NAMES.crossConnectZ : Constants.COMPONENT_NAMES.crossConnectA
            );

            // We need a new row for each circuit, so we initialize the row here.
            if (this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.interconnectA) {
                row = interconnectSiteATableRow;
            } else {
                row = backboneTableRow;
            }

            // These are the common columns between the Interconnect and Backbone types
            row = row.replace("{CIRCUIT}", circuitDesignNumber);
            row = row.replace("{ROUTER}", router);
            row = row.replace("{SITE}", site);
            row = row.replace("{INTERFACE}", node);
            row = row.replace("{DEMARCATIONS}", demarc);
            row = row.replace("{PROVIDERCIRCUITID}", providerCircuitId);
            row = row.replace("{XC}", crossConnect);

            // The backbone types have a few extra items
            if ([Constants.TEMPLATE_TYPES.backboneA, Constants.TEMPLATE_TYPES.backboneZ]
                .includes(this.state.selectedTemplateType)) {
                const leverExternal = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneA ?
                        Constants.COMPONENT_NAMES.leverAExternalInterface :
                        Constants.COMPONENT_NAMES.leverZExternalInterface
                );
                const lever = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneA ?
                        Constants.COMPONENT_NAMES.leverA :
                        Constants.COMPONENT_NAMES.leverZ
                );
                const leverInternal = HelperFunctions.getDisplayValueFromComponentName(
                    this.props.componentIdToObjectMap,
                    this.props.circuitDesignObjects[index].positionMap,
                    this.state.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneA ?
                        Constants.COMPONENT_NAMES.leverAInternalInterface :
                        Constants.COMPONENT_NAMES.leverZInternalInterface
                );

                row = row.replace("{LEVEREXTERNAL}", leverExternal);
                row = row.replace("{LEVER}", lever);
                row = row.replace("{LEVERINTERNAL}", leverInternal);
            }
            tableHeader = tableHeader.concat(row);
        }
        // finally update the main template here.
        let template = myTemplate.replace("{TABLE}", tableHeader);

        const contactName = `${this.props.orderContact}`;
        const contactEmail = `\n${this.props.orderContactEmail}`;
        const contact = contactName.concat(contactEmail);
        template = template.replace("{CONTACT}", contact);

        this.setState({
            template
        });
    }

    handleChoosingTemplateType = (evt) => {
        let generateTemplateButtonDisabled = false;
        // if the template type === backboneMCMCutsheet &&
        // disable the generateTemplate button until at least one of the circuits reaches the cabling stage
        if (evt.detail.selectedOption.value === Constants.TEMPLATE_TYPES.backboneMCMCutsheet
            && this.props.circuitDesignObjects.length > 0 && this.props.circuitDesignObjects.every(circuit =>
                HelperFunctions.isStageNotStarted(circuit.stageStatusMap[Constants.STAGE_NAMES.cabling]))) {
            generateTemplateButtonDisabled = true;
        }
        this.setState({
            selectedTemplateType: evt.detail.selectedOption.value,
            generateTemplateButtonDisabled
        });
    }

    downloadCSV = () => {
        const siteAName = this.props.componentIdToObjectMap[this.props.order.siteAId].siteName;
        const siteZName = this.props.componentIdToObjectMap[this.props.order.siteZId].siteName;

        const fileName = `${siteAName}<>${siteZName}-${this.props.circuitDesignObjects.length}.csv`;
        const data = this.state.template;
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: "txt/csv" });
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        a.remove();
    }

    doCopy = async () => {
        // Making use of the Clipboard API writeData(): https://www.w3.org/TR/clipboard-apis/#dom-clipboard-writetext
        await navigator.clipboard.writeText(this.state.template);
    }

    render() {
        return (
            <TemplateGeneratorModal
                isTemplateGeneratorModalClicked={this.props.isTemplateGeneratorModalClicked}
                hideTemplateGeneratorModal={this.props.hideTemplateGeneratorModal}
                serviceType={this.props.order.serviceType}
                orderType={this.props.order.orderType}
                generateTemplate={this.generateTemplate}
                generateFabricCutSheet={this.generateFabricCutSheet}
                template={this.state.template}
                doCopy={this.doCopy}
                downloadCSV={this.downloadCSV}
                templateTypeOptions={this.state.templateTypeOptions}
                selectedTemplateType={this.state.selectedTemplateType}
                generateTemplateButtonDisabled={this.state.generateTemplateButtonDisabled}
                handleChoosingTemplateType={this.handleChoosingTemplateType}
            />
        );
    }
}