import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontBox,
    FremontButton,
    FremontCheckbox,
    requiredLabel
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const McmExecutionInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <FremontBox label="Decommissioning Mcm Executed:"/>
                        <div>{props.order.opticalMetaMap[Constants.ATTRIBUTES.isDecommissioningMcmExecuted] &&
                        props.order.opticalMetaMap[Constants.ATTRIBUTES.isDecommissioningMcmExecuted]
                        === Constants.TRUE_STRING ? Constants.YES_NO.yes : Constants.YES_NO.no}
                        </div>
                    </div>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const McmExecutionInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Mcm Execution
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        label={requiredLabel("Decommissioning Mcm Executed:")}
                    >
                        <FremontCheckbox
                            id={Constants.ATTRIBUTES.isDecommissioningMcmExecuted}
                            checked={props.updatedOrder.opticalMetaMap[
                                Constants.ATTRIBUTES.isDecommissioningMcmExecuted
                            ]}
                            onChange={props.handleStageInputChange}
                            disabled={props.isUpdateStageInProgress}
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
