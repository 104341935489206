import React, { Component } from "react";
import Constants from "utils/Constants";
import TagModal from "tag/TagModal";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";

export default class TagHandler extends Component {
    static DEFAULT_TAG_ERROR_TEXTS = {
        tagName: Constants.ERROR_STRINGS.blankInput
    };

    state = {
        activeTag: {
            requiredCompletionDate: ""
        },
        allTagFieldsDisabled: false,
        isOrderTagModalClicked: false,
        hasSubmittedTagOnce: false,
        errorToDisplayOnTagModal: "",
        tagErrorTexts: TagHandler.DEFAULT_TAG_ERROR_TEXTS
    };

    componentDidMount() {
        this.resetTagModal(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOrderTagModalClicked !== this.props.isOrderTagModalClicked) {
            this.resetTagModal(this.props);
        }
    }

    resetTagModal = (props) => {
        this.setState({
            activeTag: {
                requiredCompletionDate: "",
                status: Constants.STATUS.active,
                orderIdListToAddFromRequest: [this.props.order.orderId]
            },
            errorToDisplayOnTagModal: "",
            allTagFieldsDisabled: false,
            hasSubmittedTagOnce: false,
            isOrderTagModalClicked: props.isOrderTagModalClicked,
            tagErrorTexts: TagHandler.DEFAULT_TAG_ERROR_TEXTS
        });
    };

    /**
     * This method handles input changes to the tag modal
     */
    handleTagModalInputChange = (evt) => {
        const attributeId = evt.target.id;
        const activeTag = HelperFunctions.deepClone(this.state.activeTag);
        const tagErrorTexts = HelperFunctions.deepClone(this.state.tagErrorTexts);

        // Handle select fields
        let inputValue;
        if (attributeId === Constants.ATTRIBUTES.status) {
            inputValue = evt.detail.selectedOption.value;
        }
        if (attributeId === Constants.ATTRIBUTES.tagName || attributeId === Constants.ATTRIBUTES.description
            || attributeId === Constants.ATTRIBUTES.requiredCompletionDate) {
            inputValue = evt.detail.value;
        }
        // If the input value is empty, pass null as the value into the tag object so a backend error doesn't
        // trigger (dynamo doesn't like blank strings)
        activeTag[attributeId] = inputValue || null;

        // Here we update the error messages for the required fields
        if (attributeId === Constants.ATTRIBUTES.tagName || attributeId === Constants.ATTRIBUTES.status) {
            if (!inputValue) {
                tagErrorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
            } else {
                tagErrorTexts[attributeId] = "";
            }
        }

        this.setState({ activeTag, tagErrorTexts });
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This function submits a createTag request to the backend
     */
    handleTagSubmit = async () => {
        this.setState({
            hasSubmittedTagOnce: true,
            errorToDisplayOnTagModal: "",
            allTagFieldsDisabled: true
        });

        // If any error messages exist, we display a flashbar error and any error texts
        if (Object.values(this.state.tagErrorTexts).some(errorText => errorText)) {
            this.setState({
                errorToDisplayOnTagModal: Constants.FLASHBAR_STRINGS.flashbarInvalidInput,
                allTagFieldsDisabled: false
            });
            return;
        }

        // Here we create the createTagRequest
        const tagToSubmit = HelperFunctions.deepClone(this.state.activeTag);

        try {
            // Create the tag and reload all the tags
            await this.FremontBackendClient.modifyTag([tagToSubmit], this.props.auth);
            await this.props.getAllTagItems();
            await this.props.getOrder();

            // Show the success notification and hide the modal
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText);
            this.props.hideTagModal();
        } catch (error) {
            this.setState({ errorToDisplayOnTagModal: error.message });
        }

        // Make sure all the tag fields are disabled
        this.setState({ allTagFieldsDisabled: false });
    };

    render() {
        return (
            <TagModal
                activeTag={this.state.activeTag}
                allTagFieldsDisabled={this.state.allTagFieldsDisabled}
                isOrderTagModalClicked={this.state.isOrderTagModalClicked}
                hasSubmittedTagOnce={this.state.hasSubmittedTagOnce}
                errorToDisplay={this.state.errorToDisplayOnTagModal}
                tagErrorTexts={this.state.hasSubmittedTagOnce
                    ? this.state.tagErrorTexts : {}}
                handleTagSubmit={this.handleTagSubmit}
                handleTagModalInputChange={this.handleTagModalInputChange}
                hideTagModal={this.props.hideTagModal}
            />
        );
    }
}