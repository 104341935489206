import React, { Component } from "react";
import { Flashbar, SpaceBetween, Alert } from "@amzn/awsui-components-react/polaris";
import CutsheetForm from "./CutsheetForm";
import HelperFunctions from "../common/HelperFunctions";
import Constants from "../utils/Constants";
import LinkServiceModal from "../components/LinkServiceModal";
import { LinkServiceButton, LinkServiceToggle } from "../components/CommonComponents";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";

export default class CutsheetUploadHandler extends Component {
    state = {
        fileNameToFileMap: {},
        cutsheets: [],
        cutsheetToReactRefMap: {},
        errorToDisplayOnCutsheetModal: [],
        isAttachmentSubmissionInProgress: false,
        disableSubmitButton: true,
        handleStaleLinks: false,
        handleThirdPartyLinks: false
    }
    componentDidUpdate(prevProps) {
        if (prevProps.isCutsheetUploadButtonClicked !== this.props.isCutsheetUploadButtonClicked) {
            this.updateCutsheetState();
        }
    }

    getUniqueKey = (cutsheetName, cutsheetType) => (`${cutsheetName}${Constants.SEPARATOR}${cutsheetType}`);

    linkServiceBackendClient = new LinkServiceBackendClient()

    updateCutsheetState = () => {
        this.setState({
            fileNameToFileMap: {},
            cutsheets: [],
            cutsheetToReactRefMap: {},
            errorToDisplayOnCutsheetModal: [],
            handleStaleLinks: false,
            handleThirdPartyLinks: false
        });
    }

    addNewCutsheet = () => {
        const cutsheetsClone = HelperFunctions.deepClone(this.state.cutsheets);
        const originalLength = cutsheetsClone.length;
        const cutsheetKey = `cutsheet${originalLength}`;
        cutsheetsClone.push({
            fileName: "",
            contentType: "",
            contentLength: "",
            cutsheetType: "",
            cutsheetKey,
            hasCutsheetBeenModified: false,
            errorTexts: {}
        });

        this.setState({
            errorToDisplayOnCutsheetModal: [],
            cutsheets: cutsheetsClone,
            // Object.assign() is merging objects, not copying them
            cutsheetToReactRefMap: Object.assign(this.state.cutsheetToReactRefMap,
                { [cutsheetKey]: React.createRef() })
        });
    };

    handleCutsheetTypeSelectChange = (evt) => {
        const cutsheetsClone = HelperFunctions.deepClone(this.state.cutsheets);
        const { fileNameToFileMap } = this.state;

        const elementId = evt.target.id;
        const index = evt.target.id.match(/\d+$/)[0];
        const cutsheetToModify = cutsheetsClone[index];

        // We can repopulate the errors depending on the user action, so resetting it here
        this.setState({
            errorToDisplayOnCutsheetModal: []
        });

        if (elementId.includes("type")) {
            const cutsheetType = evt.detail.selectedOption[Constants.POLARIS_UTILS_OPTIONS.VALUE_KEY];

            if (cutsheetToModify.cutsheetType) {
                cutsheetToModify.errorTexts = {};
            }

            // If we are changing the cutsheet type, we must also handle updating the key value in the
            // fileNameToFileMap map in state to ensure the cutsheet gets persisted as expected
            const oldUniqueKey = this.getUniqueKey(cutsheetToModify.fileName, cutsheetToModify.cutsheetType);
            if (fileNameToFileMap[oldUniqueKey]) {
                const newUniqueKey = this.getUniqueKey(cutsheetToModify.fileName, cutsheetType);

                // Check if cutsheet filename/cutsheet type combo exists already and display warning if it exists
                // We will not change the cutsheet type if there is a duplicate cutsheet key
                if (this.state.fileNameToFileMap[newUniqueKey]) {
                    this.setState({
                        errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal.concat(
                            HelperFunctions.generateWarningMessageForFlashbar(
                                "Redundant/Duplicate cutsheet.",
                                `Cannot set cutsheet of this name: ${cutsheetToModify.fileName} to type 
                                ${Constants.CUTSHEET_TYPE_LABELS[cutsheetType]} as it already exists in the upload 
                                form. Please rename the cutsheet/select a different cutsheet type`
                            )
                        )
                    });
                    return;
                }
                fileNameToFileMap[newUniqueKey] = fileNameToFileMap[oldUniqueKey];
                delete fileNameToFileMap[oldUniqueKey];
            }

            cutsheetToModify.cutsheetType = cutsheetType;
            cutsheetToModify.hasCutsheetBeenModified = true;
            cutsheetToModify.errorTexts.cutsheetType = cutsheetType
                ? "" : "This is a required input and cannot be blank.";
        }

        // Update the state
        this.setState({
            cutsheets: cutsheetsClone,
            fileNameToFileMap
        });
    };

    triggerFileInput = (evt, ref) => {
        const cutsheetIndex = evt.target.id.match(/\d+$/)[0];
        this.setState({
            cutsheetIndexBeingModified: cutsheetIndex, errorToDisplayOnCutsheetModal: []
        });
        ref.current.click();
    };

    handleFileInput = (evt) => {
        const cutsheetsClone = HelperFunctions.deepClone(this.state.cutsheets);

        // Get files from input element and cutsheet type from last cutsheet object in the list
        const { files } = evt.target;
        const { cutsheetType } = cutsheetsClone[cutsheetsClone.length - 1];

        if (files.length > 0) {
            for (let index = 0; index < files.length; index += 1) {
                const contentLength = files[index].size;
                if (contentLength > Constants.MAX_CUTSHEET_SIZE) {
                    this.setState({
                        errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal.concat(
                            HelperFunctions.generateErrorMessageForFlashbar(
                                "File sizes larger than 50MB are not allowed.",
                                "Upload a file with file size less than 50MB"
                            )
                        )
                    });
                    return;
                }
                // Validate that its a file that we support
                const contentType = files[index].type;
                if (!Constants.VALID_CUTSHEET_CONTENT_TYPES.includes(contentType)) {
                    this.setState({
                        errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal.concat(
                            HelperFunctions.generateErrorMessageForFlashbar(
                                "Unable to upload unsupported file type.",
                                "Supported file type is csv"
                            )
                        )
                    });
                    return;
                }

                const newIndex = HelperFunctions.parseInt(this.state.cutsheetIndexBeingModified) + index;
                cutsheetsClone[newIndex].fileName = files[index].name;
                cutsheetsClone[newIndex].contentType = contentType;
                cutsheetsClone[newIndex].contentLength = contentLength;
                cutsheetsClone[newIndex].errorTexts.file = "";
                cutsheetsClone[newIndex].errorTexts.cutsheetType = "";
                cutsheetsClone[newIndex].hasCutsheetBeenModified = true;

                // We're generating a unique key to make sure only a single file name is associated with a single
                // cutsheetType.
                const uniqueKey = this.getUniqueKey(files[index].name, cutsheetType);
                if (this.state.fileNameToFileMap[uniqueKey]) {
                    this.setState({
                        errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal.concat(
                            HelperFunctions.generateErrorMessageForFlashbar(
                                "Redundant/Duplicate cutsheet.",
                                "Cutsheet of this name is already in use for same cutsheet type."
                            )
                        )
                    });
                    return;
                }

                this.setState({
                    // We store the file object in a separate map because File objects do not get copied during the
                    // HelperFunctions.deepClone method which we use liberally on the cutsheet objects
                    fileNameToFileMap: Object.assign(this.state.fileNameToFileMap,
                        { [uniqueKey]: files[index] }),
                    disableSubmitButton: false
                });
            }
            // If we break out the loop early due to error messages. We will never get to this point
            // and cutsheetsClone will be thrown in the garbage
            this.setState({
                cutsheets: cutsheetsClone
            });
        }
    };

    removeCutsheet = (evt) => {
        let cutsheetsClone = HelperFunctions.deepClone(this.state.cutsheets);
        const cutsheetKeyToRemove = evt.target.id.replace("removeCutsheet", "");
        // get the cutsheet index, its at the index at the end fo cutsheet0...etc,
        // we need to also remove it from fileNameToFileMap
        const cutsheetToRemove = this.state.cutsheets[cutsheetKeyToRemove.replace("cutsheet", "")];
        const uniqueKey = this.getUniqueKey(cutsheetToRemove.fileName, cutsheetToRemove.cutsheetType);
        if (this.state.fileNameToFileMap[uniqueKey]) {
            const updatedFileNameToFileMap = Object.assign({}, this.state.fileNameToFileMap);
            delete updatedFileNameToFileMap[uniqueKey];
            this.setState({
                fileNameToFileMap: updatedFileNameToFileMap
            });
        }

        // Remove that cutsheet and reset the cutsheetKey indices
        cutsheetsClone = cutsheetsClone
            .filter(cutsheet => cutsheet.cutsheetKey !== cutsheetKeyToRemove);
        cutsheetsClone.forEach((cutsheet, index) =>
            Object.assign(cutsheet, { cutsheetKey: `cutsheet${index}` }));

        const cutsheetToReactRefMap = {};
        cutsheetsClone.forEach(cutsheet =>
            Object.assign(cutsheetToReactRefMap,
                { [cutsheet.cutsheetKey]: React.createRef() }));
        this.setState({
            cutsheetToReactRefMap
        });
        this.setState({
            cutsheets: cutsheetsClone,
            errorToDisplayOnCutsheetModal: [],
            handleStaleLinks: false,
            handleThirdPartyLinks: false
        });
    };

    processCutsheetUpload = async (cutsheets) => {
        try {
            // eslint-disable-next-line no-restricted-syntax
            for (const cutsheet of cutsheets) {
                const { fileName, cutsheetType } = cutsheet;
                // Once we pass all of the validations we call the getFileUploadUrl API
                // eslint-disable-next-line no-await-in-loop
                const getFileUploadUrlResponse = await this.linkServiceBackendClient.getFileUploadUrl(
                    fileName,
                    Constants.FILE_TYPES.cutsheet, // File type will always be cutsheet from this handler
                    cutsheetType // The cutsheet type (cph, c2c, etc.) is the fileSubType
                );

                // Upload file to S3
                // eslint-disable-next-line no-await-in-loop
                await this.uploadSingleAttachment(
                    getFileUploadUrlResponse.PresignedUrl,
                    this.state.fileNameToFileMap[this.getUniqueKey(fileName, cutsheetType)]
                );

                // Make call to LinkService to process the cutsheet, but if processing takes long don't wait
                // for LinkService response. Instead display message directing user to refresh in a bit.
                const putLinksResponsePromise = this.linkServiceBackendClient.putLinksFromFile(
                    getFileUploadUrlResponse.FileId,
                    this.state.handleStaleLinks,
                    this.state.handleThirdPartyLinks
                );
                const maxUiProcessingTimePromise = new Promise((resolve) => {
                    setTimeout(
                        resolve,
                        Constants.MAX_UI_PROCESSING_SECONDS * 1000,
                        Constants.ERROR_STRINGS.UI_PROCESSING_TIME_EXCEEDED
                    );
                });

                // eslint-disable-next-line no-await-in-loop
                const cutsheetProcessingResult = await Promise.race([
                    putLinksResponsePromise,
                    maxUiProcessingTimePromise
                ]);

                if (cutsheetProcessingResult === Constants.ERROR_STRINGS.UI_PROCESSING_TIME_EXCEEDED) {
                    this.setState({
                        errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal
                            .concat(HelperFunctions.generateSuccessMessageForFlashbar(
                                `Processing file with ID ${getFileUploadUrlResponse.FileId}`,
                                "Please refresh the page to check the status."
                            ))

                    });

                    // refresh the the page after 3 seconds, so user can view the processing status
                    // eslint-disable-next-line no-loop-func
                    HelperFunctions.callWithDelay(() => window.location.reload(), 3);
                } else if (cutsheetProcessingResult.FileMetadata !== undefined) {
                    // At this point, we have successfully uploaded the file and processed it. Show result here
                    const response = cutsheetProcessingResult.FileMetadata;
                    if (response.fileStatus.toLowerCase() === "SUCCEEDED".toLowerCase()) {
                        this.setState({
                            errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal
                                .concat(HelperFunctions.generateSuccessMessageForFlashbar(
                                    `Successfully processed and uploaded file with ID ${response.fileId}`,
                                    `Number of links created: ${cutsheetProcessingResult.LinkIDs.length}`
                                ))
                        });
                    } else {
                        this.setState({
                            errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal
                                .concat(HelperFunctions.generateErrorMessageForFlashbar(
                                    `File ${response.fileId} was uploaded but failed to process. 
                                    The file status is ${response.fileStatus}`,
                                    `Failure reason is : ${response.fileFailureReason}`
                                ))
                        });
                    }
                } else {
                    this.setState({
                        errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal
                            .concat(HelperFunctions.generateErrorMessageForFlashbar(
                                "Unable to process uploaded file(s).",
                                "Did not get a valid response from LinkService"
                            ))
                    });
                }
            }
        } catch (error) {
            this.setState({
                errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal
                    .concat(HelperFunctions.generateErrorMessageForFlashbar(
                        "Unable to process uploaded file(s).",
                        error.message
                    )),
                handleStaleLinks: false,
                handleThirdPartyLinks: false
            });
        }

        // Once a cutsheet is uploaded successfully or not, we don't want customers to resubmit that file. So we
        // set the upload button to be disabled by setting `disableSubmitButton=true`
        this.setState({
            isAttachmentSubmissionInProgress: false,
            disableSubmitButton: true,
            handleStaleLinks: false,
            handleThirdPartyLinks: false
        });
    }

    handleCutsheetSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, {
            errorToDisplayOnCutsheetModal: [],
            isAttachmentSubmissionInProgress: true
        });

        // If a cutsheet type has not been selected, an error should be thrown for that field
        const cutsheetsClone = HelperFunctions.deepClone(this.state.cutsheets);
        if (cutsheetsClone.length < 1) {
            await this.setState({
                errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal.concat(
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Missing cutsheets",
                        "No cutsheets are added to submit"
                    )
                )
            });
        }

        let isCutsheetUploadValid = true;
        cutsheetsClone.forEach((cutsheet) => {
            if (!cutsheet.fileName || !cutsheet.cutsheetType) {
                isCutsheetUploadValid = false;
            }
        });

        if (!isCutsheetUploadValid) {
            await this.setState({
                errorToDisplayOnCutsheetModal: this.state.errorToDisplayOnCutsheetModal.concat(
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Missing required options",
                        "Cutsheet upload form is missing either the cutsheet type or cutsheet file"
                    )
                ),
                handleStaleLinks: false,
                handleThirdPartyLinks: false
            });
        }

        // At this point, all validations have passed. Close current modal and open cutsheet status modal
        if (this.state.errorToDisplayOnCutsheetModal.length === 0) {
            await this.processCutsheetUpload(cutsheetsClone);
        }
    }

    handleCutsheetToggleChange = () => {
        this.setState({ handleStaleLinks: !this.state.handleStaleLinks });
    }

    handleThirdPartyToggleChange = () => {
        this.setState({ handleThirdPartyLinks: !this.state.handleThirdPartyLinks });
    }

    handleCutsheetDismiss= () => {
        this.setState({ handleStaleLinks: false, handleThirdPartyLinks: false });
    }

    uploadSingleAttachment = (presignedUrl, body) =>
        fetch(presignedUrl, {
            method: "PUT",
            body
        });

    render() {
        return (
            <LinkServiceModal
                isModalVisible={this.props.isCutsheetUploadButtonClicked}
                onDismiss={this.props.hideCutsheetUploadModal && this.handleCutsheetDismiss}
                header="Upload Cutsheet Form"
                onCancelButtonClick={this.props.hideCutsheetUploadModal}
                onSubmitButtonClick={this.handleCutsheetSubmit}
                isAttachmentSubmissionInProgress={this.state.isAttachmentSubmissionInProgress}
                disableSubmitButton={this.state.disableSubmitButton}
            >
                <Flashbar items={this.state.errorToDisplayOnCutsheetModal}/>
                <SpaceBetween size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_CONTAINER_PADDING}>
                    <div>
                        {this.state.cutsheets.map(cutsheet => (
                            <div key={cutsheet.cutsheetKey}>
                                <CutsheetForm
                                    cutsheet={cutsheet}
                                    cutsheetOptions={HelperFunctions.createSelectedOptionsForAttachments(
                                        Object.values(Constants.CUTSHEET_TYPES), this.props.user
                                    )}
                                    isAttachmentSubmissionInProgress={this.state.isAttachmentSubmissionInProgress}
                                    disableSubmitButton={this.state.disableSubmitButton}
                                    fileInputRef={
                                        this.state.cutsheetToReactRefMap[cutsheet.cutsheetKey]}
                                    handleFileInput={this.handleFileInput}
                                    triggerFileInput={this.triggerFileInput}
                                    handleCutsheetTypeSelectChange={this.handleCutsheetTypeSelectChange}
                                    removeCutsheet={this.removeCutsheet}
                                    user={this.props.user}
                                />
                            </div>
                        ))}
                    </div>
                    {this.state.cutsheets.length < 1 &&
                        <LinkServiceButton
                            id="addNewCutsheet"
                            onClick={this.addNewCutsheet}
                            disabled={false}
                        >
                        Add New Cutsheet
                        </LinkServiceButton>}
                    <div>
                        {this.state.handleStaleLinks &&
                            <Alert
                                statusIconAriaLabel="Warning"
                                type="warning"
                                header={Constants.CUTSHEET_HANDLE_STALE_LINKS_TOGGLE_MESSAGE}
                            />}
                        <LinkServiceToggle
                            id="handleStaleLinksToggle"
                            disabled={false}
                            checked={this.state.handleStaleLinks}
                            onChange={this.handleCutsheetToggleChange}
                        >
                            Delete Stale Links
                        </LinkServiceToggle>
                        {this.state.handleThirdPartyLinks &&
                            <Alert
                                statusIconAriaLabel="Warning"
                                type="warning"
                                header={Constants.CUTSHEET_HANDLE_THIRD_PARTY_LINKS_TOGGLE_MESSAGE}
                            />
                        }
                        <LinkServiceToggle
                            id="handleThirdPartyLinksToggle"
                            disabled={false}
                            checked={this.state.handleThirdPartyLinks}
                            onChange={this.handleThirdPartyToggleChange}
                        >
                            Third Party Links
                        </LinkServiceToggle>
                    </div>
                </SpaceBetween>
            </LinkServiceModal>
        );
    }
}
