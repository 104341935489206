import React from "react";
import { Button, Icon, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import { LinkServiceExpandableSection } from "../components/CommonComponents";

export const BACKEND_CUTSHEET_UPLOAD_STATUS = {
    SUCCEEDED: "Success",
    FAILED: "Failed",
    PROCESSING: "Processing",
    NOT_PROCESSED: "Not Processed",
    UNKNOWN: "Unknown"
};

export const CUTSHEET_UPLOAD_STATUS_ICON_PROPS = {
    SUCCEEDED: {
        name: "status-positive",
        variant: "success"
    },
    FAILED: {
        name: "status-negative",
        variant: "error"
    },
    PROCESSING: {
        name: "status-pending"
    },
    NOT_PROCESSED: {
        name: "status-pending",
        variant: "disabled"
    },
    UNKNOWN: {
        name: "status-stopped",
        variant: "disabled"
    }
};

export const renderCutsheetStatus = (cutsheetStatus) => {
    let validUploadStatus = cutsheetStatus;
    if (!(cutsheetStatus in CUTSHEET_UPLOAD_STATUS_ICON_PROPS)) { validUploadStatus = "UNKNOWN"; }
    return (
        <SpaceBetween
            direction="horizontal"
            size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_BUTTON_PADDING}
        >
            <Icon
                name={CUTSHEET_UPLOAD_STATUS_ICON_PROPS[validUploadStatus].name}
                size="medium"
                variant={CUTSHEET_UPLOAD_STATUS_ICON_PROPS[validUploadStatus].variant}
            />
            {BACKEND_CUTSHEET_UPLOAD_STATUS[validUploadStatus]}
        </SpaceBetween>
    );
};


export default class CutsheetTableData {
    static renderTimeFromTimestamp = (timestamp) => {
        // TODO convert timestamp to date and time string
        if (timestamp !== undefined) {
            const time = new Date(timestamp * 1000);
            return time.toUTCString();
        }
        return timestamp;
    };

    static getCreatedByFromActor = (item) => {
        // Try to derive the createdBy field from multiple attributes of the actor object.
        // The order is in descending order of precision with which we can identify a user/system
        if (item.actor !== undefined) {
            if (item.actor.OriginatingUser !== undefined) return item.actor.OriginatingUser;
            if (item.actor.CallingSystem !== undefined) return item.actor.CallingSystem;
            if (item.actor.Cti !== undefined) return Object.values(item.actor.Cti).join(",");
            if (item.actor.CallingServicePrincipal !== undefined) return item.actor.CallingServicePrincipal;
        }
        return undefined;
    };

    static renderFileFailureReason(fileFailureReason) {
        if (fileFailureReason === undefined) {
            return "None";
        } else if (fileFailureReason.length < Constants.MAX_CUTSHEET_FILE_FAILURE_REASON_LENGTH) {
            return fileFailureReason;
        }
        return (
            <LinkServiceExpandableSection
                headerText="Expand Failure Reason"
            >
                {fileFailureReason}
            </LinkServiceExpandableSection>
        );
    }

    static COLUMN_DEFINITIONS = [
        {
            id: "cutsheetName",
            sortingField: "fileName",
            header: "Cutsheet Name",
            description: "Name of Cutsheet",
            cell: item => item.fileName
        },
        {
            id: "cutsheetType",
            sortingField: "fileSubType",
            header: "Cutsheet Type",
            description: "Cutsheet Type",
            cell: item => item.fileSubType
        },
        {
            id: "cutsheetStatus",
            sortingField: "fileStatus",
            header: "Cutsheet Status",
            description: "Cutsheet processed status",
            cell: item => renderCutsheetStatus(item.fileStatus),
            width: 200
        },
        {
            id: "createdBy",
            header: "Created By",
            width: 250,
            description: "Displays who uploaded the cutsheet",
            cell: item => CutsheetTableData.getCreatedByFromActor(item)
        },
        {
            id: "createdTime",
            sortingField: "createdTime",
            header: "Created Time",
            description: "Displays when the cutsheet was uploaded",
            width: 250,
            cell: item => CutsheetTableData.renderTimeFromTimestamp(item.createdTime)
        },
        {
            id: "failureReason",
            header: "Failure Reason",
            description: "Displays if the cutsheet has a failure reason",
            width: 250,
            cell: item => CutsheetTableData.renderFileFailureReason(item.fileFailureReason)
        },
        {
            id: "cutsheetId",
            header: "Cutsheet ID",
            description: "Cutsheet Identifier",
            cell: item => item.fileId,
            width: 200
        },
        {
            id: "cutsheetContent",
            header: "Download Cutsheet",
            description: "Download contents of cutsheet",
            cell: item => (
                <Button
                    variant="icon"
                    iconName="file"
                    disabled={item.isDownloadingFile}
                    onClick={() => item.downloadFile(item.fileId)}
                />
            )
        }
    ];
}