import React from "react";
import {
    Box,
    Container,
    FormField,
    Grid
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const TopologyHeader = () => (
    (
        <div key={`${0}${Constants.SEPARATOR}${0}`}>
            <Grid gridDefinition={
                [
                    { colspan: { default: 2 } },
                    { colspan: { default: 1 } },
                    { colspan: { default: 1 } },
                    { colspan: { default: 1 } },
                    { colspan: { default: 2 } },
                    { colspan: { default: 1 } },
                    { colspan: { default: 4 } }
                ]
            }
            >
                <div>
                    Order
                </div>
                <div>
                    Circuit(s)
                </div>
                <div>
                    Site A
                </div>
                <div>
                    Site Z
                </div>
                <div>
                    Project Type
                </div>
                <div>
                    Span Name
                </div>
            </Grid>
        </div>
    )
);

export const determineSiteToDisplay = (
    isSiteA, topologyObject, orderMap, siteOptions, componentIdToObjectMap
) => {
    const site = componentIdToObjectMap[topologyObject[isSiteA ? "siteAId" : "siteZId"]].siteName;
    return { label: site, value: site };
};

export const determineProjectTypeToDisplay = (topologyObject, orderMap) => {
    if (topologyObject.orderId && orderMap[topologyObject.orderId]) {
        return orderMap[topologyObject.orderId].projectType;
    }
    return topologyObject.projectType;
};

export const TopologyRow = (props) => {
    const {
        arrayPosition,
        topologyMap,
        topologyMapPosition,
        providerCircuitNameToOrderIdsMap
    } = props;
    return (
        (
            <div
                key={`${props.topologyMapPosition}${Constants.SEPARATOR}${props.arrayPosition}`}
            >
                <Grid gridDefinition={
                    [
                        { colspan: { default: 2 } },
                        { colspan: { default: 1 } },
                        { colspan: { default: 1 } },
                        { colspan: { default: 1 } },
                        { colspan: { default: 2 } },
                        { colspan: { default: 1 } },
                        { colspan: { default: 2 } },
                        { colspan: { default: 2 } }
                    ]
                }
                >
                    {props.providerCircuitNameToOrderIdsMap[
                        props.topologyMap[props.topologyMapPosition][props.arrayPosition].providerCircuitName] &&
                            <div>
                                <FremontSelect
                                    id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.orderId}`}
                                    disabled={props.isSpanOrderSubmissionInProgress || props.isDisabled}
                                    options={HelperFunctions.createSelectedOptions(
                                        props.providerCircuitNameToOrderIdsMap[
                                            props.topologyMap[props.topologyMapPosition][props.arrayPosition]
                                                .providerCircuitName]
                                    )}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.topologyMap[props.topologyMapPosition][props.arrayPosition].orderId
                                    )}
                                    onChange={props.handleTopologyRowInputChange}
                                    filteringType="auto"
                                    disableBrowserAutocorrect
                                    expandToViewport
                                />
                            </div>
                    }
                    {!props.providerCircuitNameToOrderIdsMap[
                        props.topologyMap[props.topologyMapPosition][props.arrayPosition].providerCircuitName] &&
                            <div>
                                <FormField
                                    errorText={Object.keys(props.errorTexts).length === 0 ?
                                        "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].orderId}
                                    stretch
                                >
                                    <div>
                                        <FremontInput
                                            id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.orderId}`}
                                            disabled
                                            value={props.topologyMap[props.topologyMapPosition][props.arrayPosition]
                                                .orderId}
                                            onInput={props.handleTopologyRowInputChange}
                                        />
                                    </div>
                                </FormField>
                            </div>
                    }
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].circuitQuantity}
                            stretch
                        >
                            <FremontInput
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.circuitQuantity}`}
                                disabled={props.isSpanOrderSubmissionInProgress || props.isDisabled}
                                value={topologyMap[topologyMapPosition][arrayPosition].circuitQuantity}
                                onInput={props.handleTopologyRowInputChange}
                                type={Constants.NUMBER}
                                disableBrowserAutocorrect
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].siteAId}
                            stretch
                        >
                            <FremontSelect
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.siteAId}`}
                                disabled
                                selectedOption={determineSiteToDisplay(
                                    true,
                                    props.topologyMap[props.topologyMapPosition][props.arrayPosition],
                                    props.orderMap,
                                    props.siteOptions,
                                    props.componentIdToObjectMap
                                )}
                                options={props.siteOptions}
                                onChange={props.handleTopologyRowInputChange}
                                filteringType="auto"
                                disableBrowserAutocorrect
                                expandToViewport
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].siteZId}
                            stretch
                        >
                            <FremontSelect
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.siteZId}`}
                                disabled
                                selectedOption={determineSiteToDisplay(
                                    false,
                                    props.topologyMap[props.topologyMapPosition][props.arrayPosition],
                                    props.orderMap,
                                    props.siteOptions,
                                    props.componentIdToObjectMap
                                )}
                                options={props.siteOptions}
                                onChange={props.handleTopologyRowInputChange}
                                filteringType="auto"
                                disableBrowserAutocorrect
                                expandToViewport
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].projectType}
                            stretch
                        >
                            <FremontSelect
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.projectType}`}
                                disabled={props.isSpanOrderSubmissionInProgress || props.isDisabled}
                                selectedOption={HelperFunctions.createSelectedOption(determineProjectTypeToDisplay(
                                    props.topologyMap[props.topologyMapPosition][props.arrayPosition],
                                    props.orderMap
                                ))}
                                options={Constants.PROJECT_TYPE_OPTIONS}
                                onChange={props.handleTopologyRowInputChange}
                                filteringType="auto"
                                disableBrowserAutocorrect
                                expandToViewport
                            />
                        </FormField>
                    </div>
                    <div>
                        <FremontInput
                            id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.spanName}`}
                            disabled
                            value={
                                props.topologyMap[props.topologyMapPosition][props.arrayPosition].spanName
                            }
                            onInput={props.handleTopologyRowInputChange}
                        />
                    </div>
                    <div>
                        <FormField
                            stretch
                        >
                            <FremontButton
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.TOPOLOGY_CONSTANTS.createSpanOrder}`}
                                disabled={props.isSpanOrderSubmissionInProgress || props.isDisabled}
                                variant="primary"
                                onClick={props.handleCreateOrUseSpanOrder}
                            >
                                Create Span
                            </FremontButton>
                        </FormField>
                    </div>
                    {providerCircuitNameToOrderIdsMap[
                        props.topologyMap[props.topologyMapPosition][props.arrayPosition].providerCircuitName] &&
                            <div>
                                <FormField
                                    stretch
                                >
                                    <FremontButton
                                        id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.TOPOLOGY_CONSTANTS.reuseSpanOrder}`}
                                        disabled={!topologyMap[topologyMapPosition][arrayPosition].orderId
                                            || props.isSpanOrderSubmissionInProgress || props.isDisabled}
                                        variant="primary"
                                        onClick={props.handleCreateOrUseSpanOrder}
                                    >
                                        Use Span
                                    </FremontButton>
                                </FormField>
                            </div>
                    }
                </Grid>
            </div>
        )
    );
};

export const OpticalTopologyInfoStageDisplayMode = props => (
    /* the two divs below 'fremont-scroll-outer' and 'fremont-scroll' work together
        to allow Topology rows to be horizontally scrollable.
        The reason is very complex CSS limitations, if fremont-scroll was:
            min-width: 1000px;
            overflow-x: scroll;
        Then both of these properties would be applied to div and visually you would see the
        topology rows leaking out of the awsui-util-container.
        By keeping these divs separate, my understanding is that we can force the parent's
        'overflow: hidden' property to be applied to fremont-scroll and then the outer div will do the
        scrolling inside the awsui-util-container. It just works.
    */
    <Container>
        <div className="fremont-scroll-outer">
            <div className="fremont-scroll">
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <TopologyHeader/>
                    {(Object.keys(props.topologyMapFromDesignString).length > 0) ?
                        Object.entries(props.topologyMapFromDesignString).map(([position, topologyObjects]) =>
                            topologyObjects.map((topologyObject, index) =>
                                (
                                    <div key={topologyObject.uuid}>
                                        <TopologyRow
                                            topologyMap={props.topologyMapFromDesignString}
                                            isDisabled
                                            topologyMapPosition={position}
                                            arrayPosition={index}
                                            orderMap={props.orderMap}
                                            errorTexts=""
                                            componentIdToObjectMap={props.componentIdToObjectMap}
                                            siteOptions={props.siteOptions}
                                            providerCircuitNameToOrderIdsMap={props.providerCircuitNameToOrderIdsMap}
                                        />
                                    </div>
                                )))
                        :
                        <Box textAlign="center">
                            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                <b>
                                    {props.order.opticalDesignString
                                        ? Constants.EMPTY_TABLE_MESSAGES.opticalTopologyAlreadyAdded
                                        : Constants.EMPTY_TABLE_MESSAGES.noOpticalTopology
                                    }
                                </b>
                            </Box>
                        </Box>
                    }
                </div>
            </div>
        </div>
    </Container>
);

export const OpticalTopologyInfoStageEditMode = props => (
    /* the two divs below 'fremont-scroll-outer' and 'fremont-scroll' work together
    to allow Topology rows to be horizontally scrollable.
    The reason is very complex CSS limitations, if fremont-scroll was:
        min-width: 1000px;
        overflow-x: scroll;
    Then both of these properties would be applied to div and visually you would see the
    topology rows leaking out of the awsui-util-container.
    By keeping these divs separate, my understanding is that we can force the parent's
    'overflow: hidden' property to be applied to fremont-scroll and then the outer div will do the
    scrolling inside the awsui-util-container. It just works.
    */
    <Container>
        <div className="fremont-scroll-outer">
            <div className="fremont-scroll">
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <TopologyHeader/>
                    {(Object.keys(props.topologyMapFromDesignString).length > 0) ?
                        Object.entries(props.topologyMapFromDesignString).map(([position, topologyObjects]) =>
                            topologyObjects.map((topologyObject, index) =>
                                (
                                    <div key={topologyObject.uuid}>
                                        <TopologyRow
                                            topologyMap={props.topologyMapFromDesignString}
                                            isDisabled={!!props.allFieldsDisabled}
                                            topologyMapPosition={position}
                                            arrayPosition={index}
                                            orderMap={props.orderMap}
                                            errorTexts={props.hasBeenSubmittedOnce ? props.errorTexts : {}}
                                            componentIdToObjectMap={props.componentIdToObjectMap}
                                            siteOptions={props.siteOptions}
                                            handleStageInputChange={props.handleStageInputChange}
                                            handleCreateOrUseSpanOrder={props.handleCreateOrUseSpanOrder}
                                            handleTopologyRowInputChange={props.handleTopologyRowInputChange}
                                            isSpanOrderSubmissionInProgress={props.isSpanOrderSubmissionInProgress}
                                            providerCircuitNameToOrderIdsMap={props.providerCircuitNameToOrderIdsMap}
                                        />
                                    </div>
                                ))) :
                        <Box textAlign="center">
                            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                <b>
                                    {props.order.opticalDesignString
                                        ? Constants.EMPTY_TABLE_MESSAGES.opticalTopologyAlreadyAdded
                                        : Constants.EMPTY_TABLE_MESSAGES.noOpticalTopology
                                    }
                                </b>
                            </Box>
                        </Box>
                    }
                </div>
            </div>
        </div>
    </Container>
);
