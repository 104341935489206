import React from "react";
import {
    Box,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontExpandableSection,
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import {
    TableDisplayModeTable,
    TableEditModeTable
} from "order/OrderCommonComponents";

export const generateIpTestingColumnDefinitions = (isPort) => {
    const columnDefinitions = [
        {
            id: Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus,
            header: "Status",
            description: "IP Testing Status for this port",
            width: 175,
            cell: item => (item.editable ?
                <div className="fremont-short-table">
                    <FremontSelect
                        id={`${item[Constants.TABLE_IDS.groupingId]}${Constants.SEPARATOR}${Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus}`}
                        selectedOption={HelperFunctions.createSelectedOption(
                            item[Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus]
                        )}
                        options={HelperFunctions.createSelectedOptions(Constants.IP_TESTING_STATUSES)}
                        onChange={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                        expandToViewport
                    />
                </div>
                :
                item[Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus])
        },
        {
            id: Constants.COMPONENT_NAMES.nodeA,
            header: "Router A",
            description: "Port Or Lag router",
            cell: item => item[Constants.COMPONENT_NAMES.nodeA]
        },
        isPort ?
            {
                id: Constants.COMPONENT_NAMES.portA,
                header: "Interface A",
                description: "Port's info",
                cell: item => item[Constants.COMPONENT_NAMES.portA]
            } :
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "Interface A",
                description: "Lag's info",
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            },
        {
            id: Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4,
            header: "Amazon Test IPv4",
            cell: item => (item.editable ?
                <div>
                    <FormField
                        errorText={item.errorTexts[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]}
                    >
                        <FremontInput
                            id={`${item[Constants.TABLE_IDS.groupingId]}${Constants.SEPARATOR}${Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4}`}
                            value={item[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                </div>
                :
                item[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4])
        },
        {
            id: Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4,
            header: "Provider Test IPv4",
            cell: item => (item.editable ?
                <div>
                    <FormField
                        errorText={item.errorTexts[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4]}
                    >
                        <FremontInput
                            id={`${item[Constants.TABLE_IDS.groupingId]}${Constants.SEPARATOR}${Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4}`}
                            value={item[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                </div>
                :
                item[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4])
        },
        {
            id: Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6,
            header: "Amazon Test IPv6",
            cell: item => (item.editable ?
                <div>
                    <FormField
                        errorText={item.errorTexts[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6]}
                    >
                        <FremontInput
                            id={`${item[Constants.TABLE_IDS.groupingId]}${Constants.SEPARATOR}${Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6}`}
                            value={item[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                </div>
                :
                item[Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6])
        },
        {
            id: Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6,
            header: "Provider Test IPv6",
            cell: item => (item.editable ?
                <div>
                    <FormField
                        errorText={item.errorTexts[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6]}
                    >
                        <FremontInput
                            id={`${item[Constants.TABLE_IDS.groupingId]}${Constants.SEPARATOR}${Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6}`}
                            value={item[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                </div>
                :
                item[Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6])
        },
        {
            id: Constants.IP_TESTING_ATTRIBUTES.vlanNumber,
            header: "VLAN Number",
            cell: item => (item.editable ?
                <div>
                    <FremontInput
                        id={`${item[Constants.TABLE_IDS.groupingId]}${Constants.SEPARATOR}${Constants.IP_TESTING_ATTRIBUTES.vlanNumber}`}
                        value={item[Constants.IP_TESTING_ATTRIBUTES.vlanNumber]}
                        onInput={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                        inputMode="numeric"
                    />
                </div>
                :
                item[Constants.IP_TESTING_ATTRIBUTES.vlanNumber])
        }
    ];
    return columnDefinitions;
};

export const IpTestingEditMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                Testing Components
            </Header>
        }
        defaultExpanded
    >
        <Box padding={{ top: "xxs" }}>
            <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                <TableEditModeTable
                    id="ipTesting"
                    columnDefinitions={generateIpTestingColumnDefinitions(true)}
                    header={
                        <Header variant="h2">
                            Port Testing
                        </Header>
                    }
                    circuitItems={props.portItems}
                    massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedPortIds}
                    handleSelectedFromTable={props.handleSelectedFromTablePort}
                    unitTable
                    isDataLoaded
                    customEmptyMessage={Constants.EMPTY_TABLE_MESSAGES.noPorts}
                />
                {props.order.lacpProvider === Constants.TRUE_STRING &&
                <TableEditModeTable
                    id="lagTesting"
                    columnDefinitions={generateIpTestingColumnDefinitions(false)}
                    header={
                        <Header variant="h2">
                            Lag Testing
                        </Header>
                    }
                    circuitItems={props.lagItems}
                    isDataLoaded
                    massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedLagIds}
                    handleSelectedFromTable={props.handleSelectedFromTableLag}
                    unitTable
                    customEmptyMessage={Constants.EMPTY_TABLE_MESSAGES.noLags}
                />
                }
            </SpaceBetween>
        </Box>
    </FremontExpandableSection>
);

export const IpTestingDisplayMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                Testing Components
            </Header>
        }
        defaultExpanded={!HelperFunctions.isStageCompleted(props.order.stageStatusMap[props.stageName])}
    >
        <Box padding={{ top: "xxs" }}>
            <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                <TableDisplayModeTable
                    columnDefinitions={generateIpTestingColumnDefinitions(true)}
                    header={
                        <Header variant="h2">
                            Port Testing
                        </Header>
                    }
                    circuitItems={props.portItems}
                    isDataLoaded
                    customEmptyMessage={Constants.EMPTY_TABLE_MESSAGES.noPorts}
                />
                {props.order.lacpProvider === Constants.TRUE_STRING &&
                    <TableDisplayModeTable
                        columnDefinitions={generateIpTestingColumnDefinitions(false)}
                        header={
                            <Header variant="h2">
                                Lag Testing
                            </Header>
                        }
                        circuitItems={props.lagItems}
                        isDataLoaded
                        customEmptyMessage={Constants.EMPTY_TABLE_MESSAGES.noLags}
                    />
                }
            </SpaceBetween>
        </Box>
    </FremontExpandableSection>
);