import HelperFunctions from "../../../lighthouse/js/common/HelperFunctions";
import Constants from "../utils/Constants";
import IspCutsheetTableData from "./IspCutsheetTableData";

export default class IspDashboardHelper {
    static validateIspRows = (updatedCutsheetKeyValuePairs, ippmSearchResults) => {
        let anyError = false;
        const usedPorts = new Set([]);

        updatedCutsheetKeyValuePairs.forEach((row) => {
            Object.keys(row).forEach((key) => {
                if (key.match(/^(a|z)_hop_[1-5]_isp_panel_a_location_[a-zA-Z0-9]{3}$/)) {
                    // Extract the hop position from the key
                    const match = key.match(/^([az])_hop_(\d+)_\w+_(\w+)$/);

                    // eslint-disable-next-line
                    const [_, type, hopPosition, parentLinkType] = match;
                    // Get the values for the keys
                    const panelALocationValue = row[`${type}_hop_${hopPosition}_isp_panel_a_location_${parentLinkType}`];
                    const panelZLocationValue = row[`${type}_hop_${hopPosition}_isp_panel_z_location_${parentLinkType}`];
                    const panelAPortValue = row[`${type}_hop_${hopPosition}_isp_panel_a_port_${parentLinkType}`];
                    const panelZPortValue = row[`${type}_hop_${hopPosition}_isp_panel_z_port_${parentLinkType}`];
                    const panelAPortError = `${type}_hop_${hopPosition}_isp_panel_a_port_error_${parentLinkType}`;
                    const panelZPortError = `${type}_hop_${hopPosition}_isp_panel_z_port_error_${parentLinkType}`;
                    const panelALocationError = `${type}_hop_${hopPosition}_isp_panel_a_location_error_${parentLinkType}`;
                    const newPortA = `${panelALocationValue}:${panelAPortValue}`;
                    const newPortZ = `${panelZLocationValue}:${panelZPortValue}`;

                    // eslint-disable-next-line no-param-reassign
                    row[panelAPortError] = "";
                    // eslint-disable-next-line no-param-reassign
                    row[panelZPortError] = "";
                    // eslint-disable-next-line no-param-reassign
                    row[panelALocationError] = "";

                    if (!!panelALocationValue && !panelAPortValue) {
                        // eslint-disable-next-line no-param-reassign
                        row[panelAPortError] = "Port must be populated to submit LIU.";
                    } else if (usedPorts.has(newPortA) && !!panelAPortValue) {
                        // eslint-disable-next-line no-param-reassign
                        row[panelAPortError] = "Port A is already being used in another hop";
                    } else if (panelAPortValue) {
                        usedPorts.add(newPortA);
                    }

                    // Z port validation
                    if (!!panelALocationValue && !panelZPortValue) {
                        // eslint-disable-next-line no-param-reassign
                        row[panelZPortError] = "Port must be populated to submit LIU.";
                    } else if (usedPorts.has(newPortZ) && !!panelZPortValue) {
                        // eslint-disable-next-line no-param-reassign
                        row[panelZPortError] = "Port Z is already being used in another hop";
                    } else if (panelZPortValue) {
                        usedPorts.add(newPortZ);
                    }


                    // WE FIND IF THE PATCH PANEL BEING USED ON THIS HOP IS IN THE SEARCH RESULTS
                    const patchPanels = ippmSearchResults
                        .filter(result => result.PatchPanelLocationA === panelALocationValue);
                    if (patchPanels.length === 1) {
                        const portRange = patchPanels[0].PortCount;
                        const startA = patchPanels[0].StartingPortLocationA;
                        const startZ = patchPanels[0].StartingPortLocationZ;
                        let maxPortRangeForAEnd = parseInt(startA, 10) + parseInt(portRange, 10);
                        let maxPortRangeForZEnd = parseInt(startZ, 10) + parseInt(portRange, 10);
                        // We need to decide the Max Port Count based on the Port Type (Simplex/Duplex) given.
                        if (HelperFunctions.isValidDuplexPort(panelAPortValue)) {
                            maxPortRangeForAEnd = parseInt(startA, 10) + (parseInt(portRange, 10) * 2);
                        }
                        if (HelperFunctions.isValidDuplexPort(panelZPortValue)) {
                            maxPortRangeForZEnd = parseInt(startZ, 10) + (parseInt(portRange, 10) * 2);
                        }
                        if (parseInt(panelAPortValue, 10) < parseInt(startA, 10) ||
                            parseInt(panelAPortValue, 10) + 1 > maxPortRangeForAEnd
                        ) {
                            // eslint-disable-next-line no-param-reassign
                            row[panelAPortError] = "Port for A Room is beyond range of available " +
                                "ports for this patch panel.";
                        }

                        if (parseInt(panelZPortValue, 10) < parseInt(startZ, 10) ||
                            parseInt(panelZPortValue, 10) + 1 > maxPortRangeForZEnd
                        ) {
                            // eslint-disable-next-line no-param-reassign
                            row[panelZPortError] = "Port for Z Room is beyond range of available " +
                                "ports for this patch panel.";
                        }
                        // Check if the input entered for Port is of the same type (Single, Pair)
                        // for which the ISP config was set.
                        if (HelperFunctions.isValidDuplexPort(startA)) {
                            if (!HelperFunctions.isValidDuplexPort(panelAPortValue)) {
                                // eslint-disable-next-line no-param-reassign
                                row[panelAPortError] = "Port for A Room should follow the regex ^\\d+-\\d+$";
                            }
                        }
                        if (HelperFunctions.isValidDuplexPort(startZ)) {
                            if (!HelperFunctions.isValidDuplexPort(panelZPortValue)) {
                                // eslint-disable-next-line no-param-reassign
                                row[panelZPortError] = "Port for Z Room should follow the regex ^\\d+-\\d+$";
                            }
                        }
                    }

                    // final step: were there any errors added? then set anyError to true
                    if (row[panelAPortError] || row[panelZPortError] || row[panelALocationError]) {
                        anyError = true;
                    }
                }
            });
        });

        return anyError;
    }

    static mmrDataSubmitHelper = (
        mmrEndName,
        updatedCutsheetKeyValuePairs,
        originalCutsheetKeyValuePairs
    ) => {
        updatedCutsheetKeyValuePairs.forEach((row, index) => {
            const originalRow = originalCutsheetKeyValuePairs[index] || {};
            const hasChanged = Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER.some(
                key => row[key] !== originalRow[key]
            );

            if (hasChanged) {
                const instanceId = `${Constants.LINK_INSTANCE_ID_PATTERN}${row.intra_dc_router_to_intra_dc_router_link_instance_id}`;
                const aHostname = row[Constants.ISP_ATTRIBUTES.a_hostname];
                const aInterface = row[Constants.ISP_ATTRIBUTES.a_interface];
                const mmrAEndName = `${aHostname}_${aInterface}`;
                const { mmrZEndName } = row;

                mmrEndName.endNames.push({
                    instanceId,
                    mmrAEndName,
                    mmrZEndName
                });
            }
        });
    };

    static attributeSubmitHelper = (linksToBatchPut, updatedCutsheetKeyValuePairs, originalCutsheetKeyValuePairs) => {
        // We store a set of instance ids (meaning the ids of the links in linkservice
        // for whom we are updating attributes
        // this is because in some cases same instance ids (for example trunk_id) is
        // used across multiple rows and with massupdate users may update multiple rows
        // at the same time.  we use only the first row of that id being used.
        const instanceIds = new Set();

        updatedCutsheetKeyValuePairs.forEach((row, index) => {
            // get all the mux to mux attributes
            let attributes = [];
            let instanceId = `${Constants.LINK_INSTANCE_ID_PATTERN}${row.mux_to_mux_link_instance_id}`;
            Constants.KEYS_TO_CHECK.MUX_TO_MUX.forEach((key) => {
                if (row[key] !== originalCutsheetKeyValuePairs[index][key]) {
                    const attributeToUpdate =
                        HelperFunctions.createAttribute(key, row[key]);
                    attributes.push(attributeToUpdate);
                }
            });
            const muxLink = { instanceId, attributes };
            if (attributes.length > 0) {
                instanceIds.add(instanceId);
                linksToBatchPut.Links.push(muxLink);
            }

            // get all the encryption to encryption attributes
            attributes = [];
            instanceId = `${Constants.LINK_INSTANCE_ID_PATTERN}${row.encryption_to_encryption_link_instance_id}`;
            Constants.KEYS_TO_CHECK.ENCRYPTION_TO_ENCRYPTION.forEach((key) => {
                if (row[key] !== originalCutsheetKeyValuePairs[index][key]) {
                    const flippedKeyToCheck = `${key}_flipped`;
                    if (row[flippedKeyToCheck] === "NF") {
                        const attributeToUpdate =
                            HelperFunctions.createAttribute(key, row[key]);
                        attributes.push(attributeToUpdate);
                    } else if (key.startsWith("a_")) {
                        const newKey = key.replace("a_", "z_");
                        attributes.push(HelperFunctions.createAttribute(newKey, row[key]));
                    } else {
                        const newKey = key.replace("z_", "a_");
                        attributes.push(HelperFunctions.createAttribute(newKey, row[key]));
                    }
                }
            });
            const encryptionLink = { instanceId, attributes };
            if (attributes.length > 0 && !instanceIds.has(instanceId)) {
                instanceIds.add(instanceId);
                linksToBatchPut.Links.push(encryptionLink);
            }

            // get all the intraDcRouter To intraDcRouter attributes
            attributes = [];
            instanceId = `${Constants.LINK_INSTANCE_ID_PATTERN}${row.intra_dc_router_to_intra_dc_router_link_instance_id}`;
            // only batch put cage, rack, and patch panel for internal cabling
            // because interface is not an attribute, need to be updated using updateLink api
            Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER_ATTRIBUTES.forEach((key) => {
                if (row[key] !== originalCutsheetKeyValuePairs[index][key]) {
                    const attributeToUpdate =
                        HelperFunctions.createAttribute(key, row[key]);
                    attributes.push(attributeToUpdate);
                }
            });
            const intraDcRouterLink = { instanceId, attributes };
            if (attributes.length > 0 && !instanceIds.has(instanceId)) {
                instanceIds.add(instanceId);
                linksToBatchPut.Links.push(intraDcRouterLink);
            }

            // get all the router to router attributes
            attributes = [];
            instanceId = `${Constants.LINK_INSTANCE_ID_PATTERN}${row.router_to_router_link_instance_id}`;
            Constants.KEYS_TO_CHECK.ROUTER_TO_ROUTER.forEach((key) => {
                if (row[key] !== originalCutsheetKeyValuePairs[index][key]) {
                    const attributeToUpdate =
                        HelperFunctions.createAttribute(key, row[key]);
                    attributes.push(attributeToUpdate);
                }
            });
            const routerLink = { instanceId, attributes };
            if (attributes.length > 0 && !instanceIds.has(instanceId)) {
                instanceIds.add(instanceId);
                linksToBatchPut.Links.push(routerLink);
            }

            // get all the trunk to trunk attributes
            // the groups represent things like AB, BZ, etc which occurs with trunk_to_trunk data
            const groups = new Set();
            updatedCutsheetKeyValuePairs.forEach((keyValueMap) => {
                // Iterate through all key-value pairs in the current map
                Object.keys(keyValueMap).forEach((key) => {
                    if (key.startsWith("trunk_to_trunk_link_instance_id_")) {
                        const groupIdentifier = key.slice(-2);
                        groups.add(groupIdentifier);
                    }
                });
            });

            groups.forEach((group) => {
                attributes = [];
                const trunkId = row[`trunk_to_trunk_link_instance_id_${group}`];
                instanceId = `${Constants.LINK_INSTANCE_ID_PATTERN}${trunkId}`;
                Constants.KEYS_TO_CHECK.TRUNK_TO_TRUNK.forEach((key) => {
                    const keyWithGroup = `${key}_${group}`;
                    if (row[keyWithGroup] !== originalCutsheetKeyValuePairs[index][keyWithGroup]) {
                        const flippedKeyToCheck = `${keyWithGroup}_flipped`;
                        if (row[flippedKeyToCheck] === "NF") {
                            const attributeToUpdate =
                                HelperFunctions.createAttribute(key, row[keyWithGroup]);
                            attributes.push(attributeToUpdate);
                        } else if (key.startsWith("a_")) {
                            const newKey = key.replace("a_", "z_");
                            attributes.push(HelperFunctions.createAttribute(newKey, row[keyWithGroup]));
                        } else if (key.startsWith("z_")) {
                            const newKey = key.replace("z_", "a_");
                            attributes.push(HelperFunctions.createAttribute(newKey, row[keyWithGroup]));
                        }
                    }
                });
                const trunkLink = { instanceId, attributes };
                if (attributes.length > 0 && !!trunkId && !instanceIds.has(instanceId)) {
                    instanceIds.add(instanceId);
                    linksToBatchPut.Links.push(trunkLink);
                }
            });
        });
    }

    static columnIndexForTrunk = (updatedColumnDefinitions, cutsheetType) => {
        if (cutsheetType === Constants.ISP_CUTSHEET_TYPE.BACKBONE_CLIENT_MIGRATION) {
            return updatedColumnDefinitions.findIndex(col => col.id === Constants.ISP_ATTRIBUTES.z_port_speed);
        }
        return updatedColumnDefinitions.findIndex(col => col.id === Constants.ISP_ATTRIBUTES.z_trunk_lever_optic_type);
    }

    static columnIndexForIlaHut = updatedColumnDefinitions =>
        updatedColumnDefinitions.findIndex(col => col.id === Constants.ISP_ATTRIBUTES.z_pluggable)

    static updateColumnsAfterAddingLius = (updatedColumnDefinitions, cutsheetType, hopItems) => {
        hopItems.sort((a, b) => HelperFunctions.alphanumericSort(a.id, b.id));
        let filteredHopItems;
        let index;

        if (cutsheetType === Constants.ISP_CUTSHEET_TYPE.CLIENT) {
            // type r2r liu A
            filteredHopItems = hopItems.filter(hop => hop.id
                .includes("r2r"))
                .filter(hop => hop.id.startsWith("a_hop_"));
            index = updatedColumnDefinitions.findIndex(col => col.id === Constants.ISP_ATTRIBUTES.a_location);
            updatedColumnDefinitions.splice(index + 1, 0, ...filteredHopItems);

            // type r2r liu Z
            filteredHopItems = hopItems.filter(hop => hop.id
                .includes("r2r"))
                .filter(hop => hop.id.startsWith("z_hop_"));
            index = updatedColumnDefinitions.findIndex(col => col.id === Constants.ISP_ATTRIBUTES.z_location);
            updatedColumnDefinitions.splice(index, 0, ...filteredHopItems);

            // type e2e liu A
            filteredHopItems = hopItems.filter(hop => hop.id
                .includes("e2e"))
                .filter(hop => hop.id.startsWith("a_hop_"));
            index = updatedColumnDefinitions.findIndex(col => col.id === Constants.ISP_ATTRIBUTES.a_lever_hostname);
            updatedColumnDefinitions.splice(index + 1, 0, ...filteredHopItems);

            // type e2e liu Z
            filteredHopItems = hopItems.filter(hop => hop.id
                .includes("e2e"))
                .filter(hop => hop.id.startsWith("z_hop_"));
            index = updatedColumnDefinitions.findIndex(col => col.id === Constants.ISP_ATTRIBUTES.z_lever_hostname);
            updatedColumnDefinitions.splice(index, 0, ...filteredHopItems);

            // Function to extract two-character group identifier (e.g., 'az', 'bz', 'ac')
            const extractGroupIdentifier = (id) => {
                const match = id.match(/^[a-z]{2}_hop_/);
                return match ? id.substring(0, 2) : null;
            };

            // Extract unique group identifiers from hopItems
            const groupIdentifiers = new Set(hopItems.map(hop => extractGroupIdentifier(hop.id)).filter(id => id));
            // Process each group identifier
            groupIdentifiers.forEach((groupIdentifier) => {
                // Filter hopItems for the current group identifier
                filteredHopItems = hopItems.filter(hop =>
                    hop.id.startsWith(`${groupIdentifier}_hop_`));
                // Find the index in updatedColumnDefinitions using the groupIdentifier
                index = updatedColumnDefinitions.findIndex(col =>
                    col.id === `wavelength_${groupIdentifier.toUpperCase()}` ||
                    // in case of bulk fiber (wavelength column does not exist)
                    col.id === Constants.ISP_ATTRIBUTES.z_trunk_lever_optic_type);
                // Insert filtered hop items at the found index
                if (index !== -1) {
                    updatedColumnDefinitions.splice(index, 0, ...filteredHopItems);
                }
            });
        } else {
            // type m2m liu A
            filteredHopItems = hopItems.filter(hop => hop.id
                .includes("m2m"))
                .filter(hop => hop.id.startsWith("a_hop_"));
            index = updatedColumnDefinitions
                .findIndex(col => col.id === Constants.ISP_ATTRIBUTES.a_line_dwdm_device_location);
            updatedColumnDefinitions.splice(index + 1, 0, ...filteredHopItems);

            // type m2m liu Z
            filteredHopItems = hopItems.filter(hop => hop.id
                .includes("m2m"))
                .filter(hop => hop.id.startsWith("z_hop_"));
            index = updatedColumnDefinitions
                .findIndex(col => col.id === Constants.ISP_ATTRIBUTES.z_line_dwdm_device_location);
            updatedColumnDefinitions.splice(index, 0, ...filteredHopItems);

            // Function to extract two-character group identifier (e.g., 'az', 'bz', 'ac')
            const extractGroupIdentifier = (id) => {
                const match = id.match(/^[a-z]{2}_hop_/);
                return match ? id.substring(0, 2) : null;
            };

            // Extract unique group identifiers from hopItems
            const groupIdentifiers = new Set(hopItems.map(hop => extractGroupIdentifier(hop.id)).filter(id => id));
            // Process each group identifier
            groupIdentifiers.forEach((groupIdentifier) => {
                // Filter hopItems for the current group identifier
                filteredHopItems = hopItems.filter(hop =>
                    hop.id.startsWith(`${groupIdentifier}_hop_`));
                // Find the index in updatedColumnDefinitions using the groupIdentifier
                index = updatedColumnDefinitions.findIndex(col =>
                    col.id === `a_carrier_name_${groupIdentifier.toUpperCase()}`);

                if (index !== -1) {
                    updatedColumnDefinitions.splice(index, 0, ...filteredHopItems);
                }
            });
        }

        return updatedColumnDefinitions;
    }

    static getLiuParentUUID = (row, parentType) => {
        if (parentType === "r2r") {
            return row.router_to_router_link_instance_id;
        } else if (parentType === "e2e") {
            return row.encryption_to_encryption_link_instance_id;
        }
        return row.mux_to_mux_link_instance_id;
    };

    static disableAutosuggest = (selectedOption, item) =>
        !!(selectedOption.endsWith("e2e") && !item.encryption_to_encryption_link_instance_id)

    static createNewColumnsForLineHops = (minimumColumnDefinitions, sortedArray) => {
        sortedArray.forEach((groupIdentifier, index) => {
            const groupColumns = [
                {
                    id: `passive_to_passive_link_instance_id_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`passive_to_passive_link_instance_id_${groupIdentifier}`, index),
                    minWidth: 176,
                    cell: item => item[`passive_to_passive_link_instance_id_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `a_osp_panel_location_${groupIdentifier}`,
                    sortingField: `a_osp_panel_location_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`a_osp_panel_location`, index),
                    minWidth: 200,
                    downloadableValue: item => item[`a_osp_panel_location_${groupIdentifier}`],
                    downloadableColumnHeader: `a_osp_panel_location`,
                    cell: item => item[`a_osp_panel_location_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `a_osp_panel_ports_${groupIdentifier}`,
                    sortingField: `a_osp_panel_ports_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`a_osp_panel_ports`, index),
                    minWidth: 176,
                    downloadableValue: item => item[`a_osp_panel_ports_${groupIdentifier}`],
                    downloadableColumnHeader: `a_osp_panel_ports`,
                    cell: item => item[`a_osp_panel_ports_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `a_carrier_type_${groupIdentifier}`,
                    sortingField: `a_carrier_type_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`a_carrier_type`, index),
                    minWidth: 176,
                    downloadableValue: item => item[`a_carrier_type_${groupIdentifier}`],
                    downloadableColumnHeader: `a_carrier_type`,
                    cell: item => item[`a_carrier_type_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `a_carrier_name_${groupIdentifier}`,
                    sortingField: `a_carrier_name_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`a_carrier_name`, index),
                    minWidth: 176,
                    downloadableValue: item => item[`a_carrier_name_${groupIdentifier}`],
                    downloadableColumnHeader: `a_carrier_name`,
                    cell: item => item[`a_carrier_name_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `z_carrier_name_${groupIdentifier}`,
                    sortingField: `z_carrier_name_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`z_carrier_name`, index),
                    minWidth: 176,
                    downloadableValue: item => item[`z_carrier_name_${groupIdentifier}`],
                    downloadableColumnHeader: `z_carrier_name`,
                    cell: item => item[`z_carrier_name_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `z_carrier_type_${groupIdentifier}`,
                    sortingField: `z_carrier_type_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`z_carrier_type`, index),
                    minWidth: 176,
                    downloadableValue: item => item[`z_carrier_type_${groupIdentifier}`],
                    downloadableColumnHeader: `z_carrier_type`,
                    cell: item => item[`z_carrier_type_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `z_osp_panel_location_${groupIdentifier}`,
                    sortingField: `z_osp_panel_location_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`z_osp_panel_location`, index),
                    minWidth: 200,
                    downloadableValue: item => item[`z_osp_panel_location_${groupIdentifier}`],
                    downloadableColumnHeader: `z_osp_panel_location`,
                    cell: item => item[`z_osp_panel_location_${groupIdentifier}`],
                    isRowHeader: true
                },
                {
                    id: `z_osp_panel_ports_${groupIdentifier}`,
                    sortingField: `z_osp_panel_ports_${groupIdentifier}`,
                    header: HelperFunctions.provideColoredHeader(`z_osp_panel_ports`, index),
                    minWidth: 176,
                    downloadableValue: item => item[`z_osp_panel_ports_${groupIdentifier}`],
                    downloadableColumnHeader: `z_osp_panel_ports`,
                    cell: item => item[`z_osp_panel_ports_${groupIdentifier}`],
                    isRowHeader: true
                }
            ];

            // Index where you want to insert the groupColumns
            const insertIndex = IspDashboardHelper.columnIndexForIlaHut(minimumColumnDefinitions);

            // Insert the groupColumns array at the specified index
            minimumColumnDefinitions.splice(insertIndex, 0, ...groupColumns);
        });

        return minimumColumnDefinitions;
    }

    static createAdditionalKeysForMultiHopTrunkHelper = (cutsheetKeyValuePairs) => {
        cutsheetKeyValuePairs.forEach((row) => {
            Object.keys(row).forEach((key) => {
                const match = key.match(/^a_dwdm_location_(\w{2})_(F|NF)$/);
                if (match) {
                    // eslint-disable-next-line
                    const [_, groupIdentifier, flipped] = match;
                    // eslint-disable-next-line
                    row[`a_dwdm_location_${groupIdentifier}`] = row[key];
                    // eslint-disable-next-line
                    row[`a_dwdm_location_${groupIdentifier}_flipped`] = flipped;
                }
            });
            Object.keys(row).forEach((key) => {
                const match = key.match(/^z_dwdm_location_(\w{2})_(F|NF)$/);
                if (match) {
                    // eslint-disable-next-line
                    const [_, groupIdentifier, flipped] = match;
                    // eslint-disable-next-line
                    row[`z_dwdm_location_${groupIdentifier}`] = row[key];
                    // eslint-disable-next-line
                    row[`z_dwdm_location_${groupIdentifier}_flipped`] = flipped;
                }
            });

            Object.keys(row).forEach((key) => {
                const match = key.match(/^a_lever_location_(F|NF)$/);
                if (match) {
                    // eslint-disable-next-line
                    const [_, flipped] = match;
                    // eslint-disable-next-line
                    row[`a_lever_location`] = row[key];
                    // eslint-disable-next-line
                    row[`a_lever_location_flipped`] = flipped;
                }
            });

            Object.keys(row).forEach((key) => {
                const match = key.match(/^z_lever_location_(F|NF)$/);
                if (match) {
                    // eslint-disable-next-line
                    const [_, flipped] = match;
                    // eslint-disable-next-line
                    row[`z_lever_location`] = row[key];
                    // eslint-disable-next-line
                    row[`z_lever_location_flipped`] = flipped;
                }
            });
        });
    }

    static createAdditionalHopOptionsForDropDown = (listOfGroups, cutsheetType) => {
        // for each group there needs to be a new dropdown option
        // in the hop options
        const newHopOptions = [];
        if (cutsheetType === Constants.ISP_CUTSHEET_TYPE.LINE) {
            // Add 'a' side m2m hops
            newHopOptions.push(...IspCutsheetTableData.generateHops("a", "m2m", "m2m"));

            // Add hops for each group identifier
            listOfGroups.forEach((groupIdentifier) => {
                newHopOptions.push(...IspCutsheetTableData.generateHops(groupIdentifier.toLowerCase(), "mmr", "m2m"));
            });

            // Add 'z' side m2m hops
            newHopOptions.push(...IspCutsheetTableData.generateHops("z", "m2m", "m2m"));
        } else {
            // Condition check if the use case is Bulk-Fiber, if yes we add "AZ" group Identifier
            if (listOfGroups.length === 0) {
                listOfGroups.push("AZ");
            }
            // Add 'a' side r2r and e2e hops
            newHopOptions.push(...IspCutsheetTableData.generateHops("a", "r2r", "r2r"));
            newHopOptions.push(...IspCutsheetTableData.generateHops("a", "e2e", "e2e"));
            // Add hops for each group identifier
            listOfGroups.forEach((groupIdentifier) => {
                newHopOptions.push(...IspCutsheetTableData.generateHops(groupIdentifier.toLowerCase(), "mmr", "r2r"));
            });
            // Add 'z' side e2e and r2r hops
            newHopOptions.push(...IspCutsheetTableData.generateHops("z", "e2e", "e2e"));
            newHopOptions.push(...IspCutsheetTableData.generateHops("z", "r2r", "r2r"));
        }

        return newHopOptions;
    }

    static updateColumnDefinitions(columnDefinitions) {
        return columnDefinitions.map((columnDef) => {
            const updatedColumnDef = {
                ...columnDef,
                sortingField: columnDef.id
            };
            if (!("header" in columnDef)) {
                updatedColumnDef.header = columnDef.id;
            }
            if ("downloadableValue" in columnDef) {
                updatedColumnDef.downloadableColumnHeader = columnDef.id;
            }

            return updatedColumnDef;
        });
    }

    static sortByColumn = (items, sortingColumn, sortingDescending) => {
        if (Object.values(Constants.ADVANCED_SORTING_IDS).includes(sortingColumn)) {
            return IspDashboardHelper.advancedSort(items, sortingColumn);
        }
        return IspDashboardHelper.defaultSort(
            items, sortingColumn, sortingDescending, Constants.SORT_BY_INT_COLUMNS.includes(sortingColumn)
        );
    }

    static defaultSort = (items, sortingColumn, sortingDescending, castValuesToInt = false) =>
        (!items ? items : items.sort((link1, link2) => {
            const aStringValue = link1[sortingColumn];
            const bStringValue = link2[sortingColumn];
            let aIntValue;
            let bIntValue;
            if (castValuesToInt) {
                aIntValue = HelperFunctions.extractFirstInteger(aStringValue);
                bIntValue = HelperFunctions.extractFirstInteger(bStringValue);
            }

            if (!aStringValue && bStringValue) {
                return sortingDescending ? -1 : 1;
            } else if (aStringValue && !bStringValue) {
                return sortingDescending ? 1 : -1;
            } else if (!aStringValue && !bStringValue) {
                return 0;
            }

            if (aIntValue && bIntValue) {
                return sortingDescending ? bIntValue - aIntValue : aIntValue - bIntValue;
            }

            return sortingDescending ?
                bStringValue.localeCompare(aStringValue) : aStringValue.localeCompare(bStringValue);
        }))

    static advancedSort = (items, sortingColumnId) => (!items ? items : items.sort((link1, link2) => {
        let hostnameAttribute;
        let interfaceAttribute;

        if (sortingColumnId ===
            Constants.ADVANCED_SORTING_IDS.site_a_bricks_then_odd_to_even_router_then_odd_to_even_ports) {
            hostnameAttribute = Constants.ISP_ATTRIBUTES.a_hostname;
            interfaceAttribute = Constants.ISP_ATTRIBUTES.a_interface;
        } else if (sortingColumnId ===
            Constants.ADVANCED_SORTING_IDS.site_z_bricks_then_odd_to_even_router_then_odd_to_even_ports) {
            hostnameAttribute = Constants.ISP_ATTRIBUTES.z_hostname;
            interfaceAttribute = Constants.ISP_ATTRIBUTES.z_interface;
        } else {
            // If someone provides an unknown advanced sorting column we just return
            // the items without sorting them
            return items;
        }

        const firstBrickNum = parseInt(HelperFunctions.getFirstRegexGroupMatch(
            link1[hostnameAttribute], /-b(\d+)-/
        ), 10);
        const firstRouterNum = parseInt(HelperFunctions.getFirstRegexGroupMatch(
            link1[hostnameAttribute], /-r(\d+)/
        ), 10);
        const firstPortNum = parseInt(HelperFunctions.getFirstRegexGroupMatch(
            link1[interfaceAttribute], /(\d+)/
        ), 10);

        const secondBrickNum = parseInt(HelperFunctions.getFirstRegexGroupMatch(
            link2[hostnameAttribute], /-b(\d+)-/
        ), 10);
        const secondRouterNum = parseInt(HelperFunctions.getFirstRegexGroupMatch(
            link2[hostnameAttribute], /-r(\d+)/
        ), 10);
        const secondPortNum = parseInt(HelperFunctions.getFirstRegexGroupMatch(
            link2[interfaceAttribute], /(\d+)/
        ), 10);

        // First sort by brick number
        if (firstBrickNum !== secondBrickNum) {
            return firstBrickNum - secondBrickNum;
        }

        // Then sort by router number
        if (firstRouterNum !== secondRouterNum) {
            // Sort odd router numbers first, then even router numbers
            const isFirstRouterOdd = firstRouterNum % 2 === 1;
            const isSecondRouterOdd = secondRouterNum % 2 === 1;
            if (isFirstRouterOdd && !isSecondRouterOdd) {
                return -1;
            }
            if (!isFirstRouterOdd && isSecondRouterOdd) {
                return 1;
            }
            return firstRouterNum - secondRouterNum;
        }

        // If router numbers are the same, sort by port number
        if (firstPortNum !== secondPortNum) {
            // Sort odd port numbers first, then even port numbers
            const isFirstPortOdd = firstPortNum % 2 === 1;
            const isSecondPortOdd = secondPortNum % 2 === 1;
            if (isFirstPortOdd && !isSecondPortOdd) {
                return -1;
            }
            if (!isFirstPortOdd && isSecondPortOdd) {
                return 1;
            }
            return firstPortNum - secondPortNum;
        }

        // If both router and port numbers are the same, consider them equal
        return 0;
    }))

    static validateMmrData = (updatedCutsheetKeyValuePairs, originalCutsheetKeyValuePairs) => {
        let anyError = false;
        const updatedFieldsPerRow = this.getUpdatedAttributes(
            updatedCutsheetKeyValuePairs,
            originalCutsheetKeyValuePairs
        );

        updatedFieldsPerRow.forEach((updatedAttributes, index) => {
            const row = updatedCutsheetKeyValuePairs[index];
            const updatedMmrFields = updatedAttributes.filter(attr =>
                Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER.includes(attr.key));
            const updatedOtherFields = updatedAttributes.filter(attr =>
                !Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER.includes(attr.key));

            // Clean the error to enable customers retry to fix the error
            Object.assign(row, { z_side_error: undefined });

            if (updatedMmrFields.length > 0) {
                if (this.isEmptyField(row, Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER)) {
                    Object.assign(row, {
                        z_side_error: `All fields are required: ${Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER.join(", ")}`
                    });
                    anyError = true;
                } else {
                    Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER.forEach((field) => {
                        if (!row[field]) {
                            Object.assign(row, {
                                z_side_error: `This field is required.`
                            });
                            anyError = true;
                        }
                    });
                }
            }

            // Validate updated other fields
            updatedOtherFields.forEach(({ key, value }) => {
                if (!value) {
                    row[`${key}_error`] = `This field is required.`;
                    anyError = true;
                }
            });
        });

        // No point in checking anything any further for this row
        if (anyError) return anyError;

        const mmrZEndNameMap = new Map();
        updatedCutsheetKeyValuePairs.forEach((row, index) => {
            const [zCage, zRack, zPatchPanel, zInterface] =
                Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER.map(key => row[key]);
            if (!this.isEmptyField(row, Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER_ATTRIBUTES)) {
                const mmrZEndName = `${zCage}-${zRack}-${zPatchPanel}_${zInterface}`;
                const normalizedMmrZEndName = mmrZEndName.toLowerCase();

                // Check duplicate MMR information here
                if (mmrZEndNameMap.has(normalizedMmrZEndName)) {
                    const duplicatedIndex = mmrZEndNameMap.get(normalizedMmrZEndName);
                    const errorMessage = `Cannot have duplicate MMR information. Following fields must be different: ${
                        Constants.KEYS_TO_CHECK.INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER.join(", ")
                    }`;

                    duplicatedIndex.forEach((prevIndex) => {
                        if (!updatedCutsheetKeyValuePairs[prevIndex].z_side_error) {
                            Object.assign(updatedCutsheetKeyValuePairs[prevIndex], {
                                z_side_error: errorMessage
                            });
                        }
                    });

                    Object.assign(row, { z_side_error: errorMessage });
                    anyError = true;
                    duplicatedIndex.push(index);
                } else {
                    mmrZEndNameMap.set(normalizedMmrZEndName, [index]);
                }

                // Assign original mmrZEndName to the row
                Object.assign(row, { mmrZEndName });
            }
        });

        return anyError;
    }

    static isEmptyField = (row, fields) => fields.every(field => !row[field]);

    static getUpdatedAttributes = (updatedStates, originalStates) => updatedStates.map((row, index) => {
        const originalRow = originalStates[index];
        const attributes = [];

        Object.keys(row).forEach((key) => {
            if (row[key] !== originalRow[key]) {
                attributes.push({ key, value: row[key] });
            }
        });

        return attributes;
    })
}