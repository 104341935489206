import React from "react";
import {
    ColumnLayout,
    FormField,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import AddressValidation from "address/AddressValidation";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import {
    FremontInput,
    FremontSelect,
    optionalLabel,
    requiredLabel,
    ComponentConstants
} from "utils/CommonComponents";

const AddressForm = props => (
    (
        <ColumnLayout columns={2} borders="vertical">
            <FormField
                label={AddressValidation.isAddressLineOneRequired(props.isSiteForm, props.address) ?
                    requiredLabel("Address Line One:") : "Address Line One:"}
                errorText={props.errorTexts.addressLineOne}
            >
                <FremontInput
                    id="addressLineOne"
                    placeholder={props.allFieldsDisabled ? "" : "Street and number"}
                    value={props.address.addressLineOne}
                    disabled={props.allFieldsDisabled}
                    onInput={props.handleAddressInputChange}
                />
            </FormField>
            {props.isSiteForm ?
                <FormField
                    label={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            {AddressValidation.areCoordinatesRequired(props.address) ?
                                requiredLabel("Latitude:") : optionalLabel("Latitude:")}
                            {
                                HelperFunctions.renderCustomTooltip(null,
                                    Constants.TOOLTIP_STRINGS.latitudeFieldExplanation)
                            }
                        </SpaceBetween>
                    }
                    errorText={props.errorTexts.addressLatitude}
                >
                    <FremontInput
                        id="addressLatitude"
                        value={props.address.addressLatitude}
                        disabled={props.allFieldsDisabled}
                        onInput={props.handleAddressInputChange}
                    />
                </FormField>
                : null}
            <FormField
                label={optionalLabel("Address Line Two:")}
                errorText={props.errorTexts.addressLineTwo}
            >
                <FremontInput
                    id="addressLineTwo"
                    placeholder={props.allFieldsDisabled ? "" : "Apartment, suite, unit, building, floor, etc."}
                    value={props.address.addressLineTwo}
                    disabled={props.allFieldsDisabled}
                    onInput={props.handleAddressInputChange}
                />
            </FormField>
            {props.isSiteForm ?
                <FormField
                    label={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            {AddressValidation.areCoordinatesRequired(props.address) ?
                                requiredLabel("Longitude:") : optionalLabel("Longitude:")}
                            {
                                HelperFunctions.renderCustomTooltip(null,
                                    Constants.TOOLTIP_STRINGS.longitudeFieldExplanation)
                            }
                        </SpaceBetween>
                    }
                    errorText={props.errorTexts.addressLongitude}
                >
                    <FremontInput
                        id="addressLongitude"
                        value={props.address.addressLongitude}
                        disabled={props.allFieldsDisabled}
                        onInput={props.handleAddressInputChange}
                    />
                </FormField>
                : null
            }
            <FormField
                label={AddressValidation.isAddressRequired(props.isSiteForm, props.address) ?
                    requiredLabel("City:") : "City:"}
                errorText={props.errorTexts.addressCity}
            >
                <FremontInput
                    id="addressCity"
                    value={props.address.addressCity}
                    disabled={props.allFieldsDisabled}
                    onInput={props.handleAddressInputChange}
                />
            </FormField>
            <FormField
                label={AddressValidation.isAddressRequired(props.isSiteForm, props.address) ?
                    requiredLabel("State / Province:") : "State / Province:"}
                errorText={props.errorTexts.addressState}
            >
                <FremontInput
                    id="addressState"
                    value={props.address.addressState}
                    disabled={props.allFieldsDisabled}
                    onInput={props.handleAddressInputChange}
                />
            </FormField>
            <FormField
                label={AddressValidation.isAddressRequired(props.isSiteForm, props.address) ?
                    requiredLabel("Zip Code:") : "Zip Code:"}
                errorText={props.errorTexts.addressZipCode}
            >
                <FremontInput
                    id="addressZipCode"
                    value={props.address.addressZipCode}
                    disabled={props.allFieldsDisabled}
                    onInput={props.handleAddressInputChange}
                />
            </FormField>
            {props.isSiteForm ?
                <FormField
                    label={optionalLabel("County / District:")}
                    errorText={props.errorTexts.addressCounty}
                >
                    <FremontInput
                        id="addressCounty"
                        value={props.address.addressCounty}
                        disabled={props.allFieldsDisabled}
                        onInput={props.handleAddressInputChange}
                    />
                </FormField>
                : null
            }
            <FormField
                label={AddressValidation.isAddressRequired(props.isSiteForm, props.address) ?
                    requiredLabel("Country:") : "Country:"}
                errorText={props.errorTexts.addressCountry}
            >
                <FremontSelect
                    id="addressCountry"
                    options={HelperFunctions.getCountryOptions()}
                    selectedOption={HelperFunctions.createSelectedOption(
                        props.address.addressCountry
                    )}
                    disabled={props.allFieldsDisabled}
                    onChange={props.handleAddressInputChange}
                    filteringType="auto"
                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                />
            </FormField>
        </ColumnLayout>
    )
);

export default AddressForm;