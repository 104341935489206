import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Form,
    Header,
    FormField,
    Link,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import ContactValidation from "contact/ContactValidation";
import HelperFunctions from "common/HelperFunctions";
import {
    ContactFormAdditionalProviderInputs,
    ContactFormProviderServices,
    ContactFormSites,
    ContactAlertModal
} from "contact/ContactFormComponents";
import {
    ComponentConstants,
    optionalLabel,
    requiredLabel,
    FremontButton,
    FremontInput,
    FremontSelect,
    FremontTextarea
} from "utils/CommonComponents";

const fetchCallBackLink = providerName =>
    (providerName ? `${Constants.ROUTES.provider}/${providerName}` : Constants.ROUTES.fremontDashboard);

/**
 * ContactForm is the React component that will handle creating a contact
*/
const ContactForm = props => (
    <div>
        <ContactAlertModal
            showContactAlertModal={props.showContactAlertModal}
            handleContactAlertOkay={props.handleContactAlertOkay}
            handleContactAlertCancel={props.handleContactAlertCancel}
        />
        <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
            <Form
                header={
                    <Header
                        variant="h1"
                        description="Fill out the required fields in this form to create a contact."
                    >
                        Contact Creation Form
                    </Header>
                }
                actions={(
                    <SpaceBetween
                        direction="horizontal"
                        size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                    >
                        {props.isModal ?
                            <FremontButton
                                variant="normal"
                                disabled={props.isSubmissionInProgress}
                                onClick={props.handleContactModalClick}
                            >
                                Cancel
                            </FremontButton>
                            :
                            <Link href={fetchCallBackLink(props.providerName)}>
                                <FremontButton
                                    variant="normal"
                                    disabled={props.isSubmissionInProgress}
                                >
                                    Cancel
                                </FremontButton>
                            </Link>
                        }
                        <FremontButton
                            variant="primary"
                            id={Constants.SUBMIT_BUTTON_ID}
                            disabled={props.allFieldsDisabled || props.duplicateContactDisable}
                            loading={props.isSubmissionInProgress}
                            onClick={props.handleContactSubmit}
                        >
                            {
                                props.isSubmissionInProgress ?
                                    Constants.SUBMISSION_STRINGS.submitInProgress :
                                    Constants.SUBMISSION_STRINGS.readyToSubmit
                            }
                        </FremontButton>
                    </SpaceBetween>
                )}
            >
                <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                    <Container header={<Header variant="h2">Provider</Header>}>
                        <ColumnLayout columns={2} borders="vertical">
                            <FormField
                                label={optionalLabel("Internal Contact:")}
                            >
                                <Box padding={{ top: "xxs" }}>
                                    <FremontSelect
                                        id="internal"
                                        disabled={props.allFieldsDisabled}
                                        options={Constants.GENERAL_YES_NO_OPTIONS}
                                        selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                                            props.contact.internal
                                        )}
                                        onChange={props.handleChangeInternal}
                                    />
                                </Box>
                            </FormField>
                            <FormField
                                label={requiredLabel("Provider(s):")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.providerNameList : ""}
                                constraintText="Select providers associated with this contact."
                            >
                                <ContactFormAdditionalProviderInputs
                                    providerNameList={props.contact[Constants.ATTRIBUTES.providerNameList]}
                                    handleContactProviderChange={props.handleContactProviderChange}
                                    disableAddProviderButton={props.disableAddProviderButton}
                                    hasSubmittedOnce={props.hasSubmittedOnce}
                                    providersDisabled={props.allFieldsDisabled}
                                    handleAddAdditionalProviderInput={props.handleAddAdditionalProviderInput}
                                    handleSubtractAdditionalProviderInput={props
                                        .handleSubtractAdditionalProviderInput}
                                    handleSubtractSpecificProviderName={props
                                        .handleSubtractSpecificProviderName}
                                    providerOptions={props.providerOptions}
                                    providerInfoLoading={props.providerInfoLoading}
                                    providersLoading={props.providersLoading}
                                    internal={props.contact.internal}
                                />
                            </FormField>
                        </ColumnLayout>
                    </Container>
                    <Container header={<Header variant="h2">Personal Information</Header>}>
                        <ColumnLayout columns={2} borders="vertical">
                            <FormField
                                label={requiredLabel("First Name:")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.firstName : ""}
                            >
                                <FremontInput
                                    id="firstName"
                                    disabled={props.allFieldsDisabled}
                                    value={props.contact.firstName}
                                    onInput={props.handleContactInputChange}
                                    onChange={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Last Name:")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.lastName : ""}
                            >
                                <FremontInput
                                    id="lastName"
                                    disabled={props.allFieldsDisabled}
                                    value={props.contact.lastName}
                                    onInput={props.handleContactInputChange}
                                    onChange={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Salutation:")}
                                errorText={props.contactErrorTexts.salutation}
                            >
                                <FremontSelect
                                    id="salutation"
                                    disabled={props.allFieldsDisabled}
                                    options={ContactValidation.CONTACT_SALUTATION_OPTIONS}
                                    selectedOption={HelperFunctions.createSelectedOption(props.contact.salutation)}
                                    onChange={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Status:")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.status : ""}
                            >
                                <FremontSelect
                                    id="status"
                                    disabled={props.allFieldsDisabled}
                                    options={Constants.GENERAL_STATUS_OPTIONS}
                                    selectedOption={props.allFieldsDisabled ? {}
                                        : HelperFunctions.createSelectedOption(props.contact.status)}
                                    onChange={props.handleContactInputChange}
                                    value={props.contact.status}
                                />
                            </FormField>
                            <FormField
                                label={requiredLabel("Role:")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.role : ""}
                            >
                                <FremontSelect
                                    id="role"
                                    disabled={props.allFieldsDisabled}
                                    options={ContactValidation.CONTACT_ROLE_OPTIONS}
                                    selectedOption={HelperFunctions.createSelectedOption(props.contact.role)}
                                    onChange={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Title:")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.title : ""}
                            >
                                <FremontInput
                                    id="title"
                                    value={props.contact.title}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Team:")}
                                errorText={props.contactErrorTexts.team}
                            >
                                <FremontInput
                                    id="team"
                                    value={props.contact.team}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Department:")}
                                errorText={props.contactErrorTexts.department}
                            >
                                <FremontInput
                                    id="department"
                                    value={props.contact.department}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Level:")}
                                errorText={props.contactErrorTexts.level}
                            >
                                <FremontSelect
                                    id="level"
                                    disabled={props.allFieldsDisabled}
                                    options={HelperFunctions.getNumericalOptions(20)}
                                    selectedOption={HelperFunctions.createSelectedOption(props.contact.level)}
                                    onChange={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Contact Type:")}
                                errorText={props.contactErrorTexts.contactType}
                            >
                                <FremontSelect
                                    id="contactType"
                                    disabled={props.allFieldsDisabled}
                                    options={ContactValidation.CONTACT_TYPE_OPTIONS}
                                    selectedOption={HelperFunctions.createSelectedOption(props.contact.contactType)}
                                    onChange={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Billing Contact:")}
                            >
                                <FremontSelect
                                    id="billingContact"
                                    disabled={props.allFieldsDisabled}
                                    options={Constants.GENERAL_YES_NO_OPTIONS}
                                    selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                                        props.contact.billingContact
                                    )}
                                    onChange={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Description:")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.description : ""}
                            >
                                <FremontTextarea
                                    id="description"
                                    value={props.contact.description}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                        </ColumnLayout>
                    </Container>
                    <Container header={<Header variant="h2">Contact Information</Header>}>
                        <ColumnLayout columns={2} borders="vertical">
                            <FormField
                                label={requiredLabel("Email Address:")}
                                errorText={props.hasSubmittedOnce ? props.contactErrorTexts.email : ""}
                            >
                                <FremontInput
                                    id="email"
                                    value={props.contact.email}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Home Phone:")}
                                errorText={props.contactErrorTexts.homePhone}
                            >
                                <FremontInput
                                    id="homePhone"
                                    value={props.contact.homePhone}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Cell Phone:")}
                                errorText={props.contactErrorTexts.cellPhone}
                            >
                                <FremontInput
                                    id="cellPhone"
                                    value={props.contact.cellPhone}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Work Phone:")}
                                errorText={props.contactErrorTexts.workPhone}
                            >
                                <FremontInput
                                    id="workPhone"
                                    value={props.contact.workPhone}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Assistant Name:")}
                                errorText={props.contactErrorTexts.assistantName}
                            >
                                <FremontInput
                                    id="assistantName"
                                    value={props.contact.assistantName}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                            <FormField
                                label={optionalLabel("Assistant Phone:")}
                                errorText={props.contactErrorTexts.assistantPhone}
                            >
                                <FremontInput
                                    id="assistantPhone"
                                    value={props.contact.assistantPhone}
                                    disabled={props.allFieldsDisabled}
                                    onInput={props.handleContactInputChange}
                                />
                            </FormField>
                        </ColumnLayout>
                    </Container>
                    <Container header={<Header variant="h2">{optionalLabel("Services")}</Header>}>
                        {props.providerInfoLoading ?
                            <Spinner/>
                            :
                            <ContactFormProviderServices
                                providerNameList={props.contact[Constants.ATTRIBUTES.providerNameList]}
                                serviceOptions={props.serviceOptions}
                                handleServiceOptionsChange={props.handleServiceOptionsChange}
                                allFieldsDisabled={props.allFieldsDisabled}
                            />
                        }
                    </Container>
                    <Container header={<Header variant="h2">{optionalLabel("Sites")}</Header>}>
                        {props.providerInfoLoading ?
                            <Spinner/>
                            :
                            <ContactFormSites
                                siteMap={props.contact[Constants.ATTRIBUTES.siteMap]}
                                handleContactInputChange={props.handleContactInputChange}
                                allFieldsDisabled={props.allFieldsDisabled}
                            />
                        }
                    </Container>
                </SpaceBetween>
            </Form>
        </div>
    </div>
);

export default ContactForm;