import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import AddressForm from "address/AddressForm";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import SiteValidation from "site/SiteValidation";
import {
    optionalLabel,
    requiredLabel,
    ProviderSelectComponent,
    FremontButton,
    FremontInput,
    FremontSelect,
    FremontTextarea,
    ComponentConstants
} from "utils/CommonComponents";

const fetchCallBackLink = providerName =>
    (providerName ? `${Constants.ROUTES.provider}/${providerName}` : Constants.ROUTES.fremontDashboard);

/**
 * SiteForm is the React component that will handle creating a site
 */
export const SiteForm = props => (
    (
        <Box padding={{ left: ComponentConstants.BOX_PAGE_PADDING, right: ComponentConstants.BOX_PAGE_PADDING }}>
            <div>
                <Form
                    header={
                        <Header
                            variant="h1"
                            description="Fill out the required fields in this form to create a site."
                        >
                            Site Create Form
                        </Header>}
                    actions={
                        <SpaceBetween
                            direction="horizontal"
                            size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                        >
                            {props.isModal ?
                                <FremontButton
                                    variant="normal"
                                    disabled={props.isSubmissionInProgress}
                                    onClick={props.handleSiteModalClick}
                                >
                                    Cancel
                                </FremontButton>
                                :
                                <Link href={fetchCallBackLink(props.providerName)}>
                                    <FremontButton
                                        variant="normal"
                                        disabled={props.isSubmissionInProgress}
                                    >
                                        Cancel
                                    </FremontButton>
                                </Link>
                            }
                            <FremontButton
                                variant="primary"
                                id={Constants.SUBMIT_BUTTON_ID}
                                disabled={props.allFieldsDisabled}
                                loading={props.isSubmissionInProgress}
                                onClick={props.handleSiteSubmit}
                            >
                                {
                                    props.isSubmissionInProgress ?
                                        Constants.SUBMISSION_STRINGS.submitInProgress :
                                        Constants.SUBMISSION_STRINGS.readyToSubmit
                                }
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    <SiteFormSection
                        editMode={false}
                        site={props.site}
                        siteErrorTexts={props.siteErrorTexts}
                        allFieldsDisabled={props.allFieldsDisabled}
                        showAddressForm
                        handleSiteInputChange={props.handleSiteInputChange}
                        handleAddressInputChange={props.handleAddressInputChange}
                        providersLoading={props.providersLoading}
                        providerOptions={props.providerOptions}
                    />
                </Form>
            </div>
        </Box>
    )
);

export const SiteFormSection = props => (
    <div>
        <SpaceBetween size="l" direction="vertical">
            <Container header={
                <Header variant="h2">
                    General Information
                </Header>
            }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        label={requiredLabel("Site Name:")}
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.siteName]}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.siteName}
                            value={props.site[Constants.ATTRIBUTES.siteName]}
                            disabled={props.allFieldsDisabled || props.editMode}
                            onInput={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Site Type:")}
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.siteType]}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.siteType}
                            disabled={props.allFieldsDisabled}
                            options={HelperFunctions.createSelectedOptions(
                                Object.values(SiteValidation.SITE_TYPE_NAMES)
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.site[Constants.ATTRIBUTES.siteType]
                            )}
                            onChange={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Status:")}
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.status]}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.status}
                            disabled={props.allFieldsDisabled}
                            options={SiteValidation.STATUS_OPTIONS}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.site[Constants.ATTRIBUTES.status]
                            )}
                            onChange={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                {requiredLabel("Market:")}
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.marketFieldExplanation)
                                }
                            </SpaceBetween>
                        }
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.market]}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.market}
                            value={props.site[Constants.ATTRIBUTES.market]}
                            disabled={props.allFieldsDisabled}
                            onInput={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={optionalLabel("Colocation Site Name:")}
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.coloSiteName]}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.coloSiteName}
                            value={props.site[Constants.ATTRIBUTES.coloSiteName]}
                            disabled={props.allFieldsDisabled}
                            onInput={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={props.site[Constants.ATTRIBUTES.siteType] ?
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>
                                    {optionalLabel("CLLI Code:")}
                                </span>
                                {
                                    HelperFunctions.renderClliCodeTooltip(
                                        null, props.site[Constants.ATTRIBUTES.siteType]
                                    )
                                }
                            </SpaceBetween>
                            : optionalLabel("CLLI Code:")
                        }
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.clliCode]}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.clliCode}
                            value={props.site[Constants.ATTRIBUTES.clliCode]}
                            placeholder={props.site[Constants.ATTRIBUTES.siteType]
                            || props.allFieldsDisabled ? "" : "Please choose a site type first"}
                            disabled={!props.site[Constants.ATTRIBUTES.siteType]
                            || props.allFieldsDisabled}
                            onInput={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={optionalLabel("Quarterly Spend:")}
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.quarterlySpend]}
                    >
                        <FremontInput
                            id={Constants.ATTRIBUTES.quarterlySpend}
                            type={Constants.NUMBER}
                            value={props.site[Constants.ATTRIBUTES.quarterlySpend]}
                            disabled={props.allFieldsDisabled}
                            onInput={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={optionalLabel("Managed Site:")}
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.managedSite]}
                    >
                        <FremontSelect
                            id={Constants.ATTRIBUTES.managedSite}
                            disabled={props.allFieldsDisabled}
                            options={Constants.GENERAL_YES_NO_OPTIONS}
                            selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                                props.site[Constants.ATTRIBUTES.managedSite]
                            )}
                            onChange={props.handleSiteInputChange}
                        />
                    </FormField>
                    <FormField
                        label={optionalLabel("Description:")}
                        errorText={props.siteErrorTexts[Constants.ATTRIBUTES.description]}
                    >
                        <FremontTextarea
                            id={Constants.ATTRIBUTES.description}
                            disabled={props.allFieldsDisabled}
                            value={props.site[Constants.ATTRIBUTES.description]}
                            onInput={props.handleSiteInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </Container>
            <Container header={
                <Header variant="h2">
                    Location
                </Header>
            }
            >
                <SpaceBetween size="s">
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label={requiredLabel("Geographic Region:")}
                            errorText={props.siteErrorTexts[Constants.ATTRIBUTES.geographicRegion]}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.geographicRegion}
                                options={HelperFunctions.createSelectedOptions(
                                    Object.values(Constants.GEOGRAPHIC_REGIONS)
                                )}
                                disabled={props.allFieldsDisabled}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.site[Constants.ATTRIBUTES.geographicRegion]
                                )}
                                onChange={props.handleSiteInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("AWS Region:")}
                            errorText={props.siteErrorTexts[Constants.ATTRIBUTES.awsRegion]}
                        >
                            <FremontSelect
                                id={Constants.ATTRIBUTES.awsRegion}
                                options={SiteValidation.AWS_REGIONS}
                                disabled={props.allFieldsDisabled}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.site[Constants.ATTRIBUTES.awsRegion]
                                )}
                                onChange={props.handleSiteInputChange}
                            />
                        </FormField>
                    </ColumnLayout>
                    {props.showAddressForm &&
                    <Container
                        header={
                            <Header
                                variant="h2"
                                description="Please provide either a street address, a latitude and longitude, or both."
                            >
                                Address
                            </Header>
                        }
                    >
                        <AddressForm
                            hasSubmittedOnce={props.hasSubmittedOnce}
                            allFieldsDisabled={props.allFieldsDisabled}
                            address={props.site.address}
                            isSiteForm
                            handleAddressInputChange={props.handleAddressInputChange}
                            errorTexts={props.siteErrorTexts}
                        />
                    </Container>
                    }
                </SpaceBetween>
            </Container>
            <Container header={
                <Header
                    variant="h2"
                    description="Please provide either the building owner, colocation owner."
                >
                    Ownership / Association
                </Header>
            }
            >
                <section>
                    <ColumnLayout columns={2} borders="vertical">
                        <ProviderSelectComponent
                            id={Constants.ATTRIBUTES.buildingOwner}
                            label={props.site[Constants.ATTRIBUTES.siteType]
                            !== SiteValidation.SITE_TYPE_NAMES.offnet &&
                            (props.site[Constants.ATTRIBUTES.buildingOwner]
                                || (!props.site[Constants.ATTRIBUTES.buildingOwner]
                                    && !props.site[Constants.ATTRIBUTES.colocationOwner]))
                                ? requiredLabel("Building Owner:") : optionalLabel("Building Owner:")}
                            errorText={props.siteErrorTexts[Constants.ATTRIBUTES.buildingOwner]}
                            selectedOption={props.site[Constants.ATTRIBUTES.buildingOwner]}
                            providersLoading={props.providersLoading}
                            options={props.providerOptions}
                            disabled={props.allFieldsDisabled}
                            onChange={props.handleSiteInputChange}
                        />
                        <ProviderSelectComponent
                            id={Constants.ATTRIBUTES.colocationOwner}
                            label={props.site[Constants.ATTRIBUTES.siteType]
                            !== SiteValidation.SITE_TYPE_NAMES.offnet &&
                            (props.site[Constants.ATTRIBUTES.colocationOwner]
                                || (!props.site[Constants.ATTRIBUTES.buildingOwner]
                                    && !props.site[Constants.ATTRIBUTES.colocationOwner]))
                                ? requiredLabel("Colocation Owner:") : optionalLabel("Colocation Owner:")}
                            errorText={props.siteErrorTexts[Constants.ATTRIBUTES.colocationOwner]}
                            selectedOption={props.site[Constants.ATTRIBUTES.colocationOwner]}
                            providersLoading={props.providersLoading}
                            options={props.providerOptions}
                            disabled={props.allFieldsDisabled}
                            onChange={props.handleSiteInputChange}
                        />
                    </ColumnLayout>
                </section>
            </Container>
        </SpaceBetween>
    </div>
);