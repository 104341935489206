import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import NodeTable from "node/NodeTable";

/**
 NodeTab is used to display the list of nodes for a given object(site, provider, providerService and ASN).
 */
class NodeTab extends Component {
    state = {
        loading: true,
        nodeItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchNodes();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching given node information based on list of nodeIds
     */
    fetchNodes = async () => {
        try {
            this.setState({ loading: true });

            const response = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.NODE, this.props.nodeIdList, this.props.auth
            );
            const nodeItems = await HelperFunctions.fetchNodeItems(response.nodes, this.props.auth);

            this.setState({
                nodeItems,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the parent page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <NodeTable
                items={this.state.nodeItems}
                loading={this.state.loading}
                emptyTableMessage={this.props.emptyTableMessage}
            />
        );
    }
}

export default NodeTab;