import React, { Component } from "react";
import { get, some } from "lodash";
import {
    CreateCircuitsStageDisplayMode,
    CreateCircuitsStageEditMode
} from "order/stages/install/CreateCircuitsInformation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import RemoveCircuitsModalHandler from "circuitDesign/RemoveCircuitsModalHandler";

export default class CreateCircuitsHandler extends Component {
    static LAG_COUNT_AND_QUANTITY_FIELDS = [
        Constants.ATTRIBUTES.netNewLagCircuitCount, Constants.ATTRIBUTES.netNewLagQuantity,
        Constants.ATTRIBUTES.existingLagCircuitCount, Constants.ATTRIBUTES.existingLagQuantity
    ];

    state = {
        updatedOrder: {},
        errorTexts: {},
        isEditClicked: false,
        isUpdateStageInProgress: false,
        hasBeenSubmittedOnce: false,
        isRemoveCircuitsModalVisible: false,
        updatedTransport: {}
    };

    getRequiredErrorTexts = () => {
        if (this.props.order.circuitQuantity) {
            if (this.props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
                return Object.assign(
                    HelperFunctions.deepClone(OrderValidation.EMPTY_ADD_CIRCUITS_ERROR_TEXTS),
                    {
                        transportCorridor: "",
                        maxLatencyProtectState: "",
                        maxLatencySteadyState: ""
                    }
                );
            }
            return OrderValidation.EMPTY_ADD_CIRCUITS_ERROR_TEXTS;
        }
        if (Constants.CONSUMABLE_CUSTOMER_FABRICS.includes(this.props.order.customerFabric)) {
            return OrderValidation.BACKBONE_SPAN_ADD_CIRCUITS_ERROR_TEXTS;
        }
        if (Constants.PATH_CUSTOMER_FABRICS.includes(this.props.order.customerFabric)) {
            return OrderValidation.BACKBONE_PATH_ADD_CIRCUITS_ERROR_TEXTS;
        }
        return OrderValidation.DEFAULT_ADD_CIRCUITS_ERROR_TEXTS_NO_LACP;
    };

    FremontBackendClient = new FremontBackendClient();

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);

        // Since we set the LACP Provider option to yes by default, we should default the LAG Quantity fields to 0 if
        // no quantities are already present
        const updatedOrder = HelperFunctions.deepClone(this.props.order);
        if (this.props.circuitDesignObjects.length > 0 && HelperFunctions.isOrderSpanOrder(this.props.order)) {
            const stringAttributesToUpdate = [
                Constants.ATTRIBUTES.maxLatencyProtectState,
                Constants.ATTRIBUTES.maxLatencySteadyState,
                Constants.ATTRIBUTES.rfcTestingLatency
            ];
            const booleanAttributesToUpdate = [Constants.ATTRIBUTES.isProtected];
            stringAttributesToUpdate.forEach((attribute) => {
                updatedOrder[attribute] = HelperFunctions.getFieldFromFirstCircuitObject(
                    this.props.circuitDesignObjects, attribute, ""
                );
            });
            booleanAttributesToUpdate.forEach((attribute) => {
                updatedOrder[attribute] = HelperFunctions.getFieldFromFirstCircuitObject(
                    this.props.circuitDesignObjects, attribute, false
                );
            });
            updatedOrder.spanType = get(this.props.circuitDesignObjects[0], Constants.ATTRIBUTES.spanType, null);
        }

        if (HelperFunctions.parseBoolean(updatedOrder.lacpProvider)
            && !updatedOrder.netNewLagQuantity && !updatedOrder.netNewLagCircuitCount
            && !updatedOrder.existingLagQuantity && !updatedOrder.existingLagCircuitCount) {
            updatedOrder.netNewLagQuantity = "0";
            updatedOrder.netNewLagCircuitCount = "0";
            updatedOrder.existingLagQuantity = "0";
            updatedOrder.existingLagCircuitCount = "0";
        }

        // For backbone orders if the circuit already exists, we can extract the transport region and corridor
        // and then let users update them if they want.
        // We can grab any circuitDesign's transportRegion as all circuits on an order MUST have the same
        // transportRegion and transportCorridor
        let updatedTransport;
        if (this.props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            if (this.props.circuitDesignObjects.length > 0) {
                updatedTransport = {
                    transportRegion:
                        this.props.circuitDesignObjects.find(Boolean)[Constants.ATTRIBUTES.transportRegion] || "",
                    transportCorridor:
                        this.props.circuitDesignObjects.find(Boolean)[Constants.ATTRIBUTES.transportCorridor] || ""
                };
            } else {
                updatedTransport = {
                    transportRegion: "",
                    transportCorridor: ""
                };
            }
        }

        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            isUpdateStageInProgress: false,
            hasBeenSubmittedOnce: false,
            errorTexts: this.getRequiredErrorTexts(),
            updatedOrder,
            updatedTransport
        });
    };

    /**
     * This method handles input changes to the createCircuits
     */
    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.updatedOrder);
        input.orderErrorTexts = HelperFunctions.deepClone(this.state.errorTexts);
        input.siteOptions = HelperFunctions.deepClone(this.state.siteOptions);
        input.transport = HelperFunctions.deepClone(this.state.updatedTransport);


        // If isProtected is False, then assign Max Protect State equals to Max Steady State
        if (evt.target.id === Constants.ATTRIBUTES.maxLatencySteadyState
            && input.order.isProtected === Constants.FALSE_STRING) {
            input.order.maxLatencyProtectState = evt.detail.value;
        } else if (evt.target.id === Constants.ATTRIBUTES.isProtected
            && evt.detail.selectedOption.value === Constants.FALSE_STRING) {
            input.order.maxLatencyProtectState = input.order.maxLatencySteadyState;
            input.orderErrorTexts.maxLatencyProtectState = "";
        }

        const output = OrderValidation.validateInput(input);

        // If the user entered a port size and it is different from the current port size,
        // we auto-assign the default link handoff value for that port size. This is only for backbone orders
        if (evt.target.id === Constants.ATTRIBUTES.portSize
            && evt.detail.selectedOption.value !== this.state.updatedOrder.portSize
            && this.state.updatedOrder.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            const newPortSize = evt.detail.selectedOption.value;
            output.order.linkHandoff = Constants.LINK_HANDOFF_DEFAULTS[newPortSize];
            output.orderErrorTexts.linkHandoff = "";
        }

        // If user changes isProtected field we reset the max Latency Protect State value and
        // remove or add errors based on the selected value of isProtected
        if (evt.target.id === Constants.ATTRIBUTES.isProtected
            && evt.detail.selectedOption.value === Constants.FALSE_STRING) {
            output.orderErrorTexts.maxLatencyProtectState = "";
        } else if (evt.target.id === Constants.ATTRIBUTES.isProtected
            && evt.detail.selectedOption.value === Constants.TRUE_STRING
            && !output.order.maxLatencyProtectState) {
            output.orderErrorTexts.maxLatencyProtectState = Constants.ERROR_STRINGS.blankInput;
        }

        this.setState({
            updatedOrder: output.order,
            errorTexts: output.orderErrorTexts,
            updatedTransport: output.transport
        });
    };

    handleStageSubmit = async () => {
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true
        });
        // Here we assign a value of zero for any lag fields that were not filled out by the user. We do this by
        // constructing an event with the appropriate IDs and input values and passing it to the handleStageInputChange
        // function. We use the inputChange function so that the error messages are updated appropriately
        CreateCircuitsHandler.LAG_COUNT_AND_QUANTITY_FIELDS.forEach((lagField) => {
            if (!this.state.updatedOrder[lagField]) {
                this.handleStageInputChange({
                    target: {
                        id: lagField
                    },
                    detail: {
                        value: "0"
                    }
                });
            }
        });

        if (Object.values(this.state.errorTexts).some(errorText => !!errorText)) {
            this.props.handleFlashBarMessagesFromChildTabs(
                false,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                false
            );
            this.setState({ isUpdateStageInProgress: false, hasBeenSubmittedOnce: true });
            return;
        }
        const updatedOrder = HelperFunctions.deepClone(this.state.updatedOrder);
        if (HelperFunctions.parseInt(updatedOrder.circuitQuantity)
            - HelperFunctions.parseInt(this.props.order.circuitQuantity) > 0) {
            updatedOrder[Constants.FREMONT_OBJECTS.order.circuitQuantityToAddFromRequest] =
                (HelperFunctions.parseInt(updatedOrder.circuitQuantity)
                    - HelperFunctions.parseInt(this.props.order.circuitQuantity)).toString();
        }
        if (HelperFunctions.parseInt(updatedOrder.circuitQuantity)
            < HelperFunctions.parseInt(this.props.order.circuitQuantity)) {
            this.setState({ isRemoveCircuitsModalVisible: true });
        } else {
            await this.submitOrder(updatedOrder);
        }
    };

    submitCircuitDesignChanges = async (order) => {
        const existingCircuitDesignObject = this.props.circuitDesignObjects.find(Boolean);
        if (existingCircuitDesignObject) {
            const isTransportCorridorDirty = existingCircuitDesignObject[Constants.ATTRIBUTES.transportCorridor] !==
                this.state.updatedTransport.transportCorridor;
            const attributesToCheck = [
                Constants.ATTRIBUTES.maxLatencyProtectState,
                Constants.ATTRIBUTES.maxLatencySteadyState,
                Constants.ATTRIBUTES.isProtected,
                Constants.ATTRIBUTES.spanType,
                Constants.ATTRIBUTES.rfcTestingLatency
            ];
            const isDirty = some(attributesToCheck, (attribute) => {
                const current = get(this.state.updatedOrder, attribute, null);
                const existing = get(existingCircuitDesignObject, attribute, null);
                return existing !== current;
            });
            if (!isDirty && !isTransportCorridorDirty) return;
        }
        const circuitDesignResponse = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.CIRCUIT_DESIGN, order.circuitDesignIdList, this.props.auth
        );

        // We need to keep the original objects with us
        const originalCircuits = HelperFunctions.deepClone(circuitDesignResponse.circuitDesigns);

        // Update the circuits with the transport region and corridor
        circuitDesignResponse.circuitDesigns.forEach((circuitDesign) => {
            Object.assign(circuitDesign, {
                transportRegion: this.state.updatedTransport.transportRegion,
                transportCorridor: this.state.updatedTransport.transportCorridor,
                maxLatencySteadyState: this.state.updatedOrder.maxLatencySteadyState,
                maxLatencyProtectState: this.state.updatedOrder.maxLatencyProtectState,
                rfcTestingLatency: this.state.updatedOrder.rfcTestingLatency,
                isProtected: this.state.updatedOrder.isProtected,
                spanType: this.state.updatedOrder.spanType
            });
        });

        const circuitsToUpdate = HelperFunctions.createNewApiObjects(originalCircuits,
            circuitDesignResponse.circuitDesigns, Constants.ATTRIBUTES.circuitDesignId, Constants.KEEP_KEYS.CIRCUIT,
            Constants.IGNORE_KEYS.CIRCUIT);
        await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
    }

    submitOrder = async (updatedOrder) => {
        try {
            const orderResponse = await this.FremontBackendClient.updateOrderInfo(updatedOrder, this.props.order,
                this.props.auth);
            if (updatedOrder.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
                await this.submitCircuitDesignChanges(orderResponse);
            }
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            // Resets all input fields to original state if request is successful
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false,
                hasBeenSubmittedOnce: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isEditClicked: false,
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    /**
     * This method handles displaying the circuit removal modal
     * @returns {*}
     */
    handleToggleRemoveCircuitsModal = async (isUpdateStageInProgress) => {
        HelperFunctions.dismissFlashbar(this);
        this.setState({
            isRemoveCircuitsModalVisible: !this.state.isRemoveCircuitsModalVisible,
            isUpdateStageInProgress,
            hasBeenSubmittedOnce: true
        });
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <CreateCircuitsStageDisplayMode
                    stageName={Constants.STAGE_NAMES.createCircuits}
                    asn={this.props.asn}
                    asnLoading={this.props.asnLoading}
                    order={this.props.order}
                    orderCompleted={this.props.orderCompleted}
                    circuitDesignObjects={this.props.circuitDesignObjects}
                    workOrder={this.state.workOrder}
                    span={this.state.span}
                    siteNames={this.props.siteNames}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    editButtonsDisabled={this.props.editButtonsDisabled}
                    isDataLoaded={this.props.isDataLoaded}
                />
                :
                <div>
                    <RemoveCircuitsModalHandler
                        updateAllCircuitDesignInfo={this.props.updateAllCircuitDesignInfo}
                        handleToggleRemoveCircuitsModal={this.handleToggleRemoveCircuitsModal}
                        submitOrder={this.submitOrder}
                        isRemoveCircuitsModalVisible={this.state.isRemoveCircuitsModalVisible}
                        circuitDesignObjects={this.props.circuitDesignObjects}
                        circuitDesignsLoading={this.props.circuitDesignsLoading}
                        updatedOrder={this.state.updatedOrder}
                        handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                        auth={this.props.auth}
                        blockers={this.props.blockers}
                    />
                    <CreateCircuitsStageEditMode
                        stageName={Constants.STAGE_NAMES.createCircuits}
                        asn={this.props.asn}
                        asnLoading={this.props.asnLoading}
                        updatedOrder={this.state.updatedOrder}
                        updatedTransport={this.state.updatedTransport}
                        siteNames={this.props.siteNames}
                        disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                            this.props.order.stageStatusMap)}
                        errorTexts={this.state.hasBeenSubmittedOnce
                            ? this.state.errorTexts : {}}
                        isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                        hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                        handleStageInputChange={this.handleStageInputChange}
                        handleStageEditClick={this.handleStageEditClick}
                        handleStageSubmit={this.handleStageSubmit}
                    />
                </div>
        );
    }
}