import React, { Component } from "react";
import AuditTable from "audit/AuditTable";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";

/**
 AuditsTab is used to display the list of audits.
 */
class AuditTab extends Component {
    state = {
        loading: false,
        auditItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchAuditItems(this.props.auditIdList);
        }
    };

    componentDidUpdate = async (prevProps) => {
        if (JSON.stringify(prevProps.auditIdList) !== JSON.stringify(this.props.auditIdList)) {
            await this.fetchAuditItems(this.props.auditIdList);
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching given audit information based on list of auditIds
     */
    fetchAuditItems = async (auditIdList) => {
        try {
            this.setState({ loading: true });
            const response = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.AUDIT, auditIdList, this.props.auth
            );

            // He we sort the audits in descending order based on the modifiedTime
            // Not really sure how this is working honestly
            let auditItems = HelperFunctions.sortObjectsByField(
                response.audits, Constants.ATTRIBUTES.createdTime, true
            );

            if (!this.props.user || !this.props.user.permissions
                || !HelperFunctions.userInPosixGroup(this.props.user, Constants.POSIX_GROUPS.NEST)) {
                // here filter out the audits by date
                // https://issues.amazon.com/issues/FremontNEST-1442
                // Give a 30 second buffer https://issues.amazon.com/issues/FremontNEST-2639
                // Some pages send this.props.order as {} before it loads,
                // so also need to check if it has stageStatusMap
                if (this.props.order && this.props.order.stageStatusMap
                    && HelperFunctions.isOrderCompleted(this.props.order.stageStatusMap)) {
                    const completionTime = new Date(
                        this.props.order.stageTimerListMap.completeOrder.slice(-1)[0].finishTime
                    );
                    completionTime.setSeconds(completionTime.getSeconds() + 30);
                    auditItems = auditItems.filter(audit => new Date(audit.createdTime) <= completionTime);
                }
                if (this.props.circuitDesign) {
                    const createdTime = new Date(this.props.circuitDesign.createdTime);
                    createdTime.setSeconds(createdTime.getSeconds() - 30);
                    auditItems = auditItems.filter(audit => new Date(audit.createdTime) >= createdTime);
                }

                // Here we filter out any audits that start with the prefix FREMONT INTERNAL AUDIT.
                // We need to do this for the changed values of the audits because a single audit
                // could have multiple changed values
                auditItems.forEach((audit) => {
                    Object.assign(audit, {
                        [Constants.ATTRIBUTES.changedValues]:
                            audit.changedValues
                                .filter(changedValue =>
                                    !changedValue.startsWith(Constants.AUDIT_CHANGED_VALUE_PREFIX_TO_IGNORE))
                    });
                });
            }

            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({ auditItems, loading: false });
        } catch (error) {
            this.setState({ loading: false });
            // This is used for showing the flashbar error message on the provider audits page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <div>
                <AuditTable
                    items={this.state.auditItems}
                    user={this.props.user}
                    loading={this.state.loading}
                    emptyTableMessage={`No Audits currently exist for this ${this.props.type}`}
                />
            </div>
        );
    }
}

export default AuditTab;