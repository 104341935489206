import React, { Component } from "react";
import generateBackboneImplementationStageColumnDefinitions
from "order/stages/install/BackboneImplementationInformation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";


class BackboneImplementationHandler extends Component {
    state = {
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        isUpdateStageInProgress: false,
        updatedCircuitDesignObjects: [],
        allFieldsDisabled: false,
        massUpdateSelectedCircuitDesignIds: []
    };

    /**
     * Handle any edits to the order
     * @param evt
     */
    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(
            this.state.massUpdateSelectedCircuitDesignIds, input, circuitDesignOutput.circuitDesignObjects
        );
        this.setState({ updatedCircuitDesignObjects: output });
    };

    handleSelectedFromTable = (evt) => {
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItemsObjects = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.allFieldsDisabled
        );

        if (circuitItemsObjects.static.length > 0) {
            circuitItemsObjects.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.COMPONENT_NAMES.demarcAndCfaA]: HelperFunctions.getDemarcAndCfaDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.demarcAndCfaA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.COMPONENT_NAMES.demarcAndCfaZ]: HelperFunctions.getDemarcAndCfaDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.demarcAndCfaZ,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.providerCircuitA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.ATTRIBUTES.loaAttachmentIdA]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.loaAttachmentIdZ]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.kmzAttachmentId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.BERTAttachmentId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.RFCAttachmentId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.completionNoticeId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                                .find(Boolean)
                            : "",
                    downloadAttachment: this.props.downloadAttachment,
                    isDownloadingAttachment: this.props.isDownloadingAttachment
                }));
        }
        if (circuitItemsObjects.dynamic.length > 0) {
            circuitItemsObjects.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    [Constants.COMPONENT_NAMES.demarcAndCfaA]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.demarcAndCfaA],
                    [Constants.COMPONENT_NAMES.demarcAndCfaZ]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.demarcAndCfaZ],
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.providerCircuitA]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.providerCircuitA],
                    [Constants.ATTRIBUTES.loaAttachmentIdA]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.loaAttachmentIdZ]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.kmzAttachmentId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.completionNoticeId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                                .find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.BERTAttachmentId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.RFCAttachmentId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].find(Boolean)
                            : ""
                }));
        }
        return circuitItemsObjects;
    };

    FremontBackendClient = new FremontBackendClient();

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects)
            .map(circuitDesign => Object.assign(circuitDesign, {
                [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.providerCircuitA,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ),
                [Constants.COMPONENT_NAMES.demarcAndCfaA]: HelperFunctions.getDemarcAndCfaDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.demarcAndCfaA,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ) || "",
                [Constants.COMPONENT_NAMES.demarcAndCfaZ]: HelperFunctions.getDemarcAndCfaDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.demarcAndCfaZ,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ) || "",
                errorTexts: {}
            }));
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            hasBeenSubmittedOnce: false,
            updatedCircuitDesignObjects,
            allFieldsDisabled: false
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });

        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const originalCircuitDesignObjects = this.generateCircuitItems().static;
        if (updatedCircuitDesignObjects.some(circuitDesign =>
            Object.values(circuitDesign.errorTexts).some(errorText => errorText))) {
            this.setState({
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            return;
        }

        try {
            await this.FremontBackendClient.modifyProviderCircuit(updatedCircuitDesignObjects,
                originalCircuitDesignObjects, this.props.auth);

            await this.FremontBackendClient.modifyDemarcAndCFA(updatedCircuitDesignObjects,
                originalCircuitDesignObjects, this.props.auth);

            // only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
            const circuitsToUpdate = HelperFunctions.createNewApiObjectsCircuitWrapperForStage(
                originalCircuitDesignObjects, updatedCircuitDesignObjects
            );
            if (circuitsToUpdate.length > 0) {
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }

            // Here we call a helper function which updates all data related to the order
            // and loads new component info as well
            await this.props.loadData(true, true);

            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.backboneImplementation}
                    showAttachmentModal={this.props.showAttachmentModal}
                    disableAttachmentButton={OrderValidation.disableAttachmentButton(
                        this.generateCircuitItems().static.length,
                        this.props.orderCompleted,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    content={
                        <TableDisplayMode
                            order={this.props.order}
                            stageName={Constants.STAGE_NAMES.backboneImplementation}
                            circuitItems={this.generateCircuitItems().static}
                            columnDefinitions={generateBackboneImplementationStageColumnDefinitions(this.props.order,
                                this.props.span)}
                            isDataLoaded={this.props.isDataLoaded}
                        />
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.backboneImplementation}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <TableEditMode
                            circuitItems={this.generateCircuitItems().dynamic}
                            columnDefinitions={generateBackboneImplementationStageColumnDefinitions(this.props.order,
                                this.props.span)}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
        );
    }
}

export default BackboneImplementationHandler;