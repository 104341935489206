import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

/**
 * The FremontComponentInfo displays the createdBy, createdTime, modifiedBy and modifiedTime for all of the components.
 */
const FremontComponentInfo = props => (
    <Box margin={{ top: "l" }}>
        <Container>
            <ColumnLayout columns={2} borders="vertical">
                <div>
                    <SpaceBetween size="l">
                        <div>
                            <Box color="text-label">Last Modified By:</Box>
                            <div>{props.modifiedBy}</div>
                        </div>
                        <div>
                            <Box color="text-label">Created By:</Box>
                            <div>{props.createdBy}</div>
                        </div>
                    </SpaceBetween>
                </div>
                <div>
                    <SpaceBetween size="l">
                        <div>
                            <Box color="text-label">Modified Time:</Box>
                            <div>
                                {HelperFunctions.formatDateAndTime(
                                    props.modifiedTime, Constants.YYYYDDMM_HHMMSS_FORMAT
                                )}
                            </div>
                        </div>
                        <div>
                            <Box color="text-label">Created Time:</Box>
                            <div>
                                {HelperFunctions.formatDateAndTime(
                                    props.createdTime, Constants.YYYYDDMM_HHMMSS_FORMAT
                                )}
                            </div>
                        </div>
                    </SpaceBetween>
                </div>
            </ColumnLayout>
        </Container>
    </Box>
);

export default FremontComponentInfo;