import LinkHierarchyNode from "./LinkHierarchyNode";
import Constants from "../utils/Constants";

export default class VisualizationHelperFunctions {
    // Helper functions for accessing data
    static getStringValuesByKeys(keys, data) {
        const stringValues = {};
        if (data) {
            data.forEach((item) => {
                if (keys.includes(item.key) && item.value.type === "String") {
                    stringValues[item.key] = item.value.stringValue;
                }
            });
        }
        return stringValues;
    }

    static createLinkHierarchyNode(link) {
        const attributeKeys = ["link_type", "end_a_nsm_name", "end_z_nsm_name", "a_end_dark_fibre_handoff",
            "b_end_dark_fibre_handoff", "consumes", "consumers"];
        const stringValues = VisualizationHelperFunctions.getStringValuesByKeys(attributeKeys, link.attributes);

        const toReturn = new LinkHierarchyNode(
            link.instanceId,
            stringValues.link_type,
            stringValues.consumes,
            stringValues.consumers
        );

        if (toReturn.linkType === (Constants.LINK_TYPES.passiveToPassive).replace(/\s/g, "")) {
            toReturn.setPorts(stringValues.a_end_dark_fibre_handoff, stringValues.b_end_dark_fibre_handoff);
        } else {
            toReturn.setPorts(stringValues.end_a_nsm_name, stringValues.end_z_nsm_name);
        }
        return toReturn;
    }

    static getHierarchyData(linkHierarchyResponse) {
        const nodesByName = {};
        linkHierarchyResponse.Links.forEach((link) => {
            const node = VisualizationHelperFunctions.createLinkHierarchyNode(link);
            if (node) {
                nodesByName[node.id] = node;
            }
        });

        Object.values(nodesByName).forEach((node) => {
            if (node.consumes) {
                const consumes = JSON.parse(node.consumes);
                consumes.forEach((consumedName) => {
                    const parts = consumedName.split(".");
                    const result = parts[parts.length - 1];
                    const child = nodesByName[result];
                    if (child) {
                        node.addChild(child);
                    }
                });
            }
            if (node.consumers) {
                const consumers = JSON.parse(node.consumers);
                consumers.forEach((consumerName) => {
                    const parts = consumerName.split(".");
                    const result = parts[parts.length - 1];
                    const parent = nodesByName[result];
                    if (parent) {
                        node.addParent(parent);
                    }
                });
            }
        });

        // Return the root node
        return nodesByName[linkHierarchyResponse.Links[0].instanceId];
    }

    static convertHierarchyToArray(linkHierarchy) {
        const result = [];
        this.traverseHierarchyUpwards(linkHierarchy, result);
        this.traverseHierarchyDownwards(linkHierarchy, result);
        return result;
    }

    static traverseHierarchyDownwards(node, result) {
        if (!result.includes(node)) {
            result.push(node);
        }

        if (node.children.length !== 0) {
            node.children.forEach(child => this.traverseHierarchyDownwards(child, result));
        }
    }
    static traverseHierarchyUpwards(node, result) {
        if (!result.includes(node)) {
            result.push(node);
        }

        if (node.getParents() !== 0) {
            node.getParents().forEach(parent => this.traverseHierarchyUpwards(parent, result));
        }
    }

    static getDCFromPort(port) {
        if (!port) {
            return null;
        }
        const endDC = port.substring(0, port.indexOf("-"));
        const alphaNumericPart = endDC.slice(0, 3).toLowerCase();
        const numericPart = parseInt(endDC.slice(3, 6), 10);
        if (!numericPart) {
            return null;
        }
        return alphaNumericPart + numericPart.toString();
    }

    // Creates a curved (diagonal) path from parent to the child nodes
    static diagonal(s, d, xOffset, yOffset) {
        const path = `M ${s.x + xOffset} ${s.y + yOffset}
                      C ${(s.x + d.x + (xOffset * 2)) / 2} ${s.y + yOffset},
                        ${(s.x + d.x + (xOffset * 2)) / 2} ${d.y + yOffset},
                        ${d.x + xOffset} ${d.y + yOffset}`;
        return path;
    }

    static shouldDrawLink(d, isAEnd) {
        if (isAEnd) {
            return (!VisualizationHelperFunctions.getDCFromPort(d.data.aEndPort) ||
                !VisualizationHelperFunctions.getDCFromPort(d.parent.data.aEndPort) ||
                VisualizationHelperFunctions.getDCFromPort(d.data.aEndPort) ===
                VisualizationHelperFunctions.getDCFromPort(d.parent.data.aEndPort));
        }
        return (!VisualizationHelperFunctions.getDCFromPort(d.data.bEndPort) ||
            !VisualizationHelperFunctions.getDCFromPort(d.parent.data.bEndPort) ||
            VisualizationHelperFunctions.getDCFromPort(d.data.bEndPort) ===
            VisualizationHelperFunctions.getDCFromPort(d.parent.data.bEndPort));
    }
}

