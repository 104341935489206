import React from "react";
import FremontTable from "table/FremontTable";
import ProviderTableData from "provider/ProviderTableData";

export default function ProviderTable(props) {
    return (
        <FremontTable
            entity="Provider"
            createEntityLink={ProviderTableData.fetchCreateProviderLink(props.providerName)}
            columnDefinitions={ProviderTableData.COLUMN_DEFINITIONS}
            tableItems={props.items}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            onNextPageClickHelper={{
                nextToken: props.nextToken, fetch: async () => props.fetchAllProviderItems()
            }}
            nextToken={props.nextToken}
        />
    );
}