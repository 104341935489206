import React, { isValidElement } from "react";
import {
    Button,
    Select,
    Box,
    Icon,
    Input,
    SpaceBetween,
    ExpandableSection, ColumnLayout, Toggle, Multiselect, ButtonDropdown
} from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import HelperFunctions from "../common/HelperFunctions";
import { DO_NOT_EDIT_ATTRIBUTES } from "../link/LinkInformation";
import { recordCustomRumEvent } from "../../../common/RumHelper";

/**
 * This method exists as a wrapper method on top of the polaris <Select> method to pass the id parameter.
 */
export const LinkServiceSelect = props => (
    <Select
        {...props}
        onChange={selectEvt => props.onChange({ ...selectEvt, target: { id: props.id } })}
        expandToViewport
    />
);

export const LinkServiceButtonDropDown = props => (
    <ButtonDropdown
        {...props}
    >
        {props.dropDownButtonName}
    </ButtonDropdown>
);

/**
 * This method exists as a wrapper method on top of the polaris <MultiSelect> method to pass the id parameter.
 */
export const LinkServiceMultiSelect = props => (
    <Multiselect
        {...props}
        onChange={selectEvt => props.onChange({ ...selectEvt, target: { id: props.id } })}
        expandToViewport
    />
);

/**
 * This method exists as a wrapper method on top of the polaris <Button> method to pass the id parameter.
 */
export const LinkServiceButton = props => (
    <Button
        {...props}
        onClick={(evt) => {
            props.onClick({ evt, target: { id: props.id } });
            if (props.isTracked) {
                // Button click will be tracked with CW Rum
                const eventName = props.trackingId ? `${props.trackingId}` : "LinkServiceButton";
                recordCustomRumEvent("buttonClick", {
                    name: eventName,
                    attributes: {
                        pathname: window.location.pathname,
                        url: window.location.href,
                        timestamp: Date.now()
                    }
                });
            }
        }
        }
    >
        {props.children}
    </Button>
);
/**
 * This method exists as a wrapper method on top of the polaris <Input> method to pass the id parameter.
 */
export const LinkServiceInput = props => (
    <Input
        {...props}
        onChange={evt => props.onChange({ ...evt, target: { id: props.id } })}
    >
        {props.children}
    </Input>
);

/**
 * This method exists as a wrapper method on top of the polaris <Toggle> method to pass the id parameter
 * and use the onChange method.
 */
export const LinkServiceToggle = props => (
    <Toggle
        {...props}
        onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
    >
        {props.children}
    </Toggle>
);

/**
 * This method exists as a wrapper method on top of the polaris <ExpandableSection> method to pass the id parameter.
 */
export const LinkServiceExpandableSection = props => (
    <ExpandableSection
        {...props}
        // ExpandableSection onChange event may not always be defined, since we usually don't use that event so we're
        // accounting for the case where that is not passed in
        onChange={({ detail }) =>
            (props.onChange ? props.onChange({ detail, target: { id: props.id } }) : { target: { id: props.id } })}
    />
);

export const LinkServiceInformationField = props => (
    <div>
        <Box color="text-label">{props.label}</Box>
        <div>{props.value}</div>
    </div>
);

export const FileUploadComponent = props => (
    props.fileName ?
        <div>
            <LinkServiceButton
                id={props.buttonId}
                iconName="upload"
                iconAlign="right"
                onClick={props.onClick}
                disabled={props.isAttachmentSubmissionInProgress}
            >
                Choose file
            </LinkServiceButton>
            <IconWithColoredText
                iconName="status-positive"
                text={` "${props.fileName}" attached!`}
                type={Constants.ICON_TEXT_TYPES.success}
            />
        </div>
        :
        <div key={props.key}>
            <LinkServiceButton
                id={props.buttonId}
                iconName="upload"
                iconAlign="right"
                onClick={props.onClick}
                disabled={props.isAttachmentSubmissionInProgress}
            >
                Choose file
            </LinkServiceButton>
        </div>
);

export const IconWithColoredText = (props) => {
    let variant;
    let textColor;
    if (props.type === Constants.ICON_TEXT_TYPES.success) {
        variant = "success";
        textColor = "text-status-success";
    }
    if (props.type === Constants.ICON_TEXT_TYPES.error) {
        variant = "error";
        textColor = "text-status-error";
    }
    if (props.type === Constants.ICON_TEXT_TYPES.secondaryText) {
        variant = "subtle";
        textColor = "text-body-secondary";
    }

    return (
        <SpaceBetween direction="horizontal" size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_LINK_PADDING}>
            <Icon name={props.iconName} variant={variant}/>
            <Box color={textColor}>{props.text}</Box>
        </SpaceBetween>

    );
};

export const TabularDisplayComponent = props => (
    <div>
        <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
            {props.title &&
            <Box color="text-label" fontSize="heading-m">{`${props.title}`}</Box>
            }
            <Box padding={{ left: "s" }}>
                <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_BUTTON_PADDING_XS}>
                    {
                        props.keyValueFields &&
                        HelperFunctions.sortObjectsByField(props.keyValueFields, "key") &&
                        props.keyValueFields.map((keyValueToDisplay) => {
                            // Allow react components to be passed in as well as string elements
                            const value = isValidElement(keyValueToDisplay.value) ? keyValueToDisplay.value : `${keyValueToDisplay.value}`;
                            return (
                                <ColumnLayout columns={2} disableGutters >
                                    <Box variant="strong">
                                        {`${keyValueToDisplay.key}`}
                                    </Box>
                                    <Box>
                                        {props.inEditMode &&
                                        !Object.values(DO_NOT_EDIT_ATTRIBUTES).includes(keyValueToDisplay.key) ?
                                            <LinkServiceInput
                                                id={`${props.idPrefix}${Constants.SEPARATOR}${keyValueToDisplay.key}`}
                                                value={`${keyValueToDisplay.value}`}
                                                onChange={props.onChange}
                                            /> : value}
                                    </Box>
                                </ColumnLayout>
                            );
                        })
                    }
                </SpaceBetween>
            </Box>
        </SpaceBetween>
    </div>
);
