import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class CircuitDesignValidation {
    static INVALID_LAG = "Invalid Lag device name.";

    static getIdsAndIndex = (input) => {
        // To determine the unique id that was passed in from the event, we split the full id on the SEPARATOR
        // constant, the underscore. We now have a list of two elements, one of which is the unique id. Each unique
        // id for a circuitDesign is a uuid which contains a hyphen. We locate the element in the list that has a
        // hyphen, and that is what returns the unique id of the circuitDesign object.
        const uniqueId = input.evt.target.id.split(Constants.SEPARATOR).find(el =>
            el.includes(Constants.UUID_SEPARATOR));
        // To find the attributeId, we perform the same operation except that we find the element that does not contain
        // a hyphen, which will always be the attributeId
        const attributeId = input.evt.target.id.split(Constants.SEPARATOR).find(el =>
            !el.includes(Constants.UUID_SEPARATOR));

        // Get the index of the circuitObject we are changing so we can adjust the attribute values and errors
        const index = input.circuitDesignObjects.findIndex(circuitDesign =>
            circuitDesign.circuitDesignId === uniqueId);
        return {
            attributeId,
            index
        };
    };

    static validateInput = (input) => {
        const output = Object.assign({}, input);
        let inputValue;
        const { attributeId, index } = CircuitDesignValidation.getIdsAndIndex(input);

        // Select fields
        if (output.evt.detail.selectedOption) {
            if ([Constants.COMPONENT_NAMES.nodeA, Constants.COMPONENT_NAMES.nodeZ,
                Constants.COMPONENT_NAMES.lagA, Constants.COMPONENT_NAMES.lagZ,
                Constants.COMPONENT_NAMES.leverA, Constants.COMPONENT_NAMES.leverZ]
                .includes(attributeId)) {
                inputValue = output.evt.detail.selectedOption.value;
                output.circuitDesignObjects[index].errorTexts[attributeId] = "";
            } else if (attributeId === Constants.COMPONENT_NAMES.siteA
                || attributeId === Constants.COMPONENT_NAMES.siteZ) {
                inputValue = output.evt.detail.selectedOption.label;
                output.circuitDesignObjects[index].errorTexts[attributeId] = "";
                if (attributeId === Constants.COMPONENT_NAMES.siteA) {
                    // Here we set the siteAId based on the circuit that was selected
                    output.circuitDesignObjects[index][Constants.ATTRIBUTES.siteAId] =
                        output.evt.detail.selectedOption.value;
                } else {
                    // Here we set the siteZId based on the circuit that was selected
                    output.circuitDesignObjects[index][Constants.ATTRIBUTES.siteZId] =
                        output.evt.detail.selectedOption.value;
                }
            } else {
                inputValue = output.evt.detail.selectedOption.value;
            }
        } else if (typeof output.evt.detail.checked !== "undefined") {
            inputValue = output.evt.detail.checked;
            if (attributeId === Constants.ATTRIBUTES.usingExistingLag) {
                inputValue = output.evt.detail.checked.toString();
            }
        } else {
            inputValue = output.evt.detail.value;
            if (attributeId === Constants.ATTRIBUTES.receiveLightLevelA ||
                attributeId === Constants.ATTRIBUTES.transmitLightLevelA ||
                attributeId === Constants.ATTRIBUTES.receiveLightLevelZ ||
                attributeId === Constants.ATTRIBUTES.transmitLightLevelZ) {
                output.circuitDesignObjects[index].errorTexts[attributeId]
                    = HelperFunctions.validateDecimal(inputValue);
            }
            if ([Constants.COMPONENT_NAMES.portA, Constants.COMPONENT_NAMES.portZ,
                Constants.COMPONENT_NAMES.leverAInternalInterface, Constants.COMPONENT_NAMES.leverZInternalInterface,
                Constants.COMPONENT_NAMES.leverAExternalInterface, Constants.COMPONENT_NAMES.leverZExternalInterface]
                .includes(attributeId)) {
                output.circuitDesignObjects[index].errorTexts[attributeId] = "";
            }
            // Validation for component device names
            if ([Constants.COMPONENT_NAMES.nodeA,
                Constants.COMPONENT_NAMES.nodeZ,
                Constants.COMPONENT_NAMES.portA,
                Constants.COMPONENT_NAMES.portZ,
                Constants.COMPONENT_NAMES.lagA,
                Constants.COMPONENT_NAMES.leverA,
                Constants.COMPONENT_NAMES.leverZ,
                Constants.COMPONENT_NAMES.leverAExternalInterface,
                Constants.COMPONENT_NAMES.leverZExternalInterface,
                Constants.COMPONENT_NAMES.leverAInternalInterface,
                Constants.COMPONENT_NAMES.leverZInternalInterface].includes(attributeId)) {
                if (inputValue) {
                    output.circuitDesignObjects[index].errorTexts[attributeId] = "";
                    if ((attributeId === Constants.COMPONENT_NAMES.nodeA ||
                            attributeId === Constants.COMPONENT_NAMES.nodeZ) &&
                        !HelperFunctions.validateNode(inputValue,
                            output.circuitDesignObjects[index][attributeId === Constants.COMPONENT_NAMES.nodeA ?
                                Constants.COMPONENT_NAMES.siteA : Constants.COMPONENT_NAMES.siteZ])) {
                        output.circuitDesignObjects[index].errorTexts[attributeId] = "Invalid Node device name.";
                    }
                    if ((attributeId === Constants.COMPONENT_NAMES.leverA ||
                        attributeId === Constants.COMPONENT_NAMES.leverZ) &&
                        !HelperFunctions.validateLever(inputValue,
                            output.circuitDesignObjects[index][attributeId === Constants.COMPONENT_NAMES.leverA ?
                                Constants.COMPONENT_NAMES.siteA : Constants.COMPONENT_NAMES.siteZ])) {
                        output.circuitDesignObjects[index].errorTexts[attributeId] = "Invalid Lever device name.";
                    }
                    if ((attributeId === Constants.COMPONENT_NAMES.portA ||
                            attributeId === Constants.COMPONENT_NAMES.portZ) &&
                        !HelperFunctions.validatePort(inputValue)) {
                        output.circuitDesignObjects[index].errorTexts[attributeId] = "Invalid Port interface name.";
                    }
                    if ([Constants.COMPONENT_NAMES.leverAExternalInterface,
                        Constants.COMPONENT_NAMES.leverZExternalInterface,
                        Constants.COMPONENT_NAMES.leverAInternalInterface,
                        Constants.COMPONENT_NAMES.leverZInternalInterface].includes(attributeId) &&
                        !HelperFunctions.validateLeverInterface(inputValue)) {
                        output.circuitDesignObjects[index].errorTexts[attributeId] = "Invalid Lever interface name.";
                    }
                    if (attributeId === Constants.COMPONENT_NAMES.lagA && !HelperFunctions.validateLag(inputValue)) {
                        output.circuitDesignObjects[index].errorTexts[attributeId] =
                            CircuitDesignValidation.INVALID_LAG;
                    }
                } else {
                    output.circuitDesignObjects[index].errorTexts[attributeId] = "";
                }
            }
            // Here we have validation for when the user manually types in a site name
            if (attributeId === Constants.COMPONENT_NAMES.siteA || attributeId === Constants.COMPONENT_NAMES.siteZ) {
                const siteOptionSelected = input.siteOptions.find(siteOption => siteOption.value === inputValue);
                // If the value the user typed in correlates to an actual site object in the siteOptions, than we
                // we assign the siteAId or siteZId appropriately
                if (siteOptionSelected) {
                    output.circuitDesignObjects[index].errorTexts[attributeId] = "";
                    if (attributeId === Constants.COMPONENT_NAMES.siteA) {
                        output.circuitDesignObjects[index][Constants.ATTRIBUTES.siteAId] = siteOptionSelected.id;
                    } else {
                        output.circuitDesignObjects[index][Constants.ATTRIBUTES.siteZId] = siteOptionSelected.id;
                    }
                } else if (inputValue) {
                    output.circuitDesignObjects[index].errorTexts[attributeId] = Constants.ERROR_STRINGS.invalidSite;
                }
            }
            if ([Constants.ATTRIBUTES.amazonIPv6, Constants.ATTRIBUTES.providerIPv6].includes(attributeId)) {
                if (inputValue) {
                    // AmazonIPv6 is allowed to have a subnet, while providerIPv6 is not
                    output.circuitDesignObjects[index].errorTexts[attributeId]
                        = Constants.ATTRIBUTES.amazonIPv6 === attributeId
                            ? HelperFunctions.validateIPv6(inputValue)
                            : HelperFunctions.validateIPv6WithoutSubnet(inputValue);
                } else {
                    output.circuitDesignObjects[index].errorTexts[attributeId] = "";
                }
            }
        }

        // Here we handle removing values from all nodes, ports, and lag fields if the site is changed or removed
        if (attributeId === Constants.COMPONENT_NAMES.siteA && (!inputValue
            || inputValue !== output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.siteA])) {
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.nodeA] = "";
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.portA] = "";
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.lagA] = "";
            // Set usingExistingLag to its default value (which depends on lacpProvider)
            if (HelperFunctions.parseBoolean(output.circuitDesignObjects[index][Constants.ATTRIBUTES.lacpProvider])) {
                output.circuitDesignObjects[index][Constants.ATTRIBUTES.usingExistingLag] = Constants.FALSE_STRING;
            }
        }
        // Here we handle removing value from different port and lag fields if the ports or lags corresponding node
        // field is cleared out or changed
        if (attributeId === Constants.COMPONENT_NAMES.nodeA && (!inputValue
            || inputValue !== output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.nodeA])) {
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.portA] = "";
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.lagA] = "";
        }
        if (attributeId === Constants.COMPONENT_NAMES.nodeZ && (!inputValue
            || inputValue !== output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.nodeZ])) {
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.portZ] = "";
        }
        if (attributeId === Constants.COMPONENT_NAMES.leverA && (!inputValue
            || inputValue !== output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.leverA])) {
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.leverAExternalInterface] = "";
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.leverAInternalInterface] = "";
        }
        if (attributeId === Constants.COMPONENT_NAMES.leverZ && (!inputValue
            || inputValue !== output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.leverZ])) {
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.leverZExternalInterface] = "";
            output.circuitDesignObjects[index][Constants.COMPONENT_NAMES.leverZInternalInterface] = "";
        }

        // If the input value is empty, pass null as the value into the circuitDesign object so a backend error doesn't
        // trigger (dynamo doesn't like blank strings). We pass the input value into the circuitDesign object that
        // was modified
        if (Constants.ATTRIBUTES.subStatusMap === attributeId) {
            // This case covers the subStatusMap
            output.circuitDesignObjects[index][attributeId][input.stageName] = inputValue;
        } else if (attributeId in Constants.TT_TYPES) {
            // This case covers the ttMap id and status
            const ttAttribute = output.evt.target.id.split(Constants.SEPARATOR)[2];
            output.circuitDesignObjects[index][Constants.ATTRIBUTES.ttMap][attributeId][ttAttribute] = inputValue;
            if (ttAttribute === Constants.COMPONENT_NAMES.ttId) {
                output.circuitDesignObjects[index].errorTexts[attributeId] = inputValue ?
                    HelperFunctions.validateTTURL(inputValue) : "";

                // If we are update the ttId with a valid new value or if we clear it out, the status is cleared.
                // So basically if the ttId is updated, we always need to clear out the corresponding TT status
                output.circuitDesignObjects[index][Constants.ATTRIBUTES.ttMap][attributeId][
                    Constants.COMPONENT_NAMES.ttStatus] = "";
            }
        } else if (attributeId === Constants.ATTRIBUTES.encryptionType) {
            // EncryptionType is a special case where we update all of the circuitDesigns with the same encryptionType
            // and we just have one input value at port reservation stage to update all of the circuits
            output.circuitDesignObjects.forEach(circuitDesignObject =>
                Object.assign(circuitDesignObject, { encryptionType: inputValue }));
        } else {
            output.circuitDesignObjects[index][attributeId] = inputValue || null;
        }

        if (attributeId === Constants.ATTRIBUTES.amazonIPv4 || attributeId === Constants.ATTRIBUTES.providerIPv4) {
            // If both fields are empty, we show no error message
            if (!output.circuitDesignObjects[index][Constants.ATTRIBUTES.amazonIPv4] &&
                !output.circuitDesignObjects[index][Constants.ATTRIBUTES.providerIPv4]) {
                output.circuitDesignObjects[index].errorTexts[Constants.ATTRIBUTES.amazonIPv4] = "";
                output.circuitDesignObjects[index].errorTexts[Constants.ATTRIBUTES.providerIPv4] = "";
            } else {
                // Otherwise, we show an error message if the field is blank or invalid
                output.circuitDesignObjects[index].errorTexts[Constants.ATTRIBUTES.amazonIPv4] =
                    output.circuitDesignObjects[index][Constants.ATTRIBUTES.amazonIPv4]
                        ? HelperFunctions.validateIPv4(
                            output.circuitDesignObjects[index][Constants.ATTRIBUTES.amazonIPv4]
                        ) : Constants.ERROR_STRINGS.blankInput;
                // providerIPv4 is not allowed to have a subnet, so we use a different regex to validate it
                output.circuitDesignObjects[index].errorTexts[Constants.ATTRIBUTES.providerIPv4] =
                    output.circuitDesignObjects[index][Constants.ATTRIBUTES.providerIPv4]
                        ? HelperFunctions.validateIPv4WithoutSubnet(
                            output.circuitDesignObjects[index][Constants.ATTRIBUTES.providerIPv4]
                        ) : Constants.ERROR_STRINGS.blankInput;
            }
        }

        if ([Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv6, Constants.IP_TESTING_ATTRIBUTES.providerTestIPv6]
            .includes(attributeId)) {
            output.circuitDesignObjects[index].errorTexts[attributeId] =
                !output.circuitDesignObjects[index][attributeId] ? "" : HelperFunctions
                    .validateIPv6WithoutSubnet(output.circuitDesignObjects[index][attributeId]);
        }

        if ([Constants.IP_TESTING_ATTRIBUTES.amazonTestIPv4, Constants.IP_TESTING_ATTRIBUTES.providerTestIPv4]
            .includes(attributeId)) {
            output.circuitDesignObjects[index].errorTexts[attributeId] =
                !output.circuitDesignObjects[index][attributeId] ? "" : HelperFunctions
                    .validateIPv4WithoutSubnet(output.circuitDesignObjects[index][attributeId]);
        }

        return output;
    }

    static massUpdate = (massUpdateSelectedCircuitDesignIds, input, circuits, oldCircuits) => {
        const { attributeId, index } = CircuitDesignValidation.getIdsAndIndex(input);
        if (attributeId === Constants.ATTRIBUTES.encryptionType ||
            !massUpdateSelectedCircuitDesignIds.includes(circuits[index].circuitDesignId)) {
            // If encryptionType is the value edited
            // OR if the current circuit is not selected for mass update
            // then do not bother with massupdate since encryptionType is not a massUpdate type field
            return circuits;
        }
        const massUpdatedCircuits = circuits.map(circuit => circuit);
        if (attributeId in Constants.TT_TYPES) {
            // This case covers the ttMap id and status, which is slightly special as its a map with multiple separators
            const ttAttribute = input.evt.target.id.split(Constants.SEPARATOR)[2];
            // Using a for loop here
            // Lint does not allow a clean way to update a single field in a map without overwriting other fields
            for (let i = 0; i < circuits.length; i += 1) {
                if (massUpdateSelectedCircuitDesignIds.includes(massUpdatedCircuits[i].circuitDesignId)) {
                    if (ttAttribute === Constants.COMPONENT_NAMES.ttId) {
                        // So basically if the ttId is updated, we always need to clear out the corresponding TT status
                        massUpdatedCircuits[i][Constants.ATTRIBUTES.ttMap][attributeId][
                            Constants.COMPONENT_NAMES.ttStatus] = "";
                        massUpdatedCircuits[i].errorTexts[attributeId] = circuits[index].errorTexts[attributeId];
                        massUpdatedCircuits[i][Constants.ATTRIBUTES.ttMap][attributeId][ttAttribute] =
                            circuits[index][Constants.ATTRIBUTES.ttMap][attributeId][ttAttribute];
                    } else if (ttAttribute === Constants.COMPONENT_NAMES.ttStatus
                        && !massUpdatedCircuits[i].errorTexts[attributeId]
                        && massUpdatedCircuits[i][
                            Constants.ATTRIBUTES.ttMap][attributeId][Constants.COMPONENT_NAMES.ttId]) {
                        massUpdatedCircuits[i][Constants.ATTRIBUTES.ttMap][attributeId][ttAttribute] =
                            circuits[index][Constants.ATTRIBUTES.ttMap][attributeId][ttAttribute];
                    }
                }
            }
        } else if (Constants.COMPONENT_NAMES.nodeA === attributeId) {
            // Here we will massUpdate nodeA, which gets cleared out multiple other fields like portA and lagA
            // In addition only update nodes which have same site
            const changedOrCleared = !massUpdatedCircuits[index][attributeId] ||
                massUpdatedCircuits[index][attributeId] !== oldCircuits[index][attributeId];
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => circuit.siteA === massUpdatedCircuits[index].siteA)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [Constants.COMPONENT_NAMES.portA]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.portA],
                        [Constants.COMPONENT_NAMES.lagA]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.lagA],
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId],
                            [Constants.COMPONENT_NAMES.portA]: !massUpdatedCircuits[index][attributeId] ?
                                "" : massUpdatedCircuits[index].errorTexts[Constants.COMPONENT_NAMES.portA],
                            [Constants.COMPONENT_NAMES.lagA]: !massUpdatedCircuits[index][attributeId] ?
                                "" : massUpdatedCircuits[index].errorTexts[Constants.COMPONENT_NAMES.lagA]
                        })
                    }));
        } else if (Constants.COMPONENT_NAMES.nodeZ === attributeId) {
            // Here we will massUpdate nodeZ, which gets cleared out multiple other fields like portZ and lagZ
            // In addition only update nodes which have same site
            const changedOrCleared = !massUpdatedCircuits[index][attributeId] ||
                massUpdatedCircuits[index][attributeId] !== oldCircuits[index][attributeId];
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => circuit.siteZ === massUpdatedCircuits[index].siteZ)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [Constants.COMPONENT_NAMES.portZ]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.portZ],
                        [Constants.COMPONENT_NAMES.lagZ]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.lagZ],
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId],
                            [Constants.COMPONENT_NAMES.portZ]: changedOrCleared ?
                                "" : massUpdatedCircuits[index].errorTexts[Constants.COMPONENT_NAMES.portZ],
                            [Constants.COMPONENT_NAMES.lagZ]: changedOrCleared ?
                                "" : massUpdatedCircuits[index].errorTexts[Constants.COMPONENT_NAMES.lagZ]
                        })
                    }));
        } else if (Constants.COMPONENT_NAMES.leverA === attributeId) {
            // Here we will massUpdate leverA, which gets cleared out multiple other lever fields also get cleared
            // In addition only update levers which have same site
            const changedOrCleared = !massUpdatedCircuits[index][attributeId] ||
                massUpdatedCircuits[index][attributeId] !== oldCircuits[index][attributeId];
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => circuit.siteA === massUpdatedCircuits[index].siteA)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [Constants.COMPONENT_NAMES.leverAExternalInterface]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.leverAExternalInterface],
                        [Constants.COMPONENT_NAMES.leverAInternalInterface]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.leverAInternalInterface],
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId],
                            [Constants.COMPONENT_NAMES.leverAExternalInterface]:
                                !massUpdatedCircuits[index][attributeId] ? "" :
                                    massUpdatedCircuits[index]
                                        .errorTexts[Constants.COMPONENT_NAMES.leverAExternalInterface],
                            [Constants.COMPONENT_NAMES.leverAInternalInterface]:
                                !massUpdatedCircuits[index][attributeId] ? "" :
                                    massUpdatedCircuits[index]
                                        .errorTexts[Constants.COMPONENT_NAMES.leverAInternalInterface]
                        })
                    }));
        } else if (Constants.COMPONENT_NAMES.leverZ === attributeId) {
            // Here we will massUpdate leverZ, which gets cleared out multiple other lever fields also get cleared
            // In addition only update nodes which have same site
            const changedOrCleared = !massUpdatedCircuits[index][attributeId] ||
                massUpdatedCircuits[index][attributeId] !== oldCircuits[index][attributeId];
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => circuit.siteZ === massUpdatedCircuits[index].siteZ)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [Constants.COMPONENT_NAMES.leverZExternalInterface]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.leverZExternalInterface],
                        [Constants.COMPONENT_NAMES.leverZInternalInterface]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.leverZInternalInterface],
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign({}, circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId],
                            [Constants.COMPONENT_NAMES.leverZExternalInterface]:
                                !massUpdatedCircuits[index][attributeId] ? "" :
                                    massUpdatedCircuits[index]
                                        .errorTexts[Constants.COMPONENT_NAMES.leverZExternalInterface],
                            [Constants.COMPONENT_NAMES.leverZInternalInterface]:
                                !massUpdatedCircuits[index][attributeId] ? "" :
                                    massUpdatedCircuits[index]
                                        .errorTexts[Constants.COMPONENT_NAMES.leverZInternalInterface]
                        })
                    }));
        } else if (Constants.COMPONENT_NAMES.siteA === attributeId) {
            // Here we will massUpdate siteA, when siteA gets cleared out multiple other fields also get
            // cleared out.
            const changedOrCleared = !massUpdatedCircuits[index][attributeId] ||
                massUpdatedCircuits[index][attributeId] !== oldCircuits[index][attributeId];
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [Constants.COMPONENT_NAMES.nodeA]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.nodeA],
                        [Constants.COMPONENT_NAMES.portA]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.portA],
                        [Constants.COMPONENT_NAMES.lagA]: changedOrCleared ?
                            "" : circuitDesign[Constants.COMPONENT_NAMES.lagA],
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        [Constants.ATTRIBUTES.siteAId]:
                            massUpdatedCircuits[index][Constants.ATTRIBUTES.siteAId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId],
                            [Constants.COMPONENT_NAMES.nodeA]: changedOrCleared ?
                                "" : massUpdatedCircuits[index].errorTexts[Constants.COMPONENT_NAMES.nodeA],
                            [Constants.COMPONENT_NAMES.portA]: changedOrCleared ?
                                "" : massUpdatedCircuits[index].errorTexts[Constants.COMPONENT_NAMES.portA],
                            [Constants.COMPONENT_NAMES.lagA]: changedOrCleared ?
                                "" : massUpdatedCircuits[index].errorTexts[Constants.COMPONENT_NAMES.lagA]
                        })
                    }));
        } else if ([Constants.COMPONENT_NAMES.lagA, Constants.COMPONENT_NAMES.portA].includes(attributeId)) {
            // Here we will massUpdate lagA and portA an as they are siteA dependent.
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => circuit.siteA === massUpdatedCircuits[index].siteA)
                .filter(circuit => !!circuit.nodeA)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId]
                        })
                    }));
        } else if ([Constants.COMPONENT_NAMES.portZ].includes(attributeId)) {
            // Here we will massUpdate lagZ and portZ as they are siteZ dependent.
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => circuit.siteZ === massUpdatedCircuits[index].siteZ)
                .filter(circuit => !!circuit.nodeZ)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId]
                        })
                    }));
        } else if (Constants.ATTRIBUTES.usingExistingLag === attributeId) {
            // Here we will massUpdate lagA and portA and usingExistingLag as they are siteA dependent.
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => !!circuit.lagA)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [attributeId]: massUpdatedCircuits[index][attributeId]
                    }));
        } else if ([Constants.COMPONENT_NAMES.leverAExternalInterface,
            Constants.COMPONENT_NAMES.leverAInternalInterface].includes(attributeId)) {
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => !!circuit.leverA)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId]
                        })
                    }));
        } else if ([Constants.COMPONENT_NAMES.leverZExternalInterface,
            Constants.COMPONENT_NAMES.leverZInternalInterface].includes(attributeId)) {
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .filter(circuit => !!circuit.leverZ)
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId]
                        })
                    }));
        } else if ([Constants.ATTRIBUTES.implementationStatus, Constants.ATTRIBUTES.releasePortStatus,
            Constants.ATTRIBUTES.filterStatus, Constants.ATTRIBUTES.turnDownStatus, Constants.ATTRIBUTES.costOutStatus,
            Constants.ATTRIBUTES.verifyStopBillingStatus, Constants.ATTRIBUTES.carrierConfirmationStatus,
            Constants.ATTRIBUTES.carrierNotificationStatus, Constants.ATTRIBUTES.subStatusMap,
            Constants.IP_TESTING_ATTRIBUTES.ipTestingStatus, Constants.IP_TESTING_ATTRIBUTES.vlanNumber]
            .includes(attributeId)) {
            // Here we will run generic mass update for stages without a errorText field
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [attributeId]: massUpdatedCircuits[index][attributeId]
                    }));
        } else {
            // Here we will run generic mass update for stages with a errorText field
            massUpdatedCircuits
                .filter(circuit => massUpdateSelectedCircuitDesignIds.includes(circuit.circuitDesignId))
                .map(circuitDesign =>
                    Object.assign(circuitDesign, {
                        [attributeId]: massUpdatedCircuits[index][attributeId],
                        errorTexts: Object.assign(circuitDesign.errorTexts, {
                            [attributeId]: massUpdatedCircuits[index].errorTexts[attributeId]
                        })
                    }));
        }
        return massUpdatedCircuits;
    }
}