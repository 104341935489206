import React, { Component } from "react";
import { Tabs } from "@amzn/awsui-components-react/polaris";
import BillingTab from "dashboard/BillingTab";
import CircuitDesignTable from "circuitDesign/CircuitDesignTable";
import Constants from "utils/Constants";
import ContactTable from "contact/ContactTable";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import OrderTable from "order/OrderTable";
import OrderTableData from "order/OrderTableData";
import ProviderTable from "provider/ProviderTable";
import SiteTable from "site/SiteTable";
import UserTab from "dashboard/UserTab";

/**
 * DashboardPage acts a the homepage and displays all of the provider information in a table
 */
export default class DashboardPage extends Component {
    static ALL_PROVIDERS_TAB_ID = "allProvidersTab";
    static ALL_SITES_TAB_ID = "allSitesTab";
    static ALL_CONTACTS_TAB_ID = "allContactsTab";
    static ALL_ORDERS_TAB_ID = "allOrdersTab";
    static ALL_CIRCUIT_DESIGNS_TAB_ID = "circuitDesignTab";
    static ALL_BILLING_TAB_ID = "billingTab";
    static USER_TAB_ID = "userTab";

    /**
     * Gets the items where the user might have work to do.
     * @param user used for determining posix groups
     * @param items either created by or owned by the user, passed in from fremontDashboard page
     */
    static parseUserItems = (user, items) => (
        items.filter(item => item.workflow && Object.keys(item.workflow.stages).some(stageName =>
            HelperFunctions.isStageInProgress(item.stageStatusMap[stageName])
            && Object.keys(user.permissions).filter(posixGroup => user.permissions[posixGroup]).some(posixGroup =>
                Constants.POSIX_GROUP_TO_TAB_MAP[posixGroup]
            && Constants.POSIX_GROUP_TO_TAB_MAP[posixGroup].includes(item.workflow.stages[stageName].tab))))
    );

    state = {
        activeTabId: DashboardPage.USER_TAB_ID,
        allProviderItems: [],
        allSiteItems: [],
        allContactItems: [],
        allOrderItems: [],
        allCircuitDesignItems: [],
        flashbar: {
            type: "",
            text: "",
            flashbarLoading: false
        },
        loadingAllOrders: false,
        loadingAllContacts: false,
        loadingAllSites: false,
        loadingAllProviders: false,
        loadingAllCircuitDesigns: false,
        nextProviderToken: null,
        nextSiteToken: null,
        nextContactToken: null,
        nextOrderToken: null,
        nextCircuitDesignToken: null,
        tablePageSize: Constants.PAGINATION_LIMIT,
        userOrderItems: [],
        userOrderActionItems: [],
        userOrdersLoading: false
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            this.fetchUserItems();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     **************************************************************************************************
     * DATA LOADING
     **************************************************************************************************
     */

    /**
     * This method is used for fetching given order information based on list of orderIds
     * Note: Can speed this up by adding orderType and workflow to circuitDesign objects on backend
     */
    fetchUserItems = async () => {
        try {
            this.setState({ userOrdersLoading: true });

            // Add to orderList the circuitDesign orders
            const orderList = Array.from(new Set([
                ...(!!this.props.user.entitiesCreated.order && this.props.user.entitiesCreated.order),
                ...(!!this.props.user.entitiesOwned.order && this.props.user.entitiesOwned.order)
            ]));

            const orderResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.ORDER, orderList, this.props.auth
            );

            // Sort orders in descending order by order number
            HelperFunctions.sortOrders(orderResponse.orders);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                userOrderItems: orderResponse.orders,
                userOrderActionItems: DashboardPage.parseUserItems(this.props.user, orderResponse.orders),
                userOrdersLoading: false
            });

            // Get related order objects here, do this later so it doesn't delay objects showing up in table
            const userOrderItems = await OrderTableData.fetchOrderRelatedObjects(
                orderResponse.orders, this.props.auth, this.handleFlashBarMessagesFromChildTabs
            );
            this.setState({
                userOrderItems,
                userOrderActionItems: DashboardPage.parseUserItems(this.props.user, userOrderItems)
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { userOrdersLoading: false });
        }
    };

    /**
     * This method is used for fetching all of the order information
     */
    fetchAllOrderItems = async (resetItems) => {
        try {
            this.setState({ loadingAllOrders: true });
            const nextToken = resetItems ? null : this.state.nextOrderToken;
            const response = await this.FremontBackendClient.getAllOrderInfo(nextToken, this.props.auth);
            const tableItems = resetItems ? [] : [...this.state.allOrderItems];
            tableItems.push(...response.orders);
            // Sort orders in descending order by order number
            HelperFunctions.sortOrders(tableItems);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                allOrderItems: tableItems,
                loadingAllOrders: false,
                nextOrderToken: response.nextToken
            });
            const allOrderItems = await OrderTableData.fetchOrderRelatedObjects(
                tableItems, this.props.auth, this.handleFlashBarMessagesFromChildTabs
            );
            this.setState({
                allOrderItems
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loadingAllOrders: false });
        }
    };

    /**
     * This method is used for fetching all of the provider information
     */
    fetchAllProviderItems = async (resetItems) => {
        try {
            this.setState({ loadingAllProviders: true });
            const nextToken = resetItems ? null : this.state.nextProviderToken;
            const response = await this.FremontBackendClient.getAllProviderInfo(nextToken,
                this.props.auth);
            const tableItems = resetItems ? [] : [...this.state.allProviderItems];
            tableItems.push(...response.providers);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                allProviderItems: tableItems,
                loadingAllProviders: false,
                nextProviderToken: response.nextToken
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loadingAllProviders: false });
        }
    };

    /**
     * This method is used for fetching all of the site information
     */
    fetchAllSiteItems = async (resetItems) => {
        try {
            this.setState({ loadingAllSites: true });
            const nextToken = resetItems ? null : this.state.nextSiteToken;
            const response = await this.FremontBackendClient.getAllSiteInfo(nextToken, this.props.auth);
            const tableItems = resetItems ? [] : [...this.state.allSiteItems];
            tableItems.push(...response.sites);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                allSiteItems: tableItems,
                loadingAllSites: false,
                nextSiteToken: response.nextToken
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loadingAllSites: false });
        }
    };

    /**
     * This method is used for fetching all of the contact information
     */
    fetchAllContactItems = async (resetItems) => {
        try {
            this.setState({ loadingAllContacts: true });
            const nextToken = resetItems ? null : this.state.nextContactToken;
            const response = await this.FremontBackendClient.getAllContactInfo(nextToken, this.props.auth);
            const tableItems = resetItems ? [] : [...this.state.allContactItems];
            tableItems.push(...response.contacts);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                allContactItems: tableItems,
                loadingAllContacts: false,
                nextContactToken: response.nextToken
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loadingAllContacts: false });
        }
    };

    fetchAllCircuitDesignItems = async (resetItems) => {
        try {
            this.setState({ loadingAllCircuitDesigns: true });
            const nextToken = resetItems ? null : this.state.nextCircuitDesignToken;
            const response = await this.FremontBackendClient.getAllCircuitDesignInfo(nextToken, this.props.auth);

            const tableItems = resetItems ? [] : [...this.state.allCircuitDesignItems];
            tableItems.push(...response.circuitDesigns);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                allCircuitDesignItems: tableItems,
                loadingAllCircuitDesigns: false,
                nextCircuitDesignToken: response.nextToken
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loadingAllCircuitDesigns: false });
        }
    };

    /**
     * This function handles changing the active tab on the Tabs component, resetting all data arrays,
     * and resetting the flashbar.
     */
    handleTabChange = async (evt) => {
        const { activeTabId: activeId } = evt.detail;
        this.handleFlashbarClose();
        this.setState({
            activeTabId: activeId,
            allProviderItems: [],
            allContactItems: [],
            nextProviderToken: null,
            nextContactToken: null,
            nextOrderToken: null
        });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                {
                    userOrdersLoading: false
                });
        } else if (activeId === DashboardPage.ALL_PROVIDERS_TAB_ID) {
            await this.fetchAllProviderItems(true);
        } else if (activeId === DashboardPage.ALL_SITES_TAB_ID) {
            await this.fetchAllSiteItems(true);
        } else if (activeId === DashboardPage.ALL_CONTACTS_TAB_ID) {
            await this.fetchAllContactItems(true);
        } else if (activeId === DashboardPage.ALL_ORDERS_TAB_ID) {
            await this.fetchAllOrderItems(true);
        } else if (activeId === DashboardPage.ALL_CIRCUIT_DESIGNS_TAB_ID) {
            await this.fetchAllCircuitDesignItems(true);
        }
    };

    /**
     * This function handles changing the page size on the table
     */
    handlePaginationChange = (evt) => {
        this.setState({
            tablePageSize: evt.detail.pageSize
        });
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, { loading: false });
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     This function updates the tabs of the service details page
     */
    updateTabs = () => {
        const userTab = {
            label: "My Info",
            id: DashboardPage.USER_TAB_ID,
            content: <UserTab
                handlePaginationChange={this.handlePaginationChange}
                handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                tablePageSize={this.state.tablePageSize}
                userOrderItems={this.state.userOrderItems}
                userOrderActionItems={this.state.userOrderActionItems}
                userOrdersLoading={this.state.userOrdersLoading}
                fetchAllOrderItems={this.fetchUserItems}
            />
        };
        const allProvidersTab = {
            label: "All Providers",
            id: DashboardPage.ALL_PROVIDERS_TAB_ID,
            content: <ProviderTable
                items={this.state.allProviderItems}
                loading={this.state.loadingAllProviders}
                fetchAllProviderItems={this.fetchAllProviderItems}
                nextToken={this.state.nextProviderToken}
            />
        };
        const allSitesTab = {
            label: "All Sites",
            id: DashboardPage.ALL_SITES_TAB_ID,
            content: <SiteTable
                items={this.state.allSiteItems}
                loading={this.state.loadingAllSites}
                fetchAllSiteItems={this.fetchAllSiteItems}
                nextToken={this.state.nextSiteToken}
            />
        };
        const allContactsTab = {
            label: "All Contacts",
            id: DashboardPage.ALL_CONTACTS_TAB_ID,
            content: <ContactTable
                items={this.state.allContactItems}
                loading={this.state.loadingAllContacts}
                fetchAllContactItems={this.fetchAllContactItems}
                nextToken={this.state.nextContactToken}
            />
        };
        const allOrdersTab = {
            label: "All Orders",
            id: DashboardPage.ALL_ORDERS_TAB_ID,
            content: <OrderTable
                items={this.state.allOrderItems}
                loading={this.state.loadingAllOrders}
                fetchAllOrderItems={this.fetchAllOrderItems}
                nextToken={this.state.nextOrderToken}
            />
        };
        const allCircuitDesignsTab = {
            label: "All Circuits",
            id: DashboardPage.ALL_CIRCUIT_DESIGNS_TAB_ID,
            content: <CircuitDesignTable
                fetchAllCircuitDesignItems={this.fetchAllCircuitDesignItems}
                items={this.state.allCircuitDesignItems}
                loading={this.state.loadingAllCircuitDesigns}
                nextToken={this.state.nextCircuitDesignToken}
            />
        };
        const billingTab = {
            label: "Billing",
            id: DashboardPage.ALL_BILLING_TAB_ID,
            content: <BillingTab
                orders={this.state.allOrderItems}
                auth={this.props.auth}
                handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                handleFlashbarClose={this.handleFlashbarClose}
                allProviderOptions={this.props.allProviderOptions}
                loading={this.props.allProvidersLoading}
            />
        };
        return [
            userTab, allProvidersTab, allSitesTab, allContactsTab, allOrdersTab, allCircuitDesignsTab,
            billingTab
        ];
    };

    /**
     **************************************************************************************************
     * RENDERING
     **************************************************************************************************
     */

    render() {
        return (
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    flashbarLoading={this.state.flashbar.flashbarLoading}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <Tabs
                        tabs={this.updateTabs()}
                        activeTabId={this.state.activeTabId}
                        variant="default"
                        onChange={this.handleTabChange}
                    />
                </div>
            </div>
        );
    }
}