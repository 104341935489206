import React from "react";
import {
    Container,
    Header
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton
} from "utils/CommonComponents";
import FremontTable from "table/FremontTable";
import BulkUpdateTab from "operations/BulkUpdateTab";
import Constants from "utils/Constants";

const BulkUpdateTabInformation = props => (
    <Container
        header={
            <Header
                actions={
                    <FremontButton
                        id="addJobAttachments"
                        variant="primary"
                        onClick={props.showAttachmentModal}
                        disabled={props.disableAddBulkUpdateButton}
                    >
                        Create a New Job
                    </FremontButton>
                }
            >
                Jobs
            </Header>
        }
    >
        <FremontTable
            entity="Job"
            loading={props.loading}
            loadingText={Constants.STATUS_TEXTS.loadingJobs}
            columnDefinitions={BulkUpdateTab.BULK_UPDATE_COLUMN_DEFINITIONS}
            tableItems={props.jobs}
            emptyTableMessage={props.emptyTableMessage}
        />
    </Container>
);

export default BulkUpdateTabInformation;