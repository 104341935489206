import React, { Component } from "react";
import AsnTable from "asn/AsnTable";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import AsnTableData from "asn/AsnTableData";

/**
 * AsnTab is used to display the list of asns for a given provider.
 */
class AsnTab extends Component {
    state = {
        loading: true,
        asnItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchAsns();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This function returns all ASNs that are related to a provider by going to each provider
     * service in the provider, finding all of the ASNs for each providerService, and concatenating
     * the resulting list together
     */
    fetchAsns = async () => {
        try {
            // Here we call the back end to get all the asn objects
            const getBatchAsnObjectsResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.ASN, this.props.asnIdList, this.props.auth
            );

            // Set the necessary states to display the reformatted response in the table
            this.setState({
                asnItems: getBatchAsnObjectsResponse.asns,
                loading: false
            });

            const asnItems = await AsnTableData.fetchAsnRelatedObjects(
                getBatchAsnObjectsResponse.asns, this.props.auth, this.props.handleFlashBarMessagesFromChildTabs
            );

            this.setState({ asnItems });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the parent page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <AsnTable
                items={this.state.asnItems}
                loading={this.state.loading}
                emptyTableMessage={this.props.emptyTableMessage}
                providerName={this.props.providerName}
            />
        );
    }
}

export default AsnTab;