import React, { Component } from "react";
import { Alert, Box, ColumnLayout, Flashbar, FormField, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import Header from "@amzn/awsui-components-react/polaris/header";
import { LightHouseButton, LightHouseCheckbox, LightHouseInput, LightHouseModal } from "../components/CommonComponents";
import Constants from "../utils/Constants";
import LinkServiceBackendClient from "../../../mango/js/common/LinkServiceBackendClient";
import HelperFunctions from "../common/HelperFunctions";

const siteRegex = /[A-z]{3}[0-9]{1,3}/;

const getEmptyConfig = id => ({
    [Constants.PATCH_PANEL_CONFIG_FIELDS.ID]: id,
    [Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A]: "",
    [Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z]: "",
    [Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT]: "",
    [Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A]: "",
    [Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z]: "",
    [Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS]: false
});

const getEmptyConfigList = () => [getEmptyConfig(0)];

export default class IspPatchPanelConfigUpload extends Component {
    state = {
        patchPanelConfigs: getEmptyConfigList(),
        isSubmitInProgress: false,
        notificationToDisplay: [],
        showInvalidFields: false,
        globalBypassValidations: false,
        showBypassWarning: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isModalVisible !== this.props.isModalVisible) {
            this.clearUploadModalState();
        }
    }

    getInputId = (id, label) => `${id}${Constants.SEPARATOR}${label}`;

    setInput = (evt) => {
        const { patchPanelConfigs } = this.state;
        const id = evt.target.id.split(Constants.SEPARATOR)[0];
        const field = evt.target.id.split(Constants.SEPARATOR)[1];

        patchPanelConfigs.find(config =>
            config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID].toString() === id)[field] = evt.detail.value;

        this.setState({ patchPanelConfigs });
    }

    toggleCheckbox = (evt) => {
        const { patchPanelConfigs } = this.state;
        const id = evt.target.id.split(Constants.SEPARATOR)[0];
        const field = evt.target.id.split(Constants.SEPARATOR)[1];

        const currentToggle = patchPanelConfigs.find(config =>
            config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID].toString() === id)[field];

        patchPanelConfigs.find(config =>
            config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID].toString() === id)[field] = !currentToggle;
        this.setState({ patchPanelConfigs });
    }

    toggleGlobalBypassValidations = () => {
        const isGlobalBypassEnabled = this.state.globalBypassValidations;
        this.setState({ globalBypassValidations: !isGlobalBypassEnabled });
    }

    isInvalidIspLocation = (location, isValidationBypassed) => {
        if (location.trim() === "") {
            return "Location field is empty";
        }

        if (isValidationBypassed) {
            return undefined;
        }

        // Should have 4 parts
        const tokens = location.split(".");
        if (tokens.length !== 4) {
            return "Location format should be `site.floor.room.rack`";
        }

        // Site code should be valid
        if (!siteRegex.test(tokens[0])) {
            return "Site code is invalid";
        }

        return undefined;
    };

    isInvalidIspPort = (port) => {
        // Check for Port of type Pair/Duplex.
        if (port.includes("-")) {
            if (HelperFunctions.isValidDuplexPort(port)) {
                return undefined;
            }
            return "If Port is Duplex, check: 1) Starting Number Must be ODD. " +
                    "2) '-' Should be between the two numbers. " +
                    "3) Ending Number = Starting Number + 1";
        }
        // Check for Port of type Single/Simplex.
        return HelperFunctions.isInValidInteger(port);
    };

    locationIsInvalid = (config, field) => {
        const errorMessage = this.isInvalidIspLocation(config[field],
            config[Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS]);
        return errorMessage !== undefined;
    };

    portIsInvalid = (config, field) => {
        const errorMessage = this.isInvalidIspPort(config[field]);
        return errorMessage !== undefined;
    };

    portCountFieldIsInvalid = portCount => HelperFunctions.isInValidInteger(portCount) !== undefined

    isBatchConfigInvalid = (configs) => {
        const invalidConfigs =
            configs.filter(config => this.locationIsInvalid(config, Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A) ||
                this.locationIsInvalid(config, Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z) ||
                this.portCountFieldIsInvalid(config[Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT]) ||
                this.portIsInvalid(config, Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A) ||
                this.portIsInvalid(config, Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z));
        return invalidConfigs.length !== 0 && !this.state.globalBypassValidations;
    };

    clearUploadModalState = () => {
        this.setState({
            patchPanelConfigs: getEmptyConfigList(),
            isSubmitInProgress: false,
            notificationToDisplay: [],
            showInvalidFields: false,
            globalBypassValidations: false,
            showBypassWarning: false
        });
    }

    removeConfig = (evt) => {
        let { patchPanelConfigs } = this.state;
        const { id } = evt.target;
        patchPanelConfigs = patchPanelConfigs.filter(
            config => config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID].toString() !== id
        );

        this.setState({ patchPanelConfigs });
    }

    addConfig = () => {
        const { patchPanelConfigs } = this.state;
        patchPanelConfigs.push(getEmptyConfig(patchPanelConfigs.length));
        this.setState({ patchPanelConfigs });
    }

    linkServiceBackendClient = new LinkServiceBackendClient();

    applyBypassValidations = patchPanelConfigs =>
        // Add bypass to input if set. This also overrides validations in backend
        patchPanelConfigs.map(config => ({
            [Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A]: config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z]: config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT]: config[Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A]:
                config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z]:
                config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS]: this.state.globalBypassValidations ? true :
                config[Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS]
        }))


    uploadConfig = async () => {
        this.setState({ isSubmitInProgress: true });
        const { patchPanelConfigs } = this.state;

        // Filter out unwanted fields in the IPPM config objects
        let processedPatchPanelConfigs = patchPanelConfigs.map(config => ({
            [Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A]: config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z]: config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT]: config[Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A]:
                config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z]:
                config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z],
            [Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS]:
                config[Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS]
        }));

        // Make the network call only if validations succeed
        if (this.isBatchConfigInvalid(processedPatchPanelConfigs)) {
            this.setState({ isSubmitInProgress: false, showInvalidFields: true, showBypassWarning: true });
            return;
        }

        processedPatchPanelConfigs = this.applyBypassValidations(processedPatchPanelConfigs);

        try {
            await this.linkServiceBackendClient.batchCreateIspPanelConfig(processedPatchPanelConfigs);
            this.setState({
                notificationToDisplay: this.state.notificationToDisplay.concat(
                    HelperFunctions.generateSuccessMessageForFlashbar(
                        "Successfully created ISP Patch Panel Config", "", true, this
                    )
                )
            });
        } catch (e) {
            this.setState({
                notificationToDisplay: this.state.notificationToDisplay.concat(
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Failed creating ISP Patch Panel Config", e.toString(), true, this
                    )
                )
            });
        }

        this.setState({ isSubmitInProgress: false });
    }

    render() {
        return (
            <LightHouseModal
                header={this.props.header}
                isModalVisible={this.props.isModalVisible}
                onDismiss={this.props.onDismiss}
                size="max"
                footer={
                    <Box variant="span" float="right">
                        <SpaceBetween
                            direction="horizontal"
                            size={Constants.PADDING_SIZES.SPACE_BETWEEN_BUTTON_PADDING}
                        >
                            <LightHouseButton iconName="add-plus" onClick={this.addConfig}>
                                    Add config
                            </LightHouseButton>
                            <LightHouseButton
                                variant="primary"
                                onClick={this.uploadConfig}
                                loading={this.state.isSubmitInProgress}
                            >
                                    Submit
                            </LightHouseButton>
                        </SpaceBetween>
                    </Box>
                }
            >
                <SpaceBetween size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_CONTAINER_PADDING}>
                    <Flashbar items={this.state.notificationToDisplay}/>
                    <div>
                        {this.state.patchPanelConfigs.map(config => (
                            <ColumnLayout columns={6}>
                                {/* Location Side A field */}
                                <FormField
                                    constraintText={this.state.showInvalidFields &&
                                    !config[Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS] &&
                                    this.isInvalidIspLocation(config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A])}
                                >
                                    <LightHouseInput
                                        id={this.getInputId(config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID],
                                            Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A)
                                        }
                                        invalid={this.state.showInvalidFields &&
                                        this.locationIsInvalid(config, Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A)}
                                        value={config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A]}
                                        onChange={event => this.setInput(event)}
                                        placeholder="Location Side A"
                                    />
                                </FormField>

                                {/* Location Side Z field */}
                                <FormField
                                    constraintText={this.state.showInvalidFields &&
                                    !config[Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS] &&
                                    this.isInvalidIspLocation(config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z])}
                                >
                                    <LightHouseInput
                                        id={this.getInputId(config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID],
                                            Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z)
                                        }
                                        invalid={this.state.showInvalidFields &&
                                        this.locationIsInvalid(config, Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z)}
                                        value={config[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z]}
                                        onChange={event => this.setInput(event)}
                                        placeholder="Location Side Z"
                                    />
                                </FormField>

                                {/* Port Count field */}
                                <FormField
                                    constraintText={this.state.showInvalidFields &&
                                    HelperFunctions.isInValidInteger(
                                        config[Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT]
                                    )}
                                >
                                    <LightHouseInput
                                        id={this.getInputId(config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID],
                                            Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT)
                                        }
                                        invalid={this.state.showInvalidFields &&
                                        HelperFunctions.isInValidInteger(config[
                                            Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT])}
                                        value={config[Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT]}
                                        onChange={event => this.setInput(event)}
                                        placeholder="Port Count"
                                    />
                                </FormField>

                                {/* A Starting Port field */}
                                <FormField
                                    constraintText={this.state.showInvalidFields &&
                                        this.isInvalidIspPort(
                                            config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A]
                                        )}
                                >
                                    <LightHouseInput
                                        id={this.getInputId(config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID],
                                            Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A)
                                        }
                                        invalid={this.state.showInvalidFields &&
                                            this.portIsInvalid(config,
                                                Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A)}
                                        value={config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A]}
                                        onChange={event => this.setInput(event)}
                                        placeholder="Starting Port Number Side A"
                                    />
                                </FormField>

                                {/* Z Starting Port field */}
                                <FormField
                                    constraintText={this.state.showInvalidFields &&
                                        this.isInvalidIspPort(
                                            config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z]
                                        )}
                                >
                                    <LightHouseInput
                                        id={this.getInputId(config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID],
                                            Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z)
                                        }
                                        invalid={this.state.showInvalidFields &&
                                            this.portIsInvalid(config,
                                                Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z)}
                                        value={config[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z]}
                                        onChange={event => this.setInput(event)}
                                        placeholder="Starting Port Number Side Z"
                                    />
                                </FormField>

                                {/* Delete config button */}
                                <FormField>
                                    <LightHouseButton
                                        id={`${config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID]}`}
                                        onClick={evt => this.removeConfig(evt)}
                                    >
                                    Delete
                                    </LightHouseButton>
                                </FormField>

                                <LightHouseCheckbox
                                    id={this.getInputId(config[Constants.PATCH_PANEL_CONFIG_FIELDS.ID],
                                        Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS)
                                    }
                                    onChange={event => this.toggleCheckbox(event)}
                                    checked={config[Constants.PATCH_PANEL_CONFIG_FIELDS.BYPASS_VALIDATIONS]}
                                >
                                    This is an MMR Patch Panel
                                </LightHouseCheckbox>
                            </ColumnLayout>
                        ))}
                    </div>
                    <div>
                        {this.state.showBypassWarning &&
                        <Alert
                            statusIconAriaLabel="Warn"
                            type="warning"
                        >
                            <Header
                                variant="h2"
                                description={
                                    <LightHouseCheckbox
                                        onChange={this.toggleGlobalBypassValidations}
                                        checked={this.state.globalBypassValidations}
                                    >
                                        Click here to override warnings and retry submit
                                    </LightHouseCheckbox>}
                            >
                                One or more patch panel names do not follow standard naming convention.
                                Do you still want to create them?
                            </Header>
                        </Alert>}
                    </div>
                </SpaceBetween>
            </LightHouseModal>
        );
    }
}