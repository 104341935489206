import React from "react";
import {
    ColumnLayout,
    Container,
    FormField
} from "@amzn/awsui-components-react/polaris";
import HelperFunctions from "../common/HelperFunctions";
import Constants from "../utils/Constants";
import { LinkServiceButton, LinkServiceSelect, FileUploadComponent } from "../components/CommonComponents";

const CutsheetForm = props => (
    <Container
        footer={!props.isDownloadCutsheetTemplateForm &&
            <LinkServiceButton
                id={`removeCutsheet${props.cutsheet.cutsheetKey}`}
                onClick={props.removeCutsheet}
                disabled={props.isAttachmentSubmissionInProgress}
            >
                    Remove
            </LinkServiceButton>
        }
    >
        <ColumnLayout columns={props.isDownloadCutsheetTemplateForm ? 1 : 2} borders="vertical">
            <FormField
                label="Cutsheet Type:"
                errorText=""
            >
                <LinkServiceSelect
                    id={`type${props.cutsheet.cutsheetKey}`}
                    options={props.cutsheetOptions}
                    empty="Unable to load cutsheet options."
                    onChange={props.handleCutsheetTypeSelectChange}
                    selectedOption={HelperFunctions.createSelectedOptionForAttachment(
                        props.cutsheet.cutsheetType, props.user
                    )}
                />
            </FormField>
            <FormField
                label={Constants.NO_BREAK_SPACE_UNICODE}
            >
                { /* We want to style the file input with Polaris, so we're creating a hidden input element */}
                <input
                    multiple={false}
                    type="file"
                    style={{ display: "none" }}
                    ref={props.fileInputRef}
                    onChange={props.handleFileInput}
                />
                {!props.isDownloadCutsheetTemplateForm &&
                    <FileUploadComponent
                        onClick={e => props.triggerFileInput(e, props.fileInputRef)}
                        fileName={props.cutsheet.fileName}
                        // We disable the upload attachment button until the attachment type is selected or once the
                        // cutsheet has been processed. We also disable it during file uploads, and after its uploaded
                        isAttachmentSubmissionInProgress={!props.cutsheet.cutsheetType
                            || (props.isAttachmentSubmissionInProgress)
                            || (!!props.cutsheet.cutsheetType &&
                                !!props.cutsheet.fileName && props.disableSubmitButton)}
                        // Used to recognize which attachment in our list of attachments is being modified
                        buttonId={`button${props.cutsheet.cutsheetKey}`}
                    />
                }
            </FormField>
        </ColumnLayout>
    </Container>
);

export default CutsheetForm;