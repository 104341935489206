import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import ProviderServiceValidation from "providerService/ProviderServiceValidation";
import {
    optionalLabel,
    requiredLabel,
    ProviderSelectComponent,
    FremontButton,
    FremontInput,
    FremontSelect,
    ComponentConstants
} from "utils/CommonComponents";

const fetchCallBackLink = providerName =>
    (providerName ? `${Constants.ROUTES.provider}/${providerName}` : Constants.ROUTES.fremontDashboard);

const determineServicePlaceholderText = (allFieldsDisabled, providerName, servicesLoading, serviceOptions,
    providerErrorText) => {
    if ((!allFieldsDisabled && !providerName) || (providerName && providerErrorText)) {
        return "Please choose a provider first";
    } else if (!allFieldsDisabled && !servicesLoading && providerName && serviceOptions.length === 0) {
        return "No more services can be created for this provider";
    }
    return "";
};

const determinePlaceholderText = (allFieldsDisabled, providerName, contactsLoading, contactOptions,
    providerErrorText) => {
    if (providerName && contactOptions.length === 0 && !contactsLoading) {
        return Constants.PLACEHOLDER_TEXTS.noContactsForProvider;
    }
    if (allFieldsDisabled
        || (contactsLoading && providerName && !providerErrorText)
        || (providerName && !providerErrorText)) {
        return "";
    }
    return "Please choose a provider first";
};


// We had to write this function to handle some weird ES Lint situation below
function linkDisplayHelper(props) {
    return (
        props.isModal ?
            <FremontButton
                variant="normal"
                disabled={props.isSubmissionInProgress}
                onClick={props.handleProviderServiceModalDismiss}
            >
                Cancel
            </FremontButton>
            :
            <div>
                <Link href={fetchCallBackLink(props.providerService.providerName)}>
                    <FremontButton
                        variant="normal"
                        disabled={props.isSubmissionInProgress}
                    >
                        Cancel
                    </FremontButton>
                </Link>
            </div>
    );
}

/**
 * ProviderServiceForm is the React component that will handle creating a providerService
 */
const ProviderServiceForm = props => (
    <Box padding={{ left: ComponentConstants.BOX_PAGE_PADDING, right: ComponentConstants.BOX_PAGE_PADDING }}>
        <Form
            header={
                <Header
                    variant="h1"
                    description="Fill out the required fields in this form to create a provider service."
                >
                    Provider Service Creation Form
                </Header>
            }
            actions={(
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    {linkDisplayHelper(props)}
                    <FremontButton
                        variant="primary"
                        id={Constants.SUBMIT_BUTTON_ID}
                        disabled={props.allFieldsDisabled}
                        loading={props.isSubmissionInProgress}
                        onClick={props.handleProviderServiceSubmit}
                    >
                        {
                            props.isSubmissionInProgress ?
                                Constants.SUBMISSION_STRINGS.submitInProgress :
                                Constants.SUBMISSION_STRINGS.readyToSubmit
                        }
                    </FremontButton>
                </SpaceBetween>
            )}
        >
            <SpaceBetween size="l">
                <Container header={<Header variant="h2">General Information</Header>}>
                    <ColumnLayout columns={2} borders="vertical">
                        <ProviderSelectComponent
                            id="providerName"
                            label={requiredLabel("Provider:")}
                            errorText={props.providerErrorText}
                            providersLoading={props.providersLoading}
                            selectedOption={props.providerService.providerName}
                            options={props.providerOptions}
                            disabled={props.allFieldsDisabled}
                            onChange={props.handleProviderServiceInputChange}
                        />
                        <FormField
                            label={requiredLabel("Service Type:")}
                            errorText={props.providerServiceErrorTexts.serviceType}
                        >
                            <FremontSelect
                                id="serviceType"
                                placeholder={determineServicePlaceholderText(
                                    props.allFieldsDisabled, props.providerService.providerName,
                                    props.servicesLoading, props.serviceOptions, props.providerErrorText
                                )}
                                disabled={(props.serviceOptions.length === 0 && !props.servicesLoading)
                                || !props.providerService.providerName || props.allFieldsDisabled
                                || !!props.providerErrorText} // Need double !! otherwise it evaluates as string
                                statusType={props.servicesLoading ? Constants.LOADING : Constants.FINISHED}
                                loadingText={props.servicesLoading ?
                                    Constants.STATUS_TEXTS.loadingAvailableServices : null}
                                options={props.servicesLoading ? [] : props.serviceOptions}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.providerService.serviceType
                                )}
                                onChange={props.handleProviderServiceInputChange}
                                value={props.providerService.serviceType}
                            />
                        </FormField>
                        <FormField
                            label={requiredLabel("Status:")}
                            errorText={props.providerServiceErrorTexts.status}
                        >
                            <FremontSelect
                                id="status"
                                disabled={props.allFieldsDisabled}
                                options={Constants.GENERAL_STATUS_OPTIONS}
                                selectedOption={props.allFieldsDisabled ? {}
                                    : HelperFunctions.createSelectedOption(props.providerService.status)}
                                onChange={props.handleProviderServiceInputChange}
                                value={props.providerService.status}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Odin Credentials:")}
                            errorText={props.providerServiceErrorTexts.odinCredentials}
                        >
                            <FremontInput
                                id="odinCredentials"
                                value={props.providerService.odinCredentials}
                                disabled={props.allFieldsDisabled}
                                onInput={props.handleProviderServiceInputChange}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
                <Container header={<Header variant="h2">Contact Information</Header>}>
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label={optionalLabel("Phone Number:")}
                            errorText={props.providerServiceErrorTexts.phone}
                        >
                            <FremontInput
                                id="phone"
                                value={props.providerService.phone}
                                disabled={props.allFieldsDisabled}
                                onInput={props.handleProviderServiceInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Fax Number:")}
                            errorText={props.providerServiceErrorTexts.fax}
                        >
                            <FremontInput
                                id="fax"
                                value={props.providerService.fax}
                                disabled={props.allFieldsDisabled}
                                onInput={props.handleProviderServiceInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Email Address:")}
                            errorText={props.providerServiceErrorTexts.email}
                        >
                            <FremontInput
                                id="email"
                                value={props.providerService.email}
                                disabled={props.allFieldsDisabled}
                                onInput={props.handleProviderServiceInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Website:")}
                            errorText={props.providerServiceErrorTexts.website}
                        >
                            <FremontInput
                                id="website"
                                value={props.providerService.website}
                                disabled={props.allFieldsDisabled}
                                onInput={props.handleProviderServiceInputChange}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
                <Container header={
                    <Header
                        variant="h2"
                        description={props.allFieldsDisabled
                            ? ""
                            : "For each contact, select a level of escalation" +
                            " with one being the lowest and six being the highest."}
                    >
                        {optionalLabel("Escalation Path")}
                    </Header>
                }
                >
                    <ProviderServiceFormEscalationPathField
                        addEscalationPathButtonDisabled={props.addEscalationPathButtonDisabled}
                        handleAdditionalInput={props.handleAdditionalInput}
                        handleEscalationPathSubtraction={props.handleEscalationPathSubtraction}
                        handleProviderServiceInputChange={props.handleProviderServiceInputChange}
                        allFieldsDisabled={props.allFieldsDisabled}
                        hasSubmittedOnce={props.hasSubmittedOnce}
                        contactsLoading={props.contactsLoading}
                        escalationPathObjects={props.escalationPathObjects}
                        contactOptions={props.contactOptions}
                        providerName={props.providerService.providerName}
                        providerErrorText={props.providerErrorText}
                    />
                </Container>
                <Container header={
                    <Header
                        variant="h2"
                    >
                        {optionalLabel("Associated Contact(s)")}
                    </Header>
                }
                >
                    <FormField
                        label="Contact Name:"
                        description={props.allFieldsDisabled ? "" : "Select contacts associated with " +
                            "this provider service (contacts can also be created and added later)."}
                    >
                        <ProviderServiceFormAdditionalContactInputs
                            addContactButtonDisabled={props.addContactButtonDisabled}
                            handleAdditionalInput={props.handleAdditionalInput}
                            handleAssociatedContactsSubtraction={props.handleAssociatedContactsSubtraction}
                            handleProviderServiceInputChange={props.handleProviderServiceInputChange}
                            allFieldsDisabled={props.allFieldsDisabled}
                            hasSubmittedOnce={props.hasSubmittedOnce}
                            contactsLoading={props.contactsLoading}
                            contactObjects={props.contactObjects}
                            contactOptions={props.contactOptions}
                            providerName={props.providerService.providerName}
                            providerErrorText={props.providerErrorText}
                        />
                    </FormField>
                </Container>
            </SpaceBetween>
        </Form>
    </Box>
);

/*
 * This handles what gets rendered in the contact selection section of the providerService form
 */
export const ProviderServiceFormAdditionalContactInputs = props => (
    (
        <div>
            { props.contactObjects.map(contactObject => (
                <div key={contactObject.id}>
                    <FormField
                        errorText={props.hasSubmittedOnce ? contactObject.errorText : ""}
                    >
                        <ColumnLayout columns={2}>
                            <FremontSelect
                                id={contactObject.id}
                                // Since selectedOption by default is an empty object `{}`, the placeholder prop for
                                // FremontSelect wont work. That's why if we have an empty object (nothing has been
                                // selected yet), we convert it into an empty string to show the placeholder
                                selectedOption={Object.keys(contactObject.selectedOption).length > 0 ?
                                    contactObject.selectedOption : ""}
                                placeholder={determinePlaceholderText(props.allFieldsDisabled, props.providerName,
                                    props.contactsLoading, props.contactOptions, props.providerErrorText)}
                                statusType={props.contactsLoading ? Constants.LOADING : Constants.FINISHED}
                                loadingText={Constants.STATUS_TEXTS.loadingContacts}
                                options={props.contactOptions.filter(contactOption =>
                                    !props.contactObjects.map(contactObj =>
                                        contactObj.selectedOption.value).includes(contactOption.value))}
                                disabled={(props.contactOptions.length === 0 && !props.contactsLoading)
                                    || !props.providerName || props.allFieldsDisabled || !!props.providerErrorText}
                                onChange={props.handleProviderServiceInputChange}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                            />
                            <FremontButton
                                id={contactObject.id}
                                iconName="close"
                                variant="normal"
                                onClick={props.handleAssociatedContactsSubtraction}
                                disabled={props.allFieldsDisabled || props.contactObjects.length <= 1}
                            />
                        </ColumnLayout>
                    </FormField>
                </div>
            ))}
            <Box padding={{ top: "xs" }}>
                <FremontButton
                    id={ProviderServiceValidation.BUTTON_NAMES.addContactButton}
                    iconName="add-plus"
                    variant="normal"
                    onClick={props.handleAdditionalInput}
                    disabled={props.allFieldsDisabled || props.addContactButtonDisabled}
                />
            </Box>
        </div>
    )
);

/**
 * This handles what gets rendered in the escalation path selection section of the provider service form
 */
export const ProviderServiceFormEscalationPathField = props => (
    (
        <div>
            { props.escalationPathObjects.map(escalationPathObject => (
                <div key={escalationPathObject.id}>
                    <ColumnLayout columns={2}>
                        <FormField
                            label="Contact Name:"
                            errorText={props.hasSubmittedOnce ? escalationPathObject.contactNameErrorText : ""}
                        >
                            <FremontSelect
                                id={escalationPathObject.id}
                                // Since selectedOption by default is an empty object `{}`, the placeholder prop for
                                // FremontSelect wont work. That's why if we have an empty object (nothing has been
                                // selected yet), we convert it into an empty string to show the placeholder
                                selectedOption={Object.keys(escalationPathObject.selectedOption).length > 0 ?
                                    escalationPathObject.selectedOption : ""}
                                placeholder={determinePlaceholderText(props.allFieldsDisabled, props.providerName,
                                    props.contactsLoading, props.contactOptions, props.providerErrorText)}
                                providersLoading={props.contactsLoading}
                                // Limiting the available options to only the escalationPathObjects
                                // that have not been chosen yet
                                options={props.contactOptions.filter(contactOption =>
                                    !props.escalationPathObjects.map(escalationPathObjectObject =>
                                        escalationPathObjectObject.selectedOption.value).includes(contactOption.value))}
                                disabled={(props.contactOptions.length === 0 && !props.contactsLoading)
                                    || !props.providerName || props.allFieldsDisabled || !!props.providerErrorText}
                                onChange={props.handleProviderServiceInputChange}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                            />
                        </FormField>
                        <FormField
                            label="Escalation Level:"
                            errorText={props.hasSubmittedOnce ? escalationPathObject.levelErrorText : ""}
                        >
                            <div>
                                <ColumnLayout columns={2} borders="none">
                                    <FremontSelect
                                        id={`${escalationPathObject.id} ${ProviderServiceValidation.ESCALATION_LEVEL}`}
                                        value={escalationPathObject.level}
                                        statusType={props.contactsLoading ? Constants.LOADING : Constants.FINISHED}
                                        options={props.contactsLoading ?
                                            [] : ProviderServiceValidation.LEVEL_OPTIONS}
                                        selectedOption={
                                            HelperFunctions.createSelectedOption(escalationPathObject.level)}
                                        disabled={(props.contactOptions.length === 0 && !props.contactsLoading)
                                        || !props.providerName || props.allFieldsDisabled}
                                        onChange={props.handleProviderServiceInputChange}
                                    />
                                    <FremontButton
                                        id={escalationPathObject.id}
                                        iconName="close"
                                        variant="normal"
                                        onClick={props.handleEscalationPathSubtraction}
                                        disabled={props.allFieldsDisabled
                                            || props.escalationPathObjects.length <= 1}
                                    />
                                </ColumnLayout>
                            </div>
                        </FormField>
                    </ColumnLayout>
                </div>
            ))}
            <Box padding={{ top: "xs" }}>
                <FremontButton
                    id={ProviderServiceValidation.BUTTON_NAMES.addEscalationPathButton}
                    iconName="add-plus"
                    variant="normal"
                    onClick={props.handleAdditionalInput}
                    disabled={props.allFieldsDisabled || props.addEscalationPathButtonDisabled}
                />
            </Box>
        </div>
    )
);

export default ProviderServiceForm;