import React from "react";
import { ClipLoader } from "react-spinners";
import Constants from "../utils/Constants";

export default function TabSpinner() {
    return (
        <div className="centerSpinner">
            <ClipLoader
                color={Constants.SPINNER_ORANGE_COLOR}
                loading
                size={Constants.SPINNER_SIZES.loadingPage}
            />
        </div>
    );
}
