import React, { Component } from "react";
import {
    SideNavigation
} from "@amzn/awsui-components-react/polaris";
import {
    FremontToggle
} from "utils/CommonComponents";
import { withRouter } from "react-router-dom";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";

const header = {
    text: "Navigation"
};

const REPORTING = "Reporting";
const NAV_TYPE_DIVIDER = "divider";
const NAV_TYPE_LINK = "link";
const NAV_TYPE_LINK_GROUP = "link-group";

const getNavigationItems = (toggleDarkMode, isDarkMode, toggleSandboxMode, isSandboxMode, user, auth) => {
    // Disabling lint rule for better readability
    /* eslint-disable no-use-before-define */
    const navigationItems = [
        {
            type: NAV_TYPE_LINK,
            text: "Home",
            href: Constants.LIGHTHOUSE_ROUTES.lighthouseLandingPage
        }
    ];

    // Each service has its side nav components grouped into a single item which is why we can use push
    navigationItems.push(getFremontNavigationItems(user, auth));
    navigationItems.push(getLinkServceNavigationItems(user));
    navigationItems.push(getHaloNavigationItems());
    navigationItems.push(getInsightsNavigationItems());
    // The general nav items are a list of multiple items which is why we use the ... syntax
    navigationItems.push(...getGeneralNavigationItems(
        toggleDarkMode, isDarkMode, toggleSandboxMode, isSandboxMode, user
    ));

    return navigationItems;
};

class FremontNavigation extends Component {
    state = {
        generatingQuickSightSessionUrl: false
    };

    // For the onFollowHandler we do not always want to return a value. Only in the case of a static external link
    // do we want to return the value and allow the default behavior to occur
    // eslint-disable-next-line consistent-return
    onFollowHandler = async (evt) => {
        if (evt.detail.href.includes("https")) {
            return evt.detail.href;
        }
        evt.preventDefault();
        // When the reports button is clicked, we generate the appropriate session URL and open that link in a new tab
        if (evt.detail.text === REPORTING) {
            const url = await this.generateReportSessionUrl();
            if (url) window.open(url, "QuickSight Reporting", "", true);
        } else {
            this.props.history.push(evt.detail.href);
            if (window.location.pathname.includes("fremont")) {
                await this.props.getAllProviderItems();
            }
        }
    };

    generateReportSessionUrl = async () => {
        let response;
        // We only make the backend call to load the quickSightSessionUrl if that call is not already being made.
        // This prevents us from making multiple backend calls if the user spam-clicks the Reporting link
        if (!this.state.generatingQuickSightSessionUrl) {
            this.setState({
                generatingQuickSightSessionUrl: true
            });
            try {
                response = await this.FremontBackendClient.getQuickSightSessionUrl(this.props.auth);
                this.setState({
                    generatingQuickSightSessionUrl: false
                });
            } catch (error) {
                this.props.setSideNavError(error);
                this.setState({
                    generatingQuickSightSessionUrl: false
                });
            }
        }
        return response ? response.embedUrl : undefined;
    };

    FremontBackendClient = new FremontBackendClient();

    render() {
        return (
            <SideNavigation
                header={header}
                items={getNavigationItems(
                    this.props.toggleDarkMode, this.props.isDarkMode, this.props.toggleSandboxMode,
                    this.props.isSandboxMode, this.props.user, this.props.auth
                )}
                onFollow={this.onFollowHandler}
            />
        );
    }
}

const getFremontNavigationItems = (user, auth) => {
    const fremontItems = [];

    fremontItems.push(
        {
            type: NAV_TYPE_LINK,
            text: "Dashboard",
            href: Constants.ROUTES.fremontDashboard
        },
        {
            type: NAV_TYPE_LINK,
            text: REPORTING,
            // Need to define a href even though we generate it on-demand when a user clicks this link
            href: "",
            external: true
        },
        {
            type: NAV_TYPE_LINK,
            text: "Manage Order Assignment",
            href: Constants.ROUTES.manageOrderAssignment
        }
    );
    // We display the operations button if the user is currently being mimicked, we are in a local or development
    // environment, or the user is a part of the NEST group
    if (HelperFunctions.isMimickingUser(auth)
        || (!!user.permissions && (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()
            || HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.NEST)))) {
        fremontItems.push(
            {
                type: NAV_TYPE_LINK,
                text: "Operations",
                href: Constants.ROUTES.operations
            }
        );
    }

    // We display the manage stage SLA only to managers and nest
    if (!!user.permissions && (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()
        || HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.NEST)
        || HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT)
        || HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS)
        || HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG)
    )) {
        fremontItems.push(
            {
                type: NAV_TYPE_LINK,
                text: "Manage Order Stage SLAs",
                href: Constants.ROUTES.stageSla
            }
        );
    }
    // Here we add of the entities which can be created in Fremont
    fremontItems.push(
        {
            type: "section",
            text: "Create entities",
            defaultExpanded: false,
            items: [
                {
                    type: NAV_TYPE_LINK,
                    text: "ASN",
                    href: Constants.ROUTES.createAsn
                },
                {
                    type: NAV_TYPE_LINK,
                    text: "Contact",
                    href: Constants.ROUTES.createContact
                },
                {
                    type: NAV_TYPE_LINK,
                    text: "Order",
                    href: Constants.ROUTES.createOrder
                },
                {
                    type: NAV_TYPE_LINK,
                    text: "Provider",
                    href: Constants.ROUTES.createProvider
                },
                {
                    type: NAV_TYPE_LINK,
                    text: "Provider Service",
                    href: Constants.ROUTES.createProviderService
                },
                {
                    type: NAV_TYPE_LINK,
                    text: "Site",
                    href: Constants.ROUTES.createSite
                }
            ]
        },
    );

    return {
        type: NAV_TYPE_LINK_GROUP,
        text: "Fremont",
        defaultExpanded: false,
        items: fremontItems
    };
};

const getLinkServceNavigationItems = (user) => {
    const linkServiceItems = [];

    linkServiceItems.push(
        {
            type: NAV_TYPE_LINK,
            text: "Link Search",
            href: Constants.MANGO_ROUTES.linkSearch
        },
        {
            type: NAV_TYPE_LINK,
            text: "Cutsheet Dashboard",
            href: Constants.MANGO_ROUTES.cutsheetUpload
        }
    );

    if (!HelperFunctions.isProd() || HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.NEST)) {
        linkServiceItems.push({
            type: NAV_TYPE_LINK,
            text: "Link Visualization",
            href: Constants.MANGO_ROUTES.linkVisualization
        });
    }

    linkServiceItems.push(
        {
            type: NAV_TYPE_LINK,
            text: "ISP Patch Panel Page",
            href: Constants.LIGHTHOUSE_ROUTES.ispPatchPanelManagement
        }
    );

    return {
        type: NAV_TYPE_LINK_GROUP,
        text: "LinkService",
        items: linkServiceItems
    };
};

const getHaloNavigationItems = () => {
    const haloItems = [];

    haloItems.push(
        {
            type: NAV_TYPE_LINK,
            text: "Geospatial Fiber View",
            href: Constants.LIGHTHOUSE_PREPENDED_PATHS.halo
        }
    );

    return {
        type: NAV_TYPE_LINK_GROUP,
        text: "Halo",
        items: haloItems
    };
};

const getInsightsNavigationItems = () => {
    const insightsItems = [];

    insightsItems.push(
        {
            type: NAV_TYPE_LINK,
            text: "Fiber Reporting",
            href: Constants.LIGHTHOUSE_ROUTES.reportFiber
        }
    );

    return {
        type: NAV_TYPE_LINK_GROUP,
        text: "Insights",
        items: insightsItems
    };
};

const getGeneralNavigationItems = (toggleDarkMode, isDarkMode, toggleSandboxMode, isSandboxMode, user) => {
    const generalItems = [];

    // Here we add the generic links across both Fremont and LinkService
    generalItems.push(
        { type: NAV_TYPE_DIVIDER },
        {
            type: NAV_TYPE_LINK,
            text: "About",
            href: "https://w.amazon.com/bin/view/NDMS/Lighthouse/",
            external: true
        }
    );
    // Here we add a button in beta and gamma that allows users to enter sandbox mode,
    // where they have full permissions
    if (HelperFunctions.isBetaOrGamma()) {
        generalItems.push({
            type: NAV_TYPE_LINK,
            text: "Sandbox Mode",
            href: "https://sim.amazon.com/issues/FremontNEST-1426",
            external: true,
            info: <FremontToggle onChange={toggleSandboxMode} checked={isSandboxMode}/>
        });
    }

    // Finally, add in dark mode, which is being tested right now
    if (HelperFunctions.isLocalHost()
        || HelperFunctions.isDevelopmentStack()
        || HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.NEST)) {
        generalItems.push({
            type: NAV_TYPE_LINK,
            text: "DarkMode",
            href: "https://polaris.a2z.com/foundation/visual_foundation/visual_modes/",
            external: true,
            info: <FremontToggle onChange={toggleDarkMode} checked={isDarkMode}/>
        });
    }

    return generalItems;
};


export default withRouter(FremontNavigation);