import React from "react";
import Constants from "utils/Constants";
import {
    Box,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton
} from "utils/CommonComponents";

/**
 * This is the modal that pops up to enter the reason data request (for rejection or span decom)
 * and then submit the request.  The modal is hidden
 * by default while the "visible" prop is false. When the add rejection button is clicked, the state of
 * "isAddRejectionClicked" is flipped through the handleRejectionModalClick method which sets the visible prop to true
 * and renders the modal. Hitting "Confirm" calls the method to submit the request.
 */
const ConfirmDeliveryDateModal = props => (
    <Modal
        visible={props.isDeliveryDatePastDate}
        header={Constants.CONFIRM_DELIVERY_DATE_MODAL.header}
        size="large"
        onDismiss={props.handleCloseConfirmDeliveryDateModal}
        footer={
            <Box variant="span" float="right">
                <Box padding={{ left: "s" }}>
                    <SpaceBetween
                        direction="horizontal"
                        size="m"
                    >
                        <FremontButton
                            id="submitAddReasonButton"
                            variant="primary"
                            onClick={props.handleCloseConfirmDeliveryDateModal}
                        >
                            {Constants.CONFIRM_DELIVERY_DATE_MODAL.confirm}
                        </FremontButton>
                    </SpaceBetween>
                </Box>
            </Box>
        }
    >
        <Box margin={{ top: "xxs" }}>
            {Constants.CONFIRM_DELIVERY_DATE_MODAL.message}
        </Box>

    </Modal>
);

export default ConfirmDeliveryDateModal;
