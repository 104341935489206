import React from "react";
import {
    Box,
    Container,
    ColumnLayout,
    Form,
    FormField,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import {
    optionalLabel,
    requiredLabel,
    ProviderSelectComponent,
    FremontButton,
    FremontInput,
    FremontSelect,
    FremontTextarea,
    ComponentConstants
} from "utils/CommonComponents";

const fetchCallBackLink = providerName =>
    (providerName ? `${Constants.ROUTES.provider}/${providerName}` : Constants.ROUTES.fremontDashboard);

/**
 * ProviderForm is the React component that will handle creating a provider
*/
export const ProviderForm = props => (
    <Box padding={{ left: ComponentConstants.BOX_PAGE_PADDING, right: ComponentConstants.BOX_PAGE_PADDING }}>
        <Form
            header={
                <Header
                    variant="h1"
                    description="Fill out the required fields in this form to create a provider."
                >
                    Provider Creation Form
                </Header>
            }
            actions={(
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    {props.isModal ?
                        <FremontButton
                            variant="normal"
                            disabled={props.isSubmissionInProgress}
                            onClick={props.handleProviderModalClick}
                        >
                            Cancel
                        </FremontButton>
                        :
                        <div>
                            <Link href={fetchCallBackLink(props.providerName)}>
                                <FremontButton
                                    variant="normal"
                                    disabled={props.isSubmissionInProgress}
                                >
                                    Cancel
                                </FremontButton>
                            </Link>
                        </div>
                    }
                    <FremontButton
                        variant="primary"
                        id={Constants.SUBMIT_BUTTON_ID}
                        disabled={props.allFieldsDisabled}
                        loading={props.isSubmissionInProgress}
                        onClick={props.handleProviderSubmit}
                    >
                        {
                            props.isSubmissionInProgress ?
                                Constants.SUBMISSION_STRINGS.submitInProgress :
                                Constants.SUBMISSION_STRINGS.readyToSubmit
                        }
                    </FremontButton>
                </SpaceBetween>
            )}
        >
            <ProviderFormSection
                editMode={false}
                provider={props.provider}
                providerErrorTexts={props.providerErrorTexts}
                providerOptions={props.providerOptions}
                allFieldsDisabled={props.allFieldsDisabled}
                handleProviderInputChange={props.handleProviderInputChange}
                bizOpsResources={props.bizOpsResources}
                providersLoading={props.providersLoading}
                parentProviderErrorText={props.parentProviderErrorText}
                providersDisabled={false}
            />
        </Form>
    </Box>
);

export const ProviderFormSection = props => (
    <Container header={<Header variant="h2">General Information</Header>}>
        <ColumnLayout columns={2} borders="vertical">
            <FormField
                label={props.editMode ?
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        {requiredLabel("Provider Name:")}
                        {
                            HelperFunctions.renderCustomTooltip(null,
                                Constants.TOOLTIP_STRINGS.providerNameEditExplanation)
                        }
                    </SpaceBetween> : requiredLabel("Provider Name:")}
                errorText={props.providerErrorTexts.providerName}
            >
                <FremontInput
                    id={Constants.ATTRIBUTES.providerName}
                    disabled={props.allFieldsDisabled || props.editMode}
                    value={props.provider.providerName}
                    onInput={props.handleProviderInputChange}
                />
            </FormField>
            <FormField
                label={requiredLabel("Status:")}
                errorText={props.providerErrorTexts.status}
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.status}
                    options={Constants.GENERAL_STATUS_OPTIONS}
                    disabled={props.allFieldsDisabled}
                    selectedOption={!props.allFieldsDisabled ?
                        HelperFunctions.createSelectedOption(props.provider.status) : null}
                    onChange={props.handleProviderInputChange}
                />
            </FormField>
            <FormField
                label={requiredLabel("Biz Ops:")}
                errorText={props.providerErrorTexts.businessOperationsId}
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.businessOperationsId}
                    disabled={props.allFieldsDisabled}
                    options={
                        HelperFunctions.createSelectedOptions(props.bizOpsResources)
                    }
                    selectedOption={!props.allFieldsDisabled ?
                        HelperFunctions.createSelectedOption(props.provider.businessOperationsId) :
                        null
                    }
                    onChange={props.handleProviderInputChange}
                    filteringType="auto"
                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                />
            </FormField>
            <ProviderSelectComponent
                id={Constants.ATTRIBUTES.parentProviderName}
                label={props.providersDisabled ?
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        {optionalLabel("Parent Provider Name:")}
                        {
                            HelperFunctions.renderCustomTooltip(null,
                                Constants.TOOLTIP_STRINGS.providerDisabledExplanation)
                        }
                    </SpaceBetween> : optionalLabel("Parent Provider Name:")}
                errorText={props.parentProviderErrorText}
                selectedOption={props.provider.parentProviderName}
                providersLoading={props.providersLoading}
                options={props.providerOptions}
                excludeProviders={[props.provider.providerName]}
                disabled={props.allFieldsDisabled || props.providersDisabled}
                onChange={props.handleProviderInputChange}
            />
            <FormField
                label={optionalLabel("Industry:")}
                errorText={props.providerErrorTexts.industry}
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.industry}
                    disabled={props.allFieldsDisabled}
                    options={HelperFunctions.createSelectedOptions(Constants.INDUSTRY_OPTIONS)}
                    selectedOption={HelperFunctions.createSelectedOption(props.provider.industry)}
                    onChange={props.handleProviderInputChange}
                />
            </FormField>
            <FormField
                label={optionalLabel("Description:")}
                errorText={props.providerErrorTexts.description}
            >
                <FremontTextarea
                    id={Constants.ATTRIBUTES.description}
                    disabled={props.allFieldsDisabled}
                    value={props.provider.description}
                    onInput={props.handleProviderInputChange}
                />
            </FormField>
        </ColumnLayout>
    </Container>
);