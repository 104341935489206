import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import ProviderTable from "provider/ProviderTable";

/**
 ProviderChildrenTab is used to display the list of children for a given provider.
 */
class ProviderChildrenTab extends Component {
    state = {
        loading: true,
        providerChildrenItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchProviderChildrenItems();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching children information based on list of providerNames
     */
    fetchProviderChildrenItems = async () => {
        try {
            this.setState({ loading: true });
            const response = await this.FremontBackendClient.getBatchWithNames(
                Constants.BATCH_ENTITIES.PROVIDER,
                this.props.provider[Constants.ATTRIBUTES.childProviderNameList],
                this.props.auth
            );
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                providerChildrenItems: response.providers,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the provider children page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <ProviderTable
                items={this.state.providerChildrenItems}
                loading={this.state.loading}
                emptyTableMessage="No child providers currently exist for this provider."
                providerName={this.props.provider.providerName}
            />
        );
    }
}

export default ProviderChildrenTab;