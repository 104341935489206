import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    FormField,
    Grid,
    Header,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontBox,
    FremontButton,
    FremontDatePicker,
    FremontInput,
    FremontSelect,
    optionalLabel,
    requiredLabel
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const changeSetField = field => Constants.ATTRIBUTES.changeSet + HelperFunctions.capitalize(field);

// This is kind tricky because the work order passed into the information component could be the DynamoDB work order
// or the JSON string converted work order on the circuit. The order we want to show things in is:
//      snapshot -> changeset -> DB record
// However, things are simplified because if there is a snapshot JSON string, it should never have the changeset
// populated in that JSON string. So just checking for the presence of the snapshot still allows us to retain the
// above order because
//  1. If there is a snapshot, there will be no work order change set, so snapshot information is displayed
//  2. If there is a changeset, this condition would pick up that value and display it
//  3. If there is a DB record, this condition would fail and we'd fall back to the field on the DB record
const getWorkOrderField = (workOrder, field, display) => {
    let valueToDisplay = display ? "-" : "";
    if (workOrder && Object.keys(workOrder).length > 0) {
        valueToDisplay = workOrder[Constants.ATTRIBUTES.workOrderChangeSet]
        && workOrder[Constants.ATTRIBUTES.workOrderChangeSet][changeSetField(field)]
            ? workOrder[Constants.ATTRIBUTES.workOrderChangeSet][changeSetField(field)] : workOrder[field];
    }

    return valueToDisplay;
};

export const getDisplayWorkOrderField = (workOrder, field) => getWorkOrderField(workOrder, field, true);
export const getEditWorkOrderField = (workOrder, field) => getWorkOrderField(workOrder, field, false);

// This is kind tricky because the span passed into the information component could be the DynamoDB span
// or the JSON string converted span on the circuit. The order we want to show things in is:
//      snapshot -> changeset -> DB record
// However, things are simplified because if there is a snapshot JSON string, it should never have the changeset
// populated in that JSON string. So just checking for the presence of the snapshot still allows us to retain the
// above order because
//  1. If there is a snapshot, there will be no span change set, so snapshot information is displayed
//  2. If there is a changeset, this condition would pick up that value and display it
//  3. If there is a DB record, this condition would fail and we'd fall back to the field on the DB record
const getSpanField = (span, field, display) => {
    let valueToDisplay = display ? "-" : "";
    if (span && Object.keys(span).length > 0) {
        valueToDisplay = span[Constants.ATTRIBUTES.spanChangeSet]
        && span[Constants.ATTRIBUTES.spanChangeSet][changeSetField(field)]
            ? span[Constants.ATTRIBUTES.spanChangeSet][changeSetField(field)] : span[field];
    }

    return valueToDisplay;
};

export const getDisplaySpanField = (span, field) => getSpanField(span, field, true);
export const getEditSpanField = (span, field) => getSpanField(span, field, false);

// If there is not a value in the span field, we enforce that the field type is a number. This is to ensure
// that newly entered span numbers are indeed numbers. If the span field already has some value, we set the type
// of the field to number if the spanValue contains all numbers and text if any non-numbers are present.
// To do this, we use the isNaN function which is why we disable the no-restricted-globals.
export const getEditSpanFieldType = spanValue =>
    // eslint-disable-next-line no-restricted-globals
    (!spanValue || !isNaN(spanValue) ? Constants.NUMBER : Constants.TEXT);

// In rerate orders, only certain fields can be modified
const RERATE_LIMITED_FIELDS = [
    Constants.ATTRIBUTES.approvalUrl,
    Constants.ATTRIBUTES.contractCentralUrl,
    Constants.ATTRIBUTES.spanNumber,
    Constants.ATTRIBUTES.taxUrl
];

/**
 * This method handles determining whether a given billing field should be disabled or not
 * @param props props passed to this method
 * @param fieldName name of the field
 * @returns whether the field should be disabled or not
 */
export const handleDisablingBillingInfoField = (props, fieldName) => {
    // Here we check the parameters that apply to every single work order and span field
    const fieldDisabled = props.isUpdateStageInProgress
        || props.disabledFieldsList.includes(fieldName)
        || props.loadingWorkOrder
        || (RERATE_LIMITED_FIELDS.includes(fieldName) && props.order.orderType === Constants.ORDER_TYPES.RERATE);

    // For the contractCentralUrl and the spanNumber, we do not perform any more checks
    if ([Constants.ATTRIBUTES.contractCentralUrl, Constants.ATTRIBUTES.spanNumber].includes(fieldName)) {
        return fieldDisabled;
    }

    return fieldDisabled;
};

export const WorkOrderExecutionDisplayMode = props => (
    (
        <ColumnLayout columns={2} borders="vertical">
            <div>
                <FremontBox label="Contract Central URL:"/>
                <div>
                    {getDisplayWorkOrderField(props.workOrder, Constants.ATTRIBUTES.contractCentralUrl) !== "-" ?
                        <Link
                            external
                            href={getDisplayWorkOrderField(props.workOrder, Constants.ATTRIBUTES.contractCentralUrl)}
                        >
                            {getDisplayWorkOrderField(props.workOrder, Constants.ATTRIBUTES.contractCentralUrl)}
                        </Link> : "-"
                    }
                </div>
            </div>
            <div>
                <FremontBox label="Term:"/>
                <div>{`${getDisplayWorkOrderField(props.workOrder, Constants.ATTRIBUTES.term)} months`}</div>
            </div>
            <div>
                <FremontBox label="Currency:"/>
                <div>{getDisplayWorkOrderField(props.workOrder, Constants.ATTRIBUTES.currency)}</div>
            </div>
            <div>
                <FremontBox label="Work Order Span Number:"/>
                <div>{getDisplaySpanField(props.span, Constants.ATTRIBUTES.spanNumber)}</div>
            </div>
            <div>
                <FremontBox label="Service Type:"/>
                <div>{getDisplaySpanField(props.span, Constants.ATTRIBUTES.spanServiceType)}</div>
            </div>
            <div>
                <FremontBox label="NRC:"/>
                <div>{`${props.currencySymbol}${getDisplaySpanField(props.span, Constants.ATTRIBUTES.nrc)} per circuit`}</div>
            </div>
            <div>
                <FremontBox label="MRC:"/>
                <div>{`${props.currencySymbol}${getDisplaySpanField(props.span, Constants.ATTRIBUTES.mrc)} per circuit`}</div>
            </div>
            <div>
                <FremontBox label="Expected Delivery Date:"/>
                <div>{getDisplaySpanField(props.span, Constants.ATTRIBUTES.expectedDeliveryDate)}</div>
            </div>
            <div>
                <FremontBox label="Approval URL:"/>
                <div>{getDisplayWorkOrderField(props.workOrder, Constants.ATTRIBUTES.approvalUrl)}</div>
            </div>
            <div>
                <FremontBox label="Tax Approval URL:"/>
                <div>
                    {getDisplayWorkOrderField(props.workOrder, Constants.ATTRIBUTES.taxUrl)}
                </div>
            </div>
        </ColumnLayout>
    )
);

export const WorkOrderExecutionEditMode = props => (
    <Container
        header={
            <Header variant="h2">
                Work Order Information
            </Header>
        }
    >
        <div>
            <ColumnLayout columns={2}>
                <FormField
                    label={requiredLabel("Contract Central Url:")}
                    errorText={props.errorTexts.contractCentralUrl}
                >
                    {/* Nested ColumnLayout was not working, so we're using a Grid here, which is what ColumnLayout
                    uses under the hood */}
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                        <Box variant="p">{Constants.CONTRACT_CENTRAL_URL}</Box>
                        <FremontInput
                            id={Constants.ATTRIBUTES.contractCentralUrl}
                            disabled={
                                handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.contractCentralUrl)
                            }
                            value={getEditWorkOrderField(props.updatedWorkOrder,
                                Constants.ATTRIBUTES.contractCentralUrl)}
                            onInput={props.handleWorkOrderInputChange}
                        />
                        <FremontButton
                            variant="primary"
                            onClick={props.searchForWorkOrder}
                            loading={props.loadingWorkOrder}
                            disabled={props.isUpdateStageInProgress
                            || props.disableWorkOrderSearch
                            || !props.updatedWorkOrder.contractCentralUrl}
                        >
                            Search
                        </FremontButton>
                    </Grid>
                </FormField>
                <FormField
                    label={requiredLabel("Term:")}
                    errorText={props.errorTexts.term}
                >
                    <ColumnLayout columns={2}>
                        <FremontInput
                            id={Constants.ATTRIBUTES.term}
                            disabled={
                                handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.term)
                            }
                            value={getEditWorkOrderField(props.updatedWorkOrder, Constants.ATTRIBUTES.term)}
                            type={Constants.NUMBER}
                            onInput={props.handleWorkOrderInputChange}
                        />
                        <Box variant="h3">months</Box>
                    </ColumnLayout>
                </FormField>
                <FormField
                    label={requiredLabel("Currency:")}
                    errorText={props.errorTexts.currency}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.currency}
                        placeholder="Select a currency"
                        disabled={
                            handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.currency)
                        }
                        options={HelperFunctions.getCurrencyOptions()}
                        selectedOption={HelperFunctions.createSelectedOption(
                            getEditWorkOrderField(props.updatedWorkOrder, Constants.ATTRIBUTES.currency)
                        )}
                        onChange={props.handleWorkOrderInputChange}
                        filteringType="auto"
                        noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                        expandToViewport
                    />
                </FormField>
            </ColumnLayout>
        </div>
        <br/>
        <Container
            header={
                <Header variant="h2">
                    Span Information
                </Header>
            }
        >
            <ColumnLayout columns={2}>
                <FormField
                    label={requiredLabel("Work Order Span Number:")}
                    errorText={props.errorTexts.spanNumber}
                >
                    <ColumnLayout columns={2}>
                        <FremontInput
                            id={Constants.ATTRIBUTES.spanNumber}
                            disabled={
                                handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.spanNumber)
                            }
                            value={getEditSpanField(props.updatedSpan, Constants.ATTRIBUTES.spanNumber)}
                            type={getEditSpanFieldType(
                                getEditSpanField(props.updatedSpan, Constants.ATTRIBUTES.spanNumber)
                            )}
                            onInput={props.handleSpanInputChange}
                        />
                        <FremontButton
                            id="spanNumberSearchButton"
                            variant="primary"
                            onClick={props.searchForSpan}
                            loading={props.loadingSpan}
                            disabled={props.isUpdateStageInProgress
                            || props.disableSpanSearch
                            || !props.updatedSpan.spanNumber}
                        >
                            Search
                        </FremontButton>
                    </ColumnLayout>
                </FormField>
                <FormField
                    label={requiredLabel("Service Type:")}
                    errorText={props.errorTexts.spanServiceType}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.spanServiceType}
                        placeholder="Select a service type"
                        disabled={
                            handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.spanServiceType)
                        }
                        options={HelperFunctions.createSelectedOptions(Constants.SPAN_SERVICE_TYPES)}
                        selectedOption={HelperFunctions.createSelectedOption(
                            getEditSpanField(props.updatedSpan, Constants.ATTRIBUTES.spanServiceType)
                        )}
                        onChange={props.handleSpanInputChange}
                        expandToViewport
                    />
                </FormField>
                <FormField
                    label={requiredLabel("NRC:")}
                    errorText={props.errorTexts.nrc}
                >
                    <ColumnLayout columns={2}>
                        <FremontInput
                            id={Constants.ATTRIBUTES.nrc}
                            disabled={
                                handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.nrc)
                            }
                            loading={props.loadingSpan}
                            value={getEditSpanField(props.updatedSpan, Constants.ATTRIBUTES.nrc)}
                            type={Constants.NUMBER}
                            onInput={props.handleSpanInputChange}
                        />
                        <Box variant="h3">per circuit</Box>
                    </ColumnLayout>
                </FormField>
                <FormField
                    label={requiredLabel("MRC:")}
                    errorText={props.errorTexts.mrc}
                >
                    <ColumnLayout columns={2}>
                        <FremontInput
                            id={Constants.ATTRIBUTES.mrc}
                            disabled={
                                handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.mrc)
                            }
                            value={getEditSpanField(props.updatedSpan, Constants.ATTRIBUTES.mrc)}
                            type={Constants.NUMBER}
                            onInput={props.handleSpanInputChange}
                        />
                        <Box variant="h3">per circuit</Box>
                    </ColumnLayout>
                </FormField>
                <FormField
                    label={requiredLabel("Expected Delivery Date:")}
                    errorText={props.errorTexts.expectedDeliveryDate}
                >
                    <FremontDatePicker
                        id={Constants.ATTRIBUTES.expectedDeliveryDate}
                        disabled={
                            handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.expectedDeliveryDate)
                        }
                        placeholder={Constants.DATE_PICKER_FORMAT}
                        value={getEditSpanField(props.updatedSpan, Constants.ATTRIBUTES.expectedDeliveryDate) || ""}
                        onInput={props.handleSpanInputChange}
                        onChange={props.handleSpanInputChange}
                    />
                </FormField>
            </ColumnLayout>
        </Container>
        <Container
            header={
                <Header variant="h2">
                    Approval URLs
                </Header>
            }
        >
            <FormField
                label={optionalLabel("Approval URL:")}
            >
                <FremontInput
                    id={Constants.ATTRIBUTES.approvalUrl}
                    disabled={
                        handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.approvalUrl)
                    }
                    onInput={props.handleWorkOrderInputChange}
                    value={getEditWorkOrderField(props.updatedWorkOrder, Constants.ATTRIBUTES.approvalUrl)}
                />
            </FormField>
            <FormField
                label={optionalLabel("Tax Approvals URL:")}
            >
                <FremontInput
                    id={Constants.ATTRIBUTES.taxUrl}
                    disabled={
                        handleDisablingBillingInfoField(props, Constants.ATTRIBUTES.taxUrl)
                    }
                    onInput={props.handleWorkOrderInputChange}
                    value={getEditWorkOrderField(props.updatedWorkOrder, Constants.ATTRIBUTES.taxUrl)}
                />
            </FormField>
        </Container>
    </Container>
);
