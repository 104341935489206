import React from "react";
import {
    Box,
    ColumnLayout,
    Flashbar,
    FormField,
    Header,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontDatePicker,
    FremontInput,
    FremontSelect,
    FremontTextarea,
    optionalLabel,
    requiredLabel
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const TagModal = props => (
    <Modal
        visible={props.isOrderTagModalClicked}
        header={
            <Header variant="h2">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <span>
                        Create Tag Form
                    </span>
                    {HelperFunctions.renderCustomTooltip(
                        null,
                        Constants.TOOLTIP_STRINGS.tagOrderAssociation
                    )}
                </SpaceBetween>
            </Header>
        }
        size="large"
        footer={
            <Box variant="span" float="right">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        variant="normal"
                        disabled={props.allTagFieldsDisabled}
                        onClick={props.hideTagModal}
                    >
                        Cancel
                    </FremontButton>
                    <FremontButton
                        variant="primary"
                        loading={props.allTagFieldsDisabled}
                        onClick={props.handleTagSubmit}
                    >
                        {props.allTagFieldsDisabled ?
                            Constants.SUBMISSION_STRINGS.submitInProgress :
                            Constants.SUBMISSION_STRINGS.readyToSubmit}
                    </FremontButton>
                </SpaceBetween>
            </Box>
        }
        onDismiss={props.hideTagModal}
    >
        <Flashbar
            items={
                props.errorToDisplay ? HelperFunctions.generateErrorMessageForFlashbar(props.errorToDisplay) : []
            }
        />
        <ColumnLayout>
            <FormField
                label={requiredLabel("Tag Name:")}
                errorText={props.tagErrorTexts.tagName}
            >
                <FremontInput
                    id="tagName"
                    disabled={props.allTagFieldsDisabled}
                    value={props.activeTag.tagName}
                    onInput={props.handleTagModalInputChange}
                />
            </FormField>
            <FormField
                label={requiredLabel("Status:")}
                errorText={props.tagErrorTexts.status}
            >
                <FremontSelect
                    id="status"
                    disabled={props.allTagFieldsDisabled}
                    selectedOption={
                        Constants.GENERAL_STATUS_OPTIONS.filter(
                            status => status.value === props.activeTag.status
                        ).shift()
                    }
                    options={Constants.GENERAL_STATUS_OPTIONS}
                    onChange={props.handleTagModalInputChange}
                />
            </FormField>
            <FormField
                label={optionalLabel("Description:")}
                errorText={props.tagErrorTexts.description}
            >
                <FremontTextarea
                    id="description"
                    disabled={props.allTagFieldsDisabled}
                    value={props.activeTag.description}
                    onInput={props.handleTagModalInputChange}
                />
            </FormField>
            <FormField
                label={optionalLabel("Required Completion Date:")}
                errorText={props.tagErrorTexts.requiredCompletionDate}
            >
                <FremontDatePicker
                    id="requiredCompletionDate"
                    value={props.activeTag.requiredCompletionDate}
                    disabled={props.allTagFieldsDisabled}
                    placeholder={Constants.DATE_PICKER_FORMAT}
                    onInput={props.handleTagModalInputChange}
                    onChange={props.handleTagModalInputChange}
                />
            </FormField>
        </ColumnLayout>
    </Modal>
);

export default TagModal;