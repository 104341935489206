import React from "react";
import {
    Box,
    Link
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class ProviderServiceValidation {
    static ESCALATION_LEVEL = "escalationLevel";
    static ESCALATION_PRIORITY = "escalationPriority";

    static BUTTON_NAMES = {
        subtractContactButton: "subtractContactButton",
        subtractEscalationPathButton: "subtractEscalationPathButton",
        addContactButton: "addContactButton",
        addEscalationPathButton: "addEscalationPathButton"
    };

    static ESCALATION_PATH_COLUMN_DEFINITIONS = [
        {
            id: "escalationLevel",
            header: "Escalation Level",
            description: "Level of Escalation (One being lowest, Six being highest)",
            width: 150,
            cell: item =>
                <Box textAlign="center">{item.escalationLevel}</Box>
        },
        {
            id: "contactIdList",
            header: "Contact(s)",
            description: "Contact(s) in this level of escalation",
            cell: item => (
                item.escalationLevelObjects.map((escalationLevelObject, i) => (
                    <span key={escalationLevelObject.selectedOption.value}>
                        <Link href={`${Constants.ROUTES.contact}/${escalationLevelObject.selectedOption.value}`}>
                            {escalationLevelObject.selectedOption.label}
                        </Link>
                        {(item.escalationLevelObjects.length === (i + Constants.MAP_INDEXES.valueIndex)) ? "" : ", "}
                    </span>)))
        }
    ];

    static LEVEL_OPTIONS = [
        {
            label: "None",
            value: ""
        },
        {
            label: "1",
            value: "1"
        },
        {
            label: "2",
            value: "2"
        },
        {
            label: "3",
            value: "3"
        },
        {
            label: "4",
            value: "4"
        },
        {
            label: "5",
            value: "5"
        },
        {
            label: "6",
            value: "6"
        }
    ];

    static PRIORITY_OPTIONS = [
        {
            label: "None",
            value: ""
        },
        {
            label: "to",
            value: "to"
        },
        {
            label: "cc",
            value: "cc"
        }
    ];

    static DEFAULT_PROVIDER_SERVICE_ERROR_TEXTS = {
        providerName: Constants.ERROR_STRINGS.blankInput,
        serviceType: Constants.ERROR_STRINGS.blankInput,
        status: "",
        phone: "",
        fax: "",
        email: "",
        asnNumberList: "",
        odinCredentials: "",
        website: ""
    };

    static EMPTY_PROVIDER_SERVICE_ERROR_TEXTS = {
        providerName: "",
        status: "",
        serviceType: "",
        phone: "",
        fax: "",
        email: "",
        asnNumberList: "",
        odinCredentials: "",
        website: ""
    };

    static BLANK_CONTACT_OBJECT = {
        id: "contact1",
        selectedOption: {},
        errorText: ""
    };

    static BLANK_ESCALATION_PATH_OBJECT = {
        id: "escalationPath1",
        selectedOption: {},
        level: "",
        contactNameErrorText: "",
        levelErrorText: ""
    };

    static determineErrorMessages = (input) => {
        const errorMessagesOutput = {
            levelErrorText: Constants.ERROR_STRINGS.blankLevelErrorText,
            contactNameErrorText: Constants.ERROR_STRINGS.blankContactNameErrorText
        };
        const contactNameHasValue = !input.contactValue ? false : Object.keys(input.contactValue).length > 0;
        const levelHasValue = input.levelValue !== "";

        // When the field has a value, no error text is present for that field
        if (contactNameHasValue) {
            errorMessagesOutput.contactNameErrorText = "";
        }
        if (levelHasValue) {
            errorMessagesOutput.levelErrorText = "";
        }
        // If only one escaltionPathField is on the page, we don't display error text when each field either
        // does or does not have a value. This is because we want to let the user deselect a contact and level
        // and have no error message since escalationPath is not a required field
        if (input.escalationPathObjects.length === 1 && contactNameHasValue === levelHasValue) {
            errorMessagesOutput.levelErrorText = "";
            errorMessagesOutput.contactNameErrorText = "";
        }
        // If there are multiple escalationPathFields on the page, no error text is present only when both
        // the contact name and level have a value
        if (input.escalationPathObjects.length !== 1 && contactNameHasValue && levelHasValue) {
            errorMessagesOutput.levelErrorText = "";
            errorMessagesOutput.contactNameErrorText = "";
        }
        return errorMessagesOutput;
    };

    static handleAdditionalInput = (input) => {
        const output = input;

        if (output.objectChanged === ProviderServiceValidation.BUTTON_NAMES.addEscalationPathButton) {
            const newEscalationPathId = `escalationPath${output.escalationPathObjects.length + 1}`;
            output.escalationPathObjects = output.escalationPathObjects.concat(
                {
                    id: newEscalationPathId,
                    selectedOption: {},
                    level: "",
                    contactNameErrorText: Constants.ERROR_STRINGS.blankContactNameErrorText,
                    levelErrorText: Constants.ERROR_STRINGS.blankLevelErrorText
                }
            );
        }
        if (output.objectChanged === ProviderServiceValidation.BUTTON_NAMES.addContactButton) {
            const newContactId = `contact${output.contactObjects.length + 1}`;
            output.contactObjects = output.contactObjects.concat(
                {
                    id: newContactId,
                    selectedOption: {},
                    errorText: Constants.ERROR_STRINGS.blankContactNameErrorText
                }
            );
        }

        return output;
    };

    static validateInput = (input) => {
        const output = input;
        // fetch changed entity
        const attributeId = output.evt.target.id;

        // Handles the changes to the contactObjects array
        if (attributeId.includes("contact")) {
            output.contactObjects = output.contactObjects.map((contactObject) => {
                if (contactObject.id === attributeId) {
                    // Here we know that an option has been selected so we set the value to the selected option
                    // and the error text to a blank string
                    if (input.evt.detail.selectedOption) {
                        return Object.assign({}, contactObject, {
                            selectedOption: output.evt.detail.selectedOption,
                            errorText: ""
                        });
                    }
                    // Here we know that an option has been de-selected so we set the value to an empty map
                    // and set the error text to its approriate value
                    return Object.assign({}, contactObject, {
                        selectedOption: {},
                        // When an option is de-selected, we assigned a blank contact error text unless only one
                        // contact field exists. If only one contact field exists, we do not want to display an
                        // error message for an empty contact field because it is not a required input
                        // for a provider service
                        errorText: Object.keys(output.contactObjects).length > 1 ?
                            Constants.ERROR_STRINGS.blankContactNameErrorText : ""
                    });
                }
                return contactObject;
            });
        } else if (attributeId.includes("escalationPath")) {
            const errorMessagesInput = {
                escalationPathObjects: output.escalationPathObjects
            };
            // Handles the changes to the escalationPathObjects array
            output.escalationPathObjects = input.escalationPathObjects.map((escalationPathObject) => {
                // Updating the contact value based on a selected contact
                if (escalationPathObject.id === attributeId) {
                    const selectedOption = input.evt.detail.selectedOption ? input.evt.detail.selectedOption : {};
                    errorMessagesInput.levelValue = escalationPathObject.level;
                    errorMessagesInput.contactValue = selectedOption.label;
                    errorMessagesInput.priorityValue = escalationPathObject.priority;

                    const errorMessagesOutput = ProviderServiceValidation.determineErrorMessages(errorMessagesInput);

                    return Object.assign({}, escalationPathObject, {
                        selectedOption,
                        levelErrorText: errorMessagesOutput.levelErrorText,
                        contactNameErrorText: errorMessagesOutput.contactNameErrorText
                    });
                }
                // Updating the escalation level
                if (attributeId.includes(ProviderServiceValidation.ESCALATION_LEVEL)
                    && attributeId.includes(escalationPathObject.id)) {
                    const inputtedLevel = input.evt.detail.selectedOption.value;
                    errorMessagesInput.levelValue = inputtedLevel;
                    errorMessagesInput.contactValue = escalationPathObject.selectedOption.label;
                    errorMessagesInput.priorityValue = escalationPathObject.priority;

                    const errorMessagesOutput = ProviderServiceValidation.determineErrorMessages(errorMessagesInput);

                    return Object.assign({}, escalationPathObject, {
                        level: inputtedLevel,
                        levelErrorText: errorMessagesOutput.levelErrorText,
                        contactNameErrorText: errorMessagesOutput.contactNameErrorText
                    });
                }
                // Updating the escalation level
                if (attributeId.includes(ProviderServiceValidation.ESCALATION_PRIORITY)
                    && attributeId.includes(escalationPathObject.id)) {
                    const { selectedOption } = input.evt.detail;
                    errorMessagesInput.levelValue = escalationPathObject.level;
                    errorMessagesInput.contactValue = escalationPathObject.selectedOption.label;
                    errorMessagesInput.priorityValue = selectedOption.label;

                    const errorMessagesOutput = ProviderServiceValidation.determineErrorMessages(errorMessagesInput);

                    return Object.assign({}, escalationPathObject, {
                        priority: selectedOption.value,
                        levelErrorText: errorMessagesOutput.levelErrorText,
                        contactNameErrorText: errorMessagesOutput.contactNameErrorText
                    });
                }
                return escalationPathObject;
            });
        } else {
            let inputValue;
            if (attributeId === Constants.ATTRIBUTES.serviceType || attributeId === Constants.ATTRIBUTES.status) {
                inputValue = output.evt.detail.selectedOption.value;
            } else if (attributeId === Constants.ATTRIBUTES.providerName) {
                inputValue = output.evt.detail.selectedOption?.value;
                output.providerServiceErrorTexts[attributeId] =
                    HelperFunctions.validateInfo(inputValue, Constants.VALIDATE_INFO_OPTIONS.required);
                output.hasModifiedProviderName = true; // Only this block of code should be able to decide to do this

                // Whenever the provider name is changed, we need to clear out the service type, associates contact(s),
                // and any escalationPath objects because all the fields values are based upon objects that directly
                // relate to a specific provider
                output.providerService.serviceType = null;
                output.providerServiceErrorTexts.serviceType = Constants.ERROR_STRINGS.blankInput;
                output.contactObjects = [ProviderServiceValidation.BLANK_CONTACT_OBJECT];
                output.escalationPathObjects = [ProviderServiceValidation.BLANK_ESCALATION_PATH_OBJECT];
            } else {
                inputValue = output.evt.detail.value;
            }
            // do not allow empty strings because this will cause an error on the backend
            output.providerService[attributeId] = inputValue || null;
            // required fields cannot be blank
            if (attributeId === Constants.ATTRIBUTES.serviceType || attributeId === Constants.ATTRIBUTES.status) {
                if (!inputValue) {
                    output.providerServiceErrorTexts[attributeId] = Constants.ERROR_STRINGS.blankInput;
                } else {
                    output.providerServiceErrorTexts[attributeId] = "";
                }
            } else if (inputValue) {
                // optional fields can be empty
                if (attributeId === Constants.ATTRIBUTES.phone) {
                    output.providerServiceErrorTexts[attributeId] = HelperFunctions.validatePhoneOrFaxNumber(
                        inputValue, Constants.PHONE_OR_FAX.phone
                    );
                } else if (attributeId === Constants.ATTRIBUTES.fax) {
                    output.providerServiceErrorTexts[attributeId] = HelperFunctions.validatePhoneOrFaxNumber(
                        inputValue, Constants.PHONE_OR_FAX.fax
                    );
                } else if (attributeId === Constants.ATTRIBUTES.email) {
                    output.providerServiceErrorTexts[attributeId] = HelperFunctions.validateEmail(inputValue);
                } else if (attributeId === Constants.ATTRIBUTES.website) {
                    output.providerServiceErrorTexts[attributeId] = HelperFunctions.validateWebsite(inputValue);
                }
            } else if (attributeId !== Constants.ATTRIBUTES.providerName) { // Provider name cannot be blank
                output.providerServiceErrorTexts[attributeId] = "";
            }
        }
        return output;
    }
}