import React from "react";
import {
    Modal
} from "@amzn/awsui-components-react/polaris";
import AsnCreatePage from "asn/AsnCreatePage";

const AsnModal = props => (
    <Modal
        visible={props.isAddAsnClicked}
        header="Add ASN"
        onDismiss={props.handleAsnModalClick}
        size="large"
    >
        {props.isAddAsnClicked ? (
            <AsnCreatePage
                match={props.match}
                auth={props.auth}
                isModal
                serviceType={props.serviceType}
                isAddAsnClicked={props.isAddAsnClicked}
                handleAsnModalClick={props.handleAsnModalClick}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                providerOptions={props.providerOptions}
                providersLoading={props.providersLoading}
            />)
            :
            <span/>
        }
    </Modal>
);

export default AsnModal;
