import React from "react";
import {
    Modal
} from "@amzn/awsui-components-react/polaris";
import ProviderCreatePage from "provider/ProviderCreatePage";

const ProviderModal = props => (
    <Modal
        visible={props.isAddProviderClicked}
        header="Add Provider"
        onDismiss={props.handleProviderModalClick}
        size="large"
    >
        {props.isAddProviderClicked &&
        <ProviderCreatePage
            match={props.match}
            auth={props.auth}
            isModal
            handleProviderModalClick={props.handleProviderModalClick}
            handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
        />
        }
    </Modal>
);

export default ProviderModal;