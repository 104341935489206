import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import ProviderServiceForm from "providerService/ProviderServiceForm";
import ProviderServiceValidation from "providerService/ProviderServiceValidation";
import { Flashbar } from "@amzn/awsui-components-react/polaris";
import PolarisUtils from "utils/PolarisUtils";

/**
 * CreateProviderServicePage renders the page holding the providerService Form
 */
class ProviderServiceCreatePage extends Component {
    /**
     *  Initially set the providerServiceInfo, escalationPathObjects, and contactObjects to mimic what they would be
     *  if they were inputted as blank (since they are blank on page load). They will later be updated by user inputs.
     */
    state = {
        providerService: {},
        escalationPathObjects: [
            ProviderServiceValidation.BLANK_ESCALATION_PATH_OBJECT
        ],
        contactObjects: [
            ProviderServiceValidation.BLANK_CONTACT_OBJECT
        ],
        serviceOptions: [],
        servicesLoading: true,
        contactOptions: [],
        contactsLoading: true,
        allFieldsDisabled: false,
        hasSubmittedOnce: false,
        hasModifiedProviderName: false,
        isSubmissionInProgress: false,
        flashbar: {
            type: "",
            text: ""
        },
        providerServiceErrorTexts: ProviderServiceValidation.DEFAULT_PROVIDER_SERVICE_ERROR_TEXTS
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else {
            this.setState({
                providerService: {
                    providerName: this.props.match ? this.props.match.params.providerName : "",
                    serviceType: this.props.serviceType ? this.props.serviceType : "",
                    contactIdList: [],
                    escalationPath: {},
                    status: Constants.STATUS.active
                }
            });
            // If a providerName was passed in through the URL, we immediately fetch the services and contacts
            if (this.props.match) {
                await this.fetchAvailableServicesAndContacts(this.props.match.params.providerName);
            }
            this.state.providerServiceErrorTexts.providerName = this.props.match
                ? "" : Constants.ERROR_STRINGS.blankInput;
            this.state.providerServiceErrorTexts.serviceType = this.props.serviceType
                ? "" : Constants.ERROR_STRINGS.blankInput;
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This function obtains all providerServices and contacts associated with a given provider. It is called every
     * time a new provider is selected
     */
    fetchAvailableServicesAndContacts = async (providerName) => {
        this.setState({
            contactsLoading: true, servicesLoading: true, contactOptions: [], serviceOptions: []
        });
        try {
            // Obtain the provider object
            const providerObjectResponse = await this.FremontBackendClient.getProviderInfo(providerName,
                this.props.auth);

            // Obtaining each provider service object associated with the provider
            const batchGetProviderServicesResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE, providerObjectResponse.provider.providerServiceIdList,
                this.props.auth
            );

            this.setState({
                // Here we filter out any services that already exist so that the user cannot select them again
                serviceOptions: HelperFunctions.createSelectedOptions(Object.values(Constants.SERVICE_TYPES)
                    .filter(serviceType => Constants.SERVICE_TYPES.PAID_PEERING !== serviceType))
                    .filter(service =>
                        !batchGetProviderServicesResponse.providerServices
                            .map(providerService => providerService.serviceType)
                            .includes(service[PolarisUtils.OPTION_VALUE_KEY])),
                servicesLoading: false
            });

            // Obtaining each contact object associated with the provider
            const batchGetContactsResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CONTACT, providerObjectResponse.provider.contactIdList, this.props.auth
            );

            this.setState({
                // Creates the list of options that will be displayed to a user when they go to choose a contact
                contactOptions: batchGetContactsResponse.contacts.map(contact => ({
                    label: `${contact.firstName} ${contact.lastName}`,
                    value: contact.contactId
                })),
                contactsLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                servicesLoading: false,
                contactLoading: false
            });
        }
    };

    /**
     This function handles change of inputs to the providerService information container fields.
     */
    handleProviderServiceInputChange = async (evt) => {
        const oldProviderName = this.state.providerService.providerName;
        const input = {};
        input.evt = evt;
        input.contactObjects = HelperFunctions.deepClone(this.state.contactObjects);
        input.escalationPathObjects = HelperFunctions.deepClone(this.state.escalationPathObjects);
        input.providerService = HelperFunctions.deepClone(this.state.providerService);
        input.providerServiceErrorTexts = HelperFunctions.deepClone(this.state.providerServiceErrorTexts);
        input.providerOptions = HelperFunctions.deepClone(this.props.providerOptions);
        input.hasModifiedProviderName = this.state.hasModifiedProviderName;

        const output = ProviderServiceValidation.validateInput(input);

        this.setState({
            contactObjects: output.contactObjects,
            escalationPathObjects: output.escalationPathObjects,
            providerService: output.providerService,
            providerServiceErrorTexts: output.providerServiceErrorTexts,
            hasModifiedProviderName: output.hasModifiedProviderName
        });

        if (output.providerService.providerName
            && output.providerService.providerName !== oldProviderName
            && !output.providerServiceErrorTexts.providerName) {
            await this.fetchAvailableServicesAndContacts(output.providerService.providerName);
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     * Handles the addition or subtraction of new escalationPath or contact objects
     */
    handleAdditionalInput = (evt) => {
        const input = {};
        input.objectChanged = evt.target.id;
        input.escalationPathObjects = this.state.escalationPathObjects;
        input.contactObjects = this.state.contactObjects;

        const output = ProviderServiceValidation.handleAdditionalInput(input);

        this.setState({
            contactObjects: output.contactObjects,
            escalationPathObjects: output.escalationPathObjects
        });
    };

    /**
     * Handles the subtraction of new escalationPath
     */
    handleEscalationPathSubtraction = (evt) => {
        this.setState({
            escalationPathObjects: HelperFunctions.subtractSpecificObjectHelper(
                HelperFunctions.deepClone(this.state.escalationPathObjects),
                evt.target.id,
                Constants.DYNAMIC_INPUT_TYPES.escalationPath
            )
        });
    };

    /**
     * Handles the subtraction of new associated contacts
     */
    handleAssociatedContactsSubtraction = (evt) => {
        this.setState({
            contactObjects: HelperFunctions.subtractSpecificObjectHelper(
                HelperFunctions.deepClone(this.state.contactObjects),
                evt.target.id,
                Constants.DYNAMIC_INPUT_TYPES.contact
            )
        });
    };

    /**
     * This function determines whether or not the Add Additional EscalationPath button should be enabled or not.
     * The add escalation path button is disabled if any field has no value, or if no more contacts can be selected.
     * We know that no more contacts can be selected if the number of escalationPathObjects is equal to the number of
     * contact options.
     */
    disableAddEscalationPathButton = () => this.mapFunctionToEscalationPathObjectsArray(escalationPath =>
        // Ensuring that no fields have empty values
        escalationPath.level === "" || Object.entries(escalationPath.selectedOption).length === 0) ||
        // If the number of escalationPathObjects is equal to the number of contact options,
        // no more escalationPathObjects can be created
        (this.state.escalationPathObjects.length === this.state.contactOptions.length);

    /**
     * This function determines whether or not the Add Additional Contact button should be enabled or not.
     * The add contact button is disabled if any contact name field is blank, or if no more contacts can be selected.
     * We know that no more contacts can be selected if the number of contactPathObjects is equal to the number of
     * contact options.
     */
    disableAddContactButton = () => this.mapFunctionToContactObjectsArray(contact =>
        // Ensuring that no fields have empty values
        Object.entries(contact.selectedOption).length === 0) ||
        // If the number of contactObjects is equal to the number of contact options,
        // no more contactObjects can be created
        (this.state.contactObjects.length === this.state.contactOptions.length);

    /**
     * This function takes a mapped function and applied it to every element of the contactObjects array
     */
    mapFunctionToContactObjectsArray = mappedFunction => this.state.contactObjects.some(mappedFunction);

    /**
     * This function takes a mapped function and applied it to every element of the escalationPathObjects array
     */
    mapFunctionToEscalationPathObjectsArray = mappedFunction => this.state.escalationPathObjects.some(mappedFunction);

    /**
     * Send providerService info to backend when the submit button is clicked
     */
    handleProviderServiceSubmit = async (evt) => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true, allFieldsDisabled: true });
        this.state.hasSubmittedOnce = true;

        // Checks to see if any fields, including all the fields from the contact selection and
        // escalation path selection section, are blank
        if (Object.values(this.state.providerServiceErrorTexts).some(errorText => errorText) ||
            this.mapFunctionToContactObjectsArray(contactObject => contactObject.errorText) ||
            this.mapFunctionToEscalationPathObjectsArray(escalationPathObject =>
                escalationPathObject.contactNameErrorText || escalationPathObject.levelErrorText)) {
            const flashbarError = Object.assign({}, this.state.flashbarError, {
                name: Constants.ERROR_TYPES.input
            });
            this.setState({
                isSubmissionInProgress: false,
                allFieldsDisabled: false,
                flashbarError
            });
            HelperFunctions.displayFlashbarError(this, flashbarError);
            return;
        }
        const providerService = HelperFunctions.deepClone(this.state.providerService);

        // Clearing out the escalationPath field so that a newly chosen escalationPath can be added
        Object.keys(providerService.escalationPath).map(contactId =>
            delete providerService.escalationPath[contactId]);

        // Filters out any blank entries by removing any escalationPathObjects that have either blank contact name
        // or a blank level field. Then creates appropriate map object to pass to the back end
        this.state.escalationPathObjects.filter(escalationPathObject =>
            Object.entries(escalationPathObject.selectedOption).length > 0 && escalationPathObject.level !== "")
            .map(escalationPathObject => Object.assign(providerService.escalationPath,
                { [escalationPathObject.selectedOption.value]: escalationPathObject.level }));

        // Filters out any blank contacts and returns the all the contactIds to the contactIdList
        providerService.contactIdList = this.state.contactObjects
            .filter(contactObject => Object.entries(contactObject.selectedOption).length > 0)
            .map(contactObject => contactObject.selectedOption.value);

        try {
            const response = await this.FremontBackendClient.createProviderService(providerService, this.props.auth);
            if (!this.props.isModal) {
                this.props.history.push(`${Constants.ROUTES.providerService}/${response.providerServiceId}`);
            } else {
                this.props.handleProviderServiceModalSubmit(evt);
            }
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isSubmissionInProgress: false,
                allFieldsDisabled: false
            });
        }
    };

    render() {
        return (
            <div>
                {this.props.isModal ?
                    <Flashbar
                        items={this.state.flashbar.text ?
                            HelperFunctions.generateErrorMessageForFlashbar(this.state.flashbar.text) : []}
                    />
                    : <FremontHeader
                        history={this.props.history}
                        flashbarType={this.state.flashbar.type}
                        flashbarText={this.state.flashbar.text}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                }
                <ProviderServiceForm
                    hasSubmittedOnce={this.state.hasSubmittedOnce}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    allFieldsDisabled={this.state.allFieldsDisabled}
                    providerService={this.state.providerService}
                    handleProviderServiceSubmit={this.handleProviderServiceSubmit}
                    handleProviderServiceInputChange={this.handleProviderServiceInputChange}
                    addEscalationPathButtonDisabled={this.disableAddEscalationPathButton()}
                    handleEscalationPathSubtraction={this.handleEscalationPathSubtraction}
                    handleAssociatedContactsSubtraction={this.handleAssociatedContactsSubtraction}
                    addContactButtonDisabled={this.disableAddContactButton()}
                    handleAdditionalInput={this.handleAdditionalInput}
                    servicesLoading={this.state.servicesLoading}
                    serviceOptions={this.state.serviceOptions}
                    providersLoading={this.props.providersLoading}
                    providerOptions={this.props.providerOptions}
                    providerServiceErrorTexts={this.state.hasSubmittedOnce ? this.state.providerServiceErrorTexts :
                        ProviderServiceValidation.EMPTY_PROVIDER_SERVICE_ERROR_TEXTS}

                    // We submit this as a separate field because we do provider validation on the autocomplete
                    // box immediately upon entry, not just on submit
                    providerErrorText={this.state.hasModifiedProviderName || this.state.hasSubmittedOnce
                        ? this.state.providerServiceErrorTexts.providerName : ""}

                    escalationPathObjects={this.state.escalationPathObjects}
                    contactsLoading={this.state.contactsLoading}
                    contactObjects={this.state.contactObjects}
                    contactOptions={this.state.contactOptions}
                    isModal={this.props.isModal}
                    handleProviderServiceModalDismiss={this.props.handleProviderServiceModalDismiss}
                />
            </div>
        );
    }
}

export default ProviderServiceCreatePage;
