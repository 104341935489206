import React, { Component } from "react";
import {
    Button,
    Table
} from "@amzn/awsui-components-react/polaris";
import HelperFunctions from "../common/HelperFunctions";
import VisualizationHelperFunctions from "./VisualizationHelperFunctions";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";
import LinkVisualWrapper from "./LinkVisualWrapper";
import TabSpinner from "../components/TabSpinner";

export default class LinkVisualTable extends Component {
    // displays table with instanceID, aEndPort, bEndPort, and button to view visual for all connections returned
    state = {
        selectedRow: null,
        isVisualButtonClicked: false,
        linkHierarchy: null,
        isSearchInProgress: false
    };

    componentDidMount = async () => {
        if (this.props.linkId) await this.executeSearch();
    }

    linkServiceBackendClient = new LinkServiceBackendClient()

    executeSearch = async () => {
        this.setState({
            isSearchInProgress: true
        });
        try {
            const linkHierarchyResponse = await this.linkServiceBackendClient.getLinkHierarchy(
                this.props.linkId
            );
            const root = VisualizationHelperFunctions.getHierarchyData(linkHierarchyResponse);
            root.sortChildren();
            this.setState({
                linkHierarchy: root,
                isSearchInProgress: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
    }

    handleBackClick = () => {
        this.setState({
            selectedRow: null, // Clear the selected row data
            isVisualButtonClicked: false // Set "Visual" button off again
        });
    };

    handleVisualClick = () => {
        const { selectedRow } = this.state;
        if (selectedRow) {
            // If a row is selected, show the LinkVisual component for the selected row's data
            this.setState({
                isVisualButtonClicked: true // indicate that the "Visual" button has been clicked
            });
        }
    };

    renderTable = nodes => (
        <Table
            onSelectionChange={({ detail }) => {
                const selectedRow = detail.selectedItems[0]; // Get the selected row data
                this.setState({ selectedRow });
            }}
            selectedItems={this.state.selectedRow ? [this.state.selectedRow] : []}
            items={nodes}
            columnDefinitions={[
                {
                    id: "linkType",
                    header: "Link Type",
                    cell: e => e.linkType
                },
                {
                    id: "instanceId",
                    header: "Instance ID",
                    cell: e => e.id
                },
                {
                    id: "aEndPort",
                    header: "A End Port",
                    cell: e => e.aEndPort
                },
                {
                    id: "bEndPort",
                    header: "B End Port",
                    cell: e => e.bEndPort
                }
            ]}
            columnDisplay={[
                { id: "linkType", visible: true },
                { id: "instanceId", visible: true },
                { id: "aEndPort", visible: true },
                { id: "bEndPort", visible: true }
            ]}
            selectionType="single"
            trackBy="id"
        />
    );

    render() {
        if (!this.props.linkId) {
            return null; // Render nothing if linkId is null or Search is in progress
        }
        if (this.state.isSearchInProgress) {
            return TabSpinner();
        }

        if (!this.state.linkHierarchy) {
            // return placeholder message if link hierarchy is null
            return (
                <div style={{
                    fontFamily: "sans-serif"
                }}
                >
                    Link Hierarchy not found
                </div>
            );
        }

        const { selectedRow, isVisualButtonClicked } = this.state;
        const linkHierarchyArray = VisualizationHelperFunctions.convertHierarchyToArray(this.state.linkHierarchy);

        if (isVisualButtonClicked) {
            // Render LinkVisualWrapper component when the "Visual" button has been clicked
            return (
                <div>
                    <LinkVisualWrapper
                        linkData={selectedRow}
                        onBackClick={this.handleBackClick}
                    />
                </div>
            );
        }

        return (
            <div>
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                    <Button onClick={this.handleVisualClick} disabled={!this.state.selectedRow}>
                        Visual
                    </Button>
                </div>
                {this.renderTable(linkHierarchyArray)}
            </div>
        );
    }
}

