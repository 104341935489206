import React from "react";
import "airwolf-header.css";
// We use the react-router-dom link here because it does not force a
// full page reload when navigating back to the homepage
import { Link as InternalLink } from "react-router-dom";
import Constants from "utils/Constants";

/*
 * The HaloHeader displays the main header of Halo Service and conditionally renders a flashbar depending
 * if the flashbar type input prop is set.
 */
const HaloHeader = () => (
    <span>
        <div className="airwolf-header">
            <span className="airwolf-header-stripe-shine one"/>
            <span className="airwolf-header-stripe-shine two"/>
            <span className="airwolf-header-stripe-fade one"/>
            <span className="airwolf-header-stripe-fade two"/>
            <span className="airwolf-header-stripe-fade three"/>
            <span className="airwolf-header-stripe-fade four"/>
            <span className="airwolf-header-stripe-fade five"/>
            <InternalLink to={Constants.LIGHTHOUSE_PREPENDED_PATHS.halo}>
                <span className="airwolf-header-title">
                    <legend className="header-link">
                        <span className="airworlf-header-title">Halo</span>
                    </legend>
                </span>
            </InternalLink>
            <span className="airwolf-header-subtitle">
                    THE AUTHORITATIVE GEOSPATIAL DATA STORE
            </span>
        </div>
    </span>
);

export default HaloHeader;