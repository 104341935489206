import React from "react";
import {
    ColumnLayout,
    Container,
    Form,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import {
    ComponentConstants,
    FremontButton,
    FremontSelect
} from "utils/CommonComponents";

const AddProviderServiceToContactForm = props => (
    <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
        <Form
            actions={
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        variant="normal"
                        onClick={props.handleContactModalClick}
                        disabled={props.isSubmitInProgress}
                    >
                        Cancel
                    </FremontButton>
                    <FremontButton
                        variant="primary"
                        id={Constants.SUBMIT_BUTTON_ID}
                        onClick={props.handleContactSubmit}
                        loading={props.isSubmitInProgress}
                    >
                        Submit
                    </FremontButton>
                </SpaceBetween>
            }
        >
            <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                <Container header={<Header variant="h2">General Information</Header>}>
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label="Provider:"
                        >

                            <FremontSelect
                                disabled
                                selectedOption={HelperFunctions.createSelectedOption([props.provider])}
                            />
                        </FormField>
                        <FormField
                            label="Service Type:"
                        >

                            <FremontSelect
                                disabled
                                selectedOption={HelperFunctions.createSelectedOption([props.serviceType])}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
                <Container
                    header={
                        <Header
                            variant="h2"
                            description={`${"By selecting a contact and hitting submit,"
                            + " you will associate the selected contact with the "}${props.serviceType} provider service.`}
                        >
                            Associate Contact to Provider
                        </Header>}
                >
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label="Contact Name:"
                        >
                            <FremontSelect
                                options={props.contactItems}
                                selectedOption={props.selectedContactOption}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                onChange={props.handleContactSelect}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </Form>
    </div>
);

export default AddProviderServiceToContactForm;