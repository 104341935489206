import React, { Component } from "react";
import {
    Box,
    Tabs
} from "@amzn/awsui-components-react/polaris";
import AuditTab from "audit/AuditTab";
import AddressValidation from "address/AddressValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import HelperFunctions from "common/HelperFunctions";
import NodeTab from "node/NodeTab";
import NoteTab from "note/NoteTab";
import OrderTab from "order/OrderTab";
import SiteInformation from "site/SiteInformation";
import SiteValidation from "site/SiteValidation";
import { withRouter } from "react-router-dom";

/**
 * SiteDetailsPage acts as the site info landing page and displays all of the site information and its related info.
 */
class SiteDetailsPage extends Component {
    static SITE_INFO_TAB_ID = "details";
    static SITE_ORDER_TAB_ID = "siteOrders";
    static SITE_NODE_TAB_ID = "siteNodes";
    static SITE_NOTE_TAB_ID = "siteNotes";
    static SITE_AUDIT_TAB_ID = "siteAudits";

    state = {
        activeTabId: "details",
        flashbar: {
            type: "",
            text: ""
        },
        isPageLoading: true,
        isSpinnerShown: true,
        isUserHighlyConfidential: !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_HIGHLY_CONFIDENTIAL]
        || !!this.props.user.permissions[Constants.POSIX_GROUPS.NEST],
        site: {
            siteName: "",
            address: {}
        },
        updatedSite: {
            siteName: "",
            address: {}
        },
        hasUpdateSiteSubmittedOnce: false,
        isUpdateSiteInfoEditClicked: false,
        isUpdateSiteInfoInProgress: false,
        siteErrorTexts: {}
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false });
        } else {
            await this.fetchSiteInfo();
            document.title = `${this.state.site.siteName}`;
        }
    };

    componentWillUnmount = () => {
        document.title = "Lighthouse";
    }

    /**
     **************************************************************************************************
     * DATA LOADING
     **************************************************************************************************
     */

    /**
     * This method is used for fetching all of the site information
     */
    fetchSiteInfo = async () => {
        try {
            this.setState({ isPageLoading: true });
            const response = await this.FremontBackendClient.getSiteInfo(this.props.match.params.siteId,
                this.props.auth);
            if (AddressValidation.isAddressEmpty(response.site.address)) {
                response.site.address = {};
            }

            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                site: response.site,
                isPageLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isPageLoading: false });
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     * Changes the site info container to edit mode, edit the request object
     */
    handleUpdateSiteEdit = async () => {
        this.handleFlashbarClose();
        this.changeEditState();
    };

    /**
     * This function alters state in a manner that moves the details page to and from edit mode
     */
    changeEditState = () => {
        this.setState({
            isUpdateSiteInfoEditClicked: !this.state.isUpdateSiteInfoEditClicked,
            hasUpdateSiteSubmittedOnce: false,
            siteErrorTexts: {},
            updatedSite: this.state.site,
            isPageLoading: false
        });
    };

    /**
     This function handles change of inputs to the site information container fields.
     */
    handleUpdateSiteInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        // This handles changes to any of the link inputs
        input.site = Object.assign({}, this.state.updatedSite);
        input.siteErrorTexts = Object.assign({}, this.state.siteErrorTexts);
        input.providerOptions = HelperFunctions.deepClone(this.props.providerOptions);

        const output = SiteValidation.validateInput(input);

        this.setState({
            updatedSite: output.site,
            siteErrorTexts: output.siteErrorTexts
        });
    };

    /**
     * This function handles changes of inputs in the address information
     */
    handleUpdateAddressInputChange = (evt) => {
        const siteErrorTexts = Object.assign({}, this.state.siteErrorTexts);
        const updatedSite = Object.assign({}, this.state.updatedSite);

        const input = {};
        input.evt = evt;
        input.address = Object.assign({}, this.state.updatedSite.address);
        input.errors = siteErrorTexts;
        input.isSite = true;

        const output = AddressValidation.validateAddress(input);

        updatedSite.address = output.address;

        Object.keys(output.errors).forEach((key) => { siteErrorTexts[key] = input.errors[key]; });
        this.setState({
            updatedSite,
            siteErrorTexts
        });
    };

    /**
     * Send updated site info to backend when the submit button is clicked
     */
    handleUpdateSiteSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, { isUpdateSiteInfoInProgress: true });
        const updatedSite = Object.assign({}, this.state.updatedSite);

        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.siteErrorTexts).some(errorText => errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    isUpdateSiteInfoInProgress: false,
                    hasUpdateSiteSubmittedOnce: true
                }
            );
            return;
        }

        updatedSite.address = SiteValidation.cleanAddressObject(updatedSite.address);

        try {
            const response = await this.FremontBackendClient.modifySite(
                [updatedSite], [this.state.site], this.props.auth
            );
            const [site] = response.sites;

            // Resets all input fields to original state if request is successful
            HelperFunctions.displayFlashbarSuccess(this, Constants.FLASHBAR_STRINGS.flashbarSuccessText, {
                isUpdateSiteInfoEditClicked: false,
                hasUpdateSiteSubmittedOnce: false,
                site,
                isPageLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                hasUpdateSiteSubmittedOce: true
            });
        }
        this.setState({ isUpdateSiteInfoInProgress: false });
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     **************************************************************************************************
     * TAB CHANGE HANDLERS
     **************************************************************************************************
     */

    /**
     This function handles changing the active tab on the Tabs component, and resets the flashbar.
     */
    handleTabChange = (evt) => {
        HelperFunctions.dismissFlashbar(this, { activeTabId: evt.detail.activeTabId });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false });
        }
    };

    /**
     This function updates the tabs of the provider details page
     */
    updateTabs = () => (
        [
            {
                label: "Details",
                id: SiteDetailsPage.SITE_INFO_TAB_ID,
                content: <SiteInformation
                    site={this.state.site}
                    updatedSite={this.state.updatedSite}
                    isUpdateSiteInfoEditClicked={this.state.isUpdateSiteInfoEditClicked}
                    isUpdateSiteInfoInProgress={this.state.isUpdateSiteInfoInProgress}
                    isUserHighlyConfidential={this.state.isUserHighlyConfidential}
                    siteErrorTexts={this.state.hasUpdateSiteSubmittedOnce ?
                        this.state.siteErrorTexts : {}}
                    handleUpdateAddressInputChange={this.handleUpdateAddressInputChange}
                    handleUpdateSiteEdit={this.handleUpdateSiteEdit}
                    handleUpdateSiteInputChange={this.handleUpdateSiteInputChange}
                    handleUpdateSiteSubmit={this.handleUpdateSiteSubmit}
                    hasUpdateSiteSubmittedOnce={this.state.hasUpdateSiteSubmittedOnce}
                    providersDisabled={this.state.providersDisabled}
                    providersLoading={this.props.providersLoading}
                    providerOptions={this.props.providerOptions}
                />
            },
            {
                label: "Site Orders",
                id: SiteDetailsPage.SITE_ORDER_TAB_ID,
                content: <OrderTab
                    orderIdList={this.state.site.orderIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage="No orders currently exist for this site."
                />
            },
            {
                label: "Site Nodes",
                id: SiteDetailsPage.SITE_NODE_TAB_ID,
                content: <NodeTab
                    nodeIdList={this.state.site.nodeIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    searchTitle="Search in Site Nodes"
                    emptyTableMessage="No nodes currently exist for this site."
                />
            },
            {
                label: "Site Notes",
                id: SiteDetailsPage.SITE_NOTE_TAB_ID,
                content: <NoteTab
                    tableName="site"
                    type="site"
                    entityId={this.state.site.siteId}
                    name={this.state.site.siteName}
                    noteIdList={this.state.site.noteIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            },
            {
                label: "Audit Trail",
                id: SiteDetailsPage.SITE_AUDIT_TAB_ID,
                content: <AuditTab
                    type="site"
                    auditIdList={this.state.site.auditIdList}
                    auth={this.props.auth}
                    user={this.props.user}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            }
        ]
    );

    render() {
        if (this.state.isPageLoading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    isPageLoading={this.state.isSpinnerShown}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return (
            (
                <div>
                    <FremontHeader
                        history={this.props.history}
                        flashbarText={this.state.flashbar.text}
                        flashbarType={this.state.flashbar.type}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                    <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                        <Box variant="h1">
                            {this.state.site.siteName}
                        </Box>
                        <Tabs
                            tabs={this.updateTabs()}
                            activeTabId={this.state.activeTabId}
                            variant="default"
                            onChange={this.handleTabChange}
                        />
                    </div>
                </div>
            )
        );
    }
}

export default withRouter(SiteDetailsPage);