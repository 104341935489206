import React, { useState } from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import LinkServiceModal from "../components/LinkServiceModal";
import LinkDescriptionListItem from "../components/LinkDescriptionListItem";

export default function LinkConsumptionModal({
    linkDescription,
    removedLinks,
    addedLinks,
    isVisible,
    setIsVisible,
    submitConsumptionUpdate
}) {
    const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false);

    const onConfirmUpdate = async () => {
        setIsSubmissionInProgress(true);
        await submitConsumptionUpdate();
        setIsSubmissionInProgress(false);
    };
    const hideModal = () => {
        setIsVisible(false);
    };

    return (
        <LinkServiceModal
            isModalVisible={isVisible}
            onDismiss={hideModal}
            header={`Confirm consumption update for link ${linkDescription}`}
            onCancelButtonClick={hideModal}
            onSubmitButtonClick={onConfirmUpdate}
            isAttachmentSubmissionInProgress={isSubmissionInProgress}
            confirmButtonText="Submit"
            closeButtonText="Cancel"
        >
            <SpaceBetween>
                {!!removedLinks.length && (
                    <h2>
                        You are removing the following links from the consumption:
                        <ul>
                            {removedLinks.map(lnk => <LinkDescriptionListItem key={lnk.instanceId} link={lnk}/>)}
                        </ul>
                    </h2>
                )}
                {!!addedLinks.length && (
                    <h2>
                        You are adding the following links to the consumption:
                        <ul>
                            {addedLinks.map(lnk => <LinkDescriptionListItem key={lnk.instanceId} link={lnk}/>)}
                        </ul>
                    </h2>
                )}
            </SpaceBetween>
        </LinkServiceModal>
    );
}
