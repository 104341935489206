import React from "react";
import {
    FormField,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const generateCablingDecomColumnDefinitions = (serviceType, customerFabric) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.cabling], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.revisionNumber,
            header: "Version",
            description: "Revision number for this circuit",
            width: Constants.COLUMN_WIDTHS.version,
            cell: item => `V${item[Constants.ATTRIBUTES.revisionNumber]}`
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "Circuit bandwidth for this circuit",
            width: Constants.COLUMN_WIDTHS.bandwidth,
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth] || item[Constants.ATTRIBUTES.portBandwidth]
        },
        {
            id: Constants.COMPONENT_NAMES.ttId,
            header: "DCO TT Decom A",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (!item.editable ?
                HelperFunctions.renderExternalLink(item[Constants.ATTRIBUTES.ttMap][
                    Constants.TT_TYPES.dcoTTA
                ][Constants.COMPONENT_NAMES.ttId])
                :
                <FormField
                    errorText={item.hasStageSubmittedOnce && item.errorTexts[Constants.TT_TYPES.dcoTTA]}
                >
                    <FremontInput
                        id={`${Constants.TT_TYPES.dcoTTA}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttId}`}
                        value={item[Constants.ATTRIBUTES.ttMap][
                            Constants.TT_TYPES.dcoTTA][Constants.COMPONENT_NAMES.ttId]}
                        onInput={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                    />
                </FormField>
            )
        },
        {
            id: Constants.COMPONENT_NAMES.ttStatus,
            header: "DCO TT Status A",
            description: "Status of the TT",
            cell: item =>
                (!item.editable ?
                    item[Constants.ATTRIBUTES.ttMap][Constants.TT_TYPES.dcoTTA][Constants.COMPONENT_NAMES.ttStatus] :
                    <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                        <FremontSelect
                            /* Using multiple Constants.SEPARATOR to render values from the ttMap, ttId, and ttStatus */
                            id={`${Constants.TT_TYPES.dcoTTA}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttStatus}`}
                            options={HelperFunctions.createTTStatusSelectedOptions(
                                item[Constants.ATTRIBUTES.ttMap][
                                    Constants.TT_TYPES.dcoTTA
                                ][Constants.COMPONENT_NAMES.ttId]
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.ttMap][
                                    Constants.TT_TYPES.dcoTTA
                                ][Constants.COMPONENT_NAMES.ttStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            empty="Enter a valid TT url first."
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>)
        }
    ];

    if (Constants.SERVICE_TYPES.BACKBONE === serviceType) {
        columnDefinitions.push(
            {
                id: "ttIdZ",
                header: "DCO TT Decom Z",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (!item.editable ?
                    HelperFunctions.renderExternalLink(item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.dcoTTZ
                    ][Constants.COMPONENT_NAMES.ttId])
                    :
                    <FormField errorText={item.hasStageSubmittedOnce && item.errorTexts[Constants.TT_TYPES.dcoTTZ]}>
                        <FremontInput
                            /* Using multiple Constants.SEPARATOR to render values from the ttMap, ttId, and ttStatus */
                            id={`${Constants.TT_TYPES.dcoTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttId}`}
                            value={item[Constants.ATTRIBUTES.ttMap][
                                Constants.TT_TYPES.dcoTTZ][Constants.COMPONENT_NAMES.ttId]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>)
            },
            {
                id: "ttStatusZ",
                header: "DCO TT Status Z",
                description: "Status of the TT",
                cell: item => (!item.editable ?
                    item[Constants.ATTRIBUTES.ttMap][Constants.TT_TYPES.dcoTTZ][Constants.COMPONENT_NAMES.ttStatus] :
                    <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                        <FremontSelect
                            /* Using multiple Constants.SEPARATOR to render values from the ttMap, ttId, and ttStatus */
                            id={`${Constants.TT_TYPES.dcoTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttStatus}`}
                            options={HelperFunctions.createTTStatusSelectedOptions(
                                item[Constants.ATTRIBUTES.ttMap][
                                    Constants.TT_TYPES.dcoTTZ
                                ][Constants.COMPONENT_NAMES.ttId]
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.ttMap][
                                    Constants.TT_TYPES.dcoTTZ
                                ][Constants.COMPONENT_NAMES.ttStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            empty="Enter a valid TT url first."
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>)
            }
        );
    }

    if (Constants.OPTICAL_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: "opticalTTIdA",
                header: "Optical TT A",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (!item.editable ?
                    HelperFunctions.renderExternalLink(
                        item[Constants.ATTRIBUTES.ttMap][
                            Constants.TT_TYPES.opticalTTA
                        ][Constants.COMPONENT_NAMES.ttId]
                    )
                    :
                    <FormField
                        errorText={item.hasStageSubmittedOnce && item.errorTexts[Constants.TT_TYPES.opticalTTA]}
                    >
                        <FremontInput
                            /* Using multiple Constants.SEPARATOR to render values from the ttMap, ttId, and ttStatus */
                            id={`${Constants.TT_TYPES.opticalTTA}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttId}`}
                            value={item[Constants.ATTRIBUTES.ttMap][
                                Constants.TT_TYPES.opticalTTA][Constants.COMPONENT_NAMES.ttId]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                )
            },
            {
                id: "opticalTTStatusA",
                header: "Optical TT Status A",
                description: "Status of the TT",
                cell: item => (!item.editable ?
                    item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.opticalTTA
                    ][Constants.COMPONENT_NAMES.ttStatus] :
                    <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.TT_TYPES.opticalTTA}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttStatus}`}
                            options={HelperFunctions.createTTStatusSelectedOptions(
                                item[Constants.ATTRIBUTES.ttMap][
                                    Constants.TT_TYPES.opticalTTA
                                ][Constants.COMPONENT_NAMES.ttId]
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.ttMap][
                                    Constants.TT_TYPES.opticalTTA
                                ][Constants.COMPONENT_NAMES.ttStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            empty="Enter a valid TT url first."
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>)
            },
            {
                id: "opticalTTIdZ",
                header: "Optical TT Z",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (!item.editable ?
                    HelperFunctions.renderExternalLink(item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.opticalTTZ
                    ][Constants.COMPONENT_NAMES.ttId])
                    :
                    <FormField
                        errorText={item.hasStageSubmittedOnce && item.errorTexts[Constants.TT_TYPES.opticalTTZ]}
                    >
                        <FremontInput
                            id={`${Constants.TT_TYPES.opticalTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttId}`}
                            value={item[Constants.ATTRIBUTES.ttMap][
                                Constants.TT_TYPES.opticalTTZ][Constants.COMPONENT_NAMES.ttId]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                )
            },
            {
                id: "opticalTTStatusZ",
                header: "Optical TT Status Z",
                description: "Status of the TT",
                cell: item => (!item.editable ?
                    item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.opticalTTZ
                    ][Constants.COMPONENT_NAMES.ttStatus] :
                    <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.TT_TYPES.opticalTTZ}${Constants.SEPARATOR}${item.circuitDesignId}${Constants.SEPARATOR}${Constants.COMPONENT_NAMES.ttStatus}`}
                            options={HelperFunctions.createTTStatusSelectedOptions(
                                item[Constants.ATTRIBUTES.ttMap][
                                    Constants.TT_TYPES.opticalTTZ
                                ][Constants.COMPONENT_NAMES.ttId]
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(item[Constants.ATTRIBUTES.ttMap][
                                Constants.TT_TYPES.opticalTTZ
                            ][Constants.COMPONENT_NAMES.ttStatus])}
                            onChange={item.handleStageInputChange}
                            empty="Enter a valid TT url first."
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>)
            }
        );
    }

    columnDefinitions.push(
        {
            id: Constants.ATTRIBUTES.cablingRemovalStatus,
            header: "Cabling Status",
            description: "Cabling Decom status of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.cablingRemovalStatus] :
                    <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.cablingRemovalStatus}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={Constants.COMPLETION_OPTIONS}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.cablingRemovalStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.isUpdateStageInProgress || item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>
            )
        }
    );

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)
        || Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.nodeA,
                header: "Router A",
                description: "The nodeA component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.nodeA]
            },
            {
                id: Constants.COMPONENT_NAMES.portA,
                header: "Port A",
                description: "The portA component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.portA]
            }
        );
    }

    if (Constants.SERVICE_TYPES.SFP === serviceType) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "LAG A",
                description: "description",
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            }
        );
    }

    if (Constants.SERVICE_TYPES.BACKBONE === serviceType
        && Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.leverAInternalInterface,
                header: "Lever A Internal Interface",
                description: "The leverAInternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverAInternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.leverA,
                header: "Lever A",
                description: "The leverA component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverA]
            },
            {
                id: Constants.COMPONENT_NAMES.leverAExternalInterface,
                header: "Lever A External Interface",
                description: "The leverAExternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverAExternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.leverZExternalInterface,
                header: "Lever A External Interface",
                description: "The leverZExternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverZExternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.leverZ,
                header: "Lever Z",
                description: "The leverZ component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverZ]
            },
            {
                id: Constants.COMPONENT_NAMES.leverZInternalInterface,
                header: "Lever A Internal Interface",
                description: "The leverZInternalInterface component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.leverZInternalInterface]
            },
            {
                id: Constants.COMPONENT_NAMES.portZ,
                header: "Port Z",
                description: "The portZ component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.portZ]
            },
            {
                id: Constants.COMPONENT_NAMES.nodeZ,
                header: "Router Z",
                description: "The nodeZ component for this circuit",
                cell: item => item[Constants.COMPONENT_NAMES.nodeZ]
            }
        );
    }

    return columnDefinitions;
};

export default generateCablingDecomColumnDefinitions;