import React, { Component } from "react";
import {
    Box,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    ConfirmationModal,
    FremontAlert
} from "utils/CommonComponents";
import {
    SubmitStageDisplayMode,
    SubmitStageEditMode
} from "order/stages/SubmitForApprovalInformation";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";

class SubmitForApprovalHandler extends Component {
    state = {
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        updatedOrder: this.props.order,
        errorTexts: HelperFunctions.isOrderPathOrder(this.props.order) ?
            OrderValidation.BIZ_DEV_SUBMIT_STAGE_ERROR_BACKBONE_BFB_FINALCAT :
            OrderValidation.BUSINESS_DEVELOPER_SUBMIT_STAGE_ERROR_TEXTS,
        updatedCircuitDesignObjects: [],
        isUpdateStageInProgress: false,
        latestRejectionNote: "",
        path: {
            pathId: "",
            pathName: ""
        },
        siteNames: this.props.siteNames,
        massUpdateSelectedCircuitDesignIds: [],
        validPaths: [],
        fetchingValidPaths: true,
        isPathNameInvalidModalVisible: false
    };

    componentDidMount = async () => {
        try {
            this.setState({
                latestRejectionNote: await OrderValidation.fetchLatestRejectionNote(
                    this.FremontBackendClient,
                    this.props.order.noteIdList,
                    this.props.auth,
                    Constants.REJECTION_REASON
                )
            });
            this.setPath();
            if (!HelperFunctions.isProd()) {
                this.fetchValidPaths();
            }
        } catch (error) {
            // This is used for showing the flashbar error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    /**
     * Often times the componentIdToObjectMap will be populated after this component has mounted since it takes time
     * to fetch all the components. Since our path object is derived from this map, we need to re-render our component
     * everytime the prop changes.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.componentIdToObjectMap !== this.props.componentIdToObjectMap) {
            this.setPath();
        }
    }

    setPath = () => {
        let path = { pathId: "", pathName: "" };
        // If there is a pathId on the circuits, it should always be on the componentIdToObjectMap. If its not there
        // something ugly is happening with our call. Our GET /batch/component endpoint should catch this anyways
        if (this.props.circuitDesignObjects.length > 0 && HelperFunctions.isOrderPathOrder(this.props.order)) {
            const pathIdOnCircuits = this.props.circuitDesignObjects.find(Boolean).pathId;
            if (pathIdOnCircuits && Object.keys(this.props.componentIdToObjectMap).includes(pathIdOnCircuits)) {
                path = this.props.componentIdToObjectMap[pathIdOnCircuits];
            }
        }
        this.setState({ path });
    };

    FremontBackendClient = new FremontBackendClient();

    fetchValidPaths = async () => {
        const validPathsResponse = await this.FremontBackendClient.getValidPaths(this.props.auth);
        const pathsFilterKey = this.state.siteNames.siteAName.concat("-", this.state.siteNames.siteZName);
        const validPathsWithSites = validPathsResponse.validPaths
            .filter(path => path.path_name.includes(pathsFilterKey)
                && (path.lifecycle == null || !Constants.IGNORED_CINNAMON_PATH_LIFECYCLES.includes(path.lifecycle)))
            .sort((path1, path2) => path1.path_name.localeCompare(path2.path_name));

        this.setState({
            validPaths: validPathsWithSites,
            fetchingValidPaths: false
        });
    }

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItemsObject = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageCircuitInputChange,
            this.props.blockers,
            this.state.isUpdateStageInProgress
        );

        if (circuitItemsObject.static.length > 0) {
            circuitItemsObject.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.ATTRIBUTES.loaAttachmentIdA]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.loaAttachmentIdZ]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.completionNoticeId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                                .find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.kmzAttachmentId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.BERTAttachmentId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.RFCAttachmentId]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].find(Boolean)
                            : "",
                    [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.providerCircuitA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    downloadAttachment: this.props.downloadAttachment,
                    isDownloadingAttachment: this.props.isDownloadingAttachment
                }));
        }
        if (circuitItemsObject.dynamic.length > 0) {
            circuitItemsObject.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    [Constants.ATTRIBUTES.loaAttachmentIdA]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.loaAttachmentIdZ]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.completionNoticeId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                                .find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.kmzAttachmentId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.KMZ].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.BERTAttachmentId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.BERT].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.RFCAttachmentId]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.RFC].find(Boolean)
                            : "",
                    [Constants.COMPONENT_NAMES.providerCircuitA]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.providerCircuitA]
                }));
        }

        return circuitItemsObject;
    };

    disableSubmitForApproval = () => !HelperFunctions
        .isStageInProgress(this.props.order.stageStatusMap[Constants.STAGE_NAMES.submitForApproval])
        || this.state.isUpdateStageInProgress;

    /**
     * Handle clicking the edit button
     */
    handleStageEditClick = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects)
            .map(circuitDesign => Object.assign(circuitDesign, {
                [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.providerCircuitA,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ),
                errorTexts: {
                    [Constants.COMPONENT_NAMES.providerCircuitA]: ""
                }
            }));
        // Here we obtain the path id from a circuit object. Every circuit on an order has the same path id
        // so we are OK to obtain the path name from a single circuit.
        // However, in the updatedOrder we are just storing the pathName as that is what gets displayed to user.
        // The paths are done in a bit different way as all possible paths are currently stored on front-end
        const updatedOrder = Object.assign(
            HelperFunctions.deepClone(this.props.order), { pathName: this.state.path.pathName }
        );
        const errorTexts = HelperFunctions.isOrderPathOrder(this.props.order) ?
            OrderValidation.BIZ_DEV_SUBMIT_STAGE_ERROR_BACKBONE_BFB_FINALCAT :
            OrderValidation.BUSINESS_DEVELOPER_SUBMIT_STAGE_ERROR_TEXTS;
        // If a path name exists, we clear out the error text
        if (HelperFunctions.isOrderPathOrder(this.props.order) && !!this.state.path.pathName) {
            errorTexts.pathName = "";
        }
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            hasBeenSubmittedOnce: false,
            updatedOrder,
            errorTexts,
            isUpdateStageInProgress: false,
            updatedCircuitDesignObjects,
            isPathNameInvalidModalVisible: false
        });
    };

    /**
     * Handle any edits to the order
     * @param evt
     */
    handleStageOrderInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.updatedOrder);
        input.orderErrorTexts = HelperFunctions.deepClone(this.state.errorTexts);

        const output = OrderValidation.validateInput(input);

        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);

        this.setState({
            updatedOrder: output.order,
            errorTexts: output.orderErrorTexts
        });
    };

    handleSelectedFromTable = (evt) => {
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageCircuitInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);

        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);
        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(
            this.state.massUpdateSelectedCircuitDesignIds, input, circuitDesignOutput.circuitDesignObjects
        );
        this.setState({ updatedCircuitDesignObjects: output });
    };

    /**
     * This functions calls modifyPath
     * @param pathName
     * @param circuitDesignIdList
     */
    submitPath = async (canonicalPathId, pathName, circuitDesignIdList) => {
        let path;
        if (HelperFunctions.isProd()) {
            path = {
                pathName,
                circuitDesignIdListToAddFromRequest: circuitDesignIdList
            };
        } else {
            path = {
                canonicalPathId,
                pathName,
                circuitDesignIdListToAddFromRequest: circuitDesignIdList
            };
        }

        const pathResponse = await this.FremontBackendClient.modifyPath([path], this.props.auth);

        // If there is a valid response from modifyPath it will be used to update the state path
        // If there is an error it will be caught by the try/catch.
        this.setState({
            path: pathResponse.path
        });
    }

    /**
     * Handle submitting any edits to the order
     * @param evt
     */
    handleStageSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, {
            isUpdateStageInProgress: true,
            isPathNameInvalidModalVisible: false
        });
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const originalCircuitDesignObjects = this.generateCircuitItems().static;
        if (Object.values(this.state.errorTexts).some(errorText => errorText)
            || updatedCircuitDesignObjects.some(circuitDesign =>
                Object.values(circuitDesign.errorTexts).some(errorText => errorText))) {
            this.props.handleFlashBarMessagesFromChildTabs(
                false,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                false
            );
            this.setState({ isUpdateStageInProgress: false, hasBeenSubmittedOnce: true });
            return;
        }
        try {
            const orderResponse = await this.FremontBackendClient.updateOrderInfo(
                this.state.updatedOrder, this.props.order, this.props.auth
            );
            if (HelperFunctions.isOrderPathOrder(this.state.updatedOrder)) {
                await this.submitPath(
                    this.state.updatedOrder[Constants.ATTRIBUTES.canonicalPathId],
                    this.state.updatedOrder[Constants.ATTRIBUTES.pathName],
                    orderResponse[Constants.ATTRIBUTES.circuitDesignIdList]
                );
            }

            await this.FremontBackendClient.modifyProviderCircuit(updatedCircuitDesignObjects,
                originalCircuitDesignObjects, this.props.auth);

            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            // Resets all input fields to original state if request is successful
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false,
                hasBeenSubmittedOnce: false,
                isPathNameInvalidModalVisible: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                isPathNameInvalidModalVisible: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    /**
     * Handle submitting any edits to the order after checking the path name.
     * @param evt
     */
    confirmPathNameAndHandleStageSubmit = async (evt) => {
        if (this.isValidPathName(this.state.updatedOrder[Constants.ATTRIBUTES.pathName]).isValid) {
            this.setState({ isPathNameInvalidModalVisible: false });
            this.handleStageSubmit(evt);
        } else {
            this.setState({ isPathNameInvalidModalVisible: true });
        }
    };

    /**
     * This method handles moving from the submit for approval stage to the approval stage
     */
    handleStageSubmitForApproval = async () => {
        const updatedOrder = HelperFunctions.deepClone(this.props.order);
        if (updatedOrder[Constants.ATTRIBUTES.orderType] === Constants.ORDER_TYPES.INSTALL
            && updatedOrder[Constants.ATTRIBUTES.serviceType] === Constants.SERVICE_TYPES.BACKBONE
            && !Constants.PATH_CUSTOMER_FABRICS.includes(updatedOrder[Constants.ATTRIBUTES.customerFabric])
            && this.props.circuitDesignObjects.some(circuitDesign =>
                HelperFunctions.isEmpty(circuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA])
                || HelperFunctions.isEmpty(circuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ])
                || HelperFunctions.isEmpty(
                    circuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.completionNotice]
                ))) {
            this.props.handleFlashBarMessagesFromChildTabs(false, {
                name: "customError",
                message: "Must have LOA and Completion Notice attached to all circuits before submitting for approval."
            }, false);
            this.setState({ isUpdateStageInProgress: false, hasBeenSubmittedOnce: true });
            return;
        }
        updatedOrder.hasBeenSubmittedForApproval = true;
        if (!Object.prototype.hasOwnProperty.call(updatedOrder.stageStatusMap, Constants.STAGE_NAMES.orderAcceptance)) {
            updatedOrder.hasBeenApproved = true;
        }
        // Dismiss the flashbar and make sure other stages cannot be edited while the submission is in progress
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true
        });
        this.props.handleStageInEditOrSubmitMode(this.state.isUpdateStageInProgress);
        try {
            await this.FremontBackendClient.updateOrderInfo(updatedOrder, this.props.order, this.props.auth);
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.props.handleStageInEditOrSubmitMode(false);
        }
        this.setState({
            isUpdateStageInProgress: false
        });
    };

    anyActiveCircuitBlockers = () => HelperFunctions.anyActiveCircuitBlockersInOrder(
        this.props.circuitDesignObjects, this.props.blockers
    );

    /**
     * Checks the pathName.
     * @param {*} pathName
     * @returns { isValid: boolean }
     */
    isValidPathName = (pathName) => {
        const matched = {
            isValid: false
        };
        if (HelperFunctions.isEmpty(pathName)) {
            matched.isValid = true;
            return matched;
        }
        if (this.hasUnmatchedBracket(pathName)) {
            return matched;
        }
        /* eslint-disable no-restricted-syntax */
        for (const pattern of Constants.REGEX_PATTERNS.pathName) {
            if (pattern.test(pathName)) {
                matched.isValid = true;
                break;
            }
        }
        /* eslint-enable no-restricted-syntax */
        return matched;
    }

    hasUnmatchedBracket = (pathName) => {
        const stack = [];
        let currentChar;
        for (let i = 0; i < pathName.length; i += 1) {
            currentChar = pathName.charAt(i);
            if (currentChar === "[") {
                stack.push(currentChar);
            }
            if (currentChar === "]") {
                if (stack.length === 0) {
                    return true;
                }
                stack.pop();
            }
        }
        if (stack.length !== 0) {
            return true;
        }
        return false;
    }

    handleSubmitForApprovalEditMode = () => (HelperFunctions.isProd() ?
        <div>
            <SubmitStageEditMode
                stageName={Constants.STAGE_NAMES.submitForApproval}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                circuitItems={this.generateCircuitItems().dynamic}
                circuitDesignsLoading={this.props.circuitDesignsLoading}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                errorTexts={this.state.hasBeenSubmittedOnce ?
                    this.state.errorTexts : OrderValidation.BIZ_DEV_SUBMIT_STAGE_BLANK}
                handleStageSubmit={this.confirmPathNameAndHandleStageSubmit}
                handleStageOrderInputChange={this.handleStageOrderInputChange}
                handleStageEditClick={this.handleStageEditClick}
                latestRejectionNote={this.state.latestRejectionNote}
                handleSelectedFromTable={this.handleSelectedFromTable}
                massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
            />
            <ConfirmationModal
                isVisible={this.state.isPathNameInvalidModalVisible}
                loading={false}
                header="Path Name Validation Failed"
                description={
                    <FremontAlert type="warning">
                        <div>
                            <Box variant="p">The format should follow the conventions outlined here:</Box>
                            <Link
                                href={Constants.PATH_NAME_VALIDATION_WIKI}
                                external
                            >
                                {Constants.PATH_NAME_VALIDATION_WIKI}
                            </Link>
                        </div>
                    </FremontAlert>
                }
                hideModal={() => this.setState({
                    isUpdateStageInProgress: false,
                    isPathNameInvalidModalVisible: false
                })}
                cancelButtonText="Cancel"
                primaryButtonText="Proceed Anyway"
                onClickFunction={this.handleStageSubmit}
            />
        </div>
        : <SubmitStageEditMode
            stageName={Constants.STAGE_NAMES.submitForApproval}
            updatedOrder={this.state.updatedOrder}
            disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                this.props.order.stageStatusMap)}
            circuitItems={this.generateCircuitItems().dynamic}
            circuitDesignsLoading={this.props.circuitDesignsLoading}
            hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
            isUpdateStageInProgress={this.state.isUpdateStageInProgress}
            errorTexts={this.state.hasBeenSubmittedOnce ?
                this.state.errorTexts : OrderValidation.BIZ_DEV_SUBMIT_STAGE_BLANK}
            handleStageSubmit={this.handleStageSubmit}
            handleStageOrderInputChange={this.handleStageOrderInputChange}
            handleStageEditClick={this.handleStageEditClick}
            latestRejectionNote={this.state.latestRejectionNote}
            handleSelectedFromTable={this.handleSelectedFromTable}
            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
            validPaths={this.state.validPaths}
            fetchingValidPaths={this.state.fetchingValidPaths}
        />)

    render() {
        return !this.state.isEditClicked ?
            <SubmitStageDisplayMode
                stageName={Constants.STAGE_NAMES.submitForApproval}
                order={this.props.order}
                orderCompleted={this.props.orderCompleted}
                disableSubmitForApproval={this.disableSubmitForApproval()}
                handleStageSubmitForApproval={this.handleStageSubmitForApproval}
                showAttachmentModal={this.props.showAttachmentModal}
                handleStageEditClick={this.handleStageEditClick}
                circuitItems={this.generateCircuitItems().static}
                circuitDesignsLoading={this.props.circuitDesignsLoading}
                isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                goToComponentAction={this.props.goToComponentAction}
                anyBlockedCircuits={this.anyActiveCircuitBlockers()}
                editButtonsDisabled={this.props.editButtonsDisabled}
                latestRejectionNote={this.state.latestRejectionNote}
                pathName={this.state.path.pathName}
            />
            : this.handleSubmitForApprovalEditMode();
    }
}

export default SubmitForApprovalHandler;