import React, { Component } from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import LinkVisualConstants from "./LinkVisualConstants";
import LinkVisual from "./LinkVisual";

export default class LinkVisualWrapper extends Component {
    state = {
        isTopDown: true,
        collapsed: true,
        expanded: false
    }
    svgRef = React.createRef();

    handleTopDownClick = () => {
        this.setState({ isTopDown: true });
    };

    handleBottomUpClick = () => {
        this.setState({ isTopDown: false });
    };

    handleExpandAllClick = () => {
        this.setState({ expanded: true, collapsed: false });
    }

    handleCollapseAll = () => {
        this.setState({ collapsed: true, expanded: false });
    }

    handleExportClick = () => {
        const svgElement = this.svgRef.current;
        const serializer = new XMLSerializer();
        const source = serializer.serializeToString(svgElement);

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const img = new Image();

        img.onload = () => {
            canvas.width = LinkVisualConstants.width;
            canvas.height = LinkVisualConstants.height;
            context.drawImage(img, 0, 0);

            const anchor = document.createElement("a");
            anchor.href = canvas.toDataURL("image/png");
            anchor.download = "hierarchy.png";
            anchor.click();
        };

        img.src = `data:image/svg+xml;charset=utf-8;base64,${btoa(source)}`;
    }
    render() {
        const styles = {
            legend: {
                display: "flex",
                flexDirection: "column"
            },
            legendItem: {
                display: "flex",
                alignItems: "center",
                marginBottom: "5px"
            },
            legendColor: {
                width: "10px",
                height: "10px",
                borderRadius: "0",
                marginRight: "5x"
            },
            legendText: {
                fontSize: "10px",
                fontFamily: "sans-serif",
                marginLeft: "5px"
            },
            label: {
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
                marginBottom: "10px"
            }
        };

        return (
            <div style={{ position: "relative", width: "100%", height: "100%" }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "10px",
                    left: "0",
                    width: "100%",
                    padding: "5px"
                }}
                >
                    <Button
                        onClick={this.handleTopDownClick}
                        style={{ marginRight: "10px" }}
                        disabled={this.state.isTopDown}
                    >
                        Render Top Down View
                    </Button>
                    <Button onClick={this.handleBottomUpClick} disabled={!this.state.isTopDown}>
                        Render Bottom Up View
                    </Button>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "50px",
                    left: "0",
                    width: "100%",
                    padding: "5px"

                }}
                >
                    <Button style={{ marginRight: "10px" }} onClick={this.handleExpandAllClick}>
                        Expand All Nodes
                    </Button>
                    <Button onClick={this.handleCollapseAll}>
                        Collapse All Nodes
                    </Button>
                </div>
                <div style={{
                    position: "relative",
                    top: "100px"

                }}
                >
                    <LinkVisual
                        svgRef={this.svgRef}
                        linkData={this.props.linkData}
                        isTopDown={this.state.isTopDown}
                        expandAll={this.state.expanded}
                        collapseAll={this.state.collapsed}
                    />
                </div>
                <div style={{
                    position: "absolute", top: "10px", left: "10px", padding: "5px"
                }}
                >
                    <Button variant="primary" onClick={this.props.onBackClick}> Back To Table </Button>
                </div>
                <div style={{
                    position: "absolute", top: "60px", left: "10px", padding: "5px"
                }}
                >
                    <Button variant="primary" onClick={this.handleExportClick}>
                        Export Visual as PNG
                    </Button>
                </div>
                <div style={{
                    position: "absolute",
                    top: "10px",
                    right: "80px",
                    padding: "10px",
                    paddingBottom: "10px",
                    border: "1px solid #000",
                    borderRadius: "10px",
                    background: "#f0f0f0"
                }}
                >
                    <div style={styles.label}>Legend</div>
                    <div style={styles.legend}>
                        {Object.keys(LinkVisualConstants.linkTypeColorMap).map(category => (
                            <div key={category} style={styles.legendItem}>
                                <div
                                    style={{
                                        ...styles.legendColor,
                                        backgroundColor: LinkVisualConstants.linkTypeColorMap[category]
                                    }}
                                />
                                <span style={styles.legendText}>{category}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
