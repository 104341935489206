import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import { v4 as uuidv4 } from "uuid";
import OrderTableData from "../OrderTableData";
import {
    AwsProvisioningInfoStageDisplayMode,
    AwsProvisioningInfoStageEditMode
} from "./install/AwsProvisioningInformation";
import {
    HardwareDeliveryInfoStageDisplayMode,
    HardwareDeliveryInfoStageEditMode
} from "./install/HardwareDeliveryInformation";
import {
    OpticalCutsheetInfoStageDisplayMode,
    OpticalCutsheetInfoStageEditMode
} from "./install/OpticalCutsheetInformation";
import {
    OpticalPrepWorkInfoStageDisplayMode,
    OpticalPrepWorkInfoStageEditMode
} from "./install/OpticalPrepWorkInformation";
import {
    DecomOpticalPrepWorkInfoStageDisplayMode,
    DecomOpticalPrepWorkInfoStageEditMode
} from "./decom/DecomOpticalPrepWorkInformation";
import {
    OpticalTopologyInfoStageDisplayMode,
    OpticalTopologyInfoStageEditMode
} from "./install/OpticalTopologyInformation";
import { StageDisplayMode, StageEditMode } from "../OrderCommonComponents";
import { LineFiberInfoStageDisplayMode, LineFiberInfoStageEditMode } from "./install/LineFiberInformation";
import { PathDesignInfoStageDisplayMode, PathDesignInfoStageEditMode } from "./install/PathDesignInformation";
import { CablingOrderInfoStageDisplayMode, CablingOrderInfoStageEditMode } from "./install/CablingOrderInformation";
import { VendorBuildInfoStageDisplayMode, VendorBuildInfoStageEditMode } from "./install/VendorBuildInformation";
import { McmExecutionInfoStageDisplayMode, McmExecutionInfoStageEditMode } from "./decom/McmExecutionInformation";

export default class OpticalBackboneStageHandler extends Component {
    state={
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        updatedOrder: this.props.order,
        errorTexts: {},
        isUpdateInProgress: false,
        topologyMapFromDesignString: {},
        siteNameToSiteIdMap: {},
        isSpanOrderSubmissionInProgress: false,
        orderMap: {},
        ordersThatDontExist: [],
        spansThatExist: [],
        componentIdToObjectMap: HelperFunctions.deepClone(this.props.componentIdToObjectMap),
        providerCircuitNamesToOrderIdsMap: {},
        allFieldsDisabled: false,
        contactId: this.props.order.contactId,
        portSize: this.props.order.portSize
    };

    componentDidMount = async () => {
        try {
            await this.fetchOrderMap();
            if (!HelperFunctions.isStageCompleted(this.props.order.stageStatusMap[this.props.stageName])) {
                await this.createTopologyMapFromDesignString(this.props.order.opticalDesignString);
            }
            // Get the contactId
            const providerResponse = await this.FremontBackendClient.getProviderInfo(Constants.INTERNAL_AMAZON_PROVIDER,
                this.props.auth);
            if (providerResponse.provider.contactIdList.length === 0) {
                const errorMessage = `No Contacts for the provider ${Constants.INTERNAL_AMAZON_PROVIDER}`;
                this.props.handleFlashBarMessagesFromChildTabs(false, errorMessage, false);
            }
            this.setState({ contactId: providerResponse.provider.contactIdList[0] });
            if (!this.state.portSize && this.props.order.circuitDesignIdList.length > 0) {
                const response = await this.FremontBackendClient.getCircuitDesignInfo(
                    this.props.order.circuitDesignIdList[0], this.props.auth
                );
                this.setState({ portSize: response.circuitDesign.circuitBandwidth });
            }
        } catch (error) {
            // This is used for showing the flashbar error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    componentDidUpdate = async (prevProps) => {
        // we need to regenerate topology map from design string when optical design string changes
        if (prevProps.order.opticalDesignString !== this.props.order.opticalDesignString) {
            await this.createTopologyMapFromDesignString(this.props.order.opticalDesignString);
        }
    };

    getStageDisplayMode = (stageName) => {
        switch (stageName) {
        case Constants.STAGE_NAMES.awsProvisioning:
            return (<AwsProvisioningInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.awsProvisioning}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.cablingOrder:
            return (<CablingOrderInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.cablingOrder}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.hardwareDelivery:
            return (<HardwareDeliveryInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.hardwareDelivery}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.lineFiber:
            return (<LineFiberInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.lineFiber}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.mcmExecution:
            return (<McmExecutionInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.mcmExecution}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.opticalCutsheet:
            return (<OpticalCutsheetInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.opticalCutsheet}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.opticalPrepWork:
            if (this.props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION) {
                return (<DecomOpticalPrepWorkInfoStageDisplayMode
                    stageName={Constants.STAGE_NAMES.opticalPrepWork}
                    order={this.props.order}
                    asn={this.props.asn}
                    asnLoading={this.props.asnLoading}
                    orderCompleted={this.props.orderCompleted}
                    handleStageEditClick={this.handleStageEditClick}
                    editButtonsDisabled={this.props.editButtonsDisabled}
                    goToComponentAction={this.props.goToComponentAction}
                />);
            }
            return (<OpticalPrepWorkInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.opticalPrepWork}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.pathDesign:
            return (<PathDesignInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.pathDesign}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
                hasStageBeenCompleted={!this.props.order.congoRequired || this.props.order.opticalDesignString}
                handleCompleteStage={this.props.handleToggleCompleteStage}
                completeStageMessage="Congo Not Required"
            />);
        case Constants.STAGE_NAMES.vendorBuild:
            return (<VendorBuildInfoStageDisplayMode
                stageName={Constants.STAGE_NAMES.vendorBuild}
                order={this.props.order}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                orderCompleted={this.props.orderCompleted}
                handleStageEditClick={this.handleStageEditClick}
                editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                    || this.props.editButtonsDisabled}
                goToComponentAction={this.props.goToComponentAction}
            />);
        case Constants.STAGE_NAMES.opticalTopology:
            return (<StageDisplayMode
                order={this.props.order}
                stageName={Constants.STAGE_NAMES.opticalTopology}
                disableEditButton={this.props.editButtonsDisabled}
                handleStageEditClick={this.handleStageEditClick}
                goToComponentAction={this.props.goToComponentAction}
                hasStageBeenCompleted={this.props.order.hasOpticalTopologyBeenCompleted}
                handleCompleteStage={this.props.handleToggleCompleteStage}
                completeStageMessage="Complete Optical Topology Stage"
                content={this.generateOpticalTopologyDisplayMode()}
            />);
        default:
            console.log(`stage ${stageName} is not a optical backbone stage`);
            return (null);
        }
    }

    getStageEditMode = (stageName) => {
        switch (stageName) {
        case Constants.STAGE_NAMES.awsProvisioning:
            return (<AwsProvisioningInfoStageEditMode
                stageName={Constants.STAGE_NAMES.awsProvisioning}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.cablingOrder:
            return (<CablingOrderInfoStageEditMode
                stageName={Constants.STAGE_NAMES.cablingOrder}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.hardwareDelivery:
            return (<HardwareDeliveryInfoStageEditMode
                stageName={Constants.STAGE_NAMES.hardwareDelivery}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.lineFiber:
            return (<LineFiberInfoStageEditMode
                stageName={Constants.STAGE_NAMES.lineFiber}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.mcmExecution:
            return (<McmExecutionInfoStageEditMode
                stageName={Constants.STAGE_NAMES.mcmExecution}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.opticalCutsheet:
            return (<OpticalCutsheetInfoStageEditMode
                stageName={Constants.STAGE_NAMES.opticalCutsheet}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleOpticalCutsheetRowInputChange}
                handleStageSubmit={this.handleStageSubmit}
                handleAddRowToOpticalCutsheetMap={this.handleAddRowToOpticalCutsheetMap}
            />);
        case Constants.STAGE_NAMES.opticalPrepWork:
            if (this.props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION) {
                return (<DecomOpticalPrepWorkInfoStageEditMode
                    stageName={Constants.STAGE_NAMES.opticalPrepWork}
                    asn={this.props.asn}
                    asnLoading={this.props.asnLoading}
                    updatedOrder={this.state.updatedOrder}
                    disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                        this.props.order.stageStatusMap)}
                    hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                    isUpdateInProgress={this.state.isUpdateInProgress}
                    errorTexts={this.state.errorTexts}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageInputChange={this.handleStageInputChange}
                    handleStageSubmit={this.handleStageSubmit}
                />);
            }
            return (<OpticalPrepWorkInfoStageEditMode
                stageName={Constants.STAGE_NAMES.opticalPrepWork}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.pathDesign:
            return (<PathDesignInfoStageEditMode
                stageName={Constants.STAGE_NAMES.pathDesign}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.vendorBuild:
            return (<VendorBuildInfoStageEditMode
                stageName={Constants.STAGE_NAMES.vendorBuild}
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                updatedOrder={this.state.updatedOrder}
                disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
                    this.props.order.stageStatusMap)}
                hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
                isUpdateInProgress={this.state.isUpdateInProgress}
                errorTexts={this.state.errorTexts}
                handleStageEditClick={this.handleStageEditClick}
                handleStageInputChange={this.handleStageInputChange}
                handleStageSubmit={this.handleStageSubmit}
            />);
        case Constants.STAGE_NAMES.opticalTopology:
            return (<StageEditMode
                order={this.state.updatedOrder}
                stageName={Constants.STAGE_NAMES.opticalTopology}
                handleStageEditClick={this.handleStageEditClick}
                handleStageSubmit={this.handleStageSubmit}
                isUpdateStageInProgress={this.state.isUpdateInProgress}
                isUpdateStageDisabled={this.state.isSearchingForOrders}
                content={this.generateOpticalTopologyEditMode()}
            />);
        default:
            console.log(`stage ${stageName} is not a optical backbone stage`);
            return (null);
        }
    }

    generateOpticalTopologyDisplayMode = () => (<OpticalTopologyInfoStageDisplayMode
        order={this.state.updatedOrder}
        asn={this.props.asn}
        asnLoading={this.props.asnLoading}
        orderCompleted={this.props.orderCompleted}
        handleStageEditClick={this.handleStageEditClick}
        editButtonsDisabled={OrderValidation.isOrderStagesEditButtonDisabled(this.props.order)
                || this.props.editButtonsDisabled}
        goToComponentAction={this.props.goToComponentAction}
        componentIdToObjectMap={this.state.componentIdToObjectMap}
        orderMap={this.state.orderMap}
        topologyMapFromDesignString={this.state.topologyMapFromDesignString}
        providerCircuitNameToOrderIdsMap={this.state.providerCircuitNamesToOrderIdsMap}
    />)

    generateOpticalTopologyEditMode = () => (<OpticalTopologyInfoStageEditMode
        order={this.state.updatedOrder}
        asn={this.props.asn}
        asnLoading={this.props.asnLoading}
        updatedOrder={this.state.updatedOrder}
        disabledFieldsList={HelperFunctions.getDisabledFields(this.props.order.workflow.stages,
            this.props.order.stageStatusMap)}
        hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
        isUpdateInProgress={this.state.isUpdateInProgress}
        errorTexts={this.state.errorTexts}
        handleStageEditClick={this.handleStageEditClick}
        handleStageInputChange={this.handleStageInputChange}
        handleStageSubmit={this.handleStageSubmit}
        topologyMapFromDesignString={this.state.topologyMapFromDesignString}
        isSpanOrderSubmissionInProgress={this.state.isSpanOrderSubmissionInProgress}
        handleTopologyRowInputChange={this.handleTopologyRowInputChange}
        handleCreateOrUseSpanOrder={this.handleCreateOrUseSpanOrder}
        componentIdToObjectMap={this.state.componentIdToObjectMap}
        orderMap={this.state.orderMap}
        providerCircuitNameToOrderIdsMap={this.state.providerCircuitNamesToOrderIdsMap}
        allFieldsDisabled={this.state.allFieldsDisabled}
    />)

    FremontBackendClient = new FremontBackendClient();

    handleAddRowToOpticalCutsheetMap = () => {
        const updatedOrderClone = HelperFunctions.deepClone(this.state.updatedOrder);
        const newOpticalCutsheetObject = {
            fabricPortCutsheet: "",
            opticalClientCutsheet: ""
        };

        const lastPositionInOpticalCutsheet = Object.keys(this.state.updatedOrder.opticalCutsheetMap).length === 0 ?
            0 : Math.max(...Object.keys(this.state.updatedOrder.opticalCutsheetMap));
        updatedOrderClone.opticalCutsheetMap[lastPositionInOpticalCutsheet + 1] = newOpticalCutsheetObject;
        this.setState({ updatedOrder: updatedOrderClone });
    };

    fetchOrderMap = async () => {
        const { topologyMap } = this.state.updatedOrder;
        const orderIdSet = new Set();

        Object.entries(topologyMap).forEach(([, topologyObjects]) =>
            topologyObjects.forEach((topologyObject) => {
                if (!this.state.ordersThatDontExist.includes(topologyObject.orderId)) {
                    orderIdSet.add(topologyObject.orderId);
                }
            }));

        const response = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.ORDER, Array.from(orderIdSet), this.props.auth
        );

        const returnedOrderIds = response.orders.map(order => order.orderId);
        const ordersThatDontExist = Array.from(orderIdSet).filter(existingOrderId =>
            !returnedOrderIds.includes(existingOrderId));

        const orderItems = await OrderTableData.fetchOrderRelatedObjects(
            response.orders, this.props.auth, this.props.handleFlashBarMessagesFromChildTabs
        );
        const orderMap = Object.assign(this.state.orderMap, ...orderItems.map(order => ({ [order.orderId]: order })));

        this.setState({ ordersThatDontExist, orderMap });
    }

    fetchSiteBySiteNames = async (siteNames) => {
        try {
            const response = await this.FremontBackendClient.getBatchWithNames(
                Constants.BATCH_ENTITIES.SITE,
                siteNames,
                this.props.auth
            );

            const siteNameToSiteIdMap = {};
            response.sites.forEach((site) => {
                this.state.componentIdToObjectMap[site.siteId] = site;
                siteNameToSiteIdMap[site.siteName] = site.siteId;
            });
            this.setState({ siteNameToSiteIdMap });
        } catch (error) {
            // This is used for showing the flashbar error message on the provider children page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    fetchExistingSpanOrders = async (providerCircuitNames) => {
        const response = await this.FremontBackendClient.getBatchWithNames(
            Constants.BATCH_ENTITIES.PROVIDER_CIRCUIT,
            providerCircuitNames,
            this.props.auth
        );

        const circuitIds = [];
        const circuitIdToProviderCircuitNameMap = {};
        response.providerCircuits.forEach((providerCircuit) => {
            circuitIds.push(providerCircuit.circuitDesignId);
            circuitIdToProviderCircuitNameMap[providerCircuit.circuitDesignId] = providerCircuit.providerCircuitName;
        });

        const circuitDesignsResponse = await this.FremontBackendClient.getBatchCircuitDesignInfo(
            circuitIds, this.props.auth
        );

        circuitDesignsResponse.circuitDesigns.forEach((circuitDesign) => {
            // skip if circuit is already consumed or lifecycle stage is in ignoreLifecycleStages
            if (!circuitDesign.consumedByCircuitId &&
                !Constants.IGNORED_CIRCUIT_DESIGN_LIFECYCLES.includes(circuitDesign.lifeCycleStage)) {
                const providerCircuitName = circuitIdToProviderCircuitNameMap[circuitDesign.circuitDesignId];
                const orderIds = this.state.providerCircuitNamesToOrderIdsMap[providerCircuitName] || [];

                // add to orderIds for providerCircuitName
                if (!orderIds.includes(circuitDesign.orderId)) {
                    orderIds.push(circuitDesign.orderId);
                    this.state.providerCircuitNamesToOrderIdsMap[providerCircuitName] = orderIds;
                }
            }
        });
    };

    processSpansFromOpticalDesignString = async (spans) => {
        const allSiteNames = new Set();
        const processedSpans = [];
        spans.forEach((span) => {
            const [sites, spanName] = span.trim().split("_");
            let [siteA, siteZ] = sites.toUpperCase().split("-");
            // for opticalDesignString without siteZ airport code (e.g. pdx1-4_pp1), use same airportCode as siteA
            siteZ = siteZ.match(Constants.REGEX_PATTERNS.siteRegex) ? siteZ : `${siteA.slice(0, 3)}${siteZ}`;
            siteA = HelperFunctions.normalizeSiteCode(siteA);
            siteZ = HelperFunctions.normalizeSiteCode(siteZ);
            [siteA, siteZ] = HelperFunctions.alphanumericSortList([siteA, siteZ]);
            allSiteNames.add(siteA);
            allSiteNames.add(siteZ);
            processedSpans.push({ siteA, siteZ, spanName });
        });

        // batch call to get sites by siteNames. (we need this since optical design string only has site names)
        await this.fetchSiteBySiteNames(Array.from(allSiteNames));

        // handle invalid sites in optical design string
        const sitesThatDoesNotExist = [];
        allSiteNames.forEach((siteName) => {
            if (!Object.keys(this.state.siteNameToSiteIdMap).includes(siteName)) {
                sitesThatDoesNotExist.push(siteName);
            }
        });

        // do not generate optical topology if sites are not present
        if (sitesThatDoesNotExist.length > 0) {
            this.props.handleFlashBarMessagesFromChildTabs(false, new Error(`${Constants.ERROR_STRINGS.invalidSite} ${sitesThatDoesNotExist}`));
            return [];
        }

        return processedSpans;
    }

    // generate a pre-populated topology map from optical design string
    createTopologyMapFromDesignString = async (opticalDesignString) => {
        this.setState({ topologyMapFromDesignString: {}, errorTexts: {} });
        if (!opticalDesignString) {
            return;
        }

        const topology = {};
        const spans = opticalDesignString.split(",");

        // we will not create a topology row if corresponding span is already added to order
        Object.values(this.state.orderMap).forEach((order) => {
            if (order.spanName) {
                this.state.spansThatExist.push(order.spanName);
            }
        });

        const providerCircuitNames = new Set();
        const processedSpans = await this.processSpansFromOpticalDesignString(spans);

        // We need to assign a position to a new item in the topology map even if the user
        // later reassigns the value.  The new value is going to be the last item in the topologyMap + 1
        let position = Object.keys(this.state.updatedOrder.topologyMap).length === 0 ?
            1 : Math.max(...Object.keys(this.state.updatedOrder.topologyMap)) + 1;

        const newTopologyObject = {
            orderId: "",
            circuitQuantity: this.props.order.circuitQuantity,
            [Constants.TOPOLOGY_CONSTANTS.sourceSystem]: "Fremont",
            [Constants.TOPOLOGY_CONSTANTS.topologyCustomerFabric]: ""
        };

        const sitePairsToPositionMap = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const { siteA, siteZ, spanName } of processedSpans) {
            // if the span already exist in order topology, skip generating a row
            if (!this.state.spansThatExist.includes(spanName)) {
                const providerCircuitName = HelperFunctions.getProviderCircuitName(siteA, siteZ, spanName);
                providerCircuitNames.add(providerCircuitName);
                const topologyToBeAdded = {
                    ...newTopologyObject,
                    uuid: uuidv4(),
                    siteAId: this.state.siteNameToSiteIdMap[siteA],
                    siteZId: this.state.siteNameToSiteIdMap[siteZ],
                    spanName,
                    providerCircuitName
                };

                // handle parallel spans in opticalDesignString
                // if there are spans already for a site pair, add to same position, otherwise add a new position
                const sitePair = `${siteA}-${siteZ}`;
                if (Object.keys(sitePairsToPositionMap).includes(sitePair)) {
                    topology[sitePairsToPositionMap[sitePair]].push(topologyToBeAdded);
                } else {
                    sitePairsToPositionMap[sitePair] = position;
                    topology[position] = [topologyToBeAdded];
                    position += 1;
                }
            }
        }
        if (providerCircuitNames.size > 0) {
            await this.fetchExistingSpanOrders(Array.from(providerCircuitNames));
        }
        this.setState({ topologyMapFromDesignString: topology });
    }

    updateProviderCircuitName = async (circuitDesignIdList, providerCircuitName) => {
        const circuitDesignsResponse = await this.FremontBackendClient.getBatchCircuitDesignInfo(
            circuitDesignIdList, this.props.auth
        );
        const originalCircuitDesigns = circuitDesignsResponse.circuitDesigns;
        const updatedCircuitDesigns = [];
        originalCircuitDesigns.forEach(circuitDesign => updatedCircuitDesigns.push(Object.assign({
            [Constants.COMPONENT_NAMES.providerCircuitA]: providerCircuitName
        }, circuitDesign)));
        await this.FremontBackendClient.modifyProviderCircuit(updatedCircuitDesigns,
            originalCircuitDesigns, this.props.auth);
    }

    createSpanOrder = async (topologyObject) => {
        const orderToSubmit = {
            businessNeed: Constants.BUSINESS_NEED_CONSTANTS.scaling,
            contactId: this.state.contactId,
            customerFabric: Constants.CUSTOMER_FABRICS.BACKBONE_SPAN,
            orderType: Constants.ORDER_TYPES.INSTALL,
            ownerId: this.props.auth.userId,
            projectType: topologyObject.projectType,
            providerName: Constants.INTERNAL_AMAZON_PROVIDER,
            provisionerId: this.props.auth.userId,
            requiredCompletionDate: this.props.order.requiredCompletionDate,
            serviceType: Constants.SERVICE_TYPES.BACKBONE,
            siteAId: topologyObject.siteAId,
            siteZId: topologyObject.siteZId,
            spanName: topologyObject.spanName
        };

        let spanOrderId = "";
        try {
            const response = await this.FremontBackendClient.createOrder(orderToSubmit, this.props.auth);
            spanOrderId = response.orderId;
            const orderResponse = await this.FremontBackendClient.getOrderInfo(spanOrderId, this.props.auth);

            // add circuits to span order
            const updatedOrder = HelperFunctions.deepClone(orderResponse.order);
            updatedOrder[Constants.FREMONT_OBJECTS.order.circuitQuantityToAddFromRequest] =
                topologyObject.circuitQuantity.toString();
            updatedOrder[Constants.FREMONT_OBJECTS.order.portSize] = this.state.portSize;
            const order = await this.FremontBackendClient.updateOrderInfo(
                updatedOrder, orderResponse.order, this.props.auth
            );
            // add provider circuit id to span order circuits
            await this.updateProviderCircuitName(order.circuitDesignIdList, topologyObject.providerCircuitName);
            this.setState({ isSpanOrderSubmissionInProgress: false });
        } catch (error) {
            this.setState({ isSpanOrderSubmissionInProgress: false });
        }
        return spanOrderId;
    }

    /* this method performs these below steps:
        1. for createSpan button click, create a new span order, add circuits, port size to topology,
            populate span orderId to topology
        2. for useSpan, span orderId should already be populated
        2. add topology row to updatedOrder.topologyMap
        3. on submit, this will be added to topology
     */
    handleCreateOrUseSpanOrder = async (evt) => {
        this.setState({ isSpanOrderSubmissionInProgress: true });
        // we need to make an update to the topologyMap
        const order = HelperFunctions.deepClone(this.state.updatedOrder);
        const descriptors = evt.target.id.split(Constants.SEPARATOR).find(el => el.includes(Constants.UUID_SEPARATOR));
        const [position, arrayIndex, topologyProperty] = descriptors.split(Constants.UUID_SEPARATOR);

        const topologyMap = this.state.topologyMapFromDesignString;
        const newTopologyObject = topologyMap[position][arrayIndex];

        if (topologyProperty === Constants.TOPOLOGY_CONSTANTS.createSpanOrder) {
            if (!newTopologyObject.projectType) {
                this.props.handleFlashBarMessagesFromChildTabs(
                    false,
                    new Error(Constants.VALIDATION_ERROR_STRINGS.opticalTopologyMapProjectTypeMissing),
                    false
                );
                this.setState({ isSpanOrderSubmissionInProgress: false });
                return;
            }
            const spanOrderId = await this.createSpanOrder(newTopologyObject);
            newTopologyObject.orderId = spanOrderId;
        }
        if (topologyProperty === Constants.TOPOLOGY_CONSTANTS.reuseSpanOrder) {
            const orderResponse = await this.FremontBackendClient.getOrderInfo(newTopologyObject.orderId,
                this.props.auth);
            newTopologyObject.projectType = orderResponse.order.projectType;
            this.setState({ isSpanOrderSubmissionInProgress: false });
        }

        // if a span order is created with more capacity than required, we only add required quantity to path order
        if (HelperFunctions.parseInt(newTopologyObject.circuitQuantity)
            > HelperFunctions.parseInt(this.props.order.circuitQuantity)) {
            newTopologyObject.circuitQuantity = this.props.order.circuitQuantity;
        }
        this.setState({ updatedOrder: order });
    }

    checkErrors = (topologyMap) => {
        const error = {};
        Object.keys(topologyMap).forEach((topologyMapPosition) => {
            // First create an empty array
            error[topologyMapPosition] = [];

            // Calculate the total sum of all circuits in that Topology position
            const sumOfCircuits = topologyMap[topologyMapPosition]
                .reduce((sum, topologyObject) => sum + HelperFunctions.parseInt(topologyObject.circuitQuantity), 0);

            topologyMap[topologyMapPosition].forEach((topologyObject, index) => {
                error[topologyMapPosition][index] = {
                    circuitQuantity: (sumOfCircuits !== this.props.order.circuitDesignIdList.length) ?
                        Constants.VALIDATION_ERROR_STRINGS.topologyMapCircuitQuantityIncorrect : ""
                };
            });
        });

        return error;
    };

    handleTopologyRowInputChange = (evt) => {
        const topologyMap = HelperFunctions.deepClone(this.state.topologyMapFromDesignString);
        const descriptors = evt.target.id.split(Constants.SEPARATOR).find(el => el.includes(Constants.UUID_SEPARATOR));
        const [position, arrayIndex, topologyProperty] = descriptors.split(Constants.UUID_SEPARATOR);

        if (topologyProperty === Constants.ATTRIBUTES.circuitQuantity) {
            topologyMap[position][arrayIndex][topologyProperty] = evt.detail.value;
        }
        if ([Constants.ATTRIBUTES.orderId, Constants.ATTRIBUTES.projectType].includes(topologyProperty)) {
            topologyMap[position][arrayIndex][topologyProperty] = evt.detail.selectedOption.label;
        }

        const errorTexts = this.checkErrors(topologyMap);
        this.setState({ topologyMapFromDesignString: topologyMap, errorTexts });
    }


    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);

        if (this.props.stageName === Constants.STAGE_NAMES.opticalTopology) {
            this.createTopologyMapFromDesignString(this.props.order.opticalDesignString);
        }

        this.setState({
            isEditClicked: !this.state.isEditClicked,
            hasBeenSubmittedOnce: false,
            updatedOrder: HelperFunctions.deepClone(this.props.order),
            isUpdateInProgress: false
        });
    };

    handleOpticalCutsheetRowInputChange = (evt) => {
        const updatedOrder = HelperFunctions.deepClone(this.state.updatedOrder);
        const descriptors = evt.target.id.split(Constants.SEPARATOR);
        const position = descriptors[1];
        const property = descriptors[2];
        if (property === Constants.OPTICAL_CUTSHEET_CONSTANTS.remove) {
            delete updatedOrder.opticalCutsheetMap[position];
        } else {
            updatedOrder.opticalCutsheetMap[position][property] = evt.detail.value;
        }
        this.setState({ updatedOrder });
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.updatedOrder);
        input.orderErrorTexts = {};

        const output = OrderValidation.validateInput(input);

        this.setState({
            updatedOrder: output.order,
            errorTexts: output.orderErrorTexts
        });
    };

    handleStageSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, { isUpdateInProgress: true, hasBeenSubmittedOnce: true });
        // check for errors
        let throwError;
        if (this.props.stageName === Constants.STAGE_NAMES.opticalTopology) {
            const errorTexts = this.checkErrors(this.state.topologyMapFromDesignString);
            throwError = Object.keys(errorTexts).some(topologyMapPosition =>
                errorTexts[topologyMapPosition].some(topologyObject =>
                    Object.values(topologyObject).some(error => error)));
            this.setState({ errorTexts });
        } else {
            throwError = Object.values(this.state.errorTexts).some(errorText => !!errorText);
        }
        if (throwError) {
            this.props.handleFlashBarMessagesFromChildTabs(
                false,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                false
            );
            this.setState({ isUpdateInProgress: false, hasBeenSubmittedOnce: true });
            return;
        }
        try {
            if (this.props.stageName === Constants.STAGE_NAMES.opticalTopology) {
                Object.entries(this.state.topologyMapFromDesignString).forEach(([position, topologyObjects]) => {
                    this.state.updatedOrder.topologyMap[position] = topologyObjects;
                });
                // sort the topology
                this.state.updatedOrder.topologyMap = HelperFunctions.sortTopology(this.state.updatedOrder.topologyMap,
                    this.state.updatedOrder.siteAId, this.state.updatedOrder.siteZId, this.state.orderMap);
            }

            await this.FremontBackendClient.updateOrderInfo(this.state.updatedOrder, this.props.order, this.props.auth);
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            // Resets all input fields to original state if request is successful
            this.setState({
                isEditClicked: false,
                isUpdateInProgress: false,
                hasBeenSubmittedOnce: true,
                topologyMapFromDesignString: {},
                allFieldsDisabled: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isEditClicked: false,
                isUpdateInProgress: false,
                allFieldsDisabled: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (!this.state.isEditClicked ? this.getStageDisplayMode(this.props.stageName)
            : this.getStageEditMode(this.props.stageName));
    }
}