import React from "react";
import {
    Box,
    ColumnLayout,
    FormField,
    Icon,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontDatePicker,
    FremontMultiselect,
    FremontSelect,
    FremontTextarea,
    FremontToggle,
    optionalLabel,
    requiredLabel
} from "utils/CommonComponents";
import FremontComponentInfo from "common/FremontComponentInfo";
import FremontTable from "table/FremontTable";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";

const BLOCKER_STATUS_OPTIONS = [
    {
        label: "Active",
        value: Constants.STATUS.active
    },
    {
        label: "Resolved",
        value: Constants.STATUS.resolved
    }
];

export const createBlockerGroups = (circuitDesignObjects) => {
    const orderLevel = HelperFunctions.sortObjectsByField(
        HelperFunctions.createSelectedOptions(Constants.ORDER_LEVEL_BLOCKER_JEP_CODES)
    );
    const circuitLevel = HelperFunctions.sortObjectsByField(
        HelperFunctions.createSelectedOptions(Constants.CIRCUIT_LEVEL_BLOCKER_JEP_CODES)
    );

    // Conditionally add the circuit design options if there are circuits
    const circuitLevelBlockerOptionGroups = !circuitDesignObjects || circuitDesignObjects.length === 0
        ? []
        : [
            {
                label: "Circuit Blockers",
                options: circuitLevel
            }
        ];
    const orderLevelBlockerOptionGroups = [
        {
            label: "Order Blockers",
            options: orderLevel
        }
    ];

    return [
        ...circuitLevelBlockerOptionGroups,
        ...orderLevelBlockerOptionGroups
    ];
};

export const BLOCKER_NOTE_DEFINITIONS = [
    {
        id: "note",
        header: "Note",
        description: "Note Information",
        cell: item => <span className="pre-wrap">{item.noteBody}</span>,
        minWidth: 250
    },
    {
        id: "creator",
        header: "Created By",
        description: "Note created by",
        cell: item => item.createdBy,
        minWidth: 100
    },
    {
        id: "time",
        header: "Time",
        description: "Note created time",
        cell: item => HelperFunctions.formatDateAndTime(item.createdTime, Constants.YYYYDDMM_HHMMSS_FORMAT),
        minWidth: 25
    }
];

export const toggleSelectAllBlockerCircuitsId = "toggleSelectAllBlockerCircuitsId";

const renderSelectCircuitsFormField = (props) => {
    const optionsToShow =
        props.circuitDesignObjects.map(circuit =>
            ({ label: circuit.circuitDesignNumber, value: circuit.circuitDesignId }));

    const selectedOptions = props.updatedSelectedBlocker.circuitDesignIdList.map(
        circuitDesignId => ({
            label: props.circuitDesignObjects
                .filter(circuit => circuit.circuitDesignId === circuitDesignId)
                .map(circuit => circuit.circuitDesignNumber),
            value: circuitDesignId
        })
    );
    const isToggleDisabled = props.allBlockerFieldsDisabled;

    // Checked will be marked as true if all circuits get selected
    const isToggleChecked =
        selectedOptions.length === props.circuitDesignObjects.length;

    return (
        <div>
            <FormField
                label={requiredLabel("All Circuits:")}
            >
                <FremontToggle
                    id={toggleSelectAllBlockerCircuitsId}
                    onChange={props.handleBlockerModalInputChange}
                    disabled={isToggleDisabled}
                    checked={isToggleChecked}
                />
            </FormField>
            {selectedOptions.length < props.circuitDesignObjects.length &&
            <FormField
                label={requiredLabel("Choose Blocked Circuits:")}
                errorText={props.blockerErrorTexts[Constants.ATTRIBUTES.circuitDesignIdList]}
            >
                <FremontMultiselect
                    id="circuitDesignIdList"
                    options={optionsToShow}
                    disabled={props.allBlockerFieldsDisabled}
                    onChange={props.handleBlockerModalInputChange}
                    placeholder="Choose circuits"
                    selectedOptions={selectedOptions}
                    checkboxes
                    empty="No circuits have been created yet"
                />
            </FormField>}
        </div>
    );
};

export const OrderBlockerModalEditMode = props => (
    <div>
        <ColumnLayout columns={2} borders="vertical">
            <FormField
                label={requiredLabel("Blocker Code:")}
                errorText={props.blockerErrorTexts.jepCode}
            >
                <FremontSelect
                    id="jepCode"
                    disabled={props.allBlockerFieldsDisabled}
                    options={
                        createBlockerGroups(props.circuitDesignObjects)
                    }
                    onChange={props.handleBlockerModalInputChange}
                    selectedOption={HelperFunctions.createSelectedOption(
                        props.updatedSelectedBlocker.jepCode
                    )}
                />
            </FormField>
            {Constants.CIRCUIT_LEVEL_BLOCKER_JEP_CODES.includes(props.updatedSelectedBlocker.jepCode) ?
                renderSelectCircuitsFormField(props)
                :
                <div/>
            }
            <FormField
                label="Select a Provider:"
                constraintText="Use this to search for contacts."
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.providerName}
                    disabled={props.allBlockerFieldsDisabled}
                    statusType={props.providersLoading ? Constants.LOADING : Constants.FINISHED}
                    loadingText={Constants.STATUS_TEXTS.loadingProviders}
                    empty="No providers found"
                    onChange={props.handleBlockerModalInputChange}
                    options={props.providerOptions}
                    placeholder="Select a Provider"
                    filteringType="auto"
                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                    selectedOption={HelperFunctions.createSelectedOption(props.updatedSelectedBlocker.providerName)}
                />
            </FormField>
            <FormField
                label={requiredLabel("Responsible Contact:")}
                errorText={props.blockerErrorTexts.responsibleContactId}
                constraintText="Contact options shown are related to the selected provider."
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.responsibleContactId}
                    disabled={props.allBlockerFieldsDisabled || !props.updatedSelectedBlocker.providerName}
                    empty="No contacts exist for this provider."
                    statusType={props.responsibleContactLoading ? Constants.LOADING : Constants.FINISHED}
                    loadingText={Constants.STATUS_TEXTS.loadingContacts}
                    onChange={props.handleBlockerModalInputChange}
                    options={props.selectedBlockerProviderContacts}
                    placeholder={!props.updatedSelectedBlocker.providerName ?
                        "Select a provider first" : "Select a contact"}
                    filteringType="auto"
                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                    selectedOption={props.responsibleContactName ?
                        { value: props.responsibleContactName, label: props.responsibleContactName } : ""}
                />
            </FormField>
            <FormField
                label={requiredLabel("Stage:")}
                errorText={props.blockerErrorTexts[Constants.ATTRIBUTES.stageName]}
                constraintText="If selected this will block the order/circuit(s) at the selected stage."
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.stageName}
                    disabled={props.allBlockerFieldsDisabled}
                    selectedOption={{
                        value: props.updatedSelectedBlocker[Constants.ATTRIBUTES.stageName],
                        label: Constants.BACKEND_TO_FRONTEND_STAGE_MAP[
                            props.updatedSelectedBlocker[Constants.ATTRIBUTES.stageName]]
                    }}
                    options={props.stageNameOptions}
                    onChange={props.handleBlockerModalInputChange}
                />
            </FormField>
            <FormField
                label={requiredLabel("Status:")}
                errorText={props.blockerErrorTexts[Constants.ATTRIBUTES.status]}
                constraintText="To resolve this blocker, set the status to 'Resolved'."
            >
                <FremontSelect
                    id={Constants.ATTRIBUTES.status}
                    disabled={props.allBlockerFieldsDisabled}
                    selectedOption={
                        BLOCKER_STATUS_OPTIONS.find(
                            status => status.value === props.updatedSelectedBlocker[Constants.ATTRIBUTES.status]
                        )
                    }
                    options={BLOCKER_STATUS_OPTIONS}
                    onChange={props.handleBlockerModalInputChange}
                    filteringType="auto"
                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                />
            </FormField>
            <FormField
                label={requiredLabel("Blocker Start Date:")}
                errorText={props.blockerErrorTexts[Constants.ATTRIBUTES.startDate]}
            >
                <FremontDatePicker
                    id={Constants.ATTRIBUTES.startDate}
                    disabled={props.allBlockerFieldsDisabled}
                    value={props.updatedSelectedBlocker[Constants.ATTRIBUTES.startDate] || ""}
                    placeholder={Constants.DATE_PICKER_FORMAT}
                    onInput={props.handleBlockerModalInputChange}
                    onChange={props.handleBlockerModalInputChange}
                />
            </FormField>
            <FormField
                label={optionalLabel("Blocker Expiration Date:")}
            >
                <FremontDatePicker
                    id={Constants.ATTRIBUTES.expirationDate}
                    disabled={props.allBlockerFieldsDisabled}
                    value={props.updatedSelectedBlocker[Constants.ATTRIBUTES.expirationDate] || ""}
                    placeholder={Constants.DATE_PICKER_FORMAT}
                    onInput={props.handleBlockerModalInputChange}
                    onChange={props.handleBlockerModalInputChange}
                />
            </FormField>
            {Constants.STATUS.resolved === props.updatedSelectedBlocker[Constants.ATTRIBUTES.status] &&
                <FormField
                    label={requiredLabel("Blocker Completed Date:")}
                    errorText={props.blockerErrorTexts[Constants.ATTRIBUTES.completedDate]}
                >
                    <FremontDatePicker
                        id="completedDate"
                        disabled={props.allBlockerFieldsDisabled}
                        value={props.updatedSelectedBlocker.completedDate || ""}
                        placeholder={Constants.DATE_PICKER_FORMAT}
                        onInput={props.handleBlockerModalInputChange}
                        onChange={props.handleBlockerModalInputChange}
                    />
                </FormField>
            }
            <FormField
                label={optionalLabel("Description:")}
            >
                <FremontTextarea
                    id="description"
                    disabled={props.allBlockerFieldsDisabled}
                    value={props.updatedSelectedBlocker.description}
                    onInput={props.handleBlockerModalInputChange}
                />
            </FormField>
            <FormField
                label={optionalLabel("Add a note:")}
            >
                <FremontTextarea
                    id="addNote"
                    disabled={props.allBlockerFieldsDisabled}
                    value={props.newNote}
                    placeholder="Enter a note"
                    onInput={props.handleBlockerModalInputChange}
                />
            </FormField>
        </ColumnLayout>
        <Box
            padding={{ top: "m" }}
            color="text-label"
        >
            Notes:
        </Box>
        <div>
            <FremontTable
                entity="Note"
                columnDefinitions={BLOCKER_NOTE_DEFINITIONS}
                tableItems={props.selectedBlockerNotes}
                emptyTableMessage="No notes for this blocker."
            />
        </div>
    </div>
);

export const OrderBlockerModalDisplayMode = props => (
    <div>
        <ColumnLayout columns={2} borders="vertical">
            <div>
                <div>
                    <Box color="text-label">JEP Code:</Box>
                    <div>{props.selectedBlocker.jepCode}</div>
                </div>
            </div>
            <div>
                {props.selectedBlocker.circuitDesignIdList && props.selectedBlocker.circuitDesignIdList.length > 0 &&
                    <div>
                        <Box color="text-label">Circuit Design(s):</Box>
                        <div>
                            {HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.circuitDesign,
                                props.selectedBlocker.circuitDesignIdList.map((circuitId) => {
                                    const circuitDesign = props.circuitDesignObjects.find(circuit =>
                                        circuit.circuitDesignId === circuitId) || {};
                                    return {
                                        [Constants.ID_AND_DISPLAY_VALUE.ID]: circuitDesign.circuitDesignId,
                                        [Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]:
                                        circuitDesign.circuitDesignNumber
                                    };
                                }), false)}
                        </div>
                    </div>
                }
            </div>
            <div>
                <Box color="text-label">Responsible Contact:</Box>
                <div>
                    <Link href={`${Constants.ROUTES.contact}/${props.selectedBlocker.responsibleContactId}`}>
                        {props.responsibleContactName}
                    </Link>
                </div>
            </div>
            <div>
                {props.selectedBlocker[Constants.ATTRIBUTES.stageName] &&
                    <div>
                        <Box color="text-label">Stage Blocked:</Box>
                        <div>
                            <FremontButton
                                id={OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker}
                                variant="normal"
                                onClick={(evt) => {
                                    props.hideModal(evt);
                                    props.goToComponentAction(Constants.DEPENDENCY_LINK_REFERENCES[
                                        props.selectedBlocker[Constants.ATTRIBUTES.stageName]],
                                    props.order.workflow, props.selectedBlocker[Constants.ATTRIBUTES.stageName]);
                                }
                                }
                            >
                                {Constants.BACKEND_TO_FRONTEND_STAGE_MAP[
                                    props.selectedBlocker[Constants.ATTRIBUTES.stageName]]}
                            </FremontButton>
                        </div>
                    </div>
                }
            </div>
            <div>
                <Box color="text-label">Status:</Box>
                <div>{props.selectedBlocker[Constants.ATTRIBUTES.status] === Constants.STATUS.active ?
                    <span className="awsui-util-status-negative"><Icon name="status-negative"/>Active</span> :
                    <span className="awsui-util-status-positive"><Icon name="status-positive"/>Completed</span>
                }
                </div>
            </div>
            <div>
                <Box color="text-label">Start Date:</Box>
                <div>{HelperFunctions.formatDateAndTime(
                    props.selectedBlocker[Constants.ATTRIBUTES.startDate], Constants.YYYYDDMM_HHMMSS_FORMAT
                )}
                </div>
            </div>
            <div>
                {props.selectedBlocker[Constants.ATTRIBUTES.expirationDate] &&
                    <div>
                        <Box color="text-label">Expiration Date:</Box>
                        <div>{HelperFunctions.formatDateAndTime(
                            props.selectedBlocker[Constants.ATTRIBUTES.expirationDate],
                            Constants.YYYYDDMM_HHMMSS_FORMAT
                        )}
                        </div>
                    </div>
                }
            </div>
            <div>
                {props.selectedBlocker[Constants.ATTRIBUTES.completedDate] &&
                    <div>
                        <Box color="text-label">Completed Date:</Box>
                        <div>{HelperFunctions.formatDateAndTime(
                            props.selectedBlocker[Constants.ATTRIBUTES.completedDate],
                            Constants.YYYYDDMM_HHMMSS_FORMAT
                        )}
                        </div>
                    </div>
                }
            </div>
            <div>
                <Box color="text-label">Description:</Box>
                <div className="pre-wrap">{props.selectedBlocker.description}</div>
            </div>
        </ColumnLayout>
        <FremontComponentInfo
            modifiedBy={props.selectedBlocker.modifiedBy}
            modifiedTime={props.selectedBlocker.modifiedTime}
            createdBy={props.selectedBlocker.createdBy}
            createdTime={props.selectedBlocker.createdTime}
        />
        <Box
            padding={{ top: "m" }}
            color="text-label"
        >
            Notes:
        </Box>
        <div>
            <FremontTable
                entity="Note"
                columnDefinitions={BLOCKER_NOTE_DEFINITIONS}
                tableItems={props.selectedBlockerNotes}
                emptyTableMessage="No notes for this blocker."
            />
        </div>
    </div>
);
