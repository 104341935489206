import React from "react";
import {
    Container,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontDatePicker,
    FremontInput
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import FremontTable from "table/FremontTable";

export const generateBillingColumnDefinitions = editMode => [
    {
        id: Constants.ATTRIBUTES.serviceType,
        header: "Service Type",
        description: "The service type of the billing segment's circuit",
        width: 150,
        cell: item => item[Constants.ATTRIBUTES.serviceType]
    },
    {
        id: Constants.ATTRIBUTES.customerFabric,
        header: "Customer Fabric",
        description: "The customer fabric of the billing segment's circuit",
        width: 150,
        cell: item => item[Constants.ATTRIBUTES.customerFabric]
    },
    {
        id: Constants.ATTRIBUTES.orderId,
        header: "Order-ID",
        description: "The OrderId of the billing segment's circuit",
        width: 125,
        cell: item => (
            <Link
                href={`${Constants.ROUTES.order}/${item[Constants.ATTRIBUTES.orderId]}`}
            >
                {item[Constants.ATTRIBUTES.orderId]}
            </Link>
        )
    },
    {
        id: Constants.ATTRIBUTES.providerName,
        header: "Provider",
        description: "The Provider of the billing segment's circuit",
        width: 125,
        cell: item => item[Constants.ATTRIBUTES.providerName]
    },
    {
        id: Constants.ATTRIBUTES.providerCircuitName,
        sortingField: Constants.ATTRIBUTES.providerCircuitName,
        header: Constants.ATTRIBUTE_LABELS.providerCircuitName,
        description: Constants.ATTRIBUTE_LABELS.providerCircuitName,
        width: 125,
        cell: item => item[Constants.ATTRIBUTES.providerCircuitName]
    },
    {
        id: Constants.ATTRIBUTES.circuitDesignNumber,
        header: "Circuit ID",
        description: "The billing segment's circuit design number",
        width: 125,
        cell: item => (
            <Link
                href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}
            >
                {item[Constants.ATTRIBUTES.circuitDesignNumber]}
            </Link>
        )
    },
    {
        id: Constants.ATTRIBUTES.revisionNumber,
        header: "Revision Number",
        description: "The circuit design revision number",
        width: 125,
        cell: item => item[Constants.ATTRIBUTES.revisionNumber]
    },
    {
        id: Constants.ATTRIBUTES.lifeCycleStage,
        header: "Lifecycle Stage",
        description: "The circuit lifecycle stage",
        width: 125,
        cell: item => item[Constants.ATTRIBUTES.lifeCycleStage]
    },
    {
        id: Constants.ATTRIBUTES.spanId,
        header: "Span",
        description: "The span of the billing segment",
        width: 125,
        cell: item => item[Constants.ATTRIBUTES.spanId]
    },
    {
        id: Constants.ATTRIBUTES.riverMineBillingCircuitId,
        header: "Rivermine",
        description: "The billing segments rivermine circuit ID",
        width: 200,
        cell: item =>
            (editMode ?
                <FremontInput
                    id={`${Constants.ATTRIBUTES.riverMineBillingCircuitId}${Constants.SEPARATOR}${item.billingSegmentId}`}
                    onInput={item.handleBillingInputChange}
                    value={item.riverMineBillingCircuitId}
                /> : (item[Constants.ATTRIBUTES.riverMineBillingCircuitId] || ""))
    },
    {
        id: Constants.ATTRIBUTES.billingStartDate,
        header: "Start Date",
        description: "The start date of the billing segment",
        width: 200,
        cell: item =>
            (editMode ? <FremontDatePicker
                id={`${Constants.ATTRIBUTES.billingStartDate}${Constants.SEPARATOR}${item.billingSegmentId}`}
                value={item.billingStartDate === undefined ? "" : item.billingStartDate}
                placeholder={Constants.DATE_PICKER_FORMAT}
                onChange={item.handleBillingInputChange}
            /> : item[Constants.ATTRIBUTES.billingStartDate])
    },
    {
        id: Constants.ATTRIBUTES.billingEndDate,
        header: "End Date",
        description: "The end date of the billing segment",
        width: 200,
        cell: item =>
            (editMode ? <FremontDatePicker
                id={`${Constants.ATTRIBUTES.billingEndDate}${Constants.SEPARATOR}${item.billingSegmentId}`}
                value={item.billingEndDate === undefined ? "" : item.billingEndDate}
                placeholder={Constants.DATE_PICKER_FORMAT}
                onChange={item.handleBillingInputChange}
            /> : item[Constants.ATTRIBUTES.billingEndDate])
    }
];

export const BillingSearchResults = props => (
    <Container
        header={
            <Header variant="h2">
                Billing Search Result
            </Header>
        }
    >
        <SpaceBetween size="l">
            <FremontTable
                entity="Billing Segment"
                columnDefinitions={generateBillingColumnDefinitions(false)}
                tableItems={props.billingItems}
                loading={props.isQueryForBillingInProgress}
                emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noBillings}
                setSelectedItems={props.handleSelectedFromTable}
                selectedItems={props.selectedQueriedBillingSegments}
                selectionType="multi"
            />
            <FremontButton
                onClick={props.handleAddSelectedBillingToEdit}
                loading={props.isQueryForBillingInProgress || props.isUpdateInProgress}
            >
                Add Selected To Update
            </FremontButton>
        </SpaceBetween>
    </Container>
);

export const BillingsToEdit = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <FremontButton
                        onClick={props.handleSubmit}
                        loading={props.isQueryForBillingInProgress || props.isUpdateInProgress}
                        variant="primary"
                        disabled={props.billingSegmentsToEditFinalized.length === 0 || !props.editMode}
                    >
                        Submit
                    </FremontButton>
                }
            >
                Billings
            </Header>
        }

    >
        <FremontTable
            entity="Billing Segment"
            columnDefinitions={generateBillingColumnDefinitions(props.editMode)}
            loading={props.isQueryForBillingInProgress}
            tableItems={props.billingSegmentsToEditFinalized}
            emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noBillings}
            setSelectedItems={props.handleSelectedFromTableForMassUpdate}
            selectedItems={props.selectedBillingSegmentsToMassUpdate}
            selectionType="multi"
        />
    </Container>
);


export const BillingSegmentRecordForm = props => (
    <Container>
        <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
            {props.searchInformation}
            <BillingSearchResults
                isQueryForBillingInProgress={props.isQueryForBillingInProgress}
                isUpdateInProgress={props.isUpdateInProgress}
                billingItems={props.queriedBillingObjects}
                handleSelectedFromTable={props.handleSelectedFromTable}
                handleAddSelectedBillingToEdit={props.handleAddSelectedBillingToEdit}
                selectedQueriedBillingSegments={props.selectedQueriedBillingSegments}
            />
            <BillingsToEdit
                isQueryForBillingInProgress={props.isQueryForBillingInProgress}
                isUpdateInProgress={props.isUpdateInProgress}
                billingSegmentsToEditFinalized={props.billingSegmentsToEditFinalized}
                handleSubmit={props.handleSubmit}
                editMode={props.editMode}
                handleSelectedFromTableForMassUpdate={props.handleSelectedFromTableForMassUpdate}
                selectedBillingSegmentsToMassUpdate={props.selectedBillingSegmentsToMassUpdate}
            />
        </SpaceBetween>
    </Container>
);
