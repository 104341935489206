import React, { Component } from "react";
import {
    ConfirmationModal,
    FremontAlert
} from "utils/CommonComponents";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";
import generateCostOutColumnDefinitions from "order/stages/decom/CostOutInformation";
import FremontBackendClient from "common/FremontBackendClient";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";

export default class CostOutHandler extends Component {
    state = {
        updatedCircuitDesignObjects: [],
        isEditClicked: false,
        isUpdateStageInProgress: false,
        hasBeenSubmittedOnce: false,
        isCompleteCostOutModalVisible: false,
        massUpdateSelectedCircuitDesignIds: []
    };

    FremontBackendClient = new FremontBackendClient();

    generateCircuitItems = () => {
        const circuitItemsObject = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.isUpdateStageInProgress
        );

        // Use Object.assign to add custom columns for this stages circuitItemsObject. Already has circuitDesignID,
        // number, siteA, stageStatusMap, editable, hasStageSubmittedOnce, isUpdateStageInProgress

        if (circuitItemsObject.static.length > 0) {
            circuitItemsObject.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.leverA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverA
                    ),
                    [Constants.COMPONENT_NAMES.leverZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverZ
                    ),
                    [Constants.COMPONENT_NAMES.leverAExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverAInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZInternalInterface
                        )
                }));
        }

        if (circuitItemsObject.dynamic.length > 0) {
            circuitItemsObject.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.leverA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverA
                    ),
                    [Constants.COMPONENT_NAMES.leverZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverZ
                    ),
                    [Constants.COMPONENT_NAMES.leverAExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverAInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZInternalInterface
                        ),
                    numberOfItems: circuitItemsObject.dynamic.length
                }));
        }

        return circuitItemsObject;
    };

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            isUpdateStageInProgress: false,
            hasBeenSubmittedOnce: false,
            updatedCircuitDesignObjects
        });
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(this.state.massUpdateSelectedCircuitDesignIds,
            input, circuitDesignOutput.circuitDesignObjects, this.state.updatedCircuitDesignObjects);
        this.setState({ updatedCircuitDesignObjects: output });
    };

    handleSelectedFromTable = (evt) => {
        // This function is called after user check marks billing segments
        // and adds them to the edit list
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageSubmit = async (evt) => {
        // Backbone spans do not have a costOut stage so we do not have an additional logic
        // for different customer fabrics. If spans get a costOut stage then we will need
        // to add additional logic.
        if (HelperFunctions.isBackboneService(this.props.order.serviceType)) {
            this.handleStageSubmitBackbone(evt);
            return;
        }
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true,
            hasBeenSubmittedOnce: true
        });
        const circuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        // only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
        const circuitsToUpdate = HelperFunctions.createNewApiObjectsCircuitWrapperForStage(
            circuitDesignObjects, updatedCircuitDesignObjects
        );
        try {
            if (circuitsToUpdate.length > 0) {
                // update the circuits and wait for a response
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    generateCircuitsWithChanges = () => {
        const circuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);

        const circuitsToUpdate = HelperFunctions.createNewApiObjects(this.props.circuitDesignObjects,
            updatedCircuitDesignObjects, Constants.ATTRIBUTES.circuitDesignId, Constants.KEEP_KEYS.CIRCUIT);

        // only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
        return circuitsToUpdate.filter((updatedCircuitDesign) => {
            const matchingCircuitDesign = circuitDesignObjects.find(circuitDesign =>
                circuitDesign.circuitDesignId === updatedCircuitDesign.circuitDesignId);
            return matchingCircuitDesign[Constants.ATTRIBUTES.costOutStatus]
                !== updatedCircuitDesign[Constants.ATTRIBUTES.costOutStatus];
        });
    }

    handleStageSubmitBackbone = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true,
            hasBeenSubmittedOnce: true
        });
        const circuitsWithChanges = this.generateCircuitsWithChanges();
        if (circuitsWithChanges.length > 0) {
            if (circuitsWithChanges.some(circuit =>
                circuit[Constants.ATTRIBUTES.costOutStatus] === Constants.STATUS.incomplete)) {
                this.props.handleFlashBarMessagesFromChildTabs(
                    false,
                    { message: "Cannot undo cost out on Backbone circuits." },
                    false
                );
                this.setState({ isUpdateStageInProgress: false });
            } else { // The only other case is that a circuit's release port status has been changed to complete
                this.setState({ isCompleteCostOutModalVisible: true });
            }
        } else {
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    submitCircuits = async () => {
        try {
            this.setState({ isCompleteCostOutModalVisible: false });
            // update the circuits and wait for a response
            await this.FremontBackendClient.updateCircuitDesignInfo(
                this.generateCircuitsWithChanges(), this.props.auth
            );
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    }

    render() {
        return !this.state.isEditClicked ?
            <StageDisplayMode
                order={this.props.order}
                stageName={Constants.STAGE_NAMES.costOut}
                showAttachmentModal={false}
                disableEditButton={OrderValidation.disableEditButton(
                    this.generateCircuitItems().static.length,
                    this.props.isDataLoaded,
                    this.props.order,
                    this.props.editButtonsDisabled
                )}
                handleStageEditClick={this.handleStageEditClick}
                goToComponentAction={this.props.goToComponentAction}
                circuitItems={this.generateCircuitItems().static}
                content={
                    <TableDisplayMode
                        order={this.props.order}
                        stageName={Constants.STAGE_NAMES.costOut}
                        circuitItems={this.generateCircuitItems().static}
                        columnDefinitions={generateCostOutColumnDefinitions(this.props.order.serviceType)}
                        isDataLoaded={this.props.isDataLoaded}
                    />
                }
            />
            :
            <div>
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.costOut}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <TableEditMode
                            circuitItems={this.generateCircuitItems().dynamic}
                            columnDefinitions={generateCostOutColumnDefinitions(this.props.order.serviceType)}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
                <ConfirmationModal
                    isVisible={this.state.isCompleteCostOutModalVisible}
                    loading={false}
                    header="Cost Out Confirmation"
                    description={
                        <FremontAlert type="warning">
                            You are about to complete the cost out stage of a circuit. Once a circuit is cost out,
                            the previous stages (of that circuit) will no longer be editable and you can no longer
                            cancel the order. If you still have data to edit, hit cancel and modify that data.
                        </FremontAlert>
                    }
                    hideModal={() => this.setState({
                        isUpdateStageInProgress: false, isCompleteCostOutModalVisible: false
                    })}
                    cancelButtonText="Cancel"
                    primaryButtonText="Cost Out"
                    onClickFunction={this.submitCircuits}
                />
            </div>;
    }
}