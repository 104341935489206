import React, { Component } from "react";
import {
    Box,
    Grid,
    Link,
    Icon,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontInput,
    FremontSelect,
    FremontToggle
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";
import { ComponentConstants } from "../utils/CommonComponents";

export default class FremontGlobalSearch extends Component {
    state = {
        flashbar: {
            type: "",
            text: ""
        },
        globalSearchEntity: "All",
        globalSearchSubString: "",
        searchResultsLoading: false,
        advancedSearchEnabled: HelperFunctions.getAdvancedSearchCookie()
    };

    FremontBackendClient = new FremontBackendClient();

    handleSearchParamChange = (evt) => {
        const attributeId = evt.target.id;
        let inputValue;
        if (attributeId === Constants.GLOBAL_SEARCH_IDS.globalSearchEntitySelect) {
            inputValue = evt.detail.selectedOption.value;
            // Here we clear out the current subString and any search result options if the user changes the entity
            // type and the old entity type is not null
            if (inputValue !== this.state.globalSearchEntity) {
                this.setState({
                    globalSearchSubString: ""
                });
            }
            this.setState({
                globalSearchEntity: inputValue
            });
        }
        if (attributeId === Constants.GLOBAL_SEARCH_IDS.globalSearchSubStringInput) {
            if (evt.detail.selectedOption) {
                this.setState({
                    globalSearchSubString: evt.detail.selectedOption.label
                });
                // Here we obtain the base URL we will use from our map of auth config environments
                const redirectBaseUrl = HelperFunctions.getAuthConfigEnvironments()[
                    window.location.host].RedirectUriSignIn;
                // Here we construct the full objectURL. The routing specification for the url are contained within
                // evt.detail.selectedOption.value
                const objectUrl = redirectBaseUrl + evt.detail.selectedOption.value;
                // Here we open the page of the object URL in the current tab
                window.open(objectUrl, "_self", "", true);
            } else {
                // If the user did not click on one of the options, it means they are inputting text to
                // search or further filter down the search
                inputValue = evt.detail.value;
                this.setState({ globalSearchSubString: inputValue });
            }
        }
    };

    handleSearchSubmit = async () => {
        this.setState({ searchResultsLoading: true });

        // using query_string enables advancedSearch syntax
        const searchType = this.state.advancedSearchEnabled ?
            Constants.GLOBAL_SEARCH_IDS.query_string : Constants.GLOBAL_SEARCH_IDS.multi_match;
        try {
            const response = await this.FremontBackendClient.globalSearch(
                this.state.globalSearchEntity, this.state.globalSearchSubString, searchType, this.props.auth
            );

            this.props.updateSearchResults(response);

            this.props.history.push(`${Constants.ROUTES.search}`, {
                entityType: this.state.globalSearchEntity,
                subString: this.state.globalSearchSubString
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
            this.props.updateFlashbar(this.state.flashbar);
        }

        this.setState({ searchResultsLoading: false });
    }

    handleAdvancedSearchToggle = (evt) => {
        this.setState({ advancedSearchEnabled: evt.detail.checked });
        HelperFunctions.setAdvancedSearchCookie(evt.detail.checked);
    }

    determinePlaceholderText = () => {
        if (this.state.globalSearchEntity !== "asn" && this.state.globalSearchSubString.length < 3) {
            return "Enter at least three characters to search.";
        }
        return "";
    };

    render() {
        return (
            <div>
                <Grid
                    gridDefinition={[
                        { colspan: { default: 2 } },
                        { colspan: { default: 5 } },
                        { colspan: { default: 1 } },
                        { colspan: { default: 4 } }]}
                    disableGutters
                >
                    <Box padding={{ top: "xxs" }}>
                        <FremontSelect
                            id={Constants.GLOBAL_SEARCH_IDS.globalSearchEntitySelect}
                            options={Object.keys(Constants.GLOBAL_SEARCH_OBJECT_OPTIONS).map(optionKey => ({
                                value: optionKey,
                                label: Constants.GLOBAL_SEARCH_OBJECT_OPTIONS[optionKey]
                            }))}
                            selectedOption={HelperFunctions.createSelectedOption(
                                Constants.GLOBAL_SEARCH_OBJECT_OPTIONS[this.state.globalSearchEntity]
                            )}
                            disabled={this.state.searchResultsLoading}
                            onChange={this.handleSearchParamChange}
                            expandToViewport
                        />
                    </Box>
                    <Box padding={{ top: "xxs" }}>
                        <FremontInput
                            id={Constants.GLOBAL_SEARCH_IDS.globalSearchSubStringInput}
                            value={this.state.globalSearchSubString}
                            placeholder={this.determinePlaceholderText()}
                            disabled={this.state.searchResultsLoading}
                            onInput={this.handleSearchParamChange}
                            onKeyDown={evt => (evt.detail.key === "Enter" ? this.handleSearchSubmit() : {})}
                        />
                    </Box>
                    <Box padding={{ top: "xxs" }}>
                        <FremontButton
                            loading={this.state.searchResultsLoading}
                            disabled={this.state.globalSearchEntity !== "asn"
                                && this.state.globalSearchSubString.length < 3}
                            variant={this.state.globalSearchEntity !== "asn"
                                && this.state.globalSearchSubString.length < 3 ? "normal" : "primary"
                            }
                            iconName="search"
                            onClick={this.handleSearchSubmit}
                        />
                    </Box>
                    <Box padding={{ top: "xxs" }}>
                        <div className="advancedSearchToggle">
                            <FremontToggle
                                id={Constants.GLOBAL_SEARCH_IDS.globalSearchType}
                                onChange={this.handleAdvancedSearchToggle}
                                disabled={this.state.searchResultsLoading}
                                checked={this.state.advancedSearchEnabled}
                            >
                                <Link
                                    href="https://w.amazon.com/bin/view/Networking/Fremont/Customers/UserGuides#HGlobalSearch"
                                    target="_blank"
                                >
                                    <div>
                                        <SpaceBetween
                                            direction="horizontal"
                                            size={ComponentConstants.SPACE_BETWEEN_LINK_PADDING}
                                        >
                                            <span>Advanced</span>
                                            <Icon name="external" variant="link"/>
                                        </SpaceBetween>
                                    </div>
                                </Link>
                            </FremontToggle>
                        </div>
                    </Box>
                </Grid>
            </div>
        );
    }
}