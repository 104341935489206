import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Link,
    Tabs,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontSelect
} from "utils/CommonComponents";
import AttachmentTab from "attachment/AttachmentTab";
import AuditTab from "audit/AuditTab";
import Constants from "utils/Constants";
import CircuitDesignCustomize from "circuitDesign/CircuitDesignCustomize";
import HelperFunctions from "common/HelperFunctions";
import NoteTab from "note/NoteTab";
import OrderTable from "order/OrderTable";

export const displayAdditionalCircuitDesigns = (circuitDesign, additionalCircuitDesigns, additionalCircuitType) => {
    if (additionalCircuitType === Constants.ATTRIBUTES.consumedByCircuitId && !circuitDesign.consumedByCircuitId) {
        return "None";
    }
    if (additionalCircuitType === Constants.ATTRIBUTES.consumingCircuitsIdList
        && circuitDesign.consumingCircuitsIdList.length === 0) {
        return "None";
    }
    // This is the case where the circuit does have other circuits but they aren't loaded
    // yet. While we wait for them to load, we display "-"
    if (additionalCircuitDesigns.length === 0) {
        return "-";
    }
    HelperFunctions.sortCircuitDesigns(additionalCircuitDesigns);
    // Here we generate an array that contains the ID and display values used to generate the links for the
    // consumingCircuitDesign objects
    const idAndDisplayValueArray = additionalCircuitDesigns.map(additionalCircuitDesign => ({
        [Constants.ID_AND_DISPLAY_VALUE.ID]:
            additionalCircuitDesign[Constants.ATTRIBUTES.circuitDesignId],
        [Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]:
            additionalCircuitDesign[Constants.ATTRIBUTES.circuitDesignNumber]
    }));
    return HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.circuitDesign, idAndDisplayValueArray,
        false);
};
export const renderPathName = ({
    circuitDesign,
    componentIdToObjectMap
}) => (
    circuitDesign[Constants.ATTRIBUTES.serviceType]
    === Constants.SERVICE_TYPES.BACKBONE && Constants.PATH_CUSTOMER_FABRICS.includes(
        circuitDesign[Constants.ATTRIBUTES.customerFabric]
    ) &&
    <div>
        <Box color="text-label">Path Name:</Box>
        <div>{!componentIdToObjectMap[circuitDesign.pathId] ? "-"
            : componentIdToObjectMap[circuitDesign.pathId][Constants.ATTRIBUTES.pathName]
        }
        </div>
    </div>
);

export const getSpan = (componentIdToObjectMap) => {
    const keys = Object.keys(componentIdToObjectMap).filter((property) => {
        if (componentIdToObjectMap[property].spanId) {
            return true;
        }
        return false;
    });
    if (keys.length) {
        return componentIdToObjectMap[keys[0]];
    }
    return null;
};

export const getWorkOrder = (componentIdToObjectMap) => {
    const keys = Object.keys(componentIdToObjectMap).filter((property) => {
        if (componentIdToObjectMap[property].spanId) {
            return true;
        }
        return false;
    });
    if (keys.length) {
        return componentIdToObjectMap[componentIdToObjectMap[keys[0]][Constants.ATTRIBUTES.workOrderId]];
    }
    return null;
};
export const CircuitDesignDetailsPageHeader = props => (
    (
        <div>
            <Container header={<Header variant="h2">{props.circuitDesign.circuitDesignNumber}</Header>}>
                <ColumnLayout columns={4}>
                    <div>
                        <Box color="text-label">Provider Name:</Box>
                        <div>
                            <Link href={`${Constants.ROUTES.provider}/${props.circuitDesign[Constants.ATTRIBUTES.providerName]}`}>
                                {props.circuitDesign[Constants.ATTRIBUTES.providerName]}
                            </Link>
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">Service Type:</Box>
                        <div>{props.circuitDesign[Constants.ATTRIBUTES.serviceType]}{
                            props.circuitDesign[Constants.ATTRIBUTES.customerFabric]
                            && ` : ${props.circuitDesign[Constants.ATTRIBUTES.customerFabric]}`}
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">Lifecycle Stage:</Box>
                        <div>{props.circuitDesign[Constants.ATTRIBUTES.lifeCycleStage]}</div>
                    </div>
                    <div>
                        <Box color="text-label">Revision:</Box>
                        {props.circuitDesignRevisionOptions.length > 1 ?
                            <div>
                                <FremontSelect
                                    options={props.circuitDesignRevisionOptions}
                                    selectedOption={props.circuitDesignRevisionSelectedOption}
                                    onChange={props.handleRevisionChange}
                                />
                            </div> : <div>{props.circuitDesign[Constants.ATTRIBUTES.revisionNumber]}</div>
                        }
                    </div>
                    {renderPathName(props)}
                    <div>
                        <Box color="text-label">Order:</Box>
                        <div>
                            <Link href={`${Constants.ROUTES.order}/${props.circuitDesign[Constants.ATTRIBUTES.orderId]}`}>
                                {props.circuitDesign[Constants.ATTRIBUTES.orderId]}
                            </Link>
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">Active Order Stage(s):</Box>
                        <div>{HelperFunctions.displayCurrentInProgressStages(
                            props.circuitDesignsCurrentOrder
                        )}
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">Active Circuit Stage(s):</Box>
                        <div>{HelperFunctions.displayCurrentInProgressStages(props.circuitDesign)}</div>
                    </div>
                    <div>
                        <Box color="text-label">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>Consuming Span Circuit(s):</span>
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.consumingCircuitIdListExplanation)
                                }
                            </SpaceBetween>
                        </Box>
                        <div>
                            {displayAdditionalCircuitDesigns(
                                props.circuitDesign, props.consumingCircuitDesigns,
                                Constants.ATTRIBUTES.consumingCircuitsIdList
                            )}
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>Consumed by Path Circuit:</span>
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.consumedByCircuitIdExplanation)
                                }
                            </SpaceBetween>
                        </Box>
                        <div>
                            {displayAdditionalCircuitDesigns(
                                props.circuitDesign,
                                Object.keys(props.consumedByCircuitDesign).length === 0 ?
                                    [] : [props.consumedByCircuitDesign],
                                Constants.ATTRIBUTES.consumedByCircuitId
                            )}
                        </div>
                    </div>
                    {Object.keys(props.consumedByCircuitDesignForNewRevision).length > 0 &&
                    <div>
                        <Box color="text-label">
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                <span>Consumed by Circuit for New Revision:</span>
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.consumedByCircuitIdForNewRevisionExplanation)
                                }
                            </SpaceBetween>
                        </Box>
                        <div>
                            {displayAdditionalCircuitDesigns(
                                props.circuitDesign,
                                [props.consumedByCircuitDesignForNewRevision],
                                Constants.ATTRIBUTES.consumedByCircuitIdForNewRevision
                            )}
                        </div>
                    </div>
                    }
                    {Constants.SPAN_CUSTOMER_FABRICS.includes(props.circuitDesign.customerFabric) &&
                    <div>
                        <Box color="text-label">Actual Delivery Date:</Box>
                        <div>
                            {props.circuitDesign[Constants.ATTRIBUTES.actualDeliveryDate] || "-"}
                        </div>
                    </div>
                    }
                    <div>
                        <Box color="text-label">Bandwidth:</Box>
                        <div>{props.circuitDesign[Constants.ATTRIBUTES.circuitBandwidth]}</div>
                    </div>
                    <div>
                        <Box color="text-label">Last Updated:</Box>
                        <div>{props.circuitDesign[Constants.ATTRIBUTES.modifiedTime]}</div>
                    </div>
                    <div>
                        <Box color="text-label">Contract Central URL:</Box>
                        {
                            getWorkOrder(props.componentIdToObjectMap) &&
                            <div>
                                <Link
                                    href={
                                        getWorkOrder(
                                            props.componentIdToObjectMap
                                        )[Constants.ATTRIBUTES.contractCentralUrl]
                                    }
                                    target="_blank"
                                >
                                    <span>{
                                        getWorkOrder(
                                            props.componentIdToObjectMap
                                        )[Constants.ATTRIBUTES.contractCentralUrl]}
                                    </span>
                                </Link>
                            </div>
                        }
                    </div>
                    <div>
                        <Box color="text-label">Currency:</Box>
                        <div>{getWorkOrder(props.componentIdToObjectMap) ?
                            getWorkOrder(props.componentIdToObjectMap)[Constants.ATTRIBUTES.currency] : "-"}
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">NRC:</Box>
                        <div>{getSpan(props.componentIdToObjectMap) ?
                            getSpan(props.componentIdToObjectMap)[Constants.ATTRIBUTES.nrc] : "-"}
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">MRC:</Box>
                        <div>{getSpan(props.componentIdToObjectMap) ?
                            getSpan(props.componentIdToObjectMap)[Constants.ATTRIBUTES.mrc] : "-"}
                        </div>
                    </div>
                </ColumnLayout>
            </Container>
        </div>
    )
);

export const CircuitDesignDetailsPageTabs = (props) => {
    const tabs = [
        {
            label: "Details",
            id: Constants.CIRCUIT_DESIGN_TAB_IDS.DESIGN_TAB_ID,
            content: <CircuitDesignCustomize
                auth={props.auth}
                circuitDesign={props.circuitDesign}
                editMode={props.editMode}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                componentIdToObjectMap={props.componentIdToObjectMap}
            />
        },
        {
            // TODO: Having a table for just one order is kinda weird
            label: "Order",
            id: Constants.CIRCUIT_DESIGN_TAB_IDS.ORDERS_TAB_ID,
            content: <OrderTable
                showOnlyHeaderDescription
                loading={props.loadingAllOrders}
                fetchAllOrderItems={props.fetchAllOrderItems}
                items={[props.circuitDesignsCurrentOrder]}
                tablePageSize={Constants.PAGINATION_LIMIT}
                searchTitle="Search in All Circuit Orders"
                emptyTableMessage={<Header variant="h2">No orders currently exist for this circuit.</Header>}
                emptySearchMessage={
                    <Header variant="h2">
                        The current search input does not match any orders in Fremont.
                    </Header>
                }
            />
        },
        {
            label: "Circuit Notes",
            id: Constants.CIRCUIT_DESIGN_TAB_IDS.NOTES_TAB_ID,
            content: <NoteTab
                tableName="circuitDesign"
                type="circuitDesign"
                entityId={props.circuitDesign.circuitDesignId}
                name={props.circuitDesign.circuitDesignNumber}
                noteIdList={props.circuitDesign.noteIdList}
                auth={props.auth}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
            />
        },
        {
            label: "Attachments",
            id: Constants.CIRCUIT_DESIGN_TAB_IDS.ATTACHMENTS_TAB_ID,
            content: <AttachmentTab
                auth={props.auth}
                orderAttachments={props.circuitAttachments}
                orderAttachmentsLoading={props.circuitAttachmentsLoading}
                orderId={props.circuitDesign.circuitDesignId}
                downloadAttachment={props.downloadAttachment}
                isDownloadingAttachment={props.isDownloadingAttachment}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                orderCompleted={props.orderCompleted}
                disableAddAttachmentsButton
                circuitDesignAttachments
            />
        },
        {
            label: "Audit Trail",
            id: Constants.CIRCUIT_DESIGN_TAB_IDS.AUDIT_TAB_ID,
            content: <AuditTab
                type="circuit"
                auditIdList={props.auditIdList}
                order={props.circuitDesignsCurrentOrder}
                circuitDesign={props.circuitDesign}
                auth={props.auth}
                user={props.user}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
            />
        }
    ];
    return (
        <div className="col-l-12">
            <Tabs
                tabs={tabs}
                variant="container"
                activeTabId={props.activeTabId}
                onChange={props.handleTabChange}
            />
        </div>
    );
};
