import React, { Component } from "react";
import BulkUpdateTabInformation from "operations/BulkUpdateTabInformation";
import {
    Flashbar
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton
} from "utils/CommonComponents";
import AttachmentHandler from "attachment/AttachmentHandler";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";

/**
 * BulkUpdateTab is used to display the bulk update tab table.
 */
class BulkUpdateTab extends Component {
    static BULK_UPDATE_COLUMN_DEFINITIONS = [
        {
            id: "jobId",
            header: "Job Id",
            cell: item => item.jobId
        },
        {
            id: "jobDescription",
            header: "Job Description",
            cell: item => item.jobDescription
        },
        {
            id: "status",
            header: "Status",
            cell: item => item.jobStatus
        },
        {
            id: "tableName",
            header: "Table",
            cell: item => item.tableName
        },
        {
            id: "jobHistoryList",
            header: "Number of Jobs",
            cell: item => item.jobHistoryList.length
        },
        {
            id: "latestRunRowResult",
            header: "Row Result (latest)",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => item.jobHistoryList.length > 0 && item.jobHistoryList[item.jobHistoryList.length - 1].s3Key
        },
        {
            id: "latestRunFailure",
            header: "Failure Message (latest)",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => item.jobHistoryList.length > 0
                && item.jobHistoryList[item.jobHistoryList.length - 1].errorMessage
        },
        {
            id: "fieldNames",
            header: "Fields Modified",
            cell: item => (!item.fieldNames ? "" : item.fieldNames.toString())
        },
        {
            id: "createdBy",
            header: "Created By",
            cell: item => item.createdBy
        },
        {
            id: "createdTime",
            header: "Created Time",
            cell: item => item.createdTime
        },
        {
            id: "retry",
            header: "Retry Job",
            cell: item => (
                <FremontButton
                    variant="icon"
                    iconName="refresh"
                    disabled={Constants.JOB_STATUS.SUCCESS === item.jobStatus}
                    onClick={() => item.retryJob(item.jobId, item.attachmentId)}
                />
            )
        },
        {
            id: "download",
            header: "Download",
            cell: item => (
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item.attachmentId)}
                />
            )
        }
    ];

    state = {
        jobModalIsVisible: false,
        bulkUpdateJobsWithDownloadAttachments: [],
        allJobItems: [],
        loading: false
    };

    componentDidMount = async () => {
        await this.reloadAllJobs();
    };

    /**
     * This method is used for fetching all of the contact information
     */
    getAllJobs = async (resetItems) => {
        try {
            this.setState({ loading: true });
            const nextToken = resetItems ? null : this.state.nextJobToken;
            const response = await this.FremontBackendClient.getAllJobsInfo(nextToken, this.props.auth);

            const tableItems = resetItems ? [] : [...this.state.allJobItems];
            tableItems.push(...response.jobs);
            HelperFunctions.sortObjectsByField(tableItems, "modifiedTime", true);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                allJobItems: tableItems,
                loading: false,
                nextJobToken: response.nextToken
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    getBulkUpdateDescription = () => this.state.jobDescription

    reloadAllJobs = async () => {
        await this.getAllJobs(true);
        this.addDownloadAndRetryToBulkUpdateJobs(this.state.allJobItems, false);
    };

    showAttachmentModal = () => {
        this.setState({
            jobModalIsVisible: true
        });
    };

    hideAttachmentModal = () => {
        this.setState({
            jobModalIsVisible: false
        });
    };

    FremontBackendClient = new FremontBackendClient();

    downloadAttachment = async (attachmentId) => {
        // this.setState({ isDownloadingAttachment: true });
        try {
            const getAttachmentResponse = await this.FremontBackendClient.getAttachmentInfo(attachmentId,
                this.props.auth);

            // I wanted to use XMLHttpRequest, since that is what we use to upload the files, but even the presence of
            // the Content-Disposition didn't help in downloading the file. So the workaround here is creating an <a>
            // element and "clicking" it to download in the browser. The <a> element is simply an anchor element that
            // contains and href element. We assign our presigned url as the href of this newly created element and
            // thats all it takes to download it.
            // More on it not working with XMLHttpRequest: https://stackoverflow.com/a/22738657
            fetch(getAttachmentResponse.presignedUrl).then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement("a");
                    anchorElement.href = url;
                    anchorElement.download = getAttachmentResponse.attachment.fileName;
                    anchorElement.click();
                    anchorElement.remove();
                });
            });
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    addDownloadAndRetryToBulkUpdateJobs = (bulkUpdateJobs, isDownloadingAttachment) => {
        const bulkUpdateJobsClone = [];
        bulkUpdateJobs.forEach((bulkUpdateJob) => {
            const bulkUpdateJobClone = HelperFunctions.deepClone(bulkUpdateJob);
            bulkUpdateJobClone.isDownloadingAttachment = isDownloadingAttachment;
            bulkUpdateJobClone.downloadAttachment = this.downloadAttachment;
            bulkUpdateJobClone.retryJob = this.retryJob;
            bulkUpdateJobsClone.push(bulkUpdateJobClone);
        });
        this.setState({ bulkUpdateJobsWithDownloadAttachments: bulkUpdateJobsClone });
    };

    handleBulkUpdateTableType = (evt) => {
        this.setState({
            bulkUpdateTableType: evt.detail.selectedOption.value
        });
    };

    handleBulkUpdateDescription = (evt) => {
        this.setState({
            jobDescription: evt.detail.value
        });
    };

    retryJob = async (jobId) => {
        this.setState({
            loading: true
        });
        const jobToRetry = this.state.bulkUpdateJobsWithDownloadAttachments
            .filter(job => job.jobId === jobId)
            .find(Boolean);
        await this.handleSubmitBulkUpdateJob("", jobToRetry);
        this.setState({
            loading: false
        });
    };

    handleSubmitBulkUpdateJob = async (attachmentId, job) => {
        let jobResponse;
        try {
            let jobToSubmit;
            if (job) {
                if (!job.attachmentId) {
                    Object.assign(job, { attachmentId });
                }
                jobToSubmit = job;
            } else {
                jobToSubmit = {
                    jobType: "BulkUpdate",
                    tableName: this.state.bulkUpdateTableType,
                    jobDescription: this.state.jobDescription
                };
            }

            // Submit the job and reload all the jobs
            jobResponse = await this.FremontBackendClient.modifyJobs([jobToSubmit], this.props.auth);
            await this.reloadAllJobs();
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
        return jobResponse;
    };

    render() {
        return (
            <div className="col-l-12">
                <Flashbar
                    items={
                        [{
                            type: "warning",
                            dismissible: false,
                            content:
                                React.createElement("span", {},
                                    "If you're running a bulk update job in prod, go through the checklist below:",
                                    React.createElement("ol",
                                        {},
                                        React.createElement("li", {},
                                            "If you are performing an update on more than 2,000 records"
                                            + " suppress all table alarms for the affected tables."),
                                        React.createElement("li", {},
                                            "Create an operations ticket (if not already there)"
                                            + " and attach the bulk update csv and query to it. If the query is"
                                            + " complex, be sure to create a CR in FremontDocs and get it approved"
                                            + " before running the bulk update."),
                                        React.createElement("li", {},
                                            "Once the job is done running, be sure to update the"
                                            + " job description manually in DynamoDB.")))
                        }]
                    }
                />
                <BulkUpdateTabInformation
                    disableAddBulkUpdateButton={this.state.loading}
                    showAttachmentModal={this.showAttachmentModal}
                    loading={this.state.loading}
                    jobs={this.state.bulkUpdateJobsWithDownloadAttachments}
                    emptyTableMessage="No jobs exist in Fremont."
                />
                {this.state.jobModalIsVisible &&
                <AttachmentHandler
                    attachments={[]}
                    entityType={Constants.BULK_UPDATE_JOB_ENTITY_TYPE}
                    isAttachmentModalClicked
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                    hideAttachmentModal={this.hideAttachmentModal}

                    // bulk update specific props
                    handleBulkUpdateTableType={this.handleBulkUpdateTableType}
                    handleBulkUpdateDescription={this.handleBulkUpdateDescription}
                    getBulkUpdateDescription={this.getBulkUpdateDescription}
                    bulkUpdateTableType={this.state.bulkUpdateTableType}
                    handleSubmitBulkUpdateJob={this.handleSubmitBulkUpdateJob}
                />
                }
            </div>
        );
    }
}

export default BulkUpdateTab;