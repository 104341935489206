import React, { Component } from "react";
import FILTER_STAGE_COLUMN_DEFINITIONS from "order/stages/decom/FilterInformation";
import FremontBackendClient from "common/FremontBackendClient";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";

export default class FilterHandler extends Component {
    state = {
        updatedCircuitDesignObjects: [],
        isEditClicked: false,
        isUpdateStageInProgress: false,
        hasBeenSubmittedOnce: false,
        massUpdateSelectedCircuitDesignIds: []
    };

    FremontBackendClient = new FremontBackendClient();

    generateCircuitItems = () => {
        const circuitItemsObject = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.isUpdateStageInProgress
        );

        // Use Object.assign to add custom columns for this stages circuitItemsObject. Already has circuitDesignID,
        // number, siteA, stageStatusMap, editable, hasStageSubmittedOnce, isUpdateStageInProgress

        if (circuitItemsObject.static.length > 0) {
            circuitItemsObject.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    )
                }));
        }

        if (circuitItemsObject.dynamic.length > 0) {
            circuitItemsObject.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    numberOfItems: circuitItemsObject.dynamic.length
                }));
        }

        return circuitItemsObject;
    };

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            isUpdateStageInProgress: false,
            hasBeenSubmittedOnce: false,
            updatedCircuitDesignObjects
        });
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(this.state.massUpdateSelectedCircuitDesignIds,
            input, circuitDesignOutput.circuitDesignObjects, this.state.updatedCircuitDesignObjects);
        this.setState({ updatedCircuitDesignObjects: output });
    };

    handleSelectedFromTable = (evt) => {
        // This function is called after user check marks billing segments
        // and adds them to the edit list
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true,
            hasBeenSubmittedOnce: true
        });
        const circuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        // only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
        const circuitsToUpdate = HelperFunctions.createNewApiObjectsCircuitWrapperForStage(
            circuitDesignObjects, updatedCircuitDesignObjects
        );
        try {
            if (circuitsToUpdate.length > 0) {
                // update the circuits and wait for a response
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.filter}
                    showAttachmentModal={false}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    content={
                        <TableDisplayMode
                            order={this.props.order}
                            stageName={Constants.STAGE_NAMES.filter}
                            circuitItems={this.generateCircuitItems().static}
                            columnDefinitions={FILTER_STAGE_COLUMN_DEFINITIONS}
                            isDataLoaded={this.props.isDataLoaded}
                        />
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.filter}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <TableEditMode
                            circuitItems={this.generateCircuitItems().dynamic}
                            columnDefinitions={FILTER_STAGE_COLUMN_DEFINITIONS}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
        );
    }
}