import React, { Component } from "react";
import {
    Box,
    SpaceBetween,
    Table, Header
} from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import IspPatchPanelTableData from "./IspPatchPanelTableData";
import { LightHouseButton } from "../components/CommonComponents";

export default class IspPatchPanelTable extends Component {
    state = {
    }

    render() {
        return (
            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_BUTTON_PADDING}>
                <Table
                    columnDefinitions={IspPatchPanelTableData.COLUMN_DEFINITIONS}
                    items={this.props.items}
                    loadingText="Loading resources"
                    onSelectionChange={event => this.props.handleSelectedItems(event)}
                    selectedItems={this.props.selectedItems}
                    selectionType="multi"
                    trackBy={Constants.PATCH_PANEL_CONFIG_FIELDS.ID}
                    resizableColumns
                    empty={
                        <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                        >
                            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                                <b>No ISP Patch Panels to display</b>
                            </SpaceBetween>
                        </Box>
                    }
                    header={
                        <Header
                            actions={
                                <LightHouseButton
                                    disabled={this.props.deleteDisabled}
                                    loading={this.props.deleteInProgress}
                                    onClick={this.props.deleteConfigs}
                                    variant="primary"
                                >
                                    Delete Patch Panels
                                </LightHouseButton>}
                        >
                            {this.props.header}
                        </Header>}
                />
            </SpaceBetween>
        );
    }
}
