import React, { Component } from "react";
import {
    Box,
    Tabs
} from "@amzn/awsui-components-react/polaris";
import {
    ConfirmationModal,
    FremontAlert
} from "utils/CommonComponents";
import AsnTab from "asn/AsnTab";
import AuditTab from "audit/AuditTab";
import ContactTab from "contact/ContactTab";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import HelperFunctions from "common/HelperFunctions";
import NoteTab from "note/NoteTab";
import OrderTab from "order/OrderTab";
import ProviderChildrenTab from "provider/ProviderChildrenTab";
import ProviderInformation from "provider/ProviderInformation";
import ProviderServiceTab from "providerService/ProviderServiceTab";
import ProviderValidation from "provider/ProviderValidation";
import SiteTab from "site/SiteTab";
import { withRouter } from "react-router-dom";

/**
 * ProviderDetailsPage acts as the provider info landing page and displays all
 * of the provider information and its related info.
 */
class ProviderDetailsPage extends Component {
    static PROVIDER_INFO_TAB_ID = "details";
    static PROVIDER_CHILDREN_TAB_ID = "providerChildren";
    static PROVIDER_CONTACTS_TAB_ID = "providerContacts";
    static PROVIDER_ASN_TAB_ID = "providerAsns";
    static PROVIDER_SITES_TAB_ID = "providerSites";
    static PROVIDER_SERVICES_TAB_ID = "providerServices";
    static PROVIDER_ORDER_TAB_ID = "providerOrders";
    static PROVIDER_NOTE_TAB_ID = "providerNotes";
    static PROVIDER_AUDIT_TAB_ID= "providerAudits";

    state = {
        activeTabId: "details",
        flashbar: {
            type: "",
            text: ""
        },
        loading: true,
        isSpinnerShown: true,
        providersLoading: true,
        providerOptions: [],
        providersDisabled: false,
        provider: {},
        updatedProvider: {},
        hasUpdateProviderSubmittedOnce: false,
        hasModifiedParentProviderName: false,
        isUpdateProviderInfoEditClicked: false,
        isUpdateProviderInfoInProgress: false,
        providerErrorTexts: {},
        bizOpsResources: [],
        bizOpsLoading: false,
        asnIdList: [],
        isModalVisible: false
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchProviderInfo();
            document.title = `${this.state.provider.providerName}`;
            await this.getBizOps();
        }
    };

    componentWillUnmount = () => {
        document.title = "Lighthouse";
    }

    /**
     * This function fetches every provider item that currently exists in Fremont so that the user can choose from
     * a list of providers rather than trying to manually type in the name. The function runs in the background
     * so it does not make the page loading slower
     */
    getAllProviderItems = async () => {
        // We only make a backend call to obtain the providerOptions if they do not already exist
        if (this.state.providerOptions.length === 0) {
            this.setState({ providersLoading: true, providersDisabled: false });
            try {
                const providerOptions = HelperFunctions.addNoneSelectedOption(
                    await HelperFunctions.getAllProviderItems(this.props.auth)
                );

                this.setState({ providerOptions, providersLoading: false });
            } catch (error) {
                HelperFunctions.displayFlashbarError(this, error, { providersDisabled: true, providersLoading: false });
            }
        }
    };

    getBizOps = async () => {
        // the following if statement is only for dev environment.
        // Its basically a dummy value that will be returned for the dropdown field ONLY when we are in
        // localhost, as we cannot get a return value for getResourceNamesBasedOffResourceType due to the fact it is a
        // BRASS related API and wont work for your local stack.
        if (window.location.host.includes(Constants.LOCAL_HOST_URL)) {
            this.setState({
                bizOpsResources: ["localhost_BizOps"]
            });
            return;
        }
        this.setState({ bizOpsLoading: true });
        try {
            const response = await this.FremontBackendClient.getResourceNamesBasedOffResourceType(
                Constants.RESOURCE_TYPES.bizops,
                this.props.auth
            );

            this.setState({
                bizOpsResources: response.resourceNames,
                bizOpsLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error,
                { allFieldsDisabled: true, bizOpsLoading: false });
        }
    }

    FremontBackendClient = new FremontBackendClient();

    /**
     **************************************************************************************************
     * DATA LOADING
     **************************************************************************************************
     */

    /**
     * This method is used for fetching all of the provider information
     */
    fetchProviderInfo = async () => {
        try {
            this.setState({ loading: true });
            const providerResponse = await this.FremontBackendClient.getProviderInfo(
                this.props.match.params.providerName, this.props.auth
            );
            // also need to fetch provider services for asn Ids for the ASN tab
            const providerServiceResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE,
                Array.from(providerResponse.provider.providerServiceIdList),
                this.props.auth
            );
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                provider: providerResponse.provider,
                asnIdList: providerServiceResponse.providerServices.flatMap(service => service.asnIdList),
                loading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loading: false });
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, { loading: false });
    };

    /**
     * Changes the provider info container to edit mode, edit the request object
     */
    handleUpdateProviderEdit = async () => {
        this.handleFlashbarClose();
        if (!this.state.isUpdateProviderInfoEditClicked) {
            this.setState({
                hasUpdateProviderSubmittedOnce: false,
                providerErrorTexts: {},
                updatedProvider: HelperFunctions.deepClone(this.state.provider),
                loading: false
            });
            this.getAllProviderItems();
        }
        this.setState({
            isUpdateProviderInfoEditClicked: !this.state.isUpdateProviderInfoEditClicked
        });
    };


    /**
     * This function handles change of inputs to the provider information container fields.
     */
    handleUpdateProviderInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        // fetch changed entity
        input.provider = HelperFunctions.deepClone(this.state.updatedProvider);
        input.providerErrorTexts = HelperFunctions.deepClone(this.state.providerErrorTexts);
        input.providerOptions = HelperFunctions.deepClone(this.state.providerOptions);
        input.hasModifiedParentProviderName = this.state.hasModifiedParentProviderName;

        const output = ProviderValidation.validateInput(input);

        // Have an additional check here to make sure the name put for parent provider is not the same as
        // the provider name itself (you cannot be your own parent, which incidentally is also Parenting 101)
        if (output.provider.parentProviderName === output.provider.providerName) {
            output.providerErrorTexts.parentProviderName = Constants.ERROR_STRINGS.providerAsParentProvider;
        }

        this.setState({
            updatedProvider: output.provider,
            providerErrorTexts: output.providerErrorTexts,
            hasModifiedParentProviderName: output.hasModifiedParentProviderName
        });
    };

    /**
     * Send updated provider info to backend when the submit button is clicked
     */
    handleUpdateProviderSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, {});

        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.providerErrorTexts).some(errorText => !!errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    hasUpdateProviderSubmittedOnce: true,
                    isUpdateProviderInfoInProgress: false
                }
            );
            return;
        }

        // If we are trying to add a parent to this provider, and the provider has children, show a modal
        if (this.state.updatedProvider[Constants.ATTRIBUTES.parentProviderName]
            && this.state.provider[Constants.ATTRIBUTES.childProviderNameList].length > 0) {
            this.setState({ isModalVisible: true });
        } else {
            // Run the function to submit the provider if the provider doesn't have any children
            await this.submitProviderUpdate();
        }
    };

    submitProviderUpdate = async () => {
        try {
            this.setState({ isUpdateProviderInfoInProgress: true });

            const response = await this.FremontBackendClient.updateProviderInfo(this.state.updatedProvider,
                this.props.auth);

            // provider services for asn Ids for the ASN tab because they might have changed
            const providerServiceResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE,
                Array.from(response.providerServiceIdList),
                this.props.auth
            );

            // Resets all input fields to original state if request is successful
            HelperFunctions.displayFlashbarSuccess(this, Constants.FLASHBAR_STRINGS.flashbarSuccessText, {
                isUpdateProviderInfoEditClicked: false,
                isUpdateProviderInfoInProgress: false,
                hasUpdateProviderSubmittedOnce: false,
                provider: response,
                asnIdList: providerServiceResponse.providerServices.flatMap(service => service.asnIdList),
                isModalVisible: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                hasUpdateProviderSubmittedOnce: true,
                isUpdateProviderInfoInProgress: false,
                isModalVisible: false
            });
        }
    }

    hideModal = () => {
        this.setState({
            isModalVisible: false,
            isUpdateProviderInfoInProgress: false
        });
    }

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     **************************************************************************************************
     * TAB CHANGE HANDLERS
     **************************************************************************************************
     */

    /**
     This function handles changing the active tab on the Tabs component, and resets the flashbar.
     */
    handleTabChange = (evt) => {
        HelperFunctions.dismissFlashbar(this, { activeTabId: evt.detail.activeTabId });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        }
    };

    /**
     This function updates the tabs of the provider details page
     */
    updateTabs = () => (
        [
            {
                label: "Details",
                id: ProviderDetailsPage.PROVIDER_INFO_TAB_ID,
                content: <ProviderInformation
                    provider={this.state.provider}
                    updatedProvider={this.state.updatedProvider}
                    providerOptions={this.state.providerOptions}
                    providersLoading={this.state.providersLoading}
                    providersDisabled={this.state.providersDisabled}
                    isUpdateProviderInfoEditClicked={this.state.isUpdateProviderInfoEditClicked}
                    isUpdateProviderInfoInProgress={this.state.isUpdateProviderInfoInProgress}
                    providerErrorTexts={this.state.hasUpdateProviderSubmittedOnce ?
                        this.state.providerErrorTexts : {}}
                    parentProviderErrorTexts={this.state.hasUpdateProviderSubmittedOnce
                        || this.state.hasModifiedParentProviderName
                        ? this.state.providerErrorTexts.parentProviderName : ""}
                    handleUpdateProviderEdit={this.handleUpdateProviderEdit}
                    handleUpdateProviderInputChange={this.handleUpdateProviderInputChange}
                    handleUpdateProviderSubmit={this.handleUpdateProviderSubmit}
                    bizOpsLoading={this.state.bizOpsLoading}
                    bizOpsResources={this.state.bizOpsResources}
                />
            },
            {
                label: "Provider Sites",
                id: ProviderDetailsPage.PROVIDER_SITES_TAB_ID,
                content: <SiteTab
                    providerName={this.state.provider.providerName}
                    siteIdList={this.state.provider.siteIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage="No sites currently exist for this provider."
                />
            },
            {
                label: "Provider Contacts",
                id: ProviderDetailsPage.PROVIDER_CONTACTS_TAB_ID,
                content: <ContactTab
                    providerName={this.state.provider.providerName}
                    contactIdList={this.state.provider.contactIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage="No contacts currently exist for this provider."
                />
            },
            {
                label: "Provider Services",
                id: ProviderDetailsPage.PROVIDER_SERVICES_TAB_ID,
                content: <ProviderServiceTab
                    providerName={this.state.provider.providerName}
                    providerServiceIdList={this.state.provider.providerServiceIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage={
                        <Box variant="h2">No services currently exist for this provider.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any services for this provider.</Box>
                    }
                />
            },
            {
                label: "Provider Children",
                id: ProviderDetailsPage.PROVIDER_CHILDREN_TAB_ID,
                content: <ProviderChildrenTab
                    provider={this.state.provider}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            },
            {
                label: "Provider ASNs",
                id: ProviderDetailsPage.PROVIDER_ASN_TAB_ID,
                content: <AsnTab
                    asnIdList={this.state.asnIdList}
                    providerName={this.state.provider.providerName}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage={
                        <Box variant="h2">No ASNs currently exist for this provider.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any ASNs for this provider.</Box>}
                />
            },
            {
                label: "Provider Orders",
                id: ProviderDetailsPage.PROVIDER_ORDER_TAB_ID,
                content: <OrderTab
                    providerName={this.state.provider.providerName}
                    orderIdList={this.state.provider.orderIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    searchTitle="Search in Provider Orders"
                    emptyTableMessage={
                        <Box variant="h2">No orders currently exist for this provider.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any orders for this provider.</Box>}
                />
            },
            {
                label: "Provider Notes",
                id: ProviderDetailsPage.PROVIDER_NOTE_TAB_ID,
                content: <NoteTab
                    tableName="provider"
                    type="provider"
                    entityId={this.state.provider.providerId}
                    name={this.state.provider.providerName}
                    noteIdList={this.state.provider.noteIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            },
            {
                label: "Audit Trail",
                id: ProviderDetailsPage.PROVIDER_AUDIT_TAB_ID,
                content: <AuditTab
                    type="provider"
                    auditIdList={this.state.provider.auditIdList}
                    auth={this.props.auth}
                    user={this.props.user}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            }
        ]
    );

    render() {
        if (this.state.loading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    loading={this.state.isSpinnerShown}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return (
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                {/* Moving a parent provider a new parent confirmation modal */}
                <ConfirmationModal
                    isVisible={this.state.isModalVisible}
                    loading={this.state.isUpdateProviderInfoInProgress || this.state.providersLoading}
                    header="Moving a Parent Provider"
                    description={
                        <FremontAlert type="warning">
                            {`You are about to move provider ${this.state.provider.providerName} and all its children
                            under ${this.state.updatedProvider.parentProviderName}. If you do not want to move the children of
                            ${this.state.provider.providerName} as well, click on Cancel and move the children of
                            ${this.state.provider.providerName} manually.`}
                        </FremontAlert>
                    }
                    hideModal={() => this.hideModal}
                    cancelButtonText="Cancel"
                    primaryButtonText="Submit"
                    submitDisabled={this.state.isUpdateProviderInfoInProgress || this.state.providersLoading}
                    onClickFunction={this.submitProviderUpdate}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <Box variant="h1">
                        {this.state.provider.providerName}
                    </Box>
                    <Tabs
                        tabs={this.updateTabs()}
                        activeTabId={this.state.activeTabId}
                        variant="default"
                        onChange={this.handleTabChange}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(ProviderDetailsPage);