import React from "react";
import {
    Box,
    Container,
    FormField,
    Grid,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontInput
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const OpticalCutsheetHeader = () => (
    (
        <div key={`${0}${Constants.SEPARATOR}${0}`}>
            <Grid gridDefinition={
                [
                    { colspan: { default: 1 } },
                    { colspan: { default: 5 } },
                    { colspan: { default: 5 } }
                ]
            }
            >
                <div>
                    Position
                </div>
                <div>
                    Fabric Ports Cutsheet
                </div>
                <div>
                    Optical Client Cutsheet
                </div>
            </Grid>
        </div>
    )
);

export const OpticalCutsheetRow = (props) => {
    const {
        opticalCutsheetMap,
        position,
        isDisabled
    } = props;
    return (
        (
            <div
                key={`${props.topologyMapPosition}${Constants.SEPARATOR}${props.arrayPosition}`}
            >
                <Grid gridDefinition={
                    [
                        { colspan: { default: 1 } },
                        { colspan: { default: 5 } },
                        { colspan: { default: 5 } },
                        { colspan: { default: 1 } }
                    ]
                }
                >
                    <div>
                        <div>
                            <FormField
                                stretch
                            >
                                <FremontInput
                                    id={`${Constants.ATTRIBUTES.opticalCutsheetMap}${Constants.SEPARATOR}${props.position}${Constants.SEPARATOR}${Constants.OPTICAL_CUTSHEET_CONSTANTS.position}`}
                                    disabled={props.isDisabled || position}
                                    value={position}
                                    onInput={props.handleStageInputChange}
                                    type={Constants.NUMBER}
                                    disableBrowserAutocorrect
                                    inputMode="numeric"
                                />
                            </FormField>
                        </div>
                    </div>
                    <div>
                        <FormField
                            stretch
                        >
                            <FremontInput
                                id={`${Constants.ATTRIBUTES.opticalCutsheetMap}${Constants.SEPARATOR}${props.position}${Constants.SEPARATOR}${Constants.OPTICAL_CUTSHEET_CONSTANTS.fabricPortCutsheet}`}
                                disabled={isDisabled}
                                value={opticalCutsheetMap[position].fabricPortCutsheet}
                                onInput={props.handleStageInputChange}
                                placeholder="Enter Fabric Ports Cutsheet MCM"
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            stretch
                        >
                            <FremontInput
                                id={`${Constants.ATTRIBUTES.opticalCutsheetMap}${Constants.SEPARATOR}${props.position}${Constants.SEPARATOR}${Constants.OPTICAL_CUTSHEET_CONSTANTS.opticalClientCutsheet}`}
                                disabled={isDisabled}
                                value={opticalCutsheetMap[position].opticalClientCutsheet}
                                onInput={props.handleStageInputChange}
                                placeholder="Enter Optical Client Cutsheet MCM"
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            stretch
                        >
                            <FremontButton
                                id={`${Constants.ATTRIBUTES.opticalCutsheetMap}${Constants.SEPARATOR}${props.position}${Constants.SEPARATOR}${Constants.OPTICAL_CUTSHEET_CONSTANTS.remove}`}
                                iconName="close"
                                disabled={props.isDisabled}
                                onClick={props.handleStageInputChange}
                            />
                        </FormField>
                    </div>
                </Grid>
            </div>
        )
    );
};

export const OpticalCutsheetInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                />
            }
        >
            <div className="fremont-scroll-outer">
                <div className="fremont-scroll">
                    <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                        <OpticalCutsheetHeader/>
                        {(props.order.opticalCutsheetMap && Object.keys(props.order.opticalCutsheetMap).length > 0) ?
                            Object.keys(props.order.opticalCutsheetMap).map(position =>
                                (
                                    <div key={`${Constants.ATTRIBUTES.opticalCutsheetMap}${Constants.SEPARATOR}${props.position}`}>
                                        <OpticalCutsheetRow
                                            opticalCutsheetMap={props.order.opticalCutsheetMap}
                                            isDisabled
                                            position={position}
                                            handleStageInputChange={props.handleStageInputChange}
                                        />
                                    </div>
                                ))
                            :
                            <Box textAlign="center">
                                <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                    <b>
                                        No Position Added Yet
                                    </b>
                                </Box>
                            </Box>
                        }
                    </div>
                </div>
            </div>
        </Container>
    </div>
);

export const OpticalCutsheetInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Optical Cutsheet
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div className="fremont-scroll-outer">
                <div className="fremont-scroll">
                    <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                        <OpticalCutsheetHeader/>
                        {(props.updatedOrder.opticalCutsheetMap
                            && Object.keys(props.updatedOrder.opticalCutsheetMap).length > 0) ?
                            Object.keys(props.updatedOrder.opticalCutsheetMap).map(position =>
                                (
                                    <div>
                                        <div key={position}>
                                            <OpticalCutsheetRow
                                                opticalCutsheetMap={props.updatedOrder.opticalCutsheetMap}
                                                isDisabled={!!props.allFieldsDisabled}
                                                position={position}
                                                handleStageInputChange={props.handleStageInputChange}
                                            />
                                        </div>
                                    </div>
                                )) :
                            <Box textAlign="center">
                                <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                    <b>
                                        No Position Added yet
                                    </b>
                                </Box>
                            </Box>
                        }
                        <Box
                            padding={{ top: "m" }}
                            margin={{ bottom: "xxxs" }}
                            color="text-label"
                        >
                            <div>
                                <div className="fremont-empty-div"/>
                                <FremontButton
                                    onClick={props.handleAddRowToOpticalCutsheetMap}
                                >
                                    Add New Position
                                </FremontButton>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </Container>
    </div>
);
