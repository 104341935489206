import React from "react";
import {
    Link
} from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import FremontConstants from "../../../utils/Constants";
import {
    LinkServiceExpandableSection,
    LinkServiceSelect,
    TabularDisplayComponent
} from "../components/CommonComponents";
import HelperFunctions from "../common/HelperFunctions";
import {
    constructCellId,
    getLinkIdColumn,
    getLifecycleStateColumn,
    getAEndInterfaceColumn,
    getBEndInterfaceColumn,
    getAEndClientInterfaceColumn,
    getBEndClientInterfaceColumn,
    columnFromAttribute,
    columnWithLinkFromAttribute,
    getEncryptionCapabilityColumn,
    getEncryptionIntentColumn,
    getAttributesColumn,
    getConsumingLinksColumn,
    getClientPathNameColumn
} from "./LinkColumnHelpers";

const { COLUMNS, ATTRIBUTES } = Constants;

export const getConsumesListFromAttributes = (attributes) => {
    const consumesAttribute = attributes
        .filter(attribute => attribute.key === Constants.CONSUMPTION_ATTRIBUTES.consumesList);
    if (consumesAttribute.length > 0) {
        return JSON.parse(consumesAttribute[0].value)
            .map(link => HelperFunctions.getIdentifierFromLinkInstance(link)).join(",");
    }
    return "";
};

export const getCommonLinkTableColumns = ({ linkType, editable }) => [
    getLinkIdColumn(linkType, editable),
    getLifecycleStateColumn(linkType, editable),
    getAEndInterfaceColumn(linkType, COLUMNS.A_END_INTERFACE, editable),
    getBEndInterfaceColumn(linkType, COLUMNS.B_END_INTERFACE, editable),
    getEncryptionCapabilityColumn(linkType, editable),
    getEncryptionIntentColumn(linkType, editable),
    getAttributesColumn(linkType),
    getConsumingLinksColumn(linkType)
];

export const getCommonContainerTableColumns = props => (
    [
        {
            id: `${props.linkType}Id`,
            header: "Container ID",
            description: "Container ID",
            width: 330,
            minWidth: 330,
            cell: item =>
                <Link href={`${FremontConstants.MANGO_ROUTES.linkDetails}/${item.instanceId}`}>{item.instanceId}</Link>
        },
        {
            id: `${props.linkType}Type`,
            header: "Link Type",
            description: "Link Type",
            width: 165,
            cell: item => item.readableLinkType
        },
        {
            id: `${props.linkType}Lifecycle`,
            header: "Lifecycle State",
            description: "Lifecycle State",
            width: 150,
            cell: item => (
                props.editable ?
                    <LinkServiceSelect
                        id={
                            constructCellId(item.readableLinkType, item.instanceId, Constants.ATTRIBUTES.lifecycleState)
                        }
                        options={HelperFunctions.createSelectedOptions(Object.values(Constants.LIFECYCLE_STATES))}
                        onChange={item.handleLinkChange}
                        selectedOption={HelperFunctions.createSelectedOption(
                            item.lifecycleState
                        )}
                    />
                    : item.lifecycleState
            )
        },
        {
            id: `${props.linkType}Attributes`,
            header: "Attributes",
            description: "Attributes",
            cell: item =>
                (
                    <LinkServiceExpandableSection
                        headerText="Expand attributes"
                    >
                        <TabularDisplayComponent
                            keyValueFields={item.attributesToDisplay}
                            inEditMode={props.editable}
                            idPrefix={constructCellId(
                                item.readableLinkType,
                                item.instanceId,
                                Constants.ATTRIBUTES.attributesToDisplay
                            )}
                            onChange={item.handleLinkChange}
                        />
                    </LinkServiceExpandableSection>
                )
        },
        {
            id: `${props.linkType}ConsumingLinks`,
            header: "Consuming Links",
            description: "Consuming Links",
            cell: item => getConsumesListFromAttributes(item.attributesToDisplay)
        }
    ]
);

export function getPassiveToPassiveTableColumns(editable) {
    const linkType = Constants.LINK_TYPES.passiveToPassive;

    return [
        getLinkIdColumn(linkType, editable),
        columnFromAttribute(linkType, COLUMNS.FIBER_ID, ATTRIBUTES.fibre_id, editable),
        getLifecycleStateColumn(linkType, editable),
        columnWithLinkFromAttribute(linkType, COLUMNS.CM_OR_TT_URL, ATTRIBUTES.cm_or_tt_url, editable),
        columnFromAttribute(linkType, COLUMNS.A_END_DARK_FIBER_HO, ATTRIBUTES.a_end_dark_fibre_handoff, editable),
        columnFromAttribute(linkType, COLUMNS.B_END_DARK_FIBER_HO, ATTRIBUTES.b_end_dark_fibre_handoff, editable),
        columnFromAttribute(linkType, COLUMNS.STYX_SPAN_NAME, ATTRIBUTES.styx_span_name),
        columnFromAttribute(linkType, COLUMNS.A_END_CROSS, ATTRIBUTES.a_end_cross_connection, editable),
        columnFromAttribute(linkType, COLUMNS.B_END_CROSS, ATTRIBUTES.b_end_cross_connection, editable),
        columnFromAttribute(linkType, COLUMNS.VENDOR_NAME, ATTRIBUTES.fibre_vendor, editable),
        columnFromAttribute(linkType, COLUMNS.FIBER_SERVICE_ID, ATTRIBUTES.fibre_service_id, editable),
        columnFromAttribute(linkType, COLUMNS.AMAZON_FIBER_PAIR_ID, ATTRIBUTES.amazon_fibre_pair_id),
        columnFromAttribute(linkType, COLUMNS.AMAZON_SPAN_ID, ATTRIBUTES.amazon_span_id),
        columnFromAttribute(linkType, COLUMNS.MAINTENANCE_GROUP, ATTRIBUTES.maintenance_group, editable),
        columnFromAttribute(linkType, COLUMNS.SOL_LOSS_1550_NM, ATTRIBUTES.sol_loss_1550_nm, editable),
        columnFromAttribute(linkType, COLUMNS.OPTICAL_DISTANCE, ATTRIBUTES.optical_distance_km, editable),
        columnWithLinkFromAttribute(linkType, COLUMNS.VENDOR_URL, ATTRIBUTES.vendor_url, editable),
        getEncryptionCapabilityColumn(linkType, editable),
        getEncryptionIntentColumn(linkType, editable),
        getAttributesColumn(linkType),
        getConsumingLinksColumn(linkType)
    ];
}

export function getMuxToMuxTableColumns(editable) {
    const linkType = Constants.LINK_TYPES.muxToMux;

    return [
        getLinkIdColumn(linkType, editable),
        // OTS ID should never be editable
        columnFromAttribute(linkType, COLUMNS.OTS_ID, ATTRIBUTES.user_provided_id, false, 175),
        getLifecycleStateColumn(linkType, editable),
        columnWithLinkFromAttribute(linkType, COLUMNS.CM_OR_TT_URL, ATTRIBUTES.cm_or_tt_url, editable),
        columnFromAttribute(linkType, COLUMNS.A_END_DC, ATTRIBUTES.a_end_dc, editable, 100),
        columnFromAttribute(linkType, COLUMNS.Z_END_DC, ATTRIBUTES.z_end_dc, editable, 100),
        columnFromAttribute(linkType, COLUMNS.A_B_DEGREE, ATTRIBUTES.a_b_degree, editable, 125),
        getAEndInterfaceColumn(linkType, COLUMNS.A_END_LINE_PORT, editable),
        getBEndInterfaceColumn(linkType, COLUMNS.B_END_LINE_PORT, editable),
        getEncryptionCapabilityColumn(linkType, editable),
        getEncryptionIntentColumn(linkType, editable),
        getAttributesColumn(linkType),
        getConsumingLinksColumn(linkType)
    ];
}

export function getRouterToRouterTableColumns(editable) {
    const linkType = Constants.LINK_TYPES.routerToRouter;

    return [
        getLinkIdColumn(linkType, editable),
        getLifecycleStateColumn(linkType, editable),
        getClientPathNameColumn(linkType),
        columnFromAttribute(linkType, COLUMNS.FABRIC, ATTRIBUTES.fabric, editable, 80),
        getAEndInterfaceColumn(linkType, COLUMNS.A_END_FABRIC_INTERFACE, editable),
        getAEndClientInterfaceColumn(linkType, editable),
        getBEndInterfaceColumn(linkType, COLUMNS.B_END_FABRIC_INTERFACE, editable),
        getBEndClientInterfaceColumn(linkType, editable),
        columnFromAttribute(linkType, COLUMNS.CAPACITY, ATTRIBUTES.capacity, editable),
        getEncryptionCapabilityColumn(linkType, editable),
        getEncryptionIntentColumn(linkType, editable),
        getAttributesColumn(linkType),
        getConsumingLinksColumn(linkType)
    ];
}

export function getTrunkToTrunkTableColumns(editable) {
    const linkType = Constants.LINK_TYPES.trunkToTrunk;

    return [
        getLinkIdColumn(linkType, editable),
        getLifecycleStateColumn(linkType, editable),
        columnWithLinkFromAttribute(linkType, COLUMNS.CM_OR_TT_URL, ATTRIBUTES.cm_or_tt_url, editable),
        columnFromAttribute(linkType, COLUMNS.TYPE, ATTRIBUTES.type, editable),
        getAEndInterfaceColumn(linkType, COLUMNS.A_END_TRUNK_PORT, editable),
        getBEndInterfaceColumn(linkType, COLUMNS.B_END_TRUNK_PORT, editable),
        columnFromAttribute(linkType, COLUMNS.WAVELENGTH, ATTRIBUTES.wavelength, editable),
        columnFromAttribute(linkType, COLUMNS.CHANNEL_CAPACITY_GBPS, ATTRIBUTES.channel_capacity_gbps, editable),
        columnFromAttribute(linkType, COLUMNS.A_END_CROSS, ATTRIBUTES.a_end_cross_connection, editable),
        columnFromAttribute(linkType, COLUMNS.B_END_CROSS, ATTRIBUTES.b_end_cross_connection, editable),
        getEncryptionCapabilityColumn(linkType, editable),
        getEncryptionIntentColumn(linkType, editable),
        getAttributesColumn(linkType),
        getConsumingLinksColumn(linkType)
    ];
}

export const getOmsToOmsTableColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.omsToOms,
        editable
    });
export const getPowerMuxToPowerMuxTableColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.powerMuxToPowerMux,
        editable
    });
export const getOduToOduTableColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.oduToOdu,
        editable
    });
export const getRouterToDwdmTableColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.routerToDWDM,
        editable
    });
export const getClientToClientTableColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.clientToClient,
        editable
    });

export const getProtectionContainerColumns = editable =>
    getCommonContainerTableColumns({
        linkType: Constants.CONTAINER_TYPES.protectionContainer,
        editable
    });
export const getParallelContainerColumns = editable =>
    getCommonContainerTableColumns({
        linkType: Constants.CONTAINER_TYPES.parallelContainer,
        editable
    });
export const getEncryptionToEncryptionColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.encryptionToEncryption,
        editable
    });
export const getRouterToEncryptionColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.routerToEncryption,
        editable
    });
export const getEncryptionToDWDMColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.encryptionToDWDM,
        editable
    });
export const getIntraDcRouterToIntraDcRouterColumns = editable =>
    getCommonLinkTableColumns({
        linkType: Constants.LINK_TYPES.intraDcRouterToIntraDcRouter,
        editable
    });

// These ColumnDefinitions are just for download purposes
export function getDownloadAblePassiveToPassiveTableColumns() {
    const linkType = Constants.LINK_TYPES.passiveToPassive;
    return [
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.fibre_id, ATTRIBUTES.fibre_id),
        getLifecycleStateColumn(linkType, false),
        columnWithLinkFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.cm_or_tt_url, ATTRIBUTES.cm_or_tt_url, false
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_dark_fibre_handoff, ATTRIBUTES.a_end_dark_fibre_handoff
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_dark_fibre_handoff, ATTRIBUTES.b_end_dark_fibre_handoff
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_cross_connection, ATTRIBUTES.a_end_cross_connection
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_cross_connection, ATTRIBUTES.b_end_cross_connection
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.fibre_vendor, ATTRIBUTES.fibre_vendor
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.fibre_service_id, ATTRIBUTES.fibre_service_id
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.maintenance_group, ATTRIBUTES.maintenance_group
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.sol_loss_1550_nm, ATTRIBUTES.sol_loss_1550_nm
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.optical_distance_km, ATTRIBUTES.optical_distance_km
        ),
        columnWithLinkFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.vendor_url, ATTRIBUTES.vendor_url, false
        )
    ];
}
export function getDownloadAbleMuxToMuxTableColumns() {
    const linkType = Constants.LINK_TYPES.muxToMux;
    return [
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.ots_id, ATTRIBUTES.user_provided_id),
        getLifecycleStateColumn(linkType, false),
        columnWithLinkFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.cm_or_tt_url, ATTRIBUTES.cm_or_tt_url, false
        ),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_dc, ATTRIBUTES.a_end_dc),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_dc, ATTRIBUTES.z_end_dc),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.a_b_degree, ATTRIBUTES.a_b_degree),
        getAEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_line_port),
        getBEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_line_port),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.fibre_trail, ATTRIBUTES.fibre_trail)
    ];
}

export function getDownloadAbleTrunkToTrunkTableColumns() {
    const linkType = Constants.LINK_TYPES.trunkToTrunk;
    return [
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.och_id, ATTRIBUTES.user_provided_id),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.och_type, ATTRIBUTES.type),
        getAEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_trunk_port),
        getBEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_trunk_port),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.wavelength, ATTRIBUTES.wavelength),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.channel_capacity_gbps, ATTRIBUTES.channel_capacity_gbps,
        ),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.name, ATTRIBUTES.name),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.provider, ATTRIBUTES.provider),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_cross_connection, ATTRIBUTES.a_end_cross_connection
        ),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_cross_connection, ATTRIBUTES.b_end_cross_connection
        ),
        columnWithLinkFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.cm_or_tt_url, ATTRIBUTES.cm_or_tt_url, false
        ),
        getLifecycleStateColumn(linkType, false),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.ots_trail, ATTRIBUTES.ots_trail)
    ];
}

export function getDownloadAbleRouterToRouterTableColumns() {
    const linkType = Constants.LINK_TYPES.routerToRouter;
    return [
        getClientPathNameColumn(linkType),
        getLifecycleStateColumn(linkType, false),
        columnWithLinkFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.cm_or_tt_url, ATTRIBUTES.cm_or_tt_url, false
        ),
        columnFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.fabric, ATTRIBUTES.fabric),
        columnFromAttribute(
            linkType, Constants.DOWNLOADABLE_COLUMNS.client_path_capacity_Gbps, ATTRIBUTES.capacity
        ),
        getAEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.intended_a_end_fabric_port, false),
        columnWithLinkFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_po, ATTRIBUTES.a_end_po, false),
        getAEndClientInterfaceColumn(linkType, false),
        getBEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.intended_b_end_fabric_port, false),
        columnWithLinkFromAttribute(linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_po, ATTRIBUTES.b_end_po, false),
        getBEndClientInterfaceColumn(linkType, false)
    ];
}

export function getDownloadAbleClientToClientTableColumns() {
    const linkType = Constants.LINK_TYPES.clientToClient;
    return [
        getClientPathNameColumn(linkType),
        getAEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.a_end_trunk_port, false),
        getAEndClientInterfaceColumn(linkType, false),
        getBEndInterfaceColumn(linkType, Constants.DOWNLOADABLE_COLUMNS.b_end_trunk_port, false),
        getBEndClientInterfaceColumn(linkType, false)
    ];
}
