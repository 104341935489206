import React, { useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { isEqual, noop } from "lodash";
import {
    Box,
    Header,
    SpaceBetween,
    CollectionPreferences,
    Pagination,
    Table,
    TextFilter,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton
} from "utils/CommonComponents";

export const Preferences = ({
    preferences,
    setPreferences,
    disabled
}) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
            title: "Page size",
            options: [
                { value: 10, label: "10 Items" },
                { value: 25, label: "25 Items" },
                { value: 50, label: "50 Items" },
                { value: 100, label: "100 Items" }
            ]
        }}
        wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines"
        }}
    />
);

export const onNextPageClick = ({ nextToken = false, fetch = noop } = {}) => async () => {
    if (nextToken) {
        await fetch();
    }
};

export const TableEmptyState = ({ entityName, emptyTableMessage }) => (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No {entityName.toLowerCase()}s</b>
                <Box variant="p" color="inherit">
                    {emptyTableMessage || `No ${entityName.toLowerCase()}s in Fremont.`}
                </Box>
            </div>
        </SpaceBetween>
    </Box>
);

export const canFetchMore = nextToken => (typeof nextToken) !== "undefined";

export const TableNoMatchState = props => (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    {"We can't find a match."}
                </Box>
            </div>
            <FremontButton
                iconName={canFetchMore(props.nextToken) ? "download" : null}
                disabled={!canFetchMore(props.nextToken) || props.loading}
                variant="primary"
                onClick={onNextPageClick(props.onNextPageClickHelper)}
            >
                {canFetchMore(props.nextToken) ? "Load More Data" : "All Data Loaded"}
            </FremontButton>
            <FremontButton onClick={props.onClearFilter}>Clear filter</FremontButton>
        </SpaceBetween>
    </Box>
);

export const getFilterCounterText = count => `${count} ${count === 1 ? "match" : "matches"}`;

export const renderEmpty = ({ entity, emptyTableMessage }) =>
    (<TableEmptyState entityName={entity} emptyTableMessage={emptyTableMessage}/>);

export const renderLoadMoreButton = ({
    dontLoadMore,
    loading,
    nextToken,
    onNextPageClickHelper
}) => (!dontLoadMore &&
    <FremontButton
        iconName={canFetchMore(nextToken) ? "download" : null}
        disabled={!canFetchMore(nextToken) || loading}
        variant="primary"
        onClick={onNextPageClick(onNextPageClickHelper)}
    >
        {canFetchMore(nextToken) ? "Load More Data" : "All Data Loaded"}
    </FremontButton>);

export const renderLoadMoreDataMsg = ({
    nextToken
}) => (canFetchMore(nextToken) &&
<box>
    To see additional search results select Load More Data to the right of table
</box>);

const onSelectionChange = setSelectedItems => ({ detail }) => setSelectedItems({ detail });
const pageLabel = pageNumber => `Page ${pageNumber} of all pages`;

export default function FremontTable(props) {
    const [preferences, setPreferences] = useState({
        pageSize: 50,
        wrapLines: true
    });

    // eslint-disable-next-line no-use-before-define
    const onClearFilter = () => actions.setFiltering("");
    const {
        items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps
    } = useCollection( // https://polaris.a2z.com/get_started/dev_guides/collection_hooks/
        props.tableItems,
        {
            filtering: {
                empty: renderEmpty(props),
                noMatch: (
                    <TableNoMatchState
                        loading={props.loading}
                        nextToken={props.nextToken}
                        onNextPageClickHelper={props.onNextPageClickHelper}
                        onClearFilter={onClearFilter}
                    />
                )
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            ...(props.supportSelection && { selection: {} })
        }
    );

    // We need to do this for some reason otherwise selection doesn't work, especially on circuits. The hunch here is
    // that somehow `items`, which is the read-only list of items output from `useCollection()`, is the `selectedItems`
    // passed in through the props are different even though the items have the same fields.
    const selectedItemFiltered = [];
    if (props.selectedItems && props.selectedItems.length > 0) {
        items.forEach((item) => {
            props.selectedItems.forEach((selectedItem) => {
                if (isEqual(item, selectedItem)) {
                    selectedItemFiltered.push(item);
                }
            });
        });
    }

    const selectionProps = {
        selectedItems: selectedItemFiltered,
        selectionType: props.selectionType,
        onSelectionChange: onSelectionChange(props.setSelectedItems)
    };

    return (
        <Table
            {...collectionProps}
            {...(props.setSelectedItems ? selectionProps : {})}
            columnDefinitions={props.columnDefinitions}
            items={items}
            loading={props.loading}
            resizableColumns
            wrapLines={preferences.wrapLines}
            header={
                props.showOnlyHeaderDescription ?
                    <Header variant="h4"> {props.entity} Table </Header>
                    :
                    <Header
                        variant="h4"
                        actions={
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                {props.createEntityLink &&
                                <Link href={props.createEntityLink}>
                                    <FremontButton
                                        iconName="add-plus"
                                        variant="primary"
                                    >
                                        {`Create ${props.entity}`}
                                    </FremontButton>
                                </Link>
                                }
                                {props.handleCreateEntity &&
                                    <FremontButton
                                        id={`create${props.entity}Button`}
                                        iconName="add-plus"
                                        variant="primary"
                                        onClick={props.handleCreateEntity}
                                    >
                                        {`Add ${props.entity}`}
                                    </FremontButton>
                                }
                                {renderLoadMoreButton(props)}
                            </SpaceBetween>
                        }
                    >
                        {props.entity} Table
                        <Header variant="primary">
                            {renderLoadMoreDataMsg(props)}
                        </Header>
                    </Header>

            }
            filter={props.filter ? props.filter
                : <TextFilter
                    {...filterProps}
                    filteringAriaLabel={`Filter ${props.entity}`}
                    filteringPlaceholder={`Find ${props.entity}`}
                    countText={getFilterCounterText(filteredItemsCount)}
                />
            }
            pagination={
                <Pagination
                    {...paginationProps}
                    openEnd={canFetchMore(props.nextToken)}
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel
                    }}
                />}
            preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
        />
    );
}
