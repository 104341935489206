import React, { Component } from "react";
import {
    ConfirmationModal,
    FremontAlert,
    FremontButton
} from "utils/CommonComponents";
import AttachmentHandler from "attachment/AttachmentHandler";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";
import FremontTable from "table/FremontTable";

/**
 * AttachmentTab is used to display the list of attachments
 */
class AttachmentTab extends Component {
    static ORDER_ATTACHMENT_COLUMN_DEFINITIONS = [
        {
            id: "fileName",
            sortingField: "fileName",
            header: "File Name",
            description: "Name of the attachment",
            cell: item => item.fileName
        },
        {
            id: "attachmentType",
            sortingField: "attachmentType",
            header: "Attachment Type",
            description: "Type of attachment",
            cell: item => Constants.ATTACHMENT_TYPES_LABELS[item.attachmentType]
        },
        {
            id: "download",
            header: "Download",
            description: "Link to download the attachment",
            cell: item => (
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item.attachmentId)}
                />
            )
        },
        {
            id: "createdBy",
            sortingField: "createdBy",
            header: "Created By",
            description: "Who the attachment was created by",
            cell: item => item.createdBy
        },
        {
            id: "createdDate",
            sortingField: "createdDate",
            header: "Created",
            description: "When the attachment was created",
            cell: item => HelperFunctions.formatDateAndTime(item.createdTime, Constants.DATE_FORMAT)
        },
        {
            id: "lastModified",
            sortingField: "lastModified",
            header: "Last Modified",
            description: "When the attachment was created",
            cell: item => HelperFunctions.formatDateAndTime(item.modifiedTime, Constants.DATE_FORMAT)
        },
        {
            id: "deleteAttachment",
            header: "",
            description: "Removing the attachment from the order",
            cell: item => (
                <FremontButton
                    variant="icon"
                    iconName="close"
                    disabled={item.isDownloadingAttachment || item.disabledAttachmentRemoval}
                    onClick={() => item.showAttachmentDeleteConfirmationModal(item.attachmentId)}
                />
            )
        }
    ];

    state = {
        attachmentFormToReactRefMap: {},
        orderAttachments: [], // Existing order level attachments
        newAttachments: [], // These will be the new attachments we add
        isAttachmentModalClicked: false,
        isDeletingAttachment: false,
        isAttachmentDeleteConfirmationModalClicked: false,
        attachmentToDelete: {}
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            this.props.handleFlashBarMessagesFromChildTabs(undefined,
                new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else {
            this.addDownloadAndDeleteMetaDataToAttachments(this.props.orderAttachments, this.props.orderCompleted,
                this.props.isDownloadingAttachment);
        }
    };

    componentDidUpdate(prevProps) {
        // We need to have a condition here because if we don't we'll end up in an infinite loop.
        // For example, we update the state, then the component needs to update because the state has changed so
        // we update the state again and so on.
        if (JSON.stringify(prevProps.orderAttachments) !== JSON.stringify(this.props.orderAttachments)
            || prevProps.isDownloadingAttachment !== this.props.isDownloadingAttachment) {
            this.addDownloadAndDeleteMetaDataToAttachments(this.props.orderAttachments, this.props.orderCompleted,
                this.props.isDownloadingAttachment);
        }
    }

    addDownloadAndDeleteMetaDataToAttachments = (orderAttachments, orderCompleted, isDownloadingAttachment) => {
        const orderAttachmentsClone = [];
        orderAttachments.forEach((attachment) => {
            const attachmentClone = HelperFunctions.deepClone(attachment);
            attachmentClone.isDownloadingAttachment = isDownloadingAttachment;
            attachmentClone.downloadAttachment = this.props.downloadAttachment;
            // Disabled the button to delete attachments from tables when the order is complete or when the add
            // attachments button is disabled (meaning we don't want to modify anything)
            attachmentClone.disabledAttachmentRemoval = orderCompleted || !!this.props.disableAddAttachmentsButton;
            attachmentClone.showAttachmentDeleteConfirmationModal = this.showAttachmentDeleteConfirmationModal;
            orderAttachmentsClone.push(attachmentClone);
        });
        this.setState({ orderAttachments: orderAttachmentsClone });
    };

    showAttachmentModal = () => {
        this.setState({
            newAttachments: [],
            attachmentFormToReactRefMap: {},
            isAttachmentModalClicked: true
        });
    };

    hideAttachmentModal = () => {
        this.setState({
            isAttachmentModalClicked: false
        });
    };

    showAttachmentDeleteConfirmationModal = (attachmentToDeleteId) => {
        const attachmentToDelete = this.state.orderAttachments.find(attachment =>
            attachment.attachmentId === attachmentToDeleteId);
        this.setState({
            attachmentToDelete,
            isAttachmentDeleteConfirmationModalClicked: true
        });
    };

    hideAttachmentDeleteConfirmationModal = () => {
        this.setState({
            isAttachmentDeleteConfirmationModalClicked: false
        });
    };

    FremontBackendClient = new FremontBackendClient();

    deleteAttachment = async () => {
        this.setState({ isDeletingAttachment: true });
        try {
            this.addDownloadAndDeleteMetaDataToAttachments(this.state.orderAttachments, this.props.orderCompleted,
                true);

            // Removing an attachment means that we empty out the attachmentIds
            const requestAttachment =
                HelperFunctions.createAttachmentRequestObjectFromAttachment(this.state.attachmentToDelete);
            requestAttachment[Constants.ATTRIBUTES.entityIdList] = [];
            // If we are deleting an attachment then we already know the attachment exists
            requestAttachment[Constants.ATTRIBUTES.verifyAttachmentFromRequest] = true;

            await this.FremontBackendClient.modifyAttachment([requestAttachment],
                this.props.auth);
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(null, error);

            // Reset the delete state
            this.setState({ isDeletingAttachment: false });
            this.hideAttachmentDeleteConfirmationModal();
            return;
        }

        this.setState({ isDeletingAttachment: false });
        this.hideAttachmentDeleteConfirmationModal();

        // Display success in the modal and hide the modal if there were no errors. We want to do this before we load
        // all of the data to give quick feedback (since the serial loading could take 5-7 seconds)
        this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText);

        // Reload all the information (even the order info incase its updated by another user). We always want to
        // have the latest data.
        await this.props.loadData(true, true);
    };

    render() {
        return (
            <div>
                <FremontTable
                    entity="Attachment"
                    loading={this.state.isDeletingAttachment || this.props.orderAttachmentsLoading}
                    columnDefinitions={AttachmentTab.ORDER_ATTACHMENT_COLUMN_DEFINITIONS}
                    emptyTableMessage={this.props.noAttachmentsMessage || (this.props.circuitDesignAttachments
                        ? Constants.EMPTY_TABLE_MESSAGES.noCircuitAttachments
                        : Constants.EMPTY_TABLE_MESSAGES.noOrderAttachments)}
                    tableItems={this.state.orderAttachments}
                    showOnlyHeaderDescription={this.props.disableAddAttachmentsButton}
                    handleCreateEntity={this.showAttachmentModal}
                    dontLoadMore
                />
                <AttachmentHandler
                    attachments={this.state.newAttachments}
                    entityType={this.props.entityType || Constants.ORDER_ENTITY_TYPE}
                    orderId={this.props.orderId}
                    parentEntityIdList={this.props.parentEntityIdList}
                    attachmentFormToReactRefMap={this.state.attachmentFormToReactRefMap}
                    isAttachmentModalClicked={this.state.isAttachmentModalClicked}
                    hideAttachmentModal={this.hideAttachmentModal}
                    auth={this.props.auth}
                    loadData={this.props.loadData}
                    handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                    attachmentOptions={this.props.attachmentOptions}
                />
                <ConfirmationModal
                    isVisible={this.state.isAttachmentDeleteConfirmationModalClicked}
                    disabled={this.state.isDeletingAttachment}
                    loading={this.state.isDeletingAttachment}
                    header="Delete Attachment Confirmation"
                    description={
                        <FremontAlert type="warning" header="You are about to permanently delete an attachment.">
                            {`Are you sure you want to delete '${this.state.attachmentToDelete.fileName}'? 
                            This action cannot be undone.`}
                        </FremontAlert>
                    }
                    hideModal={this.hideAttachmentDeleteConfirmationModal}
                    cancelButtonText="Cancel"
                    primaryButtonText="Delete Attachment"
                    onClickFunction={this.deleteAttachment}
                />
            </div>
        );
    }
}

export default AttachmentTab;