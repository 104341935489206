import React from "react";
import { Link } from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";

export default class SiteTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "siteName",
            sortingField: "siteName",
            header: "Site Name",
            description: "Name of Site",
            cell: item => <Link href={`${Constants.ROUTES.site}/${item.siteId}`}>{item.siteName}</Link>
        },
        {
            id: "awsRegion",
            sortingField: "awsRegion",
            header: "Region",
            description: "Site Region",
            cell: item => item.awsRegion
        },
        {
            id: "quarterlySpend",
            header: "Quarterly Spend",
            description: "Site Quarterly Spend",
            cell: item => item.quarterlySpend
        },
        {
            id: "cluster",
            header: "Cluster",
            description: "Site Cluster",
            cell: item => item.cluster
        },
        {
            id: "market",
            header: "Market",
            description: "Site Market",
            cell: item => item.market
        },
        {
            id: "status",
            header: "Status",
            description: "Site Status",
            cell: item => item.status
        }
    ];

    static fetchCreateSiteLink = providerName =>
        (providerName ? `${Constants.ROUTES.createSite}/${providerName}` : Constants.ROUTES.createSite);
}