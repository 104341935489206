import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import {
    CreateCircuitDesignComponentModalForm,
    EditCustomComponentModalForm
} from "circuitDesign/CreateCircuitDesignComponentModalInformation";
import {
    SearchHandler,
    SearchInformation
} from "common/SearchHandler";

class CreateCircuitDesignComponentModal extends Component {
    state = {
        componentGroup: {
            id: "",
            label: ""
        },
        hasBeenSubmittedOnce: false,
        errorTexts: {
            customComponent: Constants.ERROR_STRINGS.blankInput,
            componentGroup: Constants.ERROR_STRINGS.blankInput
        },
        flashbar: {
            text: "",
            type: ""
        },
        queriedCircuitDesignObjects: [],
        isQueryForCircuitsInProgress: false,
        searchParameters: SearchHandler.generateSearchParameters(SearchHandler.TYPES.CreateCircuitDesignComponentModal,
            this.props.order),
        isUpdateInProgress: false,
        selectedItems: []
    };

    componentDidMount = () => {
        if (this.props.editCustomComponent) {
            this.setState({
                componentGroup: {
                    label: this.props.customComponentToUpdate.componentGroup
                },
                errorTexts: {
                    customComponent: "",
                    componentGroup: ""
                },
                newPositionMapItem: this.props.customComponentToUpdate
            });
        }
    }

    /**
     * This function fetches every circuitDesign objects based on the query parameters so that the user can select
     * the circuitDesigns they want for adding as a custom component in positionmap
     */
    getQueriedCircuitDesignObjects = async () => {
        await SearchHandler.getQueriedCircuitDesignObjects(
            this.state.searchParameters, null, null, false, this.props.auth,
            (isQueryForCircuitsInProgress) => {
                this.setState({ isQueryForCircuitsInProgress });
            },
            (queriedCircuitDesignObjects) => {
                this.setState({ queriedCircuitDesignObjects });
            },
            this.displayErrorFromSearchInformation
        );
    }

    /**
     * This method handles input changes to the modal
     */
    handleInputChange = async (evt) => {
        const input = {};
        input.evt = evt;

        const attributeId = input.evt.target.id;
        let inputValue;
        // Handle select fields
        if (attributeId === Constants.CIRCUIT_DESIGN_VALUES.componentGroup) {
            this.setState({
                componentGroup: {
                    value: input.evt.detail.selectedOption.value,
                    label: input.evt.detail.selectedOption.label
                },
                // we dont want to overwrite existing error message for customComponent field
                // so we set it back to its original value
                errorTexts: {
                    componentGroup: "",
                    customComponent:
                        input.evt.detail.selectedOption.label === Constants.COMPONENT_NAMES.fremontCircuit ?
                            Constants.ERROR_STRINGS.missingCircuit : this.state.errorTexts.customComponent
                }
            });
        }
        // Handle the input field
        if (attributeId === Constants.CIRCUIT_DESIGN_VALUES.customComponent) {
            inputValue = input.evt.detail.value;
            if (inputValue === "") {
                this.setState({
                    errorTexts: {
                        componentGroup: this.state.errorTexts.componentGroup,
                        customComponent: Constants.ERROR_STRINGS.blankInput
                    }
                });
            } else {
                this.setState({
                    errorTexts: {
                        componentGroup: this.state.errorTexts.componentGroup,
                        customComponent: ""
                    }
                });
            }
        }

        this.setState({
            newPositionMapItem: {
                name: inputValue,
                componentGroup: this.state.componentGroup.label,
                type: "customComponent",
                ...(this.props.customComponentSiteId && { siteGroup: this.props.customComponentSiteId })
            }
        });
    };

    handleSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, {
            hasBeenSubmittedOnce: true,
            isUpdateInProgress: true
        });

        // If any error messages exist, we display a flashbar error and any error texts
        if (Object.values(this.state.errorTexts).some(errorText => errorText)) {
            HelperFunctions.displayFlashbarError(
                this, new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                { isUpdateInProgress: false }
            );
            return;
        }

        const updatedCircuitDesignObject = HelperFunctions.deepClone(this.props.circuitDesign);
        const positionMapLength = Object.keys(this.props.circuitDesign.positionMap).length;

        // Here we increment every key greater than the location of the new component so that every
        // key in the positionMap still has a unique value and then we insert the component at the correct
        // (and now unique) position
        let key;
        if (!this.props.editCustomComponent) {
            for (key = positionMapLength - 1; key >= this.props.customComponentPositionMapIndex; key -= 1) {
                updatedCircuitDesignObject.positionMap[key + 1] = updatedCircuitDesignObject.positionMap[key];
            }
        }

        updatedCircuitDesignObject.positionMap[this.props.customComponentPositionMapIndex] =
            this.state.newPositionMapItem;

        const circuitsToUpdate = HelperFunctions.createNewApiObjects([this.props.circuitDesign],
            [updatedCircuitDesignObject], Constants.ATTRIBUTES.circuitDesignId, Constants.KEEP_KEYS.CIRCUIT,
            []);
        try {
            // update the circuits and wait for a response
            if (circuitsToUpdate.length > 0) {
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }
            this.props.handleCloseModalAfterUpdate();
            this.props.loadData(true, true);
        } catch (error) {
            // Display error message
            HelperFunctions.displayFlashbarError(
                this, error, { isUpdateInProgress: false }
            );
        }
    };

    FremontBackendClient = new FremontBackendClient();

    handleCircuitSelectedFromTable = (evt) => {
        const selectedCircuitDesign = evt.detail.selectedItems[0];
        this.setState({
            newPositionMapItem: {
                name: selectedCircuitDesign.circuitDesignNumber,
                uuid: selectedCircuitDesign.circuitDesignId,
                componentGroup: Constants.COMPONENT_NAMES.fremontCircuit,
                ...(this.props.customComponentSiteId && { siteGroup: this.props.customComponentSiteId }),
                type: "customComponent"
            },
            errorTexts: {
                componentGroup: "",
                customComponent: ""
            },
            selectedItems: evt.detail.selectedItems
        });
    };

    displayErrorFromSearchInformation = (successText, error, dismissFlashbar) => {
        if (dismissFlashbar) {
            HelperFunctions.dismissFlashbar(this);
        } else {
            HelperFunctions.displayFlashbarError(this, error);
        }
    }

    render() {
        if (this.props.editCustomComponent) {
            return (
                <EditCustomComponentModalForm
                    editCustomComponent={this.props.editCustomComponent}
                    customComponentToUpdate={this.props.customComponentToUpdate}
                    handleFormDismiss={this.props.handleFormDismiss}
                    handleInputChange={this.handleInputChange}
                    handleSubmit={this.handleSubmit}
                    handleEditCustomComponentSubmit={this.handleEditCustomComponentSubmit}
                    customComponentSiteId={this.props.customComponentSiteId}
                    customComponentSiteName={this.props.customComponentSiteName}
                    customComponentInputValue={this.state.newPositionMapItem ? this.state.newPositionMapItem.name : ""}
                    errorTexts={this.state.errorTexts}
                    isUpdateInProgress={this.state.isUpdateInProgress}
                    flashbar={this.state.flashbar}
                />
            );
        }
        return (
            <CreateCircuitDesignComponentModalForm
                customComponentModalVisible={this.props.customComponentModalVisible}
                customComponentSiteId={this.props.customComponentSiteId}
                customComponentSiteName={this.props.customComponentSiteName}
                customComponentInputValue={this.state.newPositionMapItem ? this.state.newPositionMapItem.name : ""}
                handleFormDismiss={this.props.handleFormDismiss}
                handleInputChange={this.handleInputChange}
                componentGroup={this.state.componentGroup}
                handleSubmit={this.handleSubmit}
                flashbar={this.state.flashbar}
                errorTexts={this.state.hasBeenSubmittedOnce ? this.state.errorTexts : ""}
                circuitDesign={this.props.circuitDesign}
                isUpdateInProgress={this.state.isUpdateInProgress || this.props.allProvidersLoading}
                auth={this.props.auth}
                handleCircuitSelectedFromTable={this.handleCircuitSelectedFromTable}
                selectedItems={this.state.selectedItems}
                isQueryForCircuitsInProgress={this.state.isQueryForCircuitsInProgress}
                queriedCircuitDesignObjects={this.state.queriedCircuitDesignObjects}
                searchInformation={
                    <SearchInformation
                        order={null}
                        siteNames={null}
                        searchParameters={this.state.searchParameters}
                        orderCompleted={null}
                        isUpdateInProgress={this.state.isUpdateInProgress}
                        isQueryInProgress={this.state.isQueryForCircuitsInProgress}
                        stageCompleted={false}
                        setSearchParameters={(searchParameters) => {
                            this.setState({ searchParameters });
                        }}
                        getQueriedObjects={this.getQueriedCircuitDesignObjects}
                        disableSearchButton={false}
                        disabledFilterFields={["Provider Name", "Service Type", "Customer/Fabric", "Consumable"]}
                        disabledFilterInputs={["Service Type", "Consumable"]}
                        providerOptions={this.props.allProviders}
                        searchParameterOptions={Constants.SEARCH_PARAMETER_OPTIONS}
                        customComponent
                    />
                }
            />
        );
    }
}

export default CreateCircuitDesignComponentModal;