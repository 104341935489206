import React from "react";
import NoteTableData from "note/NoteTableData";
import FremontTable from "table/FremontTable";

const NoteTable = props => (
    <FremontTable
        entity="Note"
        handleCreateEntity={props.handleNoteModalClick}
        columnDefinitions={NoteTableData.COLUMN_DEFINITIONS}
        tableItems={props.items}
        loading={props.loading}
        nextToken={props.nextToken}
        emptyTableMessage={props.emptyTableMessage}
    />
);

export default NoteTable;