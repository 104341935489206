import React, { Component } from "react";
import { Box } from "@amzn/awsui-components-react/polaris";
import {
    FremontAlert
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import { StageSlaForm, NewStageSlaModalForm } from "stageSla/StageSlaInformation";

export const DEFAULT_NEW_STAGE_SLA_ERROR_TEXTS = {
    serviceType: Constants.ERROR_STRINGS.blankInput,
    orderType: Constants.ERROR_STRINGS.blankInput,
    geographicRegion: Constants.ERROR_STRINGS.blankInput,
    customerFabric: Constants.ERROR_STRINGS.blankInput,
    priorityType: Constants.ERROR_STRINGS.blankInput,
    providerName: Constants.ERROR_STRINGS.blankInput,
    projectType: Constants.ERROR_STRINGS.blankInput
};

class StageSla extends Component {
    state = {
        isPageLoading: false,
        isSubmissionInProgress: false,
        flashbar: {
            type: "",
            text: "",
            flashbarLoading: false
        },
        editModeTable: "",
        stageSlaObjects: [],
        stageSlaObjectsEditMode: [],
        newStageSlaModalVisible: false,
        newStageSlaObject: {},
        errorTexts: {},
        hasNewStageSlaBeenSubmittedOnce: false,
        isUserManager: !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT] ||
            !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS] ||
            !!this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG] ||
            !!this.props.user.permissions[Constants.POSIX_GROUPS.NEST]
            || HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()
    };

    componentDidMount = async () => {
        await this.fetchStageSlas();
    };

    fetchStageSlas = async () => {
        const fremontBackendClient = new FremontBackendClient();
        const stageSlaObjects = [];
        let response = {};
        try {
            do {
                const nextToken = response.nextToken ? response.nextToken : null;
                // By default, lint does not allow using await in a loop. In this case, getAllStageSlaInfo returns a
                // token that is used for the next iteration which means we have to use await within a loop. For
                // lint to pass, we have to disable that error before that line, which is done below:
                // eslint-disable-next-line no-await-in-loop
                response = await fremontBackendClient.getAllStageSlaInfo(nextToken, this.props.auth);
                stageSlaObjects.push(...response.stageSla.map(stageSla => stageSla));
            }
            while (response.nextToken);
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isSubmissionInProgress: false });
        }
        this.setState({
            stageSlaObjects
        });
    }

    FremontBackendClient = new FremontBackendClient();

    handleEditModeChange = (evt) => {
        this.setState({
            editModeTable: this.state.editModeTable === evt.target.id ? "" : evt.target.id,
            stageSlaObjectsEditMode: HelperFunctions.deepClone(this.state.stageSlaObjects)
        });
    };

    validateInput = (input) => {
        const output = input;
        const uniqueId = output.evt.target.id.split(Constants.SEPARATOR)[0];
        const attribute = output.evt.target.id.split(Constants.SEPARATOR)[1];

        if ([Constants.ATTRIBUTES.serviceType,
            Constants.ATTRIBUTES.orderType,
            Constants.ATTRIBUTES.priorityType,
            Constants.ATTRIBUTES.geographicRegion,
            Constants.ATTRIBUTES.customerFabric,
            Constants.ATTRIBUTES.providerName,
            Constants.ATTRIBUTES.projectType].includes(attribute)) {
            // New Stage SLA
            const inputValue = output.evt.detail.selectedOption.value;
            output.newStageSlaObject[attribute] = inputValue;
            let orderVariant;
            // For Backbone depending on the customer Fabric we accept orderVariant to be either
            // ${serviceType}#${customerFabric}#${orderType}#${providerName} or
            // ${serviceType}#${customerFabric}#${orderType}#${providerName}#${projectType}
            // As the stages differ for each customer fabric with respect to Provider name and Project type
            if ([output.newStageSlaObject[Constants.ATTRIBUTES.serviceType], inputValue]
                .includes(Constants.SERVICE_TYPES.BACKBONE)) {
                const serviceType = output.newStageSlaObject[Constants.ATTRIBUTES.serviceType];
                const orderType = output.newStageSlaObject[Constants.ATTRIBUTES.orderType];
                const customerFabric = output.newStageSlaObject[Constants.ATTRIBUTES.customerFabric];
                const providerName = output.newStageSlaObject[Constants.ATTRIBUTES.providerName];
                orderVariant = `${serviceType}#${customerFabric}#${orderType}#${providerName}`;
                if (Constants.PROJECT_SPECIFIC_BACKBONE_FABRICS.includes(customerFabric)
                    && Constants.INTERNAL_AMAZON_PROVIDER ===
                    output.newStageSlaObject[Constants.ATTRIBUTES.providerName]
                    && output.newStageSlaObject[Constants.ATTRIBUTES.projectType] !== undefined) {
                    const projectType = output.newStageSlaObject[Constants.ATTRIBUTES.projectType];
                    orderVariant = `${serviceType}#${customerFabric}#${orderType}#${providerName}#${projectType}`;
                }
            } else {
                const serviceType = output.newStageSlaObject[Constants.ATTRIBUTES.serviceType];
                const geographicRegion = output.newStageSlaObject[Constants.ATTRIBUTES.geographicRegion];
                const orderType = output.newStageSlaObject[Constants.ATTRIBUTES.orderType];
                const priorityType = output.newStageSlaObject[Constants.ATTRIBUTES.priorityType];

                orderVariant = `${serviceType}#${geographicRegion}#${orderType}#${priorityType}`;
            }
            output.newStageSlaObject[Constants.ATTRIBUTES.orderVariant] = orderVariant;
        } else {
            // Get the index of the resource we are changing so we can adjust the attribute values and errors
            const index = output.stageSlaObjects.findIndex(stageSla =>
                stageSla.stageSlaId === uniqueId);
            if (index !== -1) {
                const value = output.evt.detail.value.trim();
                output.stageSlaObjects[index][Constants.ATTRIBUTES.stageMap][attribute] = value;
            }
        }
        return output;
    }

    handleInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.stageSlaObjects = HelperFunctions.deepClone(this.state.stageSlaObjectsEditMode);
        const output = this.validateInput(input);

        this.setState({
            stageSlaObjectsEditMode: output.stageSlaObjects
        });
    }

    calculateErrorTexts = (newStageSlaObject) => {
        const errorTexts = {};
        errorTexts[Constants.ATTRIBUTES.serviceType] = !newStageSlaObject[Constants.ATTRIBUTES.serviceType] ?
            DEFAULT_NEW_STAGE_SLA_ERROR_TEXTS.serviceType : "";
        errorTexts[Constants.ATTRIBUTES.orderType] = !newStageSlaObject[Constants.ATTRIBUTES.orderType] ?
            DEFAULT_NEW_STAGE_SLA_ERROR_TEXTS.orderType : "";

        if (newStageSlaObject.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            errorTexts[Constants.ATTRIBUTES.customerFabric] = !newStageSlaObject[Constants.ATTRIBUTES.customerFabric] ?
                DEFAULT_NEW_STAGE_SLA_ERROR_TEXTS.customerFabric : "";
            errorTexts[Constants.ATTRIBUTES.providerName] = !newStageSlaObject[Constants.ATTRIBUTES.providerName] ?
                DEFAULT_NEW_STAGE_SLA_ERROR_TEXTS.providerName : "";
        } else {
            errorTexts[Constants.ATTRIBUTES.geographicRegion] =
                !newStageSlaObject[Constants.ATTRIBUTES.geographicRegion] ?
                    DEFAULT_NEW_STAGE_SLA_ERROR_TEXTS.geographicRegion : "";
            errorTexts[Constants.ATTRIBUTES.priorityType] = !newStageSlaObject[Constants.ATTRIBUTES.priorityType] ?
                DEFAULT_NEW_STAGE_SLA_ERROR_TEXTS.priorityType : "";
        }
        return errorTexts;
    }

    handleNewStageSlaInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.newStageSlaObject = HelperFunctions.deepClone(this.state.newStageSlaObject);

        const output = this.validateInput(input);
        const errorTexts = this.calculateErrorTexts(input.newStageSlaObject);

        this.setState({
            newStageSlaObject: output.newStageSlaObject,
            errorTexts
        });
    }

    handleSubmit = async (evt) => {
        this.setState({
            isSubmissionInProgress: true,
            hasNewStageSlaBeenSubmittedOnce: true
        });

        try {
            if (evt.target.id === "newStageSla") {
                if (Object.values(this.state.errorTexts).some(error => error)) {
                    HelperFunctions.displayFlashbarError(
                        this, new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                        { isSubmissionInProgress: false }
                    );
                    return;
                }
                await this.FremontBackendClient.modifyStageSla(
                    [this.state.newStageSlaObject], [], this.props.auth
                );
            } else {
                await this.FremontBackendClient.modifyStageSla(
                    this.state.stageSlaObjectsEditMode, this.state.stageSlaObjects, this.props.auth
                );
            }

            this.setState({
                editModeTable: "",
                newStageSlaObject: { errorTexts: {} }
            });
            await this.fetchStageSlas();
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({
            isSubmissionInProgress: false,
            newStageSlaModalVisible: false,
            hasNewStageSlaBeenSubmittedOnce: false
        });
    }

    handleAddNewStageSlaModal = () => {
        this.setState({
            newStageSlaModalVisible: !this.state.newStageSlaModalVisible,
            newStageSlaObject: {},
            errorTexts: {
                [Constants.ATTRIBUTES.serviceType]: Constants.ERROR_STRINGS.blankInput
            },
            hasNewStageSlaBeenSubmittedOnce: false
        });
    }

    handleDismissNewStageSlaModal = () => {
        this.setState({
            newStageSlaObject: { errorTexts: {} },
            newStageSlaModalVisible: false,
            hasNewStageSlaBeenSubmittedOnce: false,
            flashbar: {
                type: "",
                text: "",
                flashbarLoading: false
            }
        });
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this);
    };

    render() {
        if (this.state.isPageLoading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    isPageLoading={this.state.isSpinnerShown}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return (
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    flashbarLoading={this.state.flashbar.flashbarLoading}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                {this.state.newStageSlaModalVisible &&
                <NewStageSlaModalForm
                    newStageSlaModalVisible={this.state.newStageSlaModalVisible}
                    handleSubmitNewStageSla={this.handleSubmitNewStageSla}
                    handleNewStageSlaModalDismiss={this.handleNewStageSlaModalDismiss}
                    handleNewStageSlaInputChange={this.handleNewStageSlaInputChange}
                    handleDismissNewStageSlaModal={this.handleDismissNewStageSlaModal}
                    handleSubmit={this.handleSubmit}
                    flashbar={this.state.flashbar}
                    newStageSlaObject={this.state.newStageSlaObject}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    errorTexts={this.state.hasNewStageSlaBeenSubmittedOnce ? this.state.errorTexts : {}}
                />
                }
                <Box padding={{ top: "l" }}>
                    {(this.state.isUserManager) ?
                        <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                            <StageSlaForm
                                handleNewStageSla={this.handleNewStageSla}
                                handleEditModeChange={this.handleEditModeChange}
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                handleAddNewStageSlaModal={this.handleAddNewStageSlaModal}
                                stageSlaObjects={this.state.stageSlaObjects}
                                stageSlaObjectsEditMode={this.state.stageSlaObjectsEditMode}
                                handleSubmitUpdate={this.handleSubmitUpdate}
                                isSubmissionInProgress={this.state.isSubmissionInProgress}
                                isUserManager={this.state.isUserManager}
                                editModeTable={this.state.editModeTable}
                            />
                        </div> :
                        <div>
                            <FremontAlert header="No Access to This Page" type="error">
                                User is not in valid POSIX groups. Please contact your manager to get valid permissions.
                            </FremontAlert>
                        </div>
                    }
                </Box>
            </div>
        );
    }
}

export default StageSla;