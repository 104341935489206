import React, { Component } from "react";
import { Box } from "@amzn/awsui-components-react/polaris";
import {
    ConfirmationModal,
    FremontAlert
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import { ManageOrderAssignmentForm } from "manager/ManageOrderAssignmentInformation";
import NewResourceModalForm from "manager/NewResourceModalForm";

export const DEFAULT_NEW_RESOURCE_BACKBONE_ENGINEER_ERROR_TEXTS = {
    resourceName: Constants.ERROR_STRINGS.blankInput,
    orderType: Constants.ERROR_STRINGS.blankInput
};

export const DEFAULT_NEW_RESOURCE_PROVISIONER_ERROR_TEXTS = {
    resourceName: Constants.ERROR_STRINGS.blankInput,
    orderType: Constants.ERROR_STRINGS.blankInput,
    serviceType: Constants.ERROR_STRINGS.blankInput,
    businessDeveloper: Constants.ERROR_STRINGS.blankInput,
    filterStart: Constants.ERROR_STRINGS.blankInput,
    filterEnd: Constants.ERROR_STRINGS.blankInput
};

export const DEFAULT_NEW_RESOURCE_IPENGINEER_ERROR_TEXTS = {
    resourceName: Constants.ERROR_STRINGS.blankInput,
    orderType: Constants.ERROR_STRINGS.blankInput,
    serviceType: Constants.ERROR_STRINGS.blankInput,
    geographicRegion: Constants.ERROR_STRINGS.blankInput,
    filterStart: Constants.ERROR_STRINGS.blankInput,
    filterEnd: Constants.ERROR_STRINGS.blankInput
};

export const DEFAULT_NEW_RESOURCE_BUSINESS_DEVELOPER_ERROR_TEXTS = {
    resourceName: Constants.ERROR_STRINGS.blankInput
};

const EMPTY_ERROR_TEXTS = {
    resourceName: "",
    orderType: "",
    serviceType: "",
    businessDeveloper: "",
    filterStart: "",
    filterEnd: ""
};

class ManageOrderAssignment extends Component {
    state = {
        isPageLoading: false,
        isSubmissionInProgress: false,
        flashbar: {
            type: "",
            text: "",
            flashbarLoading: false
        },
        backboneEngineers: [],
        ipEngineers: [],
        provisioners: [],
        businessDevelopers: [],
        backboneEngineersToUpdate: [],
        ipEngineersToUpdate: [],
        provisionersToUpdate: [],
        businessDevelopersToUpdate: [],
        backboneEngineerEditMode: false,
        ipEngineerEditMode: false,
        provisioningEditMode: false,
        businessDeveloperEditMode: false,
        newResourceModalVisible: false,
        newResource: {},
        newResourceHasBeenSubmitted: false,
        permissions: {},
        posixResources: {},
        resourceToDelete: {},
        showResourceDeletionConfirmationModal: false,
        mouseHover: ""
    };

    componentDidMount = async () => {
        this.processUserInfo();
        await this.fetchResources();
        await this.getResourcesFromPosix();
    };

    getResourcesFromPosix = async () => {
        // the following if statement is only for dev environment.
        // Its basically a dummy value that will be returned for the dropdown field ONLY when we are in
        // localhost, as we cannot get a return value for getResourceNamesBasedOffResourceType due to the fact it is a
        // BRASS related API and wont work for your local stack.
        if (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()) {
            const posixResources = {
                backboneEngineers: ["localhost_BackboneEngineer"],
                ipEngineers: ["localhost_ipEngineer"],
                provisioners: ["localhost_Provisioner"],
                businessDevelopers: ["localhost_BizDev"]
            };
            this.setState({
                posixResources
            });
            return;
        }
        this.setState({ isPageLoading: true });
        try {
            const posixBackboneEngineers = await this.FremontBackendClient.getResourceNamesBasedOffResourceType(
                Constants.RESOURCE_TYPES.backBoneEngineer, this.props.auth
            );

            const posixIPEngineers = await this.FremontBackendClient.getResourceNamesBasedOffResourceType(
                Constants.RESOURCE_TYPES.ipEngineer,
                this.props.auth
            );

            const posixProvisioners = await this.FremontBackendClient.getResourceNamesBasedOffResourceType(
                Constants.RESOURCE_TYPES.provisioner,
                this.props.auth
            );

            const posixBusinessDevelopers = await this.FremontBackendClient.getResourceNamesBasedOffResourceType(
                Constants.RESOURCE_TYPES.businessDeveloper,
                this.props.auth
            );

            const posixResources = {
                backboneEngineers: posixBackboneEngineers.resourceNames.sort(),
                ipEngineers: posixIPEngineers.resourceNames.sort(),
                provisioners: posixProvisioners.resourceNames.sort(),
                businessDevelopers: posixBusinessDevelopers.resourceNames.sort()
            };

            this.setState({
                posixResources,
                isPageLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error,
                { allFieldsDisabled: true, isPageLoading: false });
        }
    };

    fetchResources = async (hidePageSpinner) => {
        // If we have hidePageSpinner as true, we only want to show the spinner on the table
        this.setState({
            isPageLoading: !hidePageSpinner,
            isSubmissionInProgress: !!hidePageSpinner // To be sure its not set to undefined
        });

        try {
            const response = await this.FremontBackendClient.getResourceBasedOffUser(this.props.auth);

            // Sort the options and set the state
            this.setState({
                backboneEngineers: this.attachInputHandler(
                    response.resourceMap.BackBoneEngineer,
                    this.handleBackboneEngineerInputChange,
                    Constants.ATTRIBUTES.resourceType
                ),
                ipEngineers: this.attachInputHandler(
                    response.resourceMap.IPEngineer,
                    this.handleIPEngineerInputChange,
                    Constants.ATTRIBUTES.filterStart,
                    Constants.ATTRIBUTES.serviceType
                ),
                provisioners: this.attachInputHandler(
                    response.resourceMap.Provisioner,
                    this.handleProvisionerInputChange,
                    Constants.ATTRIBUTES.filterStart,
                    Constants.ATTRIBUTES.serviceType
                ),
                businessDevelopers: this.attachInputHandler(
                    response.resourceMap.BusinessDeveloper,
                    this.handleBusinessDeveloperInputChange,
                    Constants.ATTRIBUTES.cluster
                )
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({
            isPageLoading: false,
            isSubmissionInProgress: false
        });
    };

    processUserInfo = () => {
        // the following if statement is only for dev environment.
        // Its basically a dummy value that will be returned for the dropdown field ONLY when we are in
        // localhost, as we cannot get a return value for getResourceNamesBasedOffResourceType due to the fact it is a
        // BRASS related API and wont work for your local stack.
        if (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()) {
            const permissions = {
                nest: true
            };
            this.setState({
                permissions
            });
            return;
        }

        const permissions = {
            nest: this.props.user.permissions[Constants.POSIX_GROUPS.NEST] || false,
            manager: this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT] || false,
            backbone: this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_BBONE_CAPENG] || false,
            provisioner: this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS] || false,
            ipeng: this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG] || false,
            businessDeveloper: this.props.user.permissions[Constants.POSIX_GROUPS.FREMONT_AWS_BD] || false
        };

        this.setState({
            permissions
        });
    };

    attachInputHandler = (inputArray, functionForInputChange, fieldToSortWith, secondarySortField) => {
        const array = inputArray || [];
        array.forEach((resource) => {
            Object.assign(resource, {
                handleManagerInputChange: functionForInputChange,
                handleInputChangeToSelect: this.handleInputChangeToSelect
            });
        });
        if (fieldToSortWith) {
            if (secondarySortField) {
                HelperFunctions.sortObjectsTwoFields(array, fieldToSortWith, secondarySortField);
            } else {
                HelperFunctions.sortObjectsByField(array, fieldToSortWith);
            }
        }

        return array;
    };

    FremontBackendClient = new FremontBackendClient();

    validateInput = (input) => {
        const output = input;
        let inputValue;
        // To determine the unique id that was passed in from the event, we split the full id on the SEPARATOR
        // constant, the underscore. We now have a list of two elements, one of which is the unique id. Each unique
        // id for a circuitDesign is a uuid which contains a hyphen. We locate the element in the list that has a
        // hyphen, and that is what returns the unique id of the circuitDesign object.
        const uniqueId = output.evt.target.id.split(Constants.SEPARATOR)[1];
        // To find the attributeId, we perform the same operation except that we find the element that does not contain
        // a hyphen, which will always be the attributeId
        const attributeId = output.evt.target.id.split(Constants.SEPARATOR)[0];
        // Get the index of the resource we are changing so we can adjust the attribute values and errors
        const index = output.resources.findIndex(resource =>
            resource.resourceId === uniqueId);

        if (attributeId === Constants.ATTRIBUTES.defaultResource) {
            // Only one default per type of resource
            // so we let the user make the choice
            for (let i = 0; i < output.resources.length; i += 1) {
                if (i === index) {
                    output.resources[i][attributeId] = true;
                } else {
                    output.resources[i][attributeId] = false;
                }
            }
            inputValue = output.evt.detail.checked;
        } else if (attributeId === Constants.ATTRIBUTES.cluster) {
            inputValue = output.evt.detail.value.trim();
        } else {
            inputValue = output.evt.detail.selectedOption.value.trim();
        }

        // For now we only care about error Texts for new Resource modal.
        if (!uniqueId) {
            output.errorTexts[attributeId] = "";
        }
        output.resources[index][attributeId] = inputValue || null;
        return output;
    }

    handleInputChangeToSelect = (evt) => {
        this.setState({
            mouseHover: evt.target.id
        });
    }

    handleProvisionerInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.resources = HelperFunctions.deepClone(this.state.provisionersToUpdate);
        const resourceOutput = this.validateInput(input);

        this.setState({
            provisionersToUpdate: this.attachInputHandler(resourceOutput.resources, this.handleProvisionerInputChange)
        });
    }

    handleBusinessDeveloperInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.resources = HelperFunctions.deepClone(this.state.businessDevelopersToUpdate);
        const resourceOutput = this.validateInput(input);

        this.setState({
            businessDevelopersToUpdate:
                this.attachInputHandler(resourceOutput.resources, this.handleBusinessDeveloperInputChange)
        });
    }

    handleBackboneEngineerInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.resources = HelperFunctions.deepClone(this.state.backboneEngineersToUpdate);
        const resourceOutput = this.validateInput(input);

        this.setState({
            backboneEngineersToUpdate: this.attachInputHandler(resourceOutput.resources,
                this.handleBackboneEngineerInputChange)
        });
    }

    handleIPEngineerInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.resources = HelperFunctions.deepClone(this.state.ipEngineersToUpdate);
        const resourceOutput = this.validateInput(input);

        this.setState({
            ipEngineersToUpdate: this.attachInputHandler(resourceOutput.resources, this.handleIPEngineerInputChange)
        });
    };

    handleEditModeChange = (evt) => {
        if (evt.target.id === Constants.RESOURCE_TYPES.backBoneEngineer) {
            this.setState({
                backboneEngineerEditMode: !this.state.backboneEngineerEditMode,
                backboneEngineersToUpdate: this.state.backboneEngineers
            });
        } else if (evt.target.id === Constants.RESOURCE_TYPES.ipEngineer) {
            this.setState({
                ipEngineerEditMode: !this.state.ipEngineerEditMode,
                ipEngineersToUpdate: this.state.ipEngineers
            });
        } else if (evt.target.id === Constants.RESOURCE_TYPES.businessDeveloper) {
            this.setState({
                businessDeveloperEditMode: !this.state.businessDeveloperEditMode,
                businessDevelopersToUpdate: this.state.businessDevelopers
            });
        } else {
            this.setState({
                provisioningEditMode: !this.state.provisioningEditMode,
                provisionersToUpdate: this.state.provisioners
            });
        }
    };

    handleNewResourceModalInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.resources = [HelperFunctions.deepClone(this.state.newResource)];
        input.errorTexts = HelperFunctions.deepClone(this.state.newResourceErrorTexts);
        const resourceOutput = this.validateInput(input);

        this.setState({
            newResource: resourceOutput.resources[0],
            newResourceErrorTexts: resourceOutput.errorTexts
        });
    }

    handleDismissNewResourceModal = () => {
        this.setState({
            newResourceModalVisible: !this.state.newResourceModalVisible,
            flashbar: {
                type: "",
                text: "",
                flashbarLoading: false
            }
        });
    };

    handleAddNewResourceModal = (evt) => {
        const newResource = {};
        newResource[Constants.ATTRIBUTES.resourceType] = evt.target.id;
        if (evt.target.id === Constants.RESOURCE_TYPES.backBoneEngineer) {
            newResource[Constants.ATTRIBUTES.serviceType] = Constants.SERVICE_TYPES.BACKBONE;
            this.setState({
                newResourceErrorTexts: DEFAULT_NEW_RESOURCE_BACKBONE_ENGINEER_ERROR_TEXTS
            });
        } else if (evt.target.id === Constants.RESOURCE_TYPES.ipEngineer) {
            this.setState({
                newResourceErrorTexts: DEFAULT_NEW_RESOURCE_IPENGINEER_ERROR_TEXTS
            });
        } else if (evt.target.id === Constants.RESOURCE_TYPES.businessDeveloper) {
            newResource[Constants.ATTRIBUTES.serviceType] = Constants.SERVICE_TYPES.SFP;
            newResource[Constants.ATTRIBUTES.orderType] = Constants.ORDER_TYPES.INSTALL;
            this.setState({
                newResourceErrorTexts: DEFAULT_NEW_RESOURCE_BUSINESS_DEVELOPER_ERROR_TEXTS
            });
        } else {
            this.setState({
                newResourceErrorTexts: DEFAULT_NEW_RESOURCE_PROVISIONER_ERROR_TEXTS
            });
        }

        this.setState({
            newResourceModalVisible: !this.state.newResourceModalVisible,
            newResource,
            newResourceHasBeenSubmitted: false,
            flashbar: {
                type: "",
                text: "",
                flashbarLoading: false
            }
        });
    };

    handleSubmitNewResource = async (evt) => {
        this.setState({
            newResourceHasBeenSubmitted: true
        });

        if (Object.values(this.state.newResourceErrorTexts).some(errorText => errorText)) {
            this.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarInvalidInput,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput), false);
            return;
        }

        this.setState({
            isSubmissionInProgress: true
        });

        try {
            const response = await this.FremontBackendClient.modifyResource([this.state.newResource], this.props.auth);

            // If a successful call to add the new resource, we simply append the response to our existing list
            if (evt.target.id === Constants.RESOURCE_TYPES.backBoneEngineer) {
                this.setState({
                    backboneEngineers: this.attachInputHandler(
                        this.state.backboneEngineers.concat(response.resources),
                        this.handleBackboneEngineerInputChange,
                        Constants.ATTRIBUTES.resourceType
                    )
                });
            } else if (evt.target.id === Constants.RESOURCE_TYPES.ipEngineer) {
                this.setState({
                    ipEngineers: this.attachInputHandler(
                        this.state.ipEngineers.concat(response.resources),
                        this.handleIPEngineerInputChange,
                        Constants.ATTRIBUTES.filterStart,
                        Constants.ATTRIBUTES.serviceType
                    )
                });
            } else if (evt.target.id === Constants.RESOURCE_TYPES.businessDeveloper) {
                this.setState({
                    businessDevelopers: this.attachInputHandler(
                        this.state.businessDevelopers.concat(response.resources),
                        this.handleBusinessDeveloperInputChange,
                        Constants.ATTRIBUTES.cluster
                    )
                });
            } else {
                this.setState({
                    provisioners: this.attachInputHandler(
                        this.state.provisioners.concat(response.resources),
                        this.handleProvisionerInputChange,
                        Constants.ATTRIBUTES.filterStart,
                        Constants.ATTRIBUTES.serviceType
                    )
                });
            }
            this.setState({
                newResourceModalVisible: false
            });
            this.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({
            isSubmissionInProgress: false
        });
    };

    handleShowDeleteResourceConfirmationModal = (resourceId, resourceType) => {
        // Figure out which resource we are trying to delete
        let resourceToDelete = {};
        if (Constants.RESOURCE_TYPES.provisioner === resourceType) {
            resourceToDelete = this.state.provisioners.find(provisioner => provisioner.resourceId === resourceId);
        }
        if (Constants.RESOURCE_TYPES.ipEngineer === resourceType) {
            resourceToDelete = this.state.ipEngineers.find(ipEngineer => ipEngineer.resourceId === resourceId);
        }
        if (Constants.RESOURCE_TYPES.backBoneEngineer === resourceType) {
            resourceToDelete = this.state.backboneEngineers.find(backboneEngineer =>
                backboneEngineer.resourceId === resourceId);
        }
        if (Constants.RESOURCE_TYPES.businessDeveloper === resourceType) {
            resourceToDelete = this.state.businessDevelopers.find(businessDeveloper =>
                businessDeveloper.resourceId === resourceId);
        }
        this.setState({ showResourceDeletionConfirmationModal: true, resourceToDelete });
    };

    handleHideDeleteResourceConfirmationModal = () => {
        this.setState({ showResourceDeletionConfirmationModal: false, resourceToDelete: {} });
    };

    handleDeleteResource = async () => {
        // Get the resource and set the remove flag to true
        const resourceToDelete = HelperFunctions.deepClone(this.state.resourceToDelete);
        resourceToDelete.removeResourceFromRequest = true;

        this.setState({
            isSubmissionInProgress: true
        });

        try {
            await this.FremontBackendClient.modifyResource([resourceToDelete], this.props.auth);

            // Finally reload all the resources
            await this.fetchResources(true);

            // Show the success flashbar to indicate the request was successful
            this.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({
            isSubmissionInProgress: false,
            showResourceDeletionConfirmationModal: false,
            resourceToDelete: {}
        });
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this);
    };

    handleSubmitUpdate = async (evt) => {
        let resourcesToSubmit;
        if (evt.target.id === Constants.RESOURCE_TYPES.backBoneEngineer) {
            resourcesToSubmit = HelperFunctions.deepClone(this.state.backboneEngineersToUpdate);
        } else if (evt.target.id === Constants.RESOURCE_TYPES.ipEngineer) {
            resourcesToSubmit = HelperFunctions.deepClone(this.state.ipEngineersToUpdate);
        } else if (evt.target.id === Constants.RESOURCE_TYPES.businessDeveloper) {
            resourcesToSubmit = HelperFunctions.deepClone(this.state.businessDevelopersToUpdate);
        } else {
            resourcesToSubmit = HelperFunctions.deepClone(this.state.provisionersToUpdate);
        }
        this.setState({
            isSubmissionInProgress: true
        });

        try {
            const response = await this.FremontBackendClient.modifyResource(resourcesToSubmit, this.props.auth);

            if (evt.target.id === Constants.RESOURCE_TYPES.backBoneEngineer) {
                this.setState({
                    backboneEngineerEditMode: !this.state.backboneEngineerEditMode,
                    backboneEngineers: this.attachInputHandler(response.resources,
                        this.handleBackboneEngineerInputChange, Constants.ATTRIBUTES.resourceType)
                });
            } else if (evt.target.id === Constants.RESOURCE_TYPES.ipEngineer) {
                this.setState({
                    ipEngineerEditMode: !this.state.ipEngineerEditMode,
                    ipEngineers: this.attachInputHandler(response.resources, this.handleIPEngineerInputChange,
                        Constants.ATTRIBUTES.filterStart, Constants.ATTRIBUTES.serviceType)
                });
            } else if (evt.target.id === Constants.RESOURCE_TYPES.businessDeveloper) {
                this.setState({
                    businessDeveloperEditMode: !this.state.businessDeveloperEditMode,
                    businessDevelopers:
                        this.attachInputHandler(response.resources, this.handleBusinessDeveloperInputChange,
                            Constants.ATTRIBUTES.cluster)
                });
            } else {
                this.setState({
                    provisioningEditMode: !this.state.provisioningEditMode,
                    provisioners: this.attachInputHandler(response.resources, this.handleProvisionerInputChange,
                        Constants.ATTRIBUTES.filterStart, Constants.ATTRIBUTES.serviceType)
                });
            }
            this.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({
            isSubmissionInProgress: false
        });
    }

    render() {
        if (this.state.isPageLoading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    isPageLoading={this.state.isSpinnerShown}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return (
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    flashbarLoading={this.state.flashbar.flashbarLoading}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                {this.state.newResourceModalVisible &&
                <NewResourceModalForm
                    newResourceModalVisible={this.state.newResourceModalVisible}
                    newResource={this.state.newResource}
                    handleSubmitNewResource={this.handleSubmitNewResource}
                    handleNewResourceModalDismiss={this.handleNewResourceModalDismiss}
                    handleNewResourceModalInputChange={this.handleNewResourceModalInputChange}
                    handleDismissNewResourceModal={this.handleDismissNewResourceModal}
                    flashbar={this.state.flashbar}
                    newResourceErrorTexts={this.state.newResourceHasBeenSubmitted ?
                        this.state.newResourceErrorTexts : EMPTY_ERROR_TEXTS
                    }
                    posixResources={this.state.posixResources}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                />
                }
                <ConfirmationModal
                    isVisible={this.state.showResourceDeletionConfirmationModal}
                    hideModal={this.handleHideDeleteResourceConfirmationModal}
                    disabled={false}
                    description={
                        <FremontAlert type="warning">
                            You are about to delete an order assignment. This action cannot be undone.
                        </FremontAlert>
                    }
                    cancelButtonText="Cancel"
                    primaryButtonText="Submit"
                    header="Delete this Order Assignment"
                    onClickFunction={this.handleDeleteResource}
                    loading={this.state.isSubmissionInProgress}
                />
                <Box padding={{ top: "l" }}>
                    {(this.state.permissions.nest || this.state.permissions.manager) ?
                        <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                            <ManageOrderAssignmentForm
                                backboneEngineers={this.state.backboneEngineers}
                                backboneEngineersToUpdate={this.state.backboneEngineersToUpdate}
                                provisioners={this.state.provisioners}
                                provisionersToUpdate={this.state.provisionersToUpdate}
                                businessDevelopers={this.state.businessDevelopers}
                                businessDevelopersToUpdate={this.state.businessDevelopersToUpdate}
                                ipEngineers={this.state.ipEngineers}
                                ipEngineersToUpdate={this.state.ipEngineersToUpdate}
                                handleEditModeChange={this.handleEditModeChange}
                                provisioningEditMode={this.state.provisioningEditMode}
                                businessDeveloperEditMode={this.state.businessDeveloperEditMode}
                                backboneEngineerEditMode={this.state.backboneEngineerEditMode}
                                ipEngineerEditMode={this.state.ipEngineerEditMode}
                                handleSubmitUpdate={this.handleSubmitUpdate}
                                isSubmissionInProgress={this.state.isSubmissionInProgress}
                                handleAddNewResource={this.handleAddNewResourceModal}
                                permissions={this.state.permissions}
                                posixResources={this.state.posixResources}
                                handleShowDeleteResourceConfirmationModal={
                                    this.handleShowDeleteResourceConfirmationModal
                                }
                                mouseHover={this.state.mouseHover}
                            />
                        </div> :
                        <div>
                            <FremontAlert header="No Access to This Page" type="error">
                                User is not in valid POSIX groups. Please contact your manager to get valid permissions.
                            </FremontAlert>
                        </div>
                    }
                </Box>
            </div>
        );
    }
}

export default ManageOrderAssignment;