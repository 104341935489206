import React, { useState, useEffect } from "react";
import {
    Alert,
    Box,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
    CollectionPreferences
} from "@amzn/awsui-components-react/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { noop } from "lodash";
import Constants from "../utils/Constants";
import { LinkServiceButton, LinkServiceToggle } from "./CommonComponents";
import CutsheetTableData, { BACKEND_CUTSHEET_UPLOAD_STATUS } from "../cutsheet/CutsheetTableData";
import HelperFunctions from "../common/HelperFunctions";
import DeleteLinksModal from "./DeleteLinksModal";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";

/**
 * Utility functions used by LinkServiceTable
 * */
export const canFetchMore = nextToken => (typeof nextToken) !== "undefined";
const pageLabel = pageNumber => `Page ${pageNumber} of all pages`;
export const getFilterCounterText = count => `${count} ${count === 1 ? "match" : "matches"}`;
export const onNextPageClick = ({ nextToken = false, fetch = noop } = {}) => async () => {
    if (nextToken) {
        await fetch();
    }
};

export const Preferences = ({
    preferences,
    setPreferences,
    contentDisplayPreference,
    entity,
    disabled
}) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => {
            setPreferences(detail);
            HelperFunctions.setHiddenColumnsCookie(entity,
                (detail.contentDisplay || []).filter(c => !c.visible).map(c => c.id).join(","));
        }}
        pageSizePreference={{
            title: "Page size",
            options: [
                { value: 10, label: "10 Items" },
                { value: 25, label: "25 Items" },
                { value: 50, label: "50 Items" },
                { value: 100, label: "100 Items" }
            ]
        }}
        wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines"
        }}
        contentDisplayPreference={contentDisplayPreference}
    />
);

export const renderLoadMoreButton = ({
    loading,
    nextToken,
    onNextPageClickHelper
}) => (
    <LinkServiceButton
        iconName={canFetchMore(nextToken) ? "download" : null}
        disabled={!canFetchMore(nextToken)}
        loading={loading}
        variant="primary"
        onClick={onNextPageClick(onNextPageClickHelper)}
    >
        {canFetchMore(nextToken) ? "Load More Data" : "All Data Loaded"}
    </LinkServiceButton>);

export const TableEmptyState = ({ entityName }) => (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
        <div>
            <b>No {entityName.toLowerCase()}s</b>
        </div>
    </Box>
);

export const renderEmpty = ({ entity, emptyTableMessage }) =>
    (<TableEmptyState entityName={entity} emptyTableMessage={emptyTableMessage}/>);

export const TableNoMatchState = props => (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
        <SpaceBetween size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_LINK_PADDING}>
            <div>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    {"We can't find a match."}
                </Box>
            </div>
            <LinkServiceButton onClick={props.onClearFilter}>Clear filter</LinkServiceButton>
        </SpaceBetween>
    </Box>
);

export const filterTableData = (item, text) => {
    const linkDetailsAttributes = HelperFunctions.extractRecordAttributes(item) || {};
    const cutsheetUploadFields = [
        item.fileName,
        item.fileSubType,
        item.fileFailureReason,
        BACKEND_CUTSHEET_UPLOAD_STATUS[item.fileStatus],
        CutsheetTableData.getCreatedByFromActor(item),
        CutsheetTableData.renderTimeFromTimestamp(item.createdTime),
        item.fileId
    ];
    const linkDetailsFields = [
        item.aEndPort,
        item.bEndPort,
        item.aEndClientPort,
        item.bEndClientPort,
        item.lifecycleState,
        item.encryptionIntent,
        item.encryptionCapability,
        linkDetailsAttributes[Constants.ATTRIBUTES.fibre_id],
        linkDetailsAttributes[Constants.ATTRIBUTES.a_end_dark_fibre_handoff],
        linkDetailsAttributes[Constants.ATTRIBUTES.b_end_dark_fibre_handoff],
        linkDetailsAttributes[Constants.ATTRIBUTES.a_end_cross_connection],
        linkDetailsAttributes[Constants.ATTRIBUTES.b_end_cross_connection],
        linkDetailsAttributes[Constants.ATTRIBUTES.fibre_service_id],
        linkDetailsAttributes[Constants.ATTRIBUTES.maintenance_group],
        linkDetailsAttributes[Constants.ATTRIBUTES.a_b_degree],
        linkDetailsAttributes[Constants.ATTRIBUTES.type],
        linkDetailsAttributes[Constants.ATTRIBUTES.cm_or_tt_url],
        linkDetailsAttributes[Constants.ATTRIBUTES.vendor_url],
        linkDetailsAttributes[Constants.ATTRIBUTES.sol_loss_1550_nm],
        linkDetailsAttributes[Constants.ATTRIBUTES.optical_distance_km],
        linkDetailsAttributes[Constants.ATTRIBUTES.fibre_trail],
        linkDetailsAttributes[Constants.ATTRIBUTES.fabric],
        linkDetailsAttributes[Constants.ATTRIBUTES.wavelength],
        linkDetailsAttributes[Constants.ATTRIBUTES.channel_capacity_gbps]
    ];
    const valuesToMatch = [...cutsheetUploadFields, ...linkDetailsFields];

    return valuesToMatch.some((val) => {
        if (!text) return true;
        if (!val) return false;
        return val.toString().toUpperCase().includes(text.toUpperCase());
    });
};

export default function LinkServiceTable(props) {
    const columnsString = HelperFunctions.getHiddenColumnsCookie(props.entity);
    const hiddenColumns = columnsString ? columnsString.split(",") : [];
    const [preferences, setPreferences] = useState({
        pageSize: 100,
        wrapLines: true,
        contentDisplay: props.columnDefinitions.map(c => ({
            id: c.id,
            visible: !hiddenColumns.includes(c.id)
        }))
    });

    const contentDisplayPreference = {
        title: "Column preferences",
        options: props.columnDefinitions.map(c => ({
            id: c.id,
            label: c.header
        }))
    };

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [consumersForAllLinks, setConsumersForAllLinks] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [isDeleteModalVisible]);

    const onDeleteButtonClick = async () => {
        setIsDeleteModalVisible(true);

        const client = new LinkServiceBackendClient();
        const getConsumers = item => HelperFunctions.getValueFromRecordAttributes(item, Constants.ATTRIBUTES.consumers);

        const linkConsumers = (await Promise.all(
            props.selectedItems.map(item => (
                getConsumers(item) ?
                    ({ Consumers: JSON.parse(getConsumers(item)) }) :
                    client.listConsumers(Constants.LINK_INSTANCE_ID_PATTERN + item.instanceId)
            ))
        )).map(response => response.Consumers.map(id => HelperFunctions.getUuidFromFullLinkId(id)));

        const linkConsumerItems = await Promise.all(
            linkConsumers.map(consumers => client.getBatchLinkInstance(consumers))
        );

        setConsumersForAllLinks(linkConsumerItems);
    };

    // eslint-disable-next-line no-use-before-define
    const onClearFilter = () => actions.setFiltering("");
    const {
        items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps
    } = useCollection( // https://polaris.a2z.com/get_started/dev_guides/collection_hooks/
        props.tableItems,
        {
            filtering: {
                noMatch: (
                    <TableNoMatchState
                        loading={props.loading}
                        nextToken={props.nextToken}
                        onNextPageClickHelper={props.onNextPageClickHelper}
                        onClearFilter={onClearFilter}
                    />
                ),
                filteringFunction: filterTableData
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            ...(props.supportSelection && { selection: {} })
        }
    );
    const selectionProps = props.isDownloadEnabled || props.isDeleteEnabled ? {
        selectionType: "multi",
        onSelectionChange: ({ detail }) => {
            const existingSelectedItemsIds = props.selectedItems.map(item => item.instanceId);
            const newSelectedItems = detail.selectedItems.filter(
                item => props.selectedItems.includes(item) || !existingSelectedItemsIds.includes(item.instanceId)
            );
            props.setSelectedItems(newSelectedItems);
        },
        selectedItems: props.selectedItems
    } : {};
    const onHandleSwitchEditMode = () => {
        props.handleSwitchEditMode();
        props.setSelectedItems([]);
    };
    const onSubmitClick = () => {
        props.handleSubmit();
        props.setSelectedItems([]);
    };

    return (
        <div>
            {isDeleteModalVisible ? <DeleteLinksModal
                items={props.selectedItems}
                setIsVisible={setIsDeleteModalVisible}
                consumersForAllLinks={consumersForAllLinks}
                updateLinkHierarchy={props.updateLinkHierarchy}
                handleFlashbarMessageFromChildTabs={props.handleFlashbarMessageFromChildTabs}
                handleFlashbarMessageFromSearchPage={props.handleFlashbarMessageFromSearchPage}
                linkType={props.entity}
                updateTableItems={props.updateTableItems}
                isSearchPage={props.isSearchPage}
                setSelectedItems={props.setSelectedItems}
            /> : null}
            <Table
                {...collectionProps}
                {...selectionProps}
                columnDefinitions={props.columnDefinitions}
                columnDisplay={preferences.contentDisplay}
                items={items}
                resizableColumns
                wrapLines={preferences.wrapLines}
                loading={props.loading}
                loadingText={`Loading ${props.entity}s`}
                empty={renderEmpty(props)}
                isItemDisabled={item => props.inEditMode && item.isNonEditable}
                filter={props.filter ? props.filter
                    : <TextFilter
                        {...filterProps}
                        filteringAriaLabel={`Filter ${props.entity}`}
                        filteringPlaceholder={`Find ${props.entity}`}
                        countText={getFilterCounterText(filteredItemsCount)}
                    />
                }
                header={
                    <Header
                        variant="h4"
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_BUTTON_PADDING}
                            >
                                {props.uploadEntityLink &&
                                    <LinkServiceButton
                                        iconName="add-plus"
                                        variant="primary"
                                        onClick={props.uploadEntityLink}
                                    >
                                        {`Upload ${props.entity}`}
                                    </LinkServiceButton>
                                }
                                {props.downloadEntityLink &&
                                    <LinkServiceButton
                                        iconName="download"
                                        variant="primary"
                                        onClick={props.downloadEntityLink}
                                    >
                                        Cutsheet Templates
                                    </LinkServiceButton>
                                }
                                {props.editable && !props.inEditMode &&
                                    <LinkServiceButton
                                        iconName="edit"
                                        variant="primary"
                                        onClick={onHandleSwitchEditMode}
                                        disabled={props.editButtonDisabled}
                                    />
                                }
                                {props.editable && props.inEditMode && props.handleThirdPartyLinks &&
                                    <Alert
                                        statusIconAriaLabel="Warning"
                                        type="warning"
                                        header={Constants.CUTSHEET_HANDLE_THIRD_PARTY_LINKS_TOGGLE_MESSAGE}
                                    />
                                }
                                {props.editable && props.inEditMode &&
                                    <LinkServiceToggle
                                        id="handleThirdPartyLinksTableToggle"
                                        disabled={false}
                                        checked={props.handleThirdPartyLinks}
                                        onChange={props.handleThirdPartyToggleChange}
                                    >
                                        Third Party Links
                                    </LinkServiceToggle>
                                }
                                {props.editable && props.inEditMode &&
                                    <LinkServiceButton
                                        onClick={onHandleSwitchEditMode}
                                    >
                                        Cancel
                                    </LinkServiceButton>
                                }
                                {props.editable && props.inEditMode &&
                                    <LinkServiceButton
                                        variant="primary"
                                        loading={props.submissionInProgress}
                                        disabled={props.submitDisabled}
                                        onClick={onSubmitClick}
                                    >
                                        Submit
                                    </LinkServiceButton>
                                }
                                {props.isDownloadEnabled &&
                                    <LinkServiceButton
                                        variant="primary"
                                        iconName="download"
                                        iconAlign="left"
                                        onClick={() =>
                                            HelperFunctions.downloadTableAsCutsheet(
                                                props.tableItems, props.downloadableColumnDefinitions
                                            )}
                                        loading={false}
                                        disabled={!props.tableItems.length}
                                    >
                                        Download All Records
                                    </LinkServiceButton>
                                }
                                {props.isDownloadEnabled &&
                                    <LinkServiceButton
                                        variant="primary"
                                        iconName="download"
                                        iconAlign="left"
                                        onClick={() =>
                                            HelperFunctions.downloadTableAsCutsheet(
                                                props.selectedItems, props.downloadableColumnDefinitions
                                            )}
                                        loading={false}
                                        disabled={!props.selectedItems.length}
                                    >
                                        Download Selected Records
                                    </LinkServiceButton>
                                }
                                {props.isDeleteEnabled &&
                                    <LinkServiceButton
                                        variant="primary"
                                        iconName="remove"
                                        iconAlign="left"
                                        onClick={onDeleteButtonClick}
                                        loading={false}
                                        disabled={!props.selectedItems.length || props.inEditMode}
                                    >
                                        Delete Selected Records
                                    </LinkServiceButton>
                                }
                                {props.loadMoreEnabled && renderLoadMoreButton(props)}
                            </SpaceBetween>
                        }
                    >
                        {props.entity} Table {props.headerTextSuffix} {
                            HelperFunctions.getTotalRecordHeader(filteredItemsCount)
                        }
                    </Header>}
                pagination={
                    <Pagination
                        {...paginationProps}
                        openEnd={canFetchMore(props.nextToken)}
                        ariaLabels={{
                            nextPageLabel: "Next page",
                            previousPageLabel: "Previous page",
                            pageLabel
                        }}
                    />}
                preferences={
                    <Preferences
                        preferences={preferences}
                        setPreferences={setPreferences}
                        contentDisplayPreference={contentDisplayPreference}
                        entity={props.entity}
                    />}
            />
        </div>
    );
}
