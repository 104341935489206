import React from "react";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class NoteTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "createdTime",
            sortingField: "createdTime",
            header: "Created Time",
            description: "Note Creation Time",
            cell: item => HelperFunctions.formatDateAndTime(item.createdTime, Constants.YYYYDDMM_HHMMSS_FORMAT),
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn
        },
        {
            id: "createdBy",
            header: "Created By",
            description: "Note Created By",
            cell: item => item.createdBy,
            width: 120
        },
        {
            id: "note",
            header: "Note",
            description: "Note Information",
            cell: item => <span className="pre-wrap">{item.noteBody}</span>,
            width: 640
        }
    ];
}