import React from "react";
import {
    Box,
    Container,
    FormField,
    Grid
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const TopologyHeader = () => (
    (
        <div key={`${0}${Constants.SEPARATOR}${0}`}>
            <Grid gridDefinition={
                [
                    { colspan: { default: 1 } },
                    { colspan: { default: 2 } },
                    { colspan: { default: 2 } },
                    { colspan: { default: 1 } },
                    { colspan: { default: 1 } },
                    { colspan: { default: 2 } },
                    { colspan: { default: 2 } }
                ]
            }
            >
                <div>
                    Position
                </div>
                <div>
                    Source System
                </div>
                <div>
                    Order
                </div>
                <div>
                    Customer Fabric
                </div>
                <div>
                    Circuit(s)
                </div>
                <div>
                    Site A
                </div>
                <div>
                    Site Z
                </div>
            </Grid>
        </div>
    )
);

export const determineSiteToDisplay = (isSiteA, topologyObject, orderMap, siteOptions, componentIdToObjectMap) => {
    if (topologyObject.orderId && orderMap[topologyObject.orderId]) {
        const tempSite = orderMap[topologyObject.orderId][isSiteA ? "siteAName" : "siteZName"];
        return { label: tempSite, value: tempSite };
    }
    const siteId = isSiteA ? "siteAId" : "siteZId";
    if (topologyObject[siteId]) {
        const site = componentIdToObjectMap[topologyObject[siteId]];
        if (site) {
            return site.siteName;
        }
        if (siteOptions.length !== 0) {
            const tempSite = siteOptions.filter(siteOption =>
                siteOption.value === topologyObject[siteId]).find(Boolean);
            return { label: tempSite.siteName, value: tempSite.value };
        }
    }
    return "";
};

export const determineCustomerFabricToDisplay = (topologyObject, orderMap) => {
    if (topologyObject.sourceSystem === Constants.TOPOLOGY_CONSTANTS.linkService) {
        return !topologyObject.topologyCustomerFabric ? "" : topologyObject.topologyCustomerFabric;
    } else if (topologyObject.orderId && orderMap[topologyObject.orderId]) {
        return orderMap[topologyObject.orderId][Constants.ATTRIBUTES.customerFabric];
    }
    return "";
};

const isTopologySearchButtonDisabled = (
    {
        isDisabled,
        orderMap,
        topologyMap,
        topologyMapPosition,
        arrayPosition,
        ordersThatDontExist
    }
) =>
    isDisabled
|| Object.keys(orderMap).includes(topologyMap[topologyMapPosition][arrayPosition].orderId)
|| topologyMap[topologyMapPosition][arrayPosition].sourceSystem
        === Constants.TOPOLOGY_CONSTANTS.linkService
|| ordersThatDontExist.includes(topologyMap[topologyMapPosition][arrayPosition].orderId);

export const TopologyRow = (props) => {
    const {
        arrayPosition,
        errorTexts,
        isDisabled,
        ordersThatDontExist,
        topologyMap,
        topologyMapPosition
    } = props;
    return (
        (
            <div
                key={`${props.topologyMapPosition}${Constants.SEPARATOR}${props.arrayPosition}`}
            >
                <Grid gridDefinition={
                    [
                        { colspan: { default: 1 } },
                        { colspan: { default: 2 } },
                        { colspan: { default: 2 } },
                        { colspan: { default: 1 } },
                        { colspan: { default: 1 } },
                        { colspan: { default: 2 } },
                        { colspan: { default: 2 } },
                        { colspan: { default: 1 } }
                    ]
                }
                >
                    <div>
                        <div>
                            <FormField
                                errorText={Object.keys(props.errorTexts).length === 0 ?
                                    "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].position}
                                stretch
                            >
                                <FremontInput
                                    id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.TOPOLOGY_CONSTANTS.position}`}
                                    disabled={props.isDisabled}
                                    value={props.topologyMapPosition}
                                    onInput={props.handleStageInputChange}
                                    type={Constants.NUMBER}
                                    disableBrowserAutocorrect
                                    inputMode="numeric"
                                />
                            </FormField>
                        </div>
                    </div>
                    <div>
                        <div>
                            <FormField
                                errorText={Object.keys(props.errorTexts).length === 0 ? "" :
                                    props.errorTexts[props.topologyMapPosition][props.arrayPosition].sourceSystem}
                                stretch
                            >
                                <FremontSelect
                                    id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.TOPOLOGY_CONSTANTS.sourceSystem}`}
                                    disabled={props.isDisabled}
                                    onChange={props.handleStageInputChange}
                                    empty="No sources found."
                                    options={HelperFunctions.createSelectedOptions(Constants.TOPOLOGY_SOURCE_TYPES)}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.topologyMap[props.topologyMapPosition][props.arrayPosition].sourceSystem
                                    )}
                                    expandToViewport
                                />
                            </FormField>
                        </div>
                    </div>
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].orderId}
                            stretch
                        >
                            <Grid gridDefinition={[{ colspan: { default: 8 } }, { colspan: { default: 4 } }]}>
                                <div>
                                    <FremontInput
                                        id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.orderId}`}
                                        disabled={props.isDisabled ||
                                        props.topologyMap[props.topologyMapPosition][props.arrayPosition].sourceSystem
                                            === Constants.TOPOLOGY_CONSTANTS.linkService}
                                        value={
                                            props.topologyMap[props.topologyMapPosition][props.arrayPosition].orderId
                                        }
                                        onInput={props.handleStageInputChange}
                                    />
                                </div>
                                <div>
                                    <FremontButton
                                        id={props.topologyMap[props.topologyMapPosition][props.arrayPosition].orderId}
                                        disabled={isTopologySearchButtonDisabled(props)}
                                        loading={props.isSearchingForOrders}
                                        iconName="search"
                                        onClick={props.handleSearchForOrder}
                                    />
                                </div>
                            </Grid>
                        </FormField>
                    </div>
                    <div>
                        <div>
                            <FormField
                                errorText={
                                    Object.keys(errorTexts).length === 0 ? "" :
                                        errorTexts[topologyMapPosition][arrayPosition].topologyCustomerFabric
                                }
                                stretch
                            >
                                <FremontSelect
                                    id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.TOPOLOGY_CONSTANTS.topologyCustomerFabric}`}
                                    disabled={props.isDisabled ||
                                    props.topologyMap[props.topologyMapPosition][props.arrayPosition].sourceSystem
                                        === Constants.TOPOLOGY_CONSTANTS.fremont}
                                    onChange={props.handleStageInputChange}
                                    options={HelperFunctions.createSelectedOptions(
                                        Constants.TOPOLOGY_LINK_SERVICE_CUSTOMER_FABRIC_TYPES
                                    )}
                                    empty="No customer fabrics found."
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        determineCustomerFabricToDisplay(
                                            props.topologyMap[props.topologyMapPosition][props.arrayPosition],
                                            props.orderMap
                                        )
                                    )}
                                    expandToViewport
                                />
                            </FormField>
                        </div>
                    </div>
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].circuitQuantity}
                            stretch
                        >
                            <FremontInput
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.circuitQuantity}`}
                                disabled={isDisabled ||
                                (Constants.TOPOLOGY_CONSTANTS.fremont
                                    === topologyMap[topologyMapPosition][arrayPosition].sourceSystem
                                    && ordersThatDontExist.includes(
                                        topologyMap[topologyMapPosition][arrayPosition].orderId
                                    ))}
                                value={topologyMap[topologyMapPosition][arrayPosition].circuitQuantity}
                                onInput={props.handleStageInputChange}
                                type={Constants.NUMBER}
                                disableBrowserAutocorrect
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].siteAId}
                            stretch
                        >
                            <FremontSelect
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.siteAId}`}
                                disabled={props.isDisabled ||
                                props.topologyMap[props.topologyMapPosition][props.arrayPosition].sourceSystem
                                    === Constants.TOPOLOGY_CONSTANTS.fremont}
                                selectedOption={determineSiteToDisplay(
                                    true,
                                    props.topologyMap[props.topologyMapPosition][props.arrayPosition],
                                    props.orderMap,
                                    props.siteOptions,
                                    props.componentIdToObjectMap
                                )}
                                options={props.siteOptions}
                                onChange={props.handleStageInputChange}
                                filteringType="auto"
                                disableBrowserAutocorrect
                                expandToViewport
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            errorText={Object.keys(props.errorTexts).length === 0 ?
                                "" : props.errorTexts[props.topologyMapPosition][props.arrayPosition].siteZId}
                            stretch
                        >
                            <FremontSelect
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.ATTRIBUTES.siteZId}`}
                                disabled={props.isDisabled ||
                                props.topologyMap[props.topologyMapPosition][props.arrayPosition].sourceSystem
                                    === Constants.TOPOLOGY_CONSTANTS.fremont}
                                selectedOption={determineSiteToDisplay(
                                    false,
                                    props.topologyMap[props.topologyMapPosition][props.arrayPosition],
                                    props.orderMap,
                                    props.siteOptions,
                                    props.componentIdToObjectMap
                                )}
                                options={props.siteOptions}
                                onChange={props.handleStageInputChange}
                                filteringType="auto"
                                disableBrowserAutocorrect
                                expandToViewport
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            stretch
                        >
                            <FremontButton
                                id={`${Constants.ATTRIBUTES.topologyMap}${Constants.SEPARATOR}${props.topologyMapPosition}${Constants.UUID_SEPARATOR}${props.arrayPosition}${Constants.UUID_SEPARATOR}${Constants.TOPOLOGY_CONSTANTS.remove}`}
                                iconName="close"
                                disabled={props.isDisabled}
                                onClick={props.handleStageInputChange}
                            />
                        </FormField>
                    </div>
                </Grid>
            </div>
        )
    );
};

export const TopologyDisplayMode = props => (
    /* the two divs below 'fremont-scroll-outer' and 'fremont-scroll' work together
        to allow Topology rows to be horizontally scrollable.
        The reason is very complex CSS limitations, if fremont-scroll was:
            min-width: 1000px;
            overflow-x: scroll;
        Then both of these properties would be applied to div and visually you would see the
        topology rows leaking out of the awsui-util-container.
        By keeping these divs separate, my understanding is that we can force the parent's
        'overflow: hidden' property to be applied to fremont-scroll and then the outer div will do the
        scrolling inside the awsui-util-container. It just works.
    */
    <Container>
        <div className="fremont-scroll-outer">
            <div className="fremont-scroll">
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <TopologyHeader/>
                    {(Object.keys(props.order.topologyMap).length > 0) ?
                        Object.keys(props.order.topologyMap).map(topologyMapPosition =>
                            props.order.topologyMap[topologyMapPosition].map((topologyObject, index) =>
                                (
                                    <div key={topologyObject.uuid}>
                                        <TopologyRow
                                            topologyMap={props.order.topologyMap}
                                            isDisabled
                                            topologyMapPosition={topologyMapPosition}
                                            arrayPosition={index}
                                            orderMap={props.orderMap}
                                            errorTexts=""
                                            isSearchingForOrders={props.isSearchingForOrders}
                                            handleStageInputChange={props.handleStageInputChange}
                                            handleSearchForOrder={props.handleSearchForOrder}
                                            componentIdToObjectMap={props.componentIdToObjectMap}
                                            siteOptions={props.siteOptions}
                                        />
                                    </div>
                                )))
                        :
                        <Box textAlign="center">
                            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                <b>
                                    {Constants.EMPTY_TABLE_MESSAGES.noTopology}
                                </b>
                            </Box>
                        </Box>
                    }
                </div>
            </div>
        </div>
    </Container>
);

export const TopologyEditMode = props => (
    /* the two divs below 'fremont-scroll-outer' and 'fremont-scroll' work together
    to allow Topology rows to be horizontally scrollable.
    The reason is very complex CSS limitations, if fremont-scroll was:
        min-width: 1000px;
        overflow-x: scroll;
    Then both of these properties would be applied to div and visually you would see the
    topology rows leaking out of the awsui-util-container.
    By keeping these divs separate, my understanding is that we can force the parent's
    'overflow: hidden' property to be applied to fremont-scroll and then the outer div will do the
    scrolling inside the awsui-util-container. It just works.
    */
    <Container>
        <div className="fremont-scroll-outer">
            <div className="fremont-scroll">
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <TopologyHeader/>
                    {(Object.keys(props.order.topologyMap).length > 0) ?
                        Object.keys(props.order.topologyMap).map(topologyMapPosition =>
                            props.order.topologyMap[topologyMapPosition].map((topologyObject, index) =>
                                (
                                    <div>
                                        <div key={topologyObject.uuid}>
                                            <TopologyRow
                                                topologyMap={props.order.topologyMap}
                                                isDisabled={!!props.allFieldsDisabled}
                                                topologyMapPosition={topologyMapPosition}
                                                arrayPosition={index}
                                                orderMap={props.orderMap}
                                                ordersThatDontExist={props.ordersThatDontExist}
                                                isSearchingForOrders={props.isSearchingForOrders}
                                                errorTexts={props.hasBeenSubmittedOnce ? props.errorTexts : {}}
                                                handleStageInputChange={props.handleStageInputChange}
                                                handleSearchForOrder={props.handleSearchForOrder}
                                                componentIdToObjectMap={props.componentIdToObjectMap}
                                                siteOptions={props.siteOptions}
                                            />
                                        </div>
                                    </div>
                                ))) :
                        <Box textAlign="center">
                            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                <b>
                                    {Constants.EMPTY_TABLE_MESSAGES.noTopology}
                                </b>
                            </Box>
                        </Box>
                    }
                    <Box
                        padding={{ top: "m" }}
                        margin={{ bottom: "xxxs" }}
                        color="text-label"
                    >
                        <div>
                            <div className="fremont-empty-div"/>
                            <FremontButton
                                onClick={props.handleAddRowToTopologyMap}
                            >
                                Add New Position
                            </FremontButton>
                        </div>
                    </Box>
                </div>
            </div>
        </div>
    </Container>
);
