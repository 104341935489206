import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderRejectionModal from "order/OrderRejectionModal";
import { OrderAcceptanceStageDisplayMode } from "order/stages/OrderAcceptanceInformation";

class OrderAcceptanceHandler extends Component {
    state = {
        errorText: "",
        isAddRejectionClicked: false,
        isAddRejectionInProgress: false,
        isUpdateStageInProgress: false,
        rejectionBody: ""
    };

    handleCloseRejectionModal = () => {
        this.setState({
            isAddRejectionClicked: false
        });
    }

    generateCircuitItems = () => {
        const circuitItemsObject = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.props.circuitDesignObjects,
            false,
            false,
            this.state.isUpdateStageInProgress,
            {},
            this.props.blockers,
            false
        );

        // Use Object.assign to add custom columns for this stages circuitItemsObject. Already has circuitDesignID,
        // number, siteA, stageStatusMap, editable, hasStageSubmittedOnce, isUpdateStageInProgress

        if (circuitItemsObject.static.length > 0) {
            circuitItemsObject.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.ATTRIBUTES.revisionNumber]: staticCircuitDesign[Constants.ATTRIBUTES.revisionNumber],
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.TABLE_IDS.providerLink]: {
                        label: this.props.order.providerName,
                        id: this.props.order.providerName
                    }
                }));
        }

        return circuitItemsObject.static;
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * Order has been accepted, moves stage to Port Reservation in Capacity Engineering
     * Moves all circuit designs from Design to Provisioning
     */
    handleOrderAccepted = async () => {
        const updatedOrder = HelperFunctions.deepClone(this.props.order);
        updatedOrder.hasBeenApproved = true;
        updatedOrder.hasBeenSubmittedForApproval = true;
        HelperFunctions.dismissFlashbar(this, {
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true
        });
        this.props.handleStageInEditOrSubmitMode(this.state.isUpdateStageInProgress);
        try {
            await this.FremontBackendClient.updateOrderInfo(updatedOrder, this.props.order, this.props.auth);
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true);
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.props.handleStageInEditOrSubmitMode(false);
        }
        this.setState({
            isUpdateStageInProgress: false
        });
    };

    /**
     * Order has been rejected, opens up a rejection modal so explanation can be provided
     */
    handleOrderRejected = async () => {
        this.setState({
            isAddRejectionClicked: true
        });
    };

    /**
     * This function handles change of inputs to the rejection field.
     */
    handleRejectionInputChange = (evt) => {
        const rejectionBody = evt.detail.value;
        const errorText = HelperFunctions.validateInfo(rejectionBody,
            Constants.VALIDATE_INFO_OPTIONS.required);
        this.setState({
            rejectionBody,
            errorText
        });
    };

    /**
     * This function submits a "rejectionBody" request to the backend
     * then moves stage back to Business Developer (buttons greyed out)
     */
    handleAddRejectionSubmit = async () => {
        const errorText = HelperFunctions.validateInfo(this.state.rejectionBody,
            Constants.VALIDATE_INFO_OPTIONS.required);
        if (errorText) {
            this.setState({
                errorText
            });
            return;
        }
        // This is used for dismissing the existing flashbar on the rejections page
        HelperFunctions.dismissFlashbar(this, {
            isAddRejectionInProgress: true,
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true
        });

        // Create note
        try {
            await this.FremontBackendClient.createNote([{
                entityType: Constants.NOTE_ENTITY_TYPES.order,
                entityId: this.props.order.orderId,
                noteBody: `${Constants.REJECTION_REASON} ${this.state.rejectionBody}`
            }],
            this.props.auth);
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            return;
        }

        // Update order
        try {
            // Need to get order because note id list has changed above
            const updatedOrder = HelperFunctions.deepClone(this.props.order);
            updatedOrder.hasBeenApproved = false;
            updatedOrder.hasBeenSubmittedForApproval = false;
            await this.FremontBackendClient.updateOrderInfo(updatedOrder, this.props.order, this.props.auth);
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true);
            this.setState({
                isAddRejectionClicked: false,
                isAddRejectionInProgress: false,
                isUpdateStageInProgress: false
            });
        } catch (error) {
            // This is used for showing the flashbar error message on the provider rejections page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <div>
                <OrderAcceptanceStageDisplayMode
                    stageName={Constants.STAGE_NAMES.orderAcceptance}
                    order={this.props.order}
                    user={this.props.user}
                    circuitItems={this.generateCircuitItems()}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    handleOrderAccepted={this.handleOrderAccepted}
                    handleOrderRejected={this.handleOrderRejected}
                    goToComponentAction={this.props.goToComponentAction}
                    componentIdToObjectMap={this.props.componentIdToObjectMap}
                    loadingComponentIdToObjectMap={this.props.loadingComponentIdToObjectMap}
                    circuitDesignObjects={this.props.circuitDesignObjects}
                    circuitDesignsLoading={this.props.circuitDesignsLoading}
                    isDataLoaded={this.props.isDataLoaded}
                    editButtonsDisabled={this.props.editButtonsDisabled}
                />
                <OrderRejectionModal
                    isAddRejectionClicked={this.state.isAddRejectionClicked}
                    isAddRejectionInProgress={this.state.isAddRejectionInProgress}
                    handleRejectionInputChange={this.handleRejectionInputChange}
                    handleAddRejectionSubmit={this.handleAddRejectionSubmit}
                    errorText={this.state.errorText}
                    rejectionBody={this.state.rejectionBody}
                    handleCloseRejectionModal={this.handleCloseRejectionModal}
                />
            </div>
        );
    }
}

export default OrderAcceptanceHandler;
