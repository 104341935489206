import React from "react";
import {
    Link,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import FremontConstants from "../../../utils/Constants";
import {
    LinkServiceInput,
    LinkServiceSelect,
    LinkServiceExpandableSection,
    TabularDisplayComponent
} from "../components/CommonComponents";
import HelperFunctions from "../common/HelperFunctions";

const nonEditableFields = [
    Constants.ATTRIBUTES.fibre_id,
    Constants.ATTRIBUTES.fibre_trail
];

const attributesToHide = [
    Constants.ATTRIBUTES.b_end_dc
];

const getClientPathName = (item) => {
    const fabric = HelperFunctions.getValueFromRecordAttributes(item, Constants.ATTRIBUTES.fabric);
    const clientPathName = `${fabric}_${item.aEndPort}_${item.bEndPort}`;
    if (clientPathName.includes("undefined")) {
        return "";
    }
    return clientPathName;
};

export const getConsumesListFromAttributes = (attributes) => {
    const consumesAttribute = attributes
        .filter(attribute => attribute.key === Constants.CONSUMPTION_ATTRIBUTES.consumesList);
    if (consumesAttribute.length > 0) {
        return JSON.parse(consumesAttribute[0].value)
            .map(link => HelperFunctions.getIdentifierFromLinkInstance(link)).join(",");
    }
    return "";
};

export const constructCellId = (linkType, linkId, linkField, attribute = "") =>
    `${linkType}${Constants.SEPARATOR}${linkId}${Constants.SEPARATOR}${linkField}${Constants.SEPARATOR}${attribute}`;

export const truncatedInstanceId = item => item.instanceId && `${item.instanceId.slice(0, 8)}...`;

export const editableCell = (item, fieldName, editable) => (
    editable && !item.isNonEditable ? <LinkServiceInput
        id={constructCellId(
            item.readableLinkType,
            item.instanceId,
            fieldName
        )}
        value={item[fieldName]}
        onChange={item.handleLinkChange}
    /> : item[fieldName]
);

export function handleExpansionForAttributes(item) {
    // Concatenating with column identifier to differentiate with other expandable section
    return (item.checkForExpansion.get(item.instanceId.concat(Constants.ATTRIBUTES.attributes)) ?
        <LinkServiceExpandableSection
            headerText="Expand attributes"
            onChange={e => item.handleExpansionState(e, item.instanceId.concat(Constants.ATTRIBUTES.attributes))}
        >
            <TabularDisplayComponent
                keyValueFields={item.attributesToDisplay
                    .filter(attribute => !attributesToHide.includes(attribute.key))}
                inEditMode={false}
                idPrefix={constructCellId(
                    item.readableLinkType,
                    item.instanceId,
                    Constants.ATTRIBUTES.attributesToDisplay
                )}
                onChange={item.handleLinkChange}
            />

        </LinkServiceExpandableSection> :
        <LinkServiceExpandableSection
            headerText="Expand attributes"
            onChange={e => item.handleExpansionState(e, item.instanceId.concat(Constants.ATTRIBUTES.attributes))}
        />);
}

const handleExpansionForAttributesInHistoryTab = item => (
    <LinkServiceExpandableSection
        headerText="Expand attributes"
    >
        <TabularDisplayComponent
            keyValueFields={item.attributesToDisplay
                .filter(attribute => !attributesToHide.includes(attribute.key))}
            inEditMode={false}
            idPrefix={constructCellId(
                item.readableLinkType,
                item.instanceId,
                Constants.ATTRIBUTES.attributesToDisplay
            )}
            onChange={item.handleLinkChange}
        />
    </LinkServiceExpandableSection>
);

export function handleExpansionForConsumingLinks(item) {
    return (
        // Concatenating with column identifier to differentiate with other expandable section
        item.checkForExpansion.get(item.instanceId.concat(Constants.ATTRIBUTES.consuming_links)) ?
            <LinkServiceExpandableSection
                headerText="Expand consuming links"
                onChange={e => item.handleExpansionState(
                    e, item.instanceId.concat(Constants.ATTRIBUTES.consuming_links)
                )}
            >
                {getConsumesListFromAttributes(item.attributesToDisplay)}
            </LinkServiceExpandableSection> :
            <LinkServiceExpandableSection
                headerText="Expand consuming links"
                onChange={e => item.handleExpansionState(
                    e, item.instanceId.concat(Constants.ATTRIBUTES.consuming_links)
                )}
            />
    );
}

export function getLinkIdColumn(linkType, editable) {
    return {
        id: `${linkType}Id`,
        header: "Link ID",
        description: "Link ID",
        minWidth: 135,
        cell: (item) => {
            const id = (
                <Link href={`${FremontConstants.MANGO_ROUTES.linkDetails}/${item.instanceId}`}>
                    {truncatedInstanceId(item)}
                </Link>
            );
            return editable && item.isNonEditable ? (
                <SpaceBetween size="m">
                    {id}
                    <StatusIndicator type="warning">This link is not editable.</StatusIndicator>
                </SpaceBetween>
            ) : id;
        }
    };
}

export function getLifecycleStateColumn(linkType, editable) {
    return {
        id: `${linkType}Lifecycle`,
        header: "Lifecycle State",
        description: "Lifecycle State",
        downloadableValue: item => (item.lifecycleState ? item.lifecycleState : ""),
        downloadableColumnHeader: Constants.DOWNLOADABLE_COLUMNS.state,
        width: 150,
        sortingField: "lifecycleState",
        cell: item => (
            editable && !item.isNonEditable ?
                <LinkServiceSelect
                    id={
                        constructCellId(item.readableLinkType, item.instanceId, Constants.ATTRIBUTES.lifecycleState)
                    }
                    options={HelperFunctions.createSelectedOptions(Object.values(Constants.LIFECYCLE_STATES))}
                    onChange={item.handleLinkChange}
                    selectedOption={HelperFunctions.createSelectedOption(
                        item.lifecycleState
                    )}
                />
                : item.lifecycleState
        )
    };
}

export function getAEndInterfaceColumn(linkType, columName, editable) {
    return {
        id: `${linkType}AEndInterface`,
        header: columName,
        description: "A End Interface",
        downloadableValue: item => (item.aEndPort ? item.aEndPort : ""),
        downloadableColumnHeader: columName,
        sortingComparator: (a, b) => HelperFunctions.sortNumerically(a, b, Constants.ATTRIBUTES.aEndPort),
        minWidth: 250,
        cell: item => editableCell(item, Constants.ATTRIBUTES.aEndPort, editable)
    };
}

export function getBEndInterfaceColumn(linkType, columName, editable) {
    return {
        id: `${linkType}BEndInterface`,
        header: columName,
        description: "B End Interface",
        downloadableValue: item => (item.bEndPort ? item.bEndPort : ""),
        downloadableColumnHeader: columName,
        sortingComparator: (a, b) => HelperFunctions.sortNumerically(a, b, Constants.ATTRIBUTES.bEndPort),
        minWidth: 250,
        cell: item => editableCell(item, Constants.ATTRIBUTES.bEndPort, editable)
    };
}

export function getAEndClientInterfaceColumn(linkType, editable) {
    return {
        id: `${linkType}AEndClientInterface`,
        header: "A End Client Interface",
        description: "A End Client Interface",
        downloadableValue: item => (item.aEndClientPort ? item.aEndClientPort : ""),
        downloadableColumnHeader: Constants.DOWNLOADABLE_COLUMNS.a_end_client_port,
        sortingComparator: (a, b) => HelperFunctions.sortNumerically(a, b, Constants.ATTRIBUTES.aEndClientPort),
        minWidth: 250,
        cell: item => editableCell(item, Constants.ATTRIBUTES.aEndClientPort, editable)
    };
}

export function getBEndClientInterfaceColumn(linkType, editable) {
    return {
        id: `${linkType}BEndClientInterface`,
        header: "B End Client Interface",
        description: "B End Client Interface",
        downloadableValue: item => (item.bEndClientPort ? item.bEndClientPort : ""),
        downloadableColumnHeader: Constants.DOWNLOADABLE_COLUMNS.b_end_client_port,
        sortingComparator: (a, b) => HelperFunctions.sortNumerically(a, b, Constants.ATTRIBUTES.bEndClientPort),
        minWidth: 250,
        cell: item => editableCell(item, Constants.ATTRIBUTES.bEndClientPort, editable)
    };
}

export function getEncryptionCapabilityColumn(linkType, editable) {
    return {
        id: `${linkType}EncryptionCapability`,
        header: "Encryption Capability",
        description: "Encryption Capability",
        sortingField: "encryptionCapability",
        minWidth: 150,
        cell: item => (
            editable && !item.isNonEditable ?
                <LinkServiceSelect
                    id={
                        constructCellId(item.readableLinkType,
                            item.instanceId,
                            Constants.ATTRIBUTES.encryptionCapability)
                    }
                    options={HelperFunctions.createSelectedOptions(
                        Object.values(Constants.ENCRYPTION_CAPABILITIES)
                    )}
                    onChange={item.handleLinkChange}
                    selectedOption={HelperFunctions.createSelectedOption(
                        item.encryptionCapability
                    )}
                />
                : item.encryptionCapability
        )
    };
}

export function getEncryptionIntentColumn(linkType, editable) {
    return {
        id: `${linkType}EncryptionIntent`,
        header: "Encryption Intent",
        description: "Encryption Intent",
        sortingField: "encryptionIntent",
        minWidth: 200,
        cell: item => (
            editable && !item.isNonEditable ?
                <LinkServiceSelect
                    id={
                        constructCellId(item.readableLinkType,
                            item.instanceId,
                            Constants.ATTRIBUTES.encryptionIntent)
                    }
                    options={HelperFunctions.createSelectedOptions(
                        Object.values(Constants.ENCRYPTION_INTENT_OPTIONS)
                    )}
                    onChange={item.handleLinkChange}
                    selectedOption={HelperFunctions.createSelectedOption(
                        item.encryptionIntent
                    )}
                />
                : item.encryptionIntent
        )
    };
}

export function getAttributesColumn(linkType, isHistoryTab = false) {
    return {
        id: `${linkType}Attributes`,
        header: "Attributes",
        description: "Attributes",
        minWidth: 200,
        cell: isHistoryTab ?
            item => handleExpansionForAttributesInHistoryTab(item) :
            item => handleExpansionForAttributes(item)
    };
}
export function getConsumingLinksColumn(linkType) {
    return {
        id: `${linkType}ConsumingLinks`,
        header: "Consuming Links",
        description: "Consuming Links",
        minWidth: 250,
        cell: item => handleExpansionForConsumingLinks(item)
    };
}

export function getClientPathNameColumn(linkType) {
    return {
        id: `${linkType}ClientPathName`,
        header: "Client Path Name",
        description: "Client Path Name",
        downloadableValue: item => getClientPathName(item),
        downloadableColumnHeader: Constants.DOWNLOADABLE_COLUMNS.client_path_name,
        sortingComparator: HelperFunctions.sortDynamicCellValues(getClientPathName),
        minWidth: 250,
        cell: getClientPathName
    };
}

export function columnFromAttribute(linkType, name, attribute, editable = false, width = 250) {
    return {
        id: `${linkType}${name}`,
        header: name,
        description: name,
        downloadableValue: item => HelperFunctions.getValueFromRecordAttributes(item, attribute),
        downloadableColumnHeader: name,
        width,
        sortingComparator: (a, b) => HelperFunctions.sortByAttributeValues(a, b, attribute),
        cell: item => (
            editable && !item.isNonEditable && !nonEditableFields.includes(attribute) ?
                <LinkServiceInput
                    id={constructCellId(
                        item.readableLinkType,
                        item.instanceId,
                        Constants.ATTRIBUTES.attributesToDisplay,
                        attribute
                    )}
                    value={HelperFunctions.getValueFromRecordAttributes(item, attribute)}
                    onChange={evt => item.handleLinkChange(evt, true)}
                />
                : HelperFunctions.getValueFromRecordAttributes(item, attribute)
        )
    };
}

export function columnWithLinkFromAttribute(linkType, name, attribute, editable, minWidth = 250) {
    return {
        id: `${linkType}${name}`,
        header: name,
        description: name,
        downloadableValue: item => HelperFunctions.getValueFromRecordAttributes(item, attribute),
        downloadableColumnHeader: name,
        minWidth,
        sortingComparator: (a, b) => HelperFunctions.sortByAttributeValues(a, b, attribute),
        cell: (item) => {
            const attributeValue = HelperFunctions.getValueFromRecordAttributes(item, attribute);
            const hyperlink = attributeValue ?
                <Link target="_blank" href={attributeValue}>{attributeValue}</Link> :
                null;
            return editable && !item.isNonEditable && !nonEditableFields.includes(attribute) ?
                <LinkServiceInput
                    id={constructCellId(
                        item.readableLinkType,
                        item.instanceId,
                        Constants.ATTRIBUTES.attributesToDisplay,
                        attribute
                    )}
                    value={attributeValue || ""}
                    onChange={evt => item.handleLinkChange(evt, true)}
                />
                : hyperlink;
        }
    };
}
