import React, { Component } from "react";
import {
    ColumnLayout,
    Container,
    Form,
    Header, SpaceBetween, Button, Input
} from "@amzn/awsui-components-react";
import Constants from "../utils/Constants";
import LinkVisualTable from "./LinkVisualTable";
import LinkServiceHeader from "../common/LinkServiceHeader";


export default class LinkVisualizationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            searchTerm: null
        };
        this.handleSearchTermSubmit = this.handleSearchTermSubmit.bind(this);
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    }

    handleSearchTermChange(event) {
        const { value } = event.detail;
        this.setState({
            value,
            searchTerm: null
        });
    }

    handleSearchTermSubmit = async () => {
        const { value } = this.state;
        if (value) {
            this.setState({
                searchTerm: value
            });
        }
    }

    render() {
        return (
            <div>
                <LinkServiceHeader
                    history={this.props.history}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                        <LinkVisualizationSearchComponent
                            handleSearchTermChange={this.handleSearchTermChange}
                            value={this.state.value}
                            handleSearchTermSubmit={this.handleSearchTermSubmit}

                        />
                        {this.state.searchTerm !== null && <LinkVisualTable linkId={this.state.searchTerm}/>}
                    </SpaceBetween>
                </div>
            </div>
        );
    }
}
export const LinkVisualizationSearchComponent = props => (
    <div>
        <Form>
            <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_STAGES}>
                <Container
                    header={
                        <SpaceBetween
                            direction="horizontal"
                            size={Constants.PADDING_SIZES.SPACE_BETWEEN_LINK_PADDING}
                        >
                            <Header variant="h2">Search For Link Object to Visualize</Header>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_STAGES}>
                        <ColumnLayout columns={2}>
                            <Input
                                onChange={props.handleSearchTermChange}
                                value={props.value}
                                placeholder="Enter full link id here"
                                onKeyDown={evt => (evt.detail.key === "Enter" ? props.handleSearchTermSubmit() : {})}
                            />
                            <Button
                                variant="primary"
                                iconName="search"
                                iconAlign="left"
                                onClick={props.handleSearchTermSubmit}
                                disabled={!props.value}
                            >
                                Search
                            </Button>
                        </ColumnLayout>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </Form>
    </div>
);
