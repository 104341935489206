import React, { Component } from "react";
import { AppLayout } from "@amzn/awsui-components-react";
import Constants from "utils/Constants";
import FremontNavigation from "common/FremontNavigation";
import HelperFunctions from "common/HelperFunctions";
import LighthouseContent from "common/LighthouseContent";

export default class FremontAppLayout extends Component {
    state = {
        navigationOpen: false,
        darkModeEnabled: false,
        sandboxModeEnabled: false,
        sideNavError: null,
        searchResults: {},
        allProviders: [],
        allProvidersLoading: false
    };

    componentDidMount = async () => {
        if (!this.props.authToPass.isUserSignedIn() || !this.props.authToPass.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else if (window.location.pathname.includes("fremont")) {
            // We only fetch all providers items if we are on a page that requires it. This will be denoted
            // by the term "fremont" being present in the path name. Linkservice and Lighthouse pages
            // do not need all providers to be fetched
            await this.getAllProviderItems();
        }
    }

    /**
     * This function fetches every provider item that currently exists in Fremont so that the user can choose from
     * a list of providers rather than trying to manually type in the name
     */
    getAllProviderItems = async () => {
        this.setState({ allProvidersLoading: true });
        try {
            const allProviders = await HelperFunctions.getAllProviderItems(this.props.authToPass);
            this.setState({ allProviders, allProvidersLoading: false });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { allProvidersLoading: false });
        }
    };

    /**
     * This method handles setting the value of the sideNavError. This parameter exists because the
     * FremontNavigation component cannot display its own errors, so we pass those errors to the FremontRouter
     * where they are then displayed inside of the FremontHeader component.
     * @param error
     */
    setSideNavError = (error) => {
        this.setState({
            sideNavError: error
        });
    };

    handleNavigationChange = () => {
        this.setState({
            navigationOpen: !this.state.navigationOpen
        });
    };

    handleDarkModeChange = () => {
        this.setState({
            darkModeEnabled: !this.state.darkModeEnabled
        });
    };

    handleSandboxModeChange = () => {
        if (HelperFunctions.isBetaOrGamma()) {
            this.setState({
                sandboxModeEnabled: !this.state.sandboxModeEnabled
            });
            this.props.loadFremont();
        }
    };

    updateSearchResults = (results) => {
        this.setState({
            searchResults: results
        });
    }

    /**
     * This method handles modifying the users' permissions if they are in sandbox mode
     * @returns user object
     */
    handleUser = () => {
        const user = this.props.userResponse;
        // If we are in sandbox mode, we manually modify the users permissions to grant them access to all fields on
        // Fremont orders. We do not grant them access to management-only field,
        // nor do we grant them access to reporting
        if (this.state.sandboxModeEnabled) {
            user.permissions = {
                [Constants.POSIX_GROUPS.FREMONT_AWS_BACKBONE_OPTICAL]: true,
                [Constants.POSIX_GROUPS.FREMONT_AWS_BBONE_CAPENG]: true,
                [Constants.POSIX_GROUPS.FREMONT_AWS_BD]: true,
                [Constants.POSIX_GROUPS.FREMONT_AWS_BIZOPS]: true,
                [Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG]: true,
                [Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS]: true,
                [Constants.POSIX_GROUPS.FREMONT_AWS_REPORTING_READER]: false,
                [Constants.POSIX_GROUPS.FREMONT_AWS_REPORTING_AUTHOR]: false,
                [Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT]: false,
                [Constants.POSIX_GROUPS.NEST]: false
            };
        }

        return user;
    };

    render() {
        return (
            <AppLayout
                className={`awsui-util-no-gutters ${this.state.darkModeEnabled ? "awsui-polaris-dark-mode" : ""}`}
                navigation={<FremontNavigation
                    toggleDarkMode={this.handleDarkModeChange}
                    isDarkMode={this.state.darkModeEnabled}
                    toggleSandboxMode={this.handleSandboxModeChange}
                    isSandboxMode={this.state.sandboxModeEnabled}
                    user={this.handleUser(this.props.userResponse)}
                    auth={this.props.authToPass}
                    setSideNavError={this.setSideNavError}
                    getAllProviderItems={this.getAllProviderItems}
                />}
                navigationOpen={this.state.navigationOpen}
                navigationWidth={250}
                onNavigationChange={this.handleNavigationChange}
                toolsHide
                content={<LighthouseContent
                    auth={this.props.authToPass}
                    user={this.handleUser(this.props.userResponse)}
                    allProviders={this.state.allProviders}
                    allProvidersLoading={this.state.allProvidersLoading}
                    sideNavError={this.state.sideNavError}
                    searchResults={this.state.searchResults}
                    updateSearchResults={this.updateSearchResults}
                    getAllProviderItems={this.getAllProviderItems}
                />}
            />
        );
    }
}