import React, { Component } from "react";
import { Alert, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import LinkServiceModal from "../components/LinkServiceModal";
import CutsheetForm from "./CutsheetForm";
import HelperFunctions from "../common/HelperFunctions";

export default class CutsheetTemplateDownloadHandler extends Component {
    state = {
        cutsheet: {},
        disableDownloadButton: true
    }

    handleCutsheetTypeSelectChange = (evt) => {
        const cutsheetToDownload = {};
        const elementId = evt.target.id;

        if (elementId.includes("type")) {
            cutsheetToDownload.cutsheetType = evt.detail.selectedOption[Constants.POLARIS_UTILS_OPTIONS.VALUE_KEY];
        }

        // Update the state
        this.setState({
            cutsheet: cutsheetToDownload,
            disableDownloadButton: false
        });
    }

    handleFileDownload = () => {
        const cutsheetRows = Constants.CUTSHEET_TEMPLATE_ROWS[this.state.cutsheet.cutsheetType];
        let csvContent = "data:text/csv;charset=utf-8,";

        cutsheetRows.forEach((rowArray) => {
            const row = rowArray.join(",");
            csvContent += `${row}\r\n`;
        });

        const encodedUri = encodeURI(csvContent);
        const downloadLink = document.createElement("a");

        downloadLink.setAttribute("href", encodedUri);
        downloadLink.setAttribute("download", `${this.state.cutsheet.cutsheetType}_cutsheet_template.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        this.setState({
            cutsheet: {},
            disableDownloadButton: true
        });
    }


    render() {
        return (
            <LinkServiceModal
                isModalVisible={this.props.isCutsheetTemplateDownloadButtonClicked}
                onDismiss={this.props.hideCutsheetTemplateDownloadModal}
                header="Download Cutsheet Template"
                onCancelButtonClick={this.props.hideCutsheetTemplateDownloadModal}
                onSubmitButtonClick={this.handleFileDownload}
                isAttachmentSubmissionInProgress={false}
                disableSubmitButton={this.state.disableDownloadButton}
                confirmButtonText="Download"
            >
                <SpaceBetween size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_CONTAINER_PADDING}>
                    <div>
                        <CutsheetForm
                            cutsheet={this.state.cutsheet}
                            cutsheetOptions={HelperFunctions.createSelectedOptionsForAttachments(
                                Object.values(Constants.CUTSHEET_TYPES), this.props.user
                            )}
                            isDownloadCutsheetTemplateForm
                            isAttachmentSubmissionInProgress={false}
                            disableSubmitButton={this.state.disableSubmitButton}
                            handleCutsheetTypeSelectChange={this.handleCutsheetTypeSelectChange}
                            user={this.props.user}
                        />
                    </div>
                    <div>
                        <Alert statusIconAriaLabel="Info" header="Cutsheet Field Values">
                            <b>is_briggs</b> - TRUE, FALSE (default: FALSE)<br/>
                            <b>state</b> - in_service, decommissioned, spare, planning, provisioning, maintenance
                        </Alert>
                    </div>
                </SpaceBetween>
            </LinkServiceModal>
        );
    }
}
