import React, { Component } from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import Constants from "../utils/Constants";
import LightHouseHeader from "../common/LightHouseHeader";
import IspPatchPanelConfigSearch from "./IspPatchPanelConfigSearch";
import HelperFunctions from "../common/HelperFunctions";

export default class IspPatchPanelConfigDashboard extends Component {
    state = {
        flashbar: {
            type: "",
            text: ""
        }
    }

    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    render() {
        return (
            <div>
                <LightHouseHeader
                    history={this.props.history}
                    flashbarType={this.state.flashbar.type}
                    flashbarText={this.state.flashbar.text}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                        <IspPatchPanelConfigSearch/>
                    </SpaceBetween>
                </div>
            </div>
        );
    }
}