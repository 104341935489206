import React from "react";
import {
    FormField
} from "@amzn/awsui-components-react/polaris";
import {
    FremontInput
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

// eslint-disable-next-line import/prefer-default-export
export const generateIpAllocationStageColumnDefinitions = (lacpProvider) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item[Constants.TABLE_IDS.stageStatus], item.blockers
            )
        },
        {
            id: Constants.COMPONENT_NAMES.nodeA,
            header: "Router A",
            description: "Router A for this circuitDesign",
            width: 100,
            cell: item => item[Constants.COMPONENT_NAMES.nodeA]
        }
    ];

    if (HelperFunctions.parseBoolean(lacpProvider)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "Router A LAG",
                description: "Router A LAG for this circuitDesign",
                width: 100,
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            }
        );
    }

    columnDefinitions.push(
        {
            id: Constants.COMPONENT_NAMES.portA,
            header: "Interface A",
            description: "Interface A for this circuitDesign",
            width: 125,
            cell: item => item[Constants.COMPONENT_NAMES.portA]
        },
        {
            id: Constants.ATTRIBUTES.amazonIPv4,
            header: "Amazon IPv4 Address",
            description: "Amazon's IPv4 Address",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                item.editable ?
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.amazonIPv4]}
                    >
                        <FremontInput
                            id={`${Constants.ATTRIBUTES.amazonIPv4}${Constants.SEPARATOR}${item[Constants.TABLE_IDS.groupingId]}`}
                            value={item[Constants.ATTRIBUTES.amazonIPv4]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                    : item[Constants.ATTRIBUTES.amazonIPv4]
            )
        },
        {
            id: Constants.ATTRIBUTES.providerIPv4,
            header: "Provider IPv4 Address",
            description: "Provider's IPv4 Address",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                item.editable ?
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.providerIPv4]}
                    >
                        <FremontInput
                            id={`${Constants.ATTRIBUTES.providerIPv4}${Constants.SEPARATOR}${item[Constants.TABLE_IDS.groupingId]}`}
                            value={item[Constants.ATTRIBUTES.providerIPv4]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                    : item[Constants.ATTRIBUTES.providerIPv4]
            )
        },
        {
            id: Constants.ATTRIBUTES.amazonIPv6,
            header: "Amazon IPv6 Address",
            description: "Amazon's IPv6 Address",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                item.editable ?
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.amazonIPv6]}
                    >
                        <FremontInput
                            id={`${Constants.ATTRIBUTES.amazonIPv6}${Constants.SEPARATOR}${item[Constants.TABLE_IDS.groupingId]}`}
                            value={item[Constants.ATTRIBUTES.amazonIPv6]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                    : item[Constants.ATTRIBUTES.amazonIPv6]
            )
        },
        {
            id: Constants.ATTRIBUTES.providerIPv6,
            header: "Provider IPv6 Address",
            description: "Provider's IPv6 Address",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                item.editable ?
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.providerIPv6]}
                    >
                        <FremontInput
                            id={`${Constants.ATTRIBUTES.providerIPv6}${Constants.SEPARATOR}${item[Constants.TABLE_IDS.groupingId]}`}
                            value={item[Constants.ATTRIBUTES.providerIPv6]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                    : item[Constants.ATTRIBUTES.providerIPv6]
            )
        },
        {
            id: Constants.ATTRIBUTES.bgpIPv4MD5Key,
            header: "BGP IPv4 MD5 Key",
            description: "BGP IPv4 MD5 Key",
            width: 120,
            cell: item => (
                item.editable ?
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.bgpIPv4MD5Key]}
                    >
                        <FremontInput
                            id={`${Constants.ATTRIBUTES.bgpIPv4MD5Key}${Constants.SEPARATOR}${item[Constants.TABLE_IDS.groupingId]}`}
                            value={item[Constants.ATTRIBUTES.bgpIPv4MD5Key]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                    : item[Constants.ATTRIBUTES.bgpIPv4MD5Key]
            )
        },
        {
            id: Constants.ATTRIBUTES.bgpIPv6MD5Key,
            header: "BGP IPv6 MD5 Key",
            description: "BGP IPv6 MD5 Key",
            width: 120,
            cell: item => (
                item.editable ?
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.bgpIPv6MD5Key]}
                    >
                        <FremontInput
                            id={`${Constants.ATTRIBUTES.bgpIPv6MD5Key}${Constants.SEPARATOR}${item[Constants.TABLE_IDS.groupingId]}`}
                            value={item[Constants.ATTRIBUTES.bgpIPv6MD5Key]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
                    : item[Constants.ATTRIBUTES.bgpIPv6MD5Key]
            )
        }
    );

    return columnDefinitions;
};
