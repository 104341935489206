import React, { Component } from "react";
import {
    Header,
    Link
} from "@amzn/awsui-components-react/polaris";
import { ConfirmationModal } from "utils/CommonComponents";
import { CircuitWithCustomComponents } from "circuitDesign/CircuitDesignCustomizeInformation";
import Constants from "utils/Constants";
import CreateCircuitDesignComponentModal from "circuitDesign/CreateCircuitDesignComponentModal";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import LinkServiceBackendClient from "../mango/js/common/LinkServiceBackendClient";

class CircuitDesignCustomize extends Component {
    state = {
        customComponentModalVisible: false,
        deleteCustomComponentModalVisible: false,
        customComponentPositionMapIndex: 0,
        customComponentSiteId: "",
        customComponentSiteName: "",
        isUpdateInProgress: false,
        editCustomComponent: false
    };

    componentDidMount = async () => {
        const relatedMeta = this.props.circuitDesign?.relatedMeta;
        const externalLinkId = relatedMeta && relatedMeta.length > 0 ? relatedMeta[0].externalId : null;

        if (externalLinkId) {
            const linkServiceBackendClient = new LinkServiceBackendClient();
            const routerToDwdmInfo = await linkServiceBackendClient.getLinkRouterToDwdmInfo(externalLinkId);

            this.setState({ routerToDwdmInfo });
        }
    };

    getDisplayValueCustomComponent = (component, field, name) => {
        let valueToReturn = "-";
        if (Constants.COMPONENT_TYPES.customComponent === component.type
            && this.props.componentIdToObjectMap[component.uuid]) {
            const customComponentPositionMap = this.props.componentIdToObjectMap[component.uuid].positionMap;
            Object.keys(customComponentPositionMap).forEach((key) => {
                const positionMapElement = customComponentPositionMap[key];
                if (positionMapElement.name === name && !!positionMapElement.uuid
                    && !!this.props.componentIdToObjectMap[positionMapElement.uuid]) {
                    valueToReturn = this.props.componentIdToObjectMap[positionMapElement.uuid][field];
                }
            });
        }
        return valueToReturn;
    };

    getDisplayValue = (component, field) => {
        if (component && component.uuid && this.props.componentIdToObjectMap[component.uuid]) {
            if (Constants.LIFECYCLE_STAGES.provisioning
                === this.props.circuitDesign[Constants.ATTRIBUTES.lifeCycleStage]) {
                if (this.props.componentIdToObjectMap[component.uuid].changeSet) {
                    if (field === Constants.ATTRIBUTES.amazonIPv4
                        && this.props.componentIdToObjectMap[component.uuid].changeSet.changeSetAmazonIPv4) {
                        return this.props.componentIdToObjectMap[component.uuid].changeSet.changeSetAmazonIPv4;
                    }
                    if (field === Constants.ATTRIBUTES.providerIPv4
                        && this.props.componentIdToObjectMap[component.uuid].changeSet.changeSetProviderIPv4) {
                        return this.props.componentIdToObjectMap[component.uuid].changeSet.changeSetProviderIPv4;
                    }
                }
                if (this.props.componentIdToObjectMap[component.uuid][`${field}ForNewRevision`]) {
                    return this.props.componentIdToObjectMap[component.uuid][`${field}ForNewRevision`];
                }
            }
            return this.props.componentIdToObjectMap[component.uuid][field] || "-";
        }
        return "-";
    };

    getDisplayValueForComponent = (component, index) => {
        switch (component.type) {
        case Constants.COMPONENT_TYPES.node:
            return (
                (
                    <Header variant="h5" key={index}>
                        {`Device Name: ${this.getDisplayValue(component, Constants.ATTRIBUTES.deviceName)}`}
                    </Header>
                )
            );
        case Constants.COMPONENT_TYPES.port:
            return (
                (
                    <Header variant="h5" key={index}>
                        {`Interface Name: ${this.getDisplayValue(component, Constants.ATTRIBUTES.interfaceName)}`}
                    </Header>
                )
            );
        case Constants.COMPONENT_TYPES.lag:
            return (
                (
                    <Header variant="h5" key={index}>
                        {`Lag Name: ${this.getDisplayValue(component, Constants.ATTRIBUTES.interfaceName)}`}
                    </Header>
                )
            );
        case Constants.COMPONENT_TYPES.unit:
            return (
                (
                    <Header variant="h5" key={index}>
                        {`Amazon IPv4: ${this.getDisplayValue(component, Constants.ATTRIBUTES.amazonIPv4)}`}
                        <br/>
                        {`Provider IPv4: ${this.getDisplayValue(component, Constants.ATTRIBUTES.providerIPv4)}`}
                    </Header>
                )
            );
        case Constants.COMPONENT_TYPES.demarcAndCfa:
            return (
                (
                    <Header variant="h5" key={index}>
                        {`Assignment Id: ${this.getDisplayValue(component, Constants.ATTRIBUTES.assignmentId)}`}
                        <br/>
                        {`Assignment Type: ${this.getDisplayValue(component, Constants.ATTRIBUTES.assignmentType)}`}
                    </Header>
                )
            );
        case Constants.COMPONENT_TYPES.crossConnect:
            return (
                (
                    <Header variant="h5" key={index}>
                        {`Cross Connect: ${component.uuid || "-"}`}
                    </Header>
                )
            );
        case Constants.COMPONENT_TYPES.providerCircuit:
            return (
                (
                    <Header variant="h5" key={index}>
                        {[Constants.ATTRIBUTE_LABELS.providerCircuitName,
                            ": ",
                            this.getDisplayValue(component, Constants.ATTRIBUTES.providerCircuitName)].join("")}
                    </Header>
                )
            );
        case Constants.COMPONENT_TYPES.aClientPort:
            return this.state.routerToDwdmInfo?.aEndClientPort && (
                <Header variant="h5" key={index}>
                    <span>
                        {"A Client Port (LinkService Record): "}
                        <Link href={`${window.location.origin}/linkservice/linkDetails/${this.state.routerToDwdmInfo?.aEndRouterToDwdmLinkId}`}>
                            {this.state.routerToDwdmInfo.aEndClientPort}
                        </Link>
                    </span>
                </Header>
            );
        case Constants.COMPONENT_TYPES.bClientPort:
            return this.state.routerToDwdmInfo?.bEndClientPort && (
                <Header variant="h5" key={index}>
                    <span>
                        {"B Client Port (LinkService Record): "}
                        <Link href={`${window.location.origin}/linkservice/linkDetails/${this.state.routerToDwdmInfo?.bEndRouterToDwdmLinkId}`}>
                            {this.state.routerToDwdmInfo.bEndClientPort}
                        </Link>
                    </span>
                </Header>
            );
        case Constants.COMPONENT_TYPES.customComponent:
            if (component.componentGroup === Constants.COMPONENT_NAMES.fremontCircuit) {
                return (
                    (
                        <Header variant="h5" key={index}>
                            {
                                <span>
                                    {"Circuit Design: "}
                                    {
                                        <Link href={`/circuitDesign/${this.getDisplayValue(component, Constants.ATTRIBUTES.circuitDesignId)}`}>
                                            {this.getDisplayValue(component, Constants.ATTRIBUTES.circuitDesignNumber)}
                                        </Link>
                                    }
                                </span>
                            }
                            <br/>
                            {`Provider: ${this.getDisplayValue(component, Constants.ATTRIBUTES.providerName)}`}
                            <br/>
                            {`Service Type: ${this.getDisplayValue(component, Constants.ATTRIBUTES.serviceType)} : ${this.getDisplayValue(component, Constants.ATTRIBUTES.customerFabric)}`}
                            <br/>
                            {[Constants.ATTRIBUTE_LABELS.providerCircuitName,
                                ": ",
                                this.getDisplayValueCustomComponent(
                                    component,
                                    Constants.ATTRIBUTES.providerCircuitName,
                                    Constants.COMPONENT_NAMES.providerCircuitA
                                )].join("")}
                            <br/>
                            {`Site A Name: ${this.getDisplayValueCustomComponent(
                                component, Constants.ATTRIBUTES.siteName, Constants.COMPONENT_NAMES.siteA
                            )}`}
                            <br/>
                            {/* Here we add the siteZName if the consumed circuits serviceType is backbone */}
                            {this.props.componentIdToObjectMap[component.uuid] &&
                            this.props.componentIdToObjectMap[component.uuid].serviceType
                            === Constants.SERVICE_TYPES.BACKBONE &&
                            `Site Z Name: ${this.getDisplayValueCustomComponent(
                                component, Constants.ATTRIBUTES.siteName, Constants.COMPONENT_NAMES.siteZ
                            )}`}
                        </Header>
                    )
                );
            }
            if (component.componentGroup === Constants.TOPOLOGY_CONSTANTS.linkService) {
                const snapshot = JSON.parse(component.snapshot);
                const siteA = this.props.componentIdToObjectMap[snapshot[Constants.ATTRIBUTES.siteAId]];
                const siteZ = this.props.componentIdToObjectMap[snapshot[Constants.ATTRIBUTES.siteZId]];
                return (
                    (
                        <Header variant="h5" key={index}>
                            {`Customer Fabric: ${snapshot[Constants.ATTRIBUTES.topologyCustomerFabric]}`}
                            <br/>
                            {`Site A Name: ${siteA ? siteA.siteName : ""}`}
                            <br/>
                            {`Site Z Name: ${siteZ ? siteZ.siteName : ""}`}
                        </Header>
                    )
                );
            }
            return (
                (<Header variant="h5" key={index}>{`Custom Component Value: ${component.name}` }</Header>)
            );

        default:
            return (
                (<Header variant="h5" key={index}>Unknown Component</Header>)
            );
        }
    };

    componentGroupNames = {
        router: "Router Group"
    };

    /**
    * This helper method is only for the view mode.
    * Decides whether the custom component is empty.
    */
    isComponentEmpty = component => !(component.uuid || component.type === Constants.COMPONENT_TYPES.customComponent)

    FremontBackendClient = new FremontBackendClient();

    returnSiteName = (siteComponentName) => {
        const siteComponent = HelperFunctions.findComponent(this.props.circuitDesign.positionMap,
            siteComponentName);
        if (siteComponent && siteComponent.uuid && this.props.componentIdToObjectMap[siteComponent.uuid]) {
            return this.props.componentIdToObjectMap[siteComponent.uuid][Constants.ATTRIBUTES.siteName];
        }
        return "";
    }

    createComponentGroupArray = () => {
        if (this.props.circuitDesign.positionMap) {
            const positionMapLength = Object.keys(this.props.circuitDesign.positionMap).length;
            const siteZ = this.returnSiteName(Constants.COMPONENT_NAMES.siteZ);
            const { positionMap } = this.props.circuitDesign;
            let outerLoop;
            let innerLoop;
            let positionMapValue;
            const componentGroupArray = [];

            // positionMap keys are numbers
            for (outerLoop = 1; outerLoop < positionMapLength; outerLoop += 1) {
                // if siteZ exists and we're at the last item in map
                // no need to analyze
                if (siteZ && (outerLoop === positionMapLength - 1)) {
                    break;
                }
                positionMapValue = positionMap[outerLoop];
                const reactElementArray = [];

                // this hideEmptyComponentGroup is only for the "view mode", otherwise add custom component
                // buttons will be messed up badly!
                let hideEmptyComponentGroup = true;
                // Push the current component into the reactElement array we will display on UI
                reactElementArray.push(this.getDisplayValueForComponent(positionMapValue, outerLoop));
                if (!this.isComponentEmpty(positionMapValue)) {
                    hideEmptyComponentGroup = false;
                }
                // Create an inner loop to see if the next couple of components are part of same group
                // if they are then they need to be part of same reactElement array
                for (innerLoop = outerLoop + 1; innerLoop < Object.keys(positionMap).length; innerLoop += 1) {
                    // NEVER group custom components together!
                    if (positionMap[innerLoop].type === "customComponent") {
                        break;
                    }
                    if (positionMap[innerLoop].componentGroup === positionMapValue.componentGroup &&
                        positionMap[innerLoop].siteGroup === positionMapValue.siteGroup) {
                        reactElementArray.push(this.getDisplayValueForComponent(positionMap[innerLoop], innerLoop));
                        if (!this.isComponentEmpty(positionMap[innerLoop])) {
                            hideEmptyComponentGroup = false;
                        }
                        outerLoop = innerLoop;
                    } else {
                        break;
                    }
                }

                // Basically here if we are in view mode we only push stuff into the componentGroupArray
                // if the component has any values, otherwise it gets confusing for users for completed circuits
                // to see empty values
                // We still need to see empty components in edit mode though
                const componentGroup = positionMapValue.componentGroup || "";
                if (!hideEmptyComponentGroup || this.props.editMode) {
                    componentGroupArray.push({
                        type: positionMapValue.type,
                        group: componentGroup,
                        reactElements: this.withExtraComponentGroupFields(componentGroup, reactElementArray, outerLoop),
                        siteGroup: positionMapValue.siteGroup || "",
                        site: positionMapValue.siteGroup
                            && this.props.componentIdToObjectMap[positionMapValue.siteGroup]
                            ? this.props.componentIdToObjectMap[positionMapValue.siteGroup].siteName : "",
                        positionMapIndex: outerLoop
                    });
                }
            }

            const isBackboneCircuit = this.props.circuitDesign.serviceType === Constants.SERVICE_TYPES.BACKBONE;
            if (!isBackboneCircuit && this.state.routerToDwdmInfo) {
                const siteZRouterGroup = {
                    type: "node",
                    group: this.componentGroupNames.router,
                    reactElements: [this.getDisplayValueForComponent(
                        { type: Constants.COMPONENT_TYPES.bClientPort }
                    )],
                    site: this.state.routerToDwdmInfo.bEndSiteName,
                    positionMapIndex: componentGroupArray.length
                };

                componentGroupArray.push(siteZRouterGroup);
            }

            return componentGroupArray;
        }
        return [];
    };

    addCustomComponent = (evt) => {
        const target = evt.target.id;
        // When a user clicks on button to "add custom component" this function will be called
        // we put in the button id, its index in position map
        // and if it is part of siteGroup such as A, or Z, could also have no sitegroup or undefined
        const positionMapIndex = HelperFunctions.parseInt(target.split(":")[0]);
        const siteGroup = target.split(":")[1];

        this.setState({
            customComponentPositionMapIndex: positionMapIndex,
            customComponentSiteId: siteGroup,
            customComponentSiteName: siteGroup ? this.props.componentIdToObjectMap[siteGroup].siteName : "",
            customComponentModalVisible: true
        });
    };

    editCustomComponent = (evt) => {
        const target = evt.target.id;
        // When a user clicks on button to "edit custom component" this function will be called
        // we put in the button id, its index in position map
        // and if it is part of siteGroup such as A, or Z, could also have no sitegroup or undefined
        const positionMapIndex = HelperFunctions.parseInt(target.split(":")[0]);
        const siteGroup = target.split(":")[1];
        const updatedCircuitDesignObject = HelperFunctions.deepClone(this.props.circuitDesign);

        const customComponentToUpdate = updatedCircuitDesignObject.positionMap[positionMapIndex];

        this.setState({
            customComponentPositionMapIndex: positionMapIndex,
            customComponentToUpdate,
            customComponentSiteId: siteGroup,
            customComponentSiteName: siteGroup ? this.props.componentIdToObjectMap[siteGroup].siteName : "",
            customComponentModalVisible: true,
            editCustomComponent: true
        });
    };

    deleteCustomComponent = (evt) => {
        const target = evt.target.id;
        const positionMapIndex = HelperFunctions.parseInt(target.split(":")[0]);

        this.setState({
            customComponentPositionMapIndex: positionMapIndex,
            deleteCustomComponentModalVisible: true
        });
    };

    handleDeleteComponentConfirm = async () => {
        this.setState({
            isUpdateInProgress: true
        });
        // original positionMapLength
        const positionMapLength = Object.keys(this.props.circuitDesign.positionMap).length;
        const updatedCircuitDesignObject = HelperFunctions.deepClone(this.props.circuitDesign);
        // delete value from positionMap
        delete updatedCircuitDesignObject.positionMap[this.state.customComponentPositionMapIndex];

        // renumber the rest of them
        let key;
        for (key = this.state.customComponentPositionMapIndex; key < positionMapLength - 1; key += 1) {
            updatedCircuitDesignObject.positionMap[key] = updatedCircuitDesignObject.positionMap[key + 1];
        }
        delete updatedCircuitDesignObject.positionMap[positionMapLength - 1];

        await this.handleSubmit(updatedCircuitDesignObject);
    };

    handleSubmit = async (updatedCircuitDesignObject) => {
        const circuitsToUpdate = HelperFunctions.createNewApiObjects([this.props.circuitDesign],
            [updatedCircuitDesignObject], Constants.ATTRIBUTES.circuitDesignId, Constants.KEEP_KEYS.CIRCUIT,
            []);
        try {
            // update the circuits and wait for a response
            if (circuitsToUpdate.length > 0) {
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }

            this.setState({
                deleteCustomComponentModalVisible: false,
                isUpdateInProgress: false
            });

            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );

            // Need to update the circuit being passed in
            this.props.loadData(true, true);
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(
                false, error, false
            );
            this.setState({
                isUpdateInProgress: false,
                allFieldsDisabled: false,
                deleteCustomComponentModalVisible: false
            });
        }
    }

    handleFormDismiss = () => {
        this.setState({
            deleteCustomComponentModalVisible: false,
            customComponentModalVisible: false,
            editCustomComponent: false
        });
    };

    handleCloseModalAfterUpdate = async () => {
        this.setState({
            customComponentModalVisible: false,
            deleteCustomComponentModalVisible: false,
            isUpdateInProgress: false,
            editCustomComponent: false
        });
    };

    withExtraComponentGroupFields = (componentGroup, reactElements, startIndex) => {
        const extraComponentsMap = {
            [this.componentGroupNames.router]: [Constants.COMPONENT_TYPES.aClientPort]
        };
        const extraComponents = componentGroup in extraComponentsMap ? extraComponentsMap[componentGroup] : [];

        return reactElements.concat(extraComponents.map((componentName, index) =>
            this.getDisplayValueForComponent({ type: componentName }, index + startIndex)));
    };

    render() {
        const routerToDwdmAEndSiteName = this.state.routerToDwdmInfo?.aEndSiteName;
        const routerToDwdmBEndSiteName = this.state.routerToDwdmInfo?.bEndSiteName;
        const bEndSiteNameToDisplay = routerToDwdmAEndSiteName !== routerToDwdmBEndSiteName
            ? routerToDwdmBEndSiteName
            : null;

        return (
            (
                <div>
                    <ConfirmationModal
                        isVisible={this.state.deleteCustomComponentModalVisible}
                        hideModal={this.handleFormDismiss}
                        disabled={false}
                        description="You are about to delete a custom component. This action cannot be undone."
                        cancelButtonText="Cancel"
                        primaryButtonText="Submit"
                        header="Delete this Component"
                        onClickFunction={this.handleDeleteComponentConfirm}
                        loading={this.state.isUpdateInProgress}
                    />
                    {this.state.customComponentModalVisible &&
                    <CreateCircuitDesignComponentModal
                        customComponentToUpdate={this.state.customComponentToUpdate}
                        customComponentModalVisible={this.state.customComponentModalVisible}
                        customComponentPositionMapIndex={this.state.customComponentPositionMapIndex}
                        customComponentSiteId={this.state.customComponentSiteId}
                        customComponentSiteName={this.state.customComponentSiteName}
                        circuitDesign={this.props.circuitDesign}
                        order={this.props.order}
                        handleFormDismiss={this.handleFormDismiss}
                        handleCloseModalAfterUpdate={this.handleCloseModalAfterUpdate}
                        handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                        auth={this.props.auth}
                        entities={this.props.componentIdToObjectMap}
                        editCustomComponent={this.state.editCustomComponent}
                        loadData={this.props.loadData}
                        allProviders={this.props.allProviders}
                        allProvidersLoading={this.props.allProvidersLoading}
                    />
                    }

                    <div>
                        {this.props.showCircuitLink &&
                            <div>
                                <Header variant="h2">{"Link to Circuit Detail Page: "}
                                    <Link href={`${Constants.ROUTES.circuitDesign}/${this.props.circuitDesign.circuitDesignId}`}>
                                        {this.props.circuitDesign.circuitDesignNumber}
                                    </Link>
                                </Header>
                            </div>
                        }
                        <CircuitWithCustomComponents
                            addCustomComponent={this.addCustomComponent}
                            editCustomComponent={this.editCustomComponent}
                            deleteCustomComponent={this.deleteCustomComponent}
                            addFremontComponent={this.addCustomComponent}
                            componentGroupArray={this.createComponentGroupArray()}
                            siteA={this.returnSiteName(Constants.COMPONENT_NAMES.siteA)}
                            siteZ={this.returnSiteName(Constants.COMPONENT_NAMES.siteZ) || bEndSiteNameToDisplay}
                            circuitDesign={this.props.circuitDesign}
                            editMode={this.props.editMode || this.state.allFieldsDisabled}
                            handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                            isCircuitBlocked={this.props.isCircuitBlocked}
                            customComponentModalVisible={this.state.customComponentModalVisible}
                        />
                    </div>
                </div>
            )
        );
    }
}

export default CircuitDesignCustomize;