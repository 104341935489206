import React, { Component } from "react";
import { Link, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import {
    FremontCheckbox,
    ComponentConstants
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import RemoveCircuitsModal from "circuitDesign/RemoveCircuitsModal";

export default class RemoveCircuitsModalHandler extends Component {
    static REMOVE_CIRCUIT_DEFINITIONS = [
        {
            id: "remove",
            header: "Remove",
            cell: item => (
                <FremontCheckbox
                    id={item.circuitDesignId}
                    checked={item.removeCircuit}
                    onChange={item.handleInputChange}
                    disabled={item.disabled || item.blockers.length > 0}
                />
            )
        },
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item =>
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>{item.circuitDesignNumber}</Link>

        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: (
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <span>Status:</span>
                    {
                        HelperFunctions.renderCustomTooltip(null,
                            Constants.TOOLTIP_STRINGS.circuitStatusExplanation)
                    }
                </SpaceBetween>
            ),
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.displayInProgressStagesWithBlocker(item.stageStatusMap, item.blockers)
        }
    ];

    state = {
        updatedOrder: {},
        handlerCircuitDesignObjects: [],
        errorToDisplay: ""
    };

    /**
     * This function handles when modal's visibility is changed
     * @param nextProps
     * @returns {Promise<*>}
     */
    componentDidUpdate = async (prevProps) => {
        if (prevProps.isRemoveCircuitsModalVisible !== this.props.isRemoveCircuitsModalVisible) {
            // modal is now visible
            if (this.props.isRemoveCircuitsModalVisible) {
                const handlerCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects)
                    .map((updatedCircuitObject) => {
                        const circuitDesignObject = updatedCircuitObject;
                        circuitDesignObject.removeCircuit = false;
                        circuitDesignObject.disabled = false;
                        return circuitDesignObject;
                    });
                this.setState({
                    errorToDisplay: "",
                    updatedOrder: HelperFunctions.deepClone(this.props.updatedOrder),
                    handlerCircuitDesignObjects
                });
            }
        }
    };

    createCircuitItems = () => {
        const circuitItems = [];
        this.state.handlerCircuitDesignObjects.forEach((circuitDesign) => {
            circuitItems.push({
                removeCircuit: circuitDesign.removeCircuit,
                handleInputChange: this.handleInputChange,
                circuitDesignId: circuitDesign.circuitDesignId,
                circuitDesignNumber: circuitDesign.circuitDesignNumber,
                stageStatusMap: circuitDesign.stageStatusMap,
                disabled: circuitDesign.disabled,
                blockers: HelperFunctions.circuitsActiveBlockers(
                    [circuitDesign.circuitDesignId], this.props.blockers
                )
            });
        });
        return circuitItems;
    };

    // Get circuit design id with matching id and set removeCircuit accordingly
    handleInputChange = (evt) => {
        let handlerCircuitDesignObjects = HelperFunctions.deepClone(this.state.handlerCircuitDesignObjects);
        handlerCircuitDesignObjects.find(circuitDesign =>
            evt.target.id === circuitDesign.circuitDesignId).removeCircuit = evt.detail.checked;
        const remainingCircuits = handlerCircuitDesignObjects
            .filter(circuitDesign => !circuitDesign.removeCircuit);
        if (remainingCircuits.length === HelperFunctions.parseInt(this.state.updatedOrder.circuitQuantity)) {
            handlerCircuitDesignObjects = handlerCircuitDesignObjects.map((handlerCircuitDesignObject) => {
                const circuitDesignObject = handlerCircuitDesignObject;
                circuitDesignObject.disabled = false;
                if (!circuitDesignObject.removeCircuit) {
                    circuitDesignObject.disabled = true;
                }
                return circuitDesignObject;
            });
            this.setState({ errorToDisplay: "" });
        } else {
            handlerCircuitDesignObjects = handlerCircuitDesignObjects.map((handlerCircuitDesignObject) => {
                const circuitDesignObject = handlerCircuitDesignObject;
                circuitDesignObject.disabled = false;
                return circuitDesignObject;
            });
        }
        this.setState({
            handlerCircuitDesignObjects
        });
    };

    handleCancelRemoveCircuitsModal = () => {
        this.props.handleToggleRemoveCircuitsModal(false);
    };

    // Check that number removed matches circuit quantity
    // Remove circuit designs from orders list
    handleSubmit = async () => {
        const updatedOrder = HelperFunctions.deepClone(this.state.updatedOrder);
        const remainingCircuits = this.state.handlerCircuitDesignObjects
            .filter(circuitDesign => !circuitDesign.removeCircuit);
        if (remainingCircuits.length !== HelperFunctions.parseInt(updatedOrder.circuitQuantity)) {
            this.setState({
                errorToDisplay: `Need to select ${
                    remainingCircuits.length - HelperFunctions.parseInt(updatedOrder.circuitQuantity)
                } more circuit(s) to remove.`
            });
            return;
        }
        const updatedCircuitDesignIdList = remainingCircuits.map(circuitDesign => circuitDesign.circuitDesignId);
        updatedOrder[Constants.FREMONT_OBJECTS.order.circuitDesignIdListToRemoveFromRequest] =
            updatedOrder.circuitDesignIdList.filter(circuitDesignId =>
                !updatedCircuitDesignIdList.includes(circuitDesignId));
        this.props.handleToggleRemoveCircuitsModal(true);
        this.props.submitOrder(updatedOrder);
    };

    render() {
        return (
            <RemoveCircuitsModal
                handleInputChange={this.handleInputChange}
                handleSubmit={this.handleSubmit}
                handleCancelRemoveCircuitsModal={this.handleCancelRemoveCircuitsModal}
                isRemoveCircuitsModalVisible={this.props.isRemoveCircuitsModalVisible}
                circuitItems={this.createCircuitItems()}
                errorToDisplay={this.state.errorToDisplay}
            />
        );
    }
}