import React, { Component } from "react";
import {
    Box,
    Container,
    Form,
    Grid,
    Header,
    Popover,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import { LinkServiceButton, LinkServiceInput, LinkServiceButtonDropDown } from "../components/CommonComponents";
import Constants from "../utils/Constants.js";
import GlobalConstants from "../../../utils/Constants.js";
import SearchInstructionsComponent from "./SearchInstructionsComponent";

export const searchTermParseString = "?searchTerm=";

const getSearchTermFromLocation = searchTermFromUrl => decodeURI(searchTermFromUrl.replace(searchTermParseString, ""));

export default class LinkSearchFilter extends Component {
    state = {
        searchTerm: !this.props.searchTermFromUrl ? "" : getSearchTermFromLocation(this.props.searchTermFromUrl)
    }

    getIspCutsheetOptions = (searchTerm, isClientCutsheetEnabled, isLineCutsheetEnabled) => [
        {
            text: "Client",
            id: "client-cutsheet",
            disabled: !isClientCutsheetEnabled,
            href: `${GlobalConstants.LIGHTHOUSE_ROUTES.ispDashboard}?searchTerm=${searchTerm}&cutsheetType=CLIENT`
        },
        {
            text: "Line",
            id: "line-cutsheet",
            disabled: !isLineCutsheetEnabled,
            href: `${GlobalConstants.LIGHTHOUSE_ROUTES.ispDashboard}?searchTerm=${searchTerm}&cutsheetType=LINE`
        },
        {
            text: "Backbone-Client-Migration",
            id: "backbone-client-migration",
            disabled: !isClientCutsheetEnabled,
            href: `${GlobalConstants.LIGHTHOUSE_ROUTES.ispDashboard}?searchTerm=${searchTerm}&cutsheetType=BACKBONE-CLIENT-MIGRATION`
        }
    ];

    handleSearchTermChange = (evt) => {
        this.setState({
            searchTerm: evt.detail.value
        });
    }

    copySearchUrlToClipboard = async () => {
        // Making use of the Clipboard API writeData(): https://www.w3.org/TR/clipboard-apis/#dom-clipboard-writetext
        await navigator.clipboard.writeText(window.location.href);
    }

    render() {
        return (
            <LinkSearchFilterComponent
                primaryHeaderText={this.props.primaryHeaderText}
                secondaryHeaderText={this.props.secondaryHeaderText}
                handleSearchTermChange={this.handleSearchTermChange}
                searchTerm={this.state.searchTerm}
                isSearchInProgress={this.props.isSearchInProgress}
                handleSearchTermSubmit={this.props.handleSearchTermSubmit}
                copySearchTermToClipboard={this.copySearchUrlToClipboard}
                showCopyUrlPopover={this.props.showCopyUrlPopover}
                informationalMessageComponent={this.props.informationalMessageComponent}
                isClientCutsheetEnabled={this.props.isClientCutsheetEnabled}
                isLineCutsheetEnabled={this.props.isLineCutsheetEnabled}
                showIspCutsheetLinkButtons={this.props.showIspCutsheetLinkButtons}
                handleOptionSelect={this.handleOptionSelect}
                ispCutsheetOptions={this.getIspCutsheetOptions}
            />
        );
    }
}

const LinkSearchFilterComponent = props => (
    <div>
        <Form>
            <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_STAGES}>
                <Container
                    header={
                        <SpaceBetween
                            direction="horizontal"
                            size={Constants.PADDING_SIZES.SPACE_BETWEEN_LINK_PADDING}
                        >
                            <SpaceBetween
                                size={Constants.PADDING_SIZES.SPACE_BETWEEN_LINK_PADDING}
                            >
                                <Header variant="h2">
                                    {props.primaryHeaderText}
                                </Header>
                                {props.secondaryHeaderText &&
                                    <Header variant="h4">
                                        {props.secondaryHeaderText}
                                    </Header>}
                            </SpaceBetween>
                            {props.showCopyUrlPopover &&
                                <Box padding={{ top: "xxs" }}>
                                    <Popover
                                        dismissButton={false}
                                        position="top"
                                        size="small"
                                        triggerType="custom"
                                        content={
                                            <StatusIndicator type="success">
                                                Search URL Copied
                                            </StatusIndicator>
                                        }
                                    >
                                        <LinkServiceButton
                                            variant="icon"
                                            iconName="copy"
                                            onClick={props.copySearchTermToClipboard}
                                        />
                                    </Popover>
                                </Box>}
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_STAGES}>
                        {props.informationalMessageComponent}
                        <Grid
                            gridDefinition={[
                                { colspan: { default: 6 } },
                                { colspan: { default: 4 } }]}
                        >
                            <LinkServiceInput
                                onChange={props.handleSearchTermChange}
                                value={props.searchTerm}
                                placeholder="Enter search term here"
                                disabled={props.isSearchInProgress}
                                onKeyDown={evt => (evt.detail.key === "Enter" ?
                                    props.handleSearchTermSubmit(props.searchTerm) : {})}
                            />
                            <SpaceBetween direction="horizontal" size="xs">
                                <LinkServiceButton
                                    id={props.buttonId}
                                    variant="primary"
                                    iconName="search"
                                    iconAlign="left"
                                    onClick={() => props.handleSearchTermSubmit(props.searchTerm)}
                                    loading={props.isSearchInProgress}
                                    disabled={!props.searchTerm}
                                >
                                Search
                                </LinkServiceButton>
                                {props.showIspCutsheetLinkButtons &&
                                <LinkServiceButtonDropDown
                                    items={props.ispCutsheetOptions(props.searchTerm, props.isClientCutsheetEnabled,
                                        props.isLineCutsheetEnabled)}
                                    loading={props.isSearchInProgress}
                                    disabled={props.isSearchInProgress ||
                                            (!props.isClientCutsheetEnabled && !props.isLineCutsheetEnabled)}
                                    dropDownButtonName="Generate Live Cabling Cutsheet View"
                                />}
                            </SpaceBetween>
                        </Grid>
                        <SearchInstructionsComponent/>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </Form>
    </div>
);