import React from "react";
import FremontTable from "table/FremontTable";
import ContactTableData from "contact/ContactTableData";
import HelperFunctions from "../common/HelperFunctions";

export default function ContactTable(props) {
    return (
        <FremontTable
            entity="Contact"
            createEntityLink={ContactTableData.fetchCreateContactLink(props.providerName)}
            columnDefinitions={ContactTableData.COLUMN_DEFINITIONS}
            tableItems={HelperFunctions.sortObjectsTwoFields(props.items, "firstName", "lastName")}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            onNextPageClickHelper={{
                nextToken: props.nextToken, fetch: async () => props.fetchAllContactItems()
            }}
            nextToken={props.nextToken}
        />
    );
}