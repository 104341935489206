export default class LinkVisualConstants {
    static linkTypeColorMap = {
        PassiveToPassive: "#F4CAAF",
        MuxToMux: "#d7b513",
        OmsToOms: "#719D93",
        PowermuxToPowermux: "#7684e5",
        TrunkToTrunk: "#75AC61",
        OduToOdu: "#9b79b0",
        RouterToDWDM: "#008459",
        RouterToRouter: "#ef404e",
        ClientToClient: "#ff22b9",
        EncryptionToEncryption: "#A76C48",
        EncryptionToDWDM: "#10a977",
        RouterToEncryption: "#812836",
        Protection: "#0cc6cb"
    };

    static width = window.innerWidth;
    static height = window.innerHeight;

    static durationOfToggle = 500;
    static xSpacing = 175;
    static ySpacing = 100;
    static nodeSize = 40;
    static linkLineDistance = 100;
    static verticalDistanceMultiplier = 180;
}
