import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";

export default class QuickSightTool {
    static EMBEDDING_CONTAINER_ID = "embeddingContainer";

    linkServiceBackendClient;
    constructor() {
        this.linkServiceBackendClient = new LinkServiceBackendClient();
    }

    fetchQuickSightDashboardUrl = async (dashboardId) => {
        const response = await this.linkServiceBackendClient.quickSightGetEmbedUrlForRegisteredUser(dashboardId);
        return response.QuickSightEmbedUrl;
    };

    embedQuickSightDashboard = async (dashboardId) => {
        const dashboardUrl = await this.fetchQuickSightDashboardUrl(dashboardId);
        const dashboardOptions = {
            url: dashboardUrl,
            container: document.getElementById(QuickSightTool.EMBEDDING_CONTAINER_ID),
            scrolling: "no",
            locale: "en-US",
            height: "1400px"
        };
        const dashboard = embedDashboard(dashboardOptions);
        return dashboard;
    };
}
