import React from "react";
import {
    Box,
    ColumnLayout,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";


const AddressInformation = props => (
    (
        <ColumnLayout columns={2} borders="vertical">
            <div>
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Address Line One:</Box>
                        <div>{props.address.addressLineOne || "-"}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Address Line Two:</Box>
                        <div>{props.address.addressLineTwo || "-"}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">City:</Box>
                        <div>{props.address.addressCity || "-"}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Zip Code:</Box>
                        <div>{props.address.addressZipCode || "-"}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Country:</Box>
                        <div>{props.address.addressCountry || "-"}</div>
                    </div>
                </SpaceBetween>
            </div>
            <div>
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Latitude:</Box>
                        <div>{props.address.addressLatitude || "-"}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">Longitude:</Box>
                        <div>{props.address.addressLongitude || "-"}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">State / Province:</Box>
                        <div>{props.address.addressState || "-"}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: "xxxs" }} color="text-label">County / District:</Box>
                        <div>{props.address.addressCounty || "-"}</div>
                    </div>
                </SpaceBetween>
            </div>
        </ColumnLayout>
    )
);

export default AddressInformation;