import React from "react";
import NodeTableData from "node/NodeTableData";
import FremontTable from "table/FremontTable";

export default function NodeTable(props) {
    return (
        <FremontTable
            entity="Node"
            columnDefinitions={NodeTableData.COLUMN_DEFINITIONS}
            tableItems={props.items}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            onNextPageClickHelper={{
                nextToken: props.nextToken, fetch: async () => props.fetchAllNodeItems()
            }}
            nextToken={props.nextToken}
        />
    );
}