import React, { Component } from "react";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderTabRenderingHandler from "order/OrderTabRenderingHandler";

/**
 * OrderCapacityProvisioningTab is used to display all of the stages that a business operator works through in an order
 */
class OrderCapacityProvisioningTab extends Component {
    state = {
        isUpdateOrderCapacityProvisioningTabInProgress: false
    };

    componentDidMount = () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        }
    };

    handleStageInEditOrSubmitMode = (isBeingEditedOrSubmitted) => {
        this.props.setIsAnyEditModeEnabled(isBeingEditedOrSubmitted);
    };

    render() {
        return (
            <OrderTabRenderingHandler
                asn={this.props.asn}
                asnLoading={this.props.asnLoading}
                order={this.props.order}
                siteNames={this.props.siteNames}
                circuitDesignObjects={this.props.circuitDesignObjects}
                circuitDesignsLoading={this.props.circuitDesignsLoading}
                componentIdToObjectMap={this.props.componentIdToObjectMap}
                loadingComponentIdToObjectMap={this.props.loadingComponentIdToObjectMap}
                loadData={this.props.loadData}
                handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                stages={this.props.stages}
                orderCompleted={this.props.orderCompleted}
                goToComponentAction={this.props.goToComponentAction}
                isUpdateOrderCapacityProvisioningTabInProgress={
                    this.state.isUpdateOrderCapacityProvisioningTabInProgress}
                auth={this.props.auth}
                blockers={this.props.blockers}
                showAttachmentModal={this.props.showAttachmentModal}
                showTemplateGeneratorModal={this.props.showTemplateGeneratorModal}
                attachmentsLoading={this.props.attachmentsLoading}
                downloadAttachment={this.props.downloadAttachment}
                isDownloadingAttachment={this.props.isDownloadingAttachment}
                editButtonsDisabled={this.props.isAnyEditModeEnabled || !this.props.isTabEditable}
                handleStageInEditOrSubmitMode={this.handleStageInEditOrSubmitMode}
                isDataLoaded={this.props.isDataLoaded}
                handleToggleCompleteStage={this.props.handleToggleCompleteStage}
            />
        );
    }
}

export default OrderCapacityProvisioningTab;