import React from "react";
import {
    Box,
    ColumnLayout,
    Icon,
    Link,
    Modal,
    Popover,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontSelect,
    FremontTextarea
} from "utils/CommonComponents";
import HelperFunctions from "common/HelperFunctions";
import Constants from "utils/Constants";

const TemplateGeneratorModal = props => (
    <Modal
        visible={props.isTemplateGeneratorModalClicked}
        header="Generate TT Template"
        footer={
            <Box variant="span" float="right">
                <FremontButton
                    variant="normal"
                    onClick={props.hideTemplateGeneratorModal}
                >
                    Cancel
                </FremontButton>
            </Box>
        }
        size="large"
        onDismiss={props.hideTemplateGeneratorModal}
    >
        <ColumnLayout columns={4}>
            <FremontSelect
                placeholder="Choose a Template Type"
                options={HelperFunctions.createSelectedOptions(props.templateTypeOptions)}
                selectedOption={HelperFunctions.createSelectedOption(props.selectedTemplateType)}
                onChange={props.handleChoosingTemplateType}
            />
            <FremontButton
                variant="primary"
                onClick={props.selectedTemplateType === Constants.TEMPLATE_TYPES.backboneMCMCutsheet
                    ? props.generateFabricCutSheet : props.generateTemplate}
                disabled={!props.selectedTemplateType || props.generateTemplateButtonDisabled}
            >
                Generate Template
            </FremontButton>
            <span>
                <Link external href={Constants.TEMPLATE_GENERATOR_LINK}>
                    Cabling Request Links Wiki
                </Link>
            </span>
            <SpaceBetween
                direction="horizontal"
                size="m"
            >
                {props.selectedTemplateType !== Constants.TEMPLATE_TYPES.backboneMCMCutsheet ?
                    <Popover
                        size="small"
                        position="top"
                        triggerType="custom"
                        dismissButton={false}
                        content={
                            <span className="awsui-util-status-positive">
                                <Icon variant="success" name="status-positive"/>
                                Template copied. Paste into MARKDOWN area of t.corp.amazon.com ticket.
                            </span>
                        }
                    >
                        <FremontButton
                            iconName="copy"
                            ariaLabel="Copy [content name]"
                            onClick={props.doCopy}
                        >
                            Copy Template
                        </FremontButton>
                    </Popover> :
                    <FremontButton
                        iconName="download"
                        ariaLabel="Download [content name]"
                        onClick={props.downloadCSV}
                        disabled={props.generateTemplateButtonDisabled}
                    >
                        Download CSV
                    </FremontButton>
                }
            </SpaceBetween>
        </ColumnLayout>
        <Box margin={{ top: "l" }}>
            <FremontTextarea
                disabled
                value={props.template}
                rows={15}
            />
        </Box>
    </Modal>
);
export default TemplateGeneratorModal;

