import React from "react";
import {
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

// eslint-disable-next-line import/prefer-default-export
export const generateImplementationStageColumnDefinitions = (lacpProvider, numberOfItems, serviceType) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.implementation], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.subStatusMap,
            header: "Sub-Status",
            description: "Stage sub-status for this circuit",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.implementation] :
                    <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.subStatusMap}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={HelperFunctions.createSelectedOptions(
                                Constants.SUB_STATUS_OPTIONS.implementation
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.implementation]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled ||
                            !HelperFunctions.isStageInProgress(item.stageStatusMap.implementation)}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: Constants.ATTRIBUTES.implementationStatus,
            header: "Implementation Status",
            description: "Implementation of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.implementationStatus] :
                    <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.implementationStatus}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={Constants.IMPLEMENTATION_STATUS_OPTIONS}
                            selectedOption={HelperFunctions.createSelectedOption(item.implementationStatus)}
                            onChange={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: Constants.ATTRIBUTES.lightLevelTestingStatus,
            header: "Light Level Testing Status",
            description: "Light level testing status of the circuit",
            cell: item => item[Constants.ATTRIBUTES.lightLevelTestingStatus]
        },
        {
            id: Constants.COMPONENT_NAMES.nodeA,
            header: "Router A",
            description: "Router A for this circuitDesign",
            cell: item => item[Constants.COMPONENT_NAMES.nodeA]
        },
        {
            id: Constants.COMPONENT_NAMES.portA,
            header: "Interface A",
            description: "Interface A for this circuitDesign",
            cell: item => item[Constants.COMPONENT_NAMES.portA]
        }
    ];

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)
        && !!HelperFunctions.parseBoolean(lacpProvider)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "Router A LAG",
                description: "Router A LAG for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            }
        );
    }

    if (serviceType === Constants.SERVICE_TYPES.BACKBONE) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.nodeZ,
                header: "Router Z",
                description: "Router Z for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.nodeZ]
            },
            {
                id: Constants.COMPONENT_NAMES.portZ,
                header: "Interface Z",
                description: "Interface Z for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.portZ]
            }
        );
    }

    return columnDefinitions;
};
