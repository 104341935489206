import React from "react";
import { ClipLoader } from "react-spinners";
import Constants from "utils/Constants";
import FremontHeader from "common/FremontHeader";

const FremontHeaderWithSpinner = props => (
    <div>
        <FremontHeader
            history={props.history}
            flashbarText={props.flashbarText}
            flashbarType={props.flashbarType}
            onDismiss={props.onDismiss}
            auth={props.auth}
            sideNavError={props.sideNavError}
            updateSearchResults={props.updateSearchResults}
        />
        <div className="centerSpinner">
            <ClipLoader
                color={Constants.SPINNER_ORANGE_COLOR}
                loading={props.isPageLoading}
                size={Constants.SPINNER_SIZES.loadingPage}
            />
        </div>
    </div>
);

export default FremontHeaderWithSpinner;