import React from "react";
import HelperFunctions from "../common/HelperFunctions";

export const INSTANCE_ID_REGEX =
    /amzn1\.network\.link\.instance\.[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}/g;

const LINK_DELIMETER = ", ";

const getInstanceIdLink = (instanceId) => {
    const uuid = HelperFunctions.getUUID(instanceId);
    if (!uuid) {
        return <div>{ instanceId }</div>;
    }
    const linkSearchPath = window.location.href.substring(0, window.location.href.lastIndexOf("/") + 1);
    const truncatedUuid = `${uuid.substring(0, 4)}...${uuid.substring(uuid.length - 4, uuid.length)}`;
    return (
        <a href={linkSearchPath + uuid}>
            {truncatedUuid}
        </a>
    );
};

export const getClickableLinks = instanceIds => (
    <React.Fragment>
        {instanceIds?.map((instanceId, index) => (
            <React.Fragment key={instanceId}>
                {getInstanceIdLink(instanceId)}
                {index < instanceIds.length - 1 && LINK_DELIMETER}
            </React.Fragment>
        ))}
    </React.Fragment>
);
