import React, { Component } from "react";
import generateTroubleshootingStageColumnDefinitions from "order/stages/install/TroubleshootingInformation";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";

class TroubleshootingHandler extends Component {
    state = {
        updatedCircuitDesignObjects: [],
        isEditClicked: false,
        isUpdateStageInProgress: false,
        hasBeenSubmittedOnce: false,
        allFieldsDisabled: false,
        massUpdateSelectedCircuitDesignIds: []
    };

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItemsObjects = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.allFieldsDisabled
        );

        if (circuitItemsObjects.static.length > 0) {
            circuitItemsObjects.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.ATTRIBUTES.lightLevelTestingStatus]:
                        staticCircuitDesign[Constants.ATTRIBUTES.lightLevelTestingStatus] || "",
                    [Constants.ATTRIBUTES.receiveLightLevelA]:
                        staticCircuitDesign[Constants.ATTRIBUTES.receiveLightLevelA] || "",
                    [Constants.ATTRIBUTES.transmitLightLevelA]:
                        staticCircuitDesign[Constants.ATTRIBUTES.transmitLightLevelA] || "",
                    [Constants.ATTRIBUTES.receiveLightLevelZ]:
                        staticCircuitDesign[Constants.ATTRIBUTES.receiveLightLevelZ] || "",
                    [Constants.ATTRIBUTES.transmitLightLevelZ]:
                        staticCircuitDesign[Constants.ATTRIBUTES.transmitLightLevelZ] || "",
                    [Constants.ATTRIBUTES.portStatus]: ""
                }));
        }
        if (circuitItemsObjects.dynamic.length > 0) {
            circuitItemsObjects.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.ATTRIBUTES.lightLevelTestingStatus]:
                        dynamicCircuitDesign[Constants.ATTRIBUTES.lightLevelTestingStatus] || "",
                    [Constants.ATTRIBUTES.receiveLightLevelA]:
                        dynamicCircuitDesign[Constants.ATTRIBUTES.receiveLightLevelA] || "",
                    [Constants.ATTRIBUTES.transmitLightLevelA]:
                        dynamicCircuitDesign[Constants.ATTRIBUTES.transmitLightLevelA] || "",
                    [Constants.ATTRIBUTES.receiveLightLevelZ]:
                    dynamicCircuitDesign[Constants.ATTRIBUTES.receiveLightLevelZ] || "",
                    [Constants.ATTRIBUTES.transmitLightLevelZ]:
                    dynamicCircuitDesign[Constants.ATTRIBUTES.transmitLightLevelZ] || "",
                    [Constants.ATTRIBUTES.portStatus]: "",
                    errorTexts: this.state.hasBeenSubmittedOnce ? dynamicCircuitDesign.errorTexts :
                        {
                            receiveLightLevelA: "",
                            transmitLightLevelA: "",
                            receiveLightLevelZ: "",
                            transmitLightLevelZ: ""
                        }
                }));
        }
        return circuitItemsObjects;
    };

    FremontBackendClient = new FremontBackendClient();

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        // set dynamicCircuitDesigns and set errorTexts
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        updatedCircuitDesignObjects.forEach(circuitDesign => Object.assign(circuitDesign, {
            errorTexts: {}
        }));
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            hasBeenSubmittedOnce: false,
            updatedCircuitDesignObjects,
            allFieldsDisabled: false
        });
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        input.stageName = Constants.STAGE_NAMES.troubleshooting;
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(this.state.massUpdateSelectedCircuitDesignIds,
            input, circuitDesignOutput.circuitDesignObjects, this.state.updatedCircuitDesignObjects);
        this.setState({ updatedCircuitDesignObjects: output });
    };

    handleSelectedFromTable = (evt) => {
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        HelperFunctions.dismissFlashbar(this, {
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });

        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);

        // check for errors
        if (updatedCircuitDesignObjects.some(circuitDesign =>
            Object.values(circuitDesign.errorTexts).some(errorText => errorText))) {
            this.setState({
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            return;
        }

        const circuitsToUpdate = HelperFunctions.createNewApiObjects(this.props.circuitDesignObjects,
            updatedCircuitDesignObjects, Constants.ATTRIBUTES.circuitDesignId, Constants.KEEP_KEYS.CIRCUIT);

        try {
            if (circuitsToUpdate.length > 0) {
                // update the circuits and wait for a response
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.troubleshooting}
                    showAttachmentModal={false}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    handleToggleCompleteStage={HelperFunctions.isOrderInterconnectChange(this.props.order) ?
                        this.props.handleToggleCompleteStage : null}
                    completeStageMessage="Mark stage for completion"
                    hasStageBeenCompleted={this.props.order.hasTroubleshootingBeenCompleted}
                    handleCompleteStage={HelperFunctions.isOrderInterconnectChange(this.props.order) ?
                        this.props.handleToggleCompleteStage : null}
                    content={
                        <TableDisplayMode
                            order={this.props.order}
                            stageName={Constants.STAGE_NAMES.troubleshooting}
                            circuitItems={this.generateCircuitItems().static}
                            columnDefinitions={generateTroubleshootingStageColumnDefinitions(
                                this.props.order.lacpProvider,
                                this.generateCircuitItems().static.length,
                                this.props.order.serviceType
                            )}
                            isDataLoaded={this.props.isDataLoaded}
                        />
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.troubleshooting}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <TableEditMode
                            circuitItems={this.generateCircuitItems().dynamic}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            columnDefinitions={generateTroubleshootingStageColumnDefinitions(
                                this.props.order.lacpProvider,
                                this.generateCircuitItems().static.length,
                                this.props.order.serviceType
                            )}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
        );
    }
}

export default TroubleshootingHandler;