import React, { Component } from "react";
import AsnModal from "asn/AsnModal";
import ContactModal from "contact/ContactModal";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import OrderForm from "order/OrderForm";
import OrderValidation from "order/OrderValidation";
import ProviderModal from "provider/ProviderModal";
import ProviderServiceModal from "providerService/ProviderServiceModal";
import SiteModal from "site/SiteModal";

/**
 * CreateOrderPage renders the page holding the order creation form
 */
export default class CreateOrderPage extends Component {
    /**
     *  Initially set the orderInfo to mimic what it would be if it was
     *  inputted as blank (since it is blank on page load). It will later be updated by user inputs.
     */
    state = {
        order: {
            providerName: "",
            requiredCompletionDate: "",
            siteAId: "",
            siteZId: "",
            businessOperationsId: "",
            provisionerId: "",
            engineerId: "",
            installAndDecommission: false
        },
        providerServiceId: "",
        hasSubmittedOnce: false,
        isSubmissionInProgress: false,
        allFieldsDisabled: false,
        providerOptions: this.props.allProviders || [],
        providersLoading: this.props.allProvidersLoading,
        providerServiceOptions: [],
        asnOptions: [],
        asnLoading: false,
        sitesLoading: false,
        siteOptions: [],
        contactOptions: [],
        businessOperationsId: "",
        providerServiceItems: [], // Our full provider service options for the selected provider
        contacts: [],
        contactItems: [], // Our full contact options for the selected provider
        flashbar: {
            type: "",
            text: ""
        },
        resourceMap: {},
        selectedResourceOptions: {
            [Constants.ENGINEER]: {}, // We don't have a constant for this because it could be backbone or IP engineer
            [Constants.RESOURCE_TYPES.provisioner]: {}
        },
        isResourcesLoading: false,
        orderErrorTexts: OrderValidation.DEFAULT_ORDER_ERROR_TEXTS,
        servicesLoading: true,
        isAddProviderServiceClicked: false,
        isAddAsnClicked: false,
        isAddSiteClicked: false,
        isAddContactClicked: false,
        isAddServiceToContactClicked: false,
        isAddProviderClicked: false
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else {
            await HelperFunctions.handleAsynchronousCalls([
                this.getProviderFromProps(),
                this.getSites()
            ], HelperFunctions.displayFlashbarError);
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.allProvidersLoading !== this.props.allProvidersLoading) {
            this.setState({
                providerOptions: this.props.allProviders,
                providersLoading: this.props.allProvidersLoading
            });
        }
    }

    /**
     * This function fetches every provider item that currently exists in Fremont so that the user can choose from
     * a list of providers rather than trying to manually type in the name
     */
    getAllProviderItems = async () => {
        this.setState({ providersLoading: true });
        try {
            const providerOptions = await HelperFunctions.getAllProviderItems(this.props.auth);

            this.setState({
                providerOptions: providerOptions.filter(
                    provider => !Constants.NCIS_ONLY_PROVIDERS.includes(provider.label)
                ),
                providersLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { providersLoading: false });
        }
    };

    /**
     * This function fetches the provider from the passed in prop if appicable
     */
    getProviderFromProps = async () => {
        if (this.props.match && this.props.match.params.providerName) {
            const orderErrorTexts = HelperFunctions.deepClone(OrderValidation.DEFAULT_ORDER_ERROR_TEXTS);
            orderErrorTexts.providerName = "";
            this.setState({
                order: {
                    providerName: this.props.match.params.providerName,
                    requiredCompletionDate: ""
                },
                orderErrorTexts
            });
            await this.getContactsAndProviderServiceForProvider(this.props.match.params.providerName);
        }
    }

    /**
     * This function obtains all providerService items and asn objects associated with a provider
     */
    getContactsAndProviderServiceForProvider = async (providerName) => {
        this.setState({ providersLoading: true, servicesLoading: true });
        try {
            // Get the provider object
            const providerResponse = await this.FremontBackendClient.getProviderInfo(providerName, this.props.auth);

            this.setState({
                businessOperationsId: providerResponse.provider.businessOperationsId ?
                    providerResponse.provider.businessOperationsId : "BizOpsPlaceholder"
            });

            this.setState({ providersLoading: false });

            // We are syncronously loading the contact information since that will be filtered by the provider service
            // selections on the front end. So if we have the provider services before the contacts, we can't actually
            // filter the contacts right?
            const batchProviderContactResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CONTACT, providerResponse.provider.contactIdList, this.props.auth
            );

            const activeContacts = batchProviderContactResponse.contacts
                .filter(contact => contact.status === Constants.STATUS.active);

            const contactItems = activeContacts.map(contact => ({
                value: contact.contactId,
                label: `${contact.firstName} ${contact.lastName}`,
                providerServiceIdList: contact.providerServiceIdList
            }));
            this.setState({
                contactItems,
                contacts: activeContacts
            });

            await this.getProviderServicesForProvider(providerResponse.provider.providerServiceIdList);
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { providersLoading: false });
        }
    };

    getProviderServicesForProvider = async (providerServiceIdList) => {
        this.setState({ servicesLoading: true });
        try {
            const batchProviderServicesResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE, providerServiceIdList, this.props.auth
            );
            const providerServiceOptions = HelperFunctions
                .sortObjectsByField(batchProviderServicesResponse.providerServices
                    .map(service => ({ value: service.providerServiceId, label: service.serviceType })), "label");

            this.setState({
                providerServiceOptions,
                servicesLoading: false,
                providerServiceItems: batchProviderServicesResponse.providerServices
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { servicesLoading: false });
        }
    };

    getSites = async () => {
        // Get all the sites objects for the chosen provider service
        this.setState({ sitesLoading: true });
        try {
            const allSiteItems = await HelperFunctions.getAllSiteItems(this.props.auth);
            const siteOptions = [];

            allSiteItems.forEach(siteOption =>
                siteOptions.push(Object.assign(siteOption, {
                    geographicRegion: siteOption.site.geographicRegion
                })));
            HelperFunctions.sortObjectsByField(siteOptions, "label");

            this.setState({ sitesLoading: false, siteOptions });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { sitesLoading: false });
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This function handles change of inputs to the order information container fields.
     */
    handleOrderInputChange = async (evt) => {
        const oldOrder = HelperFunctions.deepClone(this.state.order);
        const oldProviderName = this.state.order.providerName;
        const oldServiceType = this.state.order.serviceType;
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.order);
        input.providerServiceId = this.state.providerServiceId;
        input.orderErrorTexts = HelperFunctions.deepClone(this.state.orderErrorTexts);
        input.providerOptions = HelperFunctions.deepClone(this.state.providerOptions);
        input.siteOptions = HelperFunctions.deepClone(this.state.siteOptions);
        input.asnOptions = HelperFunctions.deepClone(this.state.asnOptions);
        input.contactOptions = HelperFunctions.deepClone(this.state.contactOptions);
        const output = OrderValidation.validateInput(input);

        // Take care of the error texts based on the input changes (i.e if we move from one circuit type to another,
        // we want to clear out errors or add them if required fields are empty).
        // We have this here rather than the OrderValidation because the Create and Modify pages are likely to have
        // different validations (i.e for SiteAId when it comes to our circuit types).
        if (output.order.orderType === Constants.ORDER_TYPES.CHANGE) {
            output.orderErrorTexts.contactId = "";
            output.orderErrorTexts.requiredCompletionDate = "";
            // We don't required bizops for change orders
            output.order.businessOperationsId = "";
            output.order.installAndDecommission = false;
            output.order.contactId = "";
        }
        if (Constants.BILLING_ORDER_TYPES.includes(output.order.orderType)) {
            // We don't required bizops for billing order types orders
            output.order.businessOperationsId = "";
        }
        if (output.order.orderType === Constants.ORDER_TYPES.DECOMMISSION) {
            if (Constants.SERVICE_TYPES.SFP !== output.order.serviceType) {
                output.order.businessOperationsId = this.state.businessOperationsId;
            } else {
                output.order.businessOperationsId = "";
            }
            // We don't require provisioners for decom orders
            output.order.provisionerId = "";
            output.orderErrorTexts.provisionerId = "";
        }
        if (output.order.orderType === Constants.ORDER_TYPES.INSTALL) {
            // We don't required bizops for install orders
            output.order.businessOperationsId = "";
        }
        if (output.order.orderType === Constants.ORDER_TYPES.FABRIC_MIGRATION) {
            // The originalCustomerFabric is always BFB Path
            output.order.originalCustomerFabric = Constants.CUSTOMER_FABRICS.BFB_PATH;
            output.orderErrorTexts.originalCustomerFabric = "";
            // The destinationCustomerFabric (which we just store as the customerFabric) is always FNC Path
            output.order.customerFabric = Constants.CUSTOMER_FABRICS.FINAL_CAT_PATH;
            output.orderErrorTexts.customerFabric = "";
        } else {
            output.order.originalCustomerFabric = "";
        }
        if (output.order.serviceType !== Constants.SERVICE_TYPES.BACKBONE) {
            output.orderErrorTexts.siteZId = "";
            output.order.siteZId = "";
            output.orderErrorTexts.customerFabric = "";
            output.order.customerFabric = "";
            // Here we clear out the order type if it is not allowed on an interconnect order
            if (![Constants.ORDER_TYPES.INSTALL, Constants.ORDER_TYPES.CHANGE, Constants.ORDER_TYPES.DECOMMISSION]
                .includes(output.order.orderType)) {
                output.orderErrorTexts.orderType = Constants.ERROR_STRINGS.blankInput;
                output.order.orderType = "";
            }
        } else {
            if (!output.order.siteZId) {
                output.orderErrorTexts.siteZId = Constants.ERROR_STRINGS.blankInput;
            }
            if (!output.order.customerFabric) {
                output.orderErrorTexts.customerFabric = Constants.ERROR_STRINGS.blankInput;
            }
            // engineerId should only be set on path customer fabrics
            if (!Constants.PATH_CUSTOMER_FABRICS.includes(output.order.customerFabric)) {
                output.orderErrorTexts.engineerId = "";
                output.order.engineerId = "";
            }
        }
        if (!(output.order.serviceType === Constants.SERVICE_TYPES.IP_TRANSIT
            && output.order.orderType === Constants.ORDER_TYPES.INSTALL)) {
            output.orderErrorTexts.transitType = "";
            output.order.transitType = "";
        } else if (!output.order.transitType) {
            // If we have an IP Transit Install order, we want to default out transitType to "Full" based on the
            // requirements defined in FremontNEST-427
            output.order.transitType = Constants.TRANSIT_TYPE_FULL;
        }
        if (!(output.order.serviceType === Constants.SERVICE_TYPES.INTERNET_EXCHANGE
            && output.order.orderType === Constants.ORDER_TYPES.INSTALL)) {
            output.orderErrorTexts.peeringDBInternetExchangeName = "";
            output.order.peeringDBInternetExchangeName = "";
        } else if (!output.order.peeringDBInternetExchangeName) {
            output.orderErrorTexts.peeringDBInternetExchangeName = Constants.ERROR_STRINGS.blankInput;
        }
        if (!Constants.ASN_SERVICE_TYPES.includes(output.order.serviceType)) {
            output.orderErrorTexts.asnId = "";
            output.order.asnId = "";
        } else if (!output.order.asnId) {
            output.orderErrorTexts.asnId = Constants.ERROR_STRINGS.blankInput;
        }

        // If there is only a single business need for the current order and service type,
        // we default to that value
        const businessNeedOptions = OrderValidation.getBusinessNeedOptions(
            output.order.orderType, output.order.serviceType
        );
        if (businessNeedOptions.length === 1) {
            // The business need options are already in the form of a selectOptions ({label: ..., value: ...})
            // so we extract only the label from the sole option
            output.order.businessNeed = businessNeedOptions.find(Boolean).label;
            output.orderErrorTexts.businessNeed = "";
        }

        if (output.order.siteAId && output.order.siteZId
            && !output.orderErrorTexts.siteAId && !output.orderErrorTexts.siteZId) {
            const siteARegion = this.state.siteOptions
                .find(option => option.value === output.order.siteAId)[Constants.ATTRIBUTES.geographicRegion];
            const siteZRegion = this.state.siteOptions
                .find(option => option.value === output.order.siteZId)[Constants.ATTRIBUTES.geographicRegion];
            output.order.geographicRegion =
                Constants.ORDER_REGION_MAP_BASED_ON_GEOGRAPHIC_REGIONS[siteARegion][siteZRegion];
        } else if (output.order.siteAId && !output.orderErrorTexts.siteAId) {
            // This is used to automatically find the IP Engineer for Interconnect orders
            output.order.geographicRegion = this.state.siteOptions
                .find(option => option.value === output.order.siteAId)[Constants.ATTRIBUTES.geographicRegion];
        }

        // If the circuit type (provider service) has changed, fetch its related ASN's
        if (oldServiceType !== output.order.serviceType && output.order.serviceType) {
            output.providerServiceId = this.state.providerServiceItems.find(service =>
                service.serviceType === output.order.serviceType).providerServiceId;

            // Filter the contacts based on the circuit type (provider service) choice
            output.contactOptions = this.state.contactItems
                .filter(contact => contact.providerServiceIdList.includes(output.providerServiceId));

            if (Constants.ASN_SERVICE_TYPES.includes(output.order.serviceType)) {
                // We need to make a backend call to get asns
                await this.fetchBatchAsnInformation(output.order.serviceType);
            }
        }

        // If we manually override the automatic assignment of the resource, we need to update selectedResourceOptions
        if ([Constants.ATTRIBUTES.provisionerId, Constants.ATTRIBUTES.engineerId].includes(output.attributeId)) {
            const allResources = Object.values(this.state.resourceMap).flat();
            const newResource = allResources.find(resource =>
                resource.resourceName === output.order[output.attributeId]);

            // Figure out where this resource is stored in our selected options
            const selectedResourceOptions = HelperFunctions.deepClone(this.state.selectedResourceOptions);
            const selectedResourceKey = output.attributeId === Constants.ATTRIBUTES.provisionerId
                ? Constants.RESOURCE_TYPES.provisioner : Constants.ENGINEER;
            selectedResourceOptions[selectedResourceKey] =
                HelperFunctions.createSelectedOption(newResource.resourceName);
            this.setState({ selectedResourceOptions });
        }

        // TODO investigate why awaiting is necessary here. In the polaris V3 migration we needed to add
        // the await statement or else the call this.fetchResources(this.state.order, oldOrder); below
        // was picking up the old version of state and not the new version
        await this.setState({
            order: output.order,
            providerServiceId: output.providerServiceId,
            orderErrorTexts: output.orderErrorTexts,
            contactOptions: output.contactOptions
        });

        // Reset the provider-related options and fetch them based on the new provider if applicable
        if (!output.orderErrorTexts.providerName && output.order.providerName !== oldProviderName) {
            this.setState({
                asnOptions: [],
                providerServiceItems: [],
                providerServiceOptions: [],
                contactOptions: [],
                providerServiceId: ""
            });

            // The provider will always be non-blank since its a Select component now
            await this.getContactsAndProviderServiceForProvider(output.order.providerName);
        }

        // Fetch all the resources (if applicable)
        await this.fetchResources(this.state.order, oldOrder);
    };

    /**
     * This method is used for fetching batch asns based upon providerServiceItems
     */
    fetchBatchAsnInformation = async (serviceType) => {
        this.setState({
            asnLoading: true
        });
        try {
            const asnIds = this.state.providerServiceItems.find(
                providerService => providerService.serviceType === serviceType
            ).asnIdList;

            // Here we call the back end to get all the asn objects
            const getBatchAsnObjectsResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.ASN, asnIds, this.props.auth
            );

            const asnOptions = HelperFunctions
                .sortObjectsByField(getBatchAsnObjectsResponse.asns
                    .map(asn => ({ value: asn.asnId, label: asn.asnNumber })), "label");

            this.setState({
                asnOptions,
                asnLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { asns: [], asnLoading: false });
        }
    };

    fetchResources = async (newOrderState, oldOrderState) => {
        const resourceMap = HelperFunctions.deepClone(this.state.resourceMap);
        const selectedResourceOptions = HelperFunctions.deepClone(this.state.selectedResourceOptions);
        const orderErrorTexts = HelperFunctions.deepClone(this.state.orderErrorTexts);
        const order = HelperFunctions.deepClone(newOrderState);

        // If we've just enabled the provisioner field, load the provisioner data
        let needToMakeRequest = false;
        const resourceTypes = [];
        const request = {
            orderType: newOrderState.orderType,
            serviceType: newOrderState.serviceType
        };

        // For provisioners, whenever we have enough information we fetch the resources. If the provider name changes,
        // it resets all the fields, so we would once again wait until enough information is filled out
        if ((!this.isProvisionerFieldDisabled(newOrderState) && this.isProvisionerFieldDisabled(oldOrderState))
            || (!this.isProvisionerFieldDisabled(newOrderState)
                && oldOrderState.serviceType !== newOrderState.serviceType)
            || (!!oldOrderState.orderType && oldOrderState.orderType !== newOrderState.orderType
                && newOrderState.orderType !== Constants.ORDER_TYPES.DECOMMISSION)) {
            request.providerName = newOrderState.providerName;
            resourceTypes.push(Constants.RESOURCE_TYPES.provisioner);
            needToMakeRequest = true;

            // We need to clear out the existing selection if we are fetching this information
            this.clearOutSelectedResource(Constants.RESOURCE_TYPES.provisioner);
        }

        // We fetch the backbone engineer data when we have enough information filled or when we change the service type
        // to backbone
        if (newOrderState.serviceType === Constants.SERVICE_TYPES.BACKBONE
            && ((!this.isEngineerFieldDisabled(newOrderState) && this.isEngineerFieldDisabled(oldOrderState))
                || (!this.isEngineerFieldDisabled(newOrderState)
                    && oldOrderState.serviceType !== newOrderState.serviceType)
                || (!this.isEngineerFieldDisabled(newOrderState)
                    && oldOrderState.customerFabric !== newOrderState.customerFabric))) {
            // engineerId should only be set for path fabric types
            if (Constants.PATH_CUSTOMER_FABRICS.includes(newOrderState.customerFabric)) {
                resourceTypes.push(Constants.RESOURCE_TYPES.backBoneEngineer);
                needToMakeRequest = true;

                // We need to clear out the existing selection if we are fetching this information
                this.clearOutSelectedResource(Constants.ENGINEER);
            }
        }

        // We fetch the IP engineer data when we have enough information filled or when we change the service type
        // or site to a non-backbone service type
        if (newOrderState.serviceType !== Constants.SERVICE_TYPES.BACKBONE &&
            ((!this.isEngineerFieldDisabled(newOrderState) && this.isEngineerFieldDisabled(oldOrderState))
                || (!this.isEngineerFieldDisabled(newOrderState) && newOrderState.siteAId
                    && (oldOrderState.serviceType !== newOrderState.serviceType
                        || oldOrderState.siteAId !== newOrderState.siteAId)))) {
            request.siteName = this.state.siteOptions.find(option => option.value === newOrderState.siteAId).label;
            request.geographicRegion = newOrderState.geographicRegion;
            resourceTypes.push(Constants.RESOURCE_TYPES.ipEngineer);
            needToMakeRequest = true;

            // We need to clear out the existing selection if we are fetching this information
            this.clearOutSelectedResource(Constants.ENGINEER);
        }

        // Whenever we change the service type, we should clear out the current selection options
        if (oldOrderState.serviceType !== newOrderState.serviceType) {
            selectedResourceOptions[Constants.ENGINEER] = {};
            order[Constants.ATTRIBUTES.engineerId] = "";
            orderErrorTexts[Constants.ATTRIBUTES.engineerId] = Constants.ERROR_STRINGS.blankInput;
        }
        if (newOrderState.orderType !== Constants.ORDER_TYPES.DECOMMISSION
                && oldOrderState.serviceType !== newOrderState.serviceType) {
            selectedResourceOptions[Constants.RESOURCE_TYPES.provisioner] = {};
            order[Constants.ATTRIBUTES.provisionerId] = "";
            orderErrorTexts[Constants.ATTRIBUTES.provisionerId] = Constants.ERROR_STRINGS.blankInput;
        }

        // Make the request
        if (needToMakeRequest) {
            this.setState({ isResourcesLoading: true });
            request.resourceTypes = JSON.stringify(resourceTypes);
            try {
                // For local development we mock the resources since we won't really have them. If we want to connect to
                // beta and test things out, comment out the line below for HelperFunctions.isLocalHost()
                let response;
                if (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()) {
                    response = this.mockResourceForLocalDevelopment(resourceTypes);
                } else {
                    response = await this.FremontBackendClient.getResourcesBasedOffSearchParams(request,
                        this.props.auth);
                }

                // Process the response
                Object.keys(response.resourceMap).forEach((resourceType) => {
                    // if there are no results for a given key, we need to disable all the fields (including the submit
                    // button) and let the user know to atleast create a default resource for this combination
                    const resources = response.resourceMap[resourceType];
                    if (resources.length === 0) {
                        const errorMessage = `No applicable or default ${resourceType} was found. Please assign one before creating an order.`;
                        HelperFunctions.displayFlashbarError(this, { message: errorMessage }, {
                            allFieldsDisabled: true
                        });
                    } else {
                        resourceMap[resourceType] = resources;
                        let selectedResource;
                        if (resources.length === 1) {
                            selectedResource = resources.find(Boolean);
                        }
                        if (resources.length === 2 && resources.some(resource => resource.defaultResource)) {
                            selectedResource = resources.filter(resource => !resource.defaultResource).find(Boolean);
                        }

                        if (selectedResource) {
                            const selectedResourceOption =
                                HelperFunctions.createSelectedOption(selectedResource.resourceName);
                            if ([Constants.RESOURCE_TYPES.backBoneEngineer, Constants.RESOURCE_TYPES.ipEngineer]
                                .includes(resourceType)) {
                                selectedResourceOptions[Constants.ENGINEER] = selectedResourceOption;
                                order[Constants.ATTRIBUTES.engineerId] = selectedResourceOption.value;
                                orderErrorTexts[Constants.ATTRIBUTES.engineerId] = "";
                            } else {
                                selectedResourceOptions[resourceType] = selectedResourceOption;
                                order[Constants.ATTRIBUTES.provisionerId] = selectedResourceOption.value;
                                orderErrorTexts[Constants.ATTRIBUTES.provisionerId] = "";
                            }
                        }
                    }
                });
            } catch (error) {
                HelperFunctions.displayFlashbarError(this, error, { isResourcesLoading: false });
            }
        }

        // Update the state
        this.setState({
            resourceMap,
            selectedResourceOptions,
            orderErrorTexts,
            order,
            isResourcesLoading: false
        });
    };

    mockResourceForLocalDevelopment = (resourceTypes) => {
        const resourceMap = {};
        const devAlias = this.props.auth.userId;

        resourceTypes.forEach((resourceType) => {
            resourceMap[resourceType] = [{ resourceId: devAlias, resourceName: devAlias }];
        });
        return { resourceMap };
    };

    clearOutSelectedResource = (resourceType) => {
        const selectedResourceOptions = HelperFunctions.deepClone(this.state.selectedResourceOptions);
        selectedResourceOptions[resourceType] = {};
        this.setState({ selectedResourceOptions });
    };

    /**
     * Send order info to backend when the submit button is clicked
     */
    handleOrderSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true, allFieldsDisabled: true });
        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.orderErrorTexts).some(errorText => !!errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    hasSubmittedOnce: true,
                    isSubmissionInProgress: false,
                    allFieldsDisabled: false
                }
            );
            return;
        }

        // For Backbone orders, if we don't have the sites in alphanumerical order, throw an error
        if (this.state.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            const siteAName = this.state.siteOptions.find(option => option.value === this.state.order.siteAId).label;
            const siteZName = this.state.siteOptions.find(option => option.value === this.state.order.siteZId).label;

            let siteError = "";
            if (siteAName === siteZName) {
                siteError = "Please choose separate sites for Site A and Site Z.";
            } else if (siteAName > siteZName) {
                siteError = "Site A and Z must be in alphanumerical order.";
            }

            if (siteError) {
                HelperFunctions.displayFlashbarError(
                    this,
                    new Error(siteError),
                    {
                        hasSubmittedOnce: true,
                        isSubmissionInProgress: false,
                        allFieldsDisabled: false
                    }
                );
                return;
            }
        }

        const orderToSubmit = HelperFunctions.deepClone(this.state.order);
        // RelatedOrderId is what the user entered, the backend requires a list however
        if (orderToSubmit[Constants.ATTRIBUTES.relatedOrderId]) {
            orderToSubmit[Constants.ATTRIBUTES.relatedOrderIdList] =
                [orderToSubmit[Constants.ATTRIBUTES.relatedOrderId]];
        }
        // The owner of an order is, by default, the user that creates the order
        orderToSubmit[Constants.ATTRIBUTES.ownerId] =
            this.props.auth.userId;
        try {
            const response = await this.FremontBackendClient.createOrder(orderToSubmit, this.props.auth);

            this.props.history.push(`${Constants.ROUTES.order}/${response.orderId}`);
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isSubmissionInProgress: false,
                allFieldsDisabled: false
            });
        }
    };

    /**
     * Make create provider service modal appear
     */
    showProviderServiceModal = () => {
        this.setState({
            isAddProviderServiceClicked: true
        });
    };

    /**
     * Closing the provider service modal using the cancel or dismiss buttons
     */
    handleProviderServiceModalDismiss = () => {
        this.setState({
            isAddProviderServiceClicked: false
        });
    };

    /**
     * Closing the provider service modal should reload the provider services in case any were added
     */
    handleProviderServiceModalSubmit = async () => {
        this.handleProviderServiceModalDismiss();
        this.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false);
        await this.getContactsAndProviderServiceForProvider(this.state.order.providerName);
    };

    /**
     * Make create ASN  modal appear
     */
    handleCreateAsn = async () => {
        this.setState({
            isAddAsnClicked: true
        });
    };

    /**
     * Closing the ASN modal should reload the asns in case any were added
     */
    handleAsnModalClick = async () => {
        this.setState({
            isAddAsnClicked: false
        });

        // When the ASN modal is closed, we reload all data in case an ASN was added
        await this.getContactsAndProviderServiceForProvider(this.state.order.providerName);

        // Here we create the ASN options so that the newly created ASN is selectable as an option
        await this.fetchBatchAsnInformation(this.state.order.serviceType);
    };

    /**
     * Make create Site modal appear
     */
    handleCreateSite = async () => {
        this.setState({
            isAddSiteClicked: true
        });
    };

    /**
     * Closing the Site modal should reload the sites in case any were added
     */
    handleSiteModalClick = async (evt) => {
        this.setState({
            isAddSiteClicked: false
        });
        if (evt.target.id === Constants.SUBMIT_BUTTON_ID) {
            await this.getSites();
        }
    };

    /**
     * Make create contact modal appear
     */
    handleCreateContact = async () => {
        this.setState({
            isAddContactClicked: true
        });
    };

    /**
     * Make add service to contact modal appear
     */
    handleAddServiceToContact = async () => {
        this.setState({
            isAddServiceToContactClicked: true
        });
    };

    /**
     * Closing the contact modal should reload the contacts in case any were added
     */
    handleContactModalClick = async (evt) => {
        this.setState({
            isAddContactClicked: false,
            isAddServiceToContactClicked: false
        });
        // Seems like we need new provider/contact information to not have stale data.
        if (evt.target.id === Constants.SUBMIT_BUTTON_ID) {
            await this.getContactsAndProviderServiceForProvider(this.state.order.providerName);
        }

        // Filter the contacts based on the circuit type (provider service) choice
        this.setState({
            contactOptions: this.state.contactItems
                .filter(contact => contact.providerServiceIdList.includes(this.state.providerServiceId))
        });
    };

    /**
     * Make create provider modal appear
     */
    handleCreateProvider = async () => {
        this.setState({
            isAddProviderClicked: true
        });
    };

    /**
     * Closing the provider modal should reload the providers in case any were added
     */
    handleProviderModalClick = async (evt) => {
        this.setState({
            isAddProviderClicked: false
        });
        if (evt.target.id === Constants.SUBMIT_BUTTON_ID) {
            await this.getAllProviderItems();
        }
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, { loading: false });
    };

    isProvisionerFieldDisabled = order => !order.orderType || !order.serviceType || !order.providerName;

    isEngineerFieldDisabled = (order) => {
        if (!order.orderType || !order.serviceType
            || (Constants.SERVICE_TYPES.BACKBONE !== order.serviceType && !order.siteAId)
            || (Constants.SERVICE_TYPES.BACKBONE === order.serviceType && !order.customerFabric)) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarType={this.state.flashbar.type}
                    flashbarText={this.state.flashbar.text}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                <OrderForm
                    order={this.state.order}
                    orderErrorTexts={this.state.hasSubmittedOnce ? this.state.orderErrorTexts : {}}
                    handleOrderSubmit={this.handleOrderSubmit}
                    handleOrderInputChange={this.handleOrderInputChange}
                    hasSubmittedOnce={this.state.hasSubmittedOnce}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    allFieldsDisabled={this.state.allFieldsDisabled}
                    providerOptions={this.state.providerOptions}
                    providersLoading={this.state.providersLoading}
                    providerServiceOptions={this.state.providerServiceOptions}
                    asnOptions={this.state.asnOptions}
                    asnLoading={this.state.asnLoading}
                    sitesLoading={this.state.sitesLoading}
                    siteOptions={this.state.siteOptions}
                    providerServiceExists={this.state.providerServiceExists}
                    servicesLoading={this.state.servicesLoading}
                    showProviderServiceModal={this.showProviderServiceModal}
                    handleCreateAsn={this.handleCreateAsn}
                    contactOptions={this.state.contactOptions}
                    handleCreateSite={this.handleCreateSite}
                    handleCreateContact={this.handleCreateContact}
                    handleAddServiceToContact={this.handleAddServiceToContact}
                    handleCreateProvider={this.handleCreateProvider}
                    resourceMap={this.state.resourceMap}
                    selectedResourceOptions={this.state.selectedResourceOptions}
                    isProvisionerFieldDisabled={this.isProvisionerFieldDisabled(this.state.order)}
                    isEngineerFieldDisabled={this.isEngineerFieldDisabled(this.state.order)}
                    isResourcesLoading={this.state.isResourcesLoading}
                />
                <ProviderServiceModal
                    match={{
                        params: {
                            providerName: this.state.order.providerName
                        }
                    }}
                    auth={this.props.auth}
                    isAddProviderServiceClicked={this.state.isAddProviderServiceClicked}
                    handleProviderServiceModalSubmit={this.handleProviderServiceModalSubmit}
                    handleProviderServiceModalDismiss={this.handleProviderServiceModalDismiss}
                    providerOptions={this.state.providerOptions}
                    providersLoading={this.state.providersLoading}
                />
                <AsnModal
                    match={{
                        params: {
                            providerName: this.state.order.providerName
                        }
                    }}
                    serviceType={this.state.order.serviceType}
                    auth={this.props.auth}
                    isAddAsnClicked={this.state.isAddAsnClicked}
                    handleAsnModalClick={this.handleAsnModalClick}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    providerOptions={this.state.providerOptions}
                    providersLoading={this.state.providersLoading}
                />
                <SiteModal
                    auth={this.props.auth}
                    isAddSiteClicked={this.state.isAddSiteClicked}
                    handleSiteModalClick={this.handleSiteModalClick}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    providerOptions={this.state.providerOptions}
                    providersLoading={this.state.providersLoading}
                />
                <ProviderModal
                    auth={this.props.auth}
                    isAddProviderClicked={this.state.isAddProviderClicked}
                    handleProviderModalClick={this.handleProviderModalClick}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    providerOptions={this.state.providerOptions}
                    providersLoading={this.state.providersLoading}
                />
                <ContactModal
                    match={{
                        params: {
                            providerName: this.state.order.providerName,
                            providerServiceId: this.state.providerServiceId,
                            serviceType: this.state.order.serviceType
                        }
                    }}
                    auth={this.props.auth}
                    contactItems={this.state.contactItems}
                    contactOptions={this.state.contactOptions}
                    contacts={this.state.contacts}
                    providerServiceItems={this.state.providerServiceItems}
                    providerServiceOptions={this.state.providerServiceOptions}
                    isAddContactClicked={this.state.isAddContactClicked}
                    isAddServiceToContactClicked={this.state.isAddServiceToContactClicked}
                    handleContactModalClick={this.handleContactModalClick}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    providerOptions={this.state.providerOptions}
                    providersLoading={this.state.providersLoading}
                />
            </div>
        );
    }
}