import React from "react";
import {
    Box,
    Flashbar,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton
} from "utils/CommonComponents";
import AttachmentForm from "attachment/AttachmentForm";
import HelperFunctions from "common/HelperFunctions";

const AttachmentModal = (props) => {
    const attachmentTypesWithAllCircuits = [];

    // If we are looking at circuit attachments, figure out which attachment type has all the circuits
    if (props.circuitDesignOptions) {
        Object.keys(props.circuitDesignsAttachmentTypeMap).forEach((attachmentType) => {
            if (props.circuitDesignsAttachmentTypeMap[attachmentType].length === props.circuitDesignOptions.length) {
                attachmentTypesWithAllCircuits.push(attachmentType);
            }
        });
    }

    // Filter out any attachmentTypes where all the circuits are assigned to it (we shouldn't show those options)
    let attachmentOptions = HelperFunctions.deepClone(props.attachmentOptions);
    attachmentOptions = attachmentOptions.filter(option => !attachmentTypesWithAllCircuits.includes(option));

    // Make sure there are no errors on any of the attachments
    let disabledAddAttachmentButton = false;
    let errors = [];

    props.attachments.forEach((attachment) => {
        errors = errors.concat(Object.values(attachment.errorTexts).filter(error => error !== ""));
    });

    if (errors.length > 0 || attachmentOptions.length === 0) {
        disabledAddAttachmentButton = true;
    }

    if (props.attachments.length > 0 && props.isAttachmentForBulkUpdateType) {
        disabledAddAttachmentButton = true;
    }

    return (
        <Modal
            size="large"
            visible={props.isAttachmentModalClicked}
            header="Add Attachment Form"
            footer={
                <Box variant="span" float="right">
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            variant="normal"
                            onClick={props.hideAttachmentModal}
                            disabled={props.isAttachmentSubmissionInProgress}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            variant="primary"
                            onClick={props.handleAttachmentSubmit}
                            loading={props.isAttachmentSubmissionInProgress}
                            disabled={props.disableSubmitButton}
                        >
                            {props.isAttachmentSubmissionInProgress ? "Submitting..." : "Submit"}
                        </FremontButton>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={props.hideAttachmentModal}
        >
            <Flashbar items={props.errorToDisplayOnAttachmentModal}/>
            <SpaceBetween size="l">
                <div>
                    {props.attachments.map(attachment => (
                        <div key={attachment.attachmentFormKey}>
                            <AttachmentForm
                                attachment={attachment}
                                attachmentOptions={attachmentOptions}
                                isAttachmentSubmissionInProgress={props.isAttachmentSubmissionInProgress}
                                fileInputRef={props.attachmentFormToReactRefMap[attachment.attachmentFormKey]}
                                handleFileInput={props.handleFileInput}
                                triggerFileInput={props.triggerFileInput}
                                handleAttachmentModalInputChange={props.handleAttachmentModalInputChange}
                                hasSubmittedAttachmentOnce={props.hasSubmittedAttachmentOnce}
                                removeAttachment={props.removeAttachment}
                                toggleAllCircuits={props.toggleAllCircuits}
                                // Circuit props
                                circuitDesignsAttachmentTypeMap={props.circuitDesignsAttachmentTypeMap}
                                circuitDesignOptions={props.circuitDesignOptions}
                                isAttachmentForOrderType={props.isAttachmentForOrderType}

                                // Bulk Update props
                                handleBulkUpdateTableType={props.handleBulkUpdateTableType}
                                handleBulkUpdateDescription={props.handleBulkUpdateDescription}
                                bulkUpdateTableType={props.bulkUpdateTableType}
                                getBulkUpdateDescription={props.getBulkUpdateDescription}
                            />
                        </div>
                    ))}
                </div>
                <FremontButton
                    id="addAttachment"
                    onClick={props.addNewAttachment}
                    disabled={props.isAttachmentSubmissionInProgress || disabledAddAttachmentButton}
                >
                    Add New Attachment
                </FremontButton>
            </SpaceBetween>
        </Modal>
    );
};

export default AttachmentModal;