import React from "react";
import { ClipLoader } from "react-spinners";
import BillingSegmentRecord from "billing/BillingSegmentRecord";
import Constants from "utils/Constants";

/**
 BillingTab is the actual table itself which displays data from the backend data store.
 */
const BillingTab = props => (
    props.loading ?
        <div className="centerSpinner">
            <ClipLoader
                color={Constants.SPINNER_ORANGE_COLOR}
                loading
                size={Constants.SPINNER_SIZES.loadingPage}
            />
        </div>
        :
        <BillingSegmentRecord
            auth={props.auth}
            handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
            handleFlashbarClose={props.handleFlashbarClose}
            providerOptions={props.allProviderOptions}
        />
);

export default BillingTab;