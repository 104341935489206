import React, { Component } from "react";
import {
    CablingInstallStageEditMode,
    CablingInstallStageDisplayMode
} from "order/stages/install/CablingInstallInformation";
import Constants from "utils/Constants";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    StageEditMode
} from "order/OrderCommonComponents";

class CablingInstallHandler extends Component {
    static KEYS_TO_IGNORE = [
        Constants.COMPONENT_NAMES.crossConnectA,
        Constants.COMPONENT_NAMES.crossConnectZ,
        Constants.COMPONENT_NAMES.demarcAndCfaA,
        Constants.COMPONENT_NAMES.demarcAndCfaZ,
        Constants.COMPONENT_NAMES.providerCircuitA
    ];

    state = {
        updatedCircuitDesignObjects: [],
        isEditClicked: false,
        isUpdateStageInProgress: false,
        hasBeenSubmittedOnce: false,
        massUpdateSelectedCircuitDesignIds: [],
        attachmentIdToComprehendDataMap: {}
    };

    componentDidMount = async () => {
        await this.handleGetAttachments();
    }

    getComprehendDropdownOptionsForCircuit = (circuitDesignId, type) => {
        const circuit = this.props.circuitDesignObjects
            .filter(circuitDesign => circuitDesign.circuitDesignId === circuitDesignId).pop();
        if (circuit && circuit.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA] &&
            circuit.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0) {
            const loaAId = circuit.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean);
            if (this.state.attachmentIdToComprehendDataMap[loaAId]) {
                const returnVal = HelperFunctions.createSelectedOptionsForComprehend(
                    this.state.attachmentIdToComprehendDataMap[loaAId][type]
                );
                return returnVal;
            }
        }

        return [];
    }

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItemsObjects = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.isUpdateStageInProgress
        );

        if (circuitItemsObjects.static.length > 0) {
            circuitItemsObjects.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.COMPONENT_NAMES.demarcAndCfaA]: HelperFunctions.getDemarcAndCfaDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.demarcAndCfaA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.COMPONENT_NAMES.demarcAndCfaZ]: HelperFunctions.getDemarcAndCfaDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.demarcAndCfaZ,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.COMPONENT_NAMES.crossConnectA]: HelperFunctions.getCrossConnectDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.crossConnectA
                    ),
                    [Constants.COMPONENT_NAMES.crossConnectZ]: HelperFunctions.getCrossConnectDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.crossConnectZ
                    ),
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.leverA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverA
                    ),
                    [Constants.COMPONENT_NAMES.leverZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverZ
                    ),
                    [Constants.COMPONENT_NAMES.leverAExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverAInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.providerCircuitA,
                        this.props.order,
                        this.props.componentIdToObjectMap
                    ),
                    [Constants.ATTRIBUTES.loaAttachmentIdA]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean)
                            : "",
                    [Constants.ATTRIBUTES.loaAttachmentIdZ]:
                        staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ]
                        && staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? staticCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].find(Boolean)
                            : "",
                    downloadAttachment: this.props.downloadAttachment,
                    isDownloadingAttachment: this.props.isDownloadingAttachment
                }));
        }
        if (circuitItemsObjects.dynamic.length > 0) {
            circuitItemsObjects.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    [Constants.COMPONENT_NAMES.demarcAndCfaA]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.demarcAndCfaA],
                    [Constants.COMPONENT_NAMES.demarcAndCfaZ]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.demarcAndCfaZ],
                    [Constants.COMPONENT_NAMES.crossConnectA]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.crossConnectA],
                    [Constants.COMPONENT_NAMES.crossConnectZ]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.crossConnectZ],
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.leverA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverA
                    ),
                    [Constants.COMPONENT_NAMES.leverZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverZ
                    ),
                    [Constants.COMPONENT_NAMES.leverAExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverAInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            dynamicCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.providerCircuitA]:
                        dynamicCircuitDesign[Constants.COMPONENT_NAMES.providerCircuitA],
                    [Constants.ATTRIBUTES.loaAttachmentIdA]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean)
                            : "",
                    comprehendCFAOptions: this.getComprehendDropdownOptionsForCircuit(
                        dynamicCircuitDesign.circuitDesignId, Constants.AWS_COMPREHEND_KEYS.CFA
                    ),
                    comprehendProviderCircuitOptions: this.getComprehendDropdownOptionsForCircuit(
                        dynamicCircuitDesign.circuitDesignId, Constants.AWS_COMPREHEND_KEYS.PROVIDER_CIRCUIT
                    ),
                    [Constants.ATTRIBUTES.loaAttachmentIdZ]:
                        dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ]
                        && dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].length > 0
                            // find(Boolean) returns the first element in an array
                            ? dynamicCircuitDesign.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaZ].find(Boolean)
                            : ""
                }));
        }
        return circuitItemsObjects;
    };

    FremontBackendClient = new FremontBackendClient();

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects)
            .map(circuitDesign => Object.assign(circuitDesign, {
                [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getProviderCircuitDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.providerCircuitA,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ),
                [Constants.COMPONENT_NAMES.demarcAndCfaA]: HelperFunctions.getDemarcAndCfaDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.demarcAndCfaA,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ) || "",
                [Constants.COMPONENT_NAMES.demarcAndCfaZ]: HelperFunctions.getDemarcAndCfaDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.demarcAndCfaZ,
                    this.props.order,
                    this.props.componentIdToObjectMap
                ) || "",
                [Constants.COMPONENT_NAMES.crossConnectA]: HelperFunctions.getCrossConnectDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.crossConnectA
                ) || "",
                [Constants.COMPONENT_NAMES.crossConnectZ]: HelperFunctions.getCrossConnectDisplayValue(
                    circuitDesign.positionMap,
                    Constants.COMPONENT_NAMES.crossConnectZ
                ) || "",
                errorTexts: {}
            }));
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            hasBeenSubmittedOnce: false,
            updatedCircuitDesignObjects
        });
    };

    handleGetAttachments = async () => {
        try {
            const attachmentIds = new Set();
            this.props.circuitDesignObjects.forEach((circuit) => {
                if (circuit.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA]
                    && circuit.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].length > 0) {
                    attachmentIds.add(circuit.attachmentIdMap[Constants.ATTACHMENT_TYPES.loaA].find(Boolean));
                }
            });
            // if no attachments which are LOAs or if Backbone, then just return
            if (attachmentIds.size === 0 || this.props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) return;

            const attachmentIdList = Array.from(attachmentIds);
            let allAttachments = [];

            // the reason we are breaking the getbatch attachments call into multiple calls
            // is that fetching from s3 buckets can be slow and can timeout the apigateway or overload the lambda
            // therefore we are relying on the frontend to make safe calls with a max of 100 attachments at a time.
            for (let i = 0; i < attachmentIds.size; i += 100) {
                // eslint-disable-next-line no-await-in-loop
                const batchAttachmentsResponse = await this.FremontBackendClient.getBatchAttachmentInfo(
                    attachmentIdList.slice(i, i + 100),
                    this.props.auth
                );
                allAttachments = allAttachments.concat(batchAttachmentsResponse.attachments);
            }

            const attachmentIdToComprehendDataMap = {};
            allAttachments
                .filter(attachment => attachment.comprehendDropdown)
                .forEach((attachment) => {
                    attachmentIdToComprehendDataMap[attachment.attachmentId] =
                        JSON.parse(JSON.parse(attachment.comprehendDropdown));
                });

            this.setState({
                attachmentIdToComprehendDataMap
            });
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false
            });
        }
    }

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        input.stageName = Constants.STAGE_NAMES.cabling;
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(
            this.state.massUpdateSelectedCircuitDesignIds, input, circuitDesignOutput.circuitDesignObjects
        );
        this.setState({ updatedCircuitDesignObjects: output });
    };

    handleSelectedFromTable = (evt) => {
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true
        });

        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const originalCircuitDesignObjects = this.generateCircuitItems().static;
        if (updatedCircuitDesignObjects.some(circuitDesign =>
            Object.values(circuitDesign.errorTexts).some(errorText => errorText))) {
            this.setState({
                isUpdateStageInProgress: false
            });
            return;
        }

        // Deal with cross connects. When its null, it means its been emptied out
        updatedCircuitDesignObjects.forEach((circuit) => {
            if (circuit[Constants.COMPONENT_NAMES.crossConnectA]
                || circuit[Constants.COMPONENT_NAMES.crossConnectA] === null) {
                const crossConnectA = Object.values(circuit.positionMap).find(entry =>
                    entry[Constants.COMPONENT_KEYS.name] === Constants.COMPONENT_NAMES.crossConnectA);
                if (crossConnectA) {
                    Object.assign(crossConnectA, {
                        [Constants.COMPONENT_KEYS.uuid]: circuit[
                            Constants.COMPONENT_NAMES.crossConnectA]
                    });
                }
            }
            if (circuit[Constants.COMPONENT_NAMES.crossConnectZ]
                || circuit[Constants.COMPONENT_NAMES.crossConnectZ] === null) {
                const crossConnectZ = Object.values(circuit.positionMap).find(entry =>
                    entry[Constants.COMPONENT_KEYS.name] === Constants.COMPONENT_NAMES.crossConnectZ);
                if (crossConnectZ) {
                    Object.assign(crossConnectZ, {
                        [Constants.COMPONENT_KEYS.uuid]: circuit[
                            Constants.COMPONENT_NAMES.crossConnectZ]
                    });
                }
            }
        });

        const circuitsToUpdate = HelperFunctions.createNewApiObjects(this.props.circuitDesignObjects,
            updatedCircuitDesignObjects, Constants.ATTRIBUTES.circuitDesignId, Constants.KEEP_KEYS.CIRCUIT,
            CablingInstallHandler.KEYS_TO_IGNORE);

        try {
            if (circuitsToUpdate.length > 0) {
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }

            await this.FremontBackendClient.modifyProviderCircuit(updatedCircuitDesignObjects,
                originalCircuitDesignObjects, this.props.auth);

            await this.FremontBackendClient.modifyDemarcAndCFA(updatedCircuitDesignObjects,
                originalCircuitDesignObjects, this.props.auth);

            // Here we call a helper function which updates all data related to the order
            // and loads new component info as well
            await this.props.loadData(true, true);

            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false
            });
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isEditClicked: false,
                isUpdateStageInProgress: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.cabling}
                    showAttachmentModal={this.props.showAttachmentModal}
                    disableAttachmentButton={OrderValidation.disableAttachmentButton(
                        this.generateCircuitItems().static.length,
                        this.props.orderCompleted,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    showTemplateGeneratorModal={this.props.showTemplateGeneratorModal}
                    content={
                        <CablingInstallStageDisplayMode
                            stageName={Constants.STAGE_NAMES.cabling}
                            order={this.props.order}
                            siteNames={this.props.siteNames}
                            circuitItems={this.generateCircuitItems().static}
                            isDataLoaded={this.props.isDataLoaded}
                        />
                    }
                    handleCompleteStage={HelperFunctions.isOrderInterconnectChange(this.props.order) ?
                        this.props.handleToggleCompleteStage : null}
                    completeStageMessage="Mark stage for completion"
                    hasStageBeenCompleted={this.props.order.hasCablingBeenCompleted}
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.cabling}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <CablingInstallStageEditMode
                            stageName={Constants.STAGE_NAMES.cabling}
                            order={this.props.order}
                            siteNames={this.props.siteNames}
                            circuitItems={this.generateCircuitItems().dynamic}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                            attachmentIdToComprehendDataMap
                        />
                    }
                />
        );
    }
}

export default CablingInstallHandler;