import React from "react";
import {
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const FILTER_STAGE_COLUMN_DEFINITIONS = [
    {
        id: Constants.TABLE_IDS.circuitDesignLink,
        sortingField: Constants.TABLE_IDS.circuitDesignLink,
        sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
            Constants.ATTRIBUTES.circuitDesignNumber),
        header: "Circuit ID",
        description: "The Fremont generated ID of the circuit and display value",
        width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
        cell: item => (
            <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                {item.circuitDesignNumber}
            </Link>
        )
    },
    {
        id: Constants.TABLE_IDS.stageStatus,
        header: "Status",
        description: "Stage status for this circuit",
        width: Constants.COLUMN_WIDTHS.statusColumn,
        cell: item => HelperFunctions.showStageStatusWithBlocker(
            item.stageStatusMap[Constants.STAGE_NAMES.filter], item.blockers
        )
    },
    {
        id: Constants.ATTRIBUTES.revisionNumber,
        header: "Version",
        description: "description",
        width: Constants.COLUMN_WIDTHS.version,
        cell: item => `V${item[Constants.ATTRIBUTES.revisionNumber]}`
    },
    {
        id: Constants.ATTRIBUTES.circuitBandwidth,
        header: "Bandwidth",
        description: "Bandwidth for this circuitDesign",
        width: Constants.COLUMN_WIDTHS.bandwidth,
        cell: item => item[Constants.ATTRIBUTES.circuitBandwidth]
    },
    {
        id: Constants.ATTRIBUTES.filterStatus,
        header: "Filter Status",
        description: "Filter status of the circuit",
        cell: item => (
            !item.editable ?
                item[Constants.ATTRIBUTES.filterStatus] :
                <div className={HelperFunctions.assignShortTableClass(item.numberOfItems)}>
                    <FremontSelect
                        id={`${Constants.ATTRIBUTES.filterStatus}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        options={Constants.COMPLETION_OPTIONS}
                        selectedOption={HelperFunctions.createSelectedOption(item[Constants.ATTRIBUTES.filterStatus])}
                        onChange={item.handleStageInputChange}
                        disabled={item.isUpdateStageInProgress || item.allFieldsDisabled}
                        expandToViewport
                    />
                </div>
        )
    },
    {
        id: Constants.COMPONENT_NAMES.nodeA,
        header: "Router A",
        description: "Node A for this circuitDesign",
        cell: item => item[Constants.COMPONENT_NAMES.nodeA]
    },
    {
        id: Constants.COMPONENT_NAMES.portA,
        header: "Port A",
        description: "Port A for this circuitDesign",
        cell: item => item[Constants.COMPONENT_NAMES.portA]
    },
    {
        id: Constants.COMPONENT_NAMES.lagA,
        header: "LAG A",
        description: "Lag A for this circuitDesign",
        cell: item => item[Constants.COMPONENT_NAMES.lagA]
    }
];

export default FILTER_STAGE_COLUMN_DEFINITIONS;