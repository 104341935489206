import React from "react";
import { ClipLoader } from "react-spinners";
import {
    Box,
    Container,
    FormField,
    Icon,
    Header,
    SpaceBetween,
    TokenGroup
} from "@amzn/awsui-components-react/polaris";
import {
    FremontSelect,
    FremontButton
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import OrderValidation from "order/OrderValidation";

const renderBlockerContainer = (blockersLoading, circuitDesignsLoading, blockers,
    showModal) => {
    // Display a spinner when blockers are loading
    if (blockersLoading || circuitDesignsLoading) {
        return (
            <div className="centerBlockerSpinner">
                <ClipLoader
                    color={Constants.SPINNER_ORANGE_COLOR}
                    size={Constants.SPINNER_SIZES.blockerModal}
                />
            </div>
        );
    }
    // If at least on blocker exists, display the blocker(s)
    if (blockers.length > 0) {
        // Here we sort the blockers so that they are displayed first based on whether
        // they are active or not and then alphabetically
        const alertDivs = blockers.sort((a, b) => {
            if (a.status === b.status) {
                return (a.jepCode > b.jepCode ? 1 : -1);
            }
            return a.status === Constants.STATUS.active ? -1 : 1;
        }).map(blocker => (
            <div
                key={blocker.blockerId}
                className={blocker.status === Constants.STATUS.active ? "alert-error-button" : "alert-success-button"}
            >
                <FremontButton
                    key={blocker.blockerId}
                    id={`${OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker}${Constants.SEPARATOR}${blocker.blockerId}`}
                    onClick={showModal}
                >
                    {blocker.status === Constants.STATUS.active ?
                        <Icon name="status-negative"/> : <Icon name="status-positive"/>}{` ${blocker.jepCode}`}
                </FremontButton>
            </div>
        ));
        return (
            <div>
                {alertDivs}
            </div>
        );
    }
    // When the blockers are loaded but none exist, display a message
    return (
        <Box textAlign="center">
            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                <b>
                    {Constants.EMPTY_TABLE_MESSAGES.noBlockers}
                </b>
            </Box>
        </Box>
    );
};

const OrderSidebar = props => (
    <SpaceBetween size="l">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <FremontButton
                            id={OrderValidation.ORDER_PAGE_MODAL_TYPES.tag}
                            onClick={props.showModal}
                            // Going for disabled rather than a spinner because the autosuggest form shows a spinner
                            disabled={props.tagsLoading || (props.isAnyEditModeEnabled && !props.isOrderCompleted)}
                            loading={props.isTagSubmissionInProgress}
                        >
                            Add Tag
                        </FremontButton>
                    }
                >
                    Tags
                </Header>
            }
        >
            <div>
                <FormField stretch>
                    <FremontSelect
                        id="tagNameList"
                        placeholder="Search for a tag"
                        // We only display tags that are not currently connected to the order
                        options={props.tagItems
                            .filter(tag => !props.orderTagIdList.includes(tag.tagId))
                            .map(tag => ({ value: tag.tagId, label: tag.tagName }))
                        }
                        statusType={props.tagsLoading ? Constants.LOADING : Constants.FINISHED}
                        loadingText="Loading tags..."
                        disabled={props.isAnyEditModeEnabled || props.isTagSubmissionInProgress}
                        empty={Constants.EMPTY_MESSAGES.tag}
                        onChange={props.handleAddTagToOrder}
                        filteringType="auto"
                    />
                </FormField>
                <TokenGroup
                    items={props.orderTagIdList.map(tagId =>
                        ({
                            label: props.tagItems.find(tag => tag.tagId === tagId)
                                ? props.tagItems.find(tag => tag.tagId === tagId).tagName :
                                "Loading Tag",
                            disabled: props.isTagSubmissionInProgress
                                || !props.tagItems.find(tag => tag.tagId === tagId),
                            iconName: props.tagItems.find(tag => tag.tagId === tagId) ? "" :
                                "status-in-progress"
                        }))}
                    // at the onDismiss event fire (e), the order from the
                    // tagList which has matching index will be submitted
                    // for removal
                    onDismiss={props.handleRemoveTag}
                />
            </div>
        </Container>
        <Container
            header={
                <Header
                    variant="h2"
                    description={props.blockers.length > 0 && "Click on blocker to view/update"}
                    actions={
                        <FremontButton
                            id={OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker}
                            onClick={props.showModal}
                            // Going for disabled rather than a spinner because the container shows a spinner
                            // Also need to wait till all the circuits are loaded or else we'll not show the circuit
                            // options even though there are circuits
                            disabled={props.blockersLoading || props.circuitDesignsLoading ||
                            props.isOrderCompleted || props.isAnyEditModeEnabled || props.loading}
                        >
                            Add Blocker
                        </FremontButton>
                    }
                >
                    Blockers
                </Header>
            }
        >
            {renderBlockerContainer(props.blockersLoading, props.circuitDesignsLoading, props.blockers,
                props.showModal)}
        </Container>
    </SpaceBetween>
);

export default OrderSidebar;