import React from "react";
import {
    Modal
} from "@amzn/awsui-components-react/polaris";
import ProviderServiceCreatePage from "providerService/ProviderServiceCreatePage";

const ProviderServiceModal = props => (
    <Modal
        visible={props.isAddProviderServiceClicked}
        header="Add provider service"
        onDismiss={props.handleProviderServiceModalDismiss}
        size="large"
    >
        {props.isAddProviderServiceClicked &&
            <ProviderServiceCreatePage
                match={props.match}
                auth={props.auth}
                isModal
                handleProviderServiceModalSubmit={props.handleProviderServiceModalSubmit}
                handleProviderServiceModalDismiss={props.handleProviderServiceModalDismiss}
                providerOptions={props.providerOptions}
                providersLoading={props.providersLoading}
            />
        }
    </Modal>
);

export default ProviderServiceModal;