import React, { Component } from "react";
import AsnForm from "asn/AsnForm";
import AsnValidation from "asn/AsnValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import ProviderServiceModal from "providerService/ProviderServiceModal";
import {
    Flashbar
} from "@amzn/awsui-components-react/polaris";

/**
 * CreateAsnPage renders the page holding the asn creation Form
 */
export default class CreateAsnPage extends Component {
    /**
     *  Initially set the asnInfo and region objects to mimic what they would be if they were
     *  inputted as blank (since they are blank on page load). They will later be updated by user inputs.
     */
    state = {
        asn: {
            asnRegions: {}
        },
        chosenProviderServices: [],
        serviceOptions: [],
        servicesLoading: false,
        regionObjects: [
            {
                id: "region1",
                value: "",
                errorText: "",
                asnSessionType: {
                    id: "asnSessionType1",
                    value: "",
                    errorText: ""
                }
            }
        ],
        allFieldsDisabled: false,
        hasSubmittedOnce: false,
        hasModifiedProviderName: false,
        isSubmissionInProgress: false,
        flashbar: {
            type: "",
            text: ""
        },
        asnErrorTexts: AsnValidation.DEFAULT_ASN_ERROR_TEXTS,
        isAddProviderServiceClicked: false
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else {
            const providerServiceIdList = [];
            const chosenProviderServices = [];
            const asnErrorTexts = AsnValidation.DEFAULT_ASN_ERROR_TEXTS;
            asnErrorTexts.providerName = this.props.match ? "" : Constants.ERROR_STRINGS.blankInput;
            asnErrorTexts.providerServiceIdList = this.props.serviceType ? "" : Constants.ERROR_STRINGS.blankInput;
            if (this.props.match) {
                await this.fetchProviderServiceObjectAndDropdownValues(this.props.match.params.providerName);
            }

            // Here we automatically propogate the serviceType if one was passed in as a prop and the service exists
            // in our list of service options
            if (this.state.serviceOptions.map(serviceOption => serviceOption.label).includes(this.props.serviceType)) {
                const matchingServiceOption = this.state.serviceOptions.find(serviceOption =>
                    serviceOption.label === this.props.serviceType);
                providerServiceIdList.push(matchingServiceOption.value);
                chosenProviderServices.push(matchingServiceOption);
            }
            this.setState({
                asn: {
                    asnRegions: {},
                    providerName: this.props.match ? this.props.match.params.providerName : "",
                    providerServiceIdList
                },
                asnErrorTexts,
                chosenProviderServices
            });
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method obtains all the provider service objects associated with the passed in provider name.
     * It then creates a list of provider service objects, the services dropdown, and the service to ID map
     */
    fetchProviderServiceObjectAndDropdownValues = async (providerName) => {
        this.setState({ servicesLoading: true, serviceOptions: [] });
        try {
            // Get the provider object
            const providerResponse = await this.FremontBackendClient.getProviderInfo(providerName, this.props.auth);
            // Get the associated provider service objects
            const batchProviderServicesResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE, providerResponse.provider.providerServiceIdList,
                this.props.auth
            );

            // We need to filter out all the non-interconnect provider services (such as backbone)
            const serviceOptions = batchProviderServicesResponse.providerServices
                .filter(providerService => Constants.INTERCONNECT_SERVICE_TYPES.includes(providerService.serviceType))
                .map(providerService => ({
                    label: providerService.serviceType,
                    value: providerService.providerServiceId
                }));

            this.setState({
                // Creating a list of option object that will be passed in as the options to choose from
                serviceOptions,
                servicesLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { servicesLoading: false });
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     This function handles change of inputs to the asn information container fields.
     */
    handleAsnInputChange = async (evt) => {
        const oldProviderName = this.state.asn.providerName;
        const input = {};
        input.evt = evt;
        input.asn = HelperFunctions.deepClone(this.state.asn);
        input.asnErrorTexts = HelperFunctions.deepClone(this.state.asnErrorTexts);
        input.regionObjects = HelperFunctions.deepClone(this.state.regionObjects);
        input.chosenProviderServices = HelperFunctions.deepClone(this.state.chosenProviderServices);
        input.providerOptions = HelperFunctions.deepClone(this.props.providerOptions);
        input.hasModifiedProviderName = this.state.hasModifiedProviderName;

        const output = AsnValidation.validateInput(input);

        this.setState({
            asn: output.asn,
            asnErrorTexts: output.asnErrorTexts,
            regionObjects: output.regionObjects,
            chosenProviderServices: output.chosenProviderServices,
            hasModifiedProviderName: output.hasModifiedProviderName
        });

        // If the provider name changes, we reset the serviceOptions and the chosenProviderServices because they
        // are dependent upon a specific provider
        if (output.asn.providerName !== oldProviderName) {
            this.setState({ serviceOptions: [], chosenProviderServices: [] });
            // If the new provider name is a valid non-null value, fetch all service objects for that provider
            if (output.asn.providerName && !this.state.asnErrorTexts.providerName) {
                await this.fetchProviderServiceObjectAndDropdownValues(output.asn.providerName);
            }
        }
    };

    /**
     * Handles the addition for the regionObjects field
     */
    handleAdditionalRegionInput = (evt) => {
        const input = {};
        input.objectChanged = evt.target.id;
        input.regionObjects = this.state.regionObjects;

        const output = AsnValidation.handleAdditionalInput(input);

        this.setState({
            regionObjects: output.regionObjects
        });
    };

    /**
     * Handles the subtraction for the regionObjects field
     */
    handleSubtractRegionInput = (evt) => {
        const input = {};
        input.regionObjects = HelperFunctions.deepClone(this.state.regionObjects);

        const output = AsnValidation.handleSubtractRegionInput(evt, input);
        this.setState({
            regionObjects: output.regionObjects
        });
    };

    /**
     * Make create provider service modal appear
     */
    showProviderServiceModal = () => {
        this.setState({
            isAddProviderServiceClicked: true
        });
    };

    /**
     * Closing the provider service modal using the cancel or dismiss buttons
     */
    handleProviderServiceModalDismiss = () => {
        this.setState({
            isAddProviderServiceClicked: false
        });
    }

    /**
     * Closing the provider service modal and reloading the provider services
     */
    handleProviderServiceModalSubmit = (evt) => {
        this.handleProviderServiceModalDismiss(evt);
        this.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false);
        this.fetchProviderServiceObjectAndDropdownValues(this.state.asn.providerName);
    };

    /**
     This function determines whether or not the Add Additional Region button should be enabled or not
     */
    disableAddRegionButton = () => AsnValidation.disableAddRegionButton(this.state.regionObjects);

    /**
     * This function disables the create provider service button if all provider services are already created
     * for this provider
     */
    disableCreateProviderServiceButton = () =>
        this.state.serviceOptions.length === Object.values(Constants.SERVICE_TYPES).length ||
        !this.state.asn.providerName;

    /**
     This function maps a specified function to every element of the regionObjects array state
     */
    mapFunctionToRegionObjectsArray = mappedFunction => this.state.regionObjects.some(mappedFunction);

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     * Send asn info to backend when the submit button is clicked
     */
    handleAsnSubmit = async (evt) => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true, allFieldsDisabled: true });
        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.asnErrorTexts).some(errorText => errorText)
            || this.mapFunctionToRegionObjectsArray(region => region.errorText)
            || this.mapFunctionToRegionObjectsArray(region => region.asnSessionType.errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    isSubmissionInProgress: false,
                    allFieldsDisabled: false,
                    hasSubmittedOnce: true
                }
            );
            return;
        }
        const asn = AsnValidation.createDynamicLinks(HelperFunctions.deepClone(this.state.asn));

        // Clearing the asnRegions field so that the newly selected regions can be added to it
        Object.keys(asn.asnRegions).map(regionName => delete asn.asnRegions[regionName]);

        // Filters out any blank regions and then creates the appropriate map object to
        // pass to the back end
        this.state.regionObjects.filter(regionObject =>
            regionObject.value !== "" && regionObject.asnSessionType.value !== "").map(regionObject =>
            Object.assign(asn.asnRegions, { [regionObject.value]: regionObject.asnSessionType.value }));

        try {
            const response = await this.FremontBackendClient.createAsn(asn, this.props.auth);
            if (!this.props.isModal) {
                this.props.history.push(`${Constants.ROUTES.asn}/${response.asnId}`);
            } else {
                // This is used for showing the flashbar success message on the order create page
                this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                    false, false);
                this.props.handleAsnModalClick(evt);
            }
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isSubmissionInProgress: false,
                allFieldsDisabled: false,
                hasSubmittedOnce: true
            });
        }
    };

    render() {
        return (
            <div>
                {this.props.isModal ?
                    <Flashbar
                        items={this.state.flashbar.text ?
                            HelperFunctions.generateErrorMessageForFlashbar(this.state.flashbar.text) : []}
                    />
                    : <FremontHeader
                        history={this.props.history}
                        flashbarType={this.state.flashbar.type}
                        flashbarText={this.state.flashbar.text}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                }
                <AsnForm
                    hasSubmittedOnce={this.state.hasSubmittedOnce}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    allFieldsDisabled={
                        this.state.allFieldsDisabled || this.state.providersLoading || this.state.servicesLoading
                    }
                    asn={this.state.asn}
                    providerName={this.props.match ? this.props.match.params.providerName : ""}
                    handleAsnSubmit={this.handleAsnSubmit}
                    handleAsnInputChange={this.handleAsnInputChange}
                    asnErrorTexts={this.state.hasSubmittedOnce ?
                        this.state.asnErrorTexts : {}}
                    // We submit this as a separate field because we do provider validation on the autocomplete
                    // box immediately upon entry, not just the checkbox
                    providerErrorText={this.state.hasModifiedProviderName || this.state.hasSubmittedOnce
                        ? this.state.asnErrorTexts.providerName : ""}
                    servicesLoading={this.state.servicesLoading}
                    serviceOptions={this.state.serviceOptions}
                    providersLoading={this.props.providersLoading}
                    providerOptions={this.props.providerOptions}
                    chosenProviderServices={this.state.chosenProviderServices}
                    regionObjects={this.state.regionObjects}
                    disableCreateProviderServiceButton={this.disableCreateProviderServiceButton()}
                    addRegionButtonDisabled={this.disableAddRegionButton()}
                    handleAdditionalRegionInput={this.handleAdditionalRegionInput}
                    handleSubtractRegionInput={this.handleSubtractRegionInput}
                    showProviderServiceModal={this.showProviderServiceModal}
                    isModal={this.props.isModal}
                    handleAsnModalClick={this.props.handleAsnModalClick}
                    providerServiceItems={this.props.providerServiceItems}
                />
                <ProviderServiceModal
                    match={{ params: { providerName: this.state.asn.providerName } }}
                    auth={this.props.auth}
                    isAddProviderServiceClicked={this.state.isAddProviderServiceClicked}
                    handleProviderServiceModalSubmit={this.handleProviderServiceModalSubmit}
                    handleProviderServiceModalDismiss={this.handleProviderServiceModalDismiss}
                />
            </div>
        );
    }
}
