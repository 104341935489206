import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import SiteTable from "site/SiteTable";

/**
 SiteTab is used to display the list of sites for a given provider.
 */
class SiteTab extends Component {
    state = {
        loading: true,
        siteItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchSites();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching given site information based on list of siteIds
     */
    fetchSites = async () => {
        try {
            this.setState({ loading: true });
            const response = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.SITE, this.props.siteIdList, this.props.auth
            );

            // Set the necessary states to display the reformatted response in the table
            this.setState({
                siteItems: response.sites,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the parent page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <SiteTable
                items={this.state.siteItems}
                loading={this.state.loading}
                emptyTableMessage={this.props.emptyTableMessage}
                providerName={this.props.providerName}
            />
        );
    }
}

export default SiteTab;