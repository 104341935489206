import React from "react";
import { Link } from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";

export default class ProviderServiceTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "serviceType",
            header: "Service Type",
            description: "The service provided by this particular provider service",
            cell: item => <Link href={`${Constants.ROUTES.providerService}/${item.providerServiceId}`}>{item.serviceType}</Link>
        },
        {
            id: "providerName",
            header: "Provider Name",
            description: "Name of the provider this service is attached to",
            cell: item => <Link href={`${Constants.ROUTES.provider}/${item.providerName}`}>{item.providerName}</Link>
        },
        {
            id: "status",
            header: "Service Status",
            description: "Provider Service Status",
            cell: item => item.status
        },
        {
            id: "phone",
            header: "Phone Number",
            description: "Provider Service phone number",
            cell: item => item.phone
        },
        {
            id: "fax",
            header: "Fax Number",
            description: "Provider Service fax number",
            cell: item => item.fax
        },
        {
            id: "email",
            header: "Email Address",
            description: "Provider Service email address",
            cell: item => item.email
        },
        {
            id: "odinCredentials",
            header: "Odin Credentials",
            description: "Provider Service odin credentials",
            cell: item => item.odinCredentials
        },
        {
            id: "website",
            header: "Website",
            description: "Provider Service website",
            cell: item => item.website
        }
    ];

    static fetchCreateProviderServiceLink = providerName =>
        (providerName ? `${Constants.ROUTES.createProviderService}/${providerName}` : Constants.ROUTES.createProviderService);
}