import React, { Component } from "react";
import {
    Box,
    Flashbar,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton
} from "utils/CommonComponents";
import {
    toggleSelectAllBlockerCircuitsId,
    OrderBlockerModalDisplayMode,
    OrderBlockerModalEditMode
} from "order/OrderBlockerModalInformation";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";
import OrderValidation from "order/OrderValidation";

class OrderBlockerModalHandler extends Component {
    static generateEmptyBlocker = orderId => ({
        jepCode: "",
        orderId,
        status: Constants.STATUS.active,
        description: "",
        startDate: HelperFunctions.formatDateAndTime(new Date(), Constants.DATE_PICKER_FORMAT),
        completedDate: "",
        expirationDate: "",
        noteIdList: [],
        newNote: "",
        circuitDesignIdList: []
    });

    state = {
        selectedBlockerProviderContacts: [],
        responsibleContactLoading: false,
        responsibleContactName: "",
        selectedBlocker: OrderBlockerModalHandler.generateEmptyBlocker(this.props.order.orderId),
        updatedSelectedBlocker: OrderBlockerModalHandler.generateEmptyBlocker(this.props.order.orderId),
        selectedBlockerNotes: [],
        blockerErrorTexts: {},
        hasSubmittedBlockerOnce: false,
        isBlockerSubmissionInProgress: false,
        allBlockerFieldsDisabled: false,
        newNote: "",
        flashbar: {
            text: "",
            type: ""
        },
        isEditClicked: false,
        newBlocker: false
    };

    componentDidUpdate = async (prevProps) => {
        let selectedBlocker;
        let selectedBlockerNotes;
        if (this.props.isOrderBlockerModalClicked !== prevProps.isOrderBlockerModalClicked) {
            if (this.props.isOrderBlockerModalClicked) {
                this.setState({ newBlocker: !this.props.clickedBlockerId });
                if (this.props.clickedBlockerId) {
                    // Get our blocker from list of blockers
                    selectedBlocker = HelperFunctions.deepClone(this.props.blockers.find(
                        blocker => blocker.blockerId === this.props.clickedBlockerId
                    ));

                    // The DatePicker component throws an error if completedDate/expirationDate is undefined which is
                    // why we set it to a blank string if it does not exist. We delete the attribute during submission
                    // if it is a blank string so that dynamo accepts the object
                    if (!selectedBlocker.completedDate) {
                        selectedBlocker.completedDate = "";
                    }
                    if (!selectedBlocker.expirationDate) {
                        selectedBlocker.expirationDate = "";
                    }

                    this.setState({ selectedBlocker });

                    // Now get our blockers notes
                    const thisBlockerNoteList =
                        this.props.blockerNotes.filter(
                            blockerNote => selectedBlocker.noteIdList.includes(blockerNote.noteId)
                        );
                    selectedBlockerNotes = thisBlockerNoteList.map(
                        ({ noteBody, createdTime, createdBy }) => ({ noteBody, createdTime, createdBy })
                    );
                    selectedBlockerNotes = HelperFunctions.sortObjectsByField(selectedBlockerNotes,
                        "createdTime", true);
                    this.setState({ selectedBlockerNotes });

                    let responsibleContactName = "Error retrieving contact.";
                    try {
                        const contactResponse = await this.FremontBackendClient.getContactInfo(
                            selectedBlocker.responsibleContactId, this.props.auth
                        );
                        responsibleContactName = `${contactResponse.contact.firstName} ${contactResponse.contact.lastName}`;
                    } catch (error) {
                        HelperFunctions.displayFlashbarError(this, error);
                    }
                    this.setState({
                        responsibleContactName
                    });
                } else {
                    this.setState({
                        blockerErrorTexts: OrderValidation.DEFAULT_BLOCKER_ERROR_TEXTS
                    });
                    await this.handleEditClick();
                }
            } else {
                // Reset the state if closing the modal, makes sure when opening up the modal again it is a clean slate
                // Except providerOptions, keep those
                this.setState({
                    selectedBlockerProviderContacts: [],
                    responsibleContactLoading: false,
                    responsibleContactName: "",
                    selectedBlocker: OrderBlockerModalHandler.generateEmptyBlocker(this.props.order.orderId),
                    updatedSelectedBlocker: OrderBlockerModalHandler.generateEmptyBlocker(this.props.order.orderId),
                    selectedBlockerNotes: [],
                    blockerErrorTexts: {},
                    hasSubmittedBlockerOnce: false,
                    isBlockerSubmissionInProgress: false,
                    allBlockerFieldsDisabled: false,
                    newNote: "",
                    flashbar: {
                        text: "",
                        type: ""
                    },
                    isEditClicked: false
                });
            }
        }
    };

    FremontBackendClient = new FremontBackendClient();

    handleEditClick = async () => {
        const editClicked = this.state.isEditClicked;
        await this.setState({
            isEditClicked: !this.state.isEditClicked
        });
        if (!editClicked) {
            const { providerName } = this.props.order;
            const updatedSelectedBlocker = HelperFunctions.deepClone(this.state.selectedBlocker);
            updatedSelectedBlocker.providerName = providerName;
            this.setState({
                updatedSelectedBlocker
            });
            this.handleBlockerProviderContactSearch(providerName);
        }
    };

    handleBlockerProviderContactSearch = async (providerName) => {
        this.setState({
            responsibleContactLoading: true,
            selectedBlockerProviderContacts: []
        });
        try {
            // Get the provider object
            const providerResponse = await this.FremontBackendClient.getProviderInfo(providerName, this.props.auth);

            const batchProviderContactResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CONTACT, providerResponse.provider.contactIdList, this.props.auth
            );

            const contactItems = batchProviderContactResponse.contacts.map(contact => ({
                value: contact.contactId,
                label: `${contact.firstName} ${contact.lastName}`
            }));

            this.setState({
                selectedBlockerProviderContacts: contactItems
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {});
        }
        this.setState({
            responsibleContactLoading: false
        });
    };

    createNewNote = async (blockerId) => {
        if (this.state.newNote === "") {
            return;
        }
        try {
            // If there is an error it will display on flash bar of blocker modal
            // If the request is successful than a success message will display on the orderDetailPage from the
            // handleBlockerSubmit function and the load data function will update the blockers information
            await this.FremontBackendClient.createNote([{
                entityType: Constants.NOTE_ENTITY_TYPES.blocker,
                entityId: blockerId,
                noteBody: this.state.newNote
            }],
            this.props.auth);
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
    };

    /**
     * This method handles input changes to the blocker modal
     */
    handleBlockerModalInputChange = async (evt) => {
        let { responsibleContactName } = this.state;
        const oldProviderName = this.state.updatedSelectedBlocker.providerName;
        const updatedSelectedBlocker = HelperFunctions.deepClone(this.state.updatedSelectedBlocker);
        const blockerErrorTexts = HelperFunctions.deepClone(this.state.blockerErrorTexts);
        // design decision: currently only one note is allowed per submit of blocker
        // we do not want to issue a backend call to create a note
        // until the user hits the submit button
        // reason: blockers take "noteIds" not notes as input and noteId is generated by backend.
        if (evt.target.id === "addNote") {
            this.setState({
                newNote: evt.detail.value
            });
            return;
        }

        const attributeId = evt.target.id;
        let inputValue;
        // Handle select fields
        if ([Constants.ATTRIBUTES.status, Constants.ATTRIBUTES.jepCode, Constants.ATTRIBUTES.stageName,
            Constants.ATTRIBUTES.responsibleContactId].includes(attributeId)) {
            inputValue = evt.detail.selectedOption.value;
        }
        if ([Constants.ATTRIBUTES.startDate,
            Constants.ATTRIBUTES.completedDate,
            Constants.ATTRIBUTES.expirationDate,
            Constants.ATTRIBUTES.description].includes(attributeId)) {
            inputValue = evt.detail.value;
        }
        if (attributeId === Constants.ATTRIBUTES.providerName) {
            inputValue = evt.detail.selectedOption.label;
        }
        // If a value has been entered for the responsible contact, we handle updating the value we display here
        if (attributeId === Constants.ATTRIBUTES.circuitDesignIdList) {
            inputValue = evt.detail.selectedOptions.map(option => option.value);
        }
        // If a value has been entered for the responsible contact, we handle udpating the value we display here
        if (attributeId === Constants.ATTRIBUTES.responsibleContactId && !!inputValue) {
            responsibleContactName = evt.detail.selectedOption.label;
        } else if (attributeId === toggleSelectAllBlockerCircuitsId) {
            const allCircuitDesignIds = this.props.circuitDesignObjects
                .map(circuit => circuit.circuitDesignId);
            inputValue = evt.detail.checked ? allCircuitDesignIds : [];
            updatedSelectedBlocker[Constants.ATTRIBUTES.circuitDesignIdList] = inputValue;
        }

        if (attributeId !== toggleSelectAllBlockerCircuitsId) {
            updatedSelectedBlocker[attributeId] = inputValue || null;
        }

        // Here we update the error messages for the required fields
        if ([Constants.ATTRIBUTES.jepCode, Constants.ATTRIBUTES.status,
            Constants.ATTRIBUTES.startDate, Constants.ATTRIBUTES.responsibleContactId,
            Constants.ATTRIBUTES.stageName].includes(attributeId)) {
            blockerErrorTexts[attributeId] = !inputValue ? Constants.ERROR_STRINGS.blankInput : "";
        }

        // Here we update the error message for the circuitDesignIdList based on what jepCode was chosen
        if (attributeId === Constants.ATTRIBUTES.jepCode) {
            if (Constants.CIRCUIT_LEVEL_BLOCKER_JEP_CODES.includes(inputValue)) {
                blockerErrorTexts[Constants.ATTRIBUTES.circuitDesignIdList] =
                    updatedSelectedBlocker[Constants.ATTRIBUTES.circuitDesignIdList].length > 0
                        ? "" : Constants.ERROR_STRINGS.blankInput;
            }
            // If the user selects an order level jep code, there should
            // be no error text on the circuit selection field
            if (Constants.ORDER_LEVEL_BLOCKER_JEP_CODES.includes(inputValue)) {
                blockerErrorTexts[Constants.ATTRIBUTES.circuitDesignIdList] = "";
            }
        }

        // Here we update the error message for the circuitDesignIdList which has slightly different logic
        if (attributeId === Constants.ATTRIBUTES.circuitDesignIdList
            || attributeId === toggleSelectAllBlockerCircuitsId
        ) {
            blockerErrorTexts[Constants.ATTRIBUTES.circuitDesignIdList] =
                inputValue.length === 0 ?
                    Constants.ERROR_STRINGS.blankInput
                    : "";
        }

        // Conditional error message, depending on whether status is set to resolved
        if (Constants.STATUS.resolved === updatedSelectedBlocker.status && !updatedSelectedBlocker.completedDate) {
            blockerErrorTexts.completedDate = Constants.ERROR_STRINGS.blankInput;
        } else {
            blockerErrorTexts.completedDate = "";
        }

        this.setState({
            blockerErrorTexts,
            responsibleContactName,
            updatedSelectedBlocker
        });

        if (attributeId === Constants.ATTRIBUTES.providerName) {
            const newProviderName = updatedSelectedBlocker[attributeId];
            if (newProviderName && newProviderName !== oldProviderName) {
                // Reset the contact field (since we are changing the provider)
                this.setState({ responsibleContactName: "" });
                await this.handleBlockerProviderContactSearch(newProviderName);
            }
        }
    };

    /**
     * This function either creates a new blocker or updates an existing blocker
     */
    handleBlockerSubmit = async (evt) => {
        HelperFunctions.dismissFlashbar(this, {
            hasSubmittedBlockerOnce: true,
            isBlockerSubmissionInProgress: true,
            allBlockerFieldsDisabled: true
        });
        try {
            // If any error messages exist, we display a flashbar error and any error texts
            if (Object.values(this.state.blockerErrorTexts).some(errorText => errorText)) {
                HelperFunctions.displayFlashbarError(
                    this, new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                    { isBlockerSubmissionInProgress: false, allBlockerFieldsDisabled: false }
                );
                return;
            }

            const blockerToSubmit = HelperFunctions.deepClone(this.state.updatedSelectedBlocker);
            // In case the user was messing around with the JEP codes, they may accidentally add some
            // circuits to order level JEP codes
            if (Constants.CIRCUIT_LEVEL_BLOCKER_JEP_CODES.includes(blockerToSubmit.jepCode)) {
                const added = blockerToSubmit.circuitDesignIdList
                    .filter(items => this.state.selectedBlocker.circuitDesignIdList.indexOf(items) === -1);
                const removed = this.state.selectedBlocker.circuitDesignIdList
                    .filter(items => blockerToSubmit.circuitDesignIdList.indexOf(items) === -1);

                if (added.length > 0) {
                    blockerToSubmit.circuitDesignIdListToAddFromRequest = added;
                }
                if (removed.length > 0) {
                    blockerToSubmit.circuitDesignIdListToRemoveFromRequest = removed;
                }
            }

            const blockersUpdated = HelperFunctions.createNewApiObjects(
                [!blockerToSubmit.blockerId ? {} : this.state.selectedBlocker],
                [blockerToSubmit],
                !blockerToSubmit.blockerId ? Constants.ATTRIBUTES.blockerId : "",
                Constants.KEEP_KEYS.BLOCKER,
                Constants.IGNORE_KEYS.BLOCKER
            );

            // We only submit a blocker request if one or more blockers have been modified to avoid an
            // error being thrown from the backend
            if (blockersUpdated.length > 0) {
                const response = await this.FremontBackendClient.modifyBlocker(blockersUpdated, this.props.auth);

                // Here we obtain the blockerId of the returned blocker so that we can create a note successfully
                blockerToSubmit.blockerId = response.blockers.find(Boolean).blockerId;
            }

            // Create the note. This will update the blocker's noteId list automatically
            await this.createNewNote(blockerToSubmit.blockerId);

            // We need to refetch all the blockers since they could be updated if they had a new note attached.
            // But really, we need to reload the order object (which would require reloading everything) since we always
            // want to have the latest information and we don't want to manually modify part of the state (that could
            // cause data integrity issues).
            await this.props.loadData(true, true);

            // call the OrderDetailsPage handler to close the modal.
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText);
            this.props.hideModal(evt);
            this.setState({
                isEditClicked: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(
                this, error, { isBlockerSubmissionInProgress: false, allBlockerFieldsDisabled: false }
            );
        }
    };

    generateStageNameOptions = () => (
        Object.keys(this.props.order.stageStatusMap).filter(stageName => ![
            Constants.STAGE_NAMES.newOrderInformation,
            Constants.STAGE_NAMES.completeOrder
        ].includes(stageName)).map(stageName => ({
            value: stageName,
            label: Constants.BACKEND_TO_FRONTEND_STAGE_MAP[stageName]
        }))
    );

    render() {
        return (
            <Modal
                visible={this.props.isOrderBlockerModalClicked}
                header={this.state.newBlocker ? "New Blocker Form" : "Blocker"}
                size="large"
                footer={
                    <Box variant="span" float="right">
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={`${OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker}${Constants.SEPARATOR}Cancel`}
                                variant="normal"
                                disabled={this.state.isBlockerSubmissionInProgress}
                                onClick={!this.state.newBlocker && this.state.isEditClicked ? this.handleEditClick :
                                    this.props.hideModal}
                            >
                                Cancel
                            </FremontButton>
                            {this.state.isEditClicked ?
                                <FremontButton
                                    id={`${OrderValidation.ORDER_PAGE_MODAL_TYPES.blocker}${Constants.SEPARATOR}Submit`}
                                    variant="primary"
                                    disabled={this.state.allBlockerFieldsDisabled}
                                    loading={this.state.isBlockerSubmissionInProgress}
                                    onClick={this.handleBlockerSubmit}
                                >
                                    {this.state.isBlockerSubmissionInProgress ?
                                        Constants.SUBMISSION_STRINGS.submitInProgress :
                                        Constants.SUBMISSION_STRINGS.readyToSubmit}
                                </FremontButton>
                                :
                                <FremontButton
                                    iconName="edit"
                                    variant="primary"
                                    onClick={this.handleEditClick}
                                    disabled={this.state.allBlockerFieldsDisabled}
                                />
                            }
                        </SpaceBetween>
                    </Box>
                }
                onDismiss={this.props.hideModal}
            >
                <Flashbar
                    items={
                        this.state.flashbar.text
                            ? HelperFunctions.generateErrorMessageForFlashbar(this.state.flashbar.text) : []
                    }
                />
                {this.state.isEditClicked ?
                    <OrderBlockerModalEditMode
                        selectedBlockerProviderContacts={this.state.selectedBlockerProviderContacts}
                        responsibleContactLoading={this.state.responsibleContactLoading}
                        responsibleContactName={this.state.responsibleContactName}
                        updatedSelectedBlocker={this.state.updatedSelectedBlocker}
                        providersLoading={this.props.providersLoading}
                        blockerErrorTexts={this.state.hasSubmittedBlockerOnce
                            ? this.state.blockerErrorTexts : {}}
                        allBlockerFieldsDisabled={this.state.allBlockerFieldsDisabled
                        || HelperFunctions.isOrderCompleted(this.props.order.stageStatusMap)
                        || this.props.providersLoading}
                        newNote={this.state.newNote}
                        circuitDesignObjects={this.props.circuitDesignObjects}
                        providerOptions={this.props.providerOptions}
                        stageNameOptions={this.generateStageNameOptions()}
                        handleBlockerModalInputChange={this.handleBlockerModalInputChange}
                        selectedBlockerNotes={this.state.selectedBlockerNotes}
                    /> :
                    <OrderBlockerModalDisplayMode
                        selectedBlocker={this.state.selectedBlocker}
                        circuitDesignObjects={this.props.circuitDesignObjects}
                        responsibleContactName={this.state.responsibleContactName}
                        selectedBlockerNotes={this.state.selectedBlockerNotes}
                        goToComponentAction={this.props.goToComponentAction}
                        hideModal={this.props.hideModal}
                        order={this.props.order}
                    />
                }
            </Modal>
        );
    }
}

export default OrderBlockerModalHandler;