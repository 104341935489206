export default class DevEnvironment {
    static YOUR_USER_POOL_ID = "{YOUR_USER_POOL_ID}";

    static YOUR_CLIENT_ID = "{YOUR_CLIENT_ID}";

    static YOUR_ALIAS = "{YOUR_USER_ALIAS}";

    static APP_WEB_DOMAIN = `fremont-${DevEnvironment.YOUR_ALIAS}-test-userpool.auth.us-west-2.amazoncognito.com`;

    static UI_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.ui.fremont.networking.aws.a2z.com`;

    static OPERATIONS_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.operations.fremont.networking.aws.a2z.com`;
}
