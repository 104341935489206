import React, { Component } from "react";
import AddressValidation from "address/AddressValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import { SiteForm } from "site/SiteForm";
import SiteValidation from "site/SiteValidation";

/**
 * CreateSitePage renders the page holding the site creation Form
 */
export default class CreateSitePage extends Component {
    /**
     *  Initially set the siteInfo to mimic what it would be if it was
     *  inputted as blank (since they are blank on page load). They will later be updated by user inputs.
     */
    state = {
        site: {
            address: {},
            status: Constants.STATUS.active
        },
        allFieldsDisabled: false,
        hasSubmittedOnce: false,
        isSubmissionInProgress: false,
        flashbar: {
            type: "",
            text: ""
        },
        siteErrorTexts: SiteValidation.DEFAULT_SITE_ERROR_TEXTS
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     This function handles change of inputs to the site information container fields.
     */
    handleSiteInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        // This handles changes to any of the link inputs
        input.site = HelperFunctions.deepClone(this.state.site);
        input.siteErrorTexts = HelperFunctions.deepClone(this.state.siteErrorTexts);
        input.providerOptions = HelperFunctions.deepClone(this.props.providerOptions);

        const output = SiteValidation.validateInput(input);
        this.setState({
            site: output.site,
            siteErrorTexts: output.siteErrorTexts
        });
    };

    /**
     * This function handles changes of inputs in the address information
     */
    handleAddressInputChange = (evt) => {
        const site = Object.assign({}, this.state.site);
        const siteErrorTexts = Object.assign({}, this.state.siteErrorTexts);

        let input = {};
        input.evt = evt;
        input.address = site.address;
        input.errors = siteErrorTexts;
        input.isSite = true;

        input = AddressValidation.validateAddress(input);

        site.address = input.address;
        Object.keys(input.errors).forEach((key) => { siteErrorTexts[key] = input.errors[key]; });
        this.setState({
            site,
            siteErrorTexts
        });
    };

    /**
     * Send site info to backend when the submit button is clicked
     */
    handleSiteSubmit = async (evt) => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true, allFieldsDisabled: true });
        const site = Object.assign({}, this.state.site);
        const siteErrorTexts = Object.assign({}, this.state.siteErrorTexts);

        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(siteErrorTexts).some(errorText => errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    isSubmissionInProgress: false,
                    allFieldsDisabled: false,
                    hasSubmittedOnce: true
                }
            );
            return;
        }

        site.address = SiteValidation.cleanAddressObject(site.address);

        try {
            const response = await this.FremontBackendClient.modifySite([site], [], this.props.auth);
            if (!this.props.isModal) {
                const [returnedSite] = response.sites;
                this.props.history.push(`${Constants.ROUTES.site}/${returnedSite.siteId}`);
            } else {
                // This is used for showing the flashbar success message on the order create page
                this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                    false, false);
                this.props.handleSiteModalClick(evt);
            }
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isSubmissionInProgress: false,
                allFieldsDisabled: false,
                hasSubmittedOnce: true
            });
        }
    };

    render() {
        return (
            <div>
                {!this.props.isModal ?
                    <FremontHeader
                        history={this.props.history}
                        flashbarType={this.state.flashbar.type}
                        flashbarText={this.state.flashbar.text}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                    : ""
                }
                <SiteForm
                    handleAddressInputChange={this.handleAddressInputChange}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    allFieldsDisabled={this.state.allFieldsDisabled}
                    providerOptions={this.props.providerOptions}
                    providersLoading={this.props.providersLoading}
                    site={this.state.site}
                    handleSiteSubmit={this.handleSiteSubmit}
                    handleSiteInputChange={this.handleSiteInputChange}
                    siteErrorTexts={this.state.hasSubmittedOnce ?
                        this.state.siteErrorTexts : {}}
                    hasSubmittedOnce={this.state.hasSubmittedOnce}
                    isModal={this.props.isModal}
                    handleSiteModalClick={this.props.handleSiteModalClick}
                    providerName={this.props.match ? this.props.match.params.providerName : ""}
                />
            </div>
        );
    }
}