import React from "react";
import {
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const generateVerifyStopBillingStageColumnDefinitions = (numberOfItems, serviceType, customerFabric) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.verifyStopBilling], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.revisionNumber,
            header: "Version",
            description: "The version of the circuitDesign",
            width: Constants.COLUMN_WIDTHS.version,
            cell: item => `V${item[Constants.ATTRIBUTES.revisionNumber]}`
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "Bandwidth for this circuitDesign",
            width: Constants.COLUMN_WIDTHS.bandwidth,
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth]
        },
        {
            id: Constants.ATTRIBUTES.verifyStopBillingStatus,
            header: "Verify Stop Billing",
            description: "Has the carrier been notified",
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.verifyStopBillingStatus] :
                    <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.verifyStopBillingStatus}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={Constants.GENERAL_COMPLETION_OPTIONS}
                            selectedOption={item.verifyStopBillingStatus ?
                                HelperFunctions.createSelectedOption(item.verifyStopBillingStatus)
                                : HelperFunctions.createSelectedOption(Constants.INCOMPLETE)}
                            onChange={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: Constants.COMPONENT_NAMES.providerCircuitA,
            header: "Provider Circuit ID",
            description: "Provider circuit of the circuit",
            cell: item => item[Constants.COMPONENT_NAMES.providerCircuitA]
        }
    ];
    if (!customerFabric || Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.nodeA,
                header: "Router A",
                description: "Router A for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.nodeA]
            },
            {
                id: Constants.COMPONENT_NAMES.portA,
                header: "Interface A",
                description: "Interface A for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.portA]
            }
        );
    }
    if (Constants.SERVICE_TYPES.SFP === serviceType) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "Router A LAG",
                description: "Router A for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            }
        );
    }

    if (Constants.SERVICE_TYPES.BACKBONE === serviceType
        && Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.nodeZ,
                header: "Router Z",
                description: "Router Z for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.nodeZ]
            },
            {
                id: Constants.COMPONENT_NAMES.portZ,
                header: "Interface Z",
                description: "Interface Z for this circuitDesign",
                cell: item => item[Constants.COMPONENT_NAMES.portZ]
            }
        );
    }

    return columnDefinitions;
};

export default generateVerifyStopBillingStageColumnDefinitions;