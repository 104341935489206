import React, { Component } from "react";
import {
    Box,
    Container,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontAlert,
    ComponentConstants
} from "utils/CommonComponents";
import { withRouter } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
    CircuitDesignDetailsPageHeader,
    CircuitDesignDetailsPageTabs
} from "circuitDesign/CircuitDesignDetailsInformation";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import OrderValidation from "order/OrderValidation";
import OrderTableData from "order/OrderTableData";

class CircuitDesignDetailsPage extends Component {
    state = {
        isPageLoading: true,
        isComponentIdToObjectMapLoading: false,
        allCircuitDesignRevisions: {},
        circuitDesign: {
            orderId: "",
            consumingCircuitsIdList: []
        },
        componentIdToObjectMap: {},
        // CircuitDesign that consumes the circuitDesign whose detail page we are on
        consumedByCircuitDesign: {},
        // Change order CircuitDesign that consumes the circuitDesign whose detail page we are on
        consumedByCircuitDesignForNewRevision: {},
        // CircuitDesign objects that are consumed by the circuitDesign whose detail page we are on
        consumingCircuitDesigns: [],
        circuitDesignRevisionOptions: [],
        circuitDesignRevisionSelectedOption: {},
        flashbar: {
            type: "",
            text: ""
        },
        isDownloadingAttachment: false,
        allCircuitAttachments: [],
        currentRevisionAttachments: [],
        circuitAttachmentsLoading: false,
        loadingAllOrders: false,
        circuitDesignsCurrentOrder: {},
        activeTabId: Constants.CIRCUIT_DESIGN_TAB_IDS.DESIGN_TAB_ID
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(
                this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false }
            );
        } else {
            await this.fetchCircuitDesignInfo();
            await this.fetchOrder();
        }
    };

    componentWillUnmount = () => {
        document.title = "Lighthouse";
    }

    getAuditIdList = () => {
        if (this.state.circuitDesign.auditIdList && this.state.circuitDesign.positionMap) {
            const auditIdList = HelperFunctions.deepClone(this.state.circuitDesign.auditIdList);
            const componentIdSet = new Set();
            Object.values(this.state.circuitDesign.positionMap).forEach((component) => {
                if (component.uuid) {
                    if ([Constants.COMPONENT_TYPES.unit,
                        Constants.COMPONENT_TYPES.providerCircuit,
                        Constants.COMPONENT_TYPES.demarcAndCfa].includes(component.type)) {
                        componentIdSet.add(component.uuid);
                    }
                }
            });
            // We only attempt to push the components audits into the auditIdList if the component exist in our
            // componentIdToObjectMap
            Array.from(componentIdSet).map(componentId =>
                this.state.componentIdToObjectMap[componentId]
                && auditIdList.push(...(this.state.componentIdToObjectMap[componentId].auditIdList || [])));

            return auditIdList;
        }
        return [];
    };

    fetchAttachmentsForAllCircuits = async () => {
        let allCircuitAttachments = [];
        let circuitAttachmentIds = [];

        Object.values(this.state.allCircuitDesignRevisions).forEach((circuit) => {
            Object.values(circuit.attachmentIdMap).forEach(attachmentIdList =>
                circuitAttachmentIds.push(...attachmentIdList));
        });
        circuitAttachmentIds = Array.from(new Set(circuitAttachmentIds));

        if (circuitAttachmentIds.length > 0) {
            try {
                this.setState({ circuitAttachmentsLoading: true });

                const batchAttachmentObjectResponse = await this.FremontBackendClient.getBatch(
                    Constants.BATCH_ENTITIES.ATTACHMENT, circuitAttachmentIds, this.props.auth
                );
                allCircuitAttachments = batchAttachmentObjectResponse.attachments;
            } catch (error) {
                HelperFunctions.displayFlashbarError(this, error, { circuitAttachmentsLoading: false });
                return;
            }

            // Finally filter out attachments only for the current revision
            const currentRevisionAttachments = allCircuitAttachments.filter(attachment =>
                attachment.entityIdList.includes(this.state.circuitDesign.circuitDesignId));

            this.setState({
                allCircuitAttachments,
                currentRevisionAttachments,
                circuitAttachmentsLoading: false
            });
        }
    };

    downloadAttachment = async (attachmentId) => {
        this.setState({ isDownloadingAttachment: true });
        try {
            const getAttachmentResponse = await this.FremontBackendClient.getAttachmentInfo(attachmentId,
                this.props.auth);

            // I wanted to use XMLHttpRequest, since that is what we use to upload the files, but even the presence of
            // the Content-Disposition didn't help in downloading the file. So the workaround here is creating an <a>
            // element and "clicking" it to download in the browser. The <a> element is simply an anchor element that
            // contains and href element. We assign our presigned url as the href of this newly created element and
            // thats all it takes to download it.
            // More on it not working with XMLHttpRequest: https://stackoverflow.com/a/22738657
            fetch(getAttachmentResponse.presignedUrl).then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement("a");
                    anchorElement.href = url;
                    anchorElement.download = getAttachmentResponse.attachment.fileName;
                    anchorElement.click();
                    anchorElement.remove();

                    this.setState({ isDownloadingAttachment: false });
                });
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this,
                { message: "Unable to download the attachment." }, { isDownloadingAttachment: false });
        }
    };


    /**
     * This method is used for fetching all of the circuit's order information
     */
    fetchOrder = async () => {
        this.setState({ loadingAllOrders: true });

        let circuitDesignsCurrentOrder = {};
        try {
            const orderResponse = await this.FremontBackendClient.getOrderInfo(
                this.state.circuitDesign.orderId, this.props.auth
            );
            circuitDesignsCurrentOrder = orderResponse.order;
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loadingAllOrders: false });
        }

        this.setState({
            circuitDesignsCurrentOrder,
            loadingAllOrders: false
        });
    };

    /**
     * This method is used to fetch the objects related to an order that need to be displayed
     */
    fetchOrderRelatedObjects = async () => {
        this.setState({ loadingAllOrders: true });

        let { circuitDesignsCurrentOrder } = this.state;
        try {
            [circuitDesignsCurrentOrder] = await OrderTableData.fetchOrderRelatedObjects(
                [this.state.circuitDesignsCurrentOrder], this.props.auth, this.handleFlashBarMessagesFromChildTabs
            );
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loadingAllOrders: false });
        }

        this.setState({
            circuitDesignsCurrentOrder,
            loadingAllOrders: false
        });
    }

    FremontBackendClient = new FremontBackendClient();

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this);
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     * Gets the CircuitDesign object and related revisions
     */
    fetchCircuitDesignInfo = async () => {
        this.setState({ isPageLoading: true });
        let circuitDesign;
        let allRevisionsResponse;
        try {
            const response = await this.FremontBackendClient.getCircuitDesignInfo(
                this.props.match.params.circuitDesignId, this.props.auth
            );

            // Load all the previous and next revisions of this circuit design as well, but make the selected circuit
            // design the default value
            ({ circuitDesign } = response);

            // Here we set the title of the tab as soon as we've fetched the circuit
            document.title = HelperFunctions.deepClone(circuitDesign.circuitDesignNumber)
                .replace("CIRCUIT", "C");

            const allCircuitDesignRevisionIds = [circuitDesign.circuitDesignId,
                ...circuitDesign.previousCircuitDesignRevisionsIdList,
                ...circuitDesign.futureCircuitDesignRevisionsIdList];
            allRevisionsResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CIRCUIT_DESIGN, allCircuitDesignRevisionIds, this.props.auth
            );
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isPageLoading: false });
            return;
        }

        const allCircuitDesignRevisions = {};
        let activeRevisionNumber = "";
        let latestRevisionNumber = "-1";
        allRevisionsResponse.circuitDesigns.forEach((circuit) => {
            // Append the circuit's meta to the object. If there is a meta, create a link for later use
            allCircuitDesignRevisions[circuit.revisionNumber] =
                    HelperFunctions.appendMetaToItem(circuit, Constants.NCIS_ROUTES.circuit);

            // Determine the active revision
            if (Constants.LIFECYCLE_STAGES.active === circuit[Constants.ATTRIBUTES.lifeCycleStage]) {
                activeRevisionNumber = circuit[Constants.ATTRIBUTES.revisionNumber];
            }

            // Determine the latest revision
            if (parseInt(latestRevisionNumber, 10) < parseInt(circuit[Constants.ATTRIBUTES.revisionNumber], 10)) {
                latestRevisionNumber = circuit[Constants.ATTRIBUTES.revisionNumber];
            }
        });
        // Create the revision options as well
        const circuitDesignRevisionOptions = HelperFunctions.sortObjectsByField(
            Object.keys(allCircuitDesignRevisions)
                .map(revision => ({
                    value: revision,
                    label: `Version ${revision} (${allCircuitDesignRevisions[revision][Constants.ATTRIBUTES.lifeCycleStage]})`,
                    iconName: revision === activeRevisionNumber ? "status-positive" : "",
                    description: allCircuitDesignRevisions[revision][Constants.ATTRIBUTES.lifeCycleStage]
                })), "value", true
        );
        let circuitDesignRevisionSelectedOption =
                circuitDesignRevisionOptions.find(option => circuitDesign.revisionNumber === option.value);
        let revisionNumber = { ...circuitDesign.revisionNumber };
        if (activeRevisionNumber) {
            circuitDesignRevisionSelectedOption =
                circuitDesignRevisionOptions.find(option => option.value === activeRevisionNumber);
            revisionNumber = activeRevisionNumber;
        } else {
            circuitDesignRevisionSelectedOption =
                circuitDesignRevisionOptions.find(option => option.value === latestRevisionNumber);
            revisionNumber = latestRevisionNumber;
        }
        // Reselect the circuit design id because we attach the meta only after fetching all revisions
        circuitDesign = allCircuitDesignRevisions[revisionNumber];
        this.setState({
            circuitDesign,
            allCircuitDesignRevisions,
            circuitDesignRevisionOptions,
            circuitDesignRevisionSelectedOption
        });

        await this.generateComponentIdToObjectMap();

        // Set the necessary states to display the reformatted response in the dashboard table
        this.setState({
            isPageLoading: false
        });
    };

    /**
     * This method fetches every unique item stored in the position map of every circuitDesign. It then creates a
     * map that contains all of the unique IDs from the position maps and connects them to their object. This
     * should be called after every component submit
     */
    generateComponentIdToObjectMap = async () => {
        this.setState({
            isComponentIdToObjectMapLoading: true,
            componentIdToObjectMap: {}
        });

        // Here we pull all of the parent and child circuits related to the circuit whose detail page we are currently
        // on. We do this so that child circuit and parent circuit information can be properly displayed
        const additionalCircuitIdsToFetch = Array.from(new Set([
            ...HelperFunctions.getConsumedCircuitIdsFromPositionMap(
                this.state.circuitDesign[Constants.ATTRIBUTES.positionMap]
            ),
            ...this.state.circuitDesign[Constants.ATTRIBUTES.consumingCircuitsIdList],
            ...[this.state.circuitDesign[Constants.ATTRIBUTES.consumedByCircuitId]],
            ...[this.state.circuitDesign[Constants.ATTRIBUTES.consumedByCircuitIdForNewRevision]]
        ]));

        const input = {};
        input.circuitDesignIds = HelperFunctions.deepClone([
            ...[this.state.circuitDesign.circuitDesignId],
            ...additionalCircuitIdsToFetch.filter(circuitDesignId => !!circuitDesignId)
        ]);
        input.fremontBackendClient = this.FremontBackendClient;
        input.auth = this.props.auth;

        const componentIdToObjectMap = await OrderValidation.getAllAssociatedComponentObjects(input,
            this.handleFlashBarMessagesFromChildTabs);

        let consumedByCircuitDesign = Object.values(componentIdToObjectMap).filter(object =>
            HelperFunctions.isObjectCircuit(object) &&
            object[Constants.ATTRIBUTES.circuitDesignId] ===
            this.state.circuitDesign[Constants.ATTRIBUTES.consumedByCircuitId]);
        // If the consumedByCircuitDesign was found during the filter function above, it will be stored as the
        // only element in an array. Below we take the object out of the array by obtaining the first element using
        // find(Boolean)
        if (consumedByCircuitDesign.length > 0) {
            consumedByCircuitDesign = consumedByCircuitDesign.find(Boolean);
        } else {
            consumedByCircuitDesign = {};
        }

        let consumedByCircuitDesignForNewRevision = Object.values(componentIdToObjectMap).filter(object =>
            HelperFunctions.isObjectCircuit(object) &&
            object[Constants.ATTRIBUTES.circuitDesignId] ===
            this.state.circuitDesign[Constants.ATTRIBUTES.consumedByCircuitIdForNewRevision]);
        // If the consumedByCircuitDesignForNewRevision was found during the filter function above, it will be stored
        // as the only element in an array. Below we take the object out of the array by obtaining the first element
        // using find(Boolean)
        if (consumedByCircuitDesignForNewRevision.length > 0) {
            consumedByCircuitDesignForNewRevision = consumedByCircuitDesignForNewRevision.find(Boolean);
        } else {
            consumedByCircuitDesignForNewRevision = {};
        }

        // Here we define the consumingCircuitDesigns as all the circuits
        // we fetched that are in the consumingCircuitsIdList. We then filter those circuits to the most recent non
        // cancelled versions of the circuits
        const consumingCircuitDesigns = HelperFunctions.returnMostRecentNonCancelledVersionOfCircuits(
            Object.values(componentIdToObjectMap).filter(object =>
                HelperFunctions.isObjectCircuit(object) &&
                this.state.circuitDesign[Constants.ATTRIBUTES.consumingCircuitsIdList]
                    .includes(object[Constants.ATTRIBUTES.circuitDesignId]))
        );

        this.setState({
            consumingCircuitDesigns,
            consumedByCircuitDesign,
            consumedByCircuitDesignForNewRevision,
            componentIdToObjectMap,
            isComponentIdToObjectMapLoading: false
        });
    };

    /**
     * Keeps track of the active tab (so state changes don't take us back to the Details tab). Also clears our errors
     * when changing tabs (so if we saw an error downloading an attachment in the Attachments tab, when we switch to
     * the notes tab we don't continue seeing that error).
     */
    handleTabChange = async (evt) => {
        HelperFunctions.dismissFlashbar(this, { activeTabId: evt.detail.activeTabId });
        // If we are navigating to the attachment tab, we load all of the attachments for the circuits
        if (evt.detail.activeTabId === Constants.CIRCUIT_DESIGN_TAB_IDS.ATTACHMENTS_TAB_ID) {
            await this.fetchAttachmentsForAllCircuits();
        }
        // If we are navigating to the order tab, we load the related objects for the order. We do not need
        // to fetch the order itself because we already fetch the order page loads or when we change which
        // circuit revision we are viewing
        if (evt.detail.activeTabId === Constants.CIRCUIT_DESIGN_TAB_IDS.ORDERS_TAB_ID) {
            await this.fetchOrderRelatedObjects();
        }
    };

    handleRevisionChange = async (evt) => {
        const selectedCircuitDesignRevisionId = evt.detail.selectedOption.value;

        // Here we set the circuitDesignsCurrentOrder to an empty map as soon as the revision changes. We
        // do this so the value of the old order "Active Order Stage(s)" changes immediately
        this.setState({
            circuitDesignsCurrentOrder: {},
            circuitDesignRevisionSelectedOption: evt.detail.selectedOption
        });

        // We also need to choose which orders and attachments to display
        const circuitDesign = this.state.allCircuitDesignRevisions[selectedCircuitDesignRevisionId];
        const currentRevisionAttachments = this.state.allCircuitAttachments.filter(attachment =>
            attachment.entityIdList.includes(circuitDesign.circuitDesignId));
        await this.setState({ circuitDesign, currentRevisionAttachments });
        await this.generateComponentIdToObjectMap();
        await this.fetchOrder();
    };

    render() {
        return this.state.isPageLoading ?
            <FremontHeaderWithSpinner
                history={this.props.history}
                flashbarText={this.state.flashbar.text}
                flashbarType={this.state.flashbar.type}
                isPageLoading={this.state.isSpinnerShown}
                onDismiss={this.handleFlashbarClose}
                auth={this.props.auth}
                sideNavError={this.props.sideNavError}
                updateSearchResults={this.props.updateSearchResults}
            />
            :
            <div>
                <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_STAGES}>
                    <FremontHeader
                        history={this.props.history}
                        flashbarText={this.state.flashbar.text}
                        flashbarType={this.state.flashbar.type}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />

                    <Box
                        padding={{
                            right: ComponentConstants.BOX_PAGE_PADDING,
                            left: ComponentConstants.BOX_PAGE_PADDING
                        }}
                    >
                        {HelperFunctions.hasNCISMeta(this.state.circuitDesign.meta) &&
                        <Container>
                            <FremontAlert header="NCIS Vendor Circuit" type="info">
                                This vendor circuit has been imported from NCIS. Please note that changes
                                made here will not be reflected in NCIS.
                                <br/>
                                {"The original NCIS vendor circuit can be found "}
                                <a target="_blank" href={this.state.circuitDesign.ncisUrl}>
                                    here
                                </a>.
                            </FremontAlert>
                        </Container>
                        }

                        <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_STAGES}>
                            <CircuitDesignDetailsPageHeader
                                circuitDesign={this.state.circuitDesign}
                                circuitDesignRevisionOptions={this.state.circuitDesignRevisionOptions}
                                circuitDesignRevisionSelectedOption={this.state.circuitDesignRevisionSelectedOption}
                                circuitDesignsCurrentOrder={this.state.circuitDesignsCurrentOrder}
                                consumingCircuitDesigns={this.state.consumingCircuitDesigns}
                                consumedByCircuitDesign={this.state.consumedByCircuitDesign}
                                consumedByCircuitDesignForNewRevision={this.state.consumedByCircuitDesignForNewRevision}
                                handleRevisionChange={this.handleRevisionChange}
                                componentIdToObjectMap={this.state.componentIdToObjectMap}
                            />

                            {this.state.isComponentIdToObjectMapLoading ?
                                <ClipLoader
                                    color={Constants.SPINNER_ORANGE_COLOR}
                                    loading
                                    size={Constants.SPINNER_SIZES.loadingPage}
                                />
                                :
                                <CircuitDesignDetailsPageTabs
                                    auth={this.props.auth}
                                    user={this.props.user}
                                    circuitDesign={this.state.circuitDesign}
                                    componentIdToObjectMap={this.state.componentIdToObjectMap}
                                    showRevisions={false}
                                    circuitDesignRevisionOptions={this.state.circuitDesignRevisionOptions}
                                    fetchAllOrderItems={this.fetchOrder}
                                    circuitDesignsCurrentOrder={this.state.circuitDesignsCurrentOrder}
                                    consumingCircuitDesigns={this.state.consumingCircuitDesigns}
                                    consumedByCircuitDesign={this.state.consumedByCircuitDesign}
                                    consumedByCircuitDesignForNewRevision={
                                        this.state.consumedByCircuitDesignForNewRevision
                                    }
                                    handleRevisionChange={this.handleRevisionChange}
                                    // editMode is always false from CircuitDesignDetail page
                                    editMode={false}
                                    isDownloadingAttachment={this.state.isDownloadingAttachment}
                                    downloadAttachment={this.downloadAttachment}
                                    circuitAttachments={this.state.currentRevisionAttachments}
                                    circuitAttachmentsLoading={this.state.circuitAttachmentsLoading}
                                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                                    orderCompleted={this.state.circuitDesign.stageStatusMap
                                    && this.state.circuitDesign.stageStatusMap.completeOrder === "completed"}
                                    loadingAllOrders={this.state.loadingAllOrders}
                                    activeTabId={this.state.activeTabId}
                                    handleTabChange={this.handleTabChange}
                                    auditIdList={this.getAuditIdList()}
                                />}
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </div>;
    }
}

export default withRouter(CircuitDesignDetailsPage);