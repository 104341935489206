import React from "react";
import CircuitDesignCustomize from "circuitDesign/CircuitDesignCustomize";
import CircuitDesignTable from "circuitDesign/CircuitDesignTable";
import CircuitDesignTableData from "circuitDesign/CircuitDesignTableData";
import {
    SegmentedControl
} from "@amzn/awsui-components-react/polaris";

/**
 * This component serves as the main container holding all of the circuit design information
 */
const CircuitDesignTabInformation = props => (
    <div>
        <SegmentedControl
            options={props.generateSegmentOptions}
            onChange={props.onChange}
            selectedId={props.selectedId}
            ariaLabel="Circuit Designs"
        />
        {/* We only render the circuitDesignCustomize component once the loading of the componentIdToObjectMap has been
        attempted. This controls for use cases where the componentIdToObjectMap may be empty but we still want to
        display the circuitDesignCustomize component in that case */}
        {props.selectedId === "circuitTable" &&
            <CircuitDesignTable
                items={props.circuitDesignObjects}
                loading={props.circuitDesignObjects.length === 0}
                emptyTableMessage="No circuits currently exist for this order."
                columnDefinitions={CircuitDesignTableData.ORDER_PAGE_COLUMN_DEFINITIONS(
                    props.order.serviceType, props.order.customerFabric, props.order.lacpProvider
                )}
            />
        }
        {props.selectedId !== "circuitTable" &&
            <CircuitDesignCustomize
                auth={props.auth}
                circuitDesign={props.circuitDesignObjects.find(circuit =>
                    circuit.circuitDesignId === props.selectedId)}
                order={props.order}
                editMode={props.editMode}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
                isCircuitBlocked={props.isCircuitBlocked}
                componentIdToObjectMap={props.componentIdToObjectMap}
                showCircuitLink
                loadData={props.loadData}
                allProviders={props.allProviders}
                allProvidersLoading={props.allProvidersLoading}
            />
        }
    </div>
);

export default CircuitDesignTabInformation;