import React from "react";
import {
    Box,
    FormField,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontTextarea
} from "utils/CommonComponents";

/**
 * This is the modal that pops up to enter the note data request and then submit the request.  The modal is hidden by
 * default while the "visible" prop is false. When the add note button is clicked, the state of "isAddNoteClicked" is
 * flipped through the handleNoteModalClick method which sets the visible prop to true and renders the modal. Hitting
 * the cancel button or clicking outside of the modal calls the handleNoteModalClick method again to flip the
 * "isAddNoteClicked" property back to false and hide the modal once again. Hitting "Confirm" calls the method to
 * submit the request.
 */
const NoteModal = props => (
    <Modal
        visible={props.isAddNoteClicked}
        header={`Add note for ${props.type} "${props.name}"`}
        onDismiss={props.handleNoteModalClick}
        size="large"
        footer={
            <Box variant="span" float="right">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        disabled={props.isAddNoteInProgress}
                        variant="normal"
                        onClick={props.handleNoteModalClick}
                    >
                        Cancel
                    </FremontButton>
                    <FremontButton
                        id="submitAddNoteButton"
                        loading={props.isAddNoteInProgress}
                        variant="primary"
                        onClick={props.handleAddNoteSubmit}
                    >
                        {
                            props.isAddNoteInProgress ? "Submitting..." : "Submit"
                        }
                    </FremontButton>
                </SpaceBetween>
            </Box>
        }
    >
        <FormField
            label="Notes:"
            errorText={props.errorText}
        >
            <FremontTextarea
                id="notes"
                disabled={props.isAddNoteInProgress}
                value={props.noteBody}
                onInput={props.handleNoteInputChange}
            />
        </FormField>
    </Modal>
);

export default NoteModal;
