import React from "react";
import {
    FormField,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontInput
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const generateDemarcAndCFAStageColumnDefinitions = (customerFabric) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.demarcAndCfaStage], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "Circuit bandwidth for this circuit",
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth] || item[Constants.ATTRIBUTES.portBandwidth]
        },
        {
            id: Constants.COMPONENT_NAMES.providerCircuitA,
            header: `Provider Circuit ID`,
            description: `Provider Circuit ID of the circuit`,
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => item[Constants.COMPONENT_NAMES.providerCircuitA]
        }
    ];

    if (Constants.SPAN_CUSTOMER_FABRICS.includes(customerFabric)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.crossConnectA,
                header: "Cross Connect A",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (!item.editable ? item[Constants.COMPONENT_NAMES.crossConnectA] :
                    (
                        (
                            <FormField
                                errorText={item.errorTexts[Constants.COMPONENT_NAMES.crossConnectA]}
                            >
                                <FremontInput
                                    id={`${Constants.COMPONENT_NAMES.crossConnectA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                                    value={item[Constants.COMPONENT_NAMES.crossConnectA]}
                                    onInput={item.handleStageInputChange}
                                    disabled={item.allFieldsDisabled}
                                />
                            </FormField>
                        )
                    ))
            },
            {
                id: Constants.COMPONENT_NAMES.crossConnectZ,
                header: "Cross Connect Z",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (!item.editable ? item[Constants.COMPONENT_NAMES.crossConnectZ] :
                    (
                        (
                            <FormField
                                errorText={item.errorTexts[Constants.COMPONENT_NAMES.crossConnectZ]}
                            >
                                <FremontInput
                                    id={`${Constants.COMPONENT_NAMES.crossConnectZ}${Constants.SEPARATOR}${item.circuitDesignId}`}
                                    value={item[Constants.COMPONENT_NAMES.crossConnectZ]}
                                    onInput={item.handleStageInputChange}
                                    disabled={item.allFieldsDisabled}
                                />
                            </FormField>
                        )
                    ))
            }
        );
    }

    columnDefinitions.push(
        {
            id: Constants.COMPONENT_NAMES.demarcAndCfaA,
            header: `CFA A`,
            description: `CFA A of the circuit`,
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.COMPONENT_NAMES.demarcAndCfaA] :
                    <FremontInput
                        id={`${Constants.COMPONENT_NAMES.demarcAndCfaA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        value={item[Constants.COMPONENT_NAMES.demarcAndCfaA]}
                        onInput={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                    />
            )
        },
        {
            id: Constants.COMPONENT_NAMES.demarcAndCfaZ,
            header: `CFA Z`,
            description: `CFA Z of the circuit`,
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.COMPONENT_NAMES.demarcAndCfaZ] :
                    <FremontInput
                        id={`${Constants.COMPONENT_NAMES.demarcAndCfaZ}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        value={item[Constants.COMPONENT_NAMES.demarcAndCfaZ]}
                        onInput={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                    />
            )
        },
        {
            id: "loaA",
            header: "LOA A",
            description: "Link the LOA A file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.loaAttachmentIdA] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.loaAttachmentIdA])}
                />)
        },
        {
            id: "loaZ",
            header: "LOA Z",
            description: "Link the LOA Z file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.loaAttachmentIdZ] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.loaAttachmentIdZ])}
                />)
        },
        {
            id: "completionNotice",
            header: "Completion Notice",
            description: "Link the completion notice file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.completionNoticeId] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.completionNoticeId])}
                />)
        }
    );

    // We need to show additional attachments for Backbone Span customer fabric
    if (customerFabric === Constants.CUSTOMER_FABRICS.BACKBONE_SPAN) {
        columnDefinitions.push(
            {
                id: "BERT",
                header: "BERT Testing",
                description: "Link the BERT Testing file for this circuit",
                cell: item => (item[Constants.ATTRIBUTES.BERTAttachmentId] &&
                    <FremontButton
                        variant="icon"
                        iconName="file"
                        disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                        onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.BERTAttachmentId])}
                    />)
            },
            {
                id: "RFC",
                header: "RFC Testing",
                description: "Link the RFC Testing file for this circuit",
                cell: item => (item[Constants.ATTRIBUTES.RFCAttachmentId] &&
                    <FremontButton
                        variant="icon"
                        iconName="file"
                        disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                        onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.RFCAttachmentId])}
                    />)
            }
        );
    }

    // Finally add in the optional KMZ attachments
    columnDefinitions.push(
        {
            id: "kmz",
            header: "KMZ",
            description: "Link the KMZ file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.kmzAttachmentId] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.kmzAttachmentId])}
                />)
        }
    );

    return columnDefinitions;
};

export default generateDemarcAndCFAStageColumnDefinitions;