import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    StageEditMode
} from "order/OrderCommonComponents";
import { CarrierBuildDisplayMode, CarrierBuildEditMode } from "order/stages/install/CarrierBuildInformation";

class CarrierBuildHandler extends Component {
    state = {
        isEditClicked: false,
        allFieldsDisabled: false,
        updatedOrder: HelperFunctions.deepClone(this.props.order),
        isUpdateStageInProgress: false
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * Handle edit click to ip testing stage
     */
    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            updatedOrder: HelperFunctions.deepClone(this.props.order),
            isEditClicked: !this.state.isEditClicked,
            allFieldsDisabled: false,
            isUpdateStageInProgress: false
        });
    }

    /**
     * Handle any edits to the carrier build stage
     * @param evt
     */
    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.updatedOrder);
        input.orderErrorTexts = {};
        input.stageName = Constants.STAGE_NAMES.carrierBuild;
        const output = OrderValidation.validateInput(input);

        this.setState({
            updatedOrder: output.order
        });
    }

    /**
     * Handle any edits to the carrier build stage
     */
    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });

        try {
            // Here we submit a request to update the order
            await this.FremontBackendClient.updateOrderInfo(
                this.state.updatedOrder, this.props.order, this.props.auth
            );

            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);

            // Here we display a successful flashbar message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            // Here we call a helper function which updates all data related to the order
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: true,
                allFieldsDisabled: false
            });
        }
        this.props.handleStageInEditOrSubmitMode(this.state.isEditClicked);
    }

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.carrierBuild}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.props.circuitDesignObjects.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.props.circuitDesignObjects}
                    content={
                        <CarrierBuildDisplayMode
                            updatedOrder={this.state.order}
                            stageName={Constants.STAGE_NAMES.carrierBuild}
                            order={this.props.order}
                        />
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.carrierBuild}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <CarrierBuildEditMode
                            order={this.state.updatedOrder}
                            isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                            handleStageInputChange={this.handleStageInputChange}
                            allFieldsDisabled={this.state.allFieldsDisabled}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
        );
    }
}

export default CarrierBuildHandler;