import React from "react";
import {
    FormField,
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const generateTroubleshootingStageColumnDefinitions = (lacpProvider, numberOfItems, serviceType) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.troubleshooting], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.subStatusMap,
            header: "Sub-Status",
            description: "Stage sub-status for this circuit",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.troubleshooting] :
                    <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.subStatusMap}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={HelperFunctions.createSelectedOptions(
                                Constants.SUB_STATUS_OPTIONS.troubleshooting
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.troubleshooting]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled ||
                            !HelperFunctions.isStageInProgress(item.stageStatusMap.troubleshooting)}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: Constants.ATTRIBUTES.lightLevelTestingStatus,
            header: "Light Level Testing Status",
            description: "Light level testing status of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.lightLevelTestingStatus] :
                    <div className={HelperFunctions.assignShortTableClass(numberOfItems)}>
                        <FremontSelect
                            id={`lightLevelTestingStatus${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={Constants.TROUBLESHOOTING_STATUS_OPTIONS}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.lightLevelTestingStatus]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                            expandToViewport
                        />
                    </div>
            )
        },
        {
            id: Constants.ATTRIBUTES.receiveLightLevelA,
            header: "Receive Light Level A",
            description: "Receive light level of the A side of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.receiveLightLevelA] :
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.receiveLightLevelA]}
                    >
                        <FremontInput
                            id={`receiveLightLevelA${Constants.SEPARATOR}${item.circuitDesignId}`}
                            value={item[Constants.ATTRIBUTES.receiveLightLevelA]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
            )
        },
        {
            id: Constants.ATTRIBUTES.transmitLightLevelA,
            header: "Transmit Light Level A",
            description: "Transmit light level of the A side of the circuit",
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.transmitLightLevelA] :
                    <FormField
                        errorText={item.errorTexts[Constants.ATTRIBUTES.transmitLightLevelA]}
                    >
                        <FremontInput
                            id={`transmitLightLevelA${Constants.SEPARATOR}${item.circuitDesignId}`}
                            value={item[Constants.ATTRIBUTES.transmitLightLevelA]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                    </FormField>
            )
        }
    ];

    if (serviceType === Constants.SERVICE_TYPES.BACKBONE) {
        columnDefinitions.push(
            {
                id: Constants.ATTRIBUTES.receiveLightLevelZ,
                header: "Receive Light Level Z",
                description: "Receive light level of the z side of the circuit",
                cell: item => (
                    !item.editable ?
                        item[Constants.ATTRIBUTES.receiveLightLevelZ] :
                        <FormField
                            errorText={item.errorTexts[Constants.ATTRIBUTES.receiveLightLevelZ]}
                        >
                            <FremontInput
                                id={`receiveLightLevelZ${Constants.SEPARATOR}${item.circuitDesignId}`}
                                value={item[Constants.ATTRIBUTES.receiveLightLevelZ]}
                                onInput={item.handleStageInputChange}
                                disabled={item.allFieldsDisabled}
                            />
                        </FormField>
                )
            },
            {
                id: Constants.ATTRIBUTES.transmitLightLevelZ,
                header: "Transmit Light Level Z",
                description: "Transmit light level of the z side of the circuit",
                cell: item => (
                    !item.editable ?
                        item[Constants.ATTRIBUTES.transmitLightLevelZ] :
                        <FormField
                            errorText={item.errorTexts[Constants.ATTRIBUTES.transmitLightLevelZ]}
                        >
                            <FremontInput
                                id={`transmitLightLevelZ${Constants.SEPARATOR}${item.circuitDesignId}`}
                                value={item[Constants.ATTRIBUTES.transmitLightLevelZ]}
                                onInput={item.handleStageInputChange}
                                disabled={item.allFieldsDisabled}
                            />
                        </FormField>
                )
            }
        );
    }

    columnDefinitions.push(
        {
            id: Constants.COMPONENT_NAMES.nodeA,
            header: "Router A",
            description: "Router A of the circuit",
            cell: item => item[Constants.COMPONENT_NAMES.nodeA]
        },
        {
            id: Constants.COMPONENT_NAMES.portA,
            header: "Interface A",
            description: "Interface A of the circuit",
            cell: item => item[Constants.COMPONENT_NAMES.portA]
        }
    );

    if (serviceType === Constants.SERVICE_TYPES.BACKBONE) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.nodeZ,
                header: "Router Z",
                description: "Router Z of the circuit",
                cell: item => item[Constants.COMPONENT_NAMES.nodeZ]
            },
            {
                id: Constants.COMPONENT_NAMES.portZ,
                header: "Interface Z",
                description: "Interface Z of the circuit",
                cell: item => item[Constants.COMPONENT_NAMES.portZ]
            }
        );
    }

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)
        && !!HelperFunctions.parseBoolean(lacpProvider)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "Router A LAG",
                description: "Router A LAG of the circuit",
                cell: item => item[Constants.COMPONENT_NAMES.lagA]
            }
        );
    }
    return columnDefinitions;
};

export default generateTroubleshootingStageColumnDefinitions;