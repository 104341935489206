import React, { useState } from "react";
import { Link, SpaceBetween, Spinner, StatusIndicator } from "@amzn/awsui-components-react/polaris";
import LinkServiceModal from "../components/LinkServiceModal";
import LinkDescriptionListItem from "./LinkDescriptionListItem";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";
import HelperFunctions from "../common/HelperFunctions";

export default function DeleteLinksModal({
    items,
    setIsVisible,
    consumersForAllLinks,
    updateLinkHierarchy,
    handleFlashbarMessageFromChildTabs,
    handleFlashbarMessageFromSearchPage,
    updateTableItems,
    isSearchPage,
    setSelectedItems
}) {
    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

    const linkServiceBackendClient = new LinkServiceBackendClient();

    const getFailedLinkListItem = id => <li key={id}><Link target="_blank" href={`https://${window.location.host}/linkDetails/${id}`}>{id}</Link></li>;

    const getConsumersListItems = (consumers) => {
        const listItems = consumers.Links.map(lnk => <LinkDescriptionListItem key={lnk.instanceId} link={lnk}/>);
        const moreListItems = !consumers.FailedLinks ? [] :
            consumers.FailedLinks.map(id => getFailedLinkListItem(HelperFunctions.getUuidFromFullLinkId(id)));
        return listItems.concat(moreListItems);
    };

    const getConfirmationText = () => {
        const question = <h2>Are you sure you want to delete {items.length === 1 ? "this record" : `these ${items.length} records`}?</h2>;
        const itemBullets = items.map((item, itemIndex) => {
            const consumers = consumersForAllLinks[itemIndex];
            const consumersListItems = getConsumersListItems(consumers);

            return (
                <li key={item.instanceId}>
                    <h3>{<LinkDescriptionListItem link={item}/>}</h3>
                    {consumersListItems.length ? (
                        <div>
                            <StatusIndicator type="warning">
                                <b>
                                    This link is consumed by {consumersListItems.length} other link(s).
                                    If you click delete, the below links will no longer consume this link.<br/>
                                    This may break all the related link hierarchies.
                                </b>
                                <p>Ensure that you would still like to delete this link.</p>
                            </StatusIndicator>
                            <ul>{consumersListItems}</ul>
                        </div>
                    ) :
                        null}
                </li>
            );
        });

        return (
            <SpaceBetween size="xl">
                <div>
                    {question}
                    <ul>{itemBullets}</ul>
                </div>
                <div>
                    {isDeleteInProgress && <h2>This may take a while. Please do not leave the page.</h2>}
                </div>
            </SpaceBetween>
        );
    };

    const closeModal = () => {
        setIsDeleteInProgress(false);
        setIsVisible(false);
    };

    const deleteLinkRecords = async () => {
        setIsDeleteInProgress(true);
        try {
            // Only Router To Router Link object will have aEndDwdmLinkId and bEndDwdmLinkId.
            const routerToDwdmLinkIds = items.map(item => [item.aEndDwdmLinkId, item.bEndDwdmLinkId])
                .flat().filter(Boolean);
            // When Deleting a Router To Router we delete the associated Router To DWDM links associated with it.
            if (routerToDwdmLinkIds.length > 0) {
                const routerToDwdmLinkObjects = routerToDwdmLinkIds.map(instanceId => ({ instanceId }));
                routerToDwdmLinkObjects.forEach(object => items.push(object));
            }
            const response = await linkServiceBackendClient.batchDeleteLinks(items);

            if (isSearchPage) {
                if (response.FailedLinks.length > 0) {
                    const failedLinks = response.FailedLinks.join(", ");
                    handleFlashbarMessageFromSearchPage(null, new Error(`Deletion failed for item IDs: ${failedLinks}`));
                } else {
                    handleFlashbarMessageFromSearchPage(`${items.length} link(s) deleted successfully.`);
                }
                await updateTableItems();
                setSelectedItems([]);
            } else {
                if (response.FailedLinks.length > 0) {
                    const failedLinks = response.FailedLinks.join(", ");
                    handleFlashbarMessageFromChildTabs(null, new Error(`Deletion failed for item IDs: ${failedLinks}`));
                } else {
                    handleFlashbarMessageFromChildTabs(`${items.length} link(s) deleted successfully.`);
                }
                await updateLinkHierarchy(items);
            }
        } catch (e) {
            if (isSearchPage) {
                handleFlashbarMessageFromSearchPage(null, e);
            } else {
                handleFlashbarMessageFromChildTabs(null, e);
            }
        }
        closeModal();
    };

    return (
        <LinkServiceModal
            isModalVisible
            onDismiss={() => setIsVisible(false)}
            header="Delete Links"
            onCancelButtonClick={() => setIsVisible(false)}
            onSubmitButtonClick={deleteLinkRecords}
            isAttachmentSubmissionInProgress={isDeleteInProgress}
            confirmButtonText="Delete"
            closeButtonText="Cancel"
        >
            {consumersForAllLinks.length === items.length ? getConfirmationText() : <Spinner/>}
        </LinkServiceModal>
    );
}
