import React from "react";
import {
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontExpandableSection
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class NodeTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "deviceName",
            sortingField: "deviceName",
            header: "Device Name",
            cell: item => item.deviceName
        },
        {
            id: "siteName",
            sortingField: "siteName",
            header: "Site Name",
            cell: item => (
                item.site && <Link href={`${Constants.ROUTES.site}/${item.site.siteId}`}>{`${item.site.siteName} `}</Link>
            )
        },
        {
            id: "circuitDesignNumber",
            header: "Circuit ID(s)",
            cell: (item) => {
                if (!item.circuitDesignList) {
                    return "";
                }
                // Here we generate an array that contains the ID and display values used to generate the links for the
                // consumingCircuitDesign objects
                const idAndDisplayValueArray = item.circuitDesignList.map(additionalCircuitDesign => ({
                    [Constants.ID_AND_DISPLAY_VALUE.ID]:
                        additionalCircuitDesign[Constants.ATTRIBUTES.circuitDesignId],
                    [Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]:
                        additionalCircuitDesign[Constants.ATTRIBUTES.circuitDesignNumber]
                }));
                return (
                    <FremontExpandableSection
                        header={`${item.circuitDesignList.length} Circuits, click to see`}
                    >{HelperFunctions.renderLinksFromIdAndDisplayValues(
                            Constants.ROUTES.circuitDesign, idAndDisplayValueArray, true
                        )}
                    </FremontExpandableSection>);
            }
        }
    ];
}