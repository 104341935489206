import VisualizationHelperFunctions from "./VisualizationHelperFunctions";

export default class LinkHierarchyNode {
    constructor(name, linkType, consumes, consumers) {
        this.id = name;
        this.linkType = linkType;
        this.aEndPort = "";
        this.bEndPort = "";
        this.consumes = consumes;
        this.consumers = consumers;
        this.children = [];
        this.parents = [];
    }

    setPorts(aEndPort, bEndPort) {
        this.aEndPort = aEndPort;
        this.bEndPort = bEndPort;
    }

    addChild(child) {
        this.children.push(child);
    }

    addParent(parent) {
        this.parents.push(parent);
    }

    getParents() {
        return this.parents;
    }

    sortChildren() {
        // First group children by linkType
        const groups = this.children.reduce((group, child) => {
            const key = child.linkType;
            if (!group[key]) {
                // eslint-disable-next-line no-param-reassign
                group[key] = [];
            }
            group[key].push(child);
            return group;
        }, {});

        // sort by link type first
        const linkTypes = Object.keys(groups).sort();

        // sort the children in each link type group
        const sortedChildren = [];
        for (let i = 0; i < linkTypes.length; i += 1) {
            const linkType = linkTypes[i];
            const group = groups[linkType];
            let remainingChildren = [...group];
            const sortedGroup = [];

            // Start with child whose aEndPort matches the parent's aEndPort
            let currentChild = remainingChildren.find(child =>
                VisualizationHelperFunctions.getDCFromPort(child.aEndPort) ===
                VisualizationHelperFunctions.getDCFromPort(this.aEndPort));

            while (currentChild) {
                sortedGroup.push(currentChild);

                // eslint-disable-next-line no-loop-func
                remainingChildren = remainingChildren.filter(child => child !== currentChild);

                // Find next child whose aEndPort matches the current child's bEndPort
                // eslint-disable-next-line no-loop-func
                currentChild = remainingChildren.find(child =>
                    VisualizationHelperFunctions.getDCFromPort(child.aEndPort) ===
                    VisualizationHelperFunctions.getDCFromPort(currentChild.bEndPort));
            }

            // Sort remaining children alphabetically and add them to the end
            remainingChildren.sort((a, b) => a.id.localeCompare(b.id));
            sortedGroup.push(...remainingChildren);

            // Add sortedGroup to sortedChildren
            sortedChildren.push(...sortedGroup);
        }

        this.children = sortedChildren;

        this.children.forEach(child => child.sortChildren());
    }
}
