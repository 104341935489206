import React from "react";
import {
    ColumnLayout,
    Container,
    FormField
} from "@amzn/awsui-components-react/polaris";
import {
    FileUploadComponent,
    FremontButton,
    FremontInput,
    FremontMultiselect,
    FremontSelect,
    FremontToggle,
    requiredLabel
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import PolarisUtils from "utils/PolarisUtils";

const AttachmentForm = (props) => {
    const circuitAttachments = !!props.circuitDesignOptions;
    const bulkUpdateAttachments = !!props.handleBulkUpdateTableType;

    // We will only show the circuits that are already part of the attachment or those that don't have any attachments
    let optionsToShow = [];
    if (circuitAttachments && props.attachment.attachmentType) {
        // Define options to exclude as circuits already with an attachment of the current attachment's type
        const optionsToExclude = props.circuitDesignsAttachmentTypeMap[props.attachment.attachmentType]
            ? props.circuitDesignsAttachmentTypeMap[props.attachment.attachmentType] : [];
        optionsToShow = props.circuitDesignOptions.filter(
            option => props.attachment.entityIdList.includes(option[PolarisUtils.OPTION_VALUE_KEY])
                || !optionsToExclude.includes(option[PolarisUtils.OPTION_VALUE_KEY])
        );
    }

    // In the event all the attachments for all the types are present, we don't want to show an empty message (since
    // attachment types with all circuits are filtered). So we add the attachmentType for an attachment as an option
    const attachmentOptions = HelperFunctions.deepClone(props.attachmentOptions);
    if (props.attachment.attachmentType && !props.attachmentOptions.includes(props.attachment.attachmentType)) {
        attachmentOptions.push(props.attachment.attachmentType);
    }
    // Here we sort the attachment options alphanumerically in ascending order order
    attachmentOptions.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));

    return (
        <Container
            footer={
                <FremontButton
                    id={`removeAttachment${props.attachment.attachmentFormKey}`}
                    onClick={props.removeAttachment}
                    disabled={props.isAttachmentSubmissionInProgress}
                >
                    Remove
                </FremontButton>
            }
        >
            <ColumnLayout columns={circuitAttachments || bulkUpdateAttachments ? 3 : 2} borders="vertical">
                <FormField
                    label={requiredLabel("Attachment Type:")}
                    errorText={props.hasSubmittedAttachmentOnce ? props.attachment.errorTexts.attachmentType : ""}
                >
                    <FremontSelect
                        id={`type${props.attachment.attachmentFormKey}`}
                        disabled={props.isAttachmentSubmissionInProgress || !!props.attachment.attachmentId}
                        options={HelperFunctions.createSelectedOptionsForAttachments(attachmentOptions)}
                        empty="Unable to load attachment options."
                        onChange={props.handleAttachmentModalInputChange}
                        selectedOption={HelperFunctions.createSelectedOptionForAttachment(
                            props.attachment.attachmentType
                        )}
                    />
                </FormField>
                { circuitAttachments &&
                <div>
                    <FormField
                        label={requiredLabel("All Circuits:")}
                    >
                        <FremontToggle
                            id={`toggle${props.attachment.attachmentFormKey}`}
                            onChange={props.handleAttachmentModalInputChange}
                            disabled={props.isAttachmentSubmissionInProgress
                            || !props.attachment.attachmentType ||
                            optionsToShow.length < props.circuitDesignOptions.length}
                            // Checked will be marked as true if all circuits get selected
                            checked={
                                props.attachment.selectedOptions.length === props.circuitDesignOptions.length
                            }
                        />
                    </FormField>
                    {props.attachment.selectedOptions.length < props.circuitDesignOptions.length &&
                    <FormField
                        label={requiredLabel("Circuits:")}
                        errorText={props.hasSubmittedAttachmentOnce
                            ? props.attachment.errorTexts.entityIdList : ""}
                    >
                        <FremontMultiselect
                            id={`entityIdList${props.attachment.attachmentFormKey}`}
                            options={optionsToShow}
                            placeholder="Choose circuits"
                            onChange={props.handleAttachmentModalInputChange}
                            selectedOptions={props.attachment.selectedOptions}
                            disabled={props.isAttachmentSubmissionInProgress ||
                            !props.attachment.attachmentType}
                            empty="No circuits to choose from."
                            checkboxes
                            tokenLimit={5}
                        />
                    </FormField>
                    }
                </div>
                }
                { bulkUpdateAttachments &&
                <div>
                    <FormField
                        label={requiredLabel("Bulk Update Table:")}
                    >
                        <FremontSelect
                            id="bulkUpdateTableType"
                            disabled={props.isAttachmentSubmissionInProgress || !!props.attachment.attachmentId}
                            options={HelperFunctions.createSelectedOptions(
                                Object.values(Constants.BULK_UPDATE_TABLES)
                            )}
                            empty="Unable to load attachment options."
                            onChange={props.handleBulkUpdateTableType}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.bulkUpdateTableType
                            )}
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Description:")}
                    >
                        <FremontInput
                            id="bulkUpdateDescription"
                            placeholder="At least enter the SIM"
                            onInput={props.handleBulkUpdateDescription}
                            disabled={props.isAttachmentSubmissionInProgress || !!props.attachment.attachmentId}
                            value={props.getBulkUpdateDescription()}
                        />
                    </FormField>
                </div>
                }
                <FormField
                    label={Constants.NO_BREAK_SPACE_UNICODE}
                    errorText={props.hasSubmittedAttachmentOnce ? props.attachment.errorTexts.file : ""}
                >
                    { /* We want to style the file input with Polaris, so we're creating a hidden input element */}
                    <input
                        multiple={props.isAttachmentForOrderType}
                        type="file"
                        style={{ display: "none" }}
                        ref={props.fileInputRef}
                        onChange={props.handleFileInput}
                    />
                    <FileUploadComponent
                        onClick={e => props.triggerFileInput(e, props.fileInputRef)}
                        fileName={props.attachment.fileName}
                        // We disable the upload attachment button until the attachment type is selected
                        isAttachmentSubmissionInProgress={
                            props.isAttachmentSubmissionInProgress || !props.attachment.attachmentType
                        }
                        // Used to recognize which attachment in our list of attachments is being modified
                        buttonId={`button${props.attachment.attachmentFormKey}`}
                    />
                </FormField>
            </ColumnLayout>
        </Container>
    );
};

export default AttachmentForm;