import React, { Component } from "react";
import { Box, Grid, Tabs } from "@amzn/awsui-components-react/polaris";
import AuditTab from "audit/AuditTab";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import HelperFunctions from "common/HelperFunctions";
import ContactInformation from "contact/ContactInformation";
import ContactValidation from "contact/ContactValidation";
import ProviderServiceTab from "providerService/ProviderServiceTab";
import NoteTab from "note/NoteTab";
import { withRouter } from "react-router-dom";

/**
 * ContactDetailsPage acts as the contact info landing page and displays all of the contact
 * information and its related info.
 */
class ContactDetailsPage extends Component {
    static CONTACT_INFO_TAB_ID = "details";
    static CONTACT_SERVICE_TAB_ID = "contactServices";
    static CONTACT_SITE_TAB_ID = "contactSites";
    static CONTACT_NOTE_TAB_ID = "contactNotes";
    static CONTACT_AUDIT_TAB_ID = "contactAudits";

    state = {
        activeTabId: "details",
        flashbar: {
            type: "",
            text: ""
        },
        isPageLoading: true,
        isSpinnerShown: true,
        contact: {
            providerNameList: [""],
            siteMap: {}
        },
        providerServiceItems: [],
        updatedContact: {
            providerNameList: [""],
            siteMap: {}
        },
        isUpdateContactInfoEditClicked: false,
        contactErrorTexts: ContactValidation.EMPTY_CONTACT_ERROR_TEXTS,
        providerInfoLoading: false,
        // providersLoading: true,
        providersDisabled: false,
        // providerOptions: [],
        serviceOptions: {},
        hasSubmittedOnce: false,
        isSubmissionInProgress: false,
        showContactAlertModal: false
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(
                this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false }
            );
        } else {
            await this.fetchContactInfo();
            document.title = `${this.state.contact.firstName} ${this.state.contact.lastName}`;
        }
    };

    componentWillUnmount = () => {
        document.title = "Lighthouse";
    }

    getSiteItems = () => Object.keys(this.state.contact[Constants.ATTRIBUTES.siteMap]).map(providerName => ({
        providerName,
        [Constants.ATTRIBUTES.regionsSupported]: this.state.contact.siteMap[providerName][
            Constants.ATTRIBUTES.regionsSupported],
        [Constants.ATTRIBUTES.countriesSupported]: this.state.contact.siteMap[providerName][
            Constants.ATTRIBUTES.countriesSupported]
    }));

    /**
     **************************************************************************************************
     * DATA LOADING
     **************************************************************************************************
     */

    FremontBackendClient = new FremontBackendClient();

    fetchServiceItems = async (contact) => {
        // get service items for table
        const providerServicesResponse = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.PROVIDER_SERVICE, contact.providerServiceIdList, this.props.auth
        );
        this.setState({
            providerServiceItems: providerServicesResponse.providerServices
        });
    };

    /**
     * This method is used for fetching all of the contact information
     */
    fetchContactInfo = async () => {
        try {
            this.setState({ isPageLoading: true });
            const response = await this.FremontBackendClient.getContactInfo(this.props.match.params.contactId,
                this.props.auth);
            await this.fetchServiceItems(response.contact);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                contact: response.contact,
                isPageLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { isPageLoading: false });
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     * Changes the contact info container to edit mode, edit the request object
     */
    handleUpdateContactEdit = async () => {
        this.handleFlashbarClose();
        const { isUpdateContactInfoEditClicked } = this.state;
        this.changeEditState();
        if (!isUpdateContactInfoEditClicked) {
            await this.loadProviders(this.state.contact.providerNameList);
        }
    };

    /**
     * This function alters state in a manner that moves the detail pages to and from edit mode
     */
    changeEditState = () => {
        this.setState({
            isUpdateContactInfoEditClicked: !this.state.isUpdateContactInfoEditClicked,
            hasSubmittedOnce: false,
            isSubmissionInProgress: false,
            contactErrorTexts: ContactValidation.EMPTY_CONTACT_ERROR_TEXTS,
            updatedContact: HelperFunctions.deepClone(this.state.contact),
            isPageLoading: false
        });
    };

    /**
     * This method obtains the providerServices for all the given providers
     * Creates an object to hold the data and the necessary select options arrays
     * Differs from create page because it takes in a list
     */
    loadProviders = async (providerNames) => {
        const providers = [];
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        try {
            providerNames.forEach((providerName) => {
                // Get the provider object
                providers.push(this.FremontBackendClient.getProviderInfo(providerName, this.props.auth));
            });
            const newServiceOptions = [];
            // Get all provider responses
            (await Promise.all(providers)).forEach(response =>
                newServiceOptions.push(ContactValidation.setupServiceOptions(
                    response.provider, this.props.auth, this.FremontBackendClient
                )));
            // Get all service options and assign them
            (await Promise.all(newServiceOptions)).forEach((serviceOption) => {
                // if contact had this service id, add it as selected
                Object.assign(serviceOption, {
                    selectedOptions: serviceOption.options.filter(option =>
                        this.state.contact.providerServiceIdList.includes(option.value))
                });
                Object.assign(serviceOptions, {
                    [serviceOption.value]: serviceOption
                });
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loading: false });
        }
        this.setState({
            serviceOptions
        });
    };

    /**
    This function handles change of inputs to the contact information container fields.
    */
    handleContactInputChange = async (evt) => {
        const output = ContactValidation.contactInputChange(
            evt,
            this.state.updatedContact,
            this.state.contactErrorTexts
        );
        this.setState({
            updatedContact: output.contact,
            contactErrorTexts: output.contactErrorTexts
        });
    };

    /**
     * This function handles changing a provider name
     *
     * FIXME: This is identical to the method in ContactCreatePage, but since each page component has its
     * own separate state, we cannot combine the two methods. Just a note incase we have a common place
     * to hold state for the Contact Create/Detail pages in the future
     */
    handleContactProviderChange = async (evt) => {
        // The assumption here is that we are only able to change the last field, since subsequently filled
        // provider boxes are disabled.
        const updatedContact = HelperFunctions.deepClone(this.state.updatedContact);
        const contactErrorTexts = HelperFunctions.deepClone(this.state.contactErrorTexts);

        const newProviderName = evt.detail.selectedOption.value;

        if (!newProviderName) {
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = Constants.ERROR_STRINGS.blankProviderErrorText;
        } else {
            // If we have a non-blank valid provider, load the data
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = "";
            this.setState({
                providerInfoLoading: true
            });
            await this.loadProviders([newProviderName]);
            this.setState({
                providerInfoLoading: false
            });
        }

        updatedContact[Constants.ATTRIBUTES.providerNameList][
            updatedContact[Constants.ATTRIBUTES.providerNameList].length - 1] = newProviderName;


        updatedContact[Constants.ATTRIBUTES.providerNameList].filter(providerName =>
            providerName).forEach((providerName) => {
            if (!(providerName in updatedContact[Constants.ATTRIBUTES.siteMap])) {
                updatedContact[Constants.ATTRIBUTES.siteMap][providerName] = {
                    [Constants.ATTRIBUTES.regionsSupported]: [],
                    [Constants.ATTRIBUTES.countriesSupported]: []
                };
            }
        });

        // if provider in siteMap but not in providerNameList, remove it from siteMap
        Object.keys(updatedContact[Constants.ATTRIBUTES.siteMap]).forEach((providerName) => {
            if (!updatedContact[Constants.ATTRIBUTES.providerNameList].some(contactListProviderName =>
                providerName === contactListProviderName)) {
                delete updatedContact[Constants.ATTRIBUTES.siteMap][providerName];
            }
        });
        // if provider in serviceOptions but not in providerNameList, remove it from serviceOptions
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        Object.keys(serviceOptions).forEach((providerName) => {
            if (!updatedContact[Constants.ATTRIBUTES.providerNameList].some(contactListProviderName =>
                providerName === contactListProviderName)) {
                delete serviceOptions[providerName];
            }
        });

        this.setState({
            updatedContact,
            contactErrorTexts,
            serviceOptions
        });
    };

    /**
     * Handles toggling whether the updatedContact is internal or external
     * @param evt
     */
    handleChangeInternal = (evt) => {
        // Only show modal if there are multiple provider names entered
        // and the user is changing internal to false
        if (this.state.updatedContact.providerNameList.length > 1
            && evt.detail.selectedId === false) {
            this.setState({
                showContactAlertModal: true
            });
        } else {
            const updatedContact = HelperFunctions.deepClone(this.state.updatedContact);
            updatedContact.internal = evt.detail.selectedId;
            this.setState({
                updatedContact
            });
        }
    };

    /**
     * Handles users clicking okay on the modal
     * @param evt
     */
    handleContactAlertOkay = () => {
        const updatedContact = HelperFunctions.deepClone(this.state.updatedContact);
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        updatedContact.internal = false;
        while (updatedContact.providerNameList.length > 1) {
            // pop the last providerName off providerNames and delete it from serviceOptions
            const providerName = updatedContact.providerNameList.pop();
            delete serviceOptions[providerName];
            delete updatedContact.siteMap[providerName];
        }
        this.setState({
            updatedContact,
            serviceOptions,
            showContactAlertModal: false
        });
    };

    /**
     * Handles users clicking cancel on the modal
     * @param evt
     */
    handleContactAlertCancel = () => {
        this.setState({
            showContactAlertModal: false
        });
    };

    /**
     * Handles changes to ProviderService selections since that is part of serviceOptions
     * and I don't want validation to change serviceOptions
     */
    handleServiceOptionsChange = (evt) => {
        const serviceOptions = ContactValidation.serviceOptionsChange(evt,
            HelperFunctions.deepClone(this.state.serviceOptions));
        this.setState({
            serviceOptions
        });
    };

    /**
     Handles adding new input fields for the providerNames field
     */
    handleAddAdditionalProviderInput = () => {
        const updatedContact = HelperFunctions.deepClone(this.state.updatedContact);
        const contactErrorTexts = HelperFunctions.deepClone(this.state.contactErrorTexts);
        updatedContact.providerNameList.push("");
        contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = Constants.ERROR_STRINGS.blankProviderErrorText;
        this.setState({
            updatedContact,
            contactErrorTexts
        });
    };

    /**
     * Handles removing a certain provider from the provider list
     * @param evt
     */
    handleSubtractSpecificProviderName = (evt) => {
        const updatedContact = HelperFunctions.deepClone(this.state.updatedContact);
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        const contactErrorTexts = HelperFunctions.deepClone(this.state.contactErrorTexts);
        const providerIndex = updatedContact.providerNameList.findIndex(providerName =>
            providerName === evt.target.className);
        delete serviceOptions[updatedContact.providerNameList[providerIndex]];
        delete updatedContact.siteMap[updatedContact.providerNameList[providerIndex]];
        updatedContact.providerNameList.splice(providerIndex, 1);
        if (updatedContact.providerNameList.some(providerName => !providerName)) {
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = Constants.ERROR_STRINGS.blankProviderErrorText;
        } else {
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = "";
        }
        this.setState({
            updatedContact,
            serviceOptions,
            contactErrorTexts
        });
    };

    /**
     This function determines whether or not the Add Additional Provider button should be enabled or not
     */
    disableAddProviderButton = () =>
        // if last provider name is empty
        !this.state.updatedContact.providerNameList[this.state.updatedContact.providerNameList.length - 1]
        || this.state.providerInfoLoading
        // there are as many providers as provider options
        || this.state.updatedContact.providerNameList.length === this.props.providerOptions.length;

    /**
     * Send contact info to backend when the submit button is clicked
     */
    handleContactSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true });

        // Check to see if any of the errors are present are invalid. If so abort the submission and display error text
        if (Object.values(this.state.contactErrorTexts).some(errorText => !!errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    hasSubmittedOnce: true,
                    isSubmissionInProgress: false
                }
            );
            return;
        }

        const contactToSubmit = ContactValidation.createContactRequest(
            this.state.updatedContact,
            this.state.serviceOptions
        );

        try {
            const response = await this.FremontBackendClient.updateContactInfo(contactToSubmit, this.props.auth);
            // Resets all input fields to original state if request is successful
            HelperFunctions.displayFlashbarSuccess(this, Constants.FLASHBAR_STRINGS.flashbarSuccessText, {
                isUpdateContactInfoEditClicked: false,
                isSubmissionInProgress: false,
                hasSubmittedOnce: false,
                contact: response,
                isPageLoading: false
            });
            this.fetchServiceItems(response);
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                hasSubmittedOnce: true,
                isSubmissionInProgress: false
            });
        }
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     **************************************************************************************************
     * TAB CHANGE HANDLERS
     **************************************************************************************************
     */

    /**
     This function handles changing the active tab on the Tabs component, and resets the flashbar.
     */
    handleTabChange = (evt) => {
        HelperFunctions.dismissFlashbar(this, { activeTabId: evt.detail.activeTabId });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false });
        }
    };

    /**
     This function updates the tabs of the provider details page
     */
    updateTabs = () => (
        [
            {
                label: "Details",
                id: ContactDetailsPage.CONTACT_INFO_TAB_ID,
                content: <ContactInformation
                    contact={this.state.contact}
                    updatedContact={this.state.updatedContact}
                    handleUpdateContactEdit={this.handleUpdateContactEdit}
                    isUpdateContactInfoEditClicked={this.state.isUpdateContactInfoEditClicked}
                    disableAddProviderButton={this.disableAddProviderButton()}
                    siteItems={this.getSiteItems()}
                    providerServiceItems={this.state.providerServiceItems}
                    hasSubmittedOnce={this.state.hasSubmittedOnce}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    handleContactSubmit={this.handleContactSubmit}
                    handleContactInputChange={this.handleContactInputChange}
                    handleContactProviderChange={this.handleContactProviderChange}
                    handleAddressInputChange={this.handleAddressInputChange}
                    contactErrorTexts={this.state.contactErrorTexts}
                    handleAddAdditionalProviderInput={this.handleAddAdditionalProviderInput}
                    handleSubtractSpecificProviderName={this.handleSubtractSpecificProviderName}
                    serviceOptions={this.state.serviceOptions}
                    handleServiceOptionsChange={this.handleServiceOptionsChange}
                    providerInfoLoading={this.state.providerInfoLoading}
                    providersLoading={this.props.providersLoading}
                    providersDisabled={this.state.providersDisabled}
                    providerOptions={this.props.providerOptions}
                    handleChangeInternal={this.handleChangeInternal}
                    showContactAlertModal={this.state.showContactAlertModal}
                    handleContactAlertOkay={this.handleContactAlertOkay}
                    handleContactAlertCancel={this.handleContactAlertCancel}
                    auth={this.props.auth}
                />
            },
            {
                label: "Contact Services",
                id: ContactDetailsPage.CONTACT_SERVICE_TAB_ID,
                content: <ProviderServiceTab
                    providerServiceIdList={this.state.contact.providerServiceIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage={
                        <Box variant="h2">No services currently exist for this contact.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any services for this contact.</Box>
                    }
                />
            },
            {
                label: "Contact Notes",
                id: ContactDetailsPage.CONTACT_NOTE_TAB_ID,
                content: <NoteTab
                    tableName="contact"
                    type="contact"
                    entityId={this.state.contact.contactId}
                    name={`${this.state.contact.firstName} ${this.state.contact.lastName}`}
                    noteIdList={this.state.contact.noteIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            },
            {
                label: "Audit Trail",
                id: ContactDetailsPage.CONTACT_AUDIT_TAB_ID,
                content: <AuditTab
                    type="contact"
                    auditIdList={this.state.contact.auditIdList}
                    auth={this.props.auth}
                    user={this.props.user}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            }
        ]
    );

    render() {
        if (this.state.isPageLoading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    isPageLoading={this.state.isSpinnerShown}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return (
            (
                <div>
                    <FremontHeader
                        history={this.props.history}
                        flashbarText={this.state.flashbar.text}
                        flashbarType={this.state.flashbar.type}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />

                    <Grid
                        gridDefinition={[{ colspan: { default: 12 } }]}
                        disableGutters
                    >
                        <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                            <Box variant="h1">
                                {this.state.contact.firstName} {this.state.contact.lastName}
                            </Box>
                            <Tabs
                                tabs={this.updateTabs()}
                                activeTabId={this.state.activeTabId}
                                variant="default"
                                onChange={this.handleTabChange}
                            />
                        </div>
                    </Grid>
                </div>
            )
        );
    }
}

export default withRouter(ContactDetailsPage);