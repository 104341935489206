import React from "react";
import { Box, SpaceBetween, Modal } from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import { LinkServiceButton } from "./CommonComponents";

const getConfirmationButtonText = (textFromProps, isAttachmentSubmissionInProgress) => {
    if (!textFromProps) return isAttachmentSubmissionInProgress ? "Submitting..." : "Submit";
    return textFromProps;
};

const LinkServiceModal = props => (
    <Modal
        size="large"
        visible={props.isModalVisible}
        onDismiss={props.onDismiss}
        closeAriaLabel="Close modal"
        header={props.header}
        footer={
            <Box variant="span" float="right">
                <SpaceBetween
                    direction="horizontal"
                    size={
                        Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_BUTTON_PADDING}
                >
                    <LinkServiceButton
                        variant="normal"
                        onClick={e => props.onCancelButtonClick(e)}
                        disabled={props.isAttachmentSubmissionInProgress}
                    >
                        {props.closeButtonText ? props.closeButtonText : "Close"}
                    </LinkServiceButton>
                    <LinkServiceButton
                        variant="primary"
                        onClick={e => props.onSubmitButtonClick(e)}
                        loading={props.isAttachmentSubmissionInProgress}
                        disabled={props.disableSubmitButton}
                    >
                        {getConfirmationButtonText(props.confirmButtonText, props.isAttachmentSubmissionInProgress)}
                    </LinkServiceButton>
                </SpaceBetween>
            </Box>
        }
    >
        {props.children}
    </Modal>
);

export default LinkServiceModal;