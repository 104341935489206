import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Flashbar,
    FormField,
    Header,
    Link,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    ComponentConstants,
    FremontButton,
    FremontInput,
    FremontSelect,
    requiredLabel
} from "utils/CommonComponents";
import CircuitDesignTable from "circuitDesign/CircuitDesignTable";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const componentOptions = () => [
    {
        label: "CFA",
        value: "cfa"
    },
    {
        label: Constants.COMPONENT_GROUPS.crossConnect,
        value: "crossConnect"
    },
    {
        label: "Demarcation Point",
        value: "demarcPoint"
    },
    {
        label: Constants.COMPONENT_NAMES.fremontCircuit,
        value: "fremontCircuit"
    }
];

export const CIRCUIT_COLUMN_DEFINITIONS = [
    {
        id: Constants.ADD_CIRCUIT_TO_ORDER,
        sortingField: Constants.ADD_CIRCUIT_TO_ORDER,
        sortingComparator: (circuit1, circuit2) => (HelperFunctions.sortIdsNumerically(circuit1, circuit2,
            "circuitDesignNumber")),
        header: "Circuit ID",
        width: 125,
        cell: item =>
            <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>{item.circuitDesignNumber}</Link>
    },
    {
        id: Constants.ATTRIBUTES.revisionNumber,
        header: "Version",
        description: "The version of the Circuit Design",
        width: 100,
        cell: item => `V${item.revisionNumber}`
    },
    {
        id: Constants.ATTRIBUTES.providerName,
        header: "Provider",
        description: "The provider of the circuit design",
        width: 125,
        cell: item => `${item.providerName}`
    },
    {
        id: Constants.ATTRIBUTES.customerFabric,
        header: "Customer/Fabric",
        description: "The customer/fabric of the circuit design",
        width: 125,
        cell: item => `${item.customerFabric}`
    },
    {
        id: Constants.ATTRIBUTES.circuitBandwidth,
        header: "Bandwidth",
        description: "The bandwidth of the circuit design",
        width: 125,
        cell: item => `${item.circuitBandwidth}`
    },
    {
        id: Constants.ATTRIBUTES.orderId,
        sortingField: Constants.ATTRIBUTES.orderId,
        sortingComparator: (order1, order2) => (HelperFunctions.sortIdsNumerically(order1, order2, "orderId")),
        header: "Order",
        description: "The order for this circuit design",
        width: 125,
        cell: item =>
            <Link href={`${Constants.ROUTES.order}/${item.orderId}`}>{item.orderId}</Link>
    }
];

export const CustomComponentSearchForCircuits = props => (
    <Container
        header={
            <Header variant="h2">
                Circuit Search Result
            </Header>
        }
    >
        {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
        <CircuitDesignTable
            columnDefinitions={CIRCUIT_COLUMN_DEFINITIONS}
            loading={props.isQueryForCircuitsInProgress}
            items={props.circuitItems}
            emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noCircuitsFromQuery}
            selectionType="single"
            setSelectedItems={props.handleCircuitSelectedFromTable}
            selectedItems={props.selectedItems}
        />
    </Container>
);

export const CreateCircuitDesignComponentModalForm = props => (
    <Modal
        visible={props.customComponentModalVisible}
        header="Add a Component"
        onDismiss={props.handleFormDismiss}
        size="large"
        footer={
            <Box variant="span" float="right">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        variant="normal"
                        onClick={props.handleFormDismiss}
                        disabled={props.isQueryForCircuitsInProgress || props.isUpdateInProgress}
                    >
                        Cancel
                    </FremontButton>
                    <FremontButton
                        variant="primary"
                        onClick={props.handleSubmit}
                        loading={props.isQueryForCircuitsInProgress || props.isUpdateInProgress}
                    >
                        {Constants.SUBMISSION_STRINGS.readyToSubmit}
                    </FremontButton>
                </SpaceBetween>
            </Box>
        }
    >
        <Flashbar
            items={props.flashbar.text ?
                HelperFunctions.generateErrorMessageForFlashbar(props.flashbar.text) : []}
        />
        <div>
            <span className="buttonPadding">
                <FormField
                    label={requiredLabel("Component Group:")}
                    errorText={props.errorTexts.componentGroup}
                >
                    <div className="fremont-short-table">
                        <FremontSelect
                            id={Constants.CIRCUIT_DESIGN_VALUES.componentGroup}
                            options={componentOptions()}
                            selectedOption={props.componentGroup}
                            onChange={props.handleInputChange}
                            disabled={props.isUpdateInProgress}
                        />
                    </div>
                </FormField>
            </span>
            {props.componentGroup.value !== Constants.CIRCUIT_DESIGN_VALUES.fremontCircuit &&
                <div>
                    {props.customComponentSiteId ?
                        <span className="buttonPadding">
                            <FormField
                                label="Site:"
                            >
                                <FremontSelect
                                    id="site"
                                    selectedOption={{
                                        label: props.customComponentSiteName,
                                        value: props.customComponentSiteId
                                    }}
                                    disabled
                                />
                            </FormField>
                        </span> :
                        <span className="buttonPadding"/>
                    }
                    <FormField
                        label={requiredLabel("Input: ")}
                        errorText={props.errorTexts.customComponent}
                    >
                        <FremontInput
                            id={Constants.CIRCUIT_DESIGN_VALUES.customComponent}
                            onInput={props.handleInputChange}
                            disabled={!props.componentGroup.value
                            || props.isQueryForCircuitsInProgress || props.isUpdateInProgress}
                            value={props.customComponentInputValue}
                        />
                    </FormField>
                </div>
            }
            {props.componentGroup.value === Constants.CIRCUIT_DESIGN_VALUES.fremontCircuit &&
                <div>
                    <ColumnLayout>
                        {props.searchInformation}
                        <CustomComponentSearchForCircuits
                            isQueryForCircuitsInProgress={props.isQueryForCircuitsInProgress}
                            circuitItems={props.queriedCircuitDesignObjects}
                            handleCircuitSelectedFromTable={props.handleCircuitSelectedFromTable}
                            selectedItems={props.selectedItems}
                        />
                    </ColumnLayout>
                </div>
            }
        </div>
    </Modal>
);

export const EditCustomComponentModalForm = props => (
    <div>
        <Modal
            visible={props.editCustomComponent}
            header="Edit a Component"
            onDismiss={props.handleFormDismiss}
            size="large"
            footer={
                <Box variant="span" float="right">
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            variant="normal"
                            onClick={props.handleFormDismiss}
                            disabled={props.isUpdateInProgress}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            variant="primary"
                            onClick={props.handleSubmit}
                            loading={props.isUpdateInProgress}
                        >
                            {Constants.SUBMISSION_STRINGS.readyToSubmit}
                        </FremontButton>
                    </SpaceBetween>
                </Box>
            }
        >
            <Flashbar
                items={props.flashbar.text ?
                    HelperFunctions.generateErrorMessageForFlashbar(props.flashbar.text) : []}
            />
            <div>
                <div>
                    <span className="buttonPadding">
                        <FormField
                            label={requiredLabel("Component Group:")}
                        >
                            <div className="fremont-short-table">
                                <FremontSelect
                                    id="componentGroup"
                                    disabled
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.customComponentToUpdate.componentGroup
                                    )}
                                />
                            </div>
                        </FormField>
                    </span>
                    <div>
                        {props.customComponentSiteId ?
                            <span className="buttonPadding">
                                <FormField
                                    label="Site:"
                                >
                                    <FremontSelect
                                        selectedOption={{
                                            label: props.customComponentSiteName,
                                            value: props.customComponentSiteId
                                        }}
                                        disabled
                                    />
                                </FormField>
                            </span> :
                            <span className="buttonPadding"/>
                        }
                        <FormField
                            label={requiredLabel("Input: ")}
                            errorText={props.errorTexts.customComponent}
                        >
                            <FremontInput
                                id={Constants.CIRCUIT_DESIGN_VALUES.customComponent}
                                onInput={props.handleInputChange}
                                value={props.customComponentInputValue}
                                disabled={props.isUpdateInProgress}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
);