import React from "react";
import { Link } from "@amzn/awsui-components-react/polaris";
import {
    FremontSelectWithPopover,
    FremontButton,
    FremontInput,
    FremontDatePicker
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const renderDiffActualDeliveryExpectedDelivery = (item, span) => {
    const diffActualDeliveryExpectedDelivery =
        HelperFunctions.daysBetweenTwoDates(item.actualDeliveryDate, span.expectedDeliveryDate);
    if (!span || diffActualDeliveryExpectedDelivery < 0) {
        return "";
    } else if (diffActualDeliveryExpectedDelivery >= 10) {
        return <span style={{ color: "red" }}> {diffActualDeliveryExpectedDelivery} </span>;
    }
    return HelperFunctions.daysBetweenTwoDates(item.actualDeliveryDate, span.expectedDeliveryDate);
};


const generateBackboneImplementationStageColumnDefinitions = (order, span) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.backboneImplementation], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.circuitBandwidth,
            header: "Bandwidth",
            description: "Circuit bandwidth for this circuit",
            cell: item => item[Constants.ATTRIBUTES.circuitBandwidth] || item[Constants.ATTRIBUTES.portBandwidth]
        },
        {
            id: "actualDeliveryDate",
            header: "Actual Delivery Date",
            description: "Actual Delivery Date",
            width: 225,
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.actualDeliveryDate] :
                    <FremontDatePicker
                        id={`${Constants.ATTRIBUTES.actualDeliveryDate}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        value={item.actualDeliveryDate || ""}
                        placeholder={Constants.DATE_PICKER_FORMAT}
                        onChange={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                        expandToViewport
                    />)
        },
        ...(!HelperFunctions.isProviderAmazonInternal(order.providerName) ? [{
            id: "lateServiceDeliveryCreditReason",
            header: "Late Service Delivery Credit Reason",
            description: "Late Service Delivery Credit Reason",
            width: 225,
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.lateServiceDeliveryCreditReason] :
                    <FremontSelectWithPopover
                        id={`${Constants.ATTRIBUTES.lateServiceDeliveryCreditReason}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        options={HelperFunctions.createSelectedOptions(
                            Object.values(Constants.LATE_SERVICE_DELIVERY_CREDIT_REASONS)
                        )}
                        selectedOption={HelperFunctions.createSelectedOption(item.lateServiceDeliveryCreditReason)}
                        popoverInfo={Constants.LATE_SERVICE_DELIVERY_CREDIT_REASON_INFO}
                        onChange={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled
                        || item.actualDeliveryDate === undefined
                        || span.expectedDeliveryDate >= item.actualDeliveryDate}
                        expandToViewport
                    />)
        },
        {
            id: "diffActualDeliveryExpectedDelivery",
            header: "Number of Days Circuit Delivery Late",
            description: "Late Service Delivery Credit Reason",
            width: 200,
            cell: item => renderDiffActualDeliveryExpectedDelivery(item, span)
        }] : []),
        {
            id: Constants.COMPONENT_NAMES.providerCircuitA,
            header: "Provider Circuit ID",
            description: "Provider circuit of the circuit",
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.COMPONENT_NAMES.providerCircuitA] :
                    <FremontInput
                        id={`${Constants.COMPONENT_NAMES.providerCircuitA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                        value={item[Constants.COMPONENT_NAMES.providerCircuitA]}
                        onInput={item.handleStageInputChange}
                        disabled={item.allFieldsDisabled}
                    />
            )
        },
        {
            id: "loaA",
            header: "LOA A",
            description: "Link the LOA A file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.loaAttachmentIdA] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.loaAttachmentIdA])}
                />)
        },
        {
            id: "loaZ",
            header: "LOA Z",
            description: "Link the LOA Z file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.loaAttachmentIdZ] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.loaAttachmentIdZ])}
                />)
        },
        {
            id: "completionNotice",
            header: "Completion Notice",
            description: "Link the completion notice file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.completionNoticeId] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.completionNoticeId])}
                />)
        }
    ];

    // We need to show additional attachments for backbone span
    if (order[Constants.ATTRIBUTES.customerFabric] === Constants.CUSTOMER_FABRICS.BACKBONE_SPAN) {
        columnDefinitions.push(
            {
                id: "BERT",
                header: "BERT Testing",
                description: "Link the BERT Testing file for this circuit",
                cell: item => (item[Constants.ATTRIBUTES.BERTAttachmentId] &&
                    <FremontButton
                        variant="icon"
                        iconName="file"
                        disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                        onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.BERTAttachmentId])}
                    />)
            },
            {
                id: "RFC",
                header: "RFC Testing",
                description: "Link the RFC Testing file for this circuit",
                cell: item => (item[Constants.ATTRIBUTES.RFCAttachmentId] &&
                    <FremontButton
                        variant="icon"
                        iconName="file"
                        disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                        onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.RFCAttachmentId])}
                    />)
            }
        );
    }

    if (!Object.keys(order.stageStatusMap).includes(Constants.STAGE_NAMES.demarcAndCfaStage)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.demarcAndCfaA,
                header: `CFA A`,
                description: `CFA A of the circuit`,
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    !item.editable ?
                        item[Constants.COMPONENT_NAMES.demarcAndCfaA] :
                        <FremontInput
                            id={`${Constants.COMPONENT_NAMES.demarcAndCfaA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            value={item[Constants.COMPONENT_NAMES.demarcAndCfaA]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                )
            },
            {
                id: Constants.COMPONENT_NAMES.demarcAndCfaZ,
                header: `CFA Z`,
                description: `CFA Z of the circuit`,
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    !item.editable ?
                        item[Constants.COMPONENT_NAMES.demarcAndCfaZ] :
                        <FremontInput
                            id={`${Constants.COMPONENT_NAMES.demarcAndCfaZ}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            value={item[Constants.COMPONENT_NAMES.demarcAndCfaZ]}
                            onInput={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled}
                        />
                )
            }
        );
    }

    columnDefinitions.push(
        {
            id: "kmz",
            header: "KMZ",
            description: "Link the KMZ file for this circuit",
            cell: item => (item[Constants.ATTRIBUTES.kmzAttachmentId] &&
                <FremontButton
                    variant="icon"
                    iconName="file"
                    disabled={!item.downloadAttachment || item.isDownloadingAttachment}
                    onClick={() => item.downloadAttachment(item[Constants.ATTRIBUTES.kmzAttachmentId])}
                />)
        }
    );

    return columnDefinitions;
};

export default generateBackboneImplementationStageColumnDefinitions;