import React, { Component } from "react";
import Constants from "utils/Constants";
import ContactTable from "contact/ContactTable";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";

/**
 ContactTab is used to display the list of contacts for a given provider.
 */
class ContactTab extends Component {
    state = {
        loading: true,
        contactItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchContacts();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching given contact information based on list of contactIds
     */
    fetchContacts = async () => {
        try {
            this.setState({ loading: true });
            const response = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CONTACT, this.props.contactIdList, this.props.auth
            );

            // Set the necessary states to display the reformatted response in the table
            this.setState({
                contactItems: response.contacts,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the parent page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <ContactTable
                items={this.state.contactItems}
                loading={this.state.loading}
                emptyTableMessage={this.props.emptyTableMessage}
                providerName={this.props.providerName}
            />
        );
    }
}

export default ContactTab;