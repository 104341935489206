import HelperFunctions from "common/HelperFunctions";

export default function getAuthConfig() {
    // eslint-disable-next-line prefer-destructuring
    const host = window.location.host;
    const environments = HelperFunctions.getAuthConfigEnvironments();
    if (!(host in environments)) {
        throw new Error(`Invalid environment: ${host}, Supported environments: ${Object.keys(environments)}`);
    }
    return environments[host];
}