import React, { Component } from "react";
import AddProviderServiceToContactForm from "contact/AddProviderServiceToContactForm";
import FremontBackendClient from "common/FremontBackendClient";
import { Flashbar } from "@amzn/awsui-components-react/polaris";
import HelperFunctions from "common/HelperFunctions";
import Constants from "utils/Constants";

export default class AddProviderServiceToContact extends Component {
    state = {
        flashbar: {
            type: "",
            text: ""
        },
        isSubmitInProgress: false,
        selectedContactOption: {}
    };

    FremontBackendClient = new FremontBackendClient();

    handleContactSelect = (evt) => {
        const selectedContactOption = evt.detail.selectedOption;
        const updatedContact = this.props.contacts
            .find(contact => contact.contactId === selectedContactOption.value);
        updatedContact.providerServiceIdList.push(this.props.match.params.providerServiceId);
        this.setState({
            updatedContact,
            selectedContactOption
        });
    };

    /**
     * Send updated contact info to backend when the submit button is clicked
     */
    handleContactSubmit = async (evt) => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true });

        try {
            await this.FremontBackendClient.updateContactInfo(this.state.updatedContact,
                this.props.auth);

            this.props.handleContactModalClick(evt);
            // Display success message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isSubmissionInProgress: false
            });
        }
    };

    /**
     * Filter the contact options displayed in the modal so as to prevent duplicate addition of service to contacts
     * which already have the service.
     */
    filterContacts = () => {
        const contactSet = new Set(this.props.contactOptions.map(contact => contact.value));
        return this.props.contactItems.filter(contactItem => !contactSet.has(contactItem.value));
    }

    render() {
        return (
            <div>
                <Flashbar
                    items={this.state.flashbar.text ?
                        HelperFunctions.generateErrorMessageForFlashbar(this.state.flashbar.text) : []}
                />
                <AddProviderServiceToContactForm
                    serviceType={this.props.match.params.serviceType}
                    provider={this.props.match.params.providerName}
                    contactItems={this.filterContacts()}
                    handleContactSubmit={this.handleContactSubmit}
                    handleContactSelect={this.handleContactSelect}
                    handleContactModalClick={this.props.handleContactModalClick}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    selectedContactOption={this.state.selectedContactOption}
                />
            </div>
        );
    }
}