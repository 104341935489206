import React from "react";
import {
    Box,
    FormField,
    Modal,
    Icon,
    Popover,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontTextarea
} from "utils/CommonComponents";

/**
 * This is the modal that pops up to enter the rejection data request and then submit the request.  The modal is hidden
 * by default while the "visible" prop is false. When the add rejection button is clicked, the state of
 * "isAddRejectionClicked" is flipped through the handleRejectionModalClick method which sets the visible prop to true
 * and renders the modal. Hitting "Confirm" calls the method to submit the request.
 */
const OrderRejectionModal = props => (
    <Modal
        visible={props.isAddRejectionClicked}
        header="Add a reason for rejecting the order."
        size="large"
        onDismiss={props.handleCloseRejectionModal}
        footer={
            <Box variant="span" float="right">
                <Box padding={{ left: "s" }}>
                    <SpaceBetween
                        direction="horizontal"
                        size="m"
                    >
                        <Box margin={{ top: "xxs" }}>
                            <Popover
                                dismissButton
                                position="top"
                                size="small"
                                triggerType="custom"
                                content={
                                    <Box>Rejection reason is mandatory before submit.</Box>
                                }
                            >
                                <Icon
                                    name="status-warning"
                                    variant="subtle"
                                />
                            </Popover>
                        </Box>
                        <FremontButton
                            id="submitAddRejectionButton"
                            loading={props.isAddRejectionInProgress}
                            variant="primary"
                            onClick={props.handleAddRejectionSubmit}
                            disabled={!props.rejectionBody}
                        >
                            {props.isAddRejectionInProgress ? "Submitting..." : "Submit"}
                        </FremontButton>
                    </SpaceBetween>
                </Box>
            </Box>
        }
    >
        <FormField
            label="Rejection Reason:"
            errorText={props.errorText}
        >
            <FremontTextarea
                id="notes"
                disabled={props.isAddRejectionInProgress}
                value={props.rejectionBody}
                onInput={props.handleRejectionInputChange}
            />
        </FormField>
    </Modal>
);

export default OrderRejectionModal;