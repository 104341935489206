import React, { Component } from "react";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderTabRenderingHandler from "order/OrderTabRenderingHandler";

/**
 OrderCapacityEngineeringTab is used to display all the stages that a capacity engineer works through on an order
 */
class OrderCapacityEngineeringTab extends Component {
    state = {
        isUpdateCapacityEngineeringTabInfoInProgress: false
    };

    componentDidMount = () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        }
    };

    handleStageInEditOrSubmitMode = (isBeingEditedOrSubmitted) => {
        this.props.setIsAnyEditModeEnabled(isBeingEditedOrSubmitted);
    };

    render() {
        return (
            <OrderTabRenderingHandler
                order={this.props.order}
                siteNames={this.props.siteNames}
                siteOptions={this.props.siteOptions}
                fetchSiteOptions={this.props.fetchSiteOptions}
                orderCompleted={this.props.orderCompleted}
                circuitDesignObjects={this.props.circuitDesignObjects}
                circuitDesignsLoading={this.props.circuitDesignsLoading}
                componentIdToObjectMap={this.props.componentIdToObjectMap}
                loadingComponentIdToObjectMap={this.props.loadingComponentIdToObjectMap}
                handleFlashBarMessagesFromChildTabs={this.props.handleFlashBarMessagesFromChildTabs}
                loadData={this.props.loadData}
                isUpdateCapacityEngineeringTabInfoInProgress={this.state.isUpdateCapacityEngineeringTabInfoInProgress}
                goToComponentAction={this.props.goToComponentAction}
                blockers={this.props.blockers}
                stages={this.props.stages}
                auth={this.props.auth}
                editButtonsDisabled={this.props.isAnyEditModeEnabled || !this.props.isTabEditable}
                handleStageInEditOrSubmitMode={this.handleStageInEditOrSubmitMode}
                isDataLoaded={this.props.isDataLoaded}
                handleToggleCompleteStage={this.props.handleToggleCompleteStage}
            />
        );
    }
}

export default OrderCapacityEngineeringTab;