import React from "react";
import AsnTableData from "asn/AsnTableData";
import FremontTable from "table/FremontTable";

export default function AsnTable(props) {
    return (
        <FremontTable
            entity="Asn"
            createEntityLink={AsnTableData.fetchCreateAsnLink(props.providerName)}
            columnDefinitions={AsnTableData.COLUMN_DEFINITIONS}
            tableItems={props.items}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            nextToken={props.nextToken}
        />
    );
}