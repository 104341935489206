import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";
import Constants from "utils/Constants";

export default class ProviderTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "providerName",
            sortingField: "providerName",
            header: "Provider Name",
            description: "Name of provider",
            cell: item => <Link href={`${Constants.ROUTES.provider}/${item.providerName}`}>{item.providerName}</Link>
        },
        {
            id: "status",
            sortingField: "status",
            header: "Status",
            description: "Status of provider",
            cell: item => item.status
        },
        {
            id: "description",
            header: "Description",
            description: "Description related to provider",
            cell: item => <span className="pre-wrap">{item.description}</span>
        },
        {
            id: "parentProviderName",
            sortingField: "parentProviderName",
            header: "Parent Provider",
            description: "Parent Provider Name",
            cell: item => (item[Constants.ATTRIBUTES.parentProviderName] ?
                <Link href={`${Constants.ROUTES.provider}/${item.parentProviderName}`}>
                    {item.parentProviderName}
                </Link>
                : "")
        }
    ];

    static fetchCreateProviderLink = providerName =>
        (providerName ? `${Constants.ROUTES.createProvider}/${providerName}` : Constants.ROUTES.createProvider);
}