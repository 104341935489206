import React, { Component } from "react";
import "airwolf-header.css";
// We use the react-router-dom link here because it does not force a
// full page reload when navigating back to the homepage
import { Link as InternalLink } from "react-router-dom";
import {
    Box,
    Grid,
    Flashbar
} from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import LightHouseLogo from "../../img/lighthouselogo.png";
import HelperFunctions from "./HelperFunctions";

/*
 * The LightHouse displays the main header of LightHouse Service and conditionally renders a flashbar depending on
 * if the flashbar type input prop is set.
 */
export default class LightHouseHeader extends Component {
    state = {
        flashbar: {
            type: this.props.flashbarType,
            text: this.props.flashbarText
        }
    };

    /**
     * We use the componentDidUpdate function to modify state when the flashbar props change
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        if (prevProps.flashbarType !== this.props.flashbarType
            || prevProps.flashbarText !== this.props.flashbarText) {
            this.setState({
                flashbar: {
                    type: this.props.flashbarType,
                    text: this.props.flashbarText
                }
            });
        }
        // If the sideNavError has changed and the new sideNavError has a message, we display it
        if (prevProps.sideNavError !== this.props.sideNavError && this.props.sideNavError
            && this.props.sideNavError.message) {
            this.setState({
                flashbar: {
                    type: Constants.FLASHBAR_TYPES.error,
                    text: this.props.sideNavError.message
                }
            });
        }
    };

    /**
     * This function handles dismissing the flashbar. This will run the onDismiss() function that was passed in, if one
     * exists, along with actually clearing out the flashbar
     */
    dismissFlashbar = () => {
        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
        // If this function is not passed, we handle removing the flashbar here
        HelperFunctions.dismissFlashbar(this);
    }

    render() {
        return (
            <Box padding={{ bottom: "xxl" }}>
                <span>
                    <div className="flashbar">
                        <Grid
                            gridDefinition={[{ colspan: { default: 12 } }]}
                            disableGutters
                        />
                    </div>
                    <div className="airwolf-header">
                        <span className="airwolf-header-stripe-shine one"/>
                        <span className="airwolf-header-stripe-shine two"/>
                        <span className="logo-block">
                            <InternalLink to={Constants.LIGHTHOUSE_ROUTES.ispPatchPanelManagement}>
                                <img src={LightHouseLogo} alt="LightHouseLogo"/>
                            </InternalLink>
                        </span>
                        <span className="airwolf-header-stripe-fade one"/>
                        <span className="airwolf-header-stripe-fade two"/>
                        <span className="airwolf-header-stripe-fade three"/>
                        <span className="airwolf-header-stripe-fade four"/>
                        <span className="airwolf-header-stripe-fade five"/>
                        <InternalLink to={Constants.LIGHTHOUSE_ROUTES.ispPatchPanelManagement}>
                            <span className="airwolf-header-title">
                                <legend className="header-link">
                                    <span className="airworlf-header-title">LIGHTHOUSE</span>
                                </legend>
                            </span>
                        </InternalLink>
                        <span className="airwolf-header-subtitle">Inside Plant (ISP) Management</span>
                    </div>
                    {this.state.flashbar.type &&
                        <div className="flashbar">
                            <Flashbar
                                items={
                                    [{
                                        content: this.state.flashbar.text,
                                        dismissible: true,
                                        type: this.state.flashbar.type,
                                        loading: this.props.flashbarLoading || false,
                                        onDismiss: this.dismissFlashbar
                                    }]
                                }
                            />
                        </div>
                    }
                </span>
            </Box>
        );
    }
}