import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderTable from "order/OrderTable";
import OrderTableData from "order/OrderTableData";

/**
 OrderTab is used to display the list of orders for a given object(site, provider, providerService and ASN).
 */
class OrderTab extends Component {
    state = {
        loading: true,
        orderItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchOrders();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching given order information based on list of orderIds
     */
    fetchOrders = async () => {
        try {
            this.setState({ loading: true });
            const response = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.ORDER, this.props.orderIdList, this.props.auth
            );
            // Sort orders in descending order by order number
            HelperFunctions.sortOrders(response.orders);
            // Set the necessary states to display the reformatted response in the table
            this.setState({
                orderItems: response.orders,
                loading: false
            });
            const orderItems = await OrderTableData.fetchOrderRelatedObjects(
                response.orders, this.props.auth, this.props.handleFlashBarMessagesFromChildTabs
            );
            this.setState({
                orderItems
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the parent page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <OrderTable
                items={this.state.orderItems}
                loading={this.state.loading}
                emptyTableMessage={this.props.emptyTableMessage}
                providerName={this.props.providerName}
                fetchAllOrderItems={this.fetchOrders}
            />
        );
    }
}

export default OrderTab;