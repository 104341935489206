import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import { ProviderForm } from "provider/ProviderForm";
import ProviderValidation from "provider/ProviderValidation";
import { Flashbar } from "@amzn/awsui-components-react/polaris";

/**
 * CreateProviderPage renders the page holding the provider creation Form
*/
export default class CreateProviderPage extends Component {
    /**
     *  Initially set the providerInfo to mimic what it would be if it was
     *  inputted as blank (since it is blank on page load). It will later be updated by user inputs.
     */
    state = {
        provider: {
            providerName: "",
            status: Constants.STATUS.active,
            parentProviderName: this.props.match ? this.props.match.params.providerName : null
        },
        allFieldsDisabled: false,
        bizOpsLoading: false,
        bizOpsResources: [],
        hasSubmittedOnce: false,
        isSubmissionInProgress: false,
        flashbar: {
            type: "",
            text: ""
        },
        providerErrorTexts: ProviderValidation.DEFAULT_PROVIDER_ERROR_TEXTS
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else {
            this.setState({
                providerName: this.props.match ? this.props.match.params.providerName : ""
            });
            await this.getBizOps();
        }
    };

    getBizOps = async () => {
        // the following if statement is only for dev environment.
        // Its basically a dummy value that will be returned for the dropdown field ONLY when we are in
        // localhost, as we cannot get a return value for getResourceNamesBasedOffResourceType due to the fact it is a
        // BRASS related API and wont work for your local stack.
        if (window.location.host.includes(Constants.LOCAL_HOST_URL)) {
            this.setState({
                bizOpsResources: ["localhost_BizOps"]
            });
            return;
        }
        this.setState({ bizOpsLoading: true });
        try {
            const response = await this.FremontBackendClient.getResourceNamesBasedOffResourceType(
                Constants.RESOURCE_TYPES.bizops,
                this.props.auth
            );
            this.setState({
                bizOpsResources: response.resourceNames,
                bizOpsLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error,
                { allFieldsDisabled: true, bizOpsLoading: false });
        }
    }

    FremontBackendClient = new FremontBackendClient();

    /**
     * This function handles change of inputs to the provider information container fields.
     */
    handleProviderInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        // fetch changed entity
        input.provider = Object.assign({}, this.state.provider);
        input.providerErrorTexts = Object.assign({}, this.state.providerErrorTexts);
        input.providerOptions = HelperFunctions.deepClone(this.props.providerOptions);

        const output = ProviderValidation.validateInput(input);

        this.setState({
            provider: output.provider,
            providerErrorTexts: output.providerErrorTexts
        });
    };

    /**
     * Send provider info to backend when the submit button is clicked
     */
    handleProviderSubmit = async (evt) => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true, allFieldsDisabled: true });

        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.providerErrorTexts).some(errorText => !!errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    hasSubmittedOnce: true,
                    isSubmissionInProgress: false,
                    allFieldsDisabled: false
                }
            );
            return;
        }

        try {
            const response = await this.FremontBackendClient.createProvider(this.state.provider, this.props.auth);

            if (!this.props.isModal) {
                this.props.history.push(`${Constants.ROUTES.provider}/${response.providerName}`);
            } else {
                // This is used for showing the flashbar success message on the order create page
                this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                    false, false);
                await this.props.handleProviderModalClick(evt);
            }
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isSubmissionInProgress: false,
                allFieldsDisabled: false
            });
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, { loading: false });
    };

    render() {
        return (
            <div>
                {this.props.isModal ?
                    <Flashbar
                        items={this.state.flashbar.text ?
                            HelperFunctions.generateErrorMessageForFlashbar(this.state.flashbar.text) : []}
                    />
                    : <FremontHeader
                        history={this.props.history}
                        flashbarType={this.state.flashbar.type}
                        flashbarText={this.state.flashbar.text}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                }
                <ProviderForm
                    hasSubmittedOnce={this.state.hasSubmittedOnce}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    providerName={this.state.providerName}
                    provider={this.state.provider}
                    isModal={this.props.isModal}
                    handleProviderModalClick={this.props.handleProviderModalClick}
                    providersLoading={this.props.providersLoading || this.state.bizOpsLoading}
                    providerOptions={this.props.providerOptions}
                    allFieldsDisabled={this.state.allFieldsDisabled}
                    handleProviderSubmit={this.handleProviderSubmit}
                    handleProviderInputChange={this.handleProviderInputChange}
                    providerErrorTexts={this.state.hasSubmittedOnce ?
                        this.state.providerErrorTexts : {}}
                    parentProviderErrorText={this.state.providerErrorTexts.parentProviderName}
                    bizOpsResources={this.state.bizOpsResources}
                />
            </div>
        );
    }
}