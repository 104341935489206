import React from "react";
import { Container, Header, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import { FremontButton, FremontCheckbox, FremontInput, FremontSelect } from "utils/CommonComponents";
import FremontTable from "table/FremontTable";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = alpha.map(x => String.fromCharCode(x));
export const filterOptions = HelperFunctions.createSelectedOptions(alphabet);
export const serviceTypeOptions = HelperFunctions.createSelectedOptions(Object.values(Constants.SERVICE_TYPES));
export const regions = Object.keys(Constants.GEOGRAPHIC_REGIONS);
export const regionTypeOptions = HelperFunctions.createSelectedOptions(regions);
export const orderOptions = Constants.RESOURCE_ORDER_TYPES_ARRAY;

// We want to use the empty cell because if there is no value or "" in the cell, we cannot hover over it in edit mode
// to set it to some other value. Or in other words, our hover doesn't work to render an input/select over empty cells.
// &#8195; is just an em space
// eslint-disable-next-line react/self-closing-comp
export const emptyCell = <span>&#8195;&#8195;</span>;

export const orderTypeCell = (editMode, mouseHover) => ({
    id: Constants.ATTRIBUTES.orderType,
    header: "Order Type",
    width: 150,
    cell: item =>
        (editMode && mouseHover === `${Constants.ATTRIBUTES.orderType}${Constants.SEPARATOR}${item.resourceId}` ?
            <FremontSelect
                id={`${Constants.ATTRIBUTES.orderType}${Constants.SEPARATOR}${item.resourceId}`}
                onChange={item.handleManagerInputChange}
                selectedOption={HelperFunctions.createSelectedOption(item.orderType)}
                options={orderOptions}
                expandToViewport
            /> :
            <div
                id={`${Constants.ATTRIBUTES.orderType}${Constants.SEPARATOR}${item.resourceId}`}
                onMouseOver={item.handleInputChangeToSelect}
                onFocus={item.handleInputChangeToSelect}
            >
                {Constants.BACKEND_TO_FRONTEND_MAP[item.orderType]}
            </div>
        )
});

export const checkBoxCell = editMode => ({
    id: Constants.ATTRIBUTES.defaultResource,
    header: "Is Default",
    width: 150,
    cell: item =>
        (
            <FremontCheckbox
                id={`${Constants.ATTRIBUTES.defaultResource}${Constants.SEPARATOR}${item.resourceId}`}
                checked={item.defaultResource}
                onChange={item.handleManagerInputChange}
                disabled={item.defaultResource || !editMode}
            />
        )
});

export const regionalDefaultCell = (editMode, mouseHover) => ({
    id: Constants.ATTRIBUTES.regionalDefault,
    header: "Regional Default",
    width: 200,
    cell: item =>
        (editMode && mouseHover === `${Constants.ATTRIBUTES.regionalDefault}${Constants.SEPARATOR}${item.resourceId}` ?
            <FremontSelect
                id={`${Constants.ATTRIBUTES.regionalDefault}${Constants.SEPARATOR}${item.resourceId}`}
                onChange={item.handleManagerInputChange}
                selectedOption={HelperFunctions.createSelectedOption(item.regionalDefault)}
                options={HelperFunctions.createSelectedOptionsWithNone(regions)}
                expandToViewport
            /> :
            <div
                id={`${Constants.ATTRIBUTES.regionalDefault}${Constants.SEPARATOR}${item.resourceId}`}
                onMouseOver={item.handleInputChangeToSelect}
                onFocus={item.handleInputChangeToSelect}
            >
                {item.regionalDefault || emptyCell}
            </div>
        )
});

export const serviceTypeCell = (editMode, mouseHover) => ({
    id: Constants.ATTRIBUTES.serviceType,
    header: "Service Type",
    width: 200,
    cell: item =>
        (editMode && mouseHover === `${Constants.ATTRIBUTES.serviceType}${Constants.SEPARATOR}${item.resourceId}` ?
            <FremontSelect
                id={`${Constants.ATTRIBUTES.serviceType}${Constants.SEPARATOR}${item.resourceId}`}
                onChange={item.handleManagerInputChange}
                selectedOption={HelperFunctions.createSelectedOption(item.serviceType)}
                options={serviceTypeOptions}
                expandToViewport
            /> :
            <div
                id={`${Constants.ATTRIBUTES.serviceType}${Constants.SEPARATOR}${item.resourceId}`}
                onMouseOver={item.handleInputChangeToSelect}
                onFocus={item.handleInputChangeToSelect}
            >
                {item.serviceType}
            </div>
        )
});

export const filterStartCell = (editMode, header, onBoxClick) => ({
    id: Constants.ATTRIBUTES.filterStart,
    header,
    width: 150,
    cell: item =>
        (editMode && onBoxClick === `${Constants.ATTRIBUTES.filterStart}${Constants.SEPARATOR}${item.resourceId}` ?
            <FremontSelect
                id={`${Constants.ATTRIBUTES.filterStart}${Constants.SEPARATOR}${item.resourceId}`}
                onChange={item.handleManagerInputChange}
                selectedOption={HelperFunctions.createSelectedOption(item.filterStart)}
                options={filterOptions}
                filteringType="auto"
                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                expandToViewport
            /> :
            <div
                id={`${Constants.ATTRIBUTES.filterStart}${Constants.SEPARATOR}${item.resourceId}`}
                onMouseOver={item.handleInputChangeToSelect}
                onFocus={item.handleInputChangeToSelect}
            >
                {item.filterStart}
            </div>
        )
});

export const filterEndCell = (editMode, header, onBoxClick) => ({
    id: Constants.ATTRIBUTES.filterEnd,
    header,
    width: 150,
    cell: item =>
        (editMode && onBoxClick === `${Constants.ATTRIBUTES.filterEnd}${Constants.SEPARATOR}${item.resourceId}` ?
            <FremontSelect
                id={`${Constants.ATTRIBUTES.filterEnd}${Constants.SEPARATOR}${item.resourceId}`}
                onChange={item.handleManagerInputChange}
                selectedOption={HelperFunctions.createSelectedOption(item.filterEnd)}
                options={filterOptions}
                filteringType="auto"
                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                expandToViewport
            /> :
            <div
                id={`${Constants.ATTRIBUTES.filterEnd}${Constants.SEPARATOR}${item.resourceId}`}
                onMouseOver={item.handleInputChangeToSelect}
                onFocus={item.handleInputChangeToSelect}
            >
                {item.filterEnd}
            </div>
        )
});

export const resourceCell = (editMode, header, posixResourceNameList, onBoxClick) => ({
    id: Constants.ATTRIBUTES.resourceName,
    header,
    width: 150,
    cell: item =>
        (editMode && onBoxClick === `${Constants.ATTRIBUTES.resourceName}${Constants.SEPARATOR}${item.resourceId}` ?
            <FremontSelect
                id={`${Constants.ATTRIBUTES.resourceName}${Constants.SEPARATOR}${item.resourceId}`}
                onChange={item.handleManagerInputChange}
                options={HelperFunctions.createSelectedOptions(posixResourceNameList)}
                selectedOption={HelperFunctions.createSelectedOption(item.resourceName)}
                filteringType="auto"
                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                expandToViewport
            /> :
            <div
                id={`${Constants.ATTRIBUTES.resourceName}${Constants.SEPARATOR}${item.resourceId}`}
                onMouseOver={item.handleInputChangeToSelect}
                onFocus={item.handleInputChangeToSelect}
            >
                {item.resourceName}
            </div>
        )
});

export const deleteResourceCell = (editMode, resourceType, deleteFunction) => ({
    id: `delete${Constants.ATTRIBUTES.resourceName}`,
    header: "Delete",
    cell: item =>
        (<FremontButton
            variant="icon"
            iconName="close"
            disabled={editMode} // Disable when we are in edit mode
            onClick={() => deleteFunction(item.resourceId, item.resourceType)}
        />)
});

export const generateBusinessDeveloperResourceColumnDefinitions = (
    editMode, posixResources, deleteFunction, mouseHover
) => [
    orderTypeCell(editMode, mouseHover),
    serviceTypeCell(editMode, mouseHover),
    {
        id: Constants.ATTRIBUTES.cluster,
        header: "Cluster",
        width: 100,
        cell: item =>
            (editMode && mouseHover === `${Constants.ATTRIBUTES.cluster}${Constants.SEPARATOR}${item.resourceId}` ?
                <FremontInput
                    id={`${Constants.ATTRIBUTES.cluster}${Constants.SEPARATOR}${item.resourceId}`}
                    value={item.cluster}
                    onInput={item.handleManagerInputChange}
                />
                :
                <div
                    id={`${Constants.ATTRIBUTES.cluster}${Constants.SEPARATOR}${item.resourceId}`}
                    onMouseOver={item.handleInputChangeToSelect}
                    onFocus={item.handleInputChangeToSelect}
                >
                    {item.cluster || emptyCell}
                </div>
            )
    },
    regionalDefaultCell(editMode, mouseHover),
    resourceCell(editMode, "Business Developer", posixResources.businessDevelopers, mouseHover),
    deleteResourceCell(editMode, Constants.RESOURCE_TYPES.businessDeveloper, deleteFunction)
];

export const generateProvisioningResourceColumnDefinitions = (
    editMode, posixResources, deleteFunction, mouseHover
) => [
    orderTypeCell(editMode, mouseHover),
    checkBoxCell(editMode),
    serviceTypeCell(editMode, mouseHover),
    {
        id: Constants.ATTRIBUTES.businessDeveloper,
        header: "Business Developer",
        width: 150,
        cell: item =>
            (editMode && mouseHover === `${Constants.ATTRIBUTES.businessDeveloper}${Constants.SEPARATOR}${item.resourceId}` ?
                <FremontSelect
                    id={`${Constants.ATTRIBUTES.businessDeveloper}${Constants.SEPARATOR}${item.resourceId}`}
                    onChange={item.handleManagerInputChange}
                    options={
                        HelperFunctions.createSelectedOptions(posixResources.businessDevelopers)
                    }
                    selectedOption={
                        HelperFunctions.createSelectedOption(item.businessDeveloper)
                    }
                    expandToViewport
                />
                :
                <div
                    id={`${Constants.ATTRIBUTES.businessDeveloper}${Constants.SEPARATOR}${item.resourceId}`}
                    onMouseOver={item.handleInputChangeToSelect}
                    onFocus={item.handleInputChangeToSelect}
                >
                    {item.businessDeveloper}
                </div>
            )
    },
    filterStartCell(editMode, "Provider Filter Start", mouseHover),
    filterEndCell(editMode, "Provider Filter End", mouseHover),
    resourceCell(editMode, "Provisioner", posixResources.provisioners, mouseHover),
    deleteResourceCell(editMode, Constants.RESOURCE_TYPES.provisioner, deleteFunction)
];

export const generateIPEngineeringResourceColumnDefinitions = (
    editMode, posixResources, deleteFunction, mouseHover
) => [
    orderTypeCell(editMode, mouseHover),
    checkBoxCell(editMode),
    serviceTypeCell(editMode, mouseHover),
    {
        id: Constants.ATTRIBUTES.geographicRegion,
        header: "Geographic Region",
        width: 125,
        cell: item => (editMode && mouseHover === `${Constants.ATTRIBUTES.geographicRegion}${Constants.SEPARATOR}${item.resourceId}` ?
            <FremontSelect
                id={`${Constants.ATTRIBUTES.geographicRegion}${Constants.SEPARATOR}${item.resourceId}`}
                onChange={item.handleManagerInputChange}
                selectedOption={HelperFunctions.createSelectedOption(item.geographicRegion)}
                options={regionTypeOptions}
                filteringType="auto"
                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                expandToViewport
            />
            :
            <div
                id={`${Constants.ATTRIBUTES.geographicRegion}${Constants.SEPARATOR}${item.resourceId}`}
                onMouseOver={item.handleInputChangeToSelect}
                onFocus={item.handleInputChangeToSelect}
            >
                {item.geographicRegion}
            </div>
        )
    },
    filterStartCell(editMode, "Site Filter Start", mouseHover),
    filterEndCell(editMode, "Site Filter End", mouseHover),
    resourceCell(editMode, "Engineer", (posixResources.ipEngineers || []), mouseHover),
    deleteResourceCell(editMode, Constants.RESOURCE_TYPES.ipEngineer, deleteFunction)
];

export const generateBackboneEngineerResourceColumnDefinitions = (
    editMode, posixResources, deleteFunction, mouseHover
) => [
    orderTypeCell(editMode, mouseHover),
    serviceTypeCell(editMode, mouseHover),
    checkBoxCell(editMode, mouseHover),
    resourceCell(editMode, "Engineer", (posixResources.backboneEngineers || []), mouseHover),
    deleteResourceCell(editMode, Constants.RESOURCE_TYPES.backBoneEngineer, deleteFunction)
];

export const BusinessDeveloperManagement = props => (
    <div>
        <Container
            header={
                <ManagementTablesCommonHeader
                    id={Constants.RESOURCE_TYPES.businessDeveloper}
                    handleEditModeChange={props.handleEditModeChange}
                    handleSubmitUpdate={props.handleSubmitUpdate}
                    editMode={props.editMode}
                    title="Business Developer"
                    isDisabled={props.isDisabled}
                    handleAddNewResource={props.handleAddNewResource}
                    isLoading={props.isLoading}
                />
            }
        >
            <FremontTable
                entity="Business Developer Resource"
                columnDefinitions={
                    generateBusinessDeveloperResourceColumnDefinitions(props.editMode, props.posixResources,
                        props.handleShowDeleteResourceConfirmationModal, props.mouseHover)
                }
                loading={props.isLoading}
                tableItems={props.editMode ? props.businessDevelopersToUpdate : props.businessDevelopers}
                emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noResources}
            />
        </Container>
    </div>
);

export const ProvisioningManagement = props => (
    <div>
        <Container
            header={
                <ManagementTablesCommonHeader
                    id={Constants.RESOURCE_TYPES.provisioner}
                    handleEditModeChange={props.handleEditModeChange}
                    handleSubmitUpdate={props.handleSubmitUpdate}
                    editMode={props.editMode}
                    title="Provisioning"
                    isDisabled={props.isDisabled}
                    handleAddNewResource={props.handleAddNewResource}
                    isLoading={props.isLoading}
                />
            }
        >
            <div className="awsui-util-spacing-h-s">
                <div>
                    <FremontTable
                        entity="Provisioning Resource"
                        columnDefinitions={
                            generateProvisioningResourceColumnDefinitions(props.editMode, props.posixResources,
                                props.handleShowDeleteResourceConfirmationModal, props.mouseHover)
                        }
                        loading={props.isLoading}
                        tableItems={props.editMode ? props.provisionersToUpdate : props.provisioners}
                        emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noResources}
                    />
                </div>
            </div>
        </Container>
    </div>
);

export const IPEngineeringManagement = props => (
    <div>
        <Container
            header={
                <ManagementTablesCommonHeader
                    id={Constants.RESOURCE_TYPES.ipEngineer}
                    handleEditModeChange={props.handleEditModeChange}
                    handleSubmitUpdate={props.handleSubmitUpdate}
                    editMode={props.editMode}
                    title="IP Engineers"
                    isDisabled={props.isDisabled}
                    handleAddNewResource={props.handleAddNewResource}
                    isLoading={props.isLoading}
                />
            }
        >
            <div className="awsui-util-spacing-h-s">
                <div>
                    <FremontTable
                        entity="IP Engineers Resource"
                        columnDefinitions={generateIPEngineeringResourceColumnDefinitions(props.editMode,
                            props.posixResources, props.handleShowDeleteResourceConfirmationModal, props.mouseHover)
                        }
                        loading={props.isLoading}
                        tableItems={props.editMode ? props.ipEngineersToUpdate : props.ipEngineers}
                        emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noResources}
                    />
                </div>
            </div>
        </Container>
    </div>
);

export const BackboneEngineeringManagement = props => (
    <div>
        <Container
            header={
                <ManagementTablesCommonHeader
                    id={Constants.RESOURCE_TYPES.backBoneEngineer}
                    handleEditModeChange={props.handleEditModeChange}
                    handleSubmitUpdate={props.handleSubmitUpdate}
                    editMode={props.editMode}
                    title="Backbone Engineers"
                    isDisabled={props.isDisabled}
                    handleAddNewResource={props.handleAddNewResource}
                    isLoading={props.isLoading}
                />
            }
        >
            <div>
                <FremontTable
                    entity="Backbone Engineers Resource"
                    columnDefinitions={generateBackboneEngineerResourceColumnDefinitions(props.editMode,
                        props.posixResources, props.handleShowDeleteResourceConfirmationModal, props.mouseHover)
                    }
                    loading={props.isLoading}
                    tableItems={props.editMode ? props.backboneEngineersToUpdate : props.backboneEngineers}
                    emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noResources}
                />
            </div>
        </Container>
    </div>
);

export const ManagementTablesCommonHeader = props => (
    <Header
        variant="h2"
        actions={
            props.editMode ?
                <div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <FremontButton
                            id={props.id}
                            onClick={props.handleEditModeChange}
                            loading={props.isLoading}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id={props.id}
                            variant="primary"
                            onClick={props.handleSubmitUpdate}
                            loading={props.isLoading}
                        >
                            Submit
                        </FremontButton>
                    </SpaceBetween>
                </div>
                :
                <div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <FremontButton
                            id={props.id}
                            variant="primary"
                            onClick={props.handleAddNewResource}
                        >
                            Create New Resource
                        </FremontButton>
                        <FremontButton
                            id={props.id}
                            iconName="edit"
                            variant="primary"
                            onClick={props.handleEditModeChange}
                            disabled={props.isDisabled}
                            className={props.id}
                        />
                    </SpaceBetween>
                </div>
        }
    >
        {props.title}
    </Header>
);

export const ManageOrderAssignmentForm = props => (
    <SpaceBetween direction="vertical" size="xs">
        {(props.permissions.businessDeveloper || props.permissions.nest) &&
            <BusinessDeveloperManagement
                editMode={props.businessDeveloperEditMode}
                businessDevelopers={props.businessDevelopers}
                businessDevelopersToUpdate={props.businessDevelopersToUpdate}
                handleEditModeChange={props.handleEditModeChange}
                handleSubmitUpdate={props.handleSubmitUpdate}
                isDisabled={
                    props.ipEngineerEditMode || props.backboneEngineerEditMode || props.provisioningEditMode
                    || !props.businessDevelopers || props.isSubmissionInProgress}
                isLoading={props.isSubmissionInProgress}
                handleAddNewResource={props.handleAddNewResource}
                posixResources={props.posixResources}
                handleShowDeleteResourceConfirmationModal={
                    props.handleShowDeleteResourceConfirmationModal
                }
                mouseHover={props.mouseHover}
            />
        }
        {(props.permissions.provisioner || props.permissions.nest) &&
            <ProvisioningManagement
                editMode={props.provisioningEditMode}
                provisioners={props.provisioners}
                provisionersToUpdate={props.provisionersToUpdate}
                handleEditModeChange={props.handleEditModeChange}
                handleSubmitUpdate={props.handleSubmitUpdate}
                isDisabled={
                    props.ipEngineerEditMode || props.backboneEngineerEditMode || props.businessDeveloperEditMode
                    || !props.provisioners || props.isSubmissionInProgress}
                isLoading={props.isSubmissionInProgress}
                handleAddNewResource={props.handleAddNewResource}
                posixResources={props.posixResources}
                handleShowDeleteResourceConfirmationModal={
                    props.handleShowDeleteResourceConfirmationModal
                }
                mouseHover={props.mouseHover}
            />
        }
        {(props.permissions.ipeng || props.permissions.nest) &&
            <IPEngineeringManagement
                editMode={props.ipEngineerEditMode}
                ipEngineers={props.ipEngineers}
                ipEngineersToUpdate={props.ipEngineersToUpdate}
                handleEditModeChange={props.handleEditModeChange}
                handleSubmitUpdate={props.handleSubmitUpdate}
                isDisabled={
                    props.provisioningEditMode || props.backboneEngineerEditMode || props.businessDeveloperEditMode
                    || !props.ipEngineers || props.isSubmissionInProgress}
                isLoading={props.isSubmissionInProgress}
                handleAddNewResource={props.handleAddNewResource}
                posixResources={props.posixResources}
                handleShowDeleteResourceConfirmationModal={
                    props.handleShowDeleteResourceConfirmationModal
                }
                mouseHover={props.mouseHover}
            />
        }
        {(props.permissions.backbone || props.permissions.nest) &&
            <BackboneEngineeringManagement
                editMode={props.backboneEngineerEditMode}
                backboneEngineers={props.backboneEngineers}
                backboneEngineersToUpdate={props.backboneEngineersToUpdate}
                handleEditModeChange={props.handleEditModeChange}
                handleSubmitUpdate={props.handleSubmitUpdate}
                isDisabled={
                    props.provisioningEditMode || props.ipEngineerEditMode || props.businessDeveloperEditMode
                    || !props.backboneEngineers || props.isSubmissionInProgress}
                isLoading={props.isSubmissionInProgress}
                handleAddNewResource={props.handleAddNewResource}
                posixResources={props.posixResources}
                handleShowDeleteResourceConfirmationModal={
                    props.handleShowDeleteResourceConfirmationModal
                }
                mouseHover={props.mouseHover}
            />
        }
    </SpaceBetween>
);