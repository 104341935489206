import React, { Component } from "react";
import {
    Box,
    ColumnLayout,
    Form,
    FormField
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontInput
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";

/**
 * MimicUserTab is used to mimic any user in Fremont.
 */
class MimicUserTab extends Component {
    state = {
        userId: "",
        mimickedUserError: "",
        loadingUser: false
    };

    FremontBackendClient = new FremontBackendClient();

    handleUserIdChange = (evt) => {
        this.setState({
            userId: evt.detail.value
        });
    }

    /**
     * This functions makes a call to getUserInfo to ensure the user object exists. If no error is thrown, the
     * mimicked user is set inside of the windows sessionStorage and the page is reloaded so the changes take affect.
     */
    handleMimicUser = async () => {
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        try {
            this.setState({ loadingUser: true });
            await this.FremontBackendClient.getUserInfo(this.state.userId,
                this.props.auth);
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
            window.sessionStorage.setItem(Constants.MIMICKED_USER, this.state.userId);
            window.location.reload();
        } catch (error) {
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
        this.setState({ loadingUser: false });
    }

    /**
     * This function removes the mimicked user from the session storage and reloads the page
     * so the user is not longer mimicked.
     */
    handleClearMimickedUser = () => {
        window.sessionStorage.removeItem(Constants.MIMICKED_USER);
        window.location.reload();
    }

    render() {
        return (
            <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                <Form
                    header={
                        <div>
                                Enter an alias to mimic any user in Fremont for this session in the current tab.
                                If the alias exists, this page will reload for the changes to take effect.
                            <br/>
                                Once an alias has been successfully mimicked, it will persist for this tabs session.
                            <br/>
                                If you no longer want to mimic a user, simply close the tab or return the the
                                operations page and click the Clear Mimicked User button.
                        </div>}
                >
                    <ColumnLayout columns={2}>
                        <FormField
                            label="User Alias:"
                            errorText={this.state.mimickedUserError}
                        >
                            <FremontInput
                                id="userId"
                                value={this.state.userId}
                                disabled={this.state.loadingUser}
                                onInput={this.handleUserIdChange}
                            />
                        </FormField>
                        <Box margin={{ top: "xl" }}>
                            <FremontButton
                                id="mimicUser"
                                variant="primary"
                                disabled={this.state.loadingUser}
                                onClick={this.handleMimicUser}
                            >
                                Mimic User
                            </FremontButton>
                        </Box>
                        <Box>
                            <FremontButton
                                id="clearMimickedUser"
                                onClick={this.handleClearMimickedUser}
                            >
                                Clear Mimicked User
                            </FremontButton>
                        </Box>
                    </ColumnLayout>
                </Form>
            </div>
        );
    }
}

export default MimicUserTab;