import React, { Component } from "react";
import { Box, Tabs } from "@amzn/awsui-components-react";
import LinkServiceHeader from "../common/LinkServiceHeader";
import HelperFunctions from "../common/HelperFunctions";
import LinkInformation from "./LinkInformation";
import Constants from "../utils/Constants";
import LinkHierarchy from "./LinkHierarchy";
import LinkHierarchyHelper from "./LinkHierarchyHelper";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";
import TabSpinner from "../components/TabSpinner";
import LinkConsumption from "./LinkConsumption";
import LinkHistory from "./LinkHistory";
import LinkVisualTable from "../visualization/LinkVisualTable";

export const TAB_IDS = {
    consumption: "consumption",
    details: "details",
    hierarchy: "hierarchy",
    history: "history",
    visual: "visual"
};

export default class LinkDetailPage extends Component {
    state = {
        flashbar: {
            type: "",
            text: ""
        },
        loadingLink: true,
        errorFetchingLink: false,
        link: {},
        linksInLinkTypeMap: LinkHierarchyHelper.getEmptyLinkMap(),
        linkHistory: [],
        historyFirstInstanceVersion: null,
        fetchingHistory: false,
        fetchingConsumedLinks: false,
        consumedLinks: [],
        activeTabId: TAB_IDS.details
    }

    componentDidMount = async () => {
        await this.getLink();
    }

    getLink = async () => {
        let { linksInLinkTypeMap } = this.state;
        let { link } = this.state;

        try {
            // The linkId from the match params will always be present because
            // it is required to route to this component
            link = await this.linkServiceBackendClient.getLinkInstance(this.props.match.params.linkId);

            if (!link) {
                this.setState({ link: null, loadingLink: false });
                return;
            }

            LinkHierarchyHelper.addLinksToLinkTypeMap([link], linksInLinkTypeMap);

            linksInLinkTypeMap = await LinkHierarchyHelper.fetchLinkHierarchy(
                this.linkServiceBackendClient,
                linksInLinkTypeMap,
                link
            );
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error,
                {
                    errorFetchingLink: true
                });
        }

        this.setState({
            link,
            linksInLinkTypeMap: HelperFunctions.filterNativeLinks(linksInLinkTypeMap),
            loadingLink: false
        });

        // Fetch the history asynchronously (no await)
        this.fetchLinkHistory();

        if (this.showConsumptionTab()) {
            // fetch asynchronously (no await)
            this.fetchConsumedLinks();
        }
    }

    getTabs = () => {
        const details = {
            label: "Details",
            id: TAB_IDS.details,
            content: (
                <LinkInformation
                    link={this.state.link}
                    loadingLink={this.state.loadingLink}
                    updateLinkHierarchy={this.updateLinkHierarchy}
                    handleFlashbarMessageFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            )
        };
        const hierarchy = {
            label: "Hierarchy",
            id: TAB_IDS.hierarchy,
            content: (
                this.state.loadingLink ? <TabSpinner/>
                    : <LinkHierarchy
                        expanded
                        editable
                        // We don't show the empty link layer tables unless we cannot fetch the link
                        showEmptyLinkLayerTable={this.state.errorFetchingLink}
                        linksInLinkTypeMap={this.state.linksInLinkTypeMap}
                        editButtonDisabled={this.state.loadingLink}
                        updateLinkHierarchy={this.updateLinkHierarchy}
                        isDeleteEnabled
                        handleFlashbarMessageFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    />
            )
        };
        const consumption = {
            label: "Consumption",
            id: TAB_IDS.consumption,
            content: (
                this.state.loadingLink ? <TabSpinner/>
                    : <LinkConsumption
                        link={this.state.link}
                        linkType={this.consumedLinkType[this.state.link.readableLinkType]}
                        fetchingConsumedLinks={this.state.fetchingConsumedLinks}
                        consumedLinks={this.state.consumedLinks}
                        setConsumedLinks={this.setConsumedLinks}
                        handleFlashbarMessageFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                        clearFlashbarMessageFromChildTabs={this.clearFlashBarMessageFromChildTabs}
                    />
            )
        };
        const history = {
            label: "History",
            id: TAB_IDS.history,
            content: (
                <LinkHistory
                    linkInstanceId={this.state.link.instanceId}
                    history={this.state.linkHistory}
                    fetchLinkHistory={this.fetchLinkHistory}
                    fetchingHistory={this.state.fetchingHistory}
                    linkType={this.state.link.readableLinkType}
                    firstInstanceVersion={this.state.historyFirstInstanceVersion}
                />
            )
        };
        const visual = {
            label: "Visual (BETA)",
            id: TAB_IDS.visual,
            content: (
                <LinkVisualTable
                    linkId={`${Constants.LINK_INSTANCE_ID_PATTERN}${this.state.link.instanceId}`}
                />
            )
        };

        if (this.showConsumptionTab() && this.showVisualTab()) {
            return [details, hierarchy, consumption, history, visual];
        } else if (this.showConsumptionTab()) {
            return [details, hierarchy, consumption, history];
        } else if (this.showVisualTab()) {
            return [details, hierarchy, history, visual];
        }
        return [details, hierarchy, history];
    };

    setConsumedLinks = consumedLinks => this.setState({ consumedLinks })

    fetchLinkHistory = async () => {
        const instanceVersion = this.state.historyFirstInstanceVersion || this.state.link.instanceVersion;
        const maxResults = 100;

        this.setState({ fetchingHistory: true });
        try {
            const linkHistoryResponse = await this.linkServiceBackendClient.getLinkHistory(
                Constants.LINK_INSTANCE_ID_PATTERN + this.state.link.instanceId,
                parseInt(instanceVersion, 10),
                maxResults
            );
            const { Links } = linkHistoryResponse;
            const nextFirstInstanceVersion = Links.length < maxResults ? undefined :
                parseInt(Links[Links.length - 1].instanceVersion, 10) - 1;

            this.setState({
                linkHistory: this.state.linkHistory.concat(Links),
                fetchingHistory: false,
                historyFirstInstanceVersion: nextFirstInstanceVersion
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
    };

    fetchConsumedLinks = async () => {
        this.setState({ fetchingConsumedLinks: true });

        const consumedLinkIds = JSON.parse(
            HelperFunctions.getValueFromRecordAttributes(this.state.link, Constants.CONSUMPTION_ATTRIBUTES.consumesList)
        ).map(link => HelperFunctions.getUuidFromFullLinkId(link));
        const { Links } = await this.linkServiceBackendClient.getBatchLinkInstance(consumedLinkIds);
        const linksWithWrongType = Links.filter(link =>
            link.readableLinkType &&
            link.readableLinkType !== this.consumedLinkType[this.state.link.readableLinkType]);

        if (linksWithWrongType.length) {
            throw new Error("Found consumed links with unexpected type.");
        }

        this.setState({
            consumedLinks: Links,
            fetchingConsumedLinks: false
        });
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    clearFlashBarMessageFromChildTabs = () => {
        HelperFunctions.dismissFlashbar(this, {});
    }

    linkServiceBackendClient = new LinkServiceBackendClient();
    // TODO: add consumption tab for more link types
    showConsumptionTab = () => this.state.link.readableLinkType === Constants.LINK_TYPES.muxToMux;
    consumedLinkType = {
        [Constants.LINK_TYPES.muxToMux]: Constants.LINK_TYPES.passiveToPassive
    };

    // We are enabling the visual tab in beta mode to address https://issues.amazon.com/issues/D99742962
    showVisualTab = () => true;

    handleTabChange = (evt) => {
        HelperFunctions.dismissFlashbar(this, { activeTabId: evt.detail.activeTabId });
    }

    updateLinkHierarchy = async (deletedItems) => {
        const { link } = this.state;

        if (deletedItems.some(item => item.instanceId === link.instanceId)) {
            this.setState({ link: null });
            return;
        }

        const linksInLinkTypeMap = LinkHierarchyHelper.getEmptyLinkMap();


        LinkHierarchyHelper.addLinksToLinkTypeMap([link], linksInLinkTypeMap);

        await LinkHierarchyHelper.fetchLinkHierarchy(
            this.linkServiceBackendClient,
            linksInLinkTypeMap,
            link
        );

        this.setState({ linksInLinkTypeMap: HelperFunctions.filterNativeLinks(linksInLinkTypeMap) });
    }

    render() {
        return (
            <div>
                <LinkServiceHeader
                    history={this.props.history}
                    flashbarType={this.state.flashbar.type}
                    flashbarText={this.state.flashbar.text}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    {this.state.link ? <Tabs
                        tabs={this.getTabs()}
                        activeTabId={this.state.activeTabId}
                        onChange={this.handleTabChange}
                    /> : <Box textAlign="center" variant="h1">This link does not exist.</Box>}
                </div>
            </div>
        );
    }
}