import React, { useState, useEffect } from "react";

export const HALO_PATH = "halo.networking.aws.dev/tni-explorer?lighthouse=true";

export const getFullHaloUrl = (windowLocationHost) => {
    let haloUrl = "https://";
    if (!windowLocationHost) {
        return haloUrl + HALO_PATH;
    } else if (windowLocationHost.includes("beta")) {
        haloUrl += "beta.";
    } else if (windowLocationHost.includes("gamma")) {
        haloUrl += "gamma.";
    }
    return haloUrl + HALO_PATH;
};

const HaloIFramePage = () => {
    const [iframeHeight, setIframeHeight] = useState(0);

    useEffect(() => {
        // Update the iframe height when the window is resized
        const handleResize = () => {
            setIframeHeight(window.innerHeight);
        };

        // Set the initial iframe height
        setIframeHeight(window.innerHeight);

        // Attach the event listener for window resize
        window.addEventListener("resize", handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <iframe
            title="Halo"
            src={getFullHaloUrl(window.location.host)}
            width="100%"
            height={iframeHeight}
            frameBorder="0"
            style={{ border: "0" }} // Use an object for the style prop
            allowFullScreen
            allow-same-origin
        />
    );
};

export default HaloIFramePage;