import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import NoteModal from "note/NoteModal";
import NoteTable from "note/NoteTable";

/**
 NotesTab is used to display the list of notes.
 */
class NoteTab extends Component {
    state = {
        errorText: "",
        isAddNoteClicked: false,
        isAddNoteInProgress: false,
        loading: false,
        noteIdList: this.props.noteIdList,
        noteBody: "",
        noteItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchNoteItems();
        }
    };

    componentDidUpdate = async (prevProps) => {
        if (this.props.noteIdList !== prevProps.noteIdList) {
            // setState() is an async function, and in most places we don't need the await, but for some reason we need
            // to await here for it. I think its because the component updates are happening very fast, but its hard
            // to tell
            await this.setState({ noteIdList: this.props.noteIdList });
            await this.fetchNoteItems();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching given note information based on list of noteIds
     */
    fetchNoteItems = async () => {
        try {
            this.setState({ loading: true });
            const response = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.NOTE, this.state.noteIdList, this.props.auth
            );
            HelperFunctions.sortObjectsByField(response.notes, "createdTime", true);
            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                noteItems: response.notes,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the provider notes page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    /**
     * This function flips the state of isAddNoteClicked and sets note to empty
     */
    handleNoteModalClick = () => {
        this.setState({
            isAddNoteClicked: !this.state.isAddNoteClicked,
            noteBody: "",
            errorText: ""
        });
    };

    /**
     * This function handles change of inputs to the note field.
     */
    handleNoteInputChange = (evt) => {
        const noteBody = evt.detail.value;
        const errorText = HelperFunctions.validateInfo(noteBody,
            Constants.VALIDATE_INFO_OPTIONS.required);
        this.setState({
            noteBody,
            errorText
        });
    };

    /**
     * This function submits a "noteBody" request to the backend
     */
    handleAddNoteSubmit = async () => {
        const errorText = HelperFunctions.validateInfo(this.state.noteBody,
            Constants.VALIDATE_INFO_OPTIONS.required);
        if (errorText) {
            this.setState({
                errorText
            });
        } else {
            this.setState({
                isAddNoteInProgress: true
            });
            // This is used for dismissing the existing flashbar on the notes page
            this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
            try {
                const response = await this.FremontBackendClient.createNote([{
                    entityType: this.props.tableName,
                    entityId: this.props.entityId,
                    noteBody: this.state.noteBody
                }],
                this.props.auth);

                // Add the new noteIds to existing list of noteIds.
                const updatedNoteIdList = this.state.noteIdList.concat(response.notes.map(note => note.noteId));

                this.setState({
                    isAddNoteClicked: false,
                    isAddNoteInProgress: false,
                    noteIdList: updatedNoteIdList
                });

                // This is used for showing the flashbar success message on the notes page
                this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                    false, false);

                // Update the notes tab wth new data
                this.fetchNoteItems();
                // If the loadData function exists that means this is inside the OrderDetails page
                // If that is the case the order needs to be reloaded so that it has the latest noteIdList
                if (this.props.loadData) {
                    await this.props.loadData(true);
                }
            } catch (error) {
                this.setState({
                    isAddNoteClicked: false,
                    isAddNoteInProgress: false
                });
                // This is used for showing the flashbar error message on the provider notes page
                this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            }
        }
    };

    render() {
        return (
            <div>
                <NoteTable
                    items={this.state.noteItems}
                    loading={this.state.loading}
                    handleNoteModalClick={this.handleNoteModalClick}
                    emptyTableMessage={`No notes currently exist for this ${this.props.type}`}
                />
                <NoteModal
                    isAddNoteClicked={this.state.isAddNoteClicked}
                    isAddNoteInProgress={this.state.isAddNoteInProgress}
                    type={this.props.type}
                    name={this.props.name}
                    noteBody={this.state.noteBody}
                    errorText={this.state.errorText}
                    handleNoteModalClick={this.handleNoteModalClick}
                    handleNoteInputChange={this.handleNoteInputChange}
                    handleAddNoteSubmit={this.handleAddNoteSubmit}
                />
            </div>
        );
    }
}

export default NoteTab;