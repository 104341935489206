import React from "react";
import CircuitDesignTableData from "circuitDesign/CircuitDesignTableData";
import Constants from "utils/Constants";
import FremontTable from "table/FremontTable";
import HelperFunctions from "common/HelperFunctions";

export default function CircuitDesignTable(props) {
    return (
        <FremontTable
            entity="Circuit Design"
            columnDefinitions={props.columnDefinitions || CircuitDesignTableData.COLUMN_DEFINITIONS}
            tableItems={HelperFunctions.addMetaAndStatus(props.items, Constants.NCIS_ROUTES.circuit)}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            onNextPageClickHelper={{
                nextToken: props.nextToken, fetch: async () => props.fetchAllCircuitDesignItems()
            }}
            selectionType={props.selectionType}
            setSelectedItems={props.setSelectedItems}
            selectedItems={props.selectedItems}
            nextToken={props.nextToken}
        />
    );
}