import React, { Component } from "react";
import {
    Box,
    Header,
    Tabs
} from "@amzn/awsui-components-react/polaris";
import AsnInformation from "asn/AsnInformation";
import AsnValidation from "asn/AsnValidation";
import AuditTab from "audit/AuditTab";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import FremontHeaderWithSpinner from "common/FremontHeaderWithSpinner";
import HelperFunctions from "common/HelperFunctions";
import NoteTab from "note/NoteTab";
import OrderTab from "order/OrderTab";
import ProviderServiceTab from "providerService/ProviderServiceTab";
import { withRouter } from "react-router-dom";

/**
 * AsnDetailsPage acts as the asn info landing page and displays all of the asn information and its related info.
 */
class AsnDetailsPage extends Component {
    static ASN_INFO_TAB_ID = "details";
    static ASN_PROVIDER_SERVICE_TAB_ID = "asnProviderServices";
    static ASN_ORDER_TAB_ID = "asnOrders";
    static ASN_NOTE_TAB_ID = "asnNotes";
    static ASN_AUDIT_TAB_ID = "asnAudits";

    state = {
        activeTabId: "details",
        flashbar: {
            type: "",
            text: ""
        },
        isPageLoading: true,
        isSpinnerShown: true,
        asn: {},
        updatedAsn: {},
        serviceOptions: [],
        chosenProviderServices: [],
        regionObjects: [],
        // A shallow copy of the regionObjects array is needed so that any non-submitted changes to the region
        // selection field to not carry over to the detail page and subsequent edit form
        regionObjectsShallowCopy: [],
        hasUpdateAsnSubmittedOnce: false,
        isUpdateAsnInfoEditClicked: false,
        isUpdateAsnInfoInProgress: false,
        asnErrorTexts: {}
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false });
        } else {
            await this.fetchAsnInfo();
            document.title = `ASN-${HelperFunctions.deepClone(this.state.asn.asnNumber)}`;
        }
    };

    componentWillUnmount = () => {
        document.title = "Lighthouse";
    }

    FremontBackendClient = new FremontBackendClient();

    /**
     **************************************************************************************************
     * DATA LOADING
     **************************************************************************************************
     */

    /**
     * This method is used for fetching all of the asn information, including the provider service objects
     * and the necessary providerService id to service type mapping
     */
    fetchAsnInfo = async () => {
        try {
            this.setState({ isPageLoading: true });
            // Obtain the ASN object
            const asnObjectResponse = await this.FremontBackendClient.getAsnInfo(this.props.match.params.asnId,
                this.props.auth);

            // Obtain the provider object
            const providerObjectResponse = await this.FremontBackendClient.getProviderInfo(
                asnObjectResponse.asn.providerName,
                this.props.auth
            );

            // Obtain all of the provider service objects linked to the provider
            const providerConnectedProviderServicesResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE, providerObjectResponse.provider.providerServiceIdList,
                this.props.auth
            );

            // Only show service options that already exist for the provider
            const serviceOptions = providerConnectedProviderServicesResponse.providerServices
                .filter(providerService => Constants.INTERCONNECT_SERVICE_TYPES.includes(providerService.serviceType))
                .map(providerService => ({
                    label: providerService.serviceType,
                    value: providerService.providerServiceId
                }));

            // Only display services that the ASN is connected to
            const chosenProviderServices = serviceOptions
                .filter(providerService => asnObjectResponse.asn.providerServiceIdList.includes(providerService.value));

            // Set the necessary states to display the reformatted response in the dashboard table
            this.setState({
                chosenProviderServices,
                serviceOptions,
                asn: asnObjectResponse.asn,
                regionObjects: this.createRegionObjects(asnObjectResponse.asn.asnRegions),
                isPageLoading: false
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loading: false });
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     * This method creates an array of region objects when a raw asnRegions map is passed in
     */
    createRegionObjects = (asnRegionsMap) => {
        const regionObjects = [];
        if (Object.keys(asnRegionsMap).length > 0) {
            // The first step is to turn the array of entries (a two-dimensional array) into an array of objects
            // with names that are easy to read. The Object.entries function returns a two dimensional array. Each
            // inner array has a length of two and stores the key as the first element and the value as the second
            // element. In the case of the asnRegionsMap, the key is the geographicRegion and the value is the session
            // type. We assign the appropriate indexes to each object entry name for functional readability
            Object.entries(asnRegionsMap).map(regionEntry => ({
                region: regionEntry[Constants.MAP_INDEXES.keyIndex],
                asnSessionType: regionEntry[Constants.MAP_INDEXES.valueIndex]
            })).map((regionEntry, i) =>
                regionObjects.push({
                    id: `region${i + 1}`,
                    value: regionEntry.region,
                    errorText: "",
                    asnSessionType: {
                        id: `asnSessionType${i + 1}`,
                        value: regionEntry.asnSessionType,
                        errorText: ""
                    }
                }));
        } else {
            regionObjects.push({
                id: "region1",
                value: "",
                errorText: "",
                asnSessionType: {
                    id: "asnSessionType1",
                    value: "",
                    errorText: ""
                }
            });
        }
        return regionObjects;
    };


    /**
     * Changes the asn info container to edit mode, edit the request object
     */
    handleUpdateAsnEdit = () => {
        this.handleFlashbarClose();
        this.setState({
            isUpdateAsnInfoEditClicked: !this.state.isUpdateAsnInfoEditClicked,
            hasUpdateAsnSubmittedOnce: false,
            asnErrorTexts: {},
            updatedAsn: this.state.asn,
            isPageLoading: false,
            // When the edit button is clicked, we create the shallow copy of the regionObject. This allows the
            // region selection field to be dynamic on the edit page, but any changes that are not submitted
            // do not carry over to the detail page or the re-clicked edit page
            regionObjectsShallowCopy: this.state.regionObjects
        });
    };

    /**
     This function handles change of inputs to the asn information container fields.
     */
    handleUpdateAsnInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.asn = Object.assign({}, this.state.updatedAsn);
        input.asnErrorTexts = Object.assign({}, this.state.asnErrorTexts);
        input.regionObjects = HelperFunctions.deepClone(this.state.regionObjectsShallowCopy);
        input.chosenProviderServices = HelperFunctions.deepClone(this.state.chosenProviderServices);
        input.providerOptions = HelperFunctions.deepClone(this.state.providerOptions);

        const output = AsnValidation.validateInput(input);

        this.setState({
            updatedAsn: output.asn,
            asnErrorTexts: output.asnErrorTexts,
            regionObjectsShallowCopy: output.regionObjects,
            chosenProviderServices: output.chosenProviderServices
        });
    };

    /**
     * Handles addition and subtraction for the regionObjects field
     */
    handleAdditionalRegionInput = (evt) => {
        const input = {};
        input.objectChanged = evt.target.id;
        input.regionObjects = this.state.regionObjectsShallowCopy;

        const output = AsnValidation.handleAdditionalInput(input);

        this.setState({
            regionObjectsShallowCopy: output.regionObjects
        });
    };

    /**
     * Handles subtracting a single regionObject from the region selection area.
     * Removes the field whose close button was pressed
     */
    handleSubtractRegionInput = (evt) => {
        const input = {};
        input.regionObjects = HelperFunctions.deepClone(this.state.regionObjectsShallowCopy);

        const output = AsnValidation.handleSubtractRegionInput(evt, input);
        this.setState({
            regionObjectsShallowCopy: output.regionObjects
        });
    };

    /**
     This function determines whether or not the Add Additional Region button should be enabled or not
     */
    disableAddRegionButton = () => AsnValidation.disableAddRegionButton(this.state.regionObjectsShallowCopy);

    /**
     This function maps a specified function to every element of the regionObjectsShallowCopy array state
     */
    mapFunctionToRegionObjectsArray = mappedFunction => this.state.regionObjectsShallowCopy.some(mappedFunction);

    /**
     * Send asn info to backend when the submit button is clicked
     */
    handleUpdateAsnSubmit = async () => {
        HelperFunctions.dismissFlashbar(this, { isUpdateAsnInfoInProgress: true });
        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.asnErrorTexts).some(errorText => errorText)
            || this.mapFunctionToRegionObjectsArray(region => region.errorText)
            || this.mapFunctionToRegionObjectsArray(region => region.asnSessionType.errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    isUpdateAsnInfoInProgress: false,
                    hasUpdateAsnSubmittedOnce: true
                }
            );
            return;
        }
        const updatedAsn = Object.assign({}, this.state.updatedAsn);

        // Clearing the asnRegions field so that the newly selected regions can be added to it
        Object.keys(updatedAsn.asnRegions).map(regionName => delete updatedAsn.asnRegions[regionName]);

        // Filters out any blank regions and then creates the appropriate map object to
        // pass to the back end
        this.state.regionObjectsShallowCopy.filter(regionObject =>
            regionObject.value !== "" && regionObject.asnSessionType.value !== "").map(regionObject =>
            Object.assign(updatedAsn.asnRegions, { [regionObject.value]: regionObject.asnSessionType.value }));

        try {
            const response = await this.FremontBackendClient.updateAsnInfo(updatedAsn,
                this.props.auth);

            // Resets all input fields to original state if request is successful
            HelperFunctions.displayFlashbarSuccess(this, Constants.FLASHBAR_STRINGS.flashbarSuccessText, {
                asn: response,
                isUpdateAsnInfoEditClicked: false,
                isUpdateAsnInfoInProgress: false,
                hasUpdateAsnSubmittedOnce: false,
                isPageLoading: false,
                regionObjects: this.createRegionObjects(response.asnRegions)
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, {
                isUpdateAsnInfoInProgress: false,
                hasUpdateAsnSubmittedOnce: true
            });
        }
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };

    /**
     **************************************************************************************************
     * TAB CHANGE HANDLERS
     **************************************************************************************************
     */

    /**
     This function handles changing the active tab on the Tabs component, and resets the flashbar.
     */
    handleTabChange = (evt) => {
        HelperFunctions.dismissFlashbar(this, { activeTabId: evt.detail.activeTabId });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { isPageLoading: false });
        }
    };

    /**
     This function updates the tabs of the provider details page
     */
    updateTabs = () => (
        [
            {
                label: "Details",
                id: AsnDetailsPage.ASN_INFO_TAB_ID,
                content: <AsnInformation
                    asn={this.state.asn}
                    updatedAsn={this.state.updatedAsn}
                    isUpdateAsnInfoEditClicked={this.state.isUpdateAsnInfoEditClicked}
                    isUpdateAsnInfoInProgress={this.state.isUpdateAsnInfoInProgress}
                    asnErrorTexts={this.state.hasUpdateAsnSubmittedOnce ?
                        this.state.asnErrorTexts : {}}
                    handleUpdateAsnEdit={this.handleUpdateAsnEdit}
                    handleUpdateAsnInputChange={this.handleUpdateAsnInputChange}
                    handleUpdateAsnSubmit={this.handleUpdateAsnSubmit}
                    addRegionButtonDisabled={this.disableAddRegionButton()}
                    handleAdditionalRegionInput={this.handleAdditionalRegionInput}
                    handleSubtractSpecificRegionObject={this.handleSubtractRegionInput}
                    hasUpdateAsnSubmittedOnce={this.state.hasUpdateAsnSubmittedOnce}
                    chosenProviderServices={this.state.chosenProviderServices}
                    serviceOptions={this.state.serviceOptions}
                    regionObjectsShallowCopy={this.state.regionObjectsShallowCopy}
                    providerName={this.state.asn.providerName}
                />
            },
            {
                label: "ASN Services",
                id: AsnDetailsPage.ASN_PROVIDER_SERVICE_TAB_ID,
                content: <ProviderServiceTab
                    providerName={this.state.asn.providerName}
                    providerServiceIdList={this.state.asn.providerServiceIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    emptyTableMessage={
                        <Box variant="h2">No services currently exist for this ASN.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any services for this ASN.</Box>}
                />
            },
            {
                label: "ASN Orders",
                id: AsnDetailsPage.ASN_ORDER_TAB_ID,
                content: <OrderTab
                    providerName={this.state.asn.providerName}
                    orderIdList={this.state.asn.orderIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                    searchTitle="Search in ASN Orders"
                    emptyTableMessage={
                        <Box variant="h2">No orders currently exist for this ASN.</Box>}
                    emptySearchMessage={
                        <Box variant="h2">The current search input does not match any orders for this ASN.</Box>}
                />
            },
            {
                label: "ASN Notes",
                id: AsnDetailsPage.ASN_NOTE_TAB_ID,
                content: <NoteTab
                    tableName="asn"
                    type="ASN"
                    entityId={this.state.asn.asnId}
                    name={this.state.asn.asnNumber}
                    noteIdList={this.state.asn.noteIdList}
                    auth={this.props.auth}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            },
            {
                label: "Audit Trail",
                id: AsnDetailsPage.ASN_AUDIT_TAB_ID,
                content: <AuditTab
                    type="ASN"
                    auditIdList={this.state.asn.auditIdList}
                    auth={this.props.auth}
                    user={this.props.user}
                    handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                />
            }
        ]
    );

    render() {
        if (this.state.isPageLoading) {
            return (
                <FremontHeaderWithSpinner
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    isPageLoading={this.state.isSpinnerShown}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
            );
        }
        return ((
            <div>
                <FremontHeader
                    history={this.props.history}
                    flashbarText={this.state.flashbar.text}
                    flashbarType={this.state.flashbar.type}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                    updateSearchResults={this.props.updateSearchResults}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <Header variant="h1">
                        ASN {this.state.asn.asnNumber}
                    </Header>
                    <Tabs
                        tabs={this.updateTabs()}
                        activeTabId={this.state.activeTabId}
                        variant="default"
                        onChange={this.handleTabChange}
                    />
                </div>
            </div>
        ));
    }
}

export default withRouter(AsnDetailsPage);