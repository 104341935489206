import React, { Component } from "react";
import LinkServiceTable from "../components/LinkServiceTable";

export default class LinkDetailTable extends Component {
    state = { selectedItems: [] }
    setSelectedItems = (items) => {
        let { selectedItems } = this.state;
        selectedItems = [...items];
        this.setState({ selectedItems });
    }

    getTableItems = () => {
        if (this.props.inEditMode) {
            return this.props.generateUpdatedLinks(this.props.updatedLinksInLinkTypeMap[this.props.linkType],
                this.state.selectedItems);
        }
        return this.props.generateLinksWithExpansionState(this.props.linksInLinkTypeMap[this.props.linkType]);
    }

    render() {
        return (
            <LinkServiceTable
                isDownloadEnabled={this.props.isDownloadEnabled}
                isDeleteEnabled={this.props.isDeleteEnabled}
                entity={this.props.entity}
                tableItems={this.getTableItems()}
                columnDefinitions={this.props.columnDefinitions}
                downloadableColumnDefinitions={this.props.downloadableColumnDefinitions}
                editable={this.props.editable}
                inEditMode={this.props.inEditMode}
                handleSwitchEditMode={this.props.handleEditModeChange}
                editButtonDisabled={this.props.editButtonDisabled}
                submissionInProgress={this.props.submissionInProgress}
                handleSubmit={this.props.handleSubmit}
                handleFlashbarMessageFromChildTabs={
                    this.props.handleFlashbarMessageFromChildTabs
                }
                handleFlashbarMessageFromSearchPage={
                    this.props.handleFlashbarMessageFromSearchPage}
                updateLinkHierarchy={this.props.updateLinkHierarchy}
                selectedItems={this.state.selectedItems}
                setSelectedItems={this.setSelectedItems}
                updateTableItems={this.props.updateTableItems}
                isSearchPage={this.props.isSearchPage}
                handleThirdPartyLinks={this.props.handleThirdPartyLinks}
                handleThirdPartyToggleChange={this.props.handleThirdPartyToggleChange}
            />
        );
    }
}
