import React from "react";
import AuditTableData from "audit/AuditTableData";
import FremontTable from "table/FremontTable";

export default function AuditTable(props) {
    return (
        <FremontTable
            entity="Audit"
            columnDefinitions={AuditTableData.auditColumnDefinitions(props.user)}
            tableItems={props.items}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            nextToken={props.nextToken}
        />
    );
}