import React, { Component } from "react";
import { Container } from "@amzn/awsui-components-react/polaris";
import HelperFunctions from "../common/HelperFunctions";
import QuickSightTool from "./QuickSightTool";

export default class ReportFiberPage extends Component {
    static REPORT_FIBER_DASHBOARD_PROD = "e09760bc-6f76-470b-8ed7-8417ecf13ef4";
    static REPORT_FIBER_DASHBOARD_GAMMA = "FilterByLayerQuickSightDashboardGamma";
    static getDashboardId = () => (HelperFunctions.isGlobalProd()
        ? ReportFiberPage.REPORT_FIBER_DASHBOARD_PROD
        : ReportFiberPage.REPORT_FIBER_DASHBOARD_GAMMA);

    componentDidMount = async () => {
        await this.getQuickSightEmbeddedDashboard();
    }

    getQuickSightEmbeddedDashboard = async () => {
        HelperFunctions.dismissFlashbar(this);
        const quickSightTool = new QuickSightTool();
        try {
            await quickSightTool.embedQuickSightDashboard(ReportFiberPage.getDashboardId());
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
    }

    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <div id={QuickSightTool.EMBEDDING_CONTAINER_ID}/>
                    </React.Fragment>
                </Container>
            </div>
        );
    }
}
