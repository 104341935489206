import React from "react";
import {
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import {
    ComponentConstants,
    FremontExpandableSection
} from "utils/CommonComponents";
import HelperFunctions from "common/HelperFunctions";

const circuitDesignNumberColumn = {
    id: "circuitDesignNumber",
    sortingField: "circuitDesignNumber",
    sortingComparator: (circuitDesign1, circuitDesign2) =>
        (HelperFunctions.sortIdsNumerically(circuitDesign1, circuitDesign2, "circuitDesignNumber")),
    header: "Circuit ID",
    width: 140,
    cell: item =>
        <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>{item.circuitDesignNumber}</Link>
};

const revisionNumberColumn = {
    id: "revisionNumber",
    sortingField: "revisionNumber",
    sortingComparator: (circuitDesign1, circuitDesign2) =>
        (HelperFunctions.sortNumberStrings(circuitDesign1, circuitDesign2, "revisionNumber")),
    header: "Version",
    cell: item => item.revisionNumber
};

const circuitBandwidthColumn = {
    id: "circuitBandwidth",
    sortingField: "circuitBandwidth",
    sortingComparator: (circuitDesign1, circuitDesign2) =>
        (HelperFunctions.sortNumberStrings(circuitDesign1, circuitDesign2, "circuitBandwidth")),
    header: "Bandwidth (Gbps)",
    cell: item => item.circuitBandwidth
};

const actualDeliveryDate = {
    id: Constants.ATTRIBUTES.actualDeliveryDate,
    sortingField: Constants.ATTRIBUTES.actualDeliveryDate,
    sortingComparator: (circuitDesign1, circuitDesign2) =>
        (HelperFunctions.sortNumberStrings(circuitDesign1, circuitDesign2, "actualDeliveryDate")),
    header: "Actual Delivery Date",
    cell: item => item[Constants.ATTRIBUTES.actualDeliveryDate]
};

const externalCircuitDesignIdColumn = {
    id: "externalCircuitDesignId",
    sortingField: "externalCircuitDesignId",
    sortingComparator: (circuitDesign1, circuitDesign2) =>
        (HelperFunctions.sortIdsNumerically(circuitDesign1, circuitDesign2, "ncisLabel")),
    header: "NCIS ID",
    cell: item => (HelperFunctions.hasNCISMeta(item.meta)
        && <Link external href={item.ncisUrl}>{item.ncisLabel}</Link>)
};

export default class CircuitDesignTableData {
    // These column definitions are used for circuit tables that are not inside of any stage on an order
    static COLUMN_DEFINITIONS = [
        circuitDesignNumberColumn,
        externalCircuitDesignIdColumn,
        {
            id: "circuitStatus",
            sortingField: "objectStatusColumnSearchable",
            header: "Status",
            width: 150,
            cell: item => item.objectStatusColumnIcon
        },
        {
            id: "orderId",
            sortingField: "orderId",
            sortingComparator: (circuitDesign1, circuitDesign2) =>
                (HelperFunctions.sortIdsNumerically(circuitDesign1, circuitDesign2, "orderId")),
            header: "Order",
            description: "Order for this Circuit Design",
            width: 130,
            cell: item =>
                <Link href={`${Constants.ROUTES.order}/${item.orderId}`}>{item.orderId}</Link>
        },
        {
            id: "providerName",
            sortingField: "providerName",
            header: "Provider Name",
            description: "Provider for this Circuit Design",
            cell: item => <Link href={`${Constants.ROUTES.provider}/${item.providerName}`}>{item.providerName}</Link>
        },
        {
            id: "lifeCycleStage",
            sortingField: "lifeCycleStage",
            header: "Lifecycle Stage",
            description: "Current lifecycle stage of the Circuit Design",
            cell: item => item.lifeCycleStage
        },
        revisionNumberColumn,
        {
            id: "serviceType",
            sortingField: "serviceType",
            header: "Service Type",
            description: "Service type of the Circuit Design",
            cell: item => item.serviceType + (item.customerFabric ? (` : ${item.customerFabric}`) : "")
        },
        circuitBandwidthColumn,
        actualDeliveryDate
    ];

    static ORDER_PAGE_COLUMN_DEFINITIONS = (serviceType, customerFabric, lacpProvider) => {
        let columnDefinitions = [
            circuitDesignNumberColumn,
            {
                id: "maxLatencySteadyState",
                sortingField: "maxLatencySteadyState",
                header: "Total Max Latency-Steady State",
                width: 150,
                cell: item => item.maxLatencySteadyState
            },
            {
                id: "maxLatencyProtectState",
                sortingField: "maxLatencyProtectState",
                header: "Total Max Latency-Protect State",
                width: 150,
                cell: item => item.maxLatencyProtectState
            },
            {
                id: "parentCircuitDisplayInfoList",
                sortingField: "consumedByCircuitId",
                header: "Consumed by Circuit",
                width: 150,
                cell: item => (HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.circuitDesign,
                    item.parentCircuitDisplayInfoList, false).length > 0 ?
                    HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.circuitDesign,
                        item.parentCircuitDisplayInfoList, false) : "None")
            },
            {
                id: "parentPath",
                sortingField: "pathName",
                header: "Consumed by Path",
                width: 150,
                cell: item => (HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.circuitDesign,
                    item.parentPath, false).length > 0 ?
                    HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.circuitDesign,
                        item.parentPath, false) : "None")
            },
            {
                id: "circuitStatus",
                sortingField: "objectStatusColumnSearchable",
                header: "Status",
                width: 150,
                cell: item => item.objectStatusColumnIcon
            },
            {
                id: "subStatus",
                header: "Sub-Status",
                cell: item => item.subStatus
            }
        ];
        if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)) {
            columnDefinitions = columnDefinitions.concat(CircuitDesignTableData.INTERCONNECT_COLUMNS(lacpProvider));
        }
        if (serviceType === Constants.SERVICE_TYPES.BACKBONE
            && Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
            columnDefinitions = columnDefinitions.concat(CircuitDesignTableData.PATH_COLUMNS);
        }
        if (serviceType === Constants.SERVICE_TYPES.BACKBONE
            && !Constants.PATH_CUSTOMER_FABRICS.includes(customerFabric)) {
            columnDefinitions = columnDefinitions.concat(CircuitDesignTableData.SPAN_COLUMNS);
        }
        columnDefinitions = columnDefinitions.concat([
            revisionNumberColumn,
            CircuitDesignTableData.CIRCUIT_REVISIONS_COLUMN,
            circuitBandwidthColumn,
            externalCircuitDesignIdColumn
        ]);
        if (serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            columnDefinitions = columnDefinitions.concat([
                {
                    id: "transportRegion",
                    header: "Transport Region",
                    cell: item => item.transportRegion
                },
                {
                    id: "transportCorridor",
                    header: "Transport Corridor",
                    cell: item => item.transportCorridor
                }
            ]);
        }
        columnDefinitions = columnDefinitions.concat([
            {
                id: Constants.COMPONENT_NAMES.ttId,
                header: "DCO TT A",
                cell: item => HelperFunctions.renderExternalLink(item[Constants.ATTRIBUTES.ttMap][
                    Constants.TT_TYPES.dcoTTA][Constants.COMPONENT_NAMES.ttId])
            },
            {
                id: Constants.COMPONENT_NAMES.ttStatus,
                header: "DCO TT Status A",
                description: "Status of the TT",
                cell: item => item[Constants.ATTRIBUTES.ttMap][
                    Constants.TT_TYPES.dcoTTA][Constants.COMPONENT_NAMES.ttStatus]
            }
        ]);
        if (serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            columnDefinitions = columnDefinitions.concat([
                {
                    id: Constants.COMPONENT_NAMES.ttId,
                    header: "DCO TT Z",
                    cell: item => HelperFunctions.renderExternalLink(item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.dcoTTZ][Constants.COMPONENT_NAMES.ttId])
                },
                {
                    id: Constants.COMPONENT_NAMES.ttStatus,
                    header: "DCO TT Status Z",
                    description: "Status of the TT",
                    cell: item => item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.dcoTTZ][Constants.COMPONENT_NAMES.ttStatus]
                }
            ]);
        }
        if (Constants.OPTICAL_CUSTOMER_FABRICS.includes(customerFabric)) {
            columnDefinitions = columnDefinitions.concat([
                {
                    id: "opticalTTId",
                    header: "Optical TT A",
                    cell: item => HelperFunctions.renderExternalLink(item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.opticalTTA][Constants.COMPONENT_NAMES.ttId])
                },
                {
                    id: "opticalTTStatus",
                    header: "Optical TT Status A",
                    cell: item => item[Constants.ATTRIBUTES.ttMap][Constants.TT_TYPES.opticalTTA][
                        Constants.COMPONENT_NAMES.ttStatus]
                }
            ]);
        }
        if (serviceType === Constants.SERVICE_TYPES.BACKBONE
            && Constants.OPTICAL_CUSTOMER_FABRICS.includes(customerFabric)) {
            columnDefinitions = columnDefinitions.concat([
                {
                    id: "opticalTTId",
                    header: "Optical TT Z",
                    cell: item => HelperFunctions.renderExternalLink(item[Constants.ATTRIBUTES.ttMap][
                        Constants.TT_TYPES.opticalTTZ][Constants.COMPONENT_NAMES.ttId])
                },
                {
                    id: "opticalTTStatus",
                    header: "Optical TT Status Z",
                    cell: item => item[Constants.ATTRIBUTES.ttMap][Constants.TT_TYPES.opticalTTZ][
                        Constants.COMPONENT_NAMES.ttStatus]
                }
            ]);
        }
        columnDefinitions = columnDefinitions.concat([
            {
                id: Constants.ATTRIBUTES.lightLevelTestingStatus,
                header: "Light Level Testing Status",
                cell: item => item[Constants.ATTRIBUTES.lightLevelTestingStatus]
            },
            {
                id: Constants.ATTRIBUTES.receiveLightLevelA,
                header: "Receive Light Level A",
                cell: item => item[Constants.ATTRIBUTES.receiveLightLevelA]
            },
            {
                id: Constants.ATTRIBUTES.transmitLightLevelA,
                header: "Transmit Light Level A",
                cell: item => item[Constants.ATTRIBUTES.transmitLightLevelA]
            }
        ]);
        if (serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            columnDefinitions = columnDefinitions.concat([
                {
                    id: Constants.ATTRIBUTES.receiveLightLevelZ,
                    header: "Receive Light Level Z",
                    cell: item => item[Constants.ATTRIBUTES.receiveLightLevelZ]
                },
                {
                    id: Constants.ATTRIBUTES.transmitLightLevelZ,
                    header: "Transmit Light Level Z",
                    cell: item => item[Constants.ATTRIBUTES.transmitLightLevelZ]
                }
            ]);
        }
        return columnDefinitions;
    }

    static CIRCUIT_REVISIONS_COLUMN = {
        id: "allRevisions",
        sortingField: "allRevisions",
        header: "All Versions",
        width: 175,
        cell: (item) => {
            if (item.circuitRevisions.length > 0) {
                HelperFunctions.sortObjectsByField(item.circuitRevisions, Constants.ATTRIBUTES.revisionNumber);
                // Here we generate an array that contains the ID and display values used to generate the links
                // for the revisions order objects
                const idAndDisplayValueArray = item.circuitRevisions.map(circuitDesignRevision => ({
                    [Constants.ID_AND_DISPLAY_VALUE.ID]:
                        circuitDesignRevision[Constants.ATTRIBUTES.orderId],
                    [Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]: `V${
                        circuitDesignRevision[Constants.ATTRIBUTES.revisionNumber]} - ${
                        circuitDesignRevision[Constants.ATTRIBUTES.orderId]}`
                }));
                return (
                    <FremontExpandableSection
                        header={`${idAndDisplayValueArray.length} Orders, click to see`}
                    >
                        <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_LINK_PADDING}>
                            {HelperFunctions.renderLinksFromIdAndDisplayValues(
                                Constants.ROUTES.order, idAndDisplayValueArray, true
                            )}
                        </SpaceBetween>
                    </FremontExpandableSection>
                );
            }
            return ("No versions exist");
        }
    }

    static INTERCONNECT_COLUMNS = (lacpProvider) => {
        let columnDefinitions = [
            {
                id: Constants.COMPONENT_NAMES.siteA,
                header: "Site A",
                cell: item => item[Constants.COMPONENT_NAMES.siteA] && (
                    <Link href={`${Constants.ROUTES.site}/${item[Constants.ATTRIBUTES.siteAId]}`}>
                        {item[Constants.COMPONENT_NAMES.siteA]}
                    </Link>)
            },
            {
                id: Constants.COMPONENT_NAMES.nodeA,
                sortingField: Constants.COMPONENT_NAMES.nodeA,
                header: HelperFunctions.returnHeaderInformation(
                    Constants.COMPONENT_NAMES.siteA, Constants.COMPONENT_LABELS.router
                ),
                cell: item => item[Constants.COMPONENT_NAMES.nodeA]
            },
            {
                id: Constants.COMPONENT_NAMES.portA,
                header: HelperFunctions.returnHeaderInformation(
                    Constants.COMPONENT_NAMES.siteA, Constants.COMPONENT_LABELS.interface
                ),
                cell: item => item[Constants.COMPONENT_NAMES.portA]
            }
        ];
        if (HelperFunctions.parseBoolean(lacpProvider)) {
            columnDefinitions.push(
                {
                    id: Constants.COMPONENT_NAMES.lagA,
                    header: "LAG",
                    cell: item => item[Constants.COMPONENT_NAMES.lagA]
                }
            );
        }
        columnDefinitions = columnDefinitions.concat([
            {
                id: Constants.COMPONENT_NAMES.demarcAndCfaA,
                header: "CFA A",
                cell: item => item[Constants.COMPONENT_NAMES.demarcAndCfaA]
            },
            {
                id: Constants.COMPONENT_NAMES.crossConnectA,
                header: "Cross Connect A",
                cell: item => item[Constants.COMPONENT_NAMES.crossConnectA]
            },
            {
                id: "providerCircuitId",
                header: "Provider Circuit ID",
                description: "The Provider's circuit ID",
                cell: item => item.providerCircuitId
            }
        ]);
        return columnDefinitions;
    }

    static PATH_COLUMNS = [
        {
            id: Constants.COMPONENT_NAMES.siteA,
            header: "Site A",
            cell: item => item[Constants.COMPONENT_NAMES.siteA] && (
                <Link href={`${Constants.ROUTES.site}/${item[Constants.ATTRIBUTES.siteAId]}`}>
                    {item[Constants.COMPONENT_NAMES.siteA]}
                </Link>)
        },
        {
            id: Constants.COMPONENT_NAMES.nodeA,
            sortingField: Constants.COMPONENT_NAMES.nodeA,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteA, Constants.COMPONENT_LABELS.router
            ),
            cell: item => item[Constants.COMPONENT_NAMES.nodeA]
        },
        {
            id: Constants.COMPONENT_NAMES.portA,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteA, Constants.COMPONENT_LABELS.interface
            ),
            cell: item => item[Constants.COMPONENT_NAMES.portA]
        },
        {
            id: Constants.COMPONENT_NAMES.leverA,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteA, Constants.COMPONENT_LABELS.lever
            ),
            cell: item => item[Constants.COMPONENT_NAMES.leverA]
        }, {
            id: Constants.COMPONENT_NAMES.leverAExternalInterface,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteA, Constants.COMPONENT_LABELS.leverExternalInterface
            ),
            cell: item => item[Constants.COMPONENT_NAMES.leverAExternalInterface]
        }, {
            id: Constants.COMPONENT_NAMES.leverAInternalInterface,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteA, Constants.COMPONENT_LABELS.leverInternalInterface
            ),
            cell: item => item[Constants.COMPONENT_NAMES.leverAInternalInterface]
        },
        {
            id: Constants.COMPONENT_NAMES.demarcAndCfaA,
            header: "CFA A",
            cell: item => item[Constants.COMPONENT_NAMES.demarcAndCfaA]
        },
        {
            id: "providerCircuitId",
            header: "Provider Circuit ID",
            description: "The Provider's circuit ID",
            cell: item => item.providerCircuitId
        },
        {
            id: Constants.COMPONENT_NAMES.demarcAndCfaZ,
            header: "CFA Z",
            cell: item => item[Constants.COMPONENT_NAMES.demarcAndCfaZ]
        }, {
            id: Constants.COMPONENT_NAMES.leverAExternalInterface,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteZ, Constants.COMPONENT_LABELS.leverExternalInterface
            ),
            cell: item => item[Constants.COMPONENT_NAMES.leverZExternalInterface]
        }, {
            id: Constants.COMPONENT_NAMES.leverZ,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteZ, Constants.COMPONENT_LABELS.lever
            ),
            cell: item => item[Constants.COMPONENT_NAMES.leverZ]
        }, {
            id: Constants.COMPONENT_NAMES.leverAInternalInterface,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteZ, Constants.COMPONENT_LABELS.leverInternalInterface
            ),
            cell: item => item[Constants.COMPONENT_NAMES.leverZInternalInterface]
        }, {
            id: Constants.COMPONENT_NAMES.portZ,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteZ, Constants.COMPONENT_LABELS.interface
            ),
            cell: item => item[Constants.COMPONENT_NAMES.portZ]
        }, {
            id: Constants.COMPONENT_NAMES.nodeZ,
            sortingField: Constants.COMPONENT_NAMES.nodeZ,
            header: HelperFunctions.returnHeaderInformation(
                Constants.COMPONENT_NAMES.siteZ, Constants.COMPONENT_LABELS.router
            ),
            cell: item => item[Constants.COMPONENT_NAMES.nodeZ]
        }, {
            id: Constants.COMPONENT_NAMES.siteZ,
            header: "Site Z",
            cell: item => item[Constants.COMPONENT_NAMES.siteZ] && (
                <Link href={`${Constants.ROUTES.site}/${item[Constants.ATTRIBUTES.siteZId]}`}>
                    {item[Constants.COMPONENT_NAMES.siteZ]}
                </Link>)
        }
    ];

    static SPAN_COLUMNS = [
        {
            id: Constants.COMPONENT_NAMES.siteA,
            header: "Site A",
            cell: item => item[Constants.COMPONENT_NAMES.siteA] && (
                <Link href={`${Constants.ROUTES.site}/${item[Constants.ATTRIBUTES.siteAId]}`}>
                    {item[Constants.COMPONENT_NAMES.siteA]}
                </Link>)
        },
        {
            id: Constants.COMPONENT_NAMES.demarcAndCfaA,
            header: "CFA A",
            cell: item => item[Constants.COMPONENT_NAMES.demarcAndCfaA]
        },
        {
            id: "providerCircuitId",
            header: "Provider Circuit ID",
            description: "The Provider's circuit ID",
            cell: item => item.providerCircuitId
        },
        {
            id: Constants.COMPONENT_NAMES.demarcAndCfaZ,
            header: "CFA Z",
            cell: item => item[Constants.COMPONENT_NAMES.demarcAndCfaZ]
        },
        {
            id: Constants.COMPONENT_NAMES.siteZ,
            header: "Site Z",
            cell: item => item[Constants.COMPONENT_NAMES.siteZ] && (
                <Link href={`${Constants.ROUTES.site}/${item[Constants.ATTRIBUTES.siteZId]}`}>
                    {item[Constants.COMPONENT_NAMES.siteZ]}
                </Link>)
        },
        {
            id: Constants.ATTRIBUTES.actualDeliveryDate,
            header: "Actual Delivery Date",
            cell: item => item[Constants.ATTRIBUTES.actualDeliveryDate]
        }
    ]
}