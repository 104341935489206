import React, { Component } from "react";
import {
    ColumnLayout,
    Container, Flashbar,
    Form,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants.js";
import { LightHouseButton, LightHouseInput } from "../components/CommonComponents";
import IspPatchPanelConfigUpload from "./IspPatchPanelConfigUpload";
import IspPatchPanelTable from "./IspPatchPanelTable";
import LinkServiceBackendClient from "../../../mango/js/common/LinkServiceBackendClient";
import HelperFunctions from "../common/HelperFunctions";
import IspPatchPanelSearchInstructionComponent from "./IspPatchPanelSearchInstructionComponent";

export default class IspPatchPanelConfigSearch extends Component {
    state = {
        searchTerm: "",
        modalClicked: false,
        isSearchInProgress: false,
        searchResults: [],
        notificationToDisplay: [],
        isDeleteInProgress: false,
        selectedItems: []
    };

    linkServiceBackendClient = new LinkServiceBackendClient();

    handleSearchTermChange = (evt) => {
        this.setState({
            searchTerm: evt.detail.value
        });
    }

    handleSearchTermSubmit = async () => {
        this.setState({ isSearchInProgress: true });
        const { searchTerm } = this.state;

        // process search
        try {
            const searchResults = await this.linkServiceBackendClient.searchIspPanelConfig(searchTerm);
            this.setState({
                isSearchInProgress: false,
                searchResults: searchResults.PatchPanelConfigObjects
            });
        } catch (e) {
            this.setState({
                notificationToDisplay: this.state.notificationToDisplay.concat(
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Failed to search for ISP Patch Panel Configs", e.toString(), true, this
                    )
                )
            });
        }

        this.setState({
            isSearchInProgress: false
        });
    }

    addIspPanelPanelConfigClicked = () => {
        this.setState({ modalClicked: true });
    }

    hideIspPanelPanelConfigModal = () => {
        this.setState({ modalClicked: false });
    }

    handleSelectedItems = (evt) => {
        this.setState({
            selectedItems: evt.detail.selectedItems
        });
    }

    deleteConfigs = async () => {
        this.setState({ isDeleteInProgress: true });
        const { selectedItems } = this.state;
        const patchPanelIds = selectedItems.map(item => item[Constants.PATCH_PANEL_CONFIG_FIELDS.ID]);


        try {
            const response = await this.linkServiceBackendClient.batchDeleteIspPanelConfig(patchPanelIds);

            if (!!response && response.PatchPanelBatchFailures.length !== 0) {
                this.setState({
                    notificationToDisplay: this.state.notificationToDisplay.concat(
                        HelperFunctions.generateErrorMessageForFlashbar(
                            "Failed to delete ISP Patch Panel Configs", response.PatchPanelBatchFailures, true, this
                        )
                    )
                });
            } else {
                // Update button status and filter out deleted objects
                this.setState({
                    searchResults: this.state.searchResults.filter(item =>
                        !patchPanelIds.includes(item[Constants.PATCH_PANEL_CONFIG_FIELDS.ID]))
                });
            }
        } catch (e) {
            this.setState({
                notificationToDisplay: this.state.notificationToDisplay.concat(
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Failed to delete ISP Patch Panel Configs", e.message, true, this
                    )
                )
            });
        }

        // Update delete progress status and disable delete button
        this.setState({
            isDeleteInProgress: false,
            selectedItems: []
        });
    }

    render() {
        return (
            <div>
                <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_STAGES}>
                    <Flashbar items={this.state.notificationToDisplay}/>
                    <Form>
                        <Container header={
                            <Header variant="h2">
                            Search ISP Patch Panels
                            </Header>
                        }
                        >
                            <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_STAGES}>
                                <ColumnLayout columns={2}>
                                    <LightHouseInput
                                        onChange={this.handleSearchTermChange}
                                        value={this.state.searchTerm}
                                        placeholder="Enter search term here"
                                        disabled={this.state.isSearchInProgress}
                                        onKeyDown={evt => (evt.detail.key === "Enter" ?
                                            this.handleSearchTermSubmit() : {})}
                                    />
                                    <SpaceBetween
                                        direction="horizontal"
                                        size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_BUTTON_PADDING}
                                    >
                                        <LightHouseButton
                                            id={this.props.buttonId}
                                            variant="normal"
                                            iconName="search"
                                            iconAlign="left"
                                            onClick={this.handleSearchTermSubmit}
                                            loading={this.state.isSearchInProgress}
                                            disabled={!this.state.searchTerm}
                                        >
                                        Search
                                        </LightHouseButton>
                                        <LightHouseButton
                                            variant="primary"
                                            iconName="add-plus"
                                            iconAlign="left"
                                            disabled={false}
                                            onClick={this.addIspPanelPanelConfigClicked}
                                        >
                                            Add Patch Panels
                                        </LightHouseButton>
                                    </SpaceBetween>
                                </ColumnLayout>
                                <IspPatchPanelConfigUpload
                                    header="Add Patch-Panel Config"
                                    isModalVisible={this.state.modalClicked}
                                    onDismiss={this.hideIspPanelPanelConfigModal}
                                />
                                <IspPatchPanelSearchInstructionComponent/>
                            </SpaceBetween>
                        </Container>
                    </Form>
                    <IspPatchPanelTable
                        items={this.state.searchResults}
                        header="ISP Patch Panels"
                        deleteConfigs={this.deleteConfigs}
                        deleteInProgress={this.state.isDeleteInProgress}
                        deleteDisabled={this.state.selectedItems.length === 0}
                        handleSelectedItems={this.handleSelectedItems}
                        selectedItems={this.state.selectedItems}
                    />
                </SpaceBetween>
            </div>
        );
    }
}