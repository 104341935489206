import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import ProviderServiceTable from "providerService/ProviderServiceTable";

/**
 ProviderServiceTab is used to display the list of services for a given provider.
 */
class ProviderServiceTab extends Component {
    state = {
        loading: true,
        providerServiceItems: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        } else {
            await this.fetchProviderServices();
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method is used for fetching given service information based on list of providerServiceIds
     */
    fetchProviderServices = async () => {
        try {
            this.setState({ loading: true });
            const response = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE, this.props.providerServiceIdList, this.props.auth
            );
            // Set the necessary states to display the reformatted response in the table
            this.setState({
                providerServiceItems: response.providerServices,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
            // This is used for showing the flashbar error message on the parent page
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    render() {
        return (
            <ProviderServiceTable
                items={this.state.providerServiceItems}
                loading={this.state.loading}
                emptyTableMessage={this.props.emptyTableMessage}
                providerName={this.props.providerName}
            />
        );
    }
}

export default ProviderServiceTab;