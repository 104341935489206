import React from "react";
import { ExpandableSection } from "@amzn/awsui-components-react/polaris";

const IspPatchPanelSearchInstructionComponent = () => (
    <ExpandableSection
        headerText="Search Instructions"
    >
        <box>
            <ul>
                <li>Patch panel search is case insensitive</li>
                <li>
                    Search works on both A side and Z side patch panel location. Example: IAD56.1-1.1024.RU28 returns
                    all results where either A side or Z side location is IAD56.1-1.1024.RU28
                </li>
                <li>Search supports wildcard. Example: iad56* returns all patch panels in IAD56 site</li>
            </ul>

        </box>
    </ExpandableSection>
);

export default IspPatchPanelSearchInstructionComponent;