import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import FremontComponentInfo from "common/FremontComponentInfo";
import { ProviderFormSection } from "provider/ProviderForm";
import {
    FremontButton,
    ComponentConstants
} from "utils/CommonComponents";

/**
 * This component serves as the main container holding all of the other components in the provider information tab
 */
const ProviderInformation = props => (
    <ProviderInfoContainer
        provider={props.provider}
        updatedProvider={props.updatedProvider}
        providerOptions={props.providerOptions}
        providersLoading={props.providersLoading}
        providersDisabled={props.providersDisabled}
        isUpdateProviderInfoEditClicked={props.isUpdateProviderInfoEditClicked}
        isUpdateProviderInfoInProgress={props.isUpdateProviderInfoInProgress}
        handleUpdateProviderEdit={props.handleUpdateProviderEdit}
        handleUpdateProviderInputChange={props.handleUpdateProviderInputChange}
        handleUpdateProviderSubmit={props.handleUpdateProviderSubmit}
        providerErrorTexts={props.providerErrorTexts}
        parentProviderErrorTexts={props.parentProviderErrorTexts}
        bizOpsResources={props.bizOpsResources}
    />
);

/**
 * This component holds the provider display and edit modes
 */
export const ProviderInfoContainer = (props) => {
    if (props.isUpdateProviderInfoEditClicked) {
        return (
            <ProviderInfoEditMode
                isUpdateProviderInfoInProgress={props.isUpdateProviderInfoInProgress}
                provider={props.provider}
                updatedProvider={props.updatedProvider}
                providerOptions={props.providerOptions}
                providersLoading={props.providersLoading}
                providersDisabled={props.providersDisabled}
                providerErrorTexts={props.providerErrorTexts}
                parentProviderErrorTexts={props.parentProviderErrorTexts}
                handleUpdateProviderEdit={props.handleUpdateProviderEdit}
                handleUpdateProviderInputChange={props.handleUpdateProviderInputChange}
                handleUpdateProviderSubmit={props.handleUpdateProviderSubmit}
                bizOpsResources={props.bizOpsResources}
            />
        );
    }
    return (
        <ProviderInfoDisplayMode
            provider={props.provider}
            handleUpdateProviderEdit={props.handleUpdateProviderEdit}
        />
    );
};

/**
 * This component displays the provider info
 */
export const ProviderInfoDisplayMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <FremontButton
                        id="editProviderInfoButton"
                        iconName="edit"
                        variant="primary"
                        onClick={props.handleUpdateProviderEdit}
                    />
                }
            >
                Provider Information
            </Header>
        }
    >
        <SpaceBetween size={Constants.SPACE_BETWEEN_CONTAINER_PADDING}>
            <Container
                header={
                    <Header variant="h2">
                        General Information
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Provider Name:</Box>
                                <div>{props.provider[Constants.ATTRIBUTES.providerName]}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Parent Provider Name:</Box>
                                <div>
                                    {props.provider.parentProviderName ?
                                        <Link href={`${Constants.ROUTES.provider}/${props.provider[Constants.ATTRIBUTES.parentProviderName]}`}>
                                            {props.provider[Constants.ATTRIBUTES.parentProviderName]}
                                        </Link> : "-"}
                                </div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Biz Ops User Id:</Box>
                                <div>{props.provider.businessOperationsId || "-"}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Score:</Box>
                                <div>{props.provider.score || "-"}</div>
                            </div>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Status:</Box>
                                <div>{props.provider[Constants.ATTRIBUTES.status]}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Industry:</Box>
                                <div>{props.provider[Constants.ATTRIBUTES.industry] || "-"}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Description:</Box>
                                <div className="pre-wrap">
                                    {props.provider[Constants.ATTRIBUTES.description] || "-"}
                                </div>
                            </div>
                        </SpaceBetween>
                    </div>
                </ColumnLayout>
            </Container>
            <FremontComponentInfo
                modifiedBy={props.provider.modifiedBy}
                modifiedTime={props.provider.modifiedTime}
                createdBy={props.provider.createdBy}
                createdTime={props.provider.createdTime}
            />
        </SpaceBetween>
    </Container>
);

/**
 * This component allows the user to edit provider information
 */
export const ProviderInfoEditMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            disabled={props.isUpdateProviderInfoInProgress}
                            variant="normal"
                            onClick={props.handleUpdateProviderEdit}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id="submitProviderInfoButton"
                            loading={props.isUpdateProviderInfoInProgress}
                            variant="primary"
                            onClick={props.handleUpdateProviderSubmit}
                        >
                            {props.isUpdateProviderInfoInProgress ? "Submitting..." : "Submit"}
                        </FremontButton>
                    </SpaceBetween>
                }
            >
                Provider Information
            </Header>
        }
    >
        <ProviderFormSection
            editMode
            provider={props.updatedProvider}
            providerErrorTexts={props.providerErrorTexts}
            allFieldsDisabled={props.isUpdateProviderInfoInProgress}
            handleProviderInputChange={props.handleUpdateProviderInputChange}
            bizOpsResources={props.bizOpsResources}
            providersLoading={props.providersLoading}
            parentProviderErrorText={props.parentProviderErrorText}
            providersDisabled={props.providersDisabled}
            providerOptions={props.providerOptions}
        />
    </Container>
);

export default ProviderInformation;