import React, { Component } from "react";
import {
    generateIpAllocationStageColumnDefinitions
} from "order/stages/install/IpAllocationInformation";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";

class IpAllocationHandler extends Component {
    static UNIT_FIELD_LIST = [
        Constants.ATTRIBUTES.circuitDesignIdListFromRequest,
        Constants.ATTRIBUTES.amazonIPv4, Constants.ATTRIBUTES.providerIPv4,
        Constants.ATTRIBUTES.amazonIPv6, Constants.ATTRIBUTES.providerIPv6,
        Constants.ATTRIBUTES.bgpIPv4MD5Key, Constants.ATTRIBUTES.bgpIPv6MD5Key
    ];

    state = {
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        isUpdateStageInProgress: false,
        updatedUnitObjects: [],
        allFieldsDisabled: false,
        massUpdateSelectedGroupingIds: []
    };

    /**
     * This functions determines which display value should be shown for a unit attribute.
     */
    getUnitDisplayValue = (positionMap, attribute) => {
        const unitChangeSet = HelperFunctions.getAttributeFromComponent(this.props.componentIdToObjectMap, positionMap,
            Constants.COMPONENT_NAMES.unitA, Constants.ATTRIBUTES.changeSet);
        // If the unit has a change set than we display the changeSet values for the unit
        if (unitChangeSet && Object.keys(unitChangeSet).length > 0) {
            // Here we handle creating the changeSetAttribute necessary to obtain the value from the change set
            const changeSetAttribute = `changeSet${attribute.charAt(0).toUpperCase() + attribute.slice(1)}`;
            return unitChangeSet[changeSetAttribute];
        }
        return HelperFunctions.getDisplayValueFromComponentName(
            this.props.componentIdToObjectMap,
            positionMap,
            Constants.COMPONENT_NAMES.unitA,
            attribute
        );
    }

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItems = {
            static: [],
            dynamic: []
        };
        // The grouping object IDs are a list of either lag or port IDs that are used to group units with
        // component and the selected circuits
        let groupingObjectIds;
        if (HelperFunctions.parseBoolean(this.props.order.lacpProvider)) {
            groupingObjectIds = new Set();
            this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                const lagAComponent = HelperFunctions.findComponent(
                    circuitDesignObject.positionMap, Constants.COMPONENT_NAMES.lagA
                );
                if (lagAComponent.uuid) {
                    groupingObjectIds.add(lagAComponent.uuid);
                }
            });
        } else {
            groupingObjectIds = new Set();
            this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                const portAComponent = HelperFunctions.findComponent(
                    circuitDesignObject.positionMap, Constants.COMPONENT_NAMES.portA
                );
                if (portAComponent.uuid) {
                    groupingObjectIds.add(portAComponent.uuid);
                }
            });
        }
        groupingObjectIds.forEach((groupingObjectId) => {
            this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                const componentObject = HelperFunctions.findComponentByUUID(
                    circuitDesignObject.positionMap, groupingObjectId
                );
                if (componentObject) {
                    // Here we determine whether the groupingId already exists
                    // in one of the items in the circuitItemsArray
                    const componentItemInArray = circuitItems.static.find(item =>
                        item[Constants.TABLE_IDS.groupingId] === groupingObjectId);
                    if (componentItemInArray) {
                        // If the componentObject already exists as an item in the array, we add the current
                        // circuitDesignId to the circuitDesignIdListFromRequest. This is what allows us to connect
                        // a single unit to multiple circuitDesigns in a single row
                        componentItemInArray[Constants.ATTRIBUTES.circuitDesignIdListFromRequest].push(
                            circuitDesignObject[Constants.ATTRIBUTES.circuitDesignId]
                        );
                        // Whenever we add a new circuitDesignId, we need to update the list of active blockers
                        // attach to these circuits
                        componentItemInArray.blockers = HelperFunctions.circuitsActiveBlockers(
                            componentItemInArray[Constants.ATTRIBUTES.circuitDesignIdListFromRequest],
                            this.props.blockers
                        );
                    } else {
                        circuitItems.static.push({
                            [Constants.TABLE_IDS.groupingId]: groupingObjectId,
                            [Constants.ATTRIBUTES.circuitDesignIdListFromRequest]:
                                [circuitDesignObject[Constants.ATTRIBUTES.circuitDesignId]],
                            [Constants.ATTRIBUTES.circuitDesignNumber]:
                                circuitDesignObject[Constants.ATTRIBUTES.circuitDesignNumber],
                            [Constants.ATTRIBUTES.requiredFieldsCompletedMap]:
                                circuitDesignObject[Constants.ATTRIBUTES.requiredFieldsCompletedMap],
                            // Since each circuit connected to a groupingId will have the same status, we only need to
                            // store the stage status of a single circuit to display the status properly
                            [Constants.TABLE_IDS.stageStatus]: circuitDesignObject.stageStatusMap[
                                Constants.STAGE_NAMES.ipAllocation],
                            blockers: HelperFunctions.circuitsActiveBlockers(
                                [circuitDesignObject[Constants.ATTRIBUTES.circuitDesignId]], this.props.blockers
                            ),
                            [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                Constants.COMPONENT_NAMES.nodeA
                            ),
                            [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                Constants.COMPONENT_NAMES.portA
                            ),
                            [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                Constants.COMPONENT_NAMES.lagA
                            ),
                            [Constants.ATTRIBUTES.amazonIPv4]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.amazonIPv4
                            ),
                            [Constants.ATTRIBUTES.providerIPv4]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.providerIPv4
                            ),
                            [Constants.ATTRIBUTES.amazonIPv6]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.amazonIPv6
                            ),
                            [Constants.ATTRIBUTES.providerIPv6]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.providerIPv6
                            ),
                            [Constants.ATTRIBUTES.bgpIPv4MD5Key]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.bgpIPv4MD5Key
                            ),
                            [Constants.ATTRIBUTES.bgpIPv6MD5Key]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.bgpIPv6MD5Key
                            )
                        });
                    }
                }
            });
        });
        if (this.state.updatedUnitObjects.length > 0) {
            this.state.updatedUnitObjects.forEach((unitObject) => {
                circuitItems.dynamic.push({
                    [Constants.TABLE_IDS.groupingId]: unitObject[Constants.TABLE_IDS.groupingId],
                    [Constants.ATTRIBUTES.circuitDesignIdListFromRequest]:
                        unitObject[Constants.ATTRIBUTES.circuitDesignIdListFromRequest],
                    [Constants.ATTRIBUTES.positionMap]: unitObject[Constants.ATTRIBUTES.positionMap],
                    [Constants.TABLE_IDS.stageStatus]: unitObject[Constants.TABLE_IDS.stageStatus],
                    [Constants.COMPONENT_NAMES.nodeA]: unitObject[Constants.COMPONENT_NAMES.nodeA],
                    [Constants.COMPONENT_NAMES.portA]: unitObject[Constants.COMPONENT_NAMES.portA],
                    [Constants.COMPONENT_NAMES.lagA]: unitObject[Constants.COMPONENT_NAMES.lagA],
                    [Constants.ATTRIBUTES.amazonIPv4]: unitObject[Constants.ATTRIBUTES.amazonIPv4],
                    [Constants.ATTRIBUTES.providerIPv4]: unitObject[Constants.ATTRIBUTES.providerIPv4],
                    [Constants.ATTRIBUTES.amazonIPv6]: unitObject[Constants.ATTRIBUTES.amazonIPv6],
                    [Constants.ATTRIBUTES.providerIPv6]: unitObject[Constants.ATTRIBUTES.providerIPv6],
                    [Constants.ATTRIBUTES.bgpIPv4MD5Key]: unitObject[Constants.ATTRIBUTES.bgpIPv4MD5Key],
                    [Constants.ATTRIBUTES.bgpIPv6MD5Key]: unitObject[Constants.ATTRIBUTES.bgpIPv6MD5Key],
                    errorTexts: this.state.hasBeenSubmittedOnce ? unitObject.errorTexts :
                        OrderValidation.IP_ALLOCATION_STAGE_ERROR_TEXTS,
                    editable: this.state.isEditClicked,
                    blockers: HelperFunctions.circuitsActiveBlockers(
                        unitObject[Constants.ATTRIBUTES.circuitDesignIdListFromRequest], this.props.blockers
                    ),
                    hasStageSubmittedOnce: this.state.hasBeenSubmittedOnce,
                    isUpdateStageInProgress: this.state.isUpdateStageInProgress,
                    handleStageInputChange: this.handleStageInputChange,
                    [Constants.ATTRIBUTES.positionMap]: unitObject[Constants.ATTRIBUTES.positionMap],
                    allFieldsDisabled: this.state.allFieldsDisabled
                });
            });
        }

        return circuitItems;
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * Handle any edits to the order
     * @param evt
     */
    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        // Here we assign a new attribute, circuitDesignId, to be the uuid stored in the groupingId. We do this
        // so that we can utilize the validateInput logic that is used on every other stage table
        const transformedUnitObjects = HelperFunctions.deepClone(this.state.updatedUnitObjects).map((unitObject) => {
            Object.assign(unitObject, { circuitDesignId: unitObject[Constants.TABLE_IDS.groupingId] });
            return unitObject;
        });
        input.circuitDesignObjects = HelperFunctions.deepClone(transformedUnitObjects);
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(this.state.massUpdateSelectedGroupingIds,
            input, circuitDesignOutput.circuitDesignObjects);

        // Here we delete the circuitDesignId attribute we added above
        const revertedUnitObjects = output.map((unitObject) => {
            const unitObjectCopy = HelperFunctions.deepClone(unitObject);
            delete unitObjectCopy.circuitDesignId;
            return unitObjectCopy;
        });

        this.setState({
            updatedUnitObjects: revertedUnitObjects
        });
    };

    handleSelectedFromTable = (evt) => {
        const selectedGroupingIds = evt.detail.selectedItems.map(group => group.groupingId);
        this.setState({
            massUpdateSelectedGroupingIds: selectedGroupingIds
        });
    };

    /**
     * Handle clicking the edit button
     * @param evt
     */
    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedUnitObjects = [];
        // The grouping object IDs are a list of either lag or port IDs that are used to group units with
        // component and the selected circuits
        let groupingObjectIds;
        if (HelperFunctions.parseBoolean(this.props.order.lacpProvider)) {
            groupingObjectIds = new Set();
            this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                const lagAComponent = HelperFunctions.findComponent(
                    circuitDesignObject.positionMap, Constants.COMPONENT_NAMES.lagA
                );
                if (lagAComponent.uuid) {
                    groupingObjectIds.add(lagAComponent.uuid);
                }
            });
        } else {
            groupingObjectIds = new Set();
            this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                const portAComponent = HelperFunctions.findComponent(
                    circuitDesignObject.positionMap, Constants.COMPONENT_NAMES.portA
                );
                if (portAComponent.uuid) {
                    groupingObjectIds.add(portAComponent.uuid);
                }
            });
        }
        groupingObjectIds.forEach((groupingObjectId) => {
            this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                const componentObject = HelperFunctions.findComponentByUUID(
                    circuitDesignObject.positionMap, groupingObjectId
                );
                if (componentObject) {
                    // Here we determine whether the groupingId already exists
                    // in one of the items in the updatedUnitObjectsArray
                    const componentItemInArray = updatedUnitObjects.find(item =>
                        item[Constants.TABLE_IDS.groupingId] === groupingObjectId);
                    if (componentItemInArray) {
                        // If the componentObject already exists as an item in the array, we add the current
                        // circuitDesignId to the circuitDesignIdListFromRequest. This is what allows us to connect
                        // a single unit to multiple circuitDesigns in a single row
                        componentItemInArray[Constants.ATTRIBUTES.circuitDesignIdListFromRequest].push(
                            circuitDesignObject[Constants.ATTRIBUTES.circuitDesignId]
                        );
                        // Whenever we add a new circuitDesignId, we need to update the list of active blockers
                        // attach to these circuits
                        componentItemInArray.blockers = HelperFunctions.circuitsActiveBlockers(
                            componentItemInArray[Constants.ATTRIBUTES.circuitDesignIdListFromRequest],
                            this.props.blockers
                        );
                    } else {
                        updatedUnitObjects.push({
                            [Constants.TABLE_IDS.groupingId]: groupingObjectId,
                            [Constants.ATTRIBUTES.circuitDesignIdListFromRequest]:
                                [circuitDesignObject[Constants.ATTRIBUTES.circuitDesignId]],
                            // Even though a single component (such as a lag) may be connected to multiple circuit
                            // designs, we only need a single positionMap. Since each circuit has the same lag, it
                            // will also have the same unit so we are ok to only use a single circuitDesigns
                            // positionMap. This problem does not exist if port is the grouping component because
                            // each port has its own circuitDesign and therefore its own positionMap
                            [Constants.ATTRIBUTES.positionMap]: circuitDesignObject[Constants.ATTRIBUTES.positionMap],
                            // Since each circuit connected to a groupingId will have the same status, we only need to
                            // store the stage status of a single circuit to display the status properly
                            [Constants.TABLE_IDS.stageStatus]: circuitDesignObject.stageStatusMap[
                                Constants.STAGE_NAMES.ipAllocation],
                            blockers: HelperFunctions.circuitsActiveBlockers(
                                [circuitDesignObject[Constants.ATTRIBUTES.circuitDesignId]], this.props.blockers
                            ),
                            [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                Constants.COMPONENT_NAMES.nodeA
                            ),
                            [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                Constants.COMPONENT_NAMES.portA
                            ),
                            [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                circuitDesignObject.positionMap,
                                Constants.COMPONENT_NAMES.lagA
                            ),
                            [Constants.ATTRIBUTES.amazonIPv4]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.amazonIPv4
                            ),
                            [Constants.ATTRIBUTES.providerIPv4]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.providerIPv4
                            ),
                            [Constants.ATTRIBUTES.amazonIPv6]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.amazonIPv6
                            ),
                            [Constants.ATTRIBUTES.providerIPv6]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.providerIPv6
                            ),
                            [Constants.ATTRIBUTES.bgpIPv4MD5Key]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.bgpIPv4MD5Key
                            ),
                            [Constants.ATTRIBUTES.bgpIPv6MD5Key]: this.getUnitDisplayValue(
                                circuitDesignObject.positionMap,
                                Constants.ATTRIBUTES.bgpIPv6MD5Key
                            ),
                            errorTexts: HelperFunctions.deepClone(
                                OrderValidation.IP_ALLOCATION_STAGE_ERROR_TEXTS
                            )
                        });
                    }
                }
            });
        });
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedGroupingIds: [],
            hasBeenSubmittedOnce: false,
            updatedUnitObjects,
            allFieldsDisabled: false,
            isUpdateStageInProgress: false
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });
        const updatedUnitObjects = HelperFunctions.deepClone(this.state.updatedUnitObjects);
        // Here we check all of the error texts for each updatedUnitObject object. If any error texts exist, we
        // display them and exit the submit function
        if (updatedUnitObjects.some(updatedUnitObject =>
            Object.values(updatedUnitObject.errorTexts).some(error => error))) {
            this.setState({
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            return;
        }
        try {
            const unitList = [];
            this.state.updatedUnitObjects.forEach((updatedUnitObject) => {
                [Constants.COMPONENT_NAMES.unitA].forEach((componentName) => {
                    // We assign removeCircuitDesignIdFromRequest to be null and if its values does not change
                    // that means we take no action on the unit object because the user did not modify it
                    const unitObjectForUnitList = {
                        // The componentNameFromRequest is always unitA because units are only used in Interconnect
                        // orders which do not have a Z side
                        [Constants.ATTRIBUTES.componentNameFromRequest]: Constants.COMPONENT_NAMES.unitA,
                        [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]: null
                    };
                    // Here we assign all of the fields necessary to populate the unit object
                    IpAllocationHandler.UNIT_FIELD_LIST.forEach(field =>
                        Object.assign(unitObjectForUnitList, { [field]: updatedUnitObject[field] }));

                    // Here we obtain the unit component from the circuits positionMap
                    const unitComponent = HelperFunctions.findComponent(
                        updatedUnitObject.positionMap, componentName
                    );

                    if (!!updatedUnitObject[Constants.ATTRIBUTES.amazonIPv4]
                        && !!updatedUnitObject[Constants.ATTRIBUTES.providerIPv4]) {
                        // If the amazonIPv4 and providerIPv4 values both exist, the user is trying to add a new unit
                        // or modify an existing unit so removeCircuitDesignIdFromRequest is false
                        unitObjectForUnitList[Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest] = false;
                    } else if (unitComponent && unitComponent.uuid) {
                        // If the amazonIPv4 and providerIPv4 do not exist and a unit component exists on the circuit,
                        // that means the user want to remove the unit from that circuit object
                        // so removeCircuitDesignIdFromRequest is true
                        unitObjectForUnitList[Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest] = true;
                    }

                    // If the removeCircuitDesignIdFromRequest has been changed, that means the user is either adding
                    // or removing the unit so we add it the lists of units to pass
                    if (unitObjectForUnitList[Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest] !== null) {
                        unitList.push(unitObjectForUnitList);
                    }
                });
            });
            if (unitList.length > 0) {
                await this.FremontBackendClient.modifyUnit(unitList, this.props.auth);
            }
            // Here we call a helper function which updates all data related to the order
            // and loads new component info as well
            await this.props.loadData(true, true);
            // Resets all input fields to updated state in dynamo
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText);
            this.setState({
                hasBeenSubmittedOnce: false,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.ipAllocation}
                    showAttachmentModal={false}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}

                    hasStageBeenCompleted={this.props.order.hasIpAllocationBeenCompleted}
                    handleCompleteStage={HelperFunctions.isOrderInterconnectChange(this.props.order) ?
                        this.props.handleToggleCompleteStage : null}
                    completeStageMessage="Mark stage for completion"

                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    unitTable
                    content={
                        <div>
                            <TableDisplayMode
                                order={this.props.order}
                                stageName={Constants.STAGE_NAMES.ipAllocation}
                                circuitItems={this.generateCircuitItems().static}
                                unitTable
                                columnDefinitions={generateIpAllocationStageColumnDefinitions(
                                    this.props.order.lacpProvider
                                )}
                                isDataLoaded={this.props.isDataLoaded}
                                customEmptyMessage={Constants.EMPTY_TABLE_MESSAGES.noUnits}
                            />
                        </div>
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.ipAllocation}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <TableEditMode
                            circuitItems={this.generateCircuitItems().dynamic}
                            unitTable
                            columnDefinitions={generateIpAllocationStageColumnDefinitions(
                                this.props.order.lacpProvider
                            )}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            customEmptyMessage={Constants.EMPTY_TABLE_MESSAGES.noUnits}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedGroupingIds}
                        />
                    }
                />
        );
    }
}

export default IpAllocationHandler;