import Constants from "../utils/Constants";

export default class IspPatchPanelTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "patchPanelLocationSideA",
            header: "Panel Side A Location",
            minWidth: 176,
            cell: item => item[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_A],
            isRowHeader: true
        },
        {
            id: "patchPanelLocationSideZ",
            header: "Panel Side Z Location",
            minWidth: 176,
            cell: item => item[Constants.PATCH_PANEL_CONFIG_FIELDS.LOCATION_Z],
            isRowHeader: true
        },
        {
            id: "patchPanelPortCount",
            header: "Total Port Count",
            minWidth: 176,
            cell: item => item[Constants.PATCH_PANEL_CONFIG_FIELDS.PORT_COUNT],
            isRowHeader: true
        },
        {
            id: "patchPanelSideAStartingPort",
            header: "Side A Starting Port Number",
            minWidth: 176,
            cell: item => item[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_A],
            isRowHeader: true
        },
        {
            id: "patchPanelSideZStartingPort",
            header: "Side Z Starting Port Number",
            minWidth: 176,
            cell: item => item[Constants.PATCH_PANEL_CONFIG_FIELDS.STARTING_PORT_Z],
            isRowHeader: true
        }
    ]
}