import React from "react";
import { Box, Icon } from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import "footer.css";

/**
 * The HaloFooter displays the FAQ/User guide, bug report, and feature request links.
 * We wrap the Box and Icon components inside of spans that have custom CSS attributes since
 * most polaris V3 attributes do not allow custom CSS overrides.
 */
const HaloFooter = () => (
    <div className="footer">
        <span className="footer-link">
            <a href={Constants.FOOTER_STRINGS.haloHelpLink} target="_blank" rel="noopener noreferrer">
                <span className="link-color">
                    <Icon name="external" variant="normal"/>
                </span>
                <span className="footer-text-color">
                    <Box
                        variant="strong"
                        color="inherit"
                        fontSize="heading-s"
                        margin={{ left: "xxs" }}
                    >
                        {Constants.FOOTER_STRINGS.haloHelpText}
                    </Box>
                </span>
            </a>
        </span>
        <span className="footer-link">
            <a href={Constants.FOOTER_STRINGS.haloBugLink} target="_blank" rel="noopener noreferrer">
                <span className="link-color">
                    <Icon name="bug" variant="normal"/>
                </span>
                <span className="footer-text-color">
                    <Box
                        variant="strong"
                        color="inherit"
                        fontSize="heading-s"
                        margin={{ left: "xxs" }}
                    >
                        {Constants.FOOTER_STRINGS.bugText}
                    </Box>
                </span>
            </a>
        </span>
        <span className="footer-link">
            <a href={Constants.FOOTER_STRINGS.haloFeatureLink} target="_blank" rel="noopener noreferrer">
                <span className="link-color">
                    <Icon name="folder" variant="normal"/>
                </span>
                <span className="footer-text-color">
                    <Box
                        variant="strong"
                        color="inherit"
                        fontSize="heading-s"
                        margin={{ left: "xxs" }}
                    >
                        {Constants.FOOTER_STRINGS.featureText}
                    </Box>
                </span>
            </a>
        </span>
    </div>
);

export default HaloFooter;