import React, { Component } from "react";
import {
    generateImplementationStageColumnDefinitions
} from "order/stages/install/ImplementationInformation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";

class ImplementationHandler extends Component {
    state = {
        isEditClicked: false,
        hasBeenSubmittedOnce: false,
        isUpdateStageInProgress: false,
        updatedCircuitDesignObjects: [],
        allFieldsDisabled: false,
        massUpdateSelectedCircuitDesignIds: []
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItemsObjects = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.allFieldsDisabled
        );
        if (circuitItemsObjects.static.length > 0) {
            circuitItemsObjects.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.ATTRIBUTES.lightLevelTestingStatus]:
                        staticCircuitDesign[Constants.ATTRIBUTES.lightLevelTestingStatus] || "",
                    [Constants.ATTRIBUTES.portStatus]: "",
                    [Constants.ATTRIBUTES.lagStatus]: "",
                    [Constants.ATTRIBUTES.implementationStatus]:
                        staticCircuitDesign[Constants.ATTRIBUTES.implementationStatus] || ""
                }));
        }
        if (circuitItemsObjects.dynamic.length > 0) {
            circuitItemsObjects.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    [Constants.ATTRIBUTES.lightLevelTestingStatus]:
                        dynamicCircuitDesign[Constants.ATTRIBUTES.lightLevelTestingStatus] || "",
                    [Constants.ATTRIBUTES.portStatus]: "",
                    [Constants.ATTRIBUTES.lagStatus]: "",
                    [Constants.ATTRIBUTES.implementationStatus]:
                        dynamicCircuitDesign[Constants.ATTRIBUTES.implementationStatus] || ""
                }));
        }
        return circuitItemsObjects;
    };

    /**
     * Handle any edits to the order
     * @param evt
     */
    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        input.stageName = Constants.STAGE_NAMES.implementation;
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(this.state.massUpdateSelectedCircuitDesignIds,
            input, circuitDesignOutput.circuitDesignObjects);
        this.setState({ updatedCircuitDesignObjects: output });
    };

    /**
     * Handle clicking the edit button
     * @param evt
     */
    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            hasBeenSubmittedOnce: false,
            updatedCircuitDesignObjects: HelperFunctions.deepClone(this.props.circuitDesignObjects),
            allFieldsDisabled: false,
            isUpdateStageInProgress: false
        });
    };

    handleSelectedFromTable = (evt) => {
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });
        const circuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        // Only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
        const circuitsToUpdate = HelperFunctions.createNewApiObjectsCircuitWrapperForStage(
            circuitDesignObjects, updatedCircuitDesignObjects
        );
        try {
            if (circuitsToUpdate.length > 0) {
                // update the circuits and wait for a response
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.implementation}
                    showAttachmentModal={false}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    content={
                        <div>
                            <TableDisplayMode
                                order={this.props.order}
                                stageName={Constants.STAGE_NAMES.implementation}
                                circuitItems={this.generateCircuitItems().static}
                                columnDefinitions={generateImplementationStageColumnDefinitions(
                                    this.props.order.lacpProvider, this.generateCircuitItems().static.length,
                                    this.props.order.serviceType
                                )}
                                isDataLoaded={this.props.isDataLoaded}
                            />
                        </div>
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.implementation}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <TableEditMode
                            circuitItems={this.generateCircuitItems().dynamic}
                            columnDefinitions={generateImplementationStageColumnDefinitions(
                                this.props.order.lacpProvider, this.generateCircuitItems().static.length,
                                this.props.order.serviceType
                            )}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
        );
    }
}

export default ImplementationHandler;