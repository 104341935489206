import React, { Component } from "react";
import { Flashbar } from "@amzn/awsui-components-react/polaris";
import { FremontAlert } from "utils/CommonComponents";
import Constants from "utils/Constants";
import ContactForm from "contact/ContactForm";
import ContactValidation from "contact/ContactValidation";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";

/**
 * CreateContactPage renders the page holding the contact creation Form
*/
export default class CreateContactPage extends Component {
    /**
     *  Initially set the contactInfo and providerNames to mimic what they would be if they were
     *  inputted as blank (since they are blank on page load). They will later be updated by user inputs.
     */
    state = {
        contact: {
            [Constants.ATTRIBUTES.providerNameList]: [this.props.match ? this.props.match.params.providerName : ""],
            firstName: "",
            lastName: "",
            billingContact: false,
            internal: false,
            role: "",
            email: "",
            providerServiceIdList: [],
            [Constants.ATTRIBUTES.siteMap]: this.props.match ? {
                [this.props.match.params.providerName]: {
                    [Constants.ATTRIBUTES.regionsSupported]: [],
                    [Constants.ATTRIBUTES.countriesSupported]: []
                }
            } : {},
            status: Constants.STATUS.active
        },
        serviceOptions: {},
        allFieldsDisabled: false,
        hasSubmittedOnce: false,
        isSubmissionInProgress: false,
        showContactAlertModal: false,
        providerInfoLoading: false,
        flashbar: {
            type: "",
            text: ""
        },
        contactErrorTexts: ContactValidation.DEFAULT_CONTACT_ERROR_TEXTS,
        isDuplicate: false,
        duplicateContactDisable: false,
        existingContacts: []
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError));
        } else if (this.props.match) {
            const contact = HelperFunctions.deepClone(this.state.contact);
            const contactErrorTexts = HelperFunctions.deepClone(this.state.contactErrorTexts);
            contactErrorTexts.providerNameList = "";
            // else (if the provider exist in Fremont) we need to load the new provider names data
            this.setState({
                contactErrorTexts,
                providerInfoLoading: true
            });
            await this.loadProvider(this.props.match.params.providerName);
            const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
            // Here we assign values to the contact and set up the correct selected service
            // options if one was provided
            if (this.props.match.params.providerServiceId) {
                contact.providerServiceIdList.push(this.props.match.params.providerServiceId);
                serviceOptions[this.props.match.params.providerName].selectedOptions.push(
                    {
                        label: this.props.match.params.serviceType,
                        value: this.props.match.params.providerServiceId
                    }
                );
            }
            this.setState({
                contact,
                providerInfoLoading: false,
                serviceOptions
            });
        }
    };

    /**
     * This function obtains all providerService items and asn objects associated with a provider
     */
    getContactsForProvider = async (providerResponse) => {
        try {
            const batchProviderContactResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.CONTACT, providerResponse.provider.contactIdList, this.props.auth
            );

            this.setState({
                existingContacts: batchProviderContactResponse.contacts
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { providerInfoLoading: false });
        }
    };

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method obtains the sites and providerServices for all the given providers
     * Creates an object to hold the data and the necessary select options arrays
     */
    loadProvider = async (providerName) => {
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        try {
            // Get the provider object
            const providerResponse = await this.FremontBackendClient.getProviderInfo(providerName, this.props.auth);

            await this.getContactsForProvider(providerResponse);

            // Set up all of the necessary data associated with the provider object
            serviceOptions[providerName] = await ContactValidation.setupServiceOptions(
                providerResponse.provider, this.props.auth, this.FremontBackendClient
            );
            this.setState({
                serviceOptions
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error, { loading: false });
        }
    };

    isDuplicateContact = () => {
        const isDuplicate = this.state.existingContacts.some(contact =>
            (contact.firstName.toLowerCase() === this.state.contact.firstName.toLowerCase()
            && contact.lastName.toLowerCase() === this.state.contact.lastName.toLowerCase()));
        this.setState({
            isDuplicate,
            duplicateContactDisable: isDuplicate
        });
    }

    /**
     This function handles change of inputs to the contact information container fields.
     */
    handleContactInputChange = async (evt) => {
        const output = ContactValidation.contactInputChange(
            evt,
            this.state.contact,
            this.state.contactErrorTexts
        );

        this.setState({
            contact: output.contact,
            contactErrorTexts: output.contactErrorTexts
        });

        this.isDuplicateContact();
    };

    /**
     * This function handles changing a provider name
     */
    handleContactProviderChange = async (evt) => {
        // The assumption here is that we are only able to change the last field, since subsequently filled
        // provider boxes are disabled.
        const contact = HelperFunctions.deepClone(this.state.contact);
        const contactErrorTexts = HelperFunctions.deepClone(this.state.contactErrorTexts);

        const newProviderName = evt.detail.selectedOption.value;

        if (!newProviderName) {
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = Constants.ERROR_STRINGS.blankProviderErrorText;
        } else {
            // If we have a non-blank valid provider, load the data
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = "";
            this.setState({
                providerInfoLoading: true
            });
            await this.loadProvider(newProviderName);
            this.setState({
                providerInfoLoading: false
            });
        }

        contact[Constants.ATTRIBUTES.providerNameList][contact[Constants.ATTRIBUTES.providerNameList].length - 1]
            = newProviderName;


        contact[Constants.ATTRIBUTES.providerNameList].filter(providerName => providerName).forEach((providerName) => {
            if (!Object.prototype.hasOwnProperty.call(contact[Constants.ATTRIBUTES.siteMap], providerName)) {
                contact[Constants.ATTRIBUTES.siteMap][providerName] = {
                    [Constants.ATTRIBUTES.regionsSupported]: [],
                    [Constants.ATTRIBUTES.countriesSupported]: []
                };
            }
        });

        // if provider in siteMap but not in providerNameList, remove it from siteMap
        Object.keys(contact[Constants.ATTRIBUTES.siteMap]).forEach((providerName) => {
            if (!contact[Constants.ATTRIBUTES.providerNameList].some(contactListProviderName =>
                providerName === contactListProviderName)) {
                delete contact[Constants.ATTRIBUTES.siteMap][providerName];
            }
        });
        // if provider in serviceOptions but not in providerNameList, remove it from serviceOptions
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        Object.keys(serviceOptions).forEach((providerName) => {
            if (!contact[Constants.ATTRIBUTES.providerNameList].some(contactListProviderName =>
                providerName === contactListProviderName)) {
                delete serviceOptions[providerName];
            }
        });

        this.setState({
            contact,
            contactErrorTexts,
            serviceOptions
        });
    };

    /**
     * Handles toggling whether the contact is internal or external
     * @param evt
     */
    handleChangeInternal = (evt) => {
        // Only show modal if there are multiple provider names entered
        // and the user is changing internal to false
        if (this.state.contact.providerNameList.length > 1
            && evt.detail.selectedOption.value === false) {
            this.setState({
                showContactAlertModal: true
            });
        } else {
            const contact = HelperFunctions.deepClone(this.state.contact);
            contact.internal = evt.detail.selectedOption.value;
            this.setState({
                contact
            });
        }
    };

    /**
     * Handles users clicking okay on the modal
     */
    handleContactAlertOkay = () => {
        const contact = HelperFunctions.deepClone(this.state.contact);
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        contact.internal = false;
        while (contact.providerNameList.length > 1) {
            // pop the last providerName off providerNames and delete it from serviceOptions
            const providerName = contact.providerNameList.pop();
            delete serviceOptions[providerName];
            delete contact.siteMap[providerName];
        }
        this.setState({
            contact,
            serviceOptions,
            showContactAlertModal: false
        });
    };

    /**
     * Handles users clicking cancel on the modal
     */
    handleContactAlertCancel = () => {
        this.setState({
            showContactAlertModal: false
        });
    };

    /**
     * Handles changes to ProviderService selections since that is part of serviceOptions
     * and we don't want validation to change serviceOptions
     */
    handleServiceOptionsChange = (evt) => {
        const serviceOptions = ContactValidation.serviceOptionsChange(
            evt, HelperFunctions.deepClone(this.state.serviceOptions)
        );
        this.setState({
            serviceOptions
        });
    };

    /**
     Handles adding new input fields for the providerNames field
     */
    handleAddAdditionalProviderInput = () => {
        const contact = HelperFunctions.deepClone(this.state.contact);
        const contactErrorTexts = HelperFunctions.deepClone(this.state.contactErrorTexts);
        contact.providerNameList.push("");
        contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = Constants.ERROR_STRINGS.blankProviderErrorText;
        this.setState({
            contact,
            contactErrorTexts
        });
    };

    /**
     * Handles removing a certain provider from the provider list
     * @param evt
     */
    handleSubtractSpecificProviderName = (evt) => {
        const contact = HelperFunctions.deepClone(this.state.contact);
        const serviceOptions = HelperFunctions.deepClone(this.state.serviceOptions);
        const contactErrorTexts = HelperFunctions.deepClone(this.state.contactErrorTexts);
        const providerIndex = contact.providerNameList.findIndex(providerName =>
            providerName === evt.target.className);
        delete serviceOptions[contact.providerNameList[providerIndex]];
        delete contact.siteMap[contact.providerNameList[providerIndex]];
        contact.providerNameList.splice(providerIndex, 1);
        if (contact.providerNameList.some(providerName => !providerName)) {
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = Constants.ERROR_STRINGS.blankProviderErrorText;
        } else {
            contactErrorTexts[Constants.ATTRIBUTES.providerNameList] = "";
        }
        this.setState({
            contact,
            serviceOptions,
            contactErrorTexts
        });
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    /**
     This function determines whether or not the Add Additional Provider button should be enabled or not
     */
    disableAddProviderButton = () =>
        // if last provider name is empty
        !this.state.contact.providerNameList[this.state.contact.providerNameList.length - 1]
        || this.state.providerInfoLoading
        || this.doNotCreateProviderIfProviderIsPassedAsAParameter();

    /**
     * Do not allow a Provider to be added if we are creating
     * a contact for a given Provider. This happens when
     * we navigate to the create contact page from a
     * Provider details page.
     * @returns boolean
     */
    doNotCreateProviderIfProviderIsPassedAsAParameter = () =>
        !!this.props.providerOptions
        && this.state.contact.providerNameList.length === this.props.providerOptions.length;

    /**
     * Send contact info to backend when the submit button is clicked
     */
    handleContactSubmit = async (evt) => {
        HelperFunctions.dismissFlashbar(this, { isSubmissionInProgress: true, allFieldsDisabled: true });

        // Check to see if any of errors are present. If so abort the submission and display error text
        if (Object.values(this.state.contactErrorTexts).some(errorText => !!errorText)) {
            HelperFunctions.displayFlashbarError(
                this,
                new Error(Constants.FLASHBAR_STRINGS.flashbarInvalidInput),
                {
                    hasSubmittedOnce: true,
                    isSubmissionInProgress: false,
                    allFieldsDisabled: false
                }
            );
            return;
        }

        const contactToSubmit = ContactValidation.createContactRequest(
            this.state.contact,
            this.state.serviceOptions
        );

        try {
            const response = await this.FremontBackendClient.createContact(contactToSubmit, this.props.auth);
            if (!this.props.isModal) {
                this.props.history.push(`${Constants.ROUTES.contact}/${response.contactId}`);
            } else {
                // This is used for showing the flashbar success message on the order create page
                this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                    false, false);
                this.props.handleContactModalClick(evt);
            }
        } catch (error) {
            HelperFunctions.displayFlashbarError(
                this,
                error,
                {
                    isSubmissionInProgress: false,
                    allFieldsDisabled: false
                }
            );
        }
    };

    render() {
        return (
            <div>
                {this.props.isModal ?
                    <Flashbar
                        items={this.state.flashbar.text ?
                            HelperFunctions.generateErrorMessageForFlashbar(this.state.flashbar.text) : []}
                    />
                    : <FremontHeader
                        history={this.props.history}
                        flashbarType={this.state.flashbar.type}
                        flashbarText={this.state.flashbar.text}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                }
                {this.state.isDuplicate &&
                    <FremontAlert
                        dismissible
                        type="error"
                    >
                        Potential duplicate contact. Contact with this name already exists for this provider in Fremont!
                    </FremontAlert>
                }
                <ContactForm
                    disableAddProviderButton={this.disableAddProviderButton()}
                    hasSubmittedOnce={this.state.hasSubmittedOnce}
                    isSubmissionInProgress={this.state.isSubmissionInProgress}
                    contact={this.state.contact}
                    handleContactSubmit={this.handleContactSubmit}
                    handleContactInputChange={this.handleContactInputChange}
                    handleContactProviderChange={this.handleContactProviderChange}
                    handleAddressInputChange={this.handleAddressInputChange}
                    contactErrorTexts={this.state.hasSubmittedOnce ?
                        this.state.contactErrorTexts : ContactValidation.EMPTY_CONTACT_ERROR_TEXTS}
                    handleAddAdditionalProviderInput={this.handleAddAdditionalProviderInput}
                    handleSubtractSpecificProviderName={this.handleSubtractSpecificProviderName}
                    serviceOptions={this.state.serviceOptions}
                    handleServiceOptionsChange={this.handleServiceOptionsChange}
                    handleAddAll={this.handleAddAll}
                    providerInfoLoading={this.state.providerInfoLoading}
                    providerOptions={this.props.providerOptions}
                    providersLoading={this.props.providersLoading}
                    allFieldsDisabled={this.state.allFieldsDisabled}
                    showContactAlertModal={this.state.showContactAlertModal}
                    handleContactAlertOkay={this.handleContactAlertOkay}
                    handleContactAlertCancel={this.handleContactAlertCancel}
                    handleChangeInternal={this.handleChangeInternal}
                    isModal={this.props.isModal}
                    handleContactModalClick={this.props.handleContactModalClick}
                    duplicateContactDisable={this.state.duplicateContactDisable}
                    providerName={this.props.match ? this.props.match.params.providerName : null}
                />
            </div>
        );
    }
}
