import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Link,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import FremontComponentInfo from "common/FremontComponentInfo";
import HelperFunctions from "common/HelperFunctions";
import ProviderServiceValidation from "providerService/ProviderServiceValidation";
import {
    optionalLabel,
    requiredLabel,
    ProviderSelectComponent,
    FremontButton,
    FremontExpandableSection,
    FremontInput,
    FremontSelect,
    ComponentConstants
} from "utils/CommonComponents";

/**
 * This component serves as the main container holding all of the providerService information
 */
const ProviderServiceInformation = props => (
    <ProviderServiceInfoContainer
        providerService={props.providerService}
        updatedProviderService={props.updatedProviderService}
        isUpdateProviderServiceInfoEditClicked={props.isUpdateProviderServiceInfoEditClicked}
        isUpdateProviderServiceInfoInProgress={props.isUpdateProviderServiceInfoInProgress}
        handleUpdateProviderServiceEdit={props.handleUpdateProviderServiceEdit}
        handleUpdateProviderServiceInputChange={props.handleUpdateProviderServiceInputChange}
        handleUpdateProviderServiceSubmit={props.handleUpdateProviderServiceSubmit}
        addEscalationPathButtonDisabled={props.addEscalationPathButtonDisabled}
        addContactButtonDisabled={props.addContactButtonDisabled}
        handleAdditionalInput={props.handleAdditionalInput}
        handleSubtractSpecificObject={props.handleSubtractSpecificObject}
        hasUpdateProviderServiceSubmittedOnce={props.hasUpdateProviderServiceSubmittedOnce}
        providerServiceErrorTexts={props.providerServiceErrorTexts}
        escalationPathObjects={props.escalationPathObjects}
        escalationPathObjectsShallowCopy={props.escalationPathObjectsShallowCopy}
        escalationPathItems={props.escalationPathItems}
        contactsLoading={props.contactsLoading}
        contactOptions={props.contactOptions}
        contactObjectsShallowCopy={props.contactObjectsShallowCopy}
    />
);

/**
 * This component holds the providerService display and edit modes
 */
export const ProviderServiceInfoContainer = (props) => {
    if (props.isUpdateProviderServiceInfoEditClicked) {
        return (
            <ProviderServiceInfoEditMode
                isUpdateProviderServiceInfoInProgress={props.isUpdateProviderServiceInfoInProgress}
                updatedProviderService={props.updatedProviderService}
                providerServiceErrorTexts={props.providerServiceErrorTexts}
                hasUpdateProviderServiceSubmittedOnce={props.hasUpdateProviderServiceSubmittedOnce}
                handleUpdateProviderServiceEdit={props.handleUpdateProviderServiceEdit}
                handleUpdateProviderServiceInputChange={props.handleUpdateProviderServiceInputChange}
                handleUpdateProviderServiceSubmit={props.handleUpdateProviderServiceSubmit}
                addEscalationPathButtonDisabled={props.addEscalationPathButtonDisabled}
                addContactButtonDisabled={props.addContactButtonDisabled}
                handleAdditionalInput={props.handleAdditionalInput}
                handleSubtractSpecificObject={props.handleSubtractSpecificObject}
                escalationPathObjectsShallowCopy={props.escalationPathObjectsShallowCopy}
                contactObjectsShallowCopy={props.contactObjectsShallowCopy}
                escalationPathObjects={props.escalationPathObjects}
                contactsLoading={props.contactsLoading}
                contactOptions={props.contactOptions}
            />
        );
    }
    return (
        // Before passing the escalationPathObjects to the display page, we filter out any escalationPathObjects
        // that do not have a value
        <ProviderServiceInfoDisplayMode
            handleUpdateProviderServiceEdit={props.handleUpdateProviderServiceEdit}
            providerService={props.providerService}
            escalationPathItems={props.escalationPathItems}
        />
    );
};

/**
 * This component displays the providerService info
 */
export const ProviderServiceInfoDisplayMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <FremontButton
                        id="editProviderServiceInfoButton"
                        iconName="edit"
                        variant="primary"
                        onClick={props.handleUpdateProviderServiceEdit}
                    />
                }
            >
                Service Information
            </Header>
        }
    >
        <SpaceBetween size="l">
            <Container
                header={
                    <Header variant="h2">
                        General Information
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Provider:</Box>
                            <div>
                                <Link href={`${Constants.ROUTES.provider}/${props.providerService.providerName}`}>
                                    {props.providerService.providerName}
                                </Link>
                            </div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Status:</Box>
                            <div>{props.providerService.status}</div>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Service Type:</Box>
                            <div>{props.providerService.serviceType}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Odin Credentials:</Box>
                            <div>{props.providerService.odinCredentials || "-"}</div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header variant="h2">
                        Contact Information
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Phone Number:</Box>
                            <div>{props.providerService.phone || "-"}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Email Address:</Box>
                            <div>{props.providerService.email || "-"}</div>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Fax Number:</Box>
                            <div>{props.providerService.fax || "-"}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Website:</Box>
                            <div>{props.providerService.website || "-"}</div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <FremontExpandableSection
                variant="container"
                header={
                    <Header variant="h2">
                        Escalation Path
                    </Header>
                }
            >
                <Box variant="p">
                    If you are attempting to escalate with this network please also check Provider Notes
                    or Provider Attachments for any additional information.
                </Box>
                <Table
                    columnDefinitions={ProviderServiceValidation.ESCALATION_PATH_COLUMN_DEFINITIONS}
                    items={props.escalationPathItems}
                    empty={
                        <Box textAlign="center">
                            <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                <b>
                                    Nothing to display.
                                </b>
                            </Box>
                        </Box>
                    }
                />
            </FremontExpandableSection>
        </SpaceBetween>
        <FremontComponentInfo
            modifiedBy={props.providerService.modifiedBy}
            modifiedTime={props.providerService.modifiedTime}
            createdBy={props.providerService.createdBy}
            createdTime={props.providerService.createdTime}
        />
    </Container>
);

/**
 * This handles what gets rendered in the escalation path selection section of the provider service update activity
 */
export const ProviderServicePageEscalationPathField = props => (
    <div>
        { props.escalationPathObjectsShallowCopy.map(escalationPathObject => (
            <div key={escalationPathObject.id}>
                <ColumnLayout columns={3} borders="none">
                    <FormField
                        label="Contact Name:"
                        errorText={props.hasUpdateProviderServiceSubmittedOnce ?
                            escalationPathObject.contactNameErrorText : ""}
                    >
                        <FremontSelect
                            id={escalationPathObject.id}
                            selectedOption={escalationPathObject.selectedOption}
                            placeholder={!props.contactsLoading && props.contactOptions.length === 0 ?
                                Constants.PLACEHOLDER_TEXTS.noContactsForProvider : ""}
                            statusType={props.contactsLoading ? Constants.LOADING : Constants.FINISHED}
                            loadingText={Constants.STATUS_TEXTS.loadingContacts}
                            disabled={props.isUpdateProviderServiceInfoInProgress ||
                            (props.contactOptions.length === 0 && !props.contactsLoading)}
                            // Limiting the available options to only the escalationPathObjects
                            // that have not been chosen yet
                            options={HelperFunctions.sortContactsByField(props.contactOptions.filter(contactOption =>
                                !props.escalationPathObjectsShallowCopy.map(escalationPath =>
                                    escalationPath.selectedOption.value).includes(contactOption.value)), "label")}
                            onChange={props.handleUpdateProviderServiceInputChange}
                            filteringType="auto"
                            noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                        />
                    </FormField>
                    <FormField
                        label="Escalation Level:"
                        errorText={props.hasUpdateProviderServiceSubmittedOnce ?
                            escalationPathObject.levelErrorText : ""}
                    >
                        <div>
                            <ColumnLayout columns={2}>
                                <FremontSelect
                                    id={`${escalationPathObject.id} ${ProviderServiceValidation.ESCALATION_LEVEL}`}
                                    disabled={props.isUpdateProviderServiceInfoInProgress ||
                                    (props.contactOptions.length === 0 && !props.contactsLoading)}
                                    options={ProviderServiceValidation.LEVEL_OPTIONS}
                                    selectedOption={
                                        HelperFunctions.createSelectedOption(escalationPathObject.level)}
                                    onChange={props.handleUpdateProviderServiceInputChange}
                                />
                                <FremontButton
                                    id={escalationPathObject.id}
                                    iconName="close"
                                    variant="normal"
                                    onClick={props.handleSubtractSpecificObject}
                                    disabled={props.isUpdateProviderServiceInfoInProgress
                                    || props.escalationPathObjectsShallowCopy.length <= 1}
                                />
                            </ColumnLayout>
                        </div>
                    </FormField>
                    <FormField
                        label="Priority:"
                    >
                        <FremontSelect
                            id={`${escalationPathObject.id} ${ProviderServiceValidation.ESCALATION_PRIORITY}`}
                            disabled={props.isUpdateProviderServiceInfoInProgress ||
                                (props.contactOptions.length === 0 && !props.contactsLoading)}
                            options={ProviderServiceValidation.PRIORITY_OPTIONS}
                            selectedOption={
                                HelperFunctions.createSelectedOption(escalationPathObject.priority)}
                            onChange={props.handleUpdateProviderServiceInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        ))}
        <Box padding={{ top: "xs" }}>
            <FremontButton
                id={ProviderServiceValidation.BUTTON_NAMES.addEscalationPathButton}
                iconName="add-plus"
                variant="normal"
                onClick={props.handleAdditionalInput}
                disabled={props.isUpdateProviderServiceInfoInProgress ||
                props.addEscalationPathButtonDisabled}
            />
        </Box>
    </div>
);

/**
 * This component allows the user to edit providerService information
 */
export const ProviderServiceInfoEditMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            disabled={props.isUpdateProviderServiceInfoInProgress}
                            variant="normal"
                            onClick={props.handleUpdateProviderServiceEdit}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id="submitProviderServiceInfoButton"
                            loading={props.isUpdateProviderServiceInfoInProgress}
                            variant="primary"
                            onClick={props.handleUpdateProviderServiceSubmit}
                        >
                            {props.isUpdateProviderServiceInfoInProgress ? "Submitting..." : "Submit"}
                        </FremontButton>
                    </SpaceBetween>
                }
            >
                Service Information
            </Header>
        }
    >
        <SpaceBetween size="l">
            <Container
                header={
                    <Header variant="h2">
                        General Information
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    {/* As of right now, this is a largely read-only Provider field */}
                    <ProviderSelectComponent
                        id="providerName"
                        label={
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                {requiredLabel("Provider:")}
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.providerNameEditExplanation)
                                }
                            </SpaceBetween>
                        }
                        selectedOption={props.updatedProviderService.providerName}
                        disabled
                        onInput={props.handleUpdateProviderServiceInputChange}
                    />
                    <FormField
                        label={
                            <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                                {requiredLabel("Service Type:")}
                                {
                                    HelperFunctions.renderCustomTooltip(null,
                                        Constants.TOOLTIP_STRINGS.serviceEditExplanation)
                                }
                            </SpaceBetween>
                        }
                        errorText={props.providerServiceErrorTexts.serviceType}
                    >
                        <FremontSelect
                            id="serviceType"
                            disabled
                            options={HelperFunctions.createSelectedOptions(
                                Object.values(Constants.SERVICE_TYPES)
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedProviderService.serviceType
                            )}
                            onChange={props.handleUpdateProviderServiceInputChange}
                            value={props.updatedProviderService.serviceType}
                        />
                    </FormField>
                    <FormField
                        label={requiredLabel("Status:")}
                        errorText={props.providerServiceErrorTexts.status}
                    >
                        <FremontSelect
                            id="status"
                            disabled={props.isUpdateProviderServiceInfoInProgress}
                            options={Constants.GENERAL_STATUS_OPTIONS}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.updatedProviderService.status
                            )}
                            onChange={props.handleUpdateProviderServiceInputChange}
                            value={props.updatedProviderService.status}
                        />
                    </FormField>
                    <FormField
                        label={optionalLabel("Odin Credentials:")}
                        errorText={props.providerServiceErrorTexts.odinCredentials}
                    >
                        <FremontInput
                            id="odinCredentials"
                            value={props.updatedProviderService.odinCredentials}
                            disabled={props.isUpdateProviderServiceInfoInProgress}
                            onInput={props.handleUpdateProviderServiceInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header variant="h2">
                        Contact Information
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        label="Phone Number:"
                        errorText={props.providerServiceErrorTexts.phone}
                    >
                        <FremontInput
                            id="phone"
                            value={props.updatedProviderService.phone}
                            disabled={props.isUpdateProviderServiceInfoInProgress}
                            onInput={props.handleUpdateProviderServiceInputChange}
                        />
                    </FormField>
                    <FormField
                        label="Fax Number:"
                        errorText={props.providerServiceErrorTexts.fax}
                    >
                        <FremontInput
                            id="fax"
                            value={props.updatedProviderService.fax}
                            disabled={props.isUpdateProviderServiceInfoInProgress}
                            onInput={props.handleUpdateProviderServiceInputChange}
                        />
                    </FormField>
                    <FormField
                        label="Email Address:"
                        errorText={props.providerServiceErrorTexts.email}
                    >
                        <FremontInput
                            id="email"
                            value={props.updatedProviderService.email}
                            disabled={props.isUpdateProviderServiceInfoInProgress}
                            onInput={props.handleUpdateProviderServiceInputChange}
                        />
                    </FormField>
                    <FormField
                        label="Website:"
                        errorText={props.providerServiceErrorTexts.website}
                    >
                        <FremontInput
                            id="website"
                            value={props.updatedProviderService.website}
                            disabled={props.isUpdateProviderServiceInfoInProgress}
                            onInput={props.handleUpdateProviderServiceInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header
                        variant="h2"
                        description={"For each contact, select a level of escalation with" +
                        " one being the lowest and six being the highest."}
                    >
                        {optionalLabel("Escalation Path")}
                    </Header>
                }
            >
                <ColumnLayout columns={2}>
                    <ProviderServicePageEscalationPathField
                        addEscalationPathButtonDisabled={props.addEscalationPathButtonDisabled}
                        handleAdditionalInput={props.handleAdditionalInput}
                        handleSubtractSpecificObject={props.handleSubtractSpecificObject}
                        handleUpdateProviderServiceInputChange={
                            props.handleUpdateProviderServiceInputChange
                        }
                        isUpdateProviderServiceInfoInProgress={
                            props.isUpdateProviderServiceInfoInProgress
                        }
                        hasUpdateProviderServiceSubmittedOnce={
                            props.hasUpdateProviderServiceSubmittedOnce
                        }
                        escalationPathObjectsShallowCopy={props.escalationPathObjectsShallowCopy}
                        contactsLoading={props.contactsLoading}
                        contactOptions={props.contactOptions}
                    />
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header variant="h2">
                        {optionalLabel("Associated Contact(s)")}
                    </Header>
                }
            >
                <ColumnLayout columns={2}>
                    <FormField
                        label="Contact Name:"
                        description={"Select contacts associated with this provider" +
                        " service (contacts can also be created and added later)."}
                    >
                        <ProviderServicePageAdditionalContactInputs
                            addContactButtonDisabled={props.addContactButtonDisabled}
                            handleAdditionalInput={props.handleAdditionalInput}
                            handleSubtractSpecificObject={props.handleSubtractSpecificObject}
                            handleUpdateProviderServiceInputChange={
                                props.handleUpdateProviderServiceInputChange
                            }
                            isUpdateProviderServiceInfoInProgress={
                                props.isUpdateProviderServiceInfoInProgress
                            }
                            hasUpdateProviderServiceSubmittedOnce={
                                props.hasUpdateProviderServiceSubmittedOnce
                            }
                            contactObjectsShallowCopy={props.contactObjectsShallowCopy}
                            contactsLoading={props.contactsLoading}
                            contactOptions={props.contactOptions}
                        />
                    </FormField>
                </ColumnLayout>
            </Container>
        </SpaceBetween>
    </Container>
);

/**
 * This handles what gets rendered in the contact selection section of the providerService form
 */
export const ProviderServicePageAdditionalContactInputs = props => (
    <div>
        { props.contactObjectsShallowCopy.map(contactObject => (
            <div key={contactObject.id}>
                <FormField
                    errorText={props.hasUpdateProviderServiceSubmittedOnce ? contactObject.errorText : ""}
                >
                    <ColumnLayout columns={2}>
                        <FremontSelect
                            id={contactObject.id}
                            placeholder={!props.contactsLoading && props.contactOptions.length === 0 ?
                                Constants.PLACEHOLDER_TEXTS.noContactsForProvider : ""}
                            statusType={props.contactsLoading ? Constants.LOADING : Constants.FINISHED}
                            loadingText={Constants.STATUS_TEXTS.loadingContacts}
                            disabled={props.isUpdateProviderServiceInfoInProgress ||
                            (props.contactOptions.length === 0 && !props.contactsLoading)}
                            selectedOption={contactObject.selectedOption}
                            options={props.contactOptions.filter(contactOption =>
                                !props.contactObjectsShallowCopy.map(contactObj =>
                                    contactObj.selectedOption.value).includes(contactOption.value))}
                            onChange={props.handleUpdateProviderServiceInputChange}
                            filteringType="auto"
                            noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                        />
                        <FremontButton
                            id={contactObject.id}
                            iconName="close"
                            variant="normal"
                            onClick={props.handleSubtractSpecificObject}
                            disabled={props.isUpdateProviderServiceInfoInProgress
                            || props.contactObjectsShallowCopy.length <= 1}
                        />
                    </ColumnLayout>
                </FormField>
            </div>
        ))}
        <Box padding={{ top: "xs" }}>
            <FremontButton
                id={ProviderServiceValidation.BUTTON_NAMES.addContactButton}
                iconName="add-plus"
                variant="normal"
                onClick={props.handleAdditionalInput}
                disabled={props.isUpdateProviderServiceInfoInProgress || props.addContactButtonDisabled}
            />
        </Box>
    </div>
);

export default ProviderServiceInformation;