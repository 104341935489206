import React, { useEffect } from "react";
import * as d3 from "d3";
import VisualizationHelperFunctions from "./VisualizationHelperFunctions";
import LinkVisualConstants from "./LinkVisualConstants";

function LinkVisual(props) {
    const {
        svgRef, linkData, isTopDown, expandAll
    } = props;

    function collapse(d) {
        if (d.children) {
            // eslint-disable-next-line no-param-reassign
            d.previousChildren = d.children;
            d.previousChildren.forEach(collapse);
            // eslint-disable-next-line no-param-reassign
            d.children = null;
        }
    }
    function expand(d) {
        if (d.previousChildren) {
            // eslint-disable-next-line no-param-reassign
            d.children = d.previousChildren;
            // eslint-disable-next-line no-param-reassign
            d.previousChildren = null;
        }
        const children = (d.children) ? d.children : d.previousChildren;
        if (children) { children.forEach(expand); }
    }

    function addRectToNode(selection, offsetX = 0) {
        selection.append("rect")
            .attr("class", "node")
            .attr("width", LinkVisualConstants.nodeSize)
            .attr("height", LinkVisualConstants.nodeSize)
            .style("fill", d => LinkVisualConstants.linkTypeColorMap[d.data.linkType])
            .attr("transform", `translate(${offsetX},0)`);
    }

    function addLinkToNode(selection) {
        selection
            .append("line")
            .attr("class", "link-line")
            .attr("x1", LinkVisualConstants.nodeSize)
            .attr("y1", LinkVisualConstants.nodeSize / 2)
            .attr("x2", LinkVisualConstants.linkLineDistance)
            .attr("y2", LinkVisualConstants.nodeSize / 2)
            .attr("stroke-dasharray", (d) => {
                if (d.data.linkType === "PassiveToPassive") {
                    return "none";
                }
                return "10 3";
            });
    }

    function addTextToNode(selection, textFunction, offsetX = 0) {
        selection.append("text")
            .attr("dx", LinkVisualConstants.nodeSize / 2)
            .attr("dy", LinkVisualConstants.nodeSize / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .text(textFunction)
            .attr("transform", `translate(${offsetX},0)`);
    }

    function handleLinks(svg, links, source, linkNum, isAEnd, offsetX = 0) {
        const link = svg.selectAll(`path.link${linkNum}`)
            .data(links, d => d.id);

        const linkEnter = link.enter().insert("path", "g")
            .attr("class", `link${linkNum}`)
            .attr("d", (d) => {
                if (VisualizationHelperFunctions.shouldDrawLink(d, isAEnd)) {
                    const o = { x: source.x0, y: source.y0 };
                    return VisualizationHelperFunctions.diagonal(o, o, (LinkVisualConstants.nodeSize / 2) + offsetX,
                        LinkVisualConstants.nodeSize / 2);
                }
                return null;
            });
        const linkUpdate = linkEnter.merge(link)
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", "0.5px");
        linkUpdate.transition()
            .duration(LinkVisualConstants.durationOfToggle)
            .attr("d", (d) => {
                if (VisualizationHelperFunctions.shouldDrawLink(d, isAEnd)) {
                    return VisualizationHelperFunctions.diagonal(d, d.parent,
                        (LinkVisualConstants.nodeSize / 2) + offsetX,
                        LinkVisualConstants.nodeSize / 2);
                }
                return null;
            });
        // linkExit never explicitly called but is implicitly called when the .exit() selection is created
        // eslint-disable-next-line no-unused-vars
        const linkExit = link.exit().transition()
            .duration(LinkVisualConstants.durationOfToggle)
            .attr("d", (d) => {
                if (VisualizationHelperFunctions.shouldDrawLink(d, isAEnd)) {
                    const o = { x: source.x, y: source.y };
                    return VisualizationHelperFunctions.diagonal(o, o,
                        (LinkVisualConstants.nodeSize / 2) + offsetX, LinkVisualConstants.nodeSize / 2);
                }
                return null;
            })
            .remove();
    }

    const drawVisualization = () => {
        // eslint-disable-next-line no-use-before-define
        const zoom = d3.zoom().scaleExtent([0.5, 8]).on("zoom", zoomed);

        const svg = d3.select(svgRef.current)
            .attr("width", "100%")
            .attr("height", LinkVisualConstants.height)
            .call(zoom)
            .append("g")
            .attr("transform", `translate(${LinkVisualConstants.width / 2},0)`);

        function zoomed(event) {
            svg.attr("transform", `${event.transform}translate(${LinkVisualConstants.width / 2},0)`);
        }

        function toggleNode(d) {
            if (d.children) { // expanded state, hide the children
                // eslint-disable-next-line no-param-reassign
                d.previousChildren = d.children;
                // eslint-disable-next-line no-param-reassign
                d.children = null;
            } else { // collapsed state, show children
                // eslint-disable-next-line no-param-reassign
                d.children = d.previousChildren;
                // eslint-disable-next-line no-param-reassign
                d.previousChildren = null;
            }
            // eslint-disable-next-line no-use-before-define
            update(d);
        }

        let i = 0;
        let root;

        const treemap = d3.tree().size([LinkVisualConstants.height, LinkVisualConstants.width]).nodeSize(
            [LinkVisualConstants.xSpacing, LinkVisualConstants.ySpacing]
        );

        let initialRootY;
        if (!isTopDown) {
            initialRootY = LinkVisualConstants.height / 2;
        } else {
            initialRootY = 0;
        }

        if (isTopDown) {
            root = d3.hierarchy(linkData, d => d.children);
        } else {
            root = d3.hierarchy(linkData, d => d.getParents());
        }

        if (expandAll) {
            expand(root);
        } else {
            collapse(root);
        }


        function update(source) {
            const treeData = treemap(root);

            const nodes = treeData.descendants();
            const links = treeData.descendants().slice(1);

            nodes.forEach((d) => {
                if (isTopDown) {
                    // eslint-disable-next-line no-param-reassign
                    d.y = d.depth * LinkVisualConstants.verticalDistanceMultiplier;
                } else {
                    // eslint-disable-next-line no-param-reassign
                    d.y = initialRootY - (d.depth * LinkVisualConstants.verticalDistanceMultiplier);
                }
            });

            // Update the nodes
            const node = svg.selectAll("g.node")
                // eslint-disable-next-line no-return-assign,no-param-reassign,no-plusplus
                .data(nodes, d => d.id || (d.id = ++i));

            // Create pop-up that is hidden by default
            const tooltip = d3.select("body").append("div")
                .attr("class", "tooltip")
                .style("position", "absolute")
                .style("visibility", "hidden")
                .style("background", "rgb(255,255,255)")
                .style("color", "black")
                .style("padding", "8px")
                .style("border-radius", "4px")
                .style("font-family", "sans-serif");

            const nodeEnter = node.enter().append("g")
                .attr("class", "node")
                .attr("transform", `translate(${source.x0},${source.y0})`)
                .on("click", (event, d) => {
                    toggleNode(d);
                });

            nodeEnter.call(addRectToNode);
            nodeEnter.call(d => addRectToNode(d, LinkVisualConstants.linkLineDistance));

            nodeEnter.call(addLinkToNode);

            nodeEnter.call(addTextToNode, (d) => {
                if (!VisualizationHelperFunctions.getDCFromPort(d.data.aEndPort)) {
                    return "aEndPort";
                }
                return VisualizationHelperFunctions.getDCFromPort(d.data.aEndPort);
            });

            nodeEnter.call(addTextToNode, (d) => {
                if (!VisualizationHelperFunctions.getDCFromPort(d.data.bEndPort)) {
                    return "zEndPort";
                }
                return VisualizationHelperFunctions.getDCFromPort(d.data.bEndPort);
            }, LinkVisualConstants.linkLineDistance);

            nodeEnter
                .on("mouseover", (event, d) => {
                    tooltip.style("visibility", "visible");
                    tooltip.html(
                        `<strong>InstanceId:</strong> ${d.data.id}<br/>` +
                        `<strong>LinkType:</strong> ${d.data.linkType}<br/>` +
                        `<strong>A End DC:</strong> ${d.data.aEndPort}<br/>` +
                        `<strong>Z End DC:</strong> ${d.data.bEndPort}`
                    );
                })
                .on("mousemove", (event) => {
                    tooltip.style("top", `${event.pageY - 10}px`).style("left", `${event.pageX + 10}px`);
                })
                .on("mouseout", () => {
                    tooltip.style("visibility", "hidden");
                });

            const nodeUpdate = nodeEnter.merge(node)
                .attr("stroke", "black")
                .attr("stroke-width", "3px;")
                .style("font", "12px sans-serif");

            nodeUpdate.transition()
                .duration(LinkVisualConstants.durationOfToggle)
                .attr("transform", d => `translate(${d.x}, ${d.y})`);

            const nodeExit = node.exit().transition()
                .duration(LinkVisualConstants.durationOfToggle)
                .attr("transform", `translate(${source.x},${source.y})`)
                .remove();

            nodeExit.select("node")
                .remove();

            handleLinks(svg, links, source, 1, true);
            handleLinks(svg, links, source, 2, false, LinkVisualConstants.linkLineDistance);

            // Store the old positions for transition.
            nodes.forEach((d) => {
                // eslint-disable-next-line no-param-reassign
                d.x0 = d.x;
                // eslint-disable-next-line no-param-reassign
                d.y0 = d.y;
            });

            document.addEventListener("click", () => {
                tooltip.style("visibility", "hidden");
            });
        }
        update(root);
    };

    useEffect(() => {
        const svg = svgRef.current;
        while (svg.lastChild) {
            svg.removeChild(svg.lastChild);
        }
        drawVisualization(); // Redraw the visualization when 'isTopDown' or 'expandAll' prop changes
    }, [isTopDown, expandAll]);

    return (
        <svg ref={svgRef}/>
    );
}

export default LinkVisual;
