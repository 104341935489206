
import { AwsRum } from "aws-rum-web";
import HelperFunctions from "./HelperFunctions";


let awsRumMonitor;

// install and initialize cloudwatch RUM web client
// https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-configure-client.html
export default function initRum() {
    const config = HelperFunctions.getCloudwatchRumConfig();

    const rumConfig = {
        sessionSampleRate: config.sessionSampleRate,
        sessionEventLimit: config.sessionEventLimit,
        guestRoleArn: config.lighthouseGuestRoleArn,
        identityPoolId: config.lighthouseIdentityPoolId,
        endpoint: config.endpoint,
        telemetries: config.telemetries,
        allowCookies: config.allowCookies,
        enableXRay: config.enableXRay
    };
    awsRumMonitor = new AwsRum(
        config.lighthouseApplicationId,
        config.applicationVersion,
        config.applicationRegion,
        rumConfig
    );
    return awsRumMonitor;
}

export const recordCustomRumEvent = (eventType, event) => {
    try {
        if (awsRumMonitor) {
            awsRumMonitor.recordEvent(eventType, event);
        }
    } catch (error) {
        // Ignore Rum initialization errors and logging the error
        console.warn("Failed to record custom RUM event", error);
    }
};
