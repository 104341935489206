import React, { useState } from "react";
import FremontTable from "table/FremontTable";
import OrderTableData from "order/OrderTableData";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import OrderTablePropertyFilter from "./OrderTablePropertyFilter";

/**
 * This function returns an array consisting of all values of given object (including nested values).
 * This function has only been exported for ease of testing, and it should not be invoked from outside the module.
 */
export function getObjectValuesRecursive(object) {
    const result = [];
    Object.keys(object).forEach((key) => {
        // if object property is an object (nested object exists) recursively call the same function.
        if (typeof (object[key]) === "object" && object[key] != null) {
            result.push(...(getObjectValuesRecursive(object[key])));
        } else result.push(object[key]);
    });
    return result;
}

/**
 * This function returns true if given order satisfies the filter event object conditions. otherwise returns false.
 * This function has only been exported for ease of testing, and it should not be invoked from outside the module.
 */
export function getFilterObjectFlag(order, orderTableFilterEvent) {
    const { tokens } = orderTableFilterEvent;
    for (let index = 0; index < tokens.length; index += 1) {
        const { propertyKey, operator, value } = tokens[index];
        if (propertyKey === Constants.POLARIS_FILTER_PROPERTY_KEYS.ORDER_STATUS) {
            if (operator === Constants.POLARIS_FILTER_OPERATORS.equals
                && order.objectStatusColumnSearchable !== value
            ) {
                return false;
            } else if (operator === Constants.POLARIS_FILTER_OPERATORS.notEqual
                && order.objectStatusColumnSearchable === value
            ) {
                return false;
            }
        } else {
            // If we don't have specific property filter then else block is executed
            // which will do a generic text search on the order object
            const objectStatusColumnIconClone = order.objectStatusColumnIcon;
            const orderToSearchOn = order;
            // here we are deleting and reassigning the objectStatusColumnIcon property because
            // it is an object with functions which cannot be cloned.
            // therefore we are deleting the property before stringifying and then searching, and
            // then adding the property in the end
            delete orderToSearchOn.objectStatusColumnIcon;
            const isSearchTextIncludes = JSON.stringify(getObjectValuesRecursive(orderToSearchOn))
                .toLowerCase().includes(value.toLowerCase());
            orderToSearchOn.objectStatusColumnIcon = objectStatusColumnIconClone;
            if (!isSearchTextIncludes) {
                return false;
            }
        }
    }
    return true;
}

/**
 * This function returns filtered objects based on the filter event object.
 * This function has only been exported for ease of testing, and it should not be invoked from outside the module.
 */
export function getFilteredOrderTableItems(orders, orderTableFilterEvent) {
    const clonedOrders = HelperFunctions.addMetaAndStatus(orders, Constants.NCIS_ROUTES.order);
    if (clonedOrders && orderTableFilterEvent &&
        orderTableFilterEvent.tokens && orderTableFilterEvent.tokens.length) {
        return clonedOrders.filter(order => getFilterObjectFlag(order, orderTableFilterEvent));
    }
    return clonedOrders;
}

export default function OrderTable({
    items, providerName, loading, emptyTableMessage, nextToken, fetchAllOrderItems
}) {
    /**
     * Default value of the filter Event to be equal to status not cancelled
     */
    const [query, setQuery] = useState({
        operation: Constants.POLARIS_FILTER_LOGICAL_OPERATORS.and,
        tokens: [
            {
                propertyKey: Constants.POLARIS_FILTER_PROPERTY_KEYS.ORDER_STATUS,
                operator: Constants.POLARIS_FILTER_OPERATORS.notEqual,
                value: Constants.POLARIS_FILTER_VALUES.cancelled
            }
        ]
    });
    return (
        <FremontTable
            entity="Order"
            createEntityLink={OrderTableData.fetchCreateOrderLink(providerName)}
            columnDefinitions={OrderTableData.COLUMN_DEFINITIONS}
            tableItems={
                getFilteredOrderTableItems(items, query)
            }
            loading={loading}
            emptyTableMessage={emptyTableMessage}
            onNextPageClickHelper={{
                nextToken, fetch: async () => fetchAllOrderItems()
            }}
            nextToken={nextToken}
            filter={<OrderTablePropertyFilter
                setQuery={setQuery}
                query={query}
                tableItems={getFilteredOrderTableItems(items, query)}
            />}
        />
    );
}