import React from "react";
import {
    Tabs
} from "@amzn/awsui-components-react/polaris";
import BulkUpdateTab from "operations/BulkUpdateTab";
import MimicUserTab from "operations/MimicUserTab";

export const OperationsPageTabs = (props) => {
    const tabs = [
        {
            label: "Bulk Update",
            id: "bulkUpdateTab",
            content: <BulkUpdateTab
                auth={props.auth}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
            />
        },
        {
            label: "Mimic User",
            id: "mimicUserTab",
            content: <MimicUserTab
                auth={props.auth}
                handleFlashBarMessagesFromChildTabs={props.handleFlashBarMessagesFromChildTabs}
            />
        }
    ];
    return (
        <div className="col-l-12">
            <Tabs
                tabs={tabs}
                variant="default"
                activeTabId={props.activeTabId}
                onChange={props.handleTabChange}
            />
        </div>
    );
};

export default OperationsPageTabs;