import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween,
    Spinner,
    Table
} from "@amzn/awsui-components-react/polaris";
import Constants from "utils/Constants";
import ContactValidation from "contact/ContactValidation";
import {
    ContactFormAdditionalProviderInputs,
    ContactFormProviderServices,
    ContactFormSites,
    ContactAlertModal
} from "contact/ContactFormComponents";
import FremontComponentInfo from "common/FremontComponentInfo";
import HelperFunctions from "common/HelperFunctions";
import {
    ComponentConstants,
    optionalLabel,
    requiredLabel,
    FremontButton,
    FremontInput,
    FremontSelect,
    FremontTextarea
} from "utils/CommonComponents";

/**
 * This component serves as the main container holding all of contact information
 */
const ContactInformation = props => (
    <ContactInfoContainer
        contact={props.contact}
        updatedContact={props.updatedContact}
        disableAddProviderButton={props.disableAddProviderButton}
        siteItems={props.siteItems}
        providerServiceItems={props.providerServiceItems}
        isUpdateContactInfoEditClicked={props.isUpdateContactInfoEditClicked}
        handleUpdateContactEdit={props.handleUpdateContactEdit}
        handleContactInputChange={props.handleContactInputChange}
        handleContactSubmit={props.handleContactSubmit}
        handleContactProviderChange={props.handleContactProviderChange}
        handleAddressInputChange={props.handleAddressInputChange}
        handleAddAdditionalProviderInput={props.handleAddAdditionalProviderInput}
        handleSubtractAdditionalProviderInput={props.handleSubtractAdditionalProviderInput}
        handleSubtractSpecificProviderName={props.handleSubtractSpecificProviderName}
        serviceOptions={props.serviceOptions}
        providerInfoLoading={props.providerInfoLoading}
        providersLoading={props.providersLoading}
        providersDisabled={props.providersDisabled}
        providerOptions={props.providerOptions}
        handleSiteOptionsChange={props.handleSiteOptionsChange}
        handleServiceOptionsChange={props.handleServiceOptionsChange}
        isSubmissionInProgress={props.isSubmissionInProgress}
        hasSubmittedOnce={props.hasSubmittedOnce}
        contactErrorTexts={props.contactErrorTexts}
        handleChangeInternal={props.handleChangeInternal}
        showContactAlertModal={props.showContactAlertModal}
        handleContactAlertOkay={props.handleContactAlertOkay}
        handleContactAlertCancel={props.handleContactAlertCancel}
        auth={props.auth}
    />
);

/**
 * This component holds the contact display and edit modes
 */
export const ContactInfoContainer = (props) => {
    if (props.isUpdateContactInfoEditClicked) {
        return (
            <ContactInfoEditMode
                contact={props.contact}
                updatedContact={props.updatedContact}
                contactErrorTexts={props.contactErrorTexts}
                disableAddProviderButton={props.disableAddProviderButton}
                handleUpdateContactEdit={props.handleUpdateContactEdit}
                handleContactInputChange={props.handleContactInputChange}
                handleContactSubmit={props.handleContactSubmit}
                handleAddressInputChange={props.handleAddressInputChange}
                handleContactProviderChange={props.handleContactProviderChange}
                handleAddAdditionalProviderInput={props.handleAddAdditionalProviderInput}
                handleSubtractSpecificProviderName={props.handleSubtractSpecificProviderName}
                serviceOptions={props.serviceOptions}
                providerInfoLoading={props.providerInfoLoading}
                providersLoading={props.providersLoading}
                providersDisabled={props.providersDisabled}
                providerOptions={props.providerOptions}
                handleSiteOptionsChange={props.handleSiteOptionsChange}
                handleServiceOptionsChange={props.handleServiceOptionsChange}
                isSubmissionInProgress={props.isSubmissionInProgress}
                hasSubmittedOnce={props.hasSubmittedOnce}
                handleChangeInternal={props.handleChangeInternal}
                showContactAlertModal={props.showContactAlertModal}
                handleContactAlertOkay={props.handleContactAlertOkay}
                handleContactAlertCancel={props.handleContactAlertCancel}
            />
        );
    }
    return (
        <ContactInfoDisplayMode
            contact={props.contact}
            auth={props.auth}
            siteItems={props.siteItems}
            providerServiceItems={props.providerServiceItems}
            handleUpdateContactEdit={props.handleUpdateContactEdit}
        />
    );
};

/**
 * This component displays the contact info
 */
export const ContactInfoDisplayMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <FremontButton
                        id="editContactInfoButton"
                        iconName="edit"
                        variant="primary"
                        onClick={props.handleUpdateContactEdit}
                    />
                }
            >
                Contact Information
            </Header>
        }
    >
        <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
            <Container
                header={
                    <Header variant="h2">
                        Personal Information
                    </Header>
                }
            >
                <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">First Name:</Box>
                            <div>{props.contact.firstName}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Internal:</Box>
                            <div>{HelperFunctions.booleanToYesNo(props.contact.internal)}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Role:</Box>
                            {props.contact.role}
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Department:</Box>
                            {props.contact.department || "-"}
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Billing Contact:</Box>
                            {HelperFunctions.booleanToYesNo(props.contact.billingContact)}
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Last Name:</Box>
                            <div>{props.contact.lastName}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Provider(s):</Box>
                            <div>{HelperFunctions.renderProviderNameLinks(props.contact.providerNameList)}</div>
                        </div>

                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Title:</Box>
                            {props.contact.title || "-"}
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Level:</Box>
                            {props.contact.level || "-"}
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Description:</Box>
                            <div className="pre-wrap">{props.contact.description || "-"}</div>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Salutation:</Box>
                            {props.contact.salutation || "-"}
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Status:</Box>
                            <div>{props.contact.status}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Team:</Box>
                            {props.contact.team || "-"}
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Contact Type:</Box>
                            {props.contact.contactType || "-"}
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header variant="h2">
                        Contact Information
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Email:</Box>
                            <div>{props.contact.email}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Cell Phone:</Box>
                            <div>{props.contact.cellPhone || "-"}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Assistant Name:</Box>
                            <div>{props.contact.assistantName || "-"}</div>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Home Phone:</Box>
                            <div>{props.contact.homePhone || "-"}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Work Phone:</Box>
                            <div>{props.contact.workPhone || "-"}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Assistant Phone:</Box>
                            <div>{props.contact.assistantPhone || "-"}</div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Table
                header={
                    <Header variant="h2">Services</Header>}
                columnDefinitions={ContactValidation.SERVICE_COLUMN_DEFINITIONS}
                items={props.providerServiceItems}
                empty={
                    <Box textAlign="center">
                        <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                            <b>
                                Nothing to display.
                            </b>
                        </Box>
                    </Box>
                }
            />
            <Table
                header={
                    <Header variant="h2">Sites</Header>}
                columnDefinitions={ContactValidation.SITE_COLUMN_DEFINITIONS}
                items={props.siteItems}
                empty={
                    <Box textAlign="center">
                        <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                            <b>
                                Nothing to display.
                            </b>
                        </Box>
                    </Box>
                }
            />
        </SpaceBetween>
        <FremontComponentInfo
            modifiedBy={props.contact.modifiedBy}
            modifiedTime={props.contact.modifiedTime}
            createdBy={props.contact.createdBy}
            createdTime={props.contact.createdTime}
        />
    </Container>
);

/**
 * This component allows the user to edit contact information
 */
export const ContactInfoEditMode = props => (
    <div>
        <ContactAlertModal
            showContactAlertModal={props.showContactAlertModal}
            handleContactAlertOkay={props.handleContactAlertOkay}
            handleContactAlertCancel={props.handleContactAlertCancel}
        />
        <Container
            header={
                <Header
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                disabled={props.isSubmissionInProgress}
                                variant="normal"
                                onClick={props.handleUpdateContactEdit}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id="submitContactInfoButton"
                                loading={props.isSubmissionInProgress || props.providerInfoLoading}
                                variant="primary"
                                onClick={props.handleContactSubmit}
                            >
                                {props.isSubmissionInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Contact Information
                </Header>
            }
        >
            <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                <Container header={<Header variant="h2">Provider</Header>}>
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label={optionalLabel("Internal Contact:")}
                        >
                            <Box padding={{ top: "xxs" }}>
                                <FremontSelect
                                    id="internal"
                                    disabled={props.isSubmissionInProgress}
                                    options={Constants.GENERAL_YES_NO_OPTIONS}
                                    selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                                        props.updatedContact.internal
                                    )}
                                    onChange={props.handleChangeInternal}
                                />
                            </Box>
                        </FormField>
                        <FormField
                            label={props.providersDisabled ?
                                <SpaceBetween
                                    direction="horizontal"
                                    size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}
                                >
                                    {requiredLabel("Provider(s):")}
                                    {
                                        HelperFunctions.renderCustomTooltip(null,
                                            Constants.TOOLTIP_STRINGS.providerDisabledExplanation)
                                    }
                                </SpaceBetween>
                                : requiredLabel("Provider(s):")}
                            constraintText="Select providers associated with this contact."
                        >
                            <ContactFormAdditionalProviderInputs
                                providerNameList={props.updatedContact[
                                    Constants.ATTRIBUTES.providerNameList]}
                                handleContactProviderChange={props.handleContactProviderChange}
                                disableAddProviderButton={props.disableAddProviderButton}
                                hasSubmittedOnce={props.hasSubmittedOnce}
                                handleAddAdditionalProviderInput={props.handleAddAdditionalProviderInput}
                                handleSubtractSpecificProviderName={props
                                    .handleSubtractSpecificProviderName}
                                internal={props.updatedContact.internal}
                                providerInfoLoading={props.providerInfoLoading}
                                providersLoading={props.providersLoading}
                                providersDisabled={props.providersDisabled || props.isSubmissionInProgress}
                                providerOptions={props.providerOptions}
                                errorText={props.contactErrorTexts.providerNameList}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
                <Container header={<Header variant="h2">Personal Information</Header>}>
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label={requiredLabel("First Name:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.firstName : ""}
                        >
                            <FremontInput
                                id="firstName"
                                disabled={props.isSubmissionInProgress}
                                value={props.updatedContact.firstName}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={requiredLabel("Last Name:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.lastName : ""}
                        >
                            <FremontInput
                                id="lastName"
                                disabled={props.isSubmissionInProgress}
                                value={props.updatedContact.lastName}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Salutation:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.salutation : ""}
                        >
                            <FremontSelect
                                id="salutation"
                                disabled={props.isSubmissionInProgress}
                                options={ContactValidation.CONTACT_SALUTATION_OPTIONS}
                                selectedOption={HelperFunctions
                                    .createSelectedOption(props.updatedContact.salutation)}
                                onChange={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={requiredLabel("Status:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.status : ""}
                        >
                            <FremontSelect
                                id="status"
                                disabled={props.isSubmissionInProgress}
                                options={Constants.GENERAL_STATUS_OPTIONS}
                                selectedOption={HelperFunctions
                                    .createSelectedOption(props.updatedContact.status)}
                                onChange={props.handleContactInputChange}
                                value={props.updatedContact.status}
                            />
                        </FormField>
                        <FormField
                            label={requiredLabel("Role:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.role : ""}
                        >
                            <FremontSelect
                                id="role"
                                disabled={props.isSubmissionInProgress}
                                options={ContactValidation.CONTACT_ROLE_OPTIONS}
                                selectedOption={HelperFunctions
                                    .createSelectedOption(props.updatedContact.role)}
                                onChange={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Title:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.title : ""}
                        >
                            <FremontInput
                                id="title"
                                value={props.updatedContact.title}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Team:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.team : ""}
                        >
                            <FremontInput
                                id="team"
                                value={props.updatedContact.team}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Department:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.department : ""}
                        >
                            <FremontInput
                                id="department"
                                value={props.updatedContact.department}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Level:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.level : ""}
                        >
                            <FremontSelect
                                id="level"
                                disabled={props.isSubmissionInProgress}
                                options={HelperFunctions.getNumericalOptions(20)}
                                selectedOption={HelperFunctions
                                    .createSelectedOption(props.updatedContact.level)}
                                onChange={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Contact Type:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.contactType : ""}
                        >
                            <FremontSelect
                                id="contactType"
                                disabled={props.isSubmissionInProgress}
                                options={ContactValidation.CONTACT_TYPE_OPTIONS}
                                selectedOption={HelperFunctions
                                    .createSelectedOption(props.updatedContact.contactType)}
                                onChange={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Billing Contact:")}
                        >
                            <FremontSelect
                                id="billingContact"
                                disabled={props.isSubmissionInProgress}
                                options={Constants.GENERAL_YES_NO_OPTIONS}
                                selectedOption={HelperFunctions.booleanToYesNoSelectedOption(
                                    props.updatedContact.billingContact
                                )}
                                onChange={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Description:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.description : ""}
                        >
                            <FremontTextarea
                                id="description"
                                value={props.updatedContact.description}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
                <Container header={<Header variant="h2">Contact Information</Header>}>
                    <ColumnLayout columns={2} borders="vertical">
                        <FormField
                            label={requiredLabel("Email Address:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.email : ""}
                        >
                            <FremontInput
                                id="email"
                                value={props.updatedContact.email}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Home Phone:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.homePhone : ""}
                        >
                            <FremontInput
                                id="homePhone"
                                value={props.updatedContact.homePhone}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Cell Phone:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.cellPhone : ""}
                        >
                            <FremontInput
                                id="cellPhone"
                                value={props.updatedContact.cellPhone}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Work Phone:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.workPhone : ""}
                        >
                            <FremontInput
                                id="workPhone"
                                value={props.updatedContact.workPhone}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Assistant Name:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.assistantName : ""}
                        >
                            <FremontInput
                                id="assistantName"
                                value={props.updatedContact.assistantName}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                        <FormField
                            label={optionalLabel("Assistant Phone:")}
                            errorText={props.hasSubmittedOnce ? props.contactErrorTexts.assistantPhone : ""}
                        >
                            <FremontInput
                                id="assistantPhone"
                                value={props.updatedContact.assistantPhone}
                                disabled={props.isSubmissionInProgress}
                                onInput={props.handleContactInputChange}
                            />
                        </FormField>
                    </ColumnLayout>
                </Container>
                <Container header={<Header variant="h2">Services</Header>}>
                    {props.providerInfoLoading ?
                        <Spinner/>
                        :
                        <ContactFormProviderServices
                            providerNameList={props.updatedContact[Constants.ATTRIBUTES.providerNameList]}
                            serviceOptions={props.serviceOptions}
                            handleServiceOptionsChange={props.handleServiceOptionsChange}
                            allFieldsDisabled={props.isSubmissionInProgress}
                        />
                    }
                </Container>
                <Container header={<Header variant="h2">{optionalLabel("Sites")}</Header>}>
                    {props.providerInfoLoading ?
                        <Spinner/>
                        :
                        <ContactFormSites
                            siteMap={props.updatedContact[Constants.ATTRIBUTES.siteMap]}
                            handleContactInputChange={props.handleContactInputChange}
                            allFieldsDisabled={props.isSubmissionInProgress}
                        />
                    }
                </Container>
            </SpaceBetween>
        </Container>
    </div>
);

export default ContactInformation;