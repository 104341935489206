import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderTableData from "order/OrderTableData";
import OrderValidation from "order/OrderValidation";
import { StageDisplayMode, StageEditMode } from "order/OrderCommonComponents";
import { TopologyDisplayMode, TopologyEditMode } from "order/stages/install/TopologyInformation";
import { v4 as uuidv4 } from "uuid";

class TopologyHandler extends Component {
    state = {
        isEditClicked: false,
        isUpdateStageInProgress: false,
        isSearchingForOrders: false,
        allFieldsDisabled: false,
        updatedOrder: this.props.order,
        orderMap: {},
        ordersThatDontExist: [],
        errorTexts: {},
        hasBeenSubmittedOnce: false
    };

    componentDidMount = async () => {
        try {
            await this.fetchOrderSites();
        } catch (error) {
            // This is used for showing the flashbar error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    FremontBackendClient = new FremontBackendClient();

    fetchOrderSites = async () => {
        const { topologyMap } = this.state.updatedOrder;
        const orderIdSet = new Set();

        this.setState({
            allFieldsDisabled: true,
            isSearchingForOrders: true
        });

        Object.keys(topologyMap).forEach(topologyMapPosition =>
            topologyMap[topologyMapPosition].forEach((topologyObject) => {
                if (!this.state.ordersThatDontExist.includes(topologyObject.orderId)) {
                    orderIdSet.add(topologyObject.orderId);
                }
            }));

        const response = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.ORDER, Array.from(orderIdSet), this.props.auth
        );

        const returnedOrderIds = response.orders.map(order => order.orderId);
        const ordersThatDontExist = Array.from(orderIdSet).filter(existingOrderId =>
            !returnedOrderIds.includes(existingOrderId));

        const orderItems = await OrderTableData.fetchOrderRelatedObjects(
            response.orders, this.props.auth, this.props.handleFlashBarMessagesFromChildTabs
        );
        const orderMap = Object.assign(this.state.orderMap, ...orderItems.map(order => ({ [order.orderId]: order })));

        this.setState({ ordersThatDontExist });

        const errorTexts = this.checkErrors(this.state.updatedOrder.topologyMap,
            this.state.updatedOrder.circuitDesignIdList, orderMap);

        this.setState({
            orderMap,
            allFieldsDisabled: false,
            isSearchingForOrders: false,
            errorTexts
        });
    }

    handleStageEditClick = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedOrder = HelperFunctions.deepClone(this.props.order);

        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            allFieldsDisabled: false,
            hasBeenSubmittedOnce: false,
            updatedOrder
        });
        await this.props.fetchSiteOptions();
    };

    checkErrors = (topologyMap, circuitDesignIdList, orderMap) => {
        let lastPosition = 0;
        const error = {};

        // We must look at entire topologyMap and figure out the errors for the three fields
        // as they are linked to each other and cant really be looked at independently.
        Object.keys(topologyMap).forEach((topologyMapPosition) => {
            // First create an empty array
            error[topologyMapPosition] = [];

            // Calculate the total sum of all circuits in that Topology position
            const sumOfCircuits = topologyMap[topologyMapPosition]
                .reduce((sum, topologyObject) => sum + HelperFunctions.parseInt(topologyObject.circuitQuantity), 0);

            // Now loop through the array
            topologyMap[topologyMapPosition].forEach((topologyObject, index) => {
                let orderIdError = "";
                if (topologyObject.sourceSystem === Constants.TOPOLOGY_CONSTANTS.fremont) {
                    if (this.state.ordersThatDontExist.includes(topologyObject.orderId)) {
                        orderIdError = Constants.VALIDATION_ERROR_STRINGS.topologyMapOrderDoesNotExist;
                    } else if (!Object.keys(orderMap).includes(topologyObject.orderId)) {
                        orderIdError = Constants.VALIDATION_ERROR_STRINGS.topologyMapOrderNotValidated;
                    }
                }

                error[topologyMapPosition][index] = {
                    // position errors if there is a gap in the topology
                    // circuitQuantity errors if the total for each array doesn't add up
                    // orderId must exist in orderMap (simplest validation)
                    position: HelperFunctions.parseInt(topologyMapPosition) - lastPosition === 1 ?
                        "" : Constants.VALIDATION_ERROR_STRINGS.topologyMapPositionGap,
                    circuitQuantity: (sumOfCircuits !== circuitDesignIdList.length
                        && !this.state.ordersThatDontExist.includes(topologyObject.orderId)) ?
                        Constants.VALIDATION_ERROR_STRINGS.topologyMapCircuitQuantityIncorrect : "",
                    orderId: orderIdError,
                    siteAId: (topologyObject.siteAId
                        || topologyObject.sourceSystem === Constants.TOPOLOGY_CONSTANTS.fremont) ?
                        "" : Constants.VALIDATION_ERROR_STRINGS.topologyMapMissingSiteId,
                    siteZId: (topologyObject.siteZId ||
                        topologyObject.sourceSystem === Constants.TOPOLOGY_CONSTANTS.fremont) ?
                        "" : Constants.VALIDATION_ERROR_STRINGS.topologyMapMissingSiteId,
                    sourceSystem: !topologyObject.sourceSystem ?
                        Constants.VALIDATION_ERROR_STRINGS.topologySourceSystemMissing : "",
                    topologyCustomerFabric: (!topologyObject.topologyCustomerFabric &&
                        topologyObject.sourceSystem === Constants.TOPOLOGY_CONSTANTS.linkService) ?
                        Constants.VALIDATION_ERROR_STRINGS.topologyCustomerFabricMissing : ""
                };
            });

            // update the lastPosition so that in case there are multiple topologyRows for a single position
            // we still account for that
            lastPosition = HelperFunctions.parseInt(topologyMapPosition);
        });

        return error;
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.order = HelperFunctions.deepClone(this.state.updatedOrder);
        input.orderErrorTexts = {};
        input.stageName = Constants.STAGE_NAMES.topology;
        const output = OrderValidation.validateInput(input);
        const errorTexts = this.checkErrors(output.order.topologyMap,
            output.order.circuitDesignIdList, this.state.orderMap);

        this.setState({
            updatedOrder: output.order,
            errorTexts
        });
    };

    handleAddRowToTopologyMap = () => {
        const updatedOrderClone = HelperFunctions.deepClone(this.state.updatedOrder);
        const newTopologyObject = {
            orderId: "ORDER-",
            circuitQuantity: "",
            [Constants.TOPOLOGY_CONSTANTS.sourceSystem]: "Fremont",
            [Constants.TOPOLOGY_CONSTANTS.topologyCustomerFabric]: "",
            uuid: uuidv4()
        };

        // We need to assign a position to a new item in the topology map even if the user
        // later reassigns the value.  The new value is going to be the last item in the topologyMap + 1
        const lastPositionInTopology = Object.keys(this.state.updatedOrder.topologyMap).length === 0 ?
            0 : Math.max(...Object.keys(this.state.updatedOrder.topologyMap));
        updatedOrderClone.topologyMap[lastPositionInTopology + 1] = [newTopologyObject];
        const errorTexts = this.checkErrors(updatedOrderClone.topologyMap,
            this.state.updatedOrder.circuitDesignIdList, this.state.orderMap);
        this.setState({
            updatedOrder: updatedOrderClone,
            errorTexts
        });
    };

    /**
     * @returns {Promise<{success}>} success is true if no error, false otherwise.
     */
    handleStageSubmit = async () => {
        let success = false;
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });

        // check for errors
        const errorTexts = HelperFunctions.deepClone(this.state.errorTexts);
        if (Object.keys(errorTexts).some(topologyMapPosition =>
            errorTexts[topologyMapPosition].some(topologyObject =>
                Object.values(topologyObject).some(error => error)))) {
            this.setState({
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            return { success };
        }

        try {
            // Here we sort the topology
            this.state.updatedOrder.topologyMap = HelperFunctions.sortTopology(this.state.updatedOrder.topologyMap,
                this.state.updatedOrder.siteAId, this.state.updatedOrder.siteZId, this.state.orderMap);
            // Here we submit a request to update the order
            await this.FremontBackendClient.updateOrderInfo(
                this.state.updatedOrder, this.props.order, this.props.auth
            );

            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);

            // Here we display a successful flashbar message
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
            success = true;
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: true,
                allFieldsDisabled: false
            });
            success = false;
        }
        this.props.handleStageInEditOrSubmitMode(this.state.isEditClicked);
        return { success };
    };

    generateTopologyEditMode = () => (
        <TopologyEditMode
            order={this.state.updatedOrder}
            allFieldsDisabled={this.state.allFieldsDisabled}
            handleStageInputChange={this.handleStageInputChange}
            handleAddRowToTopologyMap={this.handleAddRowToTopologyMap}
            orderMap={this.state.orderMap}
            ordersThatDontExist={this.state.ordersThatDontExist}
            handleSearchForOrder={this.fetchOrderSites}
            errorTexts={this.state.errorTexts}
            isSearchingForOrders={this.state.isSearchingForOrders}
            hasBeenSubmittedOnce={this.state.hasBeenSubmittedOnce}
            circuitItems={this.props.circuitDesignObjects}
            componentIdToObjectMap={this.props.componentIdToObjectMap}
            siteOptions={this.props.siteOptions}
            stageName={Constants.STAGE_NAMES.topology}
        />
    );

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.topology}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.props.circuitDesignObjects.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.props.circuitDesignObjects}

                    hasStageBeenCompleted={this.props.order.hasTopologyBeenCompleted}
                    handleCompleteStage={this.props.handleToggleCompleteStage}
                    completeStageMessage="Complete Topology Stage"

                    content={
                        <TopologyDisplayMode
                            order={this.props.order}
                            orderMap={this.state.orderMap}
                            errorTexts={this.state.errorTexts}
                            isSearchingForOrders={this.state.isSearchingForOrders}
                            componentIdToObjectMap={this.props.componentIdToObjectMap}
                            siteOptions={this.props.siteOptions}
                        />
                    }
                />
                :
                <StageEditMode
                    order={this.state.updatedOrder}
                    stageName={Constants.STAGE_NAMES.topology}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    isUpdateStageDisabled={this.state.isSearchingForOrders}
                    content={this.generateTopologyEditMode()}
                />
        );
    }
}

export default TopologyHandler;