import React from "react";
import FremontTable from "table/FremontTable";
import SiteTableData from "site/SiteTableData";

export default function SiteTable(props) {
    return (
        <FremontTable
            entity="Site"
            createEntityLink={SiteTableData.fetchCreateSiteLink(props.providerName)}
            columnDefinitions={SiteTableData.COLUMN_DEFINITIONS}
            tableItems={props.items}
            loading={props.loading}
            emptyTableMessage={props.emptyTableMessage}
            onNextPageClickHelper={{
                nextToken: props.nextToken, fetch: async () => props.fetchAllSiteItems()
            }}
            nextToken={props.nextToken}
        />
    );
}