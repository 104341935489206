import React, { useState, useRef, useEffect } from "react";
import { difference, isEmpty, xor } from "lodash";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import LinkConsumptionModal from "./LinkConsumptionModal";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";
import Constants from "../utils/Constants";
import HelperFunctions from "../common/HelperFunctions";
import LinkSearchFilter from "../search/LinkSearchFilter";
import LinkServiceTable from "../components/LinkServiceTable";
import { getPassiveToPassiveTableColumns } from "./LinkColumnDefinitions";
import { LinkServiceButton } from "../components/CommonComponents";

export default function LinkConsumption({
    link,
    linkType,
    fetchingConsumedLinks,
    consumedLinks,
    setConsumedLinks,
    handleFlashbarMessageFromChildTabs,
    clearFlashbarMessageFromChildTabs
}) {
    const [isSearchInProgress, setIsSearchInProgress] = useState();
    const [foundLinks, setFoundLinks] = useState(null);
    const [updatedConsumedLinks, setUpdatedConsumedLinks] = useState([]);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

    useEffect(() => {
        setUpdatedConsumedLinks(consumedLinks);
    }, [consumedLinks]);

    const searchTermRef = useRef();

    const linkServiceBackendClient = new LinkServiceBackendClient();

    const dedupeLinks = (links) => {
        const updatedConsumedLinkIdsSet = new Set(updatedConsumedLinks.map(lnk => lnk.instanceId));
        return links.filter(lnk => !updatedConsumedLinkIdsSet.has(lnk.instanceId));
    };

    const handleSearchTermSubmit = async () => {
        setIsSearchInProgress(true);
        clearFlashbarMessageFromChildTabs();

        try {
            const { Links } = await linkServiceBackendClient.searchLinksWithHierarchies(
                searchTermRef.current.state.searchTerm
            );
            const dedupedLinks = dedupeLinks(Links.filter(lnk => lnk.readableLinkType === linkType));

            setFoundLinks(dedupedLinks);
        } catch (e) {
            handleFlashbarMessageFromChildTabs(null, e);
        }

        setIsSearchInProgress(false);
    };

    const getLinkDescription = () => {
        const userProvidedId = HelperFunctions.getValueFromRecordAttributes(
            link, Constants.ATTRIBUTES.user_provided_id
        );

        return `${userProvidedId || link.instanceId} (${link.readableLinkType})`;
    };

    const addButtonOnClick = (item) => {
        setUpdatedConsumedLinks(updatedConsumedLinks.concat(item));
    };
    const removeButtonOnClick = (item) => {
        const index = updatedConsumedLinks.findIndex(lnk => lnk.instanceId === item.instanceId);
        setUpdatedConsumedLinks(updatedConsumedLinks.slice(0, index).concat(updatedConsumedLinks.slice(index + 1)));
    };

    const getActionButtonColumn = (action) => {
        const iconName = action === Constants.CONSUMPTION_ACTIONS.ADD ? "add-plus" : "close";
        const onClick = action === Constants.CONSUMPTION_ACTIONS.ADD ? addButtonOnClick : removeButtonOnClick;

        return [{
            id: action,
            width: 80,
            cell: item => (
                <LinkServiceButton
                    id={item.instanceId}
                    iconName={iconName}
                    variant="normal"
                    onClick={() => onClick(item)}
                    disabled={false}
                />
            )
        }];
    };

    const submitConsumptionUpdate = async () => {
        try {
            await linkServiceBackendClient.updateConsumedLinks(
                link.instanceId,
                updatedConsumedLinks.map(lnk => Constants.LINK_INSTANCE_ID_PATTERN + lnk.instanceId)
            );
            const fetchedLink = await linkServiceBackendClient.getLinkInstance(link.instanceId);
            const fetchedConsumedLinkIds = HelperFunctions.getValueFromRecordAttributes(
                fetchedLink, Constants.CONSUMPTION_ATTRIBUTES.consumesList
            );
            const fetchedComsumptionMatchesUpdates = isEmpty(xor(
                JSON.parse(fetchedConsumedLinkIds).map(lnk => HelperFunctions.getUuidFromFullLinkId(lnk)),
                updatedConsumedLinks.map(lnk => lnk.instanceId)
            ));

            if (!fetchedComsumptionMatchesUpdates) {
                setConsumedLinks([]);
                throw new Error(`Fetched consumption does not match updated consumption. ${Constants.NEST_BUG_ERROR_MESSAGE}`);
            }

            setConsumedLinks(updatedConsumedLinks);
            setFoundLinks(null);
            handleFlashbarMessageFromChildTabs("Consumption has been updated.");
        } catch (e) {
            handleFlashbarMessageFromChildTabs(null, e);
        }

        setIsConfirmationModalVisible(false);
    };

    const baseColumnDefinitions = {
        [Constants.LINK_TYPES.passiveToPassive]: getPassiveToPassiveTableColumns().slice(0, 6)
    };
    const foundTableColumnDefinitions = getActionButtonColumn(Constants.CONSUMPTION_ACTIONS.ADD)
        .concat(baseColumnDefinitions[linkType]);
    const consumedTableColumnDefinitions = getActionButtonColumn(Constants.CONSUMPTION_ACTIONS.REMOVE)
        .concat(baseColumnDefinitions[linkType]);
    const foundTableItems = foundLinks && foundLinks
        .filter(lnk => !updatedConsumedLinks.map(l => l.instanceId).includes(lnk.instanceId));
    const isButtonsDisabled = isEmpty(xor(consumedLinks, updatedConsumedLinks));

    return (
        <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
            <LinkConsumptionModal
                linkDescription={getLinkDescription()}
                removedLinks={difference(consumedLinks, updatedConsumedLinks)}
                addedLinks={difference(updatedConsumedLinks, consumedLinks)}
                isVisible={isConfirmationModalVisible}
                setIsVisible={setIsConfirmationModalVisible}
                submitConsumptionUpdate={submitConsumptionUpdate}
            />
            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                <LinkSearchFilter
                    ref={searchTermRef}
                    primaryHeaderText="Search For Links To Consume"
                    secondaryHeaderText={`Updating consumption for link ${getLinkDescription()}`}
                    isSearchInProgress={isSearchInProgress}
                    handleSearchTermSubmit={handleSearchTermSubmit}
                />
                {foundLinks && <LinkServiceTable
                    entity="Found Links"
                    tableItems={foundTableItems}
                    columnDefinitions={foundTableColumnDefinitions}
                    loading={isSearchInProgress}
                />}
                <LinkServiceTable
                    entity="Consumed Links"
                    tableItems={updatedConsumedLinks}
                    columnDefinitions={consumedTableColumnDefinitions}
                    loading={fetchingConsumedLinks}
                />
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <LinkServiceButton
                            variant="normal"
                            onClick={() => setUpdatedConsumedLinks(consumedLinks)}
                            disabled={isButtonsDisabled}
                        >
                            Reset Changes
                        </LinkServiceButton>
                        <LinkServiceButton
                            variant="primary"
                            onClick={() => setIsConfirmationModalVisible(true)}
                            disabled={isButtonsDisabled}
                        >
                            Update Consumption
                        </LinkServiceButton>
                    </SpaceBetween>
                </Box>
            </SpaceBetween>
        </div>
    );
}
