import React from "react";
import {
    Box,
    Link
} from "@amzn/awsui-components-react/polaris";
import FremontHeader from "common/FremontHeader";
import Constants from "utils/Constants";

import Axel1 from "axel1.jpg";
import Axel2 from "axel2.jpg";
import Caramel from "caramel.jpg";
import Chansey from "chansey.jpg";
import Dante from "dante.png";
import Loki from "loki.png";
import Miumiu from "miumiu.png";
import Miumiu2 from "miumiu2.png";
import Pippi from "pippi.jpg";
import Gaia from "gaia.jpg";
import Marcus from "marcus.jpg";

const FremontErrorPage = (props) => {
    const randomImagesArray = [Axel1, Axel2, Caramel, Chansey, Dante, Loki, Miumiu, Miumiu2, Pippi, Gaia, Marcus];

    const getRandomImage = (imgAr) => {
        const num = Math.floor(Math.random() * imgAr.length);
        return imgAr[num];
    };

    return (
        (
            <div>
                <FremontHeader
                    history={props.history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <Box variant="h1">We&#39;re sorry!</Box>
                    <Box variant="h3">
                        Your page couldn&#39;t be found. Please try the
                        <Link href={Constants.LIGHTHOUSE_ROUTES.lighthouseLandingPage}> landing page</Link>.
                    </Box>
                    <Box variant="h3">Or you can enjoy a developer&#39;s dog instead!</Box>
                    <img width="600" src={getRandomImage(randomImagesArray)} alt="dog"/>
                </div>
            </div>
        )
    );
};
export default FremontErrorPage;
