import React from "react";
import {
    Link
} from "@amzn/awsui-components-react/polaris";
import {
    FremontExpandableSection
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";

export default class AsnTableData {
    static COLUMN_DEFINITIONS = [
        {
            id: "asnNumber",
            sortingField: "asnNumber",
            sortingComparator: (asn1, asn2) => (HelperFunctions.sortNumberStrings(asn1, asn2, "asnNumber")),
            header: "ASN",
            description: "Number of Asn",
            cell: item => <Link href={`${Constants.ROUTES.asn}/${item.asnId}`}>{item.asnNumber}</Link>
        },
        {
            id: "providerName",
            sortingField: "providerName",
            header: "Provider Name",
            description: "Provider for this ASN ",
            cell: item => <Link href={`${Constants.ROUTES.provider}/${item.providerName}`}>{item.providerName}</Link>
        },
        {
            id: "tierOneGeographicRegions",
            header: "Tier One Geographic Region(s)",
            description: "Tier One Geographic Region(s)",
            cell: item => Object.keys(item.asnRegions).filter(region => item.asnRegions[region]).join(", ")
        },
        {
            id: "geographicRegions",
            header: "Geographic Regions(s)",
            description: "Geographic Regions(s)",
            cell: item => Object.keys(item.asnRegions).filter(region => !item.asnRegions[region]).join(", ")
        },
        {
            id: "providerServices",
            header: "Services",
            cell: item => (item.providerServiceObjectList &&
                HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.providerService,
                    item.providerServiceIdList.map((providerServiceId) => {
                        const providerService = item.providerServiceObjectList.find(providerServiceObject =>
                            providerServiceObject.providerServiceId === providerServiceId) || {};
                        return {
                            [Constants.ID_AND_DISPLAY_VALUE.ID]: providerServiceId,
                            [Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]: providerService.serviceType || "Loading"
                        };
                    }), false))
        },
        {
            id: "inProgressOrderList",
            sortingField: "inProgressOrderList",
            sortingComparator: (asn1, asn2) => (HelperFunctions.sortByListLength(asn1, asn2, "inProgressOrderList")),
            header: "In Progress Orders",
            cell: item => (
                item.inProgressOrderList &&
                    <FremontExpandableSection
                        header={`${item.inProgressOrderList.length} Orders, click to see`}
                    >
                        {HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.order,
                            item.inProgressOrderList.map(order => ({
                                [Constants.ID_AND_DISPLAY_VALUE.ID]: order.orderId,
                                [Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]: order.orderId
                            })), false)}
                    </FremontExpandableSection>
            )
        },
        {
            id: "completedOrderList",
            sortingField: "completedOrderList",
            sortingComparator: (asn1, asn2) => (HelperFunctions.sortByListLength(asn1, asn2, "completedOrderList")),
            header: "Completed Orders",
            cell: item => (
                item.completedOrderList &&
                    <FremontExpandableSection
                        header={`${item.completedOrderList.length} Orders, click to see`}
                    >
                        {HelperFunctions.renderLinksFromIdAndDisplayValues(Constants.ROUTES.order,
                            item.completedOrderList.map(order => ({
                                [Constants.ID_AND_DISPLAY_VALUE.ID]: order.orderId,
                                [Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]: order.orderId
                            })), false)}
                    </FremontExpandableSection>
            )
        }
    ];

    /**
     * Fetch related items information to display
     */
    static fetchAsnRelatedObjects = async (asnItems, auth, handleFlashBarMessagesFromChildTabs) => {
        const fremontBackendClient = new FremontBackendClient();
        const asns = HelperFunctions.deepClone(asnItems);
        try {
            const providerServiceIds = new Set();
            const orderIds = new Set();
            asns.forEach((asn) => {
                if (asn.providerServiceIdList && asn.providerServiceIdList.length > 0) {
                    asn.providerServiceIdList.forEach(providerServiceIds.add, providerServiceIds);
                }
                if (asn.orderIdList && asn.orderIdList.length > 0) {
                    asn.orderIdList.forEach(orderIds.add, orderIds);
                }
            });
            // Get Provider Service objects
            const providerServiceResponse = await fremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.PROVIDER_SERVICE, Array.from(providerServiceIds), auth
            );
            const providerServiceDict = {};
            providerServiceResponse.providerServices.forEach(providerService =>
                Object.assign(providerServiceDict, { [providerService.providerServiceId]: providerService }));
            asns.forEach((asn) => {
                if (asn.providerServiceIdList && asn.providerServiceIdList.length > 0) {
                    Object.assign(asn, {
                        providerServiceObjectList: asn.providerServiceIdList.map(id => ({
                            providerServiceId: providerServiceDict[id].providerServiceId,
                            serviceType: providerServiceDict[id].serviceType
                        }))
                    });
                }
            });
            // Get Order objects
            const orderResponse = await fremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.ORDER, Array.from(orderIds), auth
            );
            const orderDict = {};
            orderResponse.orders.forEach(order =>
                Object.assign(orderDict, { [order.orderId]: order }));
            asns.forEach((asn) => {
                if (asn.orderIdList && asn.orderIdList.length > 0) {
                    Object.assign(asn, {
                        inProgressOrderList: asn.orderIdList.filter(id =>
                            !HelperFunctions.isOrderCompleted(orderDict[id].stageStatusMap)).map(id => orderDict[id]),
                        completedOrderList: asn.orderIdList.filter(id =>
                            HelperFunctions.isOrderCompleted(orderDict[id].stageStatusMap)).map(id => orderDict[id])
                    });
                }
            });
        } catch (error) {
            // This is used for showing the flashbar error message on the parent page
            handleFlashBarMessagesFromChildTabs(false, error, false);
        }
        return asns;
    }

    static fetchCreateAsnLink = providerName =>
        (providerName ? `${Constants.ROUTES.createAsn}/${providerName}` : Constants.ROUTES.createAsn);
}