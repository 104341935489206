import React from "react";
import {
    Box,
    FormField,
    Header,
    Link,
    Select
} from "@amzn/awsui-components-react/polaris";
import {
    FremontCheckbox,
    FremontExpandableSection,
    FremontInput,
    FremontSelect
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";
import {
    TableDisplayModeTable,
    TableEditModeTable
} from "order/OrderCommonComponents";

export const determineNodeOptionsToDisplay = (item, siteAIdOrSiteZId) => {
    // Here we obtain the corresponding siteId on this item that we want to query the node options for
    const siteIdForNodeComponent = item[siteAIdOrSiteZId];
    // Here we obtain the node options for the corresponding siteId
    const nodeOptions = item.nodeOptions[siteIdForNodeComponent] || [];
    // Here we filter the node options to not show any node whose device names end in "_NA"
    return nodeOptions.filter(nodeOption => nodeOption.value.slice(-3) !== Constants.NCIS_DUMMY_COMPONENT_VALUE);
};

export const FremontSelectPortRes = props => (
    // TODO: look into making this Lint compliant if possible
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
        role="searchbox"
        id={props.id}
        // onKeyUp must be used as onKeyPress does not contain the data
        onKeyUp={({ target }) => props.onChange({ detail: { value: target.value }, target: { id: props.id } })}
    >
        {/* DO NOT USE FremontSelect here as this is wrapped in a React div with onKeyUp event handler */}
        <Select
            {...props}
            onChange={({ detail }) => props.onChange({ detail, target: { id: props.id } })}
        />
    </div>
);


export const FremontSelectPortResHelperForAdditionalOption = (item, site, router) => {
    const siteAorZ = site === Constants.COMPONENT_NAMES.siteA ?
        Constants.ATTRIBUTES.siteAId : Constants.ATTRIBUTES.siteZId;

    if (!router || determineNodeOptionsToDisplay(item, siteAorZ).some(option => option.value === router)) {
        return [];
    }
    return {
        value: router,
        label: router
    };
};

export const generatePortReservationStageColumnDefinitions = (lacpProvider, numberOfItems,
    site, serviceType, orderType) => {
    const columnDefinitions = [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            sortingField: Constants.TABLE_IDS.circuitDesignLink,
            sortingComparator: (circuit1, circuit2) => HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                Constants.ATTRIBUTES.circuitDesignNumber),
            header: "Circuit ID",
            description: "The Fremont generated ID of the circuit and display value",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                <Link href={`${Constants.ROUTES.circuitDesign}/${item.circuitDesignId}`}>
                    {item.circuitDesignNumber}
                </Link>
            )
        },
        {
            id: Constants.TABLE_IDS.stageStatus,
            header: "Status",
            description: "Stage status for this circuit",
            width: Constants.COLUMN_WIDTHS.statusColumn,
            cell: item => HelperFunctions.showStageStatusWithBlocker(
                item.stageStatusMap[Constants.STAGE_NAMES.portReservation], item.blockers
            )
        },
        {
            id: Constants.ATTRIBUTES.subStatusMap,
            header: "Sub-Status",
            description: "Stage sub-status for this circuit",
            width: Constants.COLUMN_WIDTHS.circuitDesignIdColumn,
            cell: item => (
                !item.editable ?
                    item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.portReservation] :
                    <div>
                        <FremontSelect
                            id={`${Constants.ATTRIBUTES.subStatusMap}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            options={HelperFunctions.createSelectedOptions(
                                Constants.SUB_STATUS_OPTIONS.portReservation
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(
                                item[Constants.ATTRIBUTES.subStatusMap][Constants.STAGE_NAMES.portReservation]
                            )}
                            onChange={item.handleStageInputChange}
                            disabled={item.allFieldsDisabled ||
                            !HelperFunctions.isStageInProgress(item.stageStatusMap.portReservation)}
                            expandToViewport
                        />
                    </div>
            )
        }
    ];

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.siteA,
                header: "Site A",
                description: "The siteA for where the router exists",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    item.editable ?
                        <div>
                            <FremontSelect
                                id={`${Constants.COMPONENT_NAMES.siteA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                                statusType={item.areOptionsLoading ? Constants.LOADING : Constants.FINISHED}
                                loadingText="Loading Options"
                                options={HelperFunctions.groupOptions(
                                    item.siteOptions, Constants.ATTRIBUTES.geographicRegion
                                )}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    item[Constants.COMPONENT_NAMES.siteA]
                                )}
                                empty="No sites found."
                                disabled={item.allFieldsDisabled}
                                onChange={item.handleStageInputChange}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                expandToViewport
                            />
                        </div>
                        :
                        item[Constants.COMPONENT_NAMES.siteA]
                )
            }
        );
    }

    columnDefinitions.push(
        {
            id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.router),
            header: HelperFunctions.returnHeaderInformation(site, Constants.COMPONENT_LABELS.router),
            description: Constants.COMPONENT_DESCRIPTION.router,
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                item.editable ?
                    <div>
                        <FormField
                            errorText={item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                                Constants.COMPONENT_NAMES.nodeA :
                                Constants.COMPONENT_NAMES.nodeZ]}
                        >
                            <FremontSelectPortRes
                                id={site === Constants.COMPONENT_NAMES.siteA ?
                                    `${Constants.COMPONENT_NAMES.nodeA}${Constants.SEPARATOR}${item.circuitDesignId}` :
                                    `${Constants.COMPONENT_NAMES.nodeZ}${Constants.SEPARATOR}${item.circuitDesignId}`
                                }
                                siteId={site === Constants.COMPONENT_NAMES.siteA ?
                                    item[Constants.ATTRIBUTES.siteAId] : item[Constants.ATTRIBUTES.siteZId]}
                                value={item[site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.COMPONENT_NAMES.nodeA : Constants.COMPONENT_NAMES.nodeZ]}
                                statusType={item.areOptionsLoading ? Constants.LOADING : Constants.FINISHED}
                                onChange={item.handleStageInputChange}
                                loadingText="Loading Options"
                                options={determineNodeOptionsToDisplay(item,
                                    site === Constants.COMPONENT_NAMES.siteA ?
                                        Constants.ATTRIBUTES.siteAId : Constants.ATTRIBUTES.siteZId)
                                    .filter(nodeOption => HelperFunctions.validateNode(nodeOption.value,
                                        item[site === Constants.COMPONENT_NAMES.siteA ?
                                            Constants.COMPONENT_NAMES.siteA : Constants.COMPONENT_NAMES.siteZ]))
                                    .concat(FremontSelectPortResHelperForAdditionalOption(item, site,
                                        item[site === Constants.COMPONENT_NAMES.siteA ?
                                            Constants.COMPONENT_NAMES.nodeA : Constants.COMPONENT_NAMES.nodeZ]))
                                }
                                disabled={!(site === Constants.COMPONENT_NAMES.siteA ?
                                    item[Constants.COMPONENT_NAMES.siteA] : true) || item.allFieldsDisabled}
                                empty="No nodes found."
                                expandToViewport
                                filteringType="auto"
                                selectedOption={HelperFunctions.createSelectedOption(
                                    item[site === Constants.COMPONENT_NAMES.siteA ?
                                        Constants.COMPONENT_NAMES.nodeA : Constants.COMPONENT_NAMES.nodeZ]
                                )}
                            />
                        </FormField>
                    </div>
                    : item[site === Constants.COMPONENT_NAMES.siteA ?
                        Constants.COMPONENT_NAMES.nodeA : Constants.COMPONENT_NAMES.nodeZ]
            )
        },
        {
            id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.interface),
            header: HelperFunctions.returnHeaderInformation(site, Constants.COMPONENT_LABELS.interface),
            description: Constants.COMPONENT_DESCRIPTION.interface,
            width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
            cell: item => (
                item.editable ?
                    <div>
                        <FormField
                            errorText={item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                                Constants.COMPONENT_NAMES.portA :
                                Constants.COMPONENT_NAMES.portZ]}
                        >
                            <FremontInput
                                disabled={!item[site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.COMPONENT_NAMES.nodeA : Constants.COMPONENT_NAMES.nodeZ]
                                    || item.allFieldsDisabled}
                                onInput={item.handleStageInputChange}
                                value={site === Constants.COMPONENT_NAMES.siteA ?
                                    item[Constants.COMPONENT_NAMES.portA] :
                                    item[Constants.COMPONENT_NAMES.portZ]
                                }
                                id={site === Constants.COMPONENT_NAMES.siteA ?
                                    `${Constants.COMPONENT_NAMES.portA}${Constants.SEPARATOR}${item.circuitDesignId}` :
                                    `${Constants.COMPONENT_NAMES.portZ}${Constants.SEPARATOR}${item.circuitDesignId}`}
                            />
                        </FormField>
                    </div>
                    : item[site === Constants.COMPONENT_NAMES.siteA
                        ? Constants.COMPONENT_NAMES.portA : Constants.COMPONENT_NAMES.portZ]
            )
        }
    );

    if (serviceType === Constants.SERVICE_TYPES.BACKBONE) {
        columnDefinitions.push(
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.lever),
                header: HelperFunctions.returnHeaderInformation(site, Constants.COMPONENT_LABELS.lever),
                description: Constants.COMPONENT_DESCRIPTION.lever,
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    item.editable ?
                        <div>
                            <FormField
                                errorText={item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.COMPONENT_NAMES.leverA :
                                    Constants.COMPONENT_NAMES.leverZ]}
                            >
                                <FremontSelectPortRes
                                    id={site === Constants.COMPONENT_NAMES.siteA ?
                                        `${Constants.COMPONENT_NAMES.leverA}${Constants.SEPARATOR}${item.circuitDesignId}` :
                                        `${Constants.COMPONENT_NAMES.leverZ}${Constants.SEPARATOR}${item.circuitDesignId}`
                                    }
                                    value={site === Constants.COMPONENT_NAMES.siteA ?
                                        item[Constants.COMPONENT_NAMES.leverA] :
                                        item[Constants.COMPONENT_NAMES.leverZ]}
                                    statusType={item.areOptionsLoading ? Constants.LOADING : Constants.FINISHED}
                                    onChange={item.handleStageInputChange}
                                    loadingText="Loading Options"
                                    options={determineNodeOptionsToDisplay(item, site ===
                                    Constants.COMPONENT_NAMES.siteA ?
                                        Constants.ATTRIBUTES.siteAId : Constants.ATTRIBUTES.siteZId)
                                        .filter(leverOption => HelperFunctions.validateLever(leverOption.value,
                                            item[site === Constants.COMPONENT_NAMES.siteA ?
                                                Constants.COMPONENT_NAMES.siteA :
                                                Constants.COMPONENT_NAMES.siteZ]))
                                        .concat(FremontSelectPortResHelperForAdditionalOption(item, site,
                                            item[site === Constants.COMPONENT_NAMES.siteA ?
                                                Constants.COMPONENT_NAMES.leverA : Constants.COMPONENT_NAMES.leverZ]))
                                    }
                                    disabled={item.allFieldsDisabled}
                                    empty="No nodes found."
                                    expandToViewport
                                    filteringType="auto"
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        site === Constants.COMPONENT_NAMES.siteA ?
                                            item[Constants.COMPONENT_NAMES.leverA] :
                                            item[Constants.COMPONENT_NAMES.leverZ]
                                    )}
                                />
                            </FormField>
                        </div>
                        : item[site === Constants.COMPONENT_NAMES.siteA ? Constants.COMPONENT_NAMES.leverA :
                            Constants.COMPONENT_NAMES.leverZ]
                )
            },
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.leverExternalInterface),
                header: HelperFunctions.returnHeaderInformation(site,
                    Constants.COMPONENT_LABELS.leverExternalInterface),
                description: Constants.COMPONENT_DESCRIPTION.leverExternalInterface,
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    item.editable ?
                        <div>
                            <FormField
                                errorText={item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.COMPONENT_NAMES.leverAExternalInterface :
                                    Constants.COMPONENT_NAMES.leverZExternalInterface]}
                            >
                                <FremontInput
                                    disabled={!item[site === Constants.COMPONENT_NAMES.siteA ?
                                        Constants.COMPONENT_NAMES.leverA : Constants.COMPONENT_NAMES.leverZ]
                                    || item.allFieldsDisabled}
                                    onInput={item.handleStageInputChange}
                                    value={site === Constants.COMPONENT_NAMES.siteA ?
                                        item[Constants.COMPONENT_NAMES.leverAExternalInterface] :
                                        item[Constants.COMPONENT_NAMES.leverZExternalInterface]
                                    }
                                    id={site === Constants.COMPONENT_NAMES.siteA ?
                                        `${Constants.COMPONENT_NAMES.leverAExternalInterface}${Constants.SEPARATOR}${item.circuitDesignId}` :
                                        `${Constants.COMPONENT_NAMES.leverZExternalInterface}${Constants.SEPARATOR}${item.circuitDesignId}`
                                    }
                                />
                            </FormField>
                        </div>
                        : item[site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.COMPONENT_NAMES.leverAExternalInterface :
                            Constants.COMPONENT_NAMES.leverZExternalInterface]
                )
            },
            {
                id: HelperFunctions.returnIdInformation(site, Constants.COMPONENT_LABELS.leverInternalInterface),
                header: HelperFunctions.returnHeaderInformation(site,
                    Constants.COMPONENT_LABELS.leverInternalInterface),
                description: Constants.COMPONENT_DESCRIPTION.leverInternalInterface,
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    item.editable ?
                        <div>
                            <FormField
                                errorText={item.errorTexts[site === Constants.COMPONENT_NAMES.siteA ?
                                    Constants.COMPONENT_NAMES.leverAInternalInterface :
                                    Constants.COMPONENT_NAMES.leverZInternalInterface]}
                            >
                                <FremontInput
                                    disabled={!item[site === Constants.COMPONENT_NAMES.siteA ?
                                        Constants.COMPONENT_NAMES.leverA : Constants.COMPONENT_NAMES.leverZ]
                                    || item.allFieldsDisabled}
                                    onInput={item.handleStageInputChange}
                                    value={site === Constants.COMPONENT_NAMES.siteA ?
                                        item[Constants.COMPONENT_NAMES.leverAInternalInterface] :
                                        item[Constants.COMPONENT_NAMES.leverZInternalInterface]
                                    }
                                    id={site === Constants.COMPONENT_NAMES.siteA ?
                                        `${Constants.COMPONENT_NAMES.leverAInternalInterface}${Constants.SEPARATOR}${item.circuitDesignId}` :
                                        `${Constants.COMPONENT_NAMES.leverZInternalInterface}${Constants.SEPARATOR}${item.circuitDesignId}`
                                    }
                                />
                            </FormField>
                        </div>
                        : item[site === Constants.COMPONENT_NAMES.siteA ?
                            Constants.COMPONENT_NAMES.leverAInternalInterface :
                            Constants.COMPONENT_NAMES.leverZInternalInterface]
                )
            }
        );
    }

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType) && !!HelperFunctions.parseBoolean(lacpProvider)
        && orderType === Constants.ORDER_TYPES.INSTALL) {
        columnDefinitions.push(
            {
                id: "usingExistingLag",
                header: "Use Existing LAG",
                description: "Whether this circuit uses an existing LAG or not",
                cell: item => (
                    item.editable ?
                        <FremontCheckbox
                            id={`usingExistingLag${Constants.SEPARATOR}${item.circuitDesignId}`}
                            checked={!!HelperFunctions.parseBoolean(item[Constants.ATTRIBUTES.usingExistingLag])}
                            onChange={item.handleStageInputChange}
                            disabled={!item[Constants.COMPONENT_NAMES.nodeA] || item.allFieldsDisabled}
                        />
                        : item[Constants.ATTRIBUTES.usingExistingLag]
                )
            }
        );
    }

    if (Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType)
        && !!HelperFunctions.parseBoolean(lacpProvider)) {
        columnDefinitions.push(
            {
                id: Constants.COMPONENT_NAMES.lagA,
                header: "LAG",
                description: "Lag A for this circuitDesign",
                width: Constants.MINIMUM_COLUMN_WIDTHS.editableColumn,
                cell: item => (
                    item.editable ?
                        <div>
                            <FormField
                                errorText={item.errorTexts[Constants.COMPONENT_NAMES.lagA]}
                            >
                                <div>
                                    <FremontSelectPortRes
                                        id={`${Constants.COMPONENT_NAMES.lagA}${Constants.SEPARATOR}${item.circuitDesignId}`}
                                        onChange={item.handleStageInputChange}
                                        statusType={item.areOptionsLoading ? Constants.LOADING : Constants.FINISHED}
                                        loadingText="Loading Options"
                                        options={item.lagOptions.filter(lagOption =>
                                            HelperFunctions.validateLag(lagOption.value))}
                                        disabled={!item[Constants.COMPONENT_NAMES.nodeA]
                                        || item.allFieldsDisabled}
                                        empty="No lags found."
                                        expandToViewport
                                        filteringType="auto"
                                        selectedOption={HelperFunctions.createSelectedOption(
                                            item[Constants.COMPONENT_NAMES.lagA]
                                        )}
                                    />
                                </div>
                            </FormField>
                        </div>
                        : item[Constants.COMPONENT_NAMES.lagA]
                )
            }
        );
    }

    columnDefinitions.push(
        {
            id: "providerCircuitId",
            header: "Provider Circuit ID",
            description: "The Provider's circuit ID",
            cell: item => item.providerCircuitId
        }
    );

    return columnDefinitions;
};

export const PortReservationStageDisplayMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                Circuits
            </Header>
        }
        defaultExpanded={!HelperFunctions.isStageCompleted(props.order
            .stageStatusMap[props.stageName])}
    >
        {(props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) &&
            (Constants.ENCRYPTION_PATH_CUSTOMER_FABRICS.includes(props.order.customerFabric)) &&
            <Box padding={{ bottom: "xs" }}>
                <Box variant="h3"><b>Encryption Type:</b> &nbsp;
                    {(props.circuitItems.length > 0) &&
                    props.circuitItems.find(Boolean)[Constants.ATTRIBUTES.encryptionType]
                    }
                </Box>
            </Box>
        }
        <TableDisplayModeTable
            columnDefinitions={generatePortReservationStageColumnDefinitions(
                props.order.lacpProvider, null, Constants.COMPONENT_NAMES.siteA, props.order.serviceType,
                props.order.orderType
            )}
            circuitItems={props.circuitItems}
            isDataLoaded={props.isDataLoaded}
            header={
                <Header variant="h2">
                    {Constants.INTERCONNECT_SERVICE_TYPES.includes(
                        props.order[Constants.ATTRIBUTES.serviceType]
                    ) ? "Provider Site: " : "Site A: "}
                    {props.siteNames.siteAName ?
                        <Link href={`${Constants.ROUTES.site}/${props.order.siteAId}`}>
                            {props.siteNames.siteAName}
                        </Link> : "-"
                    }
                </Header>
            }
        />
        <Box padding={{ top: "m" }}>
            {props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
            <TableDisplayModeTable
                columnDefinitions={generatePortReservationStageColumnDefinitions(
                    props.order.lacpProvider, null, "siteZ",
                    props.order.serviceType, props.order.orderType
                )}
                header={
                    <Header variant="h2">
                        {"Site Z: "}
                        {props.siteNames.siteZName ?
                            <Link href={`${Constants.ROUTES.site}/${props.order.siteZId}`}>
                                {props.siteNames.siteZName}
                            </Link> : "-"
                        }
                    </Header>
                }
                circuitItems={props.circuitItems}
                unitItems={props.unitItems}
                isDataLoaded={props.isDataLoaded}
            />
            }
        </Box>
    </FremontExpandableSection>
);

export const PortReservationStageEditMode = props => (
    <FremontExpandableSection
        variant="container"
        header={
            <Header variant="h2">
                Circuits
            </Header>
        }
        defaultExpanded
    >
        {(props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) &&
            (Constants.ENCRYPTION_PATH_CUSTOMER_FABRICS.includes(props.order.customerFabric)) &&
            <Box padding={{ bottom: "xs" }}>
                <Box variant="h3"><b>Encryption Type:</b> &nbsp;
                    {(props.circuitItems.length > 0) &&
                    <FormField>
                        <FremontSelect
                            id={Constants.ATTRIBUTES.encryptionType}
                            placeholder="Select a Encryption Type"
                            options={HelperFunctions.createSelectedOptions(
                                Object.values(Constants.ENCRYPTION_TYPES)
                            )}
                            disabled={props.circuitItems[0].allFieldsDisabled}
                            selectedOption={HelperFunctions.createSelectedOption(
                                props.circuitItems.find(Boolean)[Constants.ATTRIBUTES.encryptionType]
                            )}
                            onChange={props.circuitItems[0].handleStageInputChange}
                        />
                    </FormField>
                    }
                </Box>
            </Box>
        }
        {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
        <TableEditModeTable
            columnDefinitions={generatePortReservationStageColumnDefinitions(
                props.order.lacpProvider, props.circuitItems.length,
                "siteA", props.order.serviceType, props.order.orderType
            )}
            header={
                <Header variant="h2">
                    {Constants.INTERCONNECT_SERVICE_TYPES.includes(
                        props.order[Constants.ATTRIBUTES.serviceType]
                    ) ? "Provider Site: " : "Site A: "}
                    {props.siteNames.siteAName ?
                        <Link href={`${Constants.ROUTES.site}/${props.order.siteAId}`}>
                            {props.siteNames.siteAName}
                        </Link> : "-"
                    }
                </Header>
            }
            circuitItems={props.circuitItems}
            loading={props.areOptionsLoading}
            massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedCircuitDesignIds}
            handleSelectedFromTable={props.handleSelectedFromTable}
            multiTable
        />
        <Box padding={{ top: "m" }}>
            {HelperFunctions.sortCircuitDesigns(props.circuitItems)}
            {props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
            <TableEditModeTable
                columnDefinitions={generatePortReservationStageColumnDefinitions(
                    props.order.lacpProvider, props.circuitItems.length,
                    "siteZ", props.order.serviceType, props.order.orderType
                )}
                header={
                    <Header variant="h2">
                        {"Site Z: "}
                        {props.siteNames.siteZName ?
                            <Link href={`${Constants.ROUTES.site}/${props.order.siteZId}`}>
                                {props.siteNames.siteZName}
                            </Link> : "-"
                        }
                    </Header>
                }
                circuitItems={props.circuitItems}
                loading={props.areOptionsLoading}
                massUpdateSelectedCircuitDesignIds={props.massUpdateSelectedCircuitDesignIds}
                handleSelectedFromTable={props.handleSelectedFromTable}
                multiTable
            />
            }
        </Box>
    </FremontExpandableSection>
);
