import Constants from "../utils/Constants";
import nonEditableLinks from "../utils/nonEditableLinks";
import HelperFunctions from "../common/HelperFunctions";
import LinkHierarchyHelper from "../link/LinkHierarchyHelper";

const { AbortController } = window;

// Currently there's no front end VIP for LinkService alpha/RDE stage
// The way to test this is point alpha.linkservice.fremont.networking.aws.a2z.com to localhost in /etc/hosts
// and setup port forwarding from mac to dev-desktop
const API_ENDPOINTS = {
    "prod.fremont.networking.aws.a2z.com": "https://prod.linkservice.fremont.networking.aws.a2z.com",
    "gamma.fremont.networking.aws.a2z.com": "https://gamma.linkservice.fremont.networking.aws.a2z.com",
    "gamma.lighthouse.networking.aws.dev": "https://gamma.linkservice.fremont.networking.aws.a2z.com",
    "prod.lighthouse.networking.aws.dev": "https://prod.linkservice.fremont.networking.aws.a2z.com",

    // For RDE testing
    "localhost:8080": "https://alpha.linkservice.fremont.networking.aws.a2z.com:9443"
    // "localhost:8080": "https://gamma.linkservice.fremont.networking.aws.a2z.com"
};


export const DEFAULT_ERROR_MESSAGE = "Unexpected error response from server."
    + " Please cut a bug to the NEST team to resolve this issue.";

const API_ENDPOINT = API_ENDPOINTS[window.location.host];
// const API_ENDPOINT = API_ENDPOINTS["gamma.fremont.networking.aws.a2z.com"]; // When connecting to gamma

const HEADERS = {
    "X-Amz-Target": "com.amazon.ndslinkserviceproxy.NDSLinkServiceProxy.CallLinkServiceApi",
    "Content-Type": "application/json; charset=UTF-8",
    "Content-Encoding": "amz-1.0"
};

export const MAX_BATCH_OBJECTS = 100;
export const MAX_DELETE_BATCH_OBJECTS = 20;

export const MAX_OBJECTS = {
    ListLinks: 30,
    ListFiles: 100,
    SearchLinksWithHierarchies: 100,
    SearchLinks: 100
};

export const API_IDENTIFIERS = {
    batchCreateIspPanelConfig: "BatchCreateIspPanelConfig",
    batchDeleteIspPanelConfig: "BatchDeleteIspPanelConfig",
    batchDeleteLinks: "BatchDeleteLinks",
    batchGetLinks: "BatchGetLinks",
    batchPutLinks: "BatchPutLinks",
    deleteLinkInstance: "DeleteLinkInstance",
    getConsumedLinks: "GetConsumedLinks",
    getFileDownloadUrl: "GetFileDownloadUrl",
    getFileUploadUrl: "GetFileUploadUrl",
    getIspData: "GetIspData",
    getLinkHierarchy: "GetLinkHierarchy",
    getLinkHistory: "GetLinkHistory",
    getLinkInstance: "GetLinkInstance",
    getLinkType: "GetLinkType",
    listConsumers: "ListConsumers",
    listFiles: "ListFiles",
    listLinks: "ListLinks",
    putLinksFromFile: "PutLinksFromFile",
    quickSightGetEmbedUrlForRegisteredUser: "QuickSightGetEmbedUrlForRegisteredUser",
    searchIspPanelConfig: "SearchIspPanelConfig",
    searchLinks: "SearchLinks",
    searchLinksWithHierarchies: "SearchLinksWithHierarchies",
    updateAttributes: "UpdateAttributes",
    updateConsumedLinks: "UpdateConsumedLinks",
    updateEncryptionCapability: "UpdateEncryptionCapability",
    updateEncryptionIntent: "UpdateEncryptionIntent",
    updateLifeCycle: "UpdateLinkLifecycle",
    updateLinkEnds: "UpdateLinkEnds",
    updateLinkType: "UpdateLinkType"
};

// TODO update or remove this as a part of
// https://sim.amazon.com/issues/FremontNEST-4250
const TEMPORARY_DUMMY_ACTOR = {
    OriginatingUser: "dummy_local_testing",
    CallingSystem: "Fremont",
    // CallingServicePrincipal: "us-west-2.prod.link-ingestor.aws.internal",
    // OriginatingUserReference: "https://mcm.amazon.com/cms/MCM-41646739",
    Cti: {
        Category: "Company-wide Services",
        Type: "NEST",
        Identifier: "Project Mango"
    }
};

export default class LinkServiceBackendClient {
    static TIMEOUT = 120000;

    constructRequest = (linkApi, linkPayload) => ({
        linkApi,
        linkPayload
    });

    async batchCreateIspPanelConfig(ispPatchPanelConfigs) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.batchCreateIspPanelConfig,
                JSON.stringify(this.getAllDefinedKeys({
                    IspPatchPanelConfigs: ispPatchPanelConfigs
                }))
            )
        );
    }

    async searchIspPanelConfig(searchTerm) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.searchIspPanelConfig,
                JSON.stringify(this.getAllDefinedKeys({
                    SearchTerm: searchTerm,
                    maxResults: 100
                }))
            )
        );
    }

    async batchDeleteIspPanelConfig(patchPanelIds) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.batchDeleteIspPanelConfig,
                JSON.stringify(this.getAllDefinedKeys({
                    PatchPanelIds: patchPanelIds
                }))
            )
        );
    }

    async putLinksFromFile(FileId, HandleStaleLinks, HandleThirdPartyLinks) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.putLinksFromFile,
                JSON.stringify(this.getAllDefinedKeys({
                    FileId,
                    Actor: TEMPORARY_DUMMY_ACTOR,
                    HandleStaleLinks,
                    HandleThirdPartyLinks
                }))
            )
        );
    }

    async getFileDownloadUrl(FileId) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.getFileDownloadUrl,
                JSON.stringify(this.getAllDefinedKeys({
                    FileId
                }))
            )
        );
    }

    async getFileUploadUrl(FileName, FileType, FileSubType) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.getFileUploadUrl,
                JSON.stringify(this.getAllDefinedKeys({
                    FileName,
                    FileType,
                    FileSubType,
                    Actor: TEMPORARY_DUMMY_ACTOR
                }))
            )
        );
    }

    async getConsumedLinks(linkId) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.getConsumedLinks,
                JSON.stringify(this.getAllDefinedKeys({ linkId: Constants.LINK_INSTANCE_ID_PATTERN + linkId }))
            )
        );
    }

    async updateConsumedLinks(linkId, consumedLinks) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.updateConsumedLinks,
                JSON.stringify(this.getAllDefinedKeys({
                    linkId: Constants.LINK_INSTANCE_ID_PATTERN + linkId,
                    consumedLinks
                }))
            )
        );
    }

    async listFiles(NextToken = null) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.listFiles,
                JSON.stringify(this.getAllDefinedKeys({
                    MaxResults: MAX_OBJECTS[API_IDENTIFIERS.listFiles],
                    NextToken
                }))
            )
        );
    }

    async listLinks(NextToken = null) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.listLinks,
                JSON.stringify(this.getAllDefinedKeys({
                    MaxResults: MAX_OBJECTS[API_IDENTIFIERS.listLinks],
                    NextToken
                }))
            )
        );
    }

    async updateLinkEnds(linkId, endNames, handleThirdPartyLinks = false) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.updateLinkEnds,
                JSON.stringify(this.getAllDefinedKeys({
                    LinkId: linkId,
                    EndNames: endNames,
                    HandleThirdPartyLinks: handleThirdPartyLinks
                }))
            )
        );
    }

    async updateAttributes(linkId, attributes) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.updateAttributes,
                JSON.stringify(this.getAllDefinedKeys({
                    linkId,
                    attributes
                }))
            )
        );
    }

    async updateLifeCycle(LinkId, LifecycleState, HandleThirdPartyLinks) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.updateLifeCycle,
                JSON.stringify(this.getAllDefinedKeys({
                    LinkId,
                    LifecycleState,
                    HandleThirdPartyLinks,
                    Actor: TEMPORARY_DUMMY_ACTOR
                }))
            )
        );
    }

    async updateEncryptionCapability(linkId, encryptionCapability) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.updateEncryptionCapability,
                JSON.stringify(this.getAllDefinedKeys({
                    linkId,
                    encryptionCapability
                }))
            )
        );
    }

    async updateEncryptionIntent(linkInstanceId, intent) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.updateEncryptionIntent,
                JSON.stringify(this.getAllDefinedKeys({
                    linkInstanceId,
                    intent
                }))
            )
        );
    }

    async searchLinksWithHierarchies(SearchTerm, NextToken = null) {
        return this.addDisplayableLinkFieldsForBatchResults(await this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.searchLinksWithHierarchies,
                JSON.stringify(this.getAllDefinedKeys({
                    SearchTerm,
                    MaxResults: MAX_OBJECTS[API_IDENTIFIERS.searchLinksWithHierarchies],
                    NextToken
                }))
            )
        ));
    }

    async searchLinks(SearchTerm, NextToken, Paginate = false) {
        return this.addDisplayableLinkFieldsForBatchResults(await this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.searchLinks,
                JSON.stringify(this.getAllDefinedKeys({
                    SearchTerm,
                    MaxResults: MAX_OBJECTS[API_IDENTIFIERS.searchLinks],
                    NextToken,
                    Paginate
                }))
            )
        ));
    }

    async getIspData(SearchTerm, CutsheetType, NextToken = null) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.getIspData,
                JSON.stringify(this.getAllDefinedKeys({
                    SearchTerm,
                    CutsheetType,
                    MaxResults: MAX_OBJECTS[API_IDENTIFIERS.searchLinksWithHierarchies],
                    NextToken
                }))
            )
        );
    }

    async batchPutLinks(Links) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.batchPutLinks,
                JSON.stringify(this.getAllDefinedKeys({
                    Actor: TEMPORARY_DUMMY_ACTOR,
                    Links
                }))
            )
        );
    }

    async getLinkInstance(linkId) {
        const response = await this.getBatchLinkInstance([linkId]);
        return response.Links[0];
    }

    async getLinkHistory(LinkId, StartVersion, MaxResults) {
        return this.addDisplayableLinkFieldsForBatchResults(await this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.getLinkHistory,
                JSON.stringify(this.getAllDefinedKeys({
                    LinkId,
                    MaxResults,
                    StartVersion
                }))
            )
        ));
    }

    async getLinkRouterToDwdmInfo(linkId) {
        const [link, getConsumedLinksResponse] =
            await Promise.all([this.getLinkInstance(linkId), this.getConsumedLinks(linkId)]);
        const linkType = l => HelperFunctions.getValueFromRecordAttributes(l, Constants.ATTRIBUTES.linkType);
        if (linkType(link) !== Constants.LINK_TYPES_IN_ATTRIBUTES.routerToRouter) {
            return null;
        }

        const getBatchLinkInstanceResponse = await this.getBatchLinkInstance(getConsumedLinksResponse.consumedLinks);
        const consumedLinks = getBatchLinkInstanceResponse.Links;
        const linksInLinkTypeMap = {
            [Constants.LINK_TYPES.routerToRouter]: [link],
            [Constants.LINK_TYPES.routerToDWDM]: consumedLinks
        };
        const [linkWithClientPortInfo] = LinkHierarchyHelper.addClientPortsToRouterToRouterLinks(linksInLinkTypeMap);
        const { aEndClientPort, bEndClientPort } = linkWithClientPortInfo;
        const routerToDWDMLinks = consumedLinks.filter(consumedLink =>
            linkType(consumedLink) === Constants.LINK_TYPES_IN_ATTRIBUTES.routerToDWDM);
        const [aEndRouterToDwdmLink] =
            routerToDWDMLinks.filter(l => l.aEndPort === aEndClientPort || l.bEndPort === aEndClientPort);
        const [bEndRouterToDwdmLink] =
            routerToDWDMLinks.filter(l => l.aEndPort === bEndClientPort || l.bEndPort === bEndClientPort);

        return {
            aEndClientPort,
            bEndClientPort,
            aEndRouterToDwdmLinkId: aEndRouterToDwdmLink?.instanceId,
            bEndRouterToDwdmLinkId: bEndRouterToDwdmLink?.instanceId,
            bEndSiteName: HelperFunctions.getSiteNameFromPort(bEndClientPort || "")
        };
    }

    /**
     * This batch function takes care of chunkifying the IDs to the max which is 100 objects per batch.
     * @param linkIds
     * @returns {Promise<{Links: *[], FailedLinks: *[]}>}
     */
    async getBatchLinkInstance(linkIds) {
        // The response from the API has two field: FailedLinks and Links
        const response = { FailedLinks: [], Links: [] };
        const chunks = HelperFunctions.chunkifyList(linkIds, MAX_BATCH_OBJECTS);

        // TODO there must be a way to do this Promise.all() and map() so its in parallel and writes to a list
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
            // eslint-disable-next-line no-await-in-loop
            const linksFromChunk = this.addDisplayableLinkFieldsForBatchResults(await this.callLinkService(
                this.constructRequest(
                    API_IDENTIFIERS.batchGetLinks,
                    JSON.stringify(this.getAllDefinedKeys({
                        LinkIDs: chunk.map(linkId => HelperFunctions.createFullLinkInstanceId(linkId))
                    }))
                )
            ));

            response.Links.push(...linksFromChunk.Links);
            response.FailedLinks.push(...linksFromChunk.FailedLinks);
        }

        return response;
    }

    async listConsumers(linkId) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.listConsumers,
                JSON.stringify(this.getAllDefinedKeys({ LinkId: linkId, MaxResults: 30 }))
            )
        );
    }

    /**
     * This batch function takes care of chunkifying the IDs and performs soft deletion
     * @param linkIds
     * @returns {Promise<{FailedLinks: *[]}>}
     */
    async batchDeleteLinks(linkIds) {
        const response = { FailedLinks: [] };
        const chunks = HelperFunctions.chunkifyList(linkIds, MAX_DELETE_BATCH_OBJECTS);

        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
            // eslint-disable-next-line no-await-in-loop
            const linksFromChunk = await this.callLinkService(
                this.constructRequest(
                    API_IDENTIFIERS.batchDeleteLinks,
                    JSON.stringify(this.getAllDefinedKeys({
                        linkIds: chunk.map(item => Constants.LINK_INSTANCE_ID_PATTERN + item.instanceId)
                    }))
                )
            );
            response.FailedLinks.push(...linksFromChunk.FailedLinks);
        }
        return response;
    }

    async deleteLinkRecord(linkId) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.deleteLinkInstance,
                JSON.stringify(this.getAllDefinedKeys({ linkId }))
            )
        );
    }

    async getLinkHierarchy(LinkId) {
        return this.addDisplayableLinkFieldsForBatchResults(await this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.getLinkHierarchy,
                JSON.stringify(this.getAllDefinedKeys({ LinkId }))
            )
        ));
    }

    async quickSightGetEmbedUrlForRegisteredUser(QuickSightDashboardId) {
        return this.callLinkService(
            this.constructRequest(
                API_IDENTIFIERS.quickSightGetEmbedUrlForRegisteredUser,
                JSON.stringify(this.getAllDefinedKeys({
                    QuickSightDashboardId
                }))
            )
        );
    }

    /* Fault-tolerant wrapper to call link service APIs */
    async callLinkService(request, headers = HEADERS) {
        // Try to send the request with existing creds. If that fails, refresh the id_token and retry
        try {
            return this.checkStatus(await this.sendLinkServiceApiRequest(request, headers));
        } catch (e) {
            if (
                e.message === Constants.ERROR_STRINGS.FAILED_TO_FETCH ||
                e.message === Constants.FLASHBAR_STRINGS.flashbarMidwayError ||
                e.message.includes(Constants.FLASHBAR_STRINGS.networkError)
            ) {
                await this.refreshMidwayCookie();
            } else {
                throw e;
            }
        }
        return this.checkStatus(await this.sendLinkServiceApiRequest(request, headers));
    }

    /* Sends an RPC call to LinkServiceProxy */
    sendLinkServiceApiRequest(request, headers) {
        const controller = this.createAbortController();
        return this.timeoutWrapper(
            fetch(API_ENDPOINT, {
                method: "POST",
                body: JSON.stringify(request),
                mode: "cors",
                headers, // Headers have to be an argument, providing the constant here results in a 404
                signal: controller.signal,
                redirect: "follow",
                credentials: "include"
            }),
            controller
        );
    }

    // This is just a GET request with "no-cors" and "safe" headers to refresh midway id_token
    // https://fetch.spec.whatwg.org/#cors-safelisted-request-header
    async refreshMidwayCookie() {
        const controller = this.createAbortController();
        await this.timeoutWrapper(
            fetch(API_ENDPOINT, {
                mode: "no-cors",
                signal: controller.signal,
                redirect: "follow",
                credentials: "include"
            }),
            controller
        );
    }

    /*
    * This is a generic method used for checking the response object and based on that
    * returns response back or throws out an error
    */
    async checkStatus(response) {
        if (response.ok) {
            return this.getSuccessResponse(response);
            // If you receive an authentication error return a message asking to refresh Midway credentials
        } else if (response.status === 401) {
            throw new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError);
        }
        return this.throwErrorWithCorrectResponse(response);
    }

    getSuccessResponse = async (response) => {
        const jsonView = await response.json();
        // All successful calls from LinkServiceProxy nest the response inside of
        // the `linkResponse` key. We break into that here so that every activity
        // does not have to do so and expose the underlying structure of the response
        return JSON.parse(jsonView.linkResponse);
    }

    throwErrorWithCorrectResponse = async (response) => {
        let errorMessage = DEFAULT_ERROR_MESSAGE;
        try {
            // 400 Error responses from the proxy contain a message and a type.
            // However, the message further nests the type and message so we have to parse
            // it twice to only show the error message to the customer
            const jsonResponse = await response.json();
            const messageObject = JSON.parse(jsonResponse.message);

            const typeField = "__type";

            // We only assign the message value to the errorMessage we show to the customer if it is nonNull. In
            // the case of a 500 error, the type will be present but the message will not be so we stick with
            // the default customer readable message
            if (messageObject.message) {
                errorMessage = messageObject.message;
            } else if (messageObject[typeField]) {
                // If there is no message attached in the error, we append the type to the human readable error we
                // show the customer to help with debugging
                errorMessage = `${errorMessage} Error Type: ${messageObject[typeField]}`;
            }
        } catch (e) {
            // If we fail to process the response as JSON there is something else going wrong and we will need
            // to investigate what the issue is
            // eslint-disable-next-line no-console
            console.log(response);
            // eslint-disable-next-line no-console
            console.log(e);
        }

        throw new Error(errorMessage);
    };

    addDisplayableLinkFieldsForSingleLink = link => this.addDisplayableLinkFields([link])[0];

    /**
     * Many fields inside of the link object cannot be easily displayed to a customer without further processing.
     * We do that here so that all components who handle links do not have to modify them.
     * @param links
     */
    addDisplayableLinkFields = (links) => {
        links.forEach((link) => {
            // LinkService does not have the concept of A side and B side. Since customer are used to this view,
            // we provide a consistent experience by always sorting the ends based off of the endId.
            // TODO maybe sort by the endName if that is available?
            const sortedLinkEnds = HelperFunctions.sortObjectsByField(link.ends, Constants.ATTRIBUTES.endId);
            Object.assign(
                link,
                {
                    // TODO we can create the link type field here based on the typeId and environment
                    attributesToDisplay: link.attributes.map(attribute =>
                        ({ key: attribute.key, value: this.getAttributeValue(attribute) })),
                    aEndPort: sortedLinkEnds[0].endId,
                    bEndPort: sortedLinkEnds[1].endId,
                    readableLinkType: LinkHierarchyHelper.getReadableLinkTypeFromTypeIdAndStage(link.typeId),
                    isNonEditable: nonEditableLinks.includes(link.instanceId)
                }
            );
        });
        return links;
    }

    addDisplayableLinkFieldsForBatchResults = (links) => {
        if (Object.keys(links).length === 0 || links.Links === undefined) {
            return links;
        }
        links.Links.forEach((link) => {
            // Get end names from attributes
            const aEnd = link.attributes.filter(attribute => attribute.key === "end_a_nsm_name");
            const bEnd = link.attributes.filter(attribute => attribute.key === "end_z_nsm_name");
            Object.assign(
                link,
                {
                    attributesToDisplay: link.attributes.map(attribute =>
                        ({ key: attribute.key, value: this.getAttributeValue(attribute) })),
                    readableLinkType: LinkHierarchyHelper.getReadableLinkTypeFromTypeIdAndStage(link.typeId),
                    isNonEditable: nonEditableLinks.includes(link.instanceId)
                }
            );

            // Not all links have aEnd and bEnd, example passive-to-passive
            if (aEnd.length > 0 && bEnd.length > 0) {
                Object.assign(
                    link,
                    {
                        aEndPort: this.getAttributeValue(aEnd[0]),
                        bEndPort: this.getAttributeValue(bEnd[0])
                    }
                );
            }
        });
        return links;
    }

    getAttributeValue = (attribute) => {
        if (attribute.value.type === Constants.ATTRIBUTES_TYPES.boolean) {
            return attribute.value.booleanValue;
        }
        if (attribute.value.type === Constants.ATTRIBUTES_TYPES.integer) {
            return attribute.value.integerValue;
        }
        return attribute.value.stringValue;
    }

    /*
    *  This function wraps a fetch request in a timeout. For now, leave the time period as the same for every call.
    *  If this needs to change later we can make it a parameter to this wrapper.
    */
    timeoutWrapper = async (wrappedFetchRequest, controller) => {
        const timeout = setTimeout(() => controller.abort(), LinkServiceBackendClient.TIMEOUT);
        const response = await wrappedFetchRequest;
        clearTimeout(timeout);
        return response;
    };

    /*
    *   This function creates a unique AbortController object for every fetch request. This ensures that if one request
    *   aborts, other requests on the same page will retry and not immediately get aborted as well.
    */
    createAbortController = () => (
        new AbortController()
    );

    /*
     * Removes all keys where the value is null or undefined
     */
    getAllDefinedKeys = (request) => {
        const prunedRequest = HelperFunctions.deepClone(request);
        Object.keys(prunedRequest).forEach((key) => {
            // We need to check for undefined as opposed to `!key` because we also have some boolean fields, like lacp
            if (prunedRequest[key] === undefined || prunedRequest[key] === null) {
                delete prunedRequest[key];
            }
        });
        return prunedRequest;
    };
}