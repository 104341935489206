import React from "react";
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontBox,
    FremontButton,
    FremontTextarea,
    requiredLabel,
    ComponentConstants
} from "utils/CommonComponents";
import { StageHeader } from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const PathDesignInfoStageDisplayMode = props => (
    <div className="fremont-stage-spacing" ref={Constants.DEPENDENCY_LINK_REFERENCES[props.stageName].ref}>
        <Container
            header={
                <StageHeader
                    order={props.order}
                    disableEditButton={props.orderCompleted || props.editButtonsDisabled}
                    stageName={props.stageName}
                    handleStageEditClick={props.handleStageEditClick}
                    goToComponentAction={props.goToComponentAction}
                    handleCompleteStage={!props.order.opticalDesignString && props.handleCompleteStage}
                    hasStageBeenCompleted={props.hasStageBeenCompleted}
                    completeStageMessage={props.completeStageMessage}
                />
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <FremontBox label="Optical Design String"/>
                        <div>{props.order.opticalDesignString}</div>
                    </div>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);

export const PathDesignInfoStageEditMode = props => (
    <div className="fremont-stage-spacing">
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                            <FremontButton
                                id={props.stageName}
                                variant="normal"
                                onClick={props.handleStageEditClick}
                                disabled={props.isUpdateInProgress}
                            >
                                Cancel
                            </FremontButton>
                            <FremontButton
                                id={props.stageName}
                                loading={props.isUpdateInProgress}
                                variant="primary"
                                onClick={props.handleStageSubmit}
                            >
                                {props.isUpdateInProgress ? "Submitting..." : "Submit"}
                            </FremontButton>
                        </SpaceBetween>
                    }
                >
                    Path Design
                    {HelperFunctions.showStageStatusWithBlocker(props.updatedOrder
                        .stageStatusMap[props.stageName])
                    }
                </Header>
            }
        >
            <div>
                <ColumnLayout columns={2} borders="vertical">
                    <FormField
                        label={requiredLabel("Optical Design String:")}
                        errorText={
                            Object.keys(props.errorTexts).length === 0 ? "" :
                                props.errorTexts[Constants.ATTRIBUTES.opticalDesignString]
                        }
                        stretch
                    >
                        <FremontTextarea
                            id={Constants.ATTRIBUTES.opticalDesignString}
                            disabled={props.isUpdateStageInProgress}
                            value={props.updatedOrder.opticalDesignString}
                            onInput={props.handleStageInputChange}
                        />
                    </FormField>
                </ColumnLayout>
            </div>
        </Container>
    </div>
);
