import React, { Component } from "react";
import generateCarrierNotificationStageColumnDefinitions from "order/stages/decom/CarrierNotificationInformation";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import {
    StageDisplayMode,
    TableDisplayMode,
    StageEditMode,
    TableEditMode
} from "order/OrderCommonComponents";

export default class CarrierNotificationHandler extends Component {
    state = {
        updatedCircuitDesignObjects: [],
        isEditClicked: false,
        isUpdateStageInProgress: false,
        hasCarrierNotificationStageSubmittedOnce: false,
        massUpdateSelectedCircuitDesignIds: []
    };

    FremontBackendClient = new FremontBackendClient();

    generateCircuitItems = () => {
        const carrierNotificationStageCircuitItemsObject = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasCarrierNotificationStageSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.isUpdateStageInProgress
        );

        // Depending on whether we are iterating through the dynamicCircuitItems or the staticCircuitItems, we need
        // to look up circuits that are held inside of different lists. Instead of a helper function, we just
        // utilize a simple map
        const associateCircuitObjects = {
            dynamic: this.state.updatedCircuitDesignObjects,
            static: this.props.circuitDesignObjects
        };

        // Here we assign additional values that will be needed to display each circuit in our tables
        Object.keys(carrierNotificationStageCircuitItemsObject).forEach(itemArrayName =>
            carrierNotificationStageCircuitItemsObject[itemArrayName].forEach((circuitRowItem) => {
                // Here we query the map to obtain the correct circuitDesign's positionMap
                const positionMap = associateCircuitObjects[itemArrayName].find(circuitObject =>
                    circuitObject[Constants.ATTRIBUTES.circuitDesignId] ===
                    circuitRowItem[Constants.ATTRIBUTES.circuitDesignId])[Constants.ATTRIBUTES.positionMap];
                Object.assign(circuitRowItem, {
                    // Obtain the appropriate node object
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap, positionMap, Constants.COMPONENT_NAMES.nodeA
                    ),
                    // Obtain the appropriate port object
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap, positionMap, Constants.COMPONENT_NAMES.portA
                    ),
                    // Obtain the appropriate lag object
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap, positionMap, Constants.COMPONENT_NAMES.lagA
                    ),
                    // Obtain the appropriate node object
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap, positionMap, Constants.COMPONENT_NAMES.nodeZ
                    ),
                    // Obtain the appropriate port object
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap, positionMap, Constants.COMPONENT_NAMES.portZ
                    ),
                    // Obtain the appropriate provider circuit object
                    [Constants.COMPONENT_NAMES.providerCircuitA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap, positionMap, Constants.COMPONENT_NAMES.providerCircuitA
                    ),
                    // Obtain the portBandwidth (just incase the circuitBandwidth is not available)
                    [Constants.ATTRIBUTES.portBandwidth]: HelperFunctions.getAttributeFromComponent(
                        this.props.componentIdToObjectMap, positionMap, Constants.COMPONENT_NAMES.portA,
                        Constants.ATTRIBUTES.portBandwidth
                    )
                });
            }));

        return carrierNotificationStageCircuitItemsObject;
    };

    handleStageEditClick = () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        this.setState({
            isEditClicked: !this.state.isEditClicked,
            massUpdateSelectedCircuitDesignIds: [],
            isUpdateStageInProgress: false,
            hasCarrierNotificationStageSubmittedOnce: false,
            updatedCircuitDesignObjects
        });
    };

    handleStageInputChange = (evt) => {
        const input = {};
        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(this.state.massUpdateSelectedCircuitDesignIds,
            input, circuitDesignOutput.circuitDesignObjects, this.state.updatedCircuitDesignObjects);
        this.setState({ updatedCircuitDesignObjects: output });
    };

    handleSelectedFromTable = (evt) => {
        // This function is called after user check marks billing segments
        // and adds them to the edit list
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            isUpdateStageInProgress: true,
            hasCarrierNotificationStageSubmittedOnce: true
        });
        const circuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        // Only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
        const circuitsToUpdate = HelperFunctions.createNewApiObjectsCircuitWrapperForStage(
            circuitDesignObjects, updatedCircuitDesignObjects
        );
        try {
            if (circuitsToUpdate.length > 0) {
                // Here we update all of the changed circuits
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            // Display flashbar success
            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText, false, false
            );
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
        } catch (error) {
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                isUpdateStageInProgress: false,
                isEditClicked: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.carrierNotification}
                    showAttachmentModal={false}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    content={
                        <TableDisplayMode
                            order={this.props.order}
                            stageName={Constants.STAGE_NAMES.carrierNotification}
                            circuitItems={this.generateCircuitItems().static}
                            columnDefinitions={generateCarrierNotificationStageColumnDefinitions(
                                this.generateCircuitItems().static.length,
                                this.props.order[Constants.ATTRIBUTES.serviceType],
                                this.props.order[Constants.ATTRIBUTES.customerFabric]
                            )}
                            isDataLoaded={this.props.isDataLoaded}
                        />
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.carrierNotification}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <TableEditMode
                            circuitItems={this.generateCircuitItems().dynamic}
                            columnDefinitions={generateCarrierNotificationStageColumnDefinitions(
                                this.generateCircuitItems().static.length,
                                this.props.order[Constants.ATTRIBUTES.serviceType],
                                this.props.order[Constants.ATTRIBUTES.customerFabric]
                            )}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
        );
    }
}