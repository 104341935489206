import React, { Component } from "react";
import CutsheetTemplateDownloadHandler from "./CutsheetTemplateDownloadHandler";
import CutsheetUploadHandler from "./CutsheetUploadHandler";
import HelperFunctions from "../common/HelperFunctions";
import LinkServiceHeader from "../common/LinkServiceHeader";
import LinkServiceTable from "../components/LinkServiceTable";
import CutsheetTableData from "../cutsheet/CutsheetTableData";
import Constants from "../utils/Constants";
import LinkServiceBackendClient from "../common/LinkServiceBackendClient";

export default class CutsheetTablePage extends Component {
    state = {
        flashbar: {
            type: "",
            text: ""
        },
        files: [],
        isCutsheetTemplateDownloadButtonClicked: false,
        isCutsheetUploadButtonClicked: false,
        isDownloadingFile: false,
        fetchingFiles: true,
        nextToken: null
    };

    componentDidMount = async () => {
        // We make network calls to fetch files as soon as the component loads to minimize latency
        await this.fetchFiles();
    }

    linkServiceBackendClient = new LinkServiceBackendClient()

    hideCutsheetTemplateDownloadModal = () => {
        this.setState({ isCutsheetTemplateDownloadButtonClicked: false });
    }

    hideCutsheetUploadModal = () => {
        this.setState({ isCutsheetUploadButtonClicked: false });
    }

    cutsheetTemplateDownloadButtonClicked = () => {
        this.setState({
            isCutsheetTemplateDownloadButtonClicked: true
        });
    }

    cutsheetUploadButtonClicked = () => {
        this.setState({
            isCutsheetUploadButtonClicked: true
        });
    }

    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    fetchFiles = async () => {
        HelperFunctions.dismissFlashbar(this, { fetchingFiles: true });
        try {
            const listFilesResponse = await this.linkServiceBackendClient.listFiles(this.state.nextToken);
            this.setState({
                files: this.state.files.concat(HelperFunctions.sortFiles(listFilesResponse.Files)
                    .filter(file => !!file).map(file =>
                        Object.assign(
                            file,
                            {
                                downloadFile: this.downloadFile,
                                isDownloadingFile: this.state.isDownloadingFile
                            }
                        ))),
                nextToken: listFilesResponse.NextToken
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, error);
        }
        this.setState({ fetchingFiles: false });
    }

    downloadFile = async (fileId) => {
        HelperFunctions.dismissFlashbar(this, { isDownloadingFile: true });
        try {
            const getFileResponse = await this.linkServiceBackendClient.getFileDownloadUrl(fileId);

            // I wanted to use XMLHttpRequest, since that is what we use to upload the files, but even the presence of
            // the Content-Disposition didn't help in downloading the file. So the workaround here is creating an <a>
            // element and "clicking" it to download in the browser. The <a> element is simply an anchor element that
            // contains and href element. We assign our presigned url as the href of this newly created element and
            // thats all it takes to download it.
            // More on it not working with XMLHttpRequest: https://stackoverflow.com/a/22738657
            fetch(getFileResponse.PresignedUrl).then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const anchorElement = document.createElement("a");
                    anchorElement.href = url;
                    anchorElement.download = getFileResponse.FileName;
                    anchorElement.click();
                    anchorElement.remove();
                });
            });
        } catch (error) {
            HelperFunctions.displayFlashbarError(this, { message: "Unable to download the attachment." });
        }

        this.setState({ isDownloadingFile: false });
    };

    render() {
        return (
            <div>
                <LinkServiceHeader
                    history={this.props.history}
                    flashbarType={this.state.flashbar.type}
                    flashbarText={this.state.flashbar.text}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <LinkServiceTable
                        entity="Cutsheet"
                        tableItems={this.state.files}
                        columnDefinitions={CutsheetTableData.COLUMN_DEFINITIONS}
                        downloadEntityLink={this.cutsheetTemplateDownloadButtonClicked}
                        uploadEntityLink={this.cutsheetUploadButtonClicked}
                        loading={this.state.fetchingFiles}
                        loadMoreEnabled={false}
                        nextToken={this.state.nextToken}
                        onNextPageClickHelper={{
                            nextToken: this.state.nextToken, fetch: async () => this.fetchFiles()
                        }}
                    />
                    <CutsheetUploadHandler
                        isCutsheetUploadButtonClicked={this.state.isCutsheetUploadButtonClicked}
                        hideCutsheetUploadModal={this.hideCutsheetUploadModal}
                        user={this.props.user}
                    />
                    <CutsheetTemplateDownloadHandler
                        isCutsheetTemplateDownloadButtonClicked={this.state.isCutsheetTemplateDownloadButtonClicked}
                        hideCutsheetTemplateDownloadModal={this.hideCutsheetTemplateDownloadModal}
                        user={this.props.user}
                    />
                </div>
            </div>
        );
    }
}