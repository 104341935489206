import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import AddressInformation from "address/AddressInformation";
import Constants from "utils/Constants";
import FremontComponentInfo from "common/FremontComponentInfo";
import HelperFunctions from "common/HelperFunctions";
import { SiteFormSection } from "site/SiteForm";
import {
    ComponentConstants,
    FremontButton
} from "utils/CommonComponents";

/**
 * This component serves as the main container holding all of the site information
 */
const SiteInformation = props => (
    <SiteInfoContainer
        site={props.site}
        updatedSite={props.updatedSite}
        siteErrorTexts={props.siteErrorTexts}
        isUpdateSiteInfoEditClicked={props.isUpdateSiteInfoEditClicked}
        isUpdateSiteInfoInProgress={props.isUpdateSiteInfoInProgress}
        isUserHighlyConfidential={props.isUserHighlyConfidential}
        handleUpdateAddressInputChange={props.handleUpdateAddressInputChange}
        handleUpdateSiteEdit={props.handleUpdateSiteEdit}
        handleUpdateSiteInputChange={props.handleUpdateSiteInputChange}
        handleUpdateSiteSubmit={props.handleUpdateSiteSubmit}
        hasUpdateSiteSubmittedOnce={props.hasUpdateSiteSubmittedOnce}
        providersDisabled={props.providersDisabled}
        providersLoading={props.providersLoading}
        providerOptions={props.providerOptions}
    />
);

/**
 * This component holds the site display and edit modes
 */
export const SiteInfoContainer = (props) => {
    if (props.isUpdateSiteInfoEditClicked) {
        return (
            <SiteInfoEditMode
                isUpdateSiteInfoInProgress={props.isUpdateSiteInfoInProgress}
                isUserHighlyConfidential={props.isUserHighlyConfidential}
                updatedSite={props.updatedSite}
                siteErrorTexts={props.siteErrorTexts}
                handleUpdateAddressInputChange={props.handleUpdateAddressInputChange}
                handleUpdateSiteEdit={props.handleUpdateSiteEdit}
                handleUpdateSiteInputChange={props.handleUpdateSiteInputChange}
                handleUpdateSiteSubmit={props.handleUpdateSiteSubmit}
                hasUpdateSiteSubmittedOnce={props.hasUpdateSiteSubmittedOnce}
                providersDisabled={props.providersDisabled}
                providersLoading={props.providersLoading}
                providerOptions={props.providerOptions}
            />
        );
    }
    return (
        <SiteInfoDisplayMode
            isUserHighlyConfidential={props.isUserHighlyConfidential}
            site={props.site}
            handleUpdateSiteEdit={props.handleUpdateSiteEdit}
        />
    );
};

/**
 * This component displays the site info
 */
export const SiteInfoDisplayMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <FremontButton
                        id="editSiteInfoButton"
                        iconName="edit"
                        variant="primary"
                        onClick={props.handleUpdateSiteEdit}
                    />
                }
            >
                Site Information
            </Header>
        }
    >
        <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
            <Container
                header={
                    <Header variant="h2">
                        General Information
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Site Name:</Box>
                            <div>{props.site[Constants.ATTRIBUTES.siteName]}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Status:</Box>
                            <div>{props.site[Constants.ATTRIBUTES.status]}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Colocation Site Name:</Box>
                            <div>{props.site[Constants.ATTRIBUTES.coloSiteName] || "-"}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Quarterly Spend:</Box>
                            <div>{props.site[Constants.ATTRIBUTES.quarterlySpend] || "-"}</div>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Site Type:</Box>
                            <div>{props.site[Constants.ATTRIBUTES.siteType]}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Market:</Box>
                            <div>{props.site[Constants.ATTRIBUTES.market]}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">CLLI Code:</Box>
                            <div>{props.site[Constants.ATTRIBUTES.clliCode] || "-"}</div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Managed Site:</Box>
                            <div>
                                {HelperFunctions.booleanToYesNo(props.site[Constants.ATTRIBUTES.managedSite])}
                            </div>
                        </div>
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Description:</Box>
                            <div className="pre-wrap">
                                {props.site[Constants.ATTRIBUTES.description] || "-"}
                            </div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header variant="h2">
                        Location
                    </Header>
                }
            >
                <SpaceBetween size="s">
                    <ColumnLayout columns={2} borders="vertical">
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Geographic Region:</Box>
                                <div>{props.site[Constants.ATTRIBUTES.geographicRegion]}</div>
                            </div>
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">Cluster:</Box>
                                <div>{props.site[Constants.ATTRIBUTES.cluster]}</div>
                            </div>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <div>
                                <Box margin={{ bottom: "xxxs" }} color="text-label">AWS Region:</Box>
                                <div>{props.site[Constants.ATTRIBUTES.awsRegion] || "-"}</div>
                            </div>
                        </SpaceBetween>
                    </ColumnLayout>
                    {props.isUserHighlyConfidential &&
                    <Container
                        header={
                            <Header variant="h2">
                                Address
                            </Header>
                        }
                    >
                        <AddressInformation
                            hasSubmittedOnce={props.hasUpdateSiteSubmittedOnce}
                            isSubmissionInProgress={props.isUpdateSiteInfoInProgress}
                            address={props.site.address}
                            isSiteInfo={props.site[Constants.ATTRIBUTES.siteId]}
                            handleAddressInputChange={props.handleUpdateAddressInputChange}
                            errorTexts={props.siteErrorTexts}
                        />
                    </Container>
                    }
                </SpaceBetween>
            </Container>
            <Container
                header={
                    <Header variant="h2">
                        Ownership / Association
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Building Owner:</Box>
                            <div>
                                {props.site[Constants.ATTRIBUTES.buildingOwner] ?
                                    <Link href={`${Constants.ROUTES.provider}/${props.site[Constants.ATTRIBUTES.buildingOwner]}`}>
                                        {props.site[Constants.ATTRIBUTES.buildingOwner]}
                                    </Link> : "-"
                                }
                            </div>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: "xxxs" }} color="text-label">Colocation Owner:</Box>
                            <div>
                                {props.site[Constants.ATTRIBUTES.colocationOwner] ?
                                    <Link href={`${Constants.ROUTES.provider}/${props.site[Constants.ATTRIBUTES.colocationOwner]}`}>
                                        {props.site[Constants.ATTRIBUTES.colocationOwner]}
                                    </Link> : "-"
                                }
                            </div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
        </SpaceBetween>
        <FremontComponentInfo
            modifiedBy={props.site[Constants.ATTRIBUTES.modifiedBy]}
            modifiedTime={props.site[Constants.ATTRIBUTES.modifiedTime]}
            createdBy={props.site[Constants.ATTRIBUTES.createdBy]}
            createdTime={props.site[Constants.ATTRIBUTES.createdTime]}
        />
    </Container>
);

/**
 * This component allows the user to edit site information
 */
export const SiteInfoEditMode = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            disabled={props.isUpdateSiteInfoInProgress}
                            variant="normal"
                            onClick={props.handleUpdateSiteEdit}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id="submitSiteInfoButton"
                            loading={props.isUpdateSiteInfoInProgress}
                            variant="primary"
                            onClick={props.handleUpdateSiteSubmit}
                        >
                            {props.isUpdateSiteInfoInProgress ? "Submitting..." : "Submit"}
                        </FremontButton>
                    </SpaceBetween>
                }
            >
                Site Information
            </Header>
        }
    >
        <SiteFormSection
            editMode
            site={props.updatedSite}
            siteErrorTexts={props.siteErrorTexts}
            allFieldsDisabled={props.isUpdateSiteInfoInProgress}
            showAddressForm={props.isUserHighlyConfidential}
            handleSiteInputChange={props.handleUpdateSiteInputChange}
            handleAddressInputChange={props.handleUpdateAddressInputChange}
            providersLoading={props.providersLoading}
            providerOptions={props.providerOptions}
        />
    </Container>
);

export default SiteInformation;