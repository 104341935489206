import Constants from "utils/Constants";
import DevEnvironment from "common/DevEnvironment";
import HelperFunctions from "common/HelperFunctions";

const { AbortController } = window;

/*
 * If you are testing locally using bb server, please replace the below endpoint with your api endpoint,
 * this is available as part of your api gateway custom domain for which you need to have your FremontLambda
 * and FremontInfrastructure deployed to your account first. A sample api gateway endpoint would look like
 * '{YOUR_USER_ALIAS}.test.api.fremont.networking.aws.a2z.com'
 */
const UI_API_GATEWAY_ENDPOINTS = {
    // Lighthouse
    "prod.lighthouse.networking.aws.dev": "https://prod.ui.fremont.networking.aws.a2z.com",
    "prod-cn.lighthouse.networking.aws.dev": "https://prod-cn.ui.fremont.networking.aws.a2z.com",
    "gamma.lighthouse.networking.aws.dev": "https://gamma.ui.fremont.networking.aws.a2z.com",
    "gamma-cn.lighthouse.networking.aws.dev": "https://gamma-cn.ui.fremont.networking.aws.a2z.com",
    "beta.lighthouse.networking.aws.dev": "https://beta.ui.fremont.networking.aws.a2z.com",
    "beta-cn.lighthouse.networking.aws.dev": "https://beta-cn.ui.fremont.networking.aws.a2z.com",
    [`${DevEnvironment.YOUR_ALIAS}.test.lighthouse.networking.aws.dev`]:
        `https://${DevEnvironment.YOUR_ALIAS}.test.ui.fremont.networking.aws.a2z.com`,

    // Fremont
    "prod.fremont.networking.aws.a2z.com": "https://prod.ui.fremont.networking.aws.a2z.com",
    "prod-cn.fremont.networking.aws.a2z.com": "https://prod-cn.ui.fremont.networking.aws.a2z.com",
    "gamma.fremont.networking.aws.a2z.com": "https://gamma.ui.fremont.networking.aws.a2z.com",
    "gamma-cn.fremont.networking.aws.a2z.com": "https://gamma-cn.ui.fremont.networking.aws.a2z.com",
    "beta.fremont.networking.aws.a2z.com": "https://beta.ui.fremont.networking.aws.a2z.com",
    "beta-cn.fremont.networking.aws.a2z.com": "https://beta-cn.ui.fremont.networking.aws.a2z.com",
    [`${DevEnvironment.YOUR_ALIAS}.test.fremont.networking.aws.a2z.com`]:
        `https://${DevEnvironment.YOUR_ALIAS}.test.ui.fremont.networking.aws.a2z.com`,

    /**
     * While developing locally, you may want to connect to your own stack, beta or gamma.
     * Uncomment the respective config.
     *
     * Be sure to also update HelperFunctions to determine the env config
     */
    "localhost:8080": `${DevEnvironment.UI_API_GATEWAY_ENDPOINT}`
    // "localhost:8080": "https://beta.ui.fremont.networking.aws.a2z.com"
    // "localhost:8080": "https://beta-cn.ui.fremont.networking.aws.a2z.com"
    // "localhost:8080": "https://gamma.ui.fremont.networking.aws.a2z.com"
    // "localhost:8080": "https://gamma-cn.ui.fremont.networking.aws.a2z.com"
};

/*
 * If you are testing locally using bb server, please replace the below endpoint with your api endpoint,
 * this is available as part of your api gateway custom domain for which you need to have your FremontLambda
 * and FremontInfrastructure deployed to your account first. A sample api gateway endpoint would look like
 * '{YOUR_USER_ALIAS}.test.api.fremont.networking.aws.a2z.com'
 */
const OPERATIONS_API_GATEWAY_ENDPOINTS = {
    // Lighthouse
    "prod.lighthouse.networking.aws.dev": "https://prod.operations.fremont.networking.aws.a2z.com",
    "prod-cn.lighthouse.networking.aws.dev": "https://prod-cn.operations.fremont.networking.aws.a2z.com",
    "gamma.lighthouse.networking.aws.dev": "https://gamma.operations.fremont.networking.aws.a2z.com",
    "gamma-cn.lighthouse.networking.aws.dev": "https://gamma-cn.operations.fremont.networking.aws.a2z.com",
    "beta.lighthouse.networking.aws.dev": "https://beta.operations.fremont.networking.aws.a2z.com",
    "beta-cn.lighthouse.networking.aws.dev": "https://beta-cn.operations.fremont.networking.aws.a2z.com",
    [`${DevEnvironment.YOUR_ALIAS}.test.lighthouse.networking.aws.dev`]:
        `https://${DevEnvironment.YOUR_ALIAS}.test.operations.fremont.networking.aws.a2z.com`,

    // Fremont
    "prod.fremont.networking.aws.a2z.com": "https://prod.operations.fremont.networking.aws.a2z.com",
    "prod-cn.fremont.networking.aws.a2z.com": "https://prod-cn.operations.fremont.networking.aws.a2z.com",
    "gamma.fremont.networking.aws.a2z.com": "https://gamma.operations.fremont.networking.aws.a2z.com",
    "gamma-cn.fremont.networking.aws.a2z.com": "https://gamma-cn.operations.fremont.networking.aws.a2z.com",
    "beta.fremont.networking.aws.a2z.com": "https://beta.operations.fremont.networking.aws.a2z.com",
    "beta-cn.fremont.networking.aws.a2z.com": "https://beta-cn.operations.fremont.networking.aws.a2z.com",
    [`${DevEnvironment.YOUR_ALIAS}.test.fremont.networking.aws.a2z.com`]:
        `https://${DevEnvironment.YOUR_ALIAS}.test.operations.fremont.networking.aws.a2z.com`,

    /**
     * While developing locally, you may want to connect to your own stack, beta or gamma.
     * Uncomment the respective config.
     *
     * Be sure to also update HelperFunctions to determine the env config
     */
    "localhost:8080": `${DevEnvironment.OPERATIONS_API_GATEWAY_ENDPOINT}`
    // "localhost:8080": "https://beta.operations.fremont.networking.aws.a2z.com"
    // "localhost:8080": "https://beta-cn.operations.fremont.networking.aws.a2z.com"
    // "localhost:8080": "https://gamma.operations.fremont.networking.aws.a2z.com"
    // "localhost:8080": "https://gamma-cn.operations.fremont.networking.aws.a2z.com"
};

// We have two separate endpoints is because to report of correct set of metrics for Fremont currently we do have
// operations endpoints like job, quicksight and others for which we should not be reporting metrics upon as they are
// pretty latent and impacts overall Fremont metrics here is more info https://i.amazon.com/FremontNEST-3294
const UI_API_GATEWAY_ENDPOINT = UI_API_GATEWAY_ENDPOINTS[window.location.host];
const OPERATIONS_API_GATEWAY_ENDPOINT = OPERATIONS_API_GATEWAY_ENDPOINTS[window.location.host];

export default class FremontBackendClient {
    TIMEOUT = 35000;
    MAX_CIRCUIT_DESIGN_BATCH_SIZE = 200;

    /*
     * API for fetching a csrf token
     */
    getCsrfToken = async (auth) => {
        const controller = this.createAbortController();
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/csrf/${auth.uniqueId}?${this.getQueryParamsFromRequest({
                    requestor: auth.userId
                })}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching all of tagInfo
     */
    getAllTagInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/tag?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating or updating a tag using the v2 methodology
     */
    modifyTag = async (tags, auth) => {
        const controller = this.createAbortController();
        const request = {
            tags,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken

        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/tag/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
    * API for fetching all of stageSla
    */
    getAllStageSlaInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/stageSla?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };


    /*
     * API for modifying a stageSla
     */
    modifyStageSla = async (stageSlas, originalStageSlas, auth) => {
        const controller = this.createAbortController();
        const stageSlasToSubmit = HelperFunctions.createV2Objects(
            stageSlas, originalStageSlas, Constants.BATCH_ENTITIES.STAGESLA,
            [Constants.FREMONT_OBJECTS.stageSla.stageSlaId]
        );
        const request = {
            stageSlas: stageSlasToSubmit,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/stageSla/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
    * API for creating or updating a blocker using the v2 methodology
    */
    modifyBlocker = async (blockers, auth) => {
        const controller = this.createAbortController();
        const request = {
            blockers,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken

        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/blocker/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching all of providerInformation
     */
    getAllProviderInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/provider?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular providerInfo based on providerName
     */
    getProviderInfo = async (providerName, auth) => {
        const controller = this.createAbortController();
        const request = {
            providerName,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/provider/providerIdentifier?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for updating a particular providerInfo based on providerName
     */
    updateProviderInfo = async (provider, auth) => {
        const controller = this.createAbortController();
        const request = {
            provider,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/provider`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating a provider
     */
    createProvider = async (provider, auth) => {
        const controller = this.createAbortController();
        const request = {
            provider,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/provider`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching all of CircuitDesignInformation
     */
    getAllCircuitDesignInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/circuitDesign?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular circuitDesignInfo based on circuitDesignId
     */
    getCircuitDesignInfo = async (circuitDesignId, auth) => {
        const controller = this.createAbortController();
        const request = {
            circuitDesignId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/circuitDesign/${request.circuitDesignId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for updating a particular circuitDesignInfo based on circuitDesignId
     */
    updateCircuitDesignInfo = async (circuitDesigns, auth) => {
        const controller = this.createAbortController();
        const request = {
            circuitDesigns,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };

        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/circuitDesign/${Constants.API_VERSION_V2}`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * Uses the getBatch component endpoint with 'ids'
     */
    getBatch = async (entityType, entityIdList, auth, includeDependencies, isLight) =>
        this.#getBatchComponent(entityType, true, entityIdList, auth, includeDependencies, isLight)

    /*
     * Uses the getBatch component endpoint with 'names'
     */
    getBatchWithNames = async (entityType, entityNameList, auth) =>
        this.#getBatchComponent(entityType, false, entityNameList, auth)

    /* eslint-disable no-await-in-loop, no-restricted-syntax */
    /*
     * API for batch fetching entities. Because of the weird syntax needed to combine these responses, we're ignoring
     * some lint rules for now. Can probably be improved on to make these batched calls in parallel in a future
     * revision.
     *
     * This is a private method, use `getBatch()` or `getBatchWithNames()` to call this function.
     */
    #getBatchComponent = async (entityType, usingId, identifierList, auth, includeDependencies, isLight) => {
        // If we don't have any entities to look up, skip the call entirely
        // We use `Array.isArray()` to make sure along with undefined/null we don't pass in a string for example
        if (!Array.isArray(identifierList) || !identifierList.length) {
            return { [`${entityType}s`]: [] };
        }

        // We need to determine which type of identifier is being used, whether its `ids` or `names`
        const identifierType = usingId ? "Ids" : "Names";

        // For order dependency endpoint, we want to hit the operations endpoint because its looping over 1000 orders
        // which can potentially be latent/timeout, and we don't want oncall to get paged.
        const apiGWUrl = entityType === "order" && includeDependencies
            ? OPERATIONS_API_GATEWAY_ENDPOINT
            : UI_API_GATEWAY_ENDPOINT;

        // Thanks to convention, we can infer these values. First step is to generate urls for the batch calls
        let httpMethod = `${apiGWUrl}/batch/component?requestor=${auth.userId}&entityType=${entityType}`;
        if (includeDependencies) {
            httpMethod += "&includeDependencies=true";
        }
        if (isLight) {
            httpMethod += "&light=true";
        }
        httpMethod += `&${identifierType}=`;

        const urls = [];

        // See if we can do a call for our entire fieldToSplitOn (generally an id field). If we can't we split
        if (Constants.CLOUDFRONT_URL_LIMIT < (`${httpMethod}${encodeURIComponent(JSON.stringify(identifierList))}`).length) {
            const fieldValueCopy = HelperFunctions.deepClone(identifierList);

            // The first loop will take care of ensuring we've gotten all the elements.
            // The second loop will take care of recognizing when we need to split the list into another batch call
            while (fieldValueCopy.length > 0) {
                const splitList = [];

                // As long as we are under our max and we have ids left to fetch, we'll keep adding to our splitList
                while (fieldValueCopy.length > 0
                        && Constants.CLOUDFRONT_URL_LIMIT >= (`${httpMethod}${encodeURIComponent(JSON.stringify(splitList))}`).length) {
                    splitList.push(fieldValueCopy.shift());
                }

                urls.push(`${httpMethod}${encodeURIComponent(JSON.stringify(splitList))}`);
            }
        } else {
            urls.push(`${httpMethod}${encodeURIComponent(JSON.stringify(identifierList))}`);
        }

        // Now that we have the URL's we can safely call all of them to get the response. Using `for...of` cause async
        const controller = this.createAbortController();
        const response = {};
        for (const url of urls) {
            const batchResponse = await this.checkStatus(
                await this.timeoutWrapper(
                    fetch(url, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": auth.jwtToken
                        },
                        signal: controller.signal
                    }),
                    controller
                )
            );

            const finessedBatchResponse = this.#finesseChangeSetObjects(batchResponse);
            Object.keys(finessedBatchResponse).forEach((key) => {
                if (response[key]) {
                    response[key] = response[key].concat(finessedBatchResponse[key]);
                } else {
                    response[key] = finessedBatchResponse[key];
                }
            });
        }
        return response;
    };
    /* eslint-enable no-await-in-loop */

    /*
     * API for creating a circuitDesign
     */
    createCircuitDesign = async (circuitDesign, auth) => {
        const controller = this.createAbortController();
        const request = {
            circuitDesign,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/circuitDesign`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for searching for circuitDesigns based off search params
     */
    getCircuitDesignsBasedOffSearchParams = async (searchParams, auth) => {
        const controller = this.createAbortController();
        const request = Object.assign(HelperFunctions.deepClone(searchParams), { requestor: auth.userId });
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/search/circuitDesign?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for searching for billing segments based off search params
     */
    getBillingSegmentBasedOffSearchParams = async (searchParams, auth) => {
        const controller = this.createAbortController();
        const request = Object.assign(HelperFunctions.deepClone(searchParams), { requestor: auth.userId });
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/search/billingSegment?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching all of ContactInformation
     */
    getAllContactInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/contact?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular contactInfo based on contactId
     */
    getContactInfo = async (contactId, auth) => {
        const controller = this.createAbortController();
        const request = {
            contactId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/contact/${request.contactId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for updating a particular contactInfo based on contactId
     */
    updateContactInfo = async (contact, auth) => {
        const controller = this.createAbortController();
        const request = {
            contact,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/contact`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating a contact
     */
    createContact = async (contact, auth) => {
        const controller = this.createAbortController();
        const request = {
            contact,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/contact`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular asnInfo based on asnName
     */
    getAsnInfo = async (asnId, auth) => {
        const controller = this.createAbortController();
        const request = {
            asnId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/asn/asnIdentifier?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for updating a particular ASN based on asnId
     */
    updateAsnInfo = async (asn, auth) => {
        const controller = this.createAbortController();
        const request = {
            asn,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/asn`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating an asn
     */
    createAsn = async (asn, auth) => {
        const controller = this.createAbortController();
        const request = {
            asn,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/asn`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching all of SiteInformation
     */
    getAllSiteInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/site?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular siteInfo based on siteId
     */
    getSiteInfo = async (siteId, auth) => {
        const controller = this.createAbortController();
        const request = {
            siteId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/site/siteIdentifier?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating or updating a site using the v2 methodology
     */
    modifySite = async (updatedSites, originalSites, auth) => {
        const controller = this.createAbortController();
        const sites = HelperFunctions.createV2Objects(updatedSites, originalSites, Constants.BATCH_ENTITIES.SITE,
            [Constants.FREMONT_OBJECTS.site.siteId, Constants.FREMONT_OBJECTS.site.siteName]);
        if (sites.length === 0) {
            return { sites: updatedSites };
        }
        const request = {
            sites,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken

        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/site/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular providerServiceInfo based on a providerServiceId
     */
    getProviderServiceInfo = async (providerServiceId, auth) => {
        const controller = this.createAbortController();
        const request = {
            providerServiceId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/providerService/${request.providerServiceId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for updating a particular ProviderServiceInfo based on providerServiceId
     */
    updateProviderServiceInfo = async (providerService, auth) => {
        const controller = this.createAbortController();
        const request = {
            providerService,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/providerService`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating a provider service
     */
    createProviderService = async (providerService, auth) => {
        const controller = this.createAbortController();
        const request = {
            providerService,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/providerService`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular providerCircuitInfo based on a providerCircuitId
     */
    getProviderCircuitInfo = async (providerCircuitId, auth) => {
        const controller = this.createAbortController();
        const request = {
            providerCircuitId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/providerCircuit/${request.providerCircuitId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying a provider circuit. Now using V2
     * This method is a bit different than other methods that take in circuits objects instead of provider circuits
     */
    modifyProviderCircuit = async (updatedCircuitDesigns, originalCircuitDesigns, auth) => {
        // Since we're getting circuit objects here to generate the updated/original provider circuit objects, we need
        // to transform the circuit designs into provider circuit objects first
        const transformResult = this.providerCircuitSubmitHelper(updatedCircuitDesigns, originalCircuitDesigns);
        const updatedProviderCircuits = transformResult.updatedProviderCircuitList;
        const originalProviderCircuits = transformResult.originalProviderCircuitList;

        const providerCircuits = HelperFunctions.createV2Objects(updatedProviderCircuits, originalProviderCircuits,
            Constants.BATCH_ENTITIES.PROVIDER_CIRCUIT,
            [Constants.FREMONT_OBJECTS.providerCircuit.circuitDesignIdFromRequest,
                Constants.FREMONT_OBJECTS.providerCircuit.componentNameFromRequest]);
        if (providerCircuits.length === 0) {
            return providerCircuits;
        }

        const controller = this.createAbortController();
        const request = {
            providerCircuits,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/providerCircuit/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
    * API for creating or updating a customAttribute.
    */
    modifyCustomAttribute = async (updatedCustomAttributes, originalCustomAttributes, auth) => {
        const controller = this.createAbortController();
        const customAttributes = HelperFunctions.createV2Objects(updatedCustomAttributes,
            originalCustomAttributes, Constants.BATCH_ENTITIES.CUSTOM_ATTRIBUTE,
            [Constants.FREMONT_OBJECTS.customAttribute.customAttributeId]);
        if (customAttributes.length === 0) {
            return { customAttributes: updatedCustomAttributes };
        }
        const request = {
            customAttributes,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken

        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/customAttribute/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying a demarcAndCfa. Now using V2
     * This method is a bit different than other methods that take in circuits objects instead of demarc and cfas
     */
    modifyDemarcAndCFA = async (updatedCircuitDesigns, originalCircuitDesigns, auth) => {
        // Since we're getting circuit objects here to generate the updated/original demarc and cfa objects, we need
        // to transform the circuit designs into demarc and cfa objects first
        const transformResult = this.demarcCfaSubmitHelper(updatedCircuitDesigns, originalCircuitDesigns);
        const updatedDemarcAndCfas = transformResult.updatedDemarcAndCfaList;
        const originalDemarcAndCfas = transformResult.originalDemarcAndCfaList;

        const demarcAndCfas = HelperFunctions.createV2Objects(updatedDemarcAndCfas, originalDemarcAndCfas,
            Constants.BATCH_ENTITIES.DEMARC_AND_CFA,
            [Constants.FREMONT_OBJECTS.demarcAndCfa.circuitDesignIdFromRequest,
                Constants.FREMONT_OBJECTS.demarcAndCfa.componentNameFromRequest]);
        if (demarcAndCfas.length === 0) {
            return demarcAndCfas;
        }

        const controller = this.createAbortController();
        const request = {
            demarcAndCfas,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/demarcAndCfa/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying a port. Now using V2
     */
    modifyPort = async (ports, auth) => {
        const controller = this.createAbortController();
        const request = {
            ports,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/port/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying a lag. Now using V2
     */
    modifyLag = async (lags, auth) => {
        const controller = this.createAbortController();

        // Only include the fields we care about.
        const lagRequestObjects = lags.map(lag => ({
            [Constants.ATTRIBUTES.deviceName]: lag[Constants.ATTRIBUTES.deviceName],
            [Constants.ATTRIBUTES.interfaceName]: lag[Constants.ATTRIBUTES.interfaceName],
            [Constants.ATTRIBUTES.circuitDesignIdFromRequest]: lag[Constants.ATTRIBUTES.circuitDesignIdFromRequest],
            [Constants.ATTRIBUTES.componentNameFromRequest]:
                lag[Constants.ATTRIBUTES.componentNameFromRequest],
            [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]:
                lag[Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest] || false
        }));

        const request = {
            lags: lagRequestObjects,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/lag/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying a unit
     */
    modifyUnit = async (units, auth) => {
        const controller = this.createAbortController();
        const request = {
            units,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/unit/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching all of OrderInformation
     */
    getAllOrderInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/order?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular orderInfo based on orderId
     */
    getOrderInfo = async (orderId, auth) => {
        const controller = this.createAbortController();
        const request = {
            orderId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/order/${request.orderId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for updating a particular OrderInfo based on orderId
     */
    updateOrderInfo = async (updatedOrder, originalOrder, auth) => {
        const orders = HelperFunctions.createV2Objects([updatedOrder], [originalOrder], Constants.BATCH_ENTITIES.ORDER,
            [Constants.FREMONT_OBJECTS.order.orderId]);
        if (orders.length === 0) {
            return updatedOrder;
        }
        const [order] = orders;
        const controller = this.createAbortController();
        const request = {
            order,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/order/${Constants.API_VERSION_V2}`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating a order
     */
    createOrder = async (order, auth) => {
        const controller = this.createAbortController();
        const request = {
            order,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/order`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for cancelling orders based on orderIds
     */
    cancelOrder = async (orders, auth) => {
        const controller = this.createAbortController();
        const request = {
            orders,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/order/cancel`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching all of BlockerInformation
     */
    getAllBlockerInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/blocker?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching blocker info based on blockerId
     */
    getBlockerInfo = async (blockerId, auth) => {
        const controller = this.createAbortController();
        const request = {
            blockerId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/blocker/${request.blockerId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating a blocker
     */
    createBlocker = async (blocker, auth) => {
        const controller = this.createAbortController();
        const request = {
            blocker,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/blocker`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for updating a blocker
     */
    updateBlockerInfo = async (blocker, auth) => {
        const controller = this.createAbortController();
        const request = {
            blocker,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/blocker`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating a note
     */
    createNote = async (notes, auth) => {
        const controller = this.createAbortController();
        const request = {
            notes,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/note`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching user info based on userId
     */
    getUserInfo = async (userId, auth) => {
        const controller = this.createAbortController();
        const request = {
            userId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/user/${request.userId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for creating a user
     */
    createUser = async (auth) => {
        const controller = this.createAbortController();
        const request = {
            user: { userId: auth.mimickedUserId || auth.userId },
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/user`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modiying a node
     */
    modifyNode = async (nodes, auth) => {
        const controller = this.createAbortController();
        const request = {
            nodes,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/node/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching attachment based on id (primarily for downloading the artifact with the presigned url)
     */
    getAttachmentInfo = async (attachmentId, auth) => {
        const controller = this.createAbortController();
        const request = {
            attachmentId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/attachment/${request.attachmentId}/?requestor=${request.requestor}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
    * API for fetching attachments based on ids (primarily for getting comprehend CFAs)
    */
    getBatchAttachmentInfo = async (attachmentIds, auth) => {
        const controller = this.createAbortController();
        const request = {
            attachmentIds: JSON.stringify(attachmentIds),
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/batch/attachment?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying attachments
     */
    modifyAttachment = async (attachments, auth) => {
        const controller = this.createAbortController();
        const request = {
            attachments,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/attachment`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching circuits based on ids
    */
    getBatchCircuitDesignInfo = async (circuitIds, auth) => {
        const circuitIdsChunks = HelperFunctions.chunkifyList(circuitIds, this.MAX_CIRCUIT_DESIGN_BATCH_SIZE);
        const response = { circuitDesigns: [] };
        const controller = this.createAbortController();
        await Promise.all(circuitIdsChunks.map(async (circuitIdsChunk) => {
            const request = {
                circuitDesignIds: JSON.stringify(circuitIdsChunk),
                requestor: auth.userId
            };
            const chunkifiedResponse = await this.checkStatus(
                await this.timeoutWrapper(
                    fetch(`${UI_API_GATEWAY_ENDPOINT}/batch/circuitDesign?${this.getQueryParamsFromRequest(request)}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": auth.jwtToken
                        },
                        signal: controller.signal
                    }),
                    controller
                )
            );
            response.circuitDesigns.push(...chunkifiedResponse.circuitDesigns);
        }));
        return response;
    };

    /*
     * API for updating the workflows of order and its corresponding circuitDesigns
     */
    updateWorkflowInfo = async (orderId, auth) => {
        const controller = this.createAbortController();
        const request = {
            orderId,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/workflow`, {
                    method: "PUT",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching pre-signed URL of a QuickSight session for a user
     */
    getQuickSightSessionUrl = async (auth) => {
        const controller = this.createAbortController();
        const request = {
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/quickSightSession?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for searching for spans based off search params
     */
    getSpansBasedOffSearchParams = async (searchParams, auth) => {
        const controller = this.createAbortController();
        const request = Object.assign(HelperFunctions.deepClone(searchParams), { requestor: auth.userId });

        return this.#finesseChangeSetObjects(
            await this.checkStatus(
                await this.timeoutWrapper(
                    fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/search/span?${this.getQueryParamsFromRequest(request)}`, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": auth.jwtToken
                        },
                        signal: controller.signal
                    }),
                    controller
                )
            )
        );
    };

    /*
     * API for modifying a span
     */
    modifySpan = async (spans, originalSpans, auth) => {
        const controller = this.createAbortController();
        const spansToSubmit = HelperFunctions.createV2Objects(
            spans, originalSpans, Constants.BATCH_ENTITIES.SPAN,
            [Constants.FREMONT_OBJECTS.span.spanId]
        );
        const request = {
            spans: spansToSubmit,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/span/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for searching multiple tables from a single call
     */
    globalSearch = async (entityType, subString, searchType, auth) => {
        const controller = this.createAbortController();
        const request = {
            entityType,
            subString,
            searchType,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/search/global?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for searching for work orders based off search params
     */
    getWorkOrdersBasedOffSearchParams = async (contractCentralUrl, auth) => {
        const controller = this.createAbortController();
        const request = {
            contractCentralUrl,
            requestor: auth.userId
        };

        return this.#finesseChangeSetObjects(
            await this.checkStatus(
                await this.timeoutWrapper(
                    fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/search/workOrder?${this.getQueryParamsFromRequest(request)}`, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": auth.jwtToken
                        },
                        signal: controller.signal
                    }),
                    controller
                )
            )
        );
    };

    /*
     * API for modifying a workOrder
     */
    modifyWorkOrder = async (workOrders, originalWorkOrders, auth) => {
        const controller = this.createAbortController();
        const workOrdersToSubmit = HelperFunctions.createV2Objects(
            workOrders, originalWorkOrders, Constants.BATCH_ENTITIES.WORK_ORDER,
            [Constants.FREMONT_OBJECTS.workOrder.workOrderId]
        );
        const request = {
            workOrders: workOrdersToSubmit,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/workOrder/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     *    API for modifying billing segment
     */
    modifyBillingSegment = async (billingSegments, originalBillingSegments, auth) => {
        const controller = this.createAbortController();
        const billingSegmentsToSubmit = HelperFunctions.createV2Objects(
            billingSegments, originalBillingSegments, Constants.BATCH_ENTITIES.BILLING_SEGMENT,
            [Constants.FREMONT_OBJECTS.billingSegment.billingSegmentId]
        );
        const request = {
            billingSegments: billingSegmentsToSubmit,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/billingSegment/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };


    /*
     * API for fetching a particular path based on pathName
     */
    getPathInfo = async (pathId, auth) => {
        const controller = this.createAbortController();
        const request = {
            pathId,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/path/${request.pathId}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying a path
     */
    modifyPath = async (paths, auth) => {
        const controller = this.createAbortController();
        const request = {
            paths,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/path/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching valid paths, based on authoritative data from the Cinnamon service
     */
    getValidPaths = async (auth) => {
        const controller = this.createAbortController();
        const request = {
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/valid-paths?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for searching for resources based off search params
     */
    getResourcesBasedOffSearchParams = async (searchParams, auth) => {
        const controller = this.createAbortController();
        const request = Object.assign(HelperFunctions.deepClone(searchParams), { requestor: auth.userId });
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/search/resource?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular resource based on resourceType
     */
    getResourceNamesBasedOffResourceType = async (resourceType, auth) => {
        const controller = this.createAbortController();
        const request = {
            resourceType,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/resource/${request.resourceType}?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for fetching a particular resource based on user
     */
    getResourceBasedOffUser = async (auth) => {
        const controller = this.createAbortController();
        const request = {
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/resource?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     *    API for modifying resources
     */
    modifyResource = async (resources, auth) => {
        const controller = this.createAbortController();
        const resourcesToSubmit = HelperFunctions.createV2Objects(
            resources, [], Constants.BATCH_ENTITIES.RESOURCE,
            [Constants.FREMONT_OBJECTS.resource.resourceId]
        );
        const request = {
            resources: resourcesToSubmit,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${UI_API_GATEWAY_ENDPOINT}/resource/${Constants.API_VERSION_V2}`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
    * API for fetching all of getAllJobsInfo
    */
    getAllJobsInfo = async (nextToken, auth) => {
        const controller = this.createAbortController();
        const request = {
            limit: Constants.SERVICE_LIMIT,
            nextToken,
            requestor: auth.userId
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/job?${this.getQueryParamsFromRequest(request)}`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };

    /*
     * API for modifying a provider circuit
     */
    modifyJobs = async (jobs, auth) => {
        const controller = this.createAbortController();
        const request = {
            jobs,
            requestor: auth.userId,
            uniqueId: auth.uniqueId,
            csrfToken: auth.csrfToken
        };
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`${OPERATIONS_API_GATEWAY_ENDPOINT}/job`, {
                    method: "POST",
                    body: JSON.stringify(HelperFunctions.trimTrailingAndLeadingWhitespace(request)),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": auth.jwtToken
                    },
                    signal: controller.signal
                }),
                controller
            )
        );
    };


    async callLinkService(request, headers) {
        const controller = this.createAbortController();
        return this.checkStatus(
            await this.timeoutWrapper(
                fetch(`https://localhost:1644/1/`, {
                    method: "POST",
                    body: request,
                    mode: "cors",
                    headers,
                    signal: controller.signal
                }),
                controller
            )
        );
    }

    /*
     * This function takes all of the key/value pairs of the "request" object and transforms them into url query
     * parameters. It encodes are the paramaters and removes all undefined ones.
     *
     * So if you get a request object that looks like:
     * { keyOne: valueOne, keyTwo: valueTwo, keyThree: undefined, keyFour: null },
     *
     * It will then transform them into a string of the form:
     * keyOne=valueOne&keyTwo=valueTwo
     */
    getQueryParamsFromRequest = (request) => {
        const prunedRequest = this.getAllDefinedKeys(request);

        // We want to join all the request parameters. We also want to ensure that we don't end with an ampersand
        return Object.keys(prunedRequest).map(key => (`${key}=${encodeURIComponent(prunedRequest[key])}`))
            .join("&")
            .replace(/&+$/, "");
    };

    /*
     * Removes all keys where the value is null or undefined
     */
    getAllDefinedKeys = (request) => {
        const prunedRequest = HelperFunctions.deepClone(request);
        Object.keys(prunedRequest).forEach((key) => {
            // We need to check for undefined as opposed to `!key` because we also have some boolean fields, like lacp
            if (prunedRequest[key] === undefined || prunedRequest[key] === null) {
                delete prunedRequest[key];
            }
        });
        return prunedRequest;
    };

    /*
     * Helper function to be used by FremontBackendClient only to transform circuits into provider circuit objects
     */
    providerCircuitSubmitHelper = (updatedCircuitDesignObjects, originalCircuitDesignObjects) => {
        // We add to the updated list if we have an existing object or if we have the componentName on the circuit
        // Our V2 helper method will automatically filter out objects that are the same as the original ones
        const updatedProviderCircuitList = [];
        const originalProviderCircuitList = [];
        updatedCircuitDesignObjects.forEach((circuitDesign) => {
            [Constants.COMPONENT_NAMES.providerCircuitA].forEach((componentName) => {
                const providerCircuitComponent = HelperFunctions.findComponent(
                    circuitDesign.positionMap, componentName
                );

                if ((providerCircuitComponent && providerCircuitComponent.uuid) || circuitDesign[componentName]) {
                    updatedProviderCircuitList.push({
                        [Constants.ATTRIBUTES.circuitDesignIdFromRequest]:
                            circuitDesign[Constants.ATTRIBUTES.circuitDesignId],
                        [Constants.ATTRIBUTES.componentNameFromRequest]: componentName,
                        [Constants.ATTRIBUTES.providerCircuitName]: circuitDesign[componentName],
                        [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]:
                            (providerCircuitComponent.uuid) ? !circuitDesign[componentName] : false
                    });
                }
            });
        });

        // While going through the original objects, if the provider circuit does not exist (no UUID), we return nothing
        originalCircuitDesignObjects.forEach((circuitDesign) => {
            [Constants.COMPONENT_NAMES.providerCircuitA].forEach((componentName) => {
                const providerCircuitComponent = HelperFunctions.findComponent(
                    circuitDesign.positionMap, componentName
                );

                if (providerCircuitComponent && providerCircuitComponent.uuid) {
                    originalProviderCircuitList.push({
                        [Constants.ATTRIBUTES.circuitDesignIdFromRequest]:
                            circuitDesign[Constants.ATTRIBUTES.circuitDesignId],
                        [Constants.ATTRIBUTES.componentNameFromRequest]: componentName,
                        [Constants.ATTRIBUTES.providerCircuitName]: circuitDesign[componentName],
                        [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]: false // always default to false
                    });
                }
            });
        });

        return {
            updatedProviderCircuitList,
            originalProviderCircuitList
        };
    };

    /*
     * Helper function to be used by FremontBackendClient only to transform circuits into demarc and cfa objects
     */
    demarcCfaSubmitHelper = (updatedCircuitDesignObjects, originalCircuitDesignObjects) => {
        const updatedDemarcAndCfaList = [];
        const originalDemarcAndCfaList = [];

        // We add to the updated list if we have an existing object or if we have the componentName on the circuit
        // Our V2 helper method will automatically filter out objects that are the same as the original ones
        updatedCircuitDesignObjects.forEach((circuitDesign) => {
            [Constants.COMPONENT_NAMES.demarcAndCfaA, Constants.COMPONENT_NAMES.demarcAndCfaZ]
                .forEach((componentName) => {
                    const demarcAndCfaComponent = HelperFunctions.findComponent(
                        circuitDesign.positionMap, componentName
                    );

                    if ((demarcAndCfaComponent && demarcAndCfaComponent.uuid) || circuitDesign[componentName]) {
                        updatedDemarcAndCfaList.push({
                            [Constants.ATTRIBUTES.circuitDesignIdFromRequest]:
                                circuitDesign[Constants.ATTRIBUTES.circuitDesignId],
                            [Constants.ATTRIBUTES.componentNameFromRequest]: componentName,
                            [Constants.ATTRIBUTES.assignmentId]: circuitDesign[componentName],
                            [Constants.ATTRIBUTES.assignmentType]:
                                !demarcAndCfaComponent.uuid ? Constants.ATTRIBUTES.cfa : undefined,
                            [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]:
                                (demarcAndCfaComponent.uuid) ? !circuitDesign[componentName] : false
                        });
                    }
                });
        });

        // While going through the original objects, if the demarc does not exist (no UUID), we return nothing
        originalCircuitDesignObjects.forEach((circuitDesign) => {
            [Constants.COMPONENT_NAMES.demarcAndCfaA, Constants.COMPONENT_NAMES.demarcAndCfaZ]
                .forEach((componentName) => {
                    const demarcAndCfaComponent = HelperFunctions.findComponent(
                        circuitDesign.positionMap, componentName
                    );

                    if (demarcAndCfaComponent && demarcAndCfaComponent.uuid) {
                        originalDemarcAndCfaList.push({
                            [Constants.ATTRIBUTES.circuitDesignIdFromRequest]:
                                circuitDesign[Constants.ATTRIBUTES.circuitDesignId],
                            [Constants.ATTRIBUTES.componentNameFromRequest]: componentName,
                            [Constants.ATTRIBUTES.assignmentId]: circuitDesign[componentName],
                            [Constants.ATTRIBUTES.assignmentType]:
                                !demarcAndCfaComponent.uuid ? Constants.ATTRIBUTES.cfa : undefined,
                            [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]: false
                        });
                    }
                });
        });

        return {
            updatedDemarcAndCfaList,
            originalDemarcAndCfaList
        };
    };

    /*
     * This is a generic method used for checking the response object and based on that
     * returns response back or throws out an error
     */
    checkStatus = async (response) => {
        if (response.ok) {
            return response.json();
            // If you receive an authentication error return a message asking to refresh Midway credentials
        } else if (response.status === 401) {
            throw new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError);
        }
        // Otherwise return response specific error text
        const jsonResponse = await response.json();
        throw new Error(`${response.statusText}: ${jsonResponse.message}`);
    };

    /*
     *  This function wraps a fetch request in a timeout. For now, leave the time period as the same for every call.
     *  If this needs to change later we can make it a parameter to this wrapper.
     */
    timeoutWrapper = async (wrappedFetchRequest, controller) => {
        const timeout = setTimeout(() => controller.abort(), this.TIMEOUT);
        const response = await wrappedFetchRequest;
        clearTimeout(timeout);
        return response;
    };

    /*
     *   This function creates a unique AbortController object for every fetch request. This ensures that if one request
     *   aborts, other requests on the same page will retry and not immediately get aborted as well.
     */
    createAbortController = () => (
        new AbortController()
    );

    // Once we complete https://issues.amazon.com/issues/FremontNEST-3144 and
    // https://issues.amazon.com/issues/FremontNEST-3145, we will no longer need this around
    #finesseChangeSetObjects = (response) => {
        // Here is a list of all the types of objects we will finesse
        const fieldsToFinesse = ["workOrders", "spans"];
        const responseToReturn = {};
        Object.keys(response).forEach((key) => {
            if (fieldsToFinesse.includes(key)) {
                responseToReturn[key] = this.#elevateChangeSetFields(response[key]);
            } else {
                responseToReturn[key] = response[key];
            }
        });
        return responseToReturn;
    }

    // Once we complete https://issues.amazon.com/issues/FremontNEST-3144 and
    // https://issues.amazon.com/issues/FremontNEST-3145, we will no longer need this around
    #elevateChangeSetFields = (objects) => {
        const objectsToReturn = [];
        objects.forEach((object) => {
            const changeSetFields = Object.keys(object).filter(key => key.endsWith("ChangeSet"));
            if (changeSetFields.length === 1) {
                const objectCopy = HelperFunctions.deepClone(object);
                const changeSetFieldName = changeSetFields.find(Boolean);
                Object.keys(objectCopy[changeSetFieldName]).forEach((key) => {
                    // Ignore the `changeSetOrderId` field, that's something not used to be displayed anywhere anyways
                    if (!key.endsWith("OrderId")) {
                        let nominalFieldName = key.replace("changeSet", "");
                        nominalFieldName = nominalFieldName.charAt(0).toLowerCase() + nominalFieldName.slice(1);
                        objectCopy[nominalFieldName] = objectCopy[changeSetFieldName][key];
                    }
                });

                // Remove the changeset field from the object and add it to our response
                delete objectCopy[changeSetFieldName];
                objectsToReturn.push(objectCopy);
            } else {
                objectsToReturn.push(object);
            }
        });
        return objectsToReturn;
    }
}