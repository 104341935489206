import React from "react";
import {
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontExpandableSection
} from "utils/CommonComponents";
import OrderTable from "order/OrderTable";

/**
 UserTab is the tab which is used for displaying user data.
 */
const UserTab = props => (
    <SpaceBetween size="l">
        <FremontExpandableSection
            header={
                <Header variant="h2">
                    My Orders Requiring Action
                </Header>
            }
            variant="container"
        >
            <OrderTable
                items={props.userOrderActionItems}
                loading={props.userOrdersLoading}
                emptyTableMessage="No orders requiring action exist for this user."
                fetchAllOrderItems={props.fetchAllOrderItems}
            />
        </FremontExpandableSection>
        <FremontExpandableSection
            header={
                <Header variant="h2">
                    My Orders
                </Header>
            }
            variant="container"
        >
            <OrderTable
                items={props.userOrderItems}
                loading={props.userOrdersLoading}
                emptyTableMessage="No orders currently exist within Fremont for this user."
                fetchAllOrderItems={props.fetchAllOrderItems}
            />
        </FremontExpandableSection>
    </SpaceBetween>
);

export default UserTab;