import React, { Component } from "react";
import {
    PortReservationStageDisplayMode,
    PortReservationStageEditMode
} from "order/stages/install/PortReservationInformation";
import {
    StageDisplayMode,
    StageEditMode
} from "order/OrderCommonComponents";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import HelperFunctions from "common/HelperFunctions";
import CircuitDesignValidation from "circuitDesign/CircuitDesignValidation";
import OrderValidation from "order/OrderValidation";

class PortReservationHandler extends Component {
    static PORT_TO_NODE_MAP = {
        [Constants.COMPONENT_NAMES.portA]: Constants.COMPONENT_NAMES.nodeA,
        [Constants.COMPONENT_NAMES.portZ]: Constants.COMPONENT_NAMES.nodeZ,
        [Constants.COMPONENT_NAMES.leverAExternalInterface]: Constants.COMPONENT_NAMES.leverA,
        [Constants.COMPONENT_NAMES.leverAInternalInterface]: Constants.COMPONENT_NAMES.leverA,
        [Constants.COMPONENT_NAMES.leverZExternalInterface]: Constants.COMPONENT_NAMES.leverZ,
        [Constants.COMPONENT_NAMES.leverZInternalInterface]: Constants.COMPONENT_NAMES.leverZ
    };

    state = {
        isEditClicked: false,
        areOptionsLoading: false,
        hasBeenSubmittedOnce: false,
        isUpdateStageInProgress: false,
        updatedCircuitDesignObjects: [],
        allFieldsDisabled: false,
        nodeOptions: {},
        lagOptions: {},
        massUpdateSelectedCircuitDesignIds: []
    };

    getEncryptionType = () => this.props.circuitDesignObjects
        .map(circuitDesign => circuitDesign[Constants.ATTRIBUTES.encryptionType])
        .filter(Boolean)
        .pop();

    FremontBackendClient = new FremontBackendClient();

    /**
     * This method returns an array of objects that are rendered inside of the business developer submit stage table
     */
    generateCircuitItems = () => {
        const circuitItemsObjects = HelperFunctions.generateStageCircuitItems(
            this.props.circuitDesignObjects,
            this.state.updatedCircuitDesignObjects,
            this.state.isEditClicked,
            this.state.hasBeenSubmittedOnce,
            this.state.isUpdateStageInProgress,
            this.handleStageInputChange,
            this.props.blockers,
            this.state.allFieldsDisabled
        );

        if (circuitItemsObjects.static.length > 0) {
            circuitItemsObjects.static.forEach(staticCircuitDesign =>
                Object.assign(staticCircuitDesign, {
                    [Constants.ATTRIBUTES.siteAId]: HelperFunctions.getAttributeFromComponent(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteA,
                        Constants.ATTRIBUTES.siteId
                    ),
                    [Constants.ATTRIBUTES.siteZId]: HelperFunctions.getAttributeFromComponent(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteZ,
                        Constants.ATTRIBUTES.siteId
                    ),
                    [Constants.COMPONENT_NAMES.siteA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteA
                    ),
                    [Constants.COMPONENT_NAMES.siteZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.siteZ
                    ),
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    ),
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    ),
                    [Constants.COMPONENT_NAMES.portA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portA
                    ),
                    [Constants.COMPONENT_NAMES.portZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.portZ
                    ),
                    [Constants.COMPONENT_NAMES.leverA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverA
                    ),
                    [Constants.COMPONENT_NAMES.leverZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverZ
                    ),
                    [Constants.COMPONENT_NAMES.leverAExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZExternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZExternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverAInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverAInternalInterface
                        ),
                    [Constants.COMPONENT_NAMES.leverZInternalInterface]:
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            staticCircuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.leverZInternalInterface
                        ),
                    usingExistingLag: HelperFunctions.booleanToYesNo(
                        HelperFunctions.parseBoolean(staticCircuitDesign.usingExistingLag)
                    ),
                    [Constants.COMPONENT_NAMES.lagA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.lagA
                    ),
                    providerCircuitId: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        staticCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.providerCircuitA
                    )
                }));
        }
        if (circuitItemsObjects.dynamic.length > 0) {
            circuitItemsObjects.dynamic.forEach(dynamicCircuitDesign =>
                Object.assign(dynamicCircuitDesign, {
                    providerCircuitId: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        dynamicCircuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.providerCircuitA
                    ),
                    areOptionsLoading: this.state.areOptionsLoading,
                    siteOptions: this.props.siteOptions,
                    nodeOptions: this.state.nodeOptions,
                    lagOptions: this.state.lagOptions[dynamicCircuitDesign[Constants.COMPONENT_NAMES.nodeA]] || [],
                    errorTexts: this.state.hasBeenSubmittedOnce ? dynamicCircuitDesign.errorTexts : {}
                }));
        }
        return circuitItemsObjects;
    };

    /**
     * Handle any edits to the order
     * @param evt
     */
    handleStageInputChange = async (evt) => {
        const input = {};

        input.evt = evt;
        input.circuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        input.siteOptions = HelperFunctions.deepClone(this.props.siteOptions);
        input.stageName = Constants.STAGE_NAMES.portReservation;
        const circuitDesignOutput = CircuitDesignValidation.validateInput(input);

        if (Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.order.serviceType)
            && Constants.ORDER_TYPES.INSTALL === this.props.order.ordertype) {
            // Check for existing lag errors
            const existingLagSet = new Set();
            const newLagSet = new Set();
            circuitDesignOutput.circuitDesignObjects = circuitDesignOutput.circuitDesignObjects
                .map((updatedCircuitDesignObject) => {
                    const circuitDesignObject = updatedCircuitDesignObject;
                    const lagName = `${
                        circuitDesignObject[Constants.COMPONENT_NAMES.nodeA]
                    }${
                        circuitDesignObject[Constants.COMPONENT_NAMES.lagA]
                    }`;
                    if (circuitDesignObject[Constants.COMPONENT_NAMES.lagA]
                        && CircuitDesignValidation.INVALID_LAG
                            !== circuitDesignObject.errorTexts[Constants.COMPONENT_NAMES.lagA]) {
                        if (HelperFunctions.parseBoolean(circuitDesignObject.usingExistingLag)) {
                            if (newLagSet.has(lagName)) {
                                circuitDesignObject.errorTexts[Constants.COMPONENT_NAMES.lagA] =
                                    "Lag already marked as new";
                            } else {
                                circuitDesignObject.errorTexts[Constants.COMPONENT_NAMES.lagA] = "";
                                existingLagSet.add(lagName);
                            }
                        } else if (existingLagSet.has(lagName)) {
                            circuitDesignObject.errorTexts[Constants.COMPONENT_NAMES.lagA] =
                                "Lag already marked as existing";
                        } else {
                            circuitDesignObject.errorTexts[Constants.COMPONENT_NAMES.lagA] = "";
                            newLagSet.add(lagName);
                        }
                    }

                    return circuitDesignObject;
                });
        }

        // Do mass update, and update the state
        const output = CircuitDesignValidation.massUpdate(this.state.massUpdateSelectedCircuitDesignIds,
            input, circuitDesignOutput.circuitDesignObjects, this.state.updatedCircuitDesignObjects);
        this.setState({ updatedCircuitDesignObjects: output });

        // Here we load any additional nodes, ports, and lags associated with a new site object (if a new one was
        // chosen). We do this after setting the state of the updatedCircuitDesignObjects so that the value the
        // user selects updates immediately (it looks laggy otherwise).
        if (Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.order.serviceType)) {
            // Here we check to see if every site currently assigned to a circuit exists as a key in node options.
            // If the new siteId does not exist as a key in the node options object (which means the nodes for that
            // site have not been loaded), we load the node options for that site. We do this dynamically so that we
            // do not have to load every possible component for every possible site.
            const selectedSiteIds = [];
            circuitDesignOutput.circuitDesignObjects.forEach((circuitDesign) => {
                selectedSiteIds.push(circuitDesign[Constants.ATTRIBUTES.siteAId]);
                selectedSiteIds.push(circuitDesign[Constants.ATTRIBUTES.siteZId]);
            });
            // Here we obtain the newSiteId by finding the first ID that is selected but does not exist as a key in
            // the node options (we filter out any blank siteIds). Only one new siteId can be added at a time.
            const [newSiteId] = selectedSiteIds.filter(siteId => !!siteId).filter(siteId =>
                !Object.keys(this.state.nodeOptions).includes(siteId));
            if (newSiteId) {
                await this.fetchPortReservationOptions(newSiteId);
            }
        }
    };

    /**
     * Handle clicking the edit button
     * @param evt
     */
    handleStageEditClick = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.props.handleStageInEditOrSubmitMode(!this.state.isEditClicked);
        if (!this.state.isEditClicked) {
            const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects)
                .map((updatedCircuitObject) => {
                    const circuitDesignObject = updatedCircuitObject;

                    circuitDesignObject[Constants.ATTRIBUTES.encryptionType] = this.getEncryptionType();
                    circuitDesignObject[Constants.ATTRIBUTES.siteAId] =
                        HelperFunctions.getAttributeFromComponent(
                            this.props.componentIdToObjectMap,
                            circuitDesignObject.positionMap,
                            Constants.COMPONENT_NAMES.siteA,
                            Constants.ATTRIBUTES.siteId
                        );
                    circuitDesignObject[Constants.ATTRIBUTES.siteZId] =
                        HelperFunctions.getAttributeFromComponent(
                            this.props.componentIdToObjectMap,
                            circuitDesignObject.positionMap,
                            Constants.COMPONENT_NAMES.siteZ,
                            Constants.ATTRIBUTES.siteId
                        );
                    circuitDesignObject[Constants.COMPONENT_NAMES.siteA] =
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            updatedCircuitObject.positionMap,
                            Constants.COMPONENT_NAMES.siteA
                        );
                    circuitDesignObject[Constants.COMPONENT_NAMES.siteZ] =
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            updatedCircuitObject.positionMap,
                            Constants.COMPONENT_NAMES.siteZ
                        );
                    circuitDesignObject[Constants.COMPONENT_NAMES.nodeA] =
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            updatedCircuitObject.positionMap,
                            Constants.COMPONENT_NAMES.nodeA
                        );
                    circuitDesignObject[Constants.COMPONENT_NAMES.portA] =
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            updatedCircuitObject.positionMap,
                            Constants.COMPONENT_NAMES.portA
                        );
                    circuitDesignObject[Constants.COMPONENT_NAMES.lagA] =
                        HelperFunctions.getDisplayValueFromComponentName(
                            this.props.componentIdToObjectMap,
                            updatedCircuitObject.positionMap,
                            Constants.COMPONENT_NAMES.lagA
                        );
                    if (this.props.order.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
                        circuitDesignObject[Constants.COMPONENT_NAMES.nodeZ] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.nodeZ
                            );
                        circuitDesignObject[Constants.COMPONENT_NAMES.portZ] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.portZ
                            );
                        circuitDesignObject[Constants.COMPONENT_NAMES.leverA] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.leverA
                            );
                        circuitDesignObject[Constants.COMPONENT_NAMES.leverZ] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.leverZ
                            );
                        circuitDesignObject[Constants.COMPONENT_NAMES.leverAExternalInterface] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.leverAExternalInterface
                            );
                        circuitDesignObject[Constants.COMPONENT_NAMES.leverZExternalInterface] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.leverZExternalInterface
                            );
                        circuitDesignObject[Constants.COMPONENT_NAMES.leverAInternalInterface] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.leverAInternalInterface
                            );
                        circuitDesignObject[Constants.COMPONENT_NAMES.leverZInternalInterface] =
                            HelperFunctions.getDisplayValueFromComponentName(
                                this.props.componentIdToObjectMap,
                                updatedCircuitObject.positionMap,
                                Constants.COMPONENT_NAMES.leverZInternalInterface
                            );
                        circuitDesignObject.errorTexts = {
                            [Constants.COMPONENT_NAMES.nodeA]: "",
                            [Constants.COMPONENT_NAMES.nodeZ]: "",
                            [Constants.COMPONENT_NAMES.portA]: "",
                            [Constants.COMPONENT_NAMES.portZ]: "",
                            [Constants.COMPONENT_NAMES.leverA]: "",
                            [Constants.COMPONENT_NAMES.leverZ]: "",
                            [Constants.COMPONENT_NAMES.leverAExternalInterface]: "",
                            [Constants.COMPONENT_NAMES.leverZExternalInterface]: "",
                            [Constants.COMPONENT_NAMES.leverAInternalInterface]: "",
                            [Constants.COMPONENT_NAMES.leverZInternalInterface]: ""
                        };
                    } else {
                        circuitDesignObject.errorTexts = {
                            [Constants.COMPONENT_NAMES.siteA]: "",
                            [Constants.COMPONENT_NAMES.nodeA]: "",
                            [Constants.COMPONENT_NAMES.portA]: "",
                            [Constants.COMPONENT_NAMES.lagA]: ""
                        };
                    }
                    return circuitDesignObject;
                });
            this.fetchPortReservationOptions();
            // makes lag errors show up correctly and helps with debugging
            HelperFunctions.sortCircuitDesigns(updatedCircuitDesignObjects);
            this.setState({
                isEditClicked: true,
                massUpdateSelectedCircuitDesignIds: [],
                hasBeenSubmittedOnce: false,
                updatedCircuitDesignObjects,
                allFieldsDisabled: false,
                isUpdateStageInProgress: false
            });
        } else {
            this.setState({
                isEditClicked: false
            });
        }
    };

    /**
     * Handles getting all node options based off of site objects passed in as argument.
     * @param currentlySelectedSites list of site objects whose nodes should be loaded as options
     * @param resetNodeOptions whether or not we should generate the node options from scratch
     */
    fetchNodeOptions = async (currentlySelectedSites, resetNodeOptions) => {
        const nodeOptions = resetNodeOptions ? {} : HelperFunctions.deepClone(this.state.nodeOptions);
        // We define the defaultNode response to emulate an empty response from the getBatchNodesInfo. If no nodes
        // exist we don't call the backend but still want to return an object with a valid format
        let nodeResponse = { nodes: [] };

        // Here we generate the list of node objects we need to obtain from the backend based on the sites
        // currently in use by the order.
        const nodeIdSet = new Set();
        currentlySelectedSites.forEach((siteItem) => {
            // Here we set the node options for the site to a blank list if the site does not have any node
            // IDs or if the siteId does not already exist as a key in the nodeOptions array
            if (siteItem.nodeIdList.length === 0 || !nodeOptions[siteItem.siteId]) {
                Object.assign(nodeOptions, {
                    [siteItem.siteId]: []
                });
            }
            siteItem.nodeIdList.forEach(nodeId =>
                nodeIdSet.add(nodeId));
        });
        const nodeIdList = Array.from(nodeIdSet);
        // Get all nodes from the site
        // We only make a backend call if the number of nodes to load is greater than zero
        if (nodeIdList.length > 0) {
            nodeResponse = await this.FremontBackendClient.getBatch(
                Constants.BATCH_ENTITIES.NODE, nodeIdList, this.props.auth
            );
            // Here we assign the node object as options for the appropriate site
            nodeResponse.nodes.forEach((node) => {
                const existingNodeOptionIndex = nodeOptions[node.siteId].findIndex(nodeOption =>
                    nodeOption.value === node.deviceName);
                // If the list of node options already contains the existing node, we remove the existing
                // node option before we add the same but potentially updated node option to the list
                if (existingNodeOptionIndex >= 0) {
                    // Remove existing node option
                    nodeOptions[node.siteId].splice(existingNodeOptionIndex, 1);
                }
                // Push node option onto the list of node options for the site
                nodeOptions[node.siteId].push(
                    {
                        value: node.deviceName,
                        node
                    }
                );
            });
        }
        Object.keys(nodeOptions).forEach(site => HelperFunctions.sortObjectsByField(nodeOptions[site], "value"));
        this.setState({
            nodeOptions
        });
        // We return the node response so that we can load the correct port and lags in the
        // fetchPortReservationsOptions function
        return nodeResponse;
    }

    /**
     * Handles getting all lag options based off of the current node options. For any node that is selectable,
     * we always want to have all of its lag objects stored in the lagOptions so that they can be displayed as
     * options as soon as the user selects the node.
     * @param currentNodeOptions list of node objects whose lags should be loaded as options
     */
    fetchLagOptions = async (currentNodeOptions) => {
        // Define the lag options as the ones that currently exist
        const lagOptions = HelperFunctions.deepClone(this.state.lagOptions);
        // Get map of lag ids from all nodes (after node is selected give options based on that)
        const lagIdSet = new Set();
        currentNodeOptions.forEach(node => node.lagIdList.forEach(lagId => lagIdSet.add(lagId)));
        const lagsResponse = await this.FremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.LAG, Array.from(lagIdSet).filter(e => e), this.props.auth
        );

        currentNodeOptions.forEach((node) => {
            lagOptions[node.deviceName] = lagsResponse.lags
                .filter(lag => node.lagIdList.includes(lag.lagId))
                .map(lag => ({
                    value: lag.interfaceName,
                    lag
                }));
        });

        // Here we sort the lag options on their display value so that they will be displayed in alphabetical order
        Object.keys(lagOptions).forEach(key =>
            HelperFunctions.sortObjectsByField(lagOptions[key], "value"));

        this.setState({
            lagOptions
        });
    };

    /**
     * Handles getting all site, node, port, and lag options. This function is also used to load additional node, ports,
     * and lags when a new site is selected
     */
    fetchPortReservationOptions = async (newSiteId) => {
        this.setState({ areOptionsLoading: true });
        try {
            await this.props.fetchSiteOptions();
            const siteOptions = HelperFunctions.deepClone(this.props.siteOptions);
            let resetNodeOptions = false;

            let currentlySelectedSites;
            if (newSiteId) {
                currentlySelectedSites = [this.props.siteOptions.find(siteOption =>
                    siteOption.value === newSiteId).site];
            } else {
                const currentlySelectedSiteIds = new Set();
                // Since interconnect circuits can have a siteA that is different from the order's siteA, we obtain
                // every unique site currently in use by the circuits in order to determine the correct node options
                this.props.circuitDesignObjects.forEach((circuitDesignObject) => {
                    currentlySelectedSiteIds.add(HelperFunctions.getAttributeFromComponent(
                        this.props.componentIdToObjectMap,
                        circuitDesignObject[Constants.ATTRIBUTES.positionMap],
                        Constants.COMPONENT_NAMES.siteA,
                        Constants.ATTRIBUTES.siteId
                    ));
                    currentlySelectedSiteIds.add(HelperFunctions.getAttributeFromComponent(
                        this.props.componentIdToObjectMap,
                        circuitDesignObject[Constants.ATTRIBUTES.positionMap],
                        Constants.COMPONENT_NAMES.siteZ,
                        Constants.ATTRIBUTES.siteId
                    ));
                    // Here we set the resetNodeOptions parameter to true because we want to generate them from scratch
                    resetNodeOptions = true;
                });
                // Here we filter out from the list of all possible sites the sites currently in use by the order.
                // We do this so that we do not have to fetch every node, port, and
                // lag that currently exists in Fremont
                currentlySelectedSites = HelperFunctions.deepClone(siteOptions).filter(siteOption =>
                    currentlySelectedSiteIds.has(siteOption.value));

                // Here we make a batch call to obtain all of the currentlySelectedSites from dynamo in case any of
                // their values have changes
                const getBatchSitesResponse = await this.FremontBackendClient.getBatch(
                    Constants.BATCH_ENTITIES.SITE, currentlySelectedSites.map(site => site.value), this.props.auth
                );

                currentlySelectedSites = getBatchSitesResponse.sites;
            }
            // We only load nodes, ports, and lags if the number of selected sites exists and is greater than zero
            if (currentlySelectedSites && currentlySelectedSites.length > 0) {
                const nodeResponse = await this.fetchNodeOptions(currentlySelectedSites, resetNodeOptions);
                if (Object.keys(nodeResponse.nodes).length > 0
                    && Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.order.serviceType)) {
                    await this.fetchLagOptions(nodeResponse.nodes);
                }
            }
            this.setState({
                areOptionsLoading: false
            });
        } catch (error) {
            this.setState({ areOptionsLoading: false });
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
        }
    };

    /**
     * This function generates two maps, one for the existingCircuitDesignObjects and one for the
     * updatedCircuitDesignObjects, which indicate which components each circuit was connected to before and
     * after the user submits the port reservation stage.
     */
    generateCircuitToComponentMap = () => {
        // Here we create two maps, one for the existing circuit designs and their chosen sites, and one
        // for the updated circuits and their chosen sites
        const existingCircuitToComponentMap = {};
        this.props.circuitDesignObjects.forEach(circuitDesign =>
            Object.assign(existingCircuitToComponentMap, {
                [circuitDesign[Constants.ATTRIBUTES.circuitDesignId]]:
                    {
                        [Constants.COMPONENT_NAMES.siteA]: HelperFunctions.getAttributeFromComponent(
                            this.props.componentIdToObjectMap,
                            circuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.siteA,
                            Constants.ATTRIBUTES.siteId
                        ),
                        [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getAttributeFromComponent(
                            this.props.componentIdToObjectMap,
                            circuitDesign.positionMap,
                            Constants.COMPONENT_NAMES.nodeA,
                            Constants.ATTRIBUTES.deviceName
                        )
                    }
            }));
        const updatedCircuitToComponentMap = {};
        this.state.updatedCircuitDesignObjects.forEach(circuitDesign =>
            Object.assign(updatedCircuitToComponentMap, {
                [circuitDesign[Constants.ATTRIBUTES.circuitDesignId]]:
                    {
                        [Constants.COMPONENT_NAMES.siteA]: circuitDesign[Constants.ATTRIBUTES.siteAId],
                        [Constants.COMPONENT_NAMES.nodeA]: circuitDesign[Constants.COMPONENT_NAMES.nodeA]
                    }
            }));

        return { existingCircuitToComponentMap, updatedCircuitToComponentMap };
    };

    /**
     * This helper function determines whether the removeCircuitDesignIdFromRequest should be set to true or false
     * @param updatedCircuitDesign
     * @param componentName
     * @param componentType
     */
    determineRemoveCircuitDesignIdFromRequest = (updatedCircuitDesign, componentName, componentType) => {
        const returnObject = {
            remove: false
        };
        const component = HelperFunctions.findComponent(updatedCircuitDesign.positionMap, componentName);
        // Here we check if the component exists in the positionMap and has a UUID associated to it
        if (component && component.uuid) {
            const componentObject = this.props.componentIdToObjectMap[component.uuid];
            // If the component exists in the positionMap but not in the componentIdToObjectMap, that means
            // there was an error loading data and we will display an error in that case
            if (!componentObject) {
                const error = new Error();
                switch (componentType) {
                case Constants.COMPONENT_TYPES.site:
                    error.message = Constants.FLASHBAR_STRINGS.flashbarErrorLoadingSiteComponentData;
                    break;
                case Constants.COMPONENT_TYPES.node:
                    error.message = Constants.FLASHBAR_STRINGS.flashbarErrorLoadingNodeComponentData;
                    break;
                case Constants.COMPONENT_TYPES.port:
                    error.message = Constants.FLASHBAR_STRINGS.flashbarErrorLoadingPortComponentData;
                    break;
                case Constants.COMPONENT_TYPES.lag:
                    error.message = Constants.FLASHBAR_STRINGS.flashbarErrorLoadingLagComponentData;
                    break;
                case Constants.COMPONENT_TYPES.unit:
                    error.message = Constants.FLASHBAR_STRINGS.flashbarErrorLoadingUnitComponentData;
                    break;
                default:
                    error.message = Constants.FLASHBAR_STRINGS.flashbarErrorLoadingComponentData;
                    break;
                }
                this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
                this.setState({
                    hasBeenSubmittedOnce: true,
                    isUpdateStageInProgress: false,
                    isEditClicked: false,
                    allFieldsDisabled: false
                });
                returnObject.errorRetrievingComponentObject = true;
                return returnObject;
            }

            // Here we determine which attribute we will use to compare the existing display value on the
            // updatedCircuitDesign and the display value on the component from the positionMap
            let displayValue;
            if (componentType === Constants.COMPONENT_TYPES.node) {
                displayValue = Constants.ATTRIBUTES.deviceName;
            } else if (componentType === Constants.COMPONENT_TYPES.site) {
                displayValue = Constants.ATTRIBUTES.siteName;
            } else {
                displayValue = Constants.ATTRIBUTES.interfaceName;
            }

            // If the display value equals on the updatedCircuitDesign equals the display value on the component from
            // the positionMap, then we want to use the existing object for the modifyActivity
            if (updatedCircuitDesign[componentName] === componentObject[displayValue]) {
                returnObject.componentObject = componentObject;
            }
            // If the user removed the text from the component object in the circuitDesign, it means they cleared out
            // the cell where they type this value in and want to remove it from the circuitDesign
            if (!updatedCircuitDesign[componentName]) {
                returnObject.remove = true;
                returnObject.componentObject = componentObject;
            }
        }
        return returnObject;
    };

    handleSelectedFromTable = (evt) => {
        // This function is called after user check marks billing segments
        // and adds them to the edit list
        const selectedCircuitIds = evt.detail.selectedItems.map(circuit => circuit.circuitDesignId);
        this.setState({
            massUpdateSelectedCircuitDesignIds: selectedCircuitIds
        });
    };

    handleStageSubmit = async () => {
        // Dismiss the flashbar
        this.props.handleFlashBarMessagesFromChildTabs(false, false, true);
        this.setState({
            hasBeenSubmittedOnce: true,
            isUpdateStageInProgress: true,
            allFieldsDisabled: true
        });
        // This variable is used to exit the handleStageSubmit function if an error occurs. The error in question
        // occurs inside of a forEach loop, from which we cannot use return to exit the function. After each component
        // is processed, we check this variable, and, if it is true, exit the handleStageSubmitFunction
        let exitSubmit = false;
        const updatedCircuitDesignObjects = HelperFunctions.deepClone(this.state.updatedCircuitDesignObjects);
        // Here we define a map which contains each circuitDesignId and all of its current node device names,
        // based on the four node componentNames (nodeA, nodeZ, leverA, leverZ)
        const existingCircuitDesignToNodeDeviceNameMap = {};
        this.props.circuitDesignObjects.forEach((circuitDesign) => {
            const nodeMap = Object.assign({},
                {
                    [Constants.COMPONENT_NAMES.nodeA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        circuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeA
                    )
                },
                {
                    [Constants.COMPONENT_NAMES.nodeZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        circuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.nodeZ
                    )
                },
                {
                    [Constants.COMPONENT_NAMES.leverA]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        circuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverA
                    )
                },
                {
                    [Constants.COMPONENT_NAMES.leverZ]: HelperFunctions.getDisplayValueFromComponentName(
                        this.props.componentIdToObjectMap,
                        circuitDesign.positionMap,
                        Constants.COMPONENT_NAMES.leverZ
                    )
                });
            Object.assign(existingCircuitDesignToNodeDeviceNameMap, {
                [circuitDesign[Constants.ATTRIBUTES.circuitDesignId]]: nodeMap
            });
        });
        // check for errors
        if (updatedCircuitDesignObjects.some(circuitDesign =>
            Object.values(circuitDesign.errorTexts).some(errorText => errorText))) {
            this.setState({
                isUpdateStageInProgress: false,
                allFieldsDisabled: false
            });
            return;
        }

        // Here we obtain the existing and update circuit to component maps. These let us know if any components need
        // to be reloaded before we submit the appropriate modifyActivity
        const { existingCircuitToComponentMap, updatedCircuitToComponentMap } = this.generateCircuitToComponentMap();

        try {
            // Here we call updateCircuitDesignActivity to submit any changes to the useExistingLag or subStatus field
            const circuitDesignObjects = HelperFunctions.deepClone(this.props.circuitDesignObjects);
            // Only update circuits that need to be updated (otherwise making expensive backend calls for no reason)
            const circuitsToUpdate = HelperFunctions.createNewApiObjectsCircuitWrapperForStage(
                circuitDesignObjects, updatedCircuitDesignObjects
            );
            if (circuitsToUpdate.length > 0) {
                // Remove all the fields that are not necessary for V2
                await this.FremontBackendClient.updateCircuitDesignInfo(circuitsToUpdate, this.props.auth);
            }
            if (Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.order.serviceType)) {
                // Here we create the list of sites used in the updateSiteRequest
                const siteMap = {};
                updatedCircuitDesignObjects.forEach((updatedCircuitDesign) => {
                    [Constants.COMPONENT_NAMES.siteA].forEach((componentName) => {
                        // Here we determine which siteId has been chosen for this circuit
                        const siteAId = updatedCircuitDesign[Constants.ATTRIBUTES.siteAId];
                        if (siteAId) {
                            const removeCircuitDesignIdFromRequestObject =
                                this.determineRemoveCircuitDesignIdFromRequest(
                                    updatedCircuitDesign, componentName, Constants.COMPONENT_TYPES.site
                                );
                            if (removeCircuitDesignIdFromRequestObject.errorRetrievingComponentObject) {
                                // Here we set the exitSubmit variable to true so we can exit the
                                // submit function as a whole
                                exitSubmit = true;
                                // Here we exit the forEach loop if there was an error retrieving the component object
                                return;
                            }
                            // We already have request object for this site
                            if (siteAId in siteMap) {
                                siteMap[siteAId][Constants.FREMONT_OBJECTS.site.circuitDesignIdListToAddFromRequest]
                                    .push(updatedCircuitDesign[Constants.ATTRIBUTES.circuitDesignId]);
                            } else {
                                siteMap[siteAId] = HelperFunctions.deepClone(
                                    this.props.siteOptions.find(siteOption => siteOption.value === siteAId).site
                                );
                                siteMap[siteAId][
                                    Constants.ATTRIBUTES.componentNameFromRequest
                                ] = Constants.COMPONENT_NAMES.siteA;
                                siteMap[siteAId][
                                    Constants.FREMONT_OBJECTS.site.circuitDesignIdListToAddFromRequest
                                ] = [updatedCircuitDesign[Constants.ATTRIBUTES.circuitDesignId]];
                            }
                        }
                    });
                });
                if (exitSubmit) return;
                if (Object.values(siteMap).length > 0) {
                    const updatedSites = await this.FremontBackendClient.modifySite(
                        Object.values(siteMap), this.props.siteOptions.map(option => option.site), this.props.auth
                    );
                    // If any of the sites have changes, we need to update this.state.nodeOptions because some of the
                    // nodes might have been modified in dynamo by the modifySite
                    const needToLoadNodes = Object.keys(existingCircuitToComponentMap).some(circuitDesignId =>
                        existingCircuitToComponentMap[circuitDesignId][Constants.COMPONENT_NAMES.siteA]
                        !== updatedCircuitToComponentMap[circuitDesignId][Constants.COMPONENT_NAMES.siteA]);
                    if (needToLoadNodes) {
                        await this.fetchNodeOptions(updatedSites.sites, false);
                    }
                }
            }
            // Here we create the list of nodes used in the modifyNodeRequest
            const nodeList = [];
            updatedCircuitDesignObjects.forEach((updatedCircuitDesign) => {
                [Constants.COMPONENT_NAMES.nodeA, Constants.COMPONENT_NAMES.nodeZ, Constants.COMPONENT_NAMES.leverA,
                    Constants.COMPONENT_NAMES.leverZ].forEach((componentName) => {
                    // Here we determine whether the circuitDesignObject has a site (based on whether we are looking
                    // at an A side or Z side node component)
                    const updatedSiteId = componentName.includes("A")
                        ? updatedCircuitDesign[Constants.ATTRIBUTES.siteAId]
                        : updatedCircuitDesign[Constants.ATTRIBUTES.siteZId];
                    const updatedSiteName = componentName.includes("A")
                        ? updatedCircuitDesign[Constants.COMPONENT_NAMES.siteA]
                        : updatedCircuitDesign[Constants.COMPONENT_NAMES.siteZ];

                    // We only proceed if the updatedCircuitDesignObject has a siteId and a siteName. If the user has
                    // not selected a site for a particular circuit, they cannot add a node.
                    if (updatedSiteId && updatedSiteName) {
                        const removeCircuitDesignIdFromRequestObject = this.determineRemoveCircuitDesignIdFromRequest(
                            updatedCircuitDesign, componentName, Constants.COMPONENT_TYPES.node
                        );
                        if (removeCircuitDesignIdFromRequestObject.errorRetrievingComponentObject) {
                            // Here we set the exitSubmit variable to true so we can exit the submit function as a whole
                            exitSubmit = true;
                            // Here we exit the forEach loop if there was an error retrieving the component object
                            return;
                        }

                        // Here we assign the existingNodeObject. The way we define "existing" is if the node existed
                        // on the circuit at the time this request was submitted. This helps us determine whether
                        // the node is being removed or not.
                        const existingNodeObject = removeCircuitDesignIdFromRequestObject.componentObject;

                        // The nodes deviceName is stored on the circuit under the corresponding component name
                        const deviceName = updatedCircuitDesign[componentName];

                        // Here we instantiate the nodeObject to add to the nodeList with all the necessary values
                        // except for the deviceName which we populate below
                        const nodeObjectForNodeList = {
                            [Constants.ATTRIBUTES.componentNameFromRequest]: componentName,
                            [Constants.ATTRIBUTES.circuitDesignIdFromRequest]:
                                updatedCircuitDesign[Constants.ATTRIBUTES.circuitDesignId],
                            [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]:
                                removeCircuitDesignIdFromRequestObject.remove
                        };

                        if (deviceName) {
                            // If the device name is non-null, we add it to the node and to submit
                            // the node to be processed
                            nodeObjectForNodeList[Constants.ATTRIBUTES.deviceName] = deviceName;
                            nodeList.push(nodeObjectForNodeList);
                        } else if (existingNodeObject
                            && existingNodeObject[Constants.ATTRIBUTES.siteId] === updatedSiteId) {
                            // If the deviceName is null and the circuit previously had a node object, that means the
                            // user is trying to remove a node. However, we only submit the node to be processed (and
                            // removed) if the existing nodes siteId equals the updatedSiteId on the circuit. If the
                            // siteIds are different, it means the site has been changed and the updateSiteActivity
                            // will take care of removing the node from the circuit
                            nodeObjectForNodeList[Constants.ATTRIBUTES.deviceName] = existingNodeObject.deviceName;
                            nodeList.push(nodeObjectForNodeList);
                        }
                    }
                });
            });
            if (exitSubmit) return;
            if (nodeList.length > 0) {
                const nodeResponse = await this.FremontBackendClient.modifyNode(nodeList, this.props.auth);
                const lagOptions = HelperFunctions.deepClone(this.state.lagOptions);
                nodeResponse.nodes.forEach((node) => {
                    // Add this deviceName with no options so other calls work correctly
                    if (!(node.deviceName in lagOptions)) {
                        lagOptions[node.deviceName] = [];
                    }
                });
                // Here we update the lagOptions state values so that their updated options can be used
                // in the modifyLagMethods below
                this.setState({
                    lagOptions
                });
                // Here we create the list of ports used in the modifyPortRequest
                const portList = [];
                updatedCircuitDesignObjects.forEach((updatedCircuitDesign) => {
                    [Constants.COMPONENT_NAMES.portA, Constants.COMPONENT_NAMES.portZ,
                        Constants.COMPONENT_NAMES.leverAInternalInterface,
                        Constants.COMPONENT_NAMES.leverZInternalInterface,
                        Constants.COMPONENT_NAMES.leverAExternalInterface,
                        Constants.COMPONENT_NAMES.leverZExternalInterface].forEach((componentName) => {
                        // We know which ports map to which nodes, so we obtain the component name of the appropriate
                        // node here
                        const correspondingNodeComponentName = PortReservationHandler.PORT_TO_NODE_MAP[componentName];
                        // Based on the corresponding node component name, we find the device name of that node if
                        // it is stored on the circuitDesignObject
                        const nodeDeviceName = HelperFunctions.componentNameToLowerCase(
                            updatedCircuitDesign[correspondingNodeComponentName]
                        );
                        // Here we obtain the device name of the node that existed on the circuit
                        // before the modifyNode activity ran
                        const oldNodeDeviceName = existingCircuitDesignToNodeDeviceNameMap[
                            updatedCircuitDesign[Constants.ATTRIBUTES.circuitDesignId]][
                            correspondingNodeComponentName];

                        let node;
                        if (nodeDeviceName) {
                            // Using the deviceName we found above (which must be unique), we obtain the correct node
                            node = nodeResponse.nodes.find(nodeObject =>
                                nodeObject.deviceName === nodeDeviceName);
                        } else {
                            // If the nodeDeviceName is blank, than either the node object does not
                            // exist or the user removed the node as a part of the modifyNodeRequest. In order to
                            // obtain the correct nodeId for the port, we must search for the corresponding node
                            // which is inside of the nodeList we generate above
                            node = nodeList.find(nodeObject =>
                                nodeObject.circuitDesignIdFromRequest === updatedCircuitDesign.circuitDesignId
                                && nodeObject.componentNameFromRequest === correspondingNodeComponentName);
                            // If the node in question is not a part of the nodeList, that means it did not exist
                            // at all in which case we cannot create a port
                        }

                        // We only proceed if the node object for the corresponding port exists
                        if (node) {
                            const removeCircuitDesignIdFromRequestObject =
                                this.determineRemoveCircuitDesignIdFromRequest(
                                    updatedCircuitDesign, componentName, Constants.COMPONENT_TYPES.port
                                );
                            if (removeCircuitDesignIdFromRequestObject.errorRetrievingComponentObject) {
                                // Here we set the exitSubmit variable to true so we can
                                // exit the submit function as a whole
                                exitSubmit = true;
                                // Here we exit the forEach loop if there was an error retrieving the component object
                                return;
                            }

                            // Here we assign the existingPortObject. The way we define "existing" is if the port
                            // existed on the circuit at the time this request was submitted. This helps us
                            // determine whether the port is being removed or not.
                            const existingPortObject = removeCircuitDesignIdFromRequestObject.componentObject;

                            // Here we define the ports interface name, as entered by the user
                            const portInterfaceName = updatedCircuitDesign[componentName];

                            // Here we instantiate the portObject to add to the portList with all the necessary
                            // values except for the interfaceName which we populate below
                            const portObjectForPortList = {
                                deviceName: node[Constants.ATTRIBUTES.deviceName],
                                [Constants.ATTRIBUTES.componentNameFromRequest]: componentName,
                                [Constants.ATTRIBUTES.circuitDesignIdFromRequest]:
                                    updatedCircuitDesign.circuitDesignId,
                                [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]:
                                    removeCircuitDesignIdFromRequestObject.remove
                            };

                            if (portInterfaceName) {
                                // If the interface name is non-null, we add it to the port and submit
                                // the port to be processed
                                portObjectForPortList[Constants.ATTRIBUTES.interfaceName] = portInterfaceName;
                                portList.push(portObjectForPortList);
                            } else if (existingPortObject && nodeDeviceName === oldNodeDeviceName) {
                                // If the interfaceName is null and the circuit previously had a port object,
                                // that means the user is trying to remove a port. However, we only submit the port
                                // to be processed (and removed) if the deviceName on the current node is the same
                                // as the old node. If the device names changed, than the user has changed the node
                                // and the modifyNodeActivity will handle removing the port from the circuitDesign
                                portObjectForPortList[Constants.ATTRIBUTES.interfaceName] =
                                    existingPortObject.interfaceName;
                                portList.push(portObjectForPortList);
                            }
                        }
                    });
                });
                if (exitSubmit) return;
                let portResponse = null;
                if (portList.length > 0) {
                    portResponse = await this.FremontBackendClient.modifyPort(portList, this.props.auth);
                }
                // If any of the nodes have changes, we need to update this.state.lagOptions because some of the
                // lags might have been modified in dynamo by the modifyNodeActivity
                const needToLoadLags = Object.keys(existingCircuitToComponentMap).some(circuitDesignId =>
                    existingCircuitToComponentMap[circuitDesignId][Constants.COMPONENT_NAMES.nodeA]
                    !== updatedCircuitToComponentMap[circuitDesignId][Constants.COMPONENT_NAMES.nodeA]);
                if (needToLoadLags) {
                    await this.fetchLagOptions(nodeResponse.nodes);
                }
                // Here we create the list of lags used in the modifyLagRequest
                const lagList = [];
                if (Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.order.serviceType)) {
                    updatedCircuitDesignObjects.forEach((updatedCircuitDesign) => {
                        [Constants.COMPONENT_NAMES.lagA].forEach((componentName) => {
                            // We know which lags map to which nodes, so we obtain the component name of the
                            // appropriate node here. Since the only lag component we deal with is lagA, the node
                            // componentName will always be nodeA
                            const correspondingNodeComponentName = Constants.COMPONENT_NAMES.nodeA;
                            // Based on the corresponding node component name, we find the device name of that node if
                            // it is stored on the circuitDesignObject
                            const nodeDeviceName = HelperFunctions.componentNameToLowerCase(
                                updatedCircuitDesign[correspondingNodeComponentName]
                            );
                            // Here we obtain the device name of the node that existed on the circuit
                            // before the modifyNode activity ran
                            const oldNodeDeviceName = existingCircuitDesignToNodeDeviceNameMap[
                                updatedCircuitDesign[Constants.ATTRIBUTES.circuitDesignId]][
                                correspondingNodeComponentName];

                            let node;
                            if (nodeDeviceName) {
                                // Using the deviceName we found above (which must be unique),
                                // we obtain the correct node
                                node = nodeResponse.nodes.find(nodeObject =>
                                    nodeObject.deviceName === nodeDeviceName);
                            } else {
                                // If the nodeDeviceName is blank, than either the node object does not
                                // exist of the user removed the node as a part of the modifyNodeRequest. In order to
                                // obtain the correct nodeId for the lag, we must search for the corresponding node
                                // which is inside of the nodeList we generate above
                                node = nodeList.find(nodeObject =>
                                    nodeObject.circuitDesignIdFromRequest === updatedCircuitDesign.circuitDesignId
                                    && nodeObject.componentNameFromRequest === correspondingNodeComponentName);
                                // If the node in question is not a part of the nodeList, that means it did not exist
                                // at all in which case we cannot create a lag
                            }

                            // We only proceed if the node object for the corresponding lag exists
                            if (node) {
                                const removeCircuitDesignIdFromRequestObject =
                                    this.determineRemoveCircuitDesignIdFromRequest(
                                        updatedCircuitDesign, componentName, Constants.COMPONENT_TYPES.node
                                    );
                                if (removeCircuitDesignIdFromRequestObject.errorRetrievingComponentObject) {
                                    // Here we set the exitSubmit variable to true so we can exit the
                                    // submit function as a whole
                                    exitSubmit = true;
                                    // Here we exit the forEach loop if there was an error
                                    // retrieving the component object
                                    return;
                                }

                                // Here we assign the existingLagObject. The way we define "existing" is if the lag
                                // existed on the circuit at the time this request was submitted. This helps us
                                // determine whether the lag is being removed or not.
                                const existingLagObject = removeCircuitDesignIdFromRequestObject.componentObject;

                                // Here we define the lags interface name, as entered by the user
                                const lagInterfaceName = updatedCircuitDesign[componentName];

                                // Here we instantiate the lagObject to add to the lagList with all the necessary
                                // values except for the interfaceName which we populate below
                                const lagObjectForLagList = {
                                    deviceName: node[Constants.ATTRIBUTES.deviceName],
                                    [Constants.ATTRIBUTES.componentNameFromRequest]: componentName,
                                    [Constants.ATTRIBUTES.circuitDesignIdFromRequest]:
                                        updatedCircuitDesign.circuitDesignId,
                                    [Constants.ATTRIBUTES.removeCircuitDesignIdFromRequest]:
                                        removeCircuitDesignIdFromRequestObject.remove
                                };

                                // Here we attach portIdFromRequest to the lag if it has an associatedPort
                                // (which will always be portA for lagA)
                                if (updatedCircuitDesign[Constants.COMPONENT_NAMES.portA] && portResponse) {
                                    const portObject = portResponse.ports.find(port =>
                                        port[Constants.ATTRIBUTES.deviceNameInterfaceName] ===
                                        `${node.deviceName}${updatedCircuitDesign[Constants.COMPONENT_NAMES.portA]}`);
                                    if (portObject) {
                                        lagObjectForLagList[Constants.ATTRIBUTES.portIdFromRequest] = portObject.portId;
                                    }
                                }

                                if (lagInterfaceName) {
                                    // If the interface name is non-null, we add it to the lag and submit
                                    // the lag to be processed
                                    lagObjectForLagList[Constants.ATTRIBUTES.interfaceName] = lagInterfaceName;
                                    lagList.push(lagObjectForLagList);
                                } else if (existingLagObject && nodeDeviceName === oldNodeDeviceName) {
                                    // If the interfaceName is null and the circuit previously had a lag object,
                                    // that means the user is trying to remove a lag. However, we only submit the lag
                                    // to be processed (and removed) if the deviceName on the current node is the same
                                    // as the old node. If the device names changed, than the user has changed the node
                                    // and the modifyNodeActivity will handle removing the lag from the circuitDesign
                                    lagObjectForLagList[Constants.ATTRIBUTES.interfaceName] =
                                        existingLagObject.interfaceName;
                                    lagList.push(lagObjectForLagList);
                                }
                            }
                        });
                    });
                    if (exitSubmit) return;
                    if (lagList.length > 0) {
                        await this.FremontBackendClient.modifyLag(lagList, this.props.auth);
                    }
                }
            }
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);

            this.props.handleFlashBarMessagesFromChildTabs(
                Constants.FLASHBAR_STRINGS.flashbarSuccessText,
                false,
                false
            );
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
        } catch (error) {
            // Here we call a helper function which updates all data related to the order
            await this.props.loadData(true, true);
            // Display error message
            this.props.handleFlashBarMessagesFromChildTabs(false, error, false);
            this.setState({
                hasBeenSubmittedOnce: true,
                isUpdateStageInProgress: false,
                isEditClicked: false,
                allFieldsDisabled: false
            });
            this.props.handleStageInEditOrSubmitMode(false);
        }
    };

    render() {
        return (
            !this.state.isEditClicked ?
                <StageDisplayMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.portReservation}
                    showAttachmentModal={false}
                    disableEditButton={OrderValidation.disableEditButton(
                        this.generateCircuitItems().static.length,
                        this.props.isDataLoaded,
                        this.props.order,
                        this.props.editButtonsDisabled
                    )}
                    handleStageEditClick={this.handleStageEditClick}
                    handleCompleteStage={HelperFunctions.isOrderInterconnectChange(this.props.order) ?
                        this.props.handleToggleCompleteStage : null}
                    completeStageMessage="Mark stage for completion"
                    hasStageBeenCompleted={this.props.order.hasPortReservationBeenCompleted}
                    goToComponentAction={this.props.goToComponentAction}
                    circuitItems={this.generateCircuitItems().static}
                    content={
                        <div>
                            <PortReservationStageDisplayMode
                                stageName={Constants.STAGE_NAMES.portReservation}
                                order={this.props.order}
                                siteNames={this.props.siteNames}
                                circuitItems={this.generateCircuitItems().static}
                                isDataLoaded={this.props.isDataLoaded}
                            />
                        </div>
                    }
                />
                :
                <StageEditMode
                    order={this.props.order}
                    stageName={Constants.STAGE_NAMES.portReservation}
                    handleStageEditClick={this.handleStageEditClick}
                    handleStageSubmit={this.handleStageSubmit}
                    isUpdateStageInProgress={this.state.isUpdateStageInProgress}
                    content={
                        <PortReservationStageEditMode
                            order={this.props.order}
                            siteNames={this.props.siteNames}
                            circuitItems={this.generateCircuitItems().dynamic}
                            areOptionsLoading={this.state.areOptionsLoading}
                            handleSelectedFromTable={this.handleSelectedFromTable}
                            massUpdateSelectedCircuitDesignIds={this.state.massUpdateSelectedCircuitDesignIds}
                        />
                    }
                />
        );
    }
}

export default PortReservationHandler;