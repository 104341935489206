import React, { Component } from "react";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import FremontHeader from "common/FremontHeader";
import HelperFunctions from "common/HelperFunctions";
import {
    OperationsPageTabs
} from "operations/OperationsPageInformation";
import {
    FremontAlert
} from "utils/CommonComponents";

/**
 * OperationsPage acts a the homepage for various operations activity
 */
export default class OperationsPage extends Component {
    state = {
        activeTabId: "",
        flashbar: {
            type: "",
            text: "",
            flashbarLoading: false
        },
        admin: false
    };

    componentDidMount = async () => {
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError),
                { loading: false });
        }
        // the following if statement is only for dev environment.
        // Its basically a dummy value that will be returned for the dropdown field ONLY when we are in
        // localhost, as we cannot get a return value for getResourceNamesBasedOffResourceType due to the fact it is a
        // BRASS related API and wont work for your local stack.
        this.setState({
            admin: HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()
                || HelperFunctions.userInPosixGroup(this.props.user, Constants.POSIX_GROUPS.NEST)
                || HelperFunctions.isMimickingUser(this.props.auth),
            activeTabId: Constants.OPERATIONS_PAGE_TAB_IDS.BULK_UPDATE
        });
    };

    /**
     **************************************************************************************************
     * DATA LOADING
     **************************************************************************************************
     */

    FremontBackendClient = new FremontBackendClient();

    /**
     * This function handles changing the active tab on the Tabs component, resetting all data arrays,
     * and resetting the flashbar.
     */
    handleTabChange = async (evt) => {
        const { activeTabId: activeId } = evt.detail;
        this.handleFlashbarClose();
        this.setState({
            activeTabId: activeId
        });
        if (!this.props.auth.isUserSignedIn() || !this.props.auth.getSignInUserSession().isValid()) {
            HelperFunctions.displayFlashbarError(this, new Error(Constants.FLASHBAR_STRINGS.flashbarMidwayError), {});
        }
    };

    /**
     * This handler method calls the helper function to dismiss the flashbar
     */
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, { loading: false });
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    handleFlashBarMessagesFromChildTabs = (flashbarSuccessText, error, dismiss) => {
        HelperFunctions.handleFlashBarMessagesFromChildTabs(this, flashbarSuccessText, error, dismiss);
    };


    /**
     **************************************************************************************************
     * RENDERING
     **************************************************************************************************
     */

    render() {
        return (
            <div>
                <div>
                    <FremontHeader
                        history={this.props.history}
                        flashbarText={this.state.flashbar.text}
                        flashbarType={this.state.flashbar.type}
                        flashbarLoading={this.state.flashbar.flashbarLoading}
                        onDismiss={this.handleFlashbarClose}
                        auth={this.props.auth}
                        sideNavError={this.props.sideNavError}
                        updateSearchResults={this.props.updateSearchResults}
                    />
                    {this.state.admin ?
                        <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                            <OperationsPageTabs
                                auth={this.props.auth}
                                activeTabId={this.state.activeTabId}
                                handleTabChange={this.handleTabChange}
                                handleFlashBarMessagesFromChildTabs={this.handleFlashBarMessagesFromChildTabs}
                            />
                        </div> :
                        <div>
                            <FremontAlert header="No Access to This Page" type="error">
                                User is not in valid POSIX groups. Only admins have access to this page.
                            </FremontAlert>
                        </div>
                    }
                    {(HelperFunctions.isBetaOrGamma() || HelperFunctions.isLocalHost()) &&
                        <iframe
                            title="Halo"
                            src="https://alpha.pshirod.people.aws.dev/tni-explorer?x=-30.058594&y=41.967659&z=3"
                            width="800"
                            height="600"
                            frameBorder="0"
                            style={{ border: "0" }} // Use an object for the style prop
                            allowFullScreen
                            allow-same-origin
                        />
                    }
                </div>
            </div>
        );
    }
}
