import React from "react";
import {
    Box, Flashbar,
    Form,
    FormField,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontInput,
    FremontSelect,
    requiredLabel,
    ComponentConstants
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

const getProviderServiceErrorText = (props, errorText) => {
    if (!HelperFunctions.isEmpty(props.selectedProvider) && !props.entitiesLoading
        && !props.correspondingProviderService) {
        return errorText;
    }
    return "";
};

const getFormErrorText = (props, optionsList, errorText) => {
    // If there is no provider service, we don't want to show the error text for the contact/asn options because the
    // action item here for the customer is to create the provider service first
    if (!!props.correspondingProviderService && !props.entitiesLoading
        && HelperFunctions.isEmpty(optionsList)) {
        return errorText;
    }
    return "";
};

const hasContact = order => !!order[Constants.ATTRIBUTES.contactId];

const hasAsn = order => !!order[Constants.ATTRIBUTES.asnId];

const isReadyToSubmit = (updatedOrder, order) => {
    // This should never trigger for path orders anyways, but another safety check
    if (HelperFunctions.isOrderPathOrder(updatedOrder)) {
        return false;
    }

    // Instead of using business rules, we're using the existing order as the source of truth. If it has a field, we
    // need the updatedOrder to have that field as well
    const shouldHaveAsn = !!order[Constants.ATTRIBUTES.asnId];
    const shouldHaveContact = !!order[Constants.ATTRIBUTES.contactId];

    if (shouldHaveAsn && !hasAsn(updatedOrder)) {
        return false;
    }
    if (shouldHaveContact && !hasContact(updatedOrder)) {
        return false;
    }

    // If we get here, it means that we have the fields we need
    return true;
};

const renderSelectContact = (props) => {
    if (HelperFunctions.isOrderChangeOrder(props.order)) {
        return null;
    }
    return (
        <FormField
            label={requiredLabel("Contact:")}
            errorText={getFormErrorText(props, props.contactOptions,
                `There are no contacts for the ${props.order[Constants.ATTRIBUTES.serviceType]} provider service.`
                + " Create them and return to this form.")}
        >
            <FremontSelect
                id={Constants.ATTRIBUTES.contactId}
                loading={props.entitiesLoading || props.providersLoading}
                loadingText={Constants.STATUS_TEXTS.loadingContacts}
                statusType={props.entitiesLoading || props.providersLoading ? Constants.LOADING : Constants.FINISHED}
                disabled={
                    (HelperFunctions.isEmpty(props.contactOptions) && !props.entitiesLoading && !props.providersLoading)
                        || props.submissionInProgress
                }
                options={props.contactOptions}
                selectedOption={props.selectedContactOption}
                onChange={props.handleOrderInputChange}
                filteringType="auto"
            />
        </FormField>
    );
};

const OrderProviderChangeModal = props => (
    <Modal
        size="large"
        visible={props.isOrderProviderChangeModalClicked}
        header="Change the Provider on the Order"
        footer={
            <Box variant="span" float="right">
                <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                    <FremontButton
                        variant="normal"
                        onClick={props.hideOrderProviderChangeModal}
                        disabled={props.submissionInProgress}
                    >
                            Cancel
                    </FremontButton>
                    <FremontButton
                        variant="primary"
                        onClick={props.submitOrderUpdate}
                        loading={props.submissionInProgress}
                        disabled={props.providersLoading || props.entitiesLoading
                                || !isReadyToSubmit(props.updatedOrder, props.order)}
                    >
                            Submit
                    </FremontButton>
                </SpaceBetween>
            </Box>
        }
        onDismiss={props.hideOrderProviderChangeModal}
    >
        <Flashbar items={props.errorsToDisplayInModal} onDismiss={props.resetErrorMessages}/>
        <Form>
            <SpaceBetween size={ComponentConstants.SPACE_BETWEEN_CONTAINER_PADDING}>
                <FormField
                    label="Order ID:"
                >
                    <FremontInput
                        id={Constants.ATTRIBUTES.orderId}
                        value={props.updatedOrder[Constants.ATTRIBUTES.orderId]}
                        disabled
                    />
                </FormField>
                <FormField
                    label={requiredLabel("Provider:")}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.providerName}
                        loading={props.providersLoading}
                        loadingText={Constants.STATUS_TEXTS.loadingProviders}
                        statusType={props.providersLoading ? Constants.LOADING : Constants.FINISHED}
                        disabled={props.submissionInProgress}
                        options={props.providerOptions}
                        selectedOption={
                            {
                                label: props.updatedOrder[Constants.ATTRIBUTES.providerName],
                                value: props.updatedOrder[Constants.ATTRIBUTES.providerName]
                            }
                        }
                        onChange={props.handleOrderInputChange}
                        filteringType="auto"
                    />
                </FormField>
                <FormField
                    label="Provider Service:" // Not making this a required label because it cannot change
                    // The message needs to be specific to the service type, and since changing the provider would
                    // clear out the serviceType on the updatedOrder, we are going to use the original order `order`
                    errorText={getProviderServiceErrorText(props,
                        `There is no ${props.order[Constants.ATTRIBUTES.serviceType]} service for provider ${props.selectedProvider[Constants.ATTRIBUTES.providerName]}. Create it and return to this form.`)}
                >
                    {/* This is a disabled select with no options because they cannot change the service type */}
                    <FremontInput
                        id={Constants.ATTRIBUTES.providerServiceId}
                        disabled
                        value={props.correspondingProviderService ?
                            props.correspondingProviderService[Constants.ATTRIBUTES.serviceType] : ""}
                    />
                </FormField>
                {renderSelectContact(props)}

                {/* This will render only if the order already had an ASN Id on it */}
                {props.order[Constants.ATTRIBUTES.asnId] &&
                <FormField
                    label={requiredLabel("ASN:")}
                    errorText={getFormErrorText(props, props.asnOptions,
                        `There are no ASNs for the ${props.order[Constants.ATTRIBUTES.serviceType]} provider service. Create them and return to this form.`)}
                >
                    <FremontSelect
                        id={Constants.ATTRIBUTES.asnId}
                        loading={props.entitiesLoading || props.providersLoading}
                        loadingText={Constants.STATUS_TEXTS.loadingAsns}
                        statusType={props.entitiesLoading || props.providersLoading ?
                            Constants.LOADING : Constants.FINISHED}
                        disabled={(HelperFunctions.isEmpty(props.asnOptions)
                            && !props.entitiesLoading && !props.providersLoading) || props.submissionInProgress}
                        options={props.asnOptions}
                        selectedOption={props.selectedAsnOptions}
                        onChange={props.handleOrderInputChange}
                        filteringType="auto"
                    />
                </FormField>
                }
            </SpaceBetween>
        </Form>
    </Modal>
);

export default OrderProviderChangeModal;