import React, { Component } from "react";
import Constants from "utils/Constants";
import AttachmentModal from "attachment/AttachmentModal";
import HelperFunctions from "common/HelperFunctions";
import OrderValidation from "order/OrderValidation";
import FremontBackendClient from "common/FremontBackendClient";
import PolarisUtils from "utils/PolarisUtils";

export default class AttachmentHandler extends Component {
    state = {
        attachments: [],
        originalAttachments: [],
        attachmentFormToReactRefMap: {},
        errorToDisplayOnAttachmentModal: [],
        isAttachmentSubmissionInProgress: false,
        hasSubmittedAttachmentOnce: false,
        attachmentIndexBeingModified: "",
        attachmentOptions: [],
        fileNameToFileMap: {},
        // Circuit Design specific options
        circuitDesignsAttachmentTypeMap: {},
        requiredAttachments: []
    };

    componentDidMount = () => {
        this.updateAttachmentState(this.props);

        // Figure out the attachment options based on service type. We do this here because we shouldn't do this on
        // every re-render of our handler
        let { attachmentOptions } = this.props;
        if (Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.serviceType)) {
            attachmentOptions = [Constants.ATTACHMENT_TYPES.loaA];
        }
        if (this.props.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
            // Remove loaA, loaZ and completionNotice for Paths. SIM 3247.
            if (Object.values(Constants.PATH_CUSTOMER_FABRICS).includes(this.props.customerFabric)) {
                attachmentOptions = [
                    Constants.ATTACHMENT_TYPES.KMZ
                ];
            } else if (Object.values(Constants.CUSTOMER_FABRICS).includes(this.props.customerFabric)) {
                attachmentOptions = [
                    Constants.ATTACHMENT_TYPES.loaA,
                    Constants.ATTACHMENT_TYPES.loaZ,
                    Constants.ATTACHMENT_TYPES.completionNotice,
                    Constants.ATTACHMENT_TYPES.KMZ
                ];

                // Additionally, Backbone Span customer fabric requires BERT and RFC Testing attachments
                if (this.props.customerFabric === Constants.CUSTOMER_FABRICS.BACKBONE_SPAN) {
                    attachmentOptions.push(
                        Constants.ATTACHMENT_TYPES.BERT,
                        Constants.ATTACHMENT_TYPES.RFC
                    );
                }
            } else {
                attachmentOptions = [
                    Constants.ATTACHMENT_TYPES.loaA,
                    Constants.ATTACHMENT_TYPES.loaZ,
                    Constants.ATTACHMENT_TYPES.completionNotice
                ];
            }
        }
        if (!this.props.serviceType) {
            attachmentOptions = Constants.VALID_ORDER_ATTACHMENT_TYPES;
        }
        if (this.props.entityType === Constants.BULK_UPDATE_JOB_ENTITY_TYPE) {
            attachmentOptions = Constants.VALID_BULK_UPDATE_ATTACHMENT_TYPES;
        }
        let requiredAttachments = [];
        // For interconnects we don't want the requiredAttachments logic to be active
        // as the first time user sees the attachment modal is on cabling stage and we want to let them attach
        // one or two attachments at a time. For other workflows, we want this logic to prevent users from removing
        // already attached required attachments!
        // Attachments are also optional for all of the backbone path install orders
        if (!Constants.INTERCONNECT_SERVICE_TYPES.includes(this.props.serviceType) &&
            !Constants.PATH_CUSTOMER_FABRICS.includes(this.props.customerFabric) &&
            Constants.ORDER_TYPES.INSTALL === this.props.orderType &&
            HelperFunctions.isStageCompleted(this.props.stageStatusMap[Constants.STAGE_NAMES.submitForApproval])) {
            requiredAttachments = [Constants.ATTACHMENT_TYPES.loaA, Constants.ATTACHMENT_TYPES.completionNotice];
            if (this.props.serviceType === Constants.SERVICE_TYPES.BACKBONE) {
                requiredAttachments.push(Constants.ATTACHMENT_TYPES.loaZ);
            }
        }

        this.setState({
            attachmentOptions,
            requiredAttachments
        });
    };

    /**
     * This helps attach our meta-data to each attachment object. We only want to do that when we click to show or hide
     * the modal
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        if (prevProps.isAttachmentModalClicked !== this.props.isAttachmentModalClicked) {
            this.updateAttachmentState(this.props);
        }
    }

    getUniqueKey = (attachmentName, attachmentType) => (`${attachmentName}${Constants.SEPARATOR}${attachmentType}`);

    /**
     * This component is only mounted once; however we want to have a "clean" state in the modal when we open it. This
     * method helps with giving us a "clean" state
     */
    updateAttachmentState = ({
        attachments,
        attachmentFormToReactRefMap = {},
        hasSubmittedAttachmentOnce
    }) =>
        this.setState({
            attachments,
            originalAttachments: HelperFunctions.deepClone(attachments),
            attachmentFormToReactRefMap,
            hasSubmittedAttachmentOnce,
            attachmentIndexBeingModified: "",
            errorToDisplayOnAttachmentModal: [],
            isAttachmentSubmissionInProgress: false,
            circuitDesignsAttachmentTypeMap: this.generateAttachmentTypeMap(attachments),
            fileNameToFileMap: {}
        });

    /**
     * Calculate the attachmentType map (groups the circuits by attachment type)
     * All this method does is contain a mapping of attachmentType <-> circuit designs that have an attachment of that
     * type. We need to keep this map updated when the component mounts, updates, and when there are changes made to
     * our attachments modal (i.e the user adds an additional circuit to an attachment)
     *
     * We use this map to ensure that the circuit design options we show for a given attachment makes sense; this also
     * helps us help the user avoid invalid selections (there are certain behaviors where if we don't have this map
     * up to date on user input changes, the user could select the same circuit on multiple attachments)
     */
    generateAttachmentTypeMap = (attachments) => {
        // Figure out which circuit designs already have attachments based on attachment type
        const attachmentTypeMap = {};
        attachments.forEach((attachment) => {
            // Add the key to the map if its not already there
            if (!attachmentTypeMap[attachment.attachmentType]) attachmentTypeMap[attachment.attachmentType] = [];

            // For change orders we want to filter out older revisions
            const allCircuitDesignIds = !this.props.circuitDesignOptions ? [] :
                this.props.circuitDesignOptions.map(circuitDesign => circuitDesign[PolarisUtils.OPTION_VALUE_KEY]);
            const validEntityIdList = allCircuitDesignIds.filter(
                circuitDesignId => attachment.entityIdList.includes(circuitDesignId)
            );

            attachmentTypeMap[attachment.attachmentType] =
                attachmentTypeMap[attachment.attachmentType].concat(validEntityIdList);
        });
        return attachmentTypeMap;
    };

    addNewAttachment = () => {
        // We can reuse the addMultipleAttachments here with a set length of 1. We need a new "attachment" in
        // order to render the boxes for attachment.
        this.setState({
            attachments: this.addMultipleAttachments(1, "")
        });
    };

    addMultipleAttachments = (length, attachmentType) => {
        const attachmentsClone = HelperFunctions.deepClone(this.state.attachments);
        const originalLength = attachmentsClone.length;
        for (let index = 0; index < length; index += 1) {
            const attachmentFormKey = `attachmentForm${originalLength + index}`;
            attachmentsClone.push({
                fileName: "",
                contentType: "",
                contentLength: "",
                attachmentType,
                attachmentFormKey,
                // For multiple attachments currently the entity is always going to be orderId
                entityIdList: this.props.parentEntityIdList
                    || (this.props.entityType === Constants.ORDER_ENTITY_TYPE ? [this.props.orderId] : []),
                selectedOptions: [],
                entityType: this.props.entityType,
                hasAttachmentBeenModified: false,
                // For multiple attachments, error messages should be all set to empty as the first attachment's errors
                // are now all resolved and we force the attachmentType
                errorTexts: HelperFunctions.deepClone(OrderValidation.getNewAttachmentErrorTexts(this.props.entityType))
            });
            // We are using Object.assign() to append to attachmentFormToReactRefMap so that we don't run into an
            // exception thrown because of attachmentFormToReactRefMap's circular structure (Object.assign()
            // in this case is merging objects, not copying them)
            this.setState({
                attachmentFormToReactRefMap: Object.assign(this.state.attachmentFormToReactRefMap,
                    { [attachmentFormKey]: React.createRef() })
            });
        }
        return attachmentsClone;
    };

    removeAttachment = (evt) => {
        let attachmentsClone = HelperFunctions.deepClone(this.state.attachments);
        const attachmentFormKeyToRemove = evt.target.id.replace("removeAttachment", "");

        // get the attachment index, its at the index at the end fo attachmentForm0...etc,
        // we need to also remove it from fileNameToFileMap
        const attachmentToRemove = this.state.attachments[attachmentFormKeyToRemove.replace("attachmentForm", "")];
        const uniqueKey = this.getUniqueKey(attachmentToRemove.fileName, attachmentToRemove.attachmentType);
        if (this.state.fileNameToFileMap[uniqueKey]) {
            const updatedFileNameToFileMap = Object.assign({}, this.state.fileNameToFileMap);
            delete updatedFileNameToFileMap[uniqueKey];
            this.setState({
                fileNameToFileMap: updatedFileNameToFileMap
            });
        }

        // Remove that attachment and reset the attachmenFormKeys indices
        attachmentsClone = attachmentsClone
            .filter(attachment => attachment.attachmentFormKey !== attachmentFormKeyToRemove);
        attachmentsClone.forEach((attachment, index) =>
            Object.assign(attachment, { attachmentFormKey: `attachmentForm${index}` }));

        const attachmentFormToReactRefMap = {};
        attachmentsClone.forEach(attachment =>
            Object.assign(attachmentFormToReactRefMap,
                { [attachment.attachmentFormKey]: React.createRef() }));
        this.setState({
            attachmentFormToReactRefMap
        });

        // Recalculate the circuit designs that are occupied with an attachment
        const circuitDesignsAttachmentTypeMap = this.generateAttachmentTypeMap(attachmentsClone);
        this.setState({ attachments: attachmentsClone, circuitDesignsAttachmentTypeMap });
    };

    handleAttachmentModalInputChange = (evt) => {
        const attachmentsClone = HelperFunctions.deepClone(this.state.attachments);
        const { fileNameToFileMap } = this.state;

        const elementId = evt.target.id;
        const index = evt.target.id.match(/\d+$/)[0];
        const attachmentToModify = attachmentsClone[index];
        if (elementId.includes("type")) {
            const attachmentType = evt.detail.selectedOption[PolarisUtils.OPTION_VALUE_KEY];

            // If we change the attachmentType that was already defined, we need to clear out all the entityIdList and
            // selected options
            if (attachmentToModify.attachmentType) {
                attachmentToModify.entityIdList = [];
                attachmentToModify.selectedOptions = [];
                attachmentToModify.errorTexts.entityIdList = Constants.ERROR_STRINGS.blankMultiSelectErrorText;
            }

            // If we are changing the attachment type, we must also handle updating the key value in the
            // fileNameToFileMap map in state to ensure the attachment gets persisted as expected
            const oldUniqueKey = this.getUniqueKey(attachmentToModify.fileName, attachmentToModify.attachmentType);
            if (fileNameToFileMap[oldUniqueKey]) {
                const newUniqueKey = this.getUniqueKey(attachmentToModify.fileName, attachmentType);
                fileNameToFileMap[newUniqueKey] = fileNameToFileMap[oldUniqueKey];
                delete fileNameToFileMap[oldUniqueKey];
            }

            attachmentToModify.attachmentType = attachmentType;
            attachmentToModify.hasAttachmentBeenModified = true;
            attachmentToModify.errorTexts.attachmentType = attachmentType
                ? "" : Constants.ERROR_STRINGS.blankInput;
        }
        if (elementId.includes("entityIdList")) {
            attachmentToModify.selectedOptions = evt.detail.selectedOptions;
            attachmentToModify.entityIdList =
                evt.detail.selectedOptions.map(option => option[PolarisUtils.OPTION_VALUE_KEY]);
            attachmentToModify.hasAttachmentBeenModified = true;
            attachmentToModify.errorTexts.entityIdList = evt.detail.selectedOptions.length > 0
                ? "" : Constants.ERROR_STRINGS.blankMultiSelectErrorText;
        } else if (elementId.includes("toggleattachmentForm")) {
            // list of circuit ids
            const allCircuitDesignIds =
                this.props.circuitDesignOptions.map(circuitDesign => circuitDesign[PolarisUtils.OPTION_VALUE_KEY]);
            attachmentToModify.selectedOptions = evt.detail.checked ? this.props.circuitDesignOptions : [];
            attachmentToModify.entityIdList = evt.detail.checked ? allCircuitDesignIds : [];
            attachmentToModify.errorTexts.entityIdList =
                evt.detail.checked ? "" : Constants.ERROR_STRINGS.blankMultiSelectErrorText;
            attachmentToModify.hasAttachmentBeenModified = true;
        }

        // Update our map regardless (since both changes to entityIdList and type affect this)
        const circuitDesignsAttachmentTypeMap = this.generateAttachmentTypeMap(attachmentsClone);

        // Update the state
        this.setState({
            attachments: attachmentsClone,
            circuitDesignsAttachmentTypeMap,
            fileNameToFileMap
        });
    };

    triggerFileInput = (evt, ref) => {
        const attachmentIndex = evt.target.id.match(/\d+$/)[0];
        this.setState({
            attachmentIndexBeingModified: attachmentIndex, errorToDisplayOnAttachmentModal: []
        });
        ref.current.click();
    };

    handleFileInput = (evt) => {
        let attachmentsClone = HelperFunctions.deepClone(this.state.attachments);

        const { files } = evt.target;
        const { attachmentType } = attachmentsClone[attachmentsClone.length - 1];

        if (files.length > 0) {
            if (files.length > 1) {
                // So for multiple attachments, we want to assign the attachmentType to all the following
                // attachments after the first one as they are required to be the same
                // this is why the second parameter of this function is chosen to be the last index's value
                attachmentsClone = this.addMultipleAttachments(files.length - 1,
                    attachmentsClone[attachmentsClone.length - 1].attachmentType);
            }

            // Using vanilla for loop as we can break early if any attachments are not meeting quality.
            for (let index = 0; index < files.length; index += 1) {
                const contentLength = files[index].size;
                if (contentLength > Constants.MAX_ATTACHMENT_SIZE) {
                    this.setState({
                        errorToDisplayOnAttachmentModal: HelperFunctions.generateErrorMessageForFlashbar(
                            "File sizes larger than 50MB are not allowed.", "Please try to upload a valid file."
                        )
                    });
                    return;
                }
                // For KMZ and KML files, we may not have the file.type, so we'll manually set the contentType
                let contentType = files[index].type;
                if (!files[index].type) {
                    if (files[index].name.endsWith(".kmz")) {
                        contentType = Constants.CONTENT_TYPE_KMZ;
                    }
                    if (files[index].name.endsWith(".kml")) {
                        contentType = Constants.CONTENT_TYPE_KML;
                    }
                }

                // Validate that its a file that we support (there is backend validation as well, but we want to inform
                // the user early on for a better experience).
                if (!Constants.VALID_ATTACHMENT_CONTENT_TYPES.includes(contentType)) {
                    this.setState({
                        errorToDisplayOnAttachmentModal: HelperFunctions.generateErrorMessageForFlashbar(
                            "Unable to upload unsupported file type.", "Please try to upload a valid file."
                        )
                    });
                    return;
                }

                const newIndex = HelperFunctions.parseInt(this.state.attachmentIndexBeingModified) + index;
                attachmentsClone[newIndex].fileName = files[index].name;
                attachmentsClone[newIndex].contentType = contentType;
                attachmentsClone[newIndex].contentLength = contentLength;
                // We can neutralize the error messages as to hit the file upload button
                // the attachmentType must be chosen
                attachmentsClone[newIndex].errorTexts.file = "";
                attachmentsClone[newIndex].errorTexts.attachmentType = "";
                attachmentsClone[newIndex].hasAttachmentBeenModified = true;

                // We're generating a unique key to make sure only a single file name is associated with a single
                // attachmentType. File0 can only be uploaded as a completion notice once, but we allow
                // File0 to still be used as a "quote" attachment type.
                const uniqueKey = this.getUniqueKey(files[index].name, attachmentType);
                if (this.state.fileNameToFileMap[uniqueKey]) {
                    this.setState({
                        errorToDisplayOnAttachmentModal: HelperFunctions.generateErrorMessageForFlashbar(
                            "Redundant/Duplicate attachment.",
                            "Attachment of this name is already in use for same attachment type."
                        )
                    });
                    return;
                }

                this.setState({
                    // We store the file object in a separate map because File objects do not get copied during the
                    // HelperFunctions.deepClone method which we use liberally on the attachment objects
                    fileNameToFileMap: Object.assign(this.state.fileNameToFileMap,
                        { [uniqueKey]: files[index] })
                });
            }
            // If we break out the loop early due to error messages. We will never get to this point
            // and attachmentsClone will be thrown in the garbage
            this.setState({
                attachments: attachmentsClone
            });
        }
    };

    disableSubmitButton = () => (this.state.attachments.length === this.state.originalAttachments.length
            && this.state.attachments.every(attachment => !attachment.hasAttachmentBeenModified));

    FremontBackendClient = new FremontBackendClient();

    handleAttachmentSubmit = async () => {
        this.setState({
            hasSubmittedAttachmentOnce: true,
            isAttachmentSubmissionInProgress: true,
            errorToDisplayOnAttachmentModal: []
        });

        // Make sure there are no errors on any of the attachments
        let errors = [];

        this.state.attachments.forEach((attachment) => {
            errors = errors.concat(Object.values(attachment.errorTexts).filter(error => error !== ""));
        });

        // If we have a bulk update, we need to ensure that the description is provided
        if (this.props.handleSubmitBulkUpdateJob && !this.props.getBulkUpdateDescription()) {
            errors.push("Job description is required for bulk updates.");
        }

        if (errors.length > 0) {
            this.setState({
                isAttachmentSubmissionInProgress: false,
                errorToDisplayOnAttachmentModal: this.state.errorToDisplayOnAttachmentModal
                    .concat(HelperFunctions.generateErrorMessageForFlashbar(
                        "Missing required fields.", "Please correct all the errors before submitting."
                    ))
            });
            return;
        }

        // If we have requireAllAttachments as true (for example once an order is completed cabling and all the
        // attachments have been attached), we will make sure that any changes ensure that each circuit still has
        // all the required attachments
        if (this.state.requiredAttachments.length > 0) {
            // we loop through the list of requiredAttachments checking to see if all of them are present in the
            // circuitDesignsAttachmentTypeMap
            // just check for undefined in case someone hits the remove button and we dont want a null pointer error
            // otherwise length
            const hasMissingAttachments = this.state.requiredAttachments.some(
                requiredAttachmentType =>
                    this.state.circuitDesignsAttachmentTypeMap[requiredAttachmentType] === undefined ||
                    this.state.circuitDesignsAttachmentTypeMap[requiredAttachmentType].length
                    !== this.props.circuitDesignOptions.length
            );
            if (hasMissingAttachments) {
                this.setState({
                    isAttachmentSubmissionInProgress: false,
                    errorToDisplayOnAttachmentModal: this.state.errorToDisplayOnAttachmentModal
                        .concat(HelperFunctions.generateErrorMessageForFlashbar(
                            "Missing required attachments.", "All circuits must have their required attachments."
                        ))
                });
                return;
            }
        }

        const attachmentsToSubmit = [];
        const attachmentsToVerify = [];

        // Figure out what attachments we need to remove from the circuits by seeing what attachments were in the
        // circuit design originally and are not there now. Obviously, we can only delete persisted attachments
        const selectedAttachmentIds = this.state.attachments
            .map(attachment => attachment[Constants.ATTRIBUTES.attachmentId]).filter(id => !!id);
        // Here we add all the attachmentsToRemove to the list of attachmentsToSubmit
        this.state.originalAttachments.filter(attachment =>
            !!attachment.attachmentId && !selectedAttachmentIds.includes(attachment.attachmentId))
            .forEach((attachment) => {
                const attachmentToRemove = HelperFunctions.createAttachmentRequestObjectFromAttachment(attachment);
                // For each attachment that is being removed, we set its entityIDList to a blank list so that it
                // will be disconnected from all the objects it was previously attached to
                attachmentToRemove[Constants.ATTRIBUTES.entityIdList] = [];
                attachmentToRemove[Constants.ATTRIBUTES.verifyAttachmentFromRequest] = true;
                attachmentsToSubmit.push(attachmentToRemove);
            });

        // Here we loop through each attachment that have been modified and add it to our list of attachments to submit
        this.state.attachments.forEach((attachment) => {
            if (attachment.hasAttachmentBeenModified) {
                const attachmentToSubmit = HelperFunctions.createAttachmentRequestObjectFromAttachment(attachment);
                attachmentsToSubmit.push(attachmentToSubmit);
            }
        });

        if (attachmentsToSubmit.length > 0) {
            // If we have an attachment for a job, we need to create the job first
            let modifyJobResponse;
            if (this.props.handleSubmitBulkUpdateJob) {
                modifyJobResponse = await this.props.handleSubmitBulkUpdateJob();
                const { jobId } = modifyJobResponse.jobs.find(Boolean);

                // TODO: Remove bulk update logic from the file uploader. https://issues.amazon.com/issues/FremontNEST-2902

                // Now we need to add this job id to each attachment
                attachmentsToSubmit.forEach((attachment) => { attachment.entityIdList.push(jobId); });
            }

            // Here we make a backend call to modify the attachment objects
            try {
                const modifyAttachmentResponse = await this.FremontBackendClient.modifyAttachment(
                    attachmentsToSubmit, this.props.auth
                );

                await this.uploadAllAttachments(attachmentsToVerify, modifyAttachmentResponse.attachmentsAndUrls);

                let verifyAttachmentResponse;

                if (attachmentsToVerify.length > 0) {
                    verifyAttachmentResponse = await this.FremontBackendClient.modifyAttachment(
                        attachmentsToVerify, this.props.auth
                    );
                }

                if (this.props.handleSubmitBulkUpdateJob
                    && verifyAttachmentResponse
                    && verifyAttachmentResponse.attachmentsAndUrls.length > 0
                ) {
                    await this.props.handleSubmitBulkUpdateJob(
                        verifyAttachmentResponse.attachmentsAndUrls[0].attachment.attachmentId,
                        modifyJobResponse.jobs.find(Boolean)
                    );

                    // We don't need to reload any data because if we're here, its a request from the operations page
                    this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText);
                    this.props.hideAttachmentModal();
                    return;
                }
            } catch (error) {
                this.setState({
                    errorToDisplayOnAttachmentModal: this.state.errorToDisplayOnAttachmentModal
                        .concat(HelperFunctions.generateErrorMessageForFlashbar(
                            "Unable to add attachment(s).",
                            error.message
                        ))
                });
            }
        }

        // If we were successful, reload the data and close the modal
        if (this.state.errorToDisplayOnAttachmentModal.length === 0) {
            this.setState({
                hasSubmittedAttachmentOnce: false,
                isAttachmentSubmissionInProgress: false
            });

            // Display success in the modal and hide the modal if there were no errors
            this.props.handleFlashBarMessagesFromChildTabs(Constants.FLASHBAR_STRINGS.flashbarSuccessText);
            this.props.hideAttachmentModal();

            // Reload all the information (even the order info in case its updated by another user). We always want to
            // have the latest data.
            await this.props.loadData(true, true);
        } else {
            // Re-enable all the fields for the user to correct or react to the errors
            this.setState({
                isAttachmentSubmissionInProgress: false
            });
        }
    };

    /**
     * Upload all attachments in parallel.
     * @returns {Promise}
     */
    uploadAllAttachments = async (attachmentsToVerify, attachmentsAndUrls) => {
        const resultOfAllUploads = await HelperFunctions.allSettled(
            attachmentsAndUrls.map((attachmentsAndUrl) => {
                const {
                    attachment,
                    presignedUrl
                } = attachmentsAndUrl;

                const uniqueKey = this.getUniqueKey(attachment.fileName, attachment.attachmentType);
                const attachmentBody = this.state.fileNameToFileMap[uniqueKey];
                if (attachmentBody) {
                    // Say we start of by having LOA1 for both the circuits.
                    // Then a user comes in (so perhaps another session or even an entirely new user)
                    // and decides circuit 2 needs to be on LOA2, so remove circuit 2 from LOA1
                    // and adds LOA2 with circuit 2.
                    // At this point in time, both attachments have changed,
                    // so they are added to attachmentsToSubmit.
                    // However, we don't have the actual LOA1 file, so we can't really upload it then right
                    // because the second session doesn't have context of the file (
                    // even though we have to modify the entityIdList).
                    attachment[Constants.ATTRIBUTES.verifyAttachmentFromRequest] = true;
                    attachmentsToVerify.push(attachment);
                    return this.uploadSingleAttachment(attachment, presignedUrl, attachmentBody);
                }
                return Promise.resolve();
            })
        );
        const rejections = resultOfAllUploads.filter(rejection => rejection.status === Constants.PROMISE_REJECTED);
        if (rejections.length > 0) {
            throw new Error(`Failed to upload ${rejections.length} files.`);
        }
    };

    uploadSingleAttachment = (attachment, presignedUrl, body) => fetch(presignedUrl, {
        method: "PUT",
        body,
        headers: {
            "Content-Type": attachment.contentType,
            "Content-Disposition": `attachment; filename="${attachment.fileName}"`
        }
    });

    render() {
        return (
            <AttachmentModal
                // Props passed in
                isAttachmentModalClicked={this.props.isAttachmentModalClicked}
                hideAttachmentModal={this.props.hideAttachmentModal}

                // Fields and methods from our state and/or instance
                attachments={this.state.attachments}
                attachmentOptions={this.props.attachmentOptions || this.state.attachmentOptions}
                attachmentFormToReactRefMap={this.state.attachmentFormToReactRefMap}
                hasSubmittedAttachmentOnce={this.state.hasSubmittedAttachmentOnce}
                errorToDisplayOnAttachmentModal={this.state.errorToDisplayOnAttachmentModal}
                isAttachmentSubmissionInProgress={this.state.isAttachmentSubmissionInProgress}

                addNewAttachment={this.addNewAttachment}
                removeAttachment={this.removeAttachment}
                handleAttachmentModalInputChange={this.handleAttachmentModalInputChange}
                triggerFileInput={this.triggerFileInput}
                handleFileInput={this.handleFileInput}
                handleAttachmentSubmit={this.handleAttachmentSubmit}
                disableSubmitButton={this.disableSubmitButton()}

                // Circuit props
                circuitDesignsAttachmentTypeMap={this.state.circuitDesignsAttachmentTypeMap}
                circuitDesignOptions={this.props.circuitDesignOptions}

                isAttachmentForOrderType={this.props.entityType === Constants.ORDER_ENTITY_TYPE}

                // BulkUpdate props
                isAttachmentForBulkUpdateType={this.props.entityType === Constants.BULK_UPDATE_JOB_ENTITY_TYPE}
                handleBulkUpdateTableType={this.props.handleBulkUpdateTableType}
                handleBulkUpdateDescription={this.props.handleBulkUpdateDescription}
                bulkUpdateTableType={this.props.bulkUpdateTableType}
                getBulkUpdateDescription={this.props.getBulkUpdateDescription}
            />
        );
    }
}