import AddressValidation from "address/AddressValidation";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export default class SiteValidation {
    static BUTTON_NAMES = {
        addProviderButton: "addProviderButton",
        subtractProviderButton: "subtractProviderButton"
    };

    static SITE_TYPE_NAMES = {
        colo: "Colo",
        ilaHut: "ILA Hut",
        leased: "Leased",
        manhole: "Manhole",
        offnet: "Offnet",
        owned: "Owned"
    };

    static SPECIAL_ERROR_MESSAGES = {
        noStreetAddressOrLatAndLong: "You must provide either a street address or a latitude and longitude.",
        latitudeButNoLongitude: "Longitude is required if latitude is provided.",
        longitudeButNoLatitude: "Latitude is required if longitude is provided."
    };

    static CLUSTER_LENGTH = 3;

    static STATUS_OPTIONS = [
        {
            label: "Active",
            value: "Active"
        },
        {
            label: "Inactive",
            value: "Inactive"
        }
    ];

    static DEFAULT_SITE_ERROR_TEXTS = {
        siteName: Constants.ERROR_STRINGS.blankInput,
        market: Constants.ERROR_STRINGS.blankInput,
        siteType: Constants.ERROR_STRINGS.blankInput,
        geographicRegion: Constants.ERROR_STRINGS.blankInput,
        colocationOwner: Constants.ERROR_STRINGS.buildingOwnerOrColoOwner,
        buildingOwner: Constants.ERROR_STRINGS.buildingOwnerOrColoOwner,
        addressLineOne: SiteValidation.SPECIAL_ERROR_MESSAGES.noStreetAddressOrLatAndLong,
        addressCity: Constants.ERROR_STRINGS.blankInput,
        addressState: Constants.ERROR_STRINGS.blankInput,
        addressZipCode: Constants.ERROR_STRINGS.blankInput,
        addressCountry: Constants.ERROR_STRINGS.blankInput,
        addressLatitude: SiteValidation.SPECIAL_ERROR_MESSAGES.noStreetAddressOrLatAndLong,
        addressLongitude: SiteValidation.SPECIAL_ERROR_MESSAGES.noStreetAddressOrLatAndLong
    };

    static EMPTY_SITE_ERROR_TEXTS = {
        siteName: "",
        market: "",
        status: "",
        siteType: "",
        geographicRegion: "",
        awsRegion: "",
        coloSiteName: "",
        colocationOwner: "",
        buildingOwner: "",
        clliCode: "",
        description: "",
        quarterlySpend: "",
        managedSite: "",
        addressLineOne: "",
        addressLineTwo: "",
        addressCity: "",
        addressCounty: "",
        addressState: "",
        addressZipCode: "",
        addressCountry: "",
        addressLatitude: "",
        addressLongitude: ""
    };

    static cleanAddressObject = (input) => {
        const output = input;
        // If address is empty delete address object from site,
        // otherwise it will throw an error because of empty strings.
        // We do the same for other optional fields
        if (AddressValidation.isAddressEmpty(output)) {
            return {};
        }
        if (!output.addressLineOne) {
            delete output.addressLineTwo;
        }
        if (!output.addressLineTwo) {
            delete output.addressLineTwo;
        }
        if (!output.addressLatitude) {
            delete output.addressLatitude;
        }
        if (!output.addressLongitude) {
            delete output.addressLongitude;
        }
        if (!output.addressCounty) {
            delete output.addressCounty;
        }
        return output;
    };

    static validateInput = (input) => {
        const inputtedData = input;
        // Here we fetch the changed entity Id
        const attributeId = inputtedData.evt.target.id;
        // Here we define the value to be used later
        let inputValue;

        if ([Constants.ATTRIBUTES.status, Constants.ATTRIBUTES.siteType, Constants.ATTRIBUTES.geographicRegion,
            Constants.ATTRIBUTES.awsRegion, Constants.ATTRIBUTES.managedSite, Constants.ATTRIBUTES.buildingOwner,
            Constants.ATTRIBUTES.colocationOwner].includes(attributeId)) {
            // Handle select dropdowns differently
            inputValue = inputtedData.evt.detail.selectedOption.value;
            inputtedData.site[attributeId] = inputValue || null;
            if (attributeId === Constants.ATTRIBUTES.status || attributeId === Constants.ATTRIBUTES.siteType
                || attributeId === Constants.ATTRIBUTES.geographicRegion) {
                inputtedData.siteErrorTexts[attributeId] = HelperFunctions.validateInfo(inputValue,
                    Constants.VALIDATE_INFO_OPTIONS.required);
            }
            if (attributeId === Constants.ATTRIBUTES.siteType
                || attributeId === Constants.ATTRIBUTES.buildingOwner
                || attributeId === Constants.ATTRIBUTES.colocationOwner) {
                inputtedData.site[attributeId] = inputtedData.evt.detail.selectedOption.value;
                // Here we assign the appropriate error texts to the buildingOwner and colocationOwner fields
                // If both fields are empty, we assign error texts to each
                if (inputtedData.site[Constants.ATTRIBUTES.siteType] !== SiteValidation.SITE_TYPE_NAMES.offnet
                    && !inputtedData.site[Constants.ATTRIBUTES.buildingOwner]
                    && !inputtedData.site[Constants.ATTRIBUTES.colocationOwner]) {
                    inputtedData.siteErrorTexts[Constants.ATTRIBUTES.buildingOwner]
                        = Constants.ERROR_STRINGS.buildingOwnerOrColoOwner;
                    inputtedData.siteErrorTexts[Constants.ATTRIBUTES.colocationOwner]
                        = Constants.ERROR_STRINGS.buildingOwnerOrColoOwner;
                } else {
                    // Otherwise, neither field should have an error message
                    inputtedData.siteErrorTexts[Constants.ATTRIBUTES.buildingOwner] = "";
                    inputtedData.siteErrorTexts[Constants.ATTRIBUTES.colocationOwner] = "";
                }
            }
        } else {
            inputValue = inputtedData.evt.detail.value;
            inputtedData.site[attributeId] = inputValue || null;
            if (attributeId === Constants.ATTRIBUTES.market) {
                // First we check if market is blank and assign the appropriate error text
                let marketErrorText = HelperFunctions.validateInfo(inputValue,
                    Constants.VALIDATE_INFO_OPTIONS.required);
                // If the error message is blank, we check that the input is in the correct format
                if (marketErrorText === "") {
                    marketErrorText = HelperFunctions.validateAirportCode(inputValue);
                }
                inputtedData.siteErrorTexts[attributeId] = marketErrorText;
            }
            if (attributeId === Constants.ATTRIBUTES.siteName) {
                inputtedData.siteErrorTexts[attributeId] = HelperFunctions.validateInfo(inputValue,
                    Constants.VALIDATE_INFO_OPTIONS.required);
            }
            if (attributeId === Constants.ATTRIBUTES.clliCode) {
                if (inputValue === "") {
                    inputtedData.siteErrorTexts[attributeId] = "";
                } else if (inputtedData.site.siteType === SiteValidation.SITE_TYPE_NAMES.manhole
                    || inputtedData.site.siteType === SiteValidation.SITE_TYPE_NAMES.ilaHut) {
                    inputtedData.siteErrorTexts[attributeId] = HelperFunctions
                        .validateNetworkSupportSiteClliCode(inputValue);
                }
                // If the character is a letter, we capitalize it automatically for the user. Do this because
                // CLLI codes only allow uppercase letters
                if (inputValue.toUpperCase() !== inputValue.toLowerCase()) {
                    inputtedData.site[attributeId] = inputValue.toUpperCase();
                }
            }
            if (attributeId === Constants.ATTRIBUTES.description) {
                inputtedData.siteErrorTexts[attributeId] = HelperFunctions.validateStringLength(inputValue);
            }
        }
        return inputtedData;
    };

    // Data obtained from
    // https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Concepts.RegionsAndAvailabilityZones.html
    static AWS_REGIONS = [
        {
            label: "None",
            value: ""
        },
        {
            label: "us-east-1",
            value: "us-east-1"
        },
        {
            label: "us-east-2",
            value: "us-east-2"
        },
        {
            label: "us-west-1",
            value: "us-west-1"
        },
        {
            label: "us-west-2",
            value: "us-west-2"
        },
        {
            label: "af-south-1",
            value: "af-south-1"
        },
        {
            label: "ap-east-1",
            value: "ap-east-1"
        },
        {
            label: "ap-northeast-1",
            value: "ap-northeast-1"
        },
        {
            label: "ap-northeast-2",
            value: "ap-northeast-2"
        },
        {
            label: "ap-northeast-3",
            value: "ap-northeast-2"
        },
        {
            label: "ap-southeast-1",
            value: "ap-southeast-1"
        },
        {
            label: "ap-southeast-2",
            value: "ap-southeast-2"
        },
        {
            label: "ap-southeast-3",
            value: "ap-southeast-3"
        },
        {
            label: "ca-central-1",
            value: "ca-central-1"
        },
        {
            label: "eu-central-1",
            value: "eu-central-1"
        },
        {
            label: "eu-west-1",
            value: "eu-west-1"
        },
        {
            label: "eu-west-2",
            value: "eu-west-2"
        },
        {
            label: "eu-west-3",
            value: "eu-west-3"
        },
        {
            label: "eu-south-1",
            value: "eu-south-1"
        },
        {
            label: "eu-north-1",
            value: "eu-north-1"
        },
        {
            label: "me-south-1",
            value: "me-south-1"
        },
        {
            label: "sa-east-1",
            value: "sa-east-1"
        },
        {
            label: "us-gov-east-1",
            value: "us-gov-east-1"
        },
        {
            label: "us-gov-west-1",
            value: "us-gov-west-1"
        }
    ];
}