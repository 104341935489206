import Constants from "utils/Constants";
import SiteValidation from "site/SiteValidation";
import HelperFunctions from "common/HelperFunctions";

export default class AddressValidation {
    static validateLatitude = (latitude) => {
        const latitudeRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
        if (latitudeRegex.test(latitude)) {
            return "";
        }
        return Constants.VALIDATION_ERROR_STRINGS.latitude;
    };

    static validateLongitude = (longitude) => {
        const longitudeRegex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
        if (longitudeRegex.test(longitude)) {
            return "";
        }
        return Constants.VALIDATION_ERROR_STRINGS.longitude;
    };

    /*
     * This function is used for validating complete address.
     */
    static validateAddress = (input) => {
        const inputtedData = input;
        // Here we fetch the changed entity Id
        const attributeId = inputtedData.evt.target.id;
        let inputValue;
        if (attributeId === Constants.ADDRESS_IDS.country) {
            inputValue = inputtedData.evt.detail.selectedOption.value;
        } else {
            inputValue = inputtedData.evt.detail.value;
        }
        inputtedData.address[attributeId] = inputValue;

        let requiredText = "";

        if (inputtedData.isSite) {
            requiredText = Constants.ERROR_STRINGS.blankInput;
            if (attributeId === Constants.ADDRESS_IDS.lineOne
                || attributeId === Constants.ADDRESS_IDS.latitude
                || attributeId === Constants.ADDRESS_IDS.longitude) {
                // Handles the logic that requires users to provide either street address or latitude and longitude
                if (!inputtedData.address.addressLineOne && !inputtedData.address.addressLatitude
                    && !inputtedData.address.addressLongitude) {
                    inputtedData.errors.addressLineOne = SiteValidation
                        .SPECIAL_ERROR_MESSAGES.noStreetAddressOrLatAndLong;
                    inputtedData.errors.addressLatitude = SiteValidation
                        .SPECIAL_ERROR_MESSAGES.noStreetAddressOrLatAndLong;
                    inputtedData.errors.addressLongitude = SiteValidation
                        .SPECIAL_ERROR_MESSAGES.noStreetAddressOrLatAndLong;
                } else {
                    if (inputtedData.address.addressLineOne) {
                        inputtedData.errors.addressLineOne = AddressValidation.validateAddressLine(
                            inputtedData.address.addressLineOne
                        );
                    } else {
                        inputtedData.errors.addressLineOne = "";
                    }
                    inputtedData.errors.addressLatitude = "";
                    inputtedData.errors.addressLongitude = "";
                }
                if (inputtedData.address.addressLatitude && !inputtedData.address.addressLongitude) {
                    inputtedData.errors.addressLatitude = "";
                    inputtedData.errors.addressLongitude = SiteValidation
                        .SPECIAL_ERROR_MESSAGES.latitudeButNoLongitude;
                } else if (!inputtedData.address.addressLatitude && inputtedData.address.addressLongitude) {
                    inputtedData.errors.addressLatitude = SiteValidation.SPECIAL_ERROR_MESSAGES.longitudeButNoLatitude;
                    inputtedData.errors.addressLongitude = "";
                } else if (inputtedData.address.addressLatitude && inputtedData.address.addressLongitude) {
                    inputtedData.errors.addressLatitude = AddressValidation.validateLatitude(
                        inputtedData.address.addressLatitude
                    );
                    inputtedData.errors.addressLongitude = AddressValidation.validateLongitude(
                        inputtedData.address.addressLongitude
                    );
                }
            }
        } else {
            requiredText = "";
            // If the address is empty, we remove all error messages since address is not a required field
            // for non-site objects
            if (AddressValidation.isAddressEmpty(inputtedData.address)) {
                inputtedData.errors.addressLineOne = "";
                inputtedData.errors.addressLineTwo = "";
                inputtedData.errors.addressCity = "";
                inputtedData.errors.addressState = "";
                inputtedData.errors.addressZipCode = "";
                inputtedData.errors.addressCountry = "";
            } else {
                // If any field in the address has a value, we must update every error message in the address
                // appropriately
                if (!inputtedData.address.addressLineOne) {
                    inputtedData.errors.addressLineOne = Constants.ERROR_STRINGS.blankAddressInput;
                } else {
                    inputtedData.errors.addressLineOne = AddressValidation.validateAddressLine(
                        inputtedData.address.addressLineOne
                    );
                }
                if (!inputtedData.address.addressCity) {
                    inputtedData.errors.addressCity = Constants.ERROR_STRINGS.blankAddressInput;
                } else {
                    inputtedData.errors.addressCity = "";
                }
                if (!inputtedData.address.addressState) {
                    inputtedData.errors.addressState = Constants.ERROR_STRINGS.blankAddressInput;
                } else {
                    inputtedData.errors.addressState = "";
                }
                if (!inputtedData.address.addressZipCode) {
                    inputtedData.errors.addressZipCode = Constants.ERROR_STRINGS.blankAddressInput;
                } else {
                    inputtedData.errors.addressZipCode = "";
                }
                if (!inputtedData.address.addressCountry) {
                    inputtedData.errors.addressCountry = Constants.ERROR_STRINGS.blankAddressInput;
                } else {
                    inputtedData.errors.addressCountry = "";
                }
            }
        }

        switch (attributeId) {
        case Constants.ADDRESS_IDS.lineTwo:
            if (!inputtedData.address.addressLineTwo) {
                inputtedData.errors.addressLineTwo = "";
            } else {
                inputtedData.errors.addressLineTwo = AddressValidation.validateAddressLine(
                    inputtedData.address.addressLineTwo
                );
            }
            break;
        case Constants.ADDRESS_IDS.city:
            if (!inputtedData.address.addressCity) {
                inputtedData.errors.addressCity = requiredText;
            } else {
                inputtedData.errors.addressCity = HelperFunctions.validateLettersAndSpaces(
                    inputtedData.address.addressCity
                );
            }
            break;
        case Constants.ADDRESS_IDS.state:
            if (!inputtedData.address.addressState) {
                inputtedData.errors.addressState = requiredText;
            } else {
                inputtedData.errors.addressState = HelperFunctions.validateLettersAndSpaces(
                    inputtedData.address.addressState
                );
            }
            break;
        case Constants.ADDRESS_IDS.zipCode:
            if (!inputtedData.address.addressZipCode) {
                inputtedData.errors.addressZipCode = requiredText;
            } else {
                inputtedData.errors.addressZipCode = HelperFunctions.validateStringLength(
                    inputtedData.address.addressZipCode
                );
            }
            break;
        case Constants.ADDRESS_IDS.country:
            if (!inputtedData.address.addressCountry) {
                inputtedData.errors.addressCountry = requiredText;
            } else {
                inputtedData.errors.addressCountry = "";
            }
            break;
        default:
        }
        return inputtedData;
    };

    /*
     * This function is used for validating address line one or two.
     */
    static validateAddressLine = (input) => {
        const addressLineRegex = /^[A-zÀ-ÿ0-9-'.,#\s]+$/;
        if (addressLineRegex.test(input)) {
            return "";
        }
        return Constants.VALIDATION_ERROR_STRINGS.addressLineOneOrTwo;
    };

    /*
     * This function is used for validating if address is empty or not.
     */
    static isAddressEmpty = (address) => {
        if (address === undefined) {
            return true;
        }
        if (Object.keys(address).length === 0) {
            return true;
        }
        let addressIsNotPresent = true;
        Object.keys(address).forEach((key) => {
            // if all are empty don't check
            if (address[key].trim()) {
                addressIsNotPresent = false;
            }
        });
        return addressIsNotPresent;
    };

    /**
     * Helper function that returns whether any part of a standard address has been entered
     * Must use !! because this converts the strings to boolean values (otherwise it returns a string)
     * @param address
     * @returns boolean
     */
    static hasAnyAddressLineBeenEntered = address => !!address.addressLineOne || !!address.addressLineTwo ||
        !!address.addressCity || !!address.addressZipCode || !!address.addressCounty ||
        !!address.addressState || !!address.addressCountry;

    /**
     * Used in the address form to decide whether the address line one is required or optional
     * @param isSiteForm, address
     * @returns boolean
     */
    static isAddressLineOneRequired = (isSiteForm, address) => {
        if (isSiteForm) {
            if (address.addressLatitude || address.addressLongitude) {
                return false;
            }
            return true;
        } else if (AddressValidation.hasAnyAddressLineBeenEntered(address)) {
            return true;
        }
        return false;
    };

    /**
     * Used in the address form to decide whether the address fields are required or optional
     * @param isSiteForm, address
     * @returns boolean
     */
    static isAddressRequired = (isSiteForm, address) => {
        if (isSiteForm) {
            return true;
        }
        return AddressValidation.hasAnyAddressLineBeenEntered(address);
    };

    /**
     * Used in the address form to decide whether the address fields are required or optional
     * This is only applicable when coordinates are present (which is only on the site form)
     * @param address
     * @returns boolean
     */
    static areCoordinatesRequired = (address) => {
        if (address.addressLatitude || address.addressLongitude) {
            return true;
        }
        return !address.addressLineOne;
    };
}