import React from "react";
import { Icon, Link } from "@amzn/awsui-components-react/polaris";
import Box from "@amzn/awsui-components-react/polaris/box";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { FremontContentLayout } from "../utils/CommonComponents";

const LighthouseLandingPage = () => (
    <FremontContentLayout
        disableOverlap
        header={
            <Grid gridDefinition={[{ colspan: { default: 12 } }]}>
                <Box padding="l">
                    <div className="lighthouse-header">
                        <Grid gridDefinition={[{ colspan: 6, offset: { default: 2 } }]}>
                            <Box padding={{ top: "xxxl" }}>
                                <SpaceBetween size="xxs">
                                    <Box
                                        variant="h1"
                                        fontWeight="bold"
                                        padding="n"
                                        fontSize="display-l"
                                        color="inherit"
                                    >
                                        <span className="lighthouse-header-text">Lighthouse</span>
                                    </Box>
                                    <Box
                                        variant="p"
                                        padding="n"
                                        fontSize="display-l"
                                        color="inherit"
                                    >
                                        <span className="lighthouse-header-text">
                                            Comprehensive Physical Network Service
                                        </span>
                                    </Box>
                                </SpaceBetween>
                            </Box>
                        </Grid>
                        <Grid gridDefinition={[{ colspan: 6, offset: { default: 2 } }]}>
                            <Box padding={{ bottom: "xxxl" }}>
                                <Box
                                    variant="p"
                                    padding="n"
                                    fontSize="display-m"
                                    color="inherit"
                                >
                                    <span className="lighthouse-header-text">
                                        Lighthouse tracks the physical state of the AWS network from L0 to L3. Through
                                        geospatial, workflow, and hierarchical based views of the physical network and
                                        its consumers, we provide the ability to design, deploy, and operate the
                                        physical and related logical components of the worlds largest network.
                                    </span>
                                </Box>
                            </Box>
                        </Grid>
                    </div>
                </Box>
            </Grid>
        }
    >
        <Grid
            gridDefinition={[{ colspan: 6, offset: { default: 2 } }]}
        >
            <Box>
                <Box padding={{ left: "xxxl", right: "xxxl" }}>
                    <Box margin={{ bottom: "s" }}>
                        <Header variant="h1">Benefits and features</Header>
                    </Box>
                </Box>
                <Box padding={{ left: "xxxl", right: "xxxl" }}>
                    <SpaceBetween size="xxl">
                        <Container>
                            <ColumnLayout columns={2} variant="text-grid">
                                <SpaceBetween size="m">
                                    <div>
                                        <Box variant="h3">Fremont</Box>
                                        <Box variant="p">Network capacity workflow orchestration service</Box>
                                    </div>
                                </SpaceBetween>
                                <SpaceBetween size="m">
                                    <div>
                                        <Box variant="h3">LinkService</Box>
                                        <Box variant="p">
                                            Data store for physical, optical, and fabric links along with the
                                            hierarchies that bind them together
                                        </Box>
                                    </div>
                                </SpaceBetween>
                                <SpaceBetween size="m">
                                    <div>
                                        <Box variant="h3">Halo</Box>
                                        <Box variant="p">
                                            Physical fiber visualization and Shared Risk Group (SRG) calculation engine
                                        </Box>
                                    </div>
                                </SpaceBetween>
                                <SpaceBetween size="m">
                                    <div>
                                        <Box variant="h3">Insights</Box>
                                        <Box variant="p">
                                            Insights into physical fiber data including utilization,
                                            consumption, and more
                                        </Box>
                                    </div>
                                </SpaceBetween>
                            </ColumnLayout>
                        </Container>
                        <Container header={<Header variant="h3">Additional Resources</Header>}>
                            <ColumnLayout columns={3} variant="text-grid">
                                <Box>
                                    <Link
                                        href="https://w.amazon.com/bin/view/Networking/Fremont/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <SpaceBetween direction="horizontal" size="xs">
                                            Fremont Wiki
                                            <Icon name="external"/>
                                        </SpaceBetween>
                                    </Link>
                                </Box>
                                <Box>
                                    <Link
                                        href="https://w.amazon.com/bin/view/Networking/NEST/LinkServiceHome/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <SpaceBetween direction="horizontal" size="xs">
                                            LinkService Wiki
                                            <Icon name="external"/>
                                        </SpaceBetween>
                                    </Link>
                                </Box>
                                <Box>
                                    <Link
                                        href="https://w.amazon.com/bin/view/NetworkSpatialData/Halo/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <SpaceBetween direction="horizontal" size="xs">
                                            Halo Wiki
                                            <Icon name="external"/>
                                        </SpaceBetween>
                                    </Link>
                                </Box>
                            </ColumnLayout>
                        </Container>
                    </SpaceBetween>
                </Box>
            </Box>
        </Grid>
    </FremontContentLayout>
);

export default LighthouseLandingPage;